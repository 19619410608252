import i18next from 'i18next';
import { BllinkLanguageDetector } from "./BllinkLanguageDetector";
import { initReactI18next } from 'react-i18next';
import ReactPostprocessor from 'i18next-react-postprocessor';
import { en } from './en/translation'
import { he } from './he/translation'
// stopping support to save dev time approved by rayee via email
// import { es } from './es_MX/translation'

i18next
    .use(new BllinkLanguageDetector())
    .use(initReactI18next) // bind react-i18next to the instance
    .use(new ReactPostprocessor())
    .init({
        debug: true,
        saveMissing: true,
        updateMissing: true,
        appendNamespaceToMissingKey: true,
        missingKeyHandler: (lng, ns, key, fallbackValue, updateMissing, options) => {
            throw new Error(`Key missed. ${key} in ${lng} language`);
        },

        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        postProcess: [
            'reactPostprocessor'
        ],

        // resources: { en, he, es },
        resources: { en, he },
    });

export const i18n = i18next;
