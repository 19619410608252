import { createAsyncThunk } from '@reduxjs/toolkit';
import { BllinkLogger } from '../../common/utils/bllink_loggers';
import { Auth } from 'aws-amplify';
import CacheData from '../../common/utils/cacheData';
import ServerData from '../../common/serverData';
import {hash} from "../../common/utils/hash";

const verifyLoggedIn = createAsyncThunk(
    'auth/verify',
    async (payload, {rejectWithValue}) => {
        try {

            BllinkLogger.info(`verifyLoggedIn start`);
            let user = await Auth.currentAuthenticatedUser();
            BllinkLogger.info(`verify end with `, user?.attributes?.email);
            if (user.attributes && user.attributes.sub && user.attributes.email) {
                const userID = user.attributes.email;
                if (window.smartlook) {
                    BllinkLogger.info(`set email manager in smartlook`, user.attributes.email);
                    window.smartlook('identify', userID);
                }
                // moved to getUserProfile
                // if (window.userpilot) {
                //     BllinkLogger.info(`set email manager in userpilot`, user.attributes.email);
                //     window.userpilot.identify( userID, {email: userID})
                // }
            }
            return {
                attributes: user.attributes,
                accessToken: user.signInUserSession.accessToken.jwtToken
            };
        } catch(e) {
            BllinkLogger.error('Error in verifyLoggedIn', e);
            if (payload && payload.avoidExceptionOnNotLogged){
                // added this option to avoid redirect to login page due to not logged in for the self onboard page
                console.log(`not throwing an exception on not logged in `)
            }else {
                throw new Error(e);
            }
        }
    }
);

/**
 * @typedef {Object} User
 * @property {Object} attributes - The attributes of the user.
 * @property {string} attributes.email - The email of the user.
 * @property {boolean} attributes.email_verified - Whether the user's email is verified.
 * @property {string} attributes.identities - The identities of the user.
 * @property {string} attributes.phone_number - The phone number of the user.
 * @property {boolean} attributes.phone_number_verified - Whether the user's phone number is verified.
 * @property {string} attributes.picture - The URL of the user's picture.
 * @property {string} attributes.sub - The subject identifier for the user.
 * @property {string} accessToken - The access token of the user.
 */

/**
 *
 * @type {AsyncThunk<{User, void, AsyncThunkConfig>}
 */
const getAuthenticatedUser = createAsyncThunk(
    'auth/getCurrentUser',
    async (payload, {rejectWithValue}) => {
        const user = await Auth.currentAuthenticatedUser();
        // map attributes to a simple object
        // not using CacheData since we need it on index.html
        window.localStorage.setItem('managerLoggedIN', 'true');
        return {
            attributes: user.attributes,
            accessToken: user.signInUserSession.accessToken.jwtToken
        };
    }
);

const getUserProfile = createAsyncThunk(
    'auth/getUserProfile',
    async (payload, {getState, rejectWithValue}) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const locale_code = state.common?.language ?? 'he'
        let client = new ServerData(null, null, {accessToken: accessToken});

        try {
            const response = await CacheData.getOrCache(async () => client.managers.getProfile(), 'profile');
            const userID = response.userID;
            const h = await hash(`${userID}${accessToken}`); // we do not store accessToken, so hash is pretty enough
            if (window.analytics && localStorage['lastIdentified'] !== h){
                localStorage['lastIdentified'] = h;
                // Refer to https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
                try {
                    const payload = { ...response, lastLogin: new Date(), loggedIn: true };
                    await window.analytics.identify(userID, payload);
                    await window.analytics.group(response.partnerID, {
                        partnerLogo: response.partnerLogo,
                        partnerName: response.partnerName,
                        partnerID: response.partnerID,
                        name: response.partnerName,
                        'Account Type': 'Management Company',
                    });
                    await window.analytics.track('login', { userID, partnerID: response.partnerID });

                    if (window.userpilot) {
                        payload.locale_code = locale_code;
                        await window.userpilot.identify( userID, payload)
                    }



                } catch (e) {
                    console.error(e);
                }
            }

            BllinkLogger.info(`profile response `, response);
            // ReactGA.set({
            //     userId: response.userID
            // })

            if (window.clarity){
                // https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api
                const userIDAndFriendlyName = userID;
                window.clarity('identify', userIDAndFriendlyName, null, null, userIDAndFriendlyName)
            }
            return response;
        } catch (e) {
            return rejectWithValue({});
        }
    }
);

export {
    verifyLoggedIn,
    getAuthenticatedUser,
    getUserProfile,
};
