import {FeaturesApi} from "./operations/features.api";

export class OperationsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
        this.features = new FeaturesApi(client);
    }

    /**
     * @param {string} functionName
     * @param {Record<string, any>} functionParams
     * @param {boolean} async
     * */
    simulateWorker(functionName, functionParams = {}, async = false) {
        return this.#client.post(`/operations/simulate-worker`, { ...functionParams, functionName, async });
    }

    /**
     * @param {string} eventName
     * @param {object} eventContext
     * @param {boolean} [async=false]
     * */
    systemEvent(eventName, eventContext, async = false) {
        return this.#client.post(`/operations/simulate-worker`, { eventName, eventContext, functionName: 'systemEvent', async });
    }

    /**
     * */
    updatePaymentPlans(buildingID, filters, wetRun = false, ) {
        return this.simulateWorker('updatePaymentPlansOnFeeUpdate', {buildingID, wetRun, filters}, true);
    }

    /**
     * */
    startAutoChequeTransfer() {
        return this.simulateWorker('autoDeposits', {}, false);
    }

    // /**
    //  * @param {number[]} BuildingId
    //  * @param {boolean} forceStart
    //  * */
    // async startAutoCampaign(BuildingId, forceStart= true) {
    //     const payload = {
    //         BuildingId,
    //         forceStart // will trigger the first step immediately if true
    //     };
    //     return await this.#client.put('/operations/auto-campaigns/new', payload);
    // }


    async smsReport(BuildingId) {
        const filter = {
            building_ids: BuildingId
        };
        return await this.#client.post('/operations/sms-report-all-bllink/', {filter});
    }

    async smsReportPartnerIds(partnerIDs) {
        const filter = {
            partner_ids: partnerIDs
        };
        return await this.#client.post('/operations/sms-report-all-bllink/', {filter});
    }

    async testRapydPayout() {
        return await this.#client.post('/operations/test/rapyd/payout');
    }

    async testRapydWallet() {
        return await this.#client.post('/operations/test/rapyd/wallet');
    }

    async testGreenInvoice() {
        return await this.#client.post('/operations/test/greeninvoice');
    }

    async testShortening(url) {
        return await this.#client.post('/operations/test/shortening', { url });
    }

    async generateRenewReport(month = null, year = null, noRenewLinks = true) {
        month = month || new Date().getMonth() + 1;
        year = year || new Date().getFullYear();
        this.#client.post('/operations/plans-to-renew/', {
            "exceptBuildingIds": [],
            "sync": true,
            "noRenewLinks": noRenewLinks,
            "justOffline1": true,
            "time": {"month": month, "year": year}
        });
    }

    async getFeature(entityType, entityId, featureName) {
        return await this.#client.get(`/operations/features/${entityType}/${entityId}/${featureName}`);
    }

    async setFeature(entityType, entityId, featureName, value) {
        return await this.#client.put(`/operations/features/${entityType}/${entityId}/${featureName}`, { value });
    }

    async renewPlan(lastPaymentId, monthsForward = 12, numberOfInstallments = 12) {
        const renewPayload = {
            lastPaymentId: lastPaymentId,
            number_of_months_forward: monthsForward,
            number_of_installments: numberOfInstallments,
            send_invoice: true,
            send_confirmation: true
        };
        return await this.#client.post(`/operations/renew-plan/`, renewPayload);
    }

    /**
     * @param {string} cartId
     * @param {sting} paymentID
     * @param {sting} cardLast4Digits
     * */
    async chargeCart(cartId, paymentID, cardLast4Digits) {
        return await this.#client.post('/managers/operations/charge-cart/', {
            cartID: cartId,
            paymentID: paymentID,
            last4Digits: cardLast4Digits
        });
    }

    /**
     * @param {number} paymentPlanId
     * @param {boolean} [includeData=false]
     * */
    getPaymentPlanStatus(paymentPlanId, includeData = false) {
        const options = {};
        if (includeData) {
            options.includeData = true;
        }
        return this.#client.get(`/operations/order/${paymentPlanId}`, options);
    }


    /**
     * @param {number} paymentID
     * @param {string} cardLast4Digits
     * */
    async issueInvoice(paymentID) {
        // doens't work well with more than one payment
        // need to create a look to send for each payment id
        // ps: protects against duplicates
        return await this.#client.post('/operations/invoices/issue-invoices/', {
            paymentsIds: [paymentID]
        });
    }

    // DEBUG ONLY - it's very sensitive
    // async autoCharge(payload) {
    //     return await this.#client.post('/operations/auto-charge', payload);
    // }

    async createUser(login, email, phoneNumber, firstName, lastName, role, tmpPassword) {
        return await this.#client.post('/operations/users/create', { login, email, phoneNumber, firstName, lastName, role, tmpPassword });
    }

}
