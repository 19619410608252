// offline payment AKA external payments are a way to log external payments
// made by wire / cheque / cash directly to co op bank account not via our platform
import $ from 'jquery';
import { i18n } from "../../common/translations";
import {GAEvent} from "../../common/google_analytics/google_analytics";
import { constants } from "../../common/constants/constants";
import {BllinkLogger} from "../../common/utils/bllink_loggers";
require('jquery-validation');

export default class OfflinePaymentsLogic{

    static validatePayloadBeforeSending(data){
        // todo validate only month related or only one time
        // validate total sums match

    }

    static async createOfflinePaymentPlan(client, data){
        // will post to the server and log the payments
        // once successful, it will redirect to confirmation page
        const buildingID = data.buildingID;
        const apartmentNumber = data.apartmentNumber;
        const url = `/managers/external-payments/${buildingID}/${apartmentNumber}`;
        this.validatePayloadBeforeSending(data);

        if (buildingID == null || apartmentNumber == null) {
            console.error('[offlinePaymentsLogic] incorrect API call, path parameters are undefined');
        }
        return await client.post(url, data);
    }

    static bindOfflineForms(reactComponent){
        const thisObj = this;
        $(document).ready(function (){

            thisObj.addWireValidationRules();
            thisObj.addCashValidationRules();
        });

    }

    static addChequeValidationRules(validator, numberOfRows){
        /*
            when adding new rows, we need to destroy the validator and rebuild it
         */

        BllinkLogger.info(`addChequeValidationRules validator `, validator);
        if (validator){
            BllinkLogger.info('destroy validation')
            BllinkLogger.info(validator.destroy());
        }

        let payload = this.buildingChequeRowsValidatorPayload(numberOfRows);
        BllinkLogger.info(`validation payload`, payload)
        validator = $(`.js-offline-payment-form.js-cheque-form`).validate(payload);

        return validator

    }

    static buildingChequeRowsValidatorPayload(numberOfRows){
        let payload = {
            rules: {
                // https://app.asana.com/0/1200496983031576/1200321519654469
                // Mandatory fields - Offline Payments - We have mandatory fields in the offline payments process,
                // lets change them to optional
                // 'js-bank-code': 'required',
                // 'branch-number': {
                //     required: true,
                //     minlength: 3
                // },
                // 'account-number': {
                //     required: true,
                //     minlength: 5
                // }
            },
            // Specify validation error messages
            messages: {
                // 'js-bank-code': i18n.t('titles.forms.mandatory_field'),
                // 'branch-number': i18n.t('titles.forms.mandatory_field'),
                // 'account-number': i18n.t('titles.forms.mandatory_field'),
            },

        }

        for (let i=-1; i< numberOfRows; i++){
            payload.rules[`js-cheque-id-${i}`] = 'required'
            payload.rules[`js-datepicker-${i}`] = 'required'
            payload.rules[`js-cheque-amount-${i}`] = 'required'
            payload.messages[`js-cheque-id-${i}`] = i18n.t('titles.forms.mandatory_field')
            payload.messages[`js-datepicker-${i}`] = i18n.t('titles.forms.mandatory_field')
            payload.messages[`js-cheque-amount-${i}`] = i18n.t('titles.forms.mandatory_field')
        }

        payload = {
            ...payload,
            // Make sure the form is submitted to the destination defined
            // in the "action" attribute of the form when valid
            submitHandler: function(form) {
                BllinkLogger.info(`form submitted `)
            },

            invalidHandler: function(form, validator) {
                BllinkLogger.info(`validator.errorList `, validator.errorList);
                for (const error of validator.errorList){
                    GAEvent(constants.GoogleAnalytics.Events.offlinePayments, 'validationError', 1, $(error.element).attr('name'));
                }
            }
        }

        return payload

    }

    static addWireValidationRules(){

        $(`.js-offline-payment-form.js-wire-form`).validate({
            // Specify validation rules
            rules: {
                // https://app.asana.com/0/1200496983031576/1200321519654469
                // [0.5] Mandatory fields - Offline Payments - We have mandatory fields in the offline payments process, lets change them to optional
                // 'js-bank-code': 'required',
                // 'branch-number': {
                //     required: true,
                //     minlength: 3
                // },
                // 'account-number': {
                //     required: true,
                //     minlength: 5
                // },
                'datepicker': 'required'

            },

            // Specify validation error messages
            messages: {
                // 'js-bank-code': i18n.t('titles.forms.mandatory_field'),
                // 'branch-number': i18n.t('titles.forms.mandatory_field'),
                // 'account-number': i18n.t('titles.forms.mandatory_field'),
                'datepicker': i18n.t('titles.forms.mandatory_field'),
            },

            // Make sure the form is submitted to the destination defined
            // in the "action" attribute of the form when valid
            submitHandler: function(form) {
                BllinkLogger.info(`form submitted `)
            },

            invalidHandler: function(form, validator) {
                BllinkLogger.info(`validator.errorList `, validator.errorList);
                for (const error of validator.errorList){
                    GAEvent(constants.GoogleAnalytics.Events.offlinePayments, 'validationError', 1, $(error.element).attr('name'));
                }
            }
        });
    }

    static addCashValidationRules(){

        $(`.js-offline-payment-form.js-cash-form`).validate({
            // Specify validation rules
            rules: {
                'datepicker': 'required'

            },

            messages: {
                'datepicker': i18n.t('titles.forms.mandatory_field'),
            },

            // Make sure the form is submitted to the destination defined
            // in the "action" attribute of the form when valid
            submitHandler: function(form) {
                BllinkLogger.info(`form submitted `);
            },

            invalidHandler: function(form, validator) {
                BllinkLogger.info(`validator.errorList `, validator.errorList);
                for (const error of validator.errorList){
                    GAEvent(constants.GoogleAnalytics.Events.offlinePayments, 'validationError', 1, $(error.element).attr('name'));
                }
            }
        });
    }

}
