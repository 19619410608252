export const signup = {
    "signup": "Sign up",
    "signin": "Sign in",
    "username": "Username",
    "password": "Password",
    "first_name": "First name",
    "last_name": "Last name",
    "already_have_an_account": "Already have an account?",
    "or_with": "or",
    "open_a_new_user": "Open a new account",
    "open_a_new_user_sub_title": "Opening a new account will allow you to open additional buildings under the same account",
    "open_a_new_user_with": "Open account with",
    "email_verify": "Email verify",
    "password_verify": "Password verify",
    "test": "test",
    "email": "Email",
    "phone": "Phone"
};
