import { useSelector } from 'react-redux';
import LoaderIcon from '../../images/loader.svg';

export default function Loader() {
    const isLoading = useSelector((state) => state.common.loader);
    return (
        <div
            className={`loading-overlay ${isLoading > 0 ? 'is-active' : ''}`}
        >
            <img src={LoaderIcon} className="rotate loader" alt="spinner"/>
        </div>
    );
}

