import styled from 'styled-components';
import {Container} from 'react-bootstrap';

export const StyledPdfResult = styled(Container)`
  .payments-table {
    margin-bottom: 50px;
    .table-title {
      padding: 15px !important;
      h4 {
        font-size: 24px !important;
      }
    }
    #table {
      width: 100%;
      margin-bottom: 0;
      td, th {
        padding: 15px 20px;
      }
    }
  }

  .bllink-button {
    width: 100% !important;
  }
`;