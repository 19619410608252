// Helpers to get modified data from redux store
import {createSelector} from '@reduxjs/toolkit';
import {constants} from "../../common/constants/constants";

export const isAdminSelector = createSelector(state => state.auth.user, user => {
    const {email} = user;
    // removed ptop.co.il
    return email && (email.includes('bllink.co'));
});

export const isGuestSelector = createSelector(state => state.auth.user, user => {
    const {email} = user;
    return !email
});


export const isDevSelector = createSelector(state => state.auth.userProfile, profile => {
    const { isDeveloper } = profile;
    return isDeveloper;
});

export const isPremiumUserSelector = createSelector(state => state.manager.partnerFeatures, features => {
    // state.manager.partnerFeatures can change based on the url for admins
    // decision here based on most people are or are not premium users
    // todo also consider lean if no feature is found
    const isLeanBllink = features?.leanBllink === 'true';
    return !isLeanBllink;
    // mocked for now
    // related to https://bllink.atlassian.net/browse/SSB-2061
    // return true;
});

export const hasPremiumAccessSelector = createSelector([isPremiumUserSelector], isPremiumUser => {
    const isCurrentDateLessThenAccessEndDate = (new Date()).getTime() < constants.leanUsersAccessSidebarTillDate?.getTime();
    return isPremiumUser || isCurrentDateLessThenAccessEndDate;
});

export const shouldLandUserToPremiumInfoPageSelector = createSelector([isPremiumUserSelector, state => state.auth ,state => state.commonPersistent], (isPremiumUser, auth, commonPersistent) => {
    // return true if user just logged in and don`t have premium status
    // related to https://bllink.atlassian.net/browse/SSB-2050
    return !!auth.userProfile?.userID && !!auth.userProfile?.partnerID && commonPersistent.userShouldVisitPremiumInfoPage && !isPremiumUser;
});

export const isPartnerAllowedSeeChequeScanner = createSelector(state => state.auth.userProfile, userProfile => {
    const {partnerID} = userProfile;
    let allowedPartners = [

    ];
    return partnerID && allowedPartners.includes(partnerID);
});

export const shouldHideRewardsSelector = createSelector(state => state.auth.userProfile, userProfile => {
    const {partnerID} = userProfile;
    let hideFromPartners = [
        'Adems', 'US Demo', 'Chung', 'ZoharHoldongs'
    ];
    return partnerID && hideFromPartners.includes(partnerID);
});


export const isPartnerAllowedSeeNewFeature = createSelector(state => state.auth.userProfile, userProfile => {
    /*
        generic function to gradually rollout new features
        for partners
     */
    const {partnerID} = userProfile;
    let allowedPartners = [
        'agas',
        'bllink',
        'alon',
        'clinton'
    ];
    return partnerID && allowedPartners.includes(partnerID);
});

export const isPartnerAllowedSeeUpdateManual = createSelector(state => state.auth.userProfile, userProfile => {
    /*
        generic function to gradually rollout new features
        for partners
     */
    const partnerID = (userProfile?.partnerID || '').trim().toLowerCase();
    let allowedPartners = [
        'opal'
    ];
    return partnerID && allowedPartners.includes(partnerID);
});


export const isNewFeatureOpenSelector = createSelector(state => state.auth.userProfile, userProfile => {

    // open for all managers. after 1/2/2024 we can remove this code all together
    // return true
    const partnerID = (userProfile?.partnerID || '').trim().toLowerCase();
    let excludedPartners = [
        'shhakim'
    ];
    return !excludedPartners.includes(partnerID);

    // return features?.showNewFeature === 'true';
    // mocked for now
    // related to https://bllink.atlassian.net/browse/SSB-2061
    // return true;
});
