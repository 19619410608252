import { constants } from "../../constants/constants";
import {lazyReload} from "../../lazyReload";
const PlanRenewalsPage = lazyReload(() => import("../../../tenants/tenant_payments/planRenewals/planRenewalsPage"));
const FailedPaymentsPage = lazyReload(() => import("../../../tenants/tenant_payments/failedPayments/failedPaymentsPage"));

export const OperationRoutes = [
    {path: `/${constants.pages.planRenewalPage}/:renewalToken`, component: PlanRenewalsPage, exact: true, newFlowFooter: true, forceFooter: true},
    {path: `/${constants.pages.failedPaymentPage}/:failedToken`, component: FailedPaymentsPage, exact: true},

]

