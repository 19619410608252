export const reports = {
    tenantType: {
        renter: 'renter',
        owner: 'owner',
        unknown: 'unknown',
    },
    "yearly_cashflow_title": "Yearly cashflow report {{year}}",
    "monthly_cashflow_title": "Monthly charges {{monthName}} {{year}} - Total <span class='amount'>{{currency}} {{amount}}<\/span>",
    "monthly_cashflow_table_title": "Monthly charges {{monthName}} {{year}} - Total {{amount}} {{currency}} ",
    "monthly_partner_cashflow_table_title_tooltip": "Bllink charges and payment documentation. Also includes inactive buildings",
    "export_title": "Monthly partner report",
    "cheques_export_title": "Partner checks report",
    "apartment_cashflow_title": "Payment for apartment {{apartmentNumber}}",
    "cheques_title": "Checks report {{monthName}} {{year}} - Total {{amount}} {{currency}}",
    "monthly_future_cashflow_title": "Future Monthly Charges Report - {{monthName}} {{year}} - Total {{amount}} {{currency}}",
    "yearly_by_months_title": "Months paid for {{year}}",
    "yearly_by_months_title_new": "Yearly Report",
    "special_payments_report_title": "Special payments",
    "yearly_by_months_one_time_title": "Special (one-time) payment details for the year {{year}}",
    "tenantName": "Tenant name",
    "buildingTitle": "Building Address",
    "selectApartment": 'Select apartment',
    "renterNameLabel": "Tenant:",
    "ownerNameLabel": "Owner:",
    "ownerName": "Owner name",
    "ownerPhone": "Owner phone",
    "ownerEmail": "Owner email",
    "renterName": "Renter name",
    "renterPhone": "Renter phone",
    "renterEmail": "Renter email",
    "apartmentName": "Apartment",
    "buildingName": "Building",
    "date_to_pay": "Charge Date",
    "ownership_type": "Tenant type",
    "payment_comment": "Payment comment",
    "receipt": "Receipt",
    "done_by": "Done by",
    "transferredAt": "Transferred to Building",
    "actions": "Actions",
    "confirmation": "Payment confirmation",
    "confirmation_link_text": "Confirm",
    "billing_date": "Charge Date",
    "apartment_number": "Apartment {{apartmentNumber}}",
    "paid_out_of_total": "Paid {{paid}} out of {{total}}",
    "paid": "Paid {{paid}}",
    "paid_full": "Full paid {{paid}}",
    "paid_none": "Not paid",
    "paid_for": "Paid for",
    "amount": "Amount",
    "scheduled_to_pay": "Future payment {{scheduledToPay}} out of {{total}}",
    "failed_payment": "Payment failed. Tenant will receive a link to update credit details. no action needed",
    "none_paid": "None paid",
    "partially_paid": "Partially paid",
    "paid_new": "Paid",
    "paid_full_new": "Full paid",
    "paid_none_new": "Not paid",
    "scheduled_to_pay_only_new": "Future payment",
    "monthly_cashflow_sub_title": "Total via Bllink",
    "monthly_cashflow_sub_title_future": "Total in future payments",
    "monthly_cashflow_sub_title_early_withdrawal": "Total for early withdrawal. Available for admin. Does not include fees",
    "scheduled_to_pay_only": "Future payment {{scheduledToPay}}",
    "next_payments": "future charges at {{nextPayments}}",
    "paid_explanation": "Paid or future payment is set",
    "part_paid_explanation": "Partial payment",
    "tenant_claim_paid_explanation": "Marked as paid",
    "empty_table": "No records found",
    "empty_table_for": "No records found for {{name}}",
    "monthly_on_going": "Monthly payments",
    "monthly_debt": "Monthly debts",
    "one_time_debt": "One time Payment debt",
    "one_time_payment": "One time payment",
    "unknown": "Unknown",
    "paid_with": "Paid with",
    "tenantEmail": "Email",
    "tenantPhone": "Phone",
    "monthlyFee": "Monthly fee",
    "tenant_list_title": "Tenant list",
    "drag_and_drop_info": "Drag the file here to upload details",
    "updatedTenantList": "Tenant list was updated",
    "notUpdatedTenantList": "Tenant list wasn't updated",
    "date_of_expense": "Expense date",
    "vendor_name": "Supplier name",
    "expense_frequency": "Expense frequency",
    "print_tooltip": "Click here to print table",
    "excel_tooltip": "Click here to export table to Excel",
    "csv_tooltip": "Click here to export table in csv format",
    "hashavshevet_tooltip": "Click here to export data for Hashavshevet",
    "balance_title": "Balance sheet report for",
    "balance_total": "Total",
    "balance_total_bllink": "Total in Bllink",
    "creditCards": "Total via Bllink",
    "cheques": "Total in cheque",
    "wires": "Total in wires",
    "cash": "Total in cash",
    "legacyPayment": "Total in legacy payments",
    "bankCharge": "Total in bank charges",
    "autoCheque": "Total in auto cheques",
    "tenantRecord": "Total in tenant records",
    "bit": "bit payments",
    "applePay": "Apple pay",
    "googlePay": "Google pay",
    "other": "Total in other methods",
    "balance_total_cash": "Total in cash",
    "balance_total_cheque": "Total in cheque",
    "balance_total_bank": "Total in bank charge",
    "balance_income": "Total income",
    "balance_expense": "Total expense",
    "balance_monthly_total": "Monthly total",
    "not_paid_explanation": "Future charge",
    "balance_money_in": "Money in",
    "balance_money_out": "Money out",
    "balance_diff": "waiting for collection",
    "balance_diff_expenses": "waiting for payment",
    "dateRangeSameYear": "Start/end dates must have the same year",
    "show_all": "Show all",
    "hide": "Hide",
    "save": "Save",
    "tenantsSaved": "Tenants data was saved!",
    "nothingToSave": "There is no data to be saved",
    "fieldError": "{{field}} is invalid",
    "deleteExpenseSuccess": "Expense record was deleted!",
    "deleteExpenseConfirmText": "Are you sure you want to delete expense?",
    "deleteExpenseRecurring": "Delete all recurring expenses",
    "deleteOneMonthExpense": "Delete this month expense only",
    "cancel": "Cancel",
    "partner_debt_list": "General Debt Report",
    "monthly_reports_at_partner_level": "Monthly Charges Report",
    "building_debt_list": "Building debt list",
    "one_time_comment": "One time comment",
    "monthly_comment": "Monthly comment",
    "mc_dashboard_title": "Dashboard for <name>",
    "mc_dashboard": "MC dashboard",
    "total_debt_title": "Total debt: ",
    "total_debt_sub_title_partner": "Not including debts from inactive buildings",
    "total_debt_title_tooltip": "For active buildings only",
    "total_debt_sub_title_tooltip": "Will be equal to total debts in overview status for one-time and monthly, minus debts for inactive buildings",
    "go_back": "Back to Building List",
    "back_to_dashboard": "Back to MC dashboard",
    "total_charged": "Total charged",
    "total_debt": "Total debt",
    "total_future": "Total future payments",
    "futurePayment": "Future Payment",
    "stoppedPayment": "Deleted Payment",
    "partner_debt_title": "General Debt Report",
    "building_debt_title": "Building debt list for:",
    "building_debt": "Building debt list",
    "refund": {
        "tooltip": "Refund",
        "modalTitle": "Confirm refund",
        "modalConfirmText": "Are you sure that you want a refund?",
        "partialRefundText": "Partial refund",
        "refundNotificationSuccess": "Refund was successful!",
        "refundNotificationError": "Refund was not completed",
        "btns": {
            "yes": "Yes",
            "no": "No"
        },
        "future": {
            "tooltip": "Stop all future payments",
            "modalTitle": "Confirm payment cancellation",
            "modalConfirmText": "Are you sure that you want to cancel payment?",
            "refundNotificationSuccess": "Future payment plan has been canceled. Tenant won't be charged!",
            "refundNotificationError": "Payment was not canceled"
        },
        "refund": "refund",
        "chargeback": "client chargeback"
    },
    "delete": {
        "tooltip": "Delete",
        "modalTitle": "Delete payment",
        "notificationSuccess": "Records deleted successfully!",
        "notificationNoUpdates": "Didn't update any records",
        "notificationFailure": "Failed to delete offline payment",
        "radioLabels": {
            "oneMonth": "Delete this month payment only",
            "onePayment": "Delete this payment only",
            "allPayments": "Delete all recurring payments",
            "allNextPayments": "Delete all next recurring payments",
        },
        "btns": {
            "confirm": "Confirm",
            "cancel": "Cancel"
        }
    },
    "apartment": {
        "monthly": "Table",
        "oneTime": "One time",
        "full": "List",
        "year": "Year",
        "tenantDetails": "Tenant details",
        "tenantsPage": "You can edit tenants data on the Tenants list page",
        "paid": "Paid",
        "totalPaid": "Total paid",
        "fee": "Total",
        "diff": "Left"
    },
    "paymentRequestResults": {
        "fallBackSuccessEmail": "Email to {{to}} was sent successfully",
        "fallBackSuccessSMS": "SMS to {{to}} was sent successfully",
        "fallBackSuccessIVR": "IVR to {{to}} was sent successfully",
        "fallBackErrorEmail": "Failed to send email to {{to}}",
        "fallBackErrorSMS": "Failed to send SMS to {{to}}",
        "fallBackErrorIVR": "Failed to send IVR to {{to}}",
        "errorNoEmail": "Email is not provided for {{to}}",
        "errorNoPhone": "Phone is not provided for {{to}}",
    },
    "paymentRequestPopUp": {
        "sendAll": "Send Request",
        "selectAll": "Select all",
        "selectAllExcludeOneMonth": "All except this month's debts only",
        "paymentTitle": "Payment request",
        "allInfoTitle": "All information",
        "howToSendLabel": "How would you like to send the payment request?",
        "sendToLabel": "Send payment request to",
        "recipientsSelect": "Select Recipients",
        "recipientType": "Recipient type",
        "requestMethods": "Submit a request via",
        "sendBuildingRequestLabel": "Send payment request {{name}}",
        "tenantHelloText": "Hi [Tenant Name]",
        "buildingPaymentAddress": "Your building at the address of {{address}} apartment [Apartment Number] request you pay your HOA dues",
        "buildingHelpInfo": "To view the details and confirm the payment request, go to the building page via the link",
        "importantNote": "It is important to note that if you are a tenant, make sure that the months marked for payment are until the end of the contract only",
        "onBehalf": "On behalf of [Partner Name]",
        "problemWithLink": "If you cannot click on the link, reply back by message and click on the link once more*",
        "currentMonthOnlyDebt": "Current month only debt",
        "labels": {
            "sms": "Send SMS",
            "sendEmail": "Send Email",
            "sendIvr": "Send IVR",
            "owner": "Owner",
            "renter": "Renter",
            "name": "Name",
            "phone": "Phone",
            "email": "Email",
            "sendWa": "Send direct WA"
        },
        "btns": {
            "send": "Send",
            "saveSend": "Save and send"
        },


        "thanksTitle": "Thank you!",
        "requestTitle": "Your request has been sent",
        "requestSuccess": "Sent to selected tenants",
        "requestSuccessTo": "Sent to the selected tenants from apt {{apartmentNumber}} building {{buildingID}}",
        "requestError": "Something went wrong",
        "waText": "Hi {{personName}}\n" +
            "Your building at {{buildingAddress}} apartment {{apartmentNumber}} is asking for your HOA fee\n" +
            "To view full details click here \n" +
            " {{paymentLink}}\n" +
            "total monthly debt: {{monthlyDebtIncludingCurrentMonth}} ils\n" +
            "total one time payments: {{oneTimeDebt}} ils\n" +
            "If you are a renter, notice to mark the months only until the end of your contract.\n" +
            "*if you can't click on the link, just reply and it will become clickable\n" +
            "in behalf of {{buildingManagerName}}",
        "sendWaNote": "Requires being connected to WA on this computer"
    },
    "debtListFilteringPopUp": {
        "title": "Debt report filtering",
        "thisMontDebtsLabel": "Filter out apartments with this month only debts"
    },
    "yearlyByMonths": {
        "mainTitle": "Manager manual update apartment {{apartmentName}}, year {{year}}",
        "managerUpdateApartment": "Manual update apartment",
        "monthlyFee": "Monthly fee",
        "amountPaid": "Amount paid"
    },
    "transaction": {
        'tenant_record_approved': 'Tenant report approved',
        'tenant_record_rejected': 'Tenant report canceled',
        'tenant_record_rejected_and_redirect': 'Tenant report canceled, means of payment can be documented',
        'tenant_record_transaction_failed': 'Transaction was failed',
    },
    "partnerMonthlyReportTitle": "Monthly report for",
    "monthly_cashflow_cheque_sub_title": "Total in checks",
    "yearly_by_one_time_title": "Annual report one-time payments",
    "cheque_deposit_tooltip": "Click here to print Checks report for bank deposit",
    "cheque_confirmation_id": "Check number",
    "download_tooltip": "Download PDF",
    "legacyPaymentsUpdated": "Manual payment successfully set, the page will refresh in a second and the information will appear on the screen",
    "legacyPaymentsFailed": "Error setting manual payment",
    "total_debt_col": "Total debt",
    "total_to_pay": "Total",
    "payment_for": "Payment for",
    "total": "Total",
    "total_bank_transfers": "Total Bank Transfers",
    "percentage": "Percentage",
    "4_last_digits": "Last 4 digits",
    "sendRequest": "Send request",
    "setPayment": "Set payment",
    "confirm": "Confirm",
    "deleteExpense": "Delete",
    "payGiftPage_step2_tenantType_Lessee": "Renter",
    "payGiftPage_step2_tenantType_Owner": "Owner",
    "tenantTypeUnknown": "",
    "expenses_monthly_title": "Expenses Report - Month",
    "expenses_report_title": "Expenses Report",
    "expenses_monthly_subtitle": "Total expenses amount for this month",
    "payment_type": "Payment type",
    "frequency": "Frequency",
    "confirmation_id": "Confirmation ID",
    "bank_reconsile": "Bank reconsile",
    "note": "Note",
    "bllink_id": "bllink ID",
    "download_files": "Download files",
    "file_not_found": "File not found",
    "edit_payment_details": "Edit payment details",
    "edit_expense_details": "Edit expense details",
    "open_confirmation": "Confirmation of payment",
    "send_confirmation": "Send Confirmation",
    "add_payment_details": "Add payment details",
    "delete_expense": "Delete expense",
    "unpaid": "Non-paid",
    "oneTime": "One time",
    "recurring": "Fixed payment",
    "apt": "Apt",
    "chargeApt": "Charge apartment",
    "paymentMethod": "Payment method",
    "charge_date": "Charge date:",
    "charge_date_title": "Charge date",
    "deposit_date": "Deposit date:",
    "balance_report_title": "Balance report",
    "opening_account": "Opening Bank Amount",
    "actual_balance": "Bank balance at the beginning of ",
    "building_tenant_records_list": "Building tenant records list",
    "auto_campaign": "Auto Campaign",
    "auto_campaign_instructions": "Download Instructions",
    "auto_campaign_settings": "Auto Campaign Settings",
    "mark_payment": "Mark payment",
    "record_different_months": "Reject Record and record again for tenant",
    "add_meaning_of_payment": "Adding a means of payment",
    "total_tenant_records_title": "Total reported as paid by tenant: ",
    "building_tenant_records_title": "Payment reports from tenants",
    "tenant_report_marked_as_paid": "Tenant report marked as paid",
    "month_marked_as_paid": "Month marked as paid",
    "debtListActions": {
        "sendWarningLetter": "Send warning letter",
        "sendRequest": "Send payment request",
        "setPayment": "Set payment",
        "aptNotes": "Apt notes",
        "downloadWarningLetter": "Download warning letter"
    },
    "debtListNew": {
        "selectApartment": "Select an apartment from list",
        "debtType": "Debt Type",
        "debtTotal": "Total"
    },
    "balance_report_subtitle": "On this page you can track the balance of expenses and income",
    "tenant_list_subtitle": "On this page you can update apartment contacts",
    "total_apartments": "Total apartments",
    "add_payment": "Add payment",
    "add_contact": "Add contact",
    "edit_contact": "Edit contact",
    "delete_contact": "Delete contact",
    "aptNotes": "Apt notes",
    "delete_apartment": "Delete apartment",
    "apartment_deleted": "Apartment {{apartmentNum}} was deleted",
    "total_contacts": "Total contacts",
    "isPrimary": "Primary contact",
    "total_charged_balance": "Total deposited",
    "total_charged_balance_expenses": "Total paid",
    "set_expense_title": "Balance",
    "confirmation_title": "Confirmation Number",
    "fast_search": "Fast search",
    "debt_report_title": "Debt report",
    "debt_report_subtitle": "On this page you can track owing tenants",
    "total_debts": "Total debts",
    "debt_type": "Open payment details",
    "total_amount": "Total amount",
    "tenant_details": "Tenants details",
    "debt_details": "Debt details",
    "for": "For",
    "monthly_fee": "Monthly HOA fee",
    "special_payment": "Special payment",
    "monthly_fee_and_spec_payment": "Monthly HOA fee and special payment",
    "stop_plans": {
        "action": "Stop Plan(s)",
        "action_tooltip": "Stop future payments",
        "confirm": "Please press approve to cancel all future payments",
        "approve": "Confirm",
        "success_notification": "Selected payments successfully stopped",
        "empty_selection_notification": "No one payment selected to stop",
        "no_payments": "No payments to stop found",
    },
    "edit_apartment_payments": {
        "stop_success": "Selected payments successfully stopped",
        "charge_dates_success": "Selected payments' charge dates successfully updated",
        "empty_selection": "No payments selected to proceed",
        "action": "Edit apartment payments",
        "title": "Edit apartment {{apartmentNumber}} payments",
        "subtitle": "Choose payments which you would like to edit",
        "cancel_payments": "Cancel Payments",
        "change_charge_date": "Change Charge Date",
        "items_selected": "items selected",
        "no_payments": "No available payments found",
    },
    "edit_check_payments": {
      "marked_as_deposited": "Mark as deposited",
      "marked_as_deposited_success": "Selected checks have been marked as deposited",
      "mark_as_manual": "Mark as manual",
      "delete": "Delete",
      "delete_success": "Selected checks have been cancelled",
    },
    "edit_wire_payments": {
        "marked_as_approved": "marked as approved",
        "delete_success": "Selected wires have been removed",
        "marked_as_wired_success": "Selected wires have been marked as approved",
    },
    "mark_primary": "Mark contact as primary",
    "mark_legal": "Mark contact in legal status",
    "mark_manager": "Mark contact as manager",
    "filtering": "Filter",
    "phone_is_unavailable": "There is no available number",
    "collection_report": "Collection report",
    "backoffice": "retool backoffice",
    "accounting_service": "Green Invoice morning",
    "maintenance_service": "Maintenance service",
    "bank_deposit": "Bank deposit",
    "bllink_charges": "Bllink charges",
    "bllink_cheques": "Checks",
    "bllink_bank_wire": "Bank Wire",
    "manual_cheques": "Manual checks",
    "digital_cheuqes": "Digital checks",
    "createdAtDate": "created At {{date}}",
    "updatedAtDate": "updated At {{date}}",
    "updatedBy": "updated By {{updatedBy}}",
    "deposited_cheques": "Deposited checks",
    "apt_num": "Apt num",
    "bank_code": "Bank code",
    "check_number": "Check number",
    "account": "Account",
    "expire_date": "Expire date",
    "card_type": "Card type",
    "all_statuses": "All statuses",
    "total_tenants": "Total tenants",
    "for_clarification": "Payments for clarification",
    "failed_transactions": "Failed transactions",
    "update_credit_card": "Update credit card details",
    "wire_confirmation_num": "Wire confirmation num",
    "reasons": {
        "MUST_BE_PENDING": "Payment must be pending",
        "MUST_BE_OFFLINE": "Payment must be offline",
    },
    "yearly_collection_report": "Yearly collection report",
    "special": "Special",
    "charged": "Charged",
    "debt": "Debt",
    "future": "Future",
    "collection_open_confirmation": "Open confirmation page",
    "collection_refund": "Refund",
    "collection_cancel": "Cancel",
    "expenses_deposit_date": "Deposit date",
    "credit_date": "Credit date",
    "pdf": "PDF",
    "excel": "Excel",
    "csv": "CSV",
    "print": "Print",
    "send": "Send report",
    "sendReportModal": {
        "title": "Sending a report to the house committee",
        "set_tenant_as_building_manager": "Update a tenant as the building manager",
        "update_tenant_contact": "Update a tenant as the main contact for the apartment",
        "send_to_email": "Send to email",
        "freeze_building_title": "In order to close a building, the House Committee is required there for the purpose of investigation in the event of future transaction denials",
        "freeze_building": "confirm freeze building",
        "freeze_building_success": "Freeze building Successfully"
    },
    "deposited_date_selection_modal": {
        "title": "Selection of deposit date",
    },
    "total_needed_tooltip": "Amount planned for collection in the date range",
    "total_needed_tooltip_expenses": "Amount planned for expenses in the date range",
    "total_charged_tooltip": "Charges deposited to the bank in the selected period (including payment methods)",
    "total_charged_tooltip_expenses": "Expenses from the bank in the selected period (including payment methods)",
    "balance_diff_tooltip": "The difference does not refer to payments collected outside the date range",
    "balance_diff_tooltip_expenses": "Waiting for payment",
    "pdf_note": "The list of tenants is attached to this report",
    "special_debt": "Special debt",
    "details": "Details",
    "planned": "Planned",
    "fileName": "File name",
    "fileType": "File type",
    "uploadDate": "Upload date",
    "uploadedBy": "Uploaded by",
    "size": "Size",
    "addFile": "Add file",
    "ran_into_problem": "Oops... We ran into a problem",
    "uploaded_file_not_identified": "We could not identify the uploaded file. Our team may need to check the file",
    "mark_transactions_manually": "Until then... the transactions that are entered in Blink can be marked and adjusted manually",
    "understood": "I understood, thank you",
    "deposit": "Deposit",
    "deductions": "Deductions",
    "wireAwaitingApproval": "Awaiting approval",
    "wireApproved": "Approved",
    "fileUploaded": "Your file was uploaded. You'll be redirected to the manual update tab to continue",
    "totalOffsets": "Total offsets",
    "moreDetails": "More details",
    "charges": "Charges",
    "awaitingApproval": "Awaiting final approval",
    "sentFor": "Sent For",
    "nonRefundable": "cannot refund with this payment method",
    "producedByBllink": "This document was produced using Bllink system",
    "issueDate": "Issue date:",
    "noDataAvailable": "No data available",
    "lastTurn": "Last turn",
    "messageType": "Message type",
    "nextFace": "Next face in",
    "removeFromCampaign": "Remove from campaign",
    "addToCampaign": "Add to campaign",
    "markedApartments": "Marked Apartments",
    "payments": "Payments",
    "tenants": "Tenants",
    "tenant": "Tenant",
    "history": "History",
    "card": "Card",
    "previousTenants": "Previous tenants",
    "mainTenants": "Main tenants",
    "managerNotes": "Manager",
    "bllinkNotes": "Bllink",
    "downloadInvoice": "Download receipt",
    "downloadInvoiceError": "Unable download receipt",
    "refundPayment": "Making a credit",
    "stopPlan": "Cancellation of future payment",
    "deletePlan": "Deletion of payment records",
    "noPartnerIDError": "Partner ID isn't provided",
    "installmentDeal": "installment deal",
    "apartment_report_link": "Link to report on an apartment",
    "status": {
        "awaiting_withdrawal": "Awaiting withdrawal",
        "pending_approval": "Pending approval",
        "approved": "Transferred To Vendor",
        "pulled_from_building": "Charged successfully",
        "failed": "Failed",
    },
    "collection_total_tooltip": {
        "total_transferred": "Total transferred",
        "total_refunded": "Total refunded",
        "building_commission": "Building commission",
        "management_commission": "Management Commission",
        "revenue": "other",
        "deductions": "Deductions",
    },
    "tenant_labels": {
        "name": "Name:",
        "phone": "Phone:",
        "email": "Email:"
    },
};
