export class GraphqlApi {

    /**
     * @template T
     * @typedef {
     *     data: T;
     *     error: any;
     * } GraphQLResponse
     * */

    #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} query
     * */
    query(query, variables) {
        return this.#client.post('/graphql', { query, variables });
    }

    /**
     * @param {string} mutation
     * @param {object} variables
     * */
    mutation(mutation, variables = {}) {
        return this.#client.post('/graphql', { query: mutation, variables });
    }
}
