/**
 * Warning this function is not efficient, need optimize before work with large data
 * @param {string} text
 * @param {'sha-1' | 'SHA-256' | 'SHA-384' | 'SHA-512'} [algorithm='sha-1'] see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 * @return {Promise<string>} hex-string
 * */
export async function hash(text, algorithm = 'sha-1') {
    const te = new TextEncoder();
    const bufferHash = await crypto.subtle.digest(algorithm, te.encode(text));
    const uintHash = new Uint8Array(bufferHash);
    const hash = Array.from(uintHash).map(b => b.toString(16).padStart(2, '0')).join('');
    return hash;
}
