import { constants } from "../constants/constants";
import {BllinkLogger} from "./bllink_loggers";


export default class Url{
    static detectPageName(pageUrl){
        /*
            /payment-details/rXJPwyKJc -> payment-details
         */

        let pattern;
        for (const [urlPatternName, urlPattern] of Object.entries(constants.pages)){
            if (pageUrl.includes(urlPattern)){
                BllinkLogger.info(`detected urlPattern ${urlPattern} for pageUrl ${pageUrl}`)
                pattern = urlPatternName
                return pattern
            }
        }

        if (!pattern){
            // ReactGA.pageview(`unknown_${pageUrl}`);
        }

        BllinkLogger.info(`will return ${pattern}`);
        return pattern
    }

    static reportPageToGoogleAnalytics(pathname, action){
        /*
            actual sending to google
            sending both raw and parsed url
         */
        BllinkLogger.info(`reporting to google analytics ${pathname} 
                    location.pathname ${pathname}  action ${action}`);
        const pageName = this.detectPageName(pathname);
        if (pageName){
            BllinkLogger.info(`detected ${pageName}`)
            // hiding for now since upgrading to ga4 ReactGA.pageview doenst work
            // ReactGA.set({page: pageName}); // Update the user's current page
            // ReactGA.pageview(pageName); // Record a pageview for the given page
            BllinkLogger.info(`reported specific page ${pageName}`);
            this.reportToSegment(pageName);
        }

        // sending both for now. we'll need to choose one or the other once we'll decide which is better
        // ReactGA.set({page: pathname}); // Update the user's current page
        // ReactGA.pageview(pathname); // Record a pageview for the given page
        BllinkLogger.info(`reported raw ${pathname} to analytics `)

    }

    static reportToSegment(pageName){
        if(window.analytics){
            if(pageName){
                window.analytics.page(pageName);
            }else{
                window.analytics.page();
            }
        }
    }
}
