import { constants } from "../../constants/constants";
import {lazyReload} from "../../lazyReload";

const BuildingPaymentChangeFavicon = lazyReload(() => import("../../../tenants/BuildingPaymentChangeFavicon"));
const PaymentsLastCheckoutPage = lazyReload(() => import("../../../tenants/tenant_payments/paymentLastCheckoutPage"));
const SelectPayments = lazyReload(() => import("../../../tenants/tenant_payments/selectPayments"));
const CreditCard = lazyReload(() => import("../../../tenants/credit_card/CreditCard"));
const TenantDetails = lazyReload(() => import("../../../tenants/tenant_details/TenantDetails"));
const ReportCollection = lazyReload(() => import("../../../tenants/buildingPayment/ReportCollection"));
const RapydCheckoutPage = lazyReload(() => import("../../../tenants/rapyd_checkout_page/RapydCheckoutPage"));
const RapydIframeStub = lazyReload(() => import("../../../tenants/rapyd_checkout_page/RapydIframeStub"));

const ManagerDetails = lazyReload(() => import("../../../tenants/manager_details/ManagerDetails"));

const TenantMaintenance = lazyReload(() => import('../../../tenants/maintenance/TenantMaintenance'));
const ThankYouPage = lazyReload(() => import("../../../tenants/maintenance/ThankYouPage"));

const TestPage = lazyReload(() => import("../../testPage"));

// new flow
const BuildingPaymentNew = lazyReload(() => import("../../../tenants/buildingPayment/BuildingPayment"));
const TenantDetailsNew = lazyReload(() => import("../../../tenants/buildingPayment/TenantDetails"));
const PaymentPage = lazyReload(() => import("../../../tenants/buildingPayment/paymentPage/PaymentPage"));
const CheckoutPage = lazyReload(() => import("../../../tenants/buildingPayment/checkoutPage/CheckoutPage"));
const ChoosePaymentMethod = lazyReload(() => import("../../../tenants/buildingPayment/ChoosePaymentMethod"));
const BankInfo = lazyReload(() => import("../../../tenants/buildingPayment/BankInfo"));
const CreditCardPage = lazyReload(() => import("../../../tenants/buildingPayment/creditCardPage/CreditCardPage"));
const BankPage = lazyReload(() => import("../../../tenants/buildingPayment/bankPage/BankPage"));
const BankChargeApplicationPage = lazyReload(() => import("../../../tenants/buildingPayment/bankPage/BankChargeApplicationPage"));
const ChooseBankChargeMethod = lazyReload(() => import("../../../tenants/buildingPayment/bankPage/ChooseBankChargeMethod"));
const DirectTransfer = lazyReload(() => import("../../../tenants/buildingPayment/bankPage/DirectTransfer"));
const LearnMore = lazyReload(() => import("../../../tenants/buildingPayment/LearnMore"));
const MobilePaymentPage = lazyReload(() => import("../../../tenants/buildingPayment/MobilePaymentPage"));
const InsuranceOfferPage = lazyReload(() => import("../../../tenants/buildingPayment/InsuranceOfferPage"));
const StorePage = lazyReload(() => import("../../../tenants/store/storePage"));
const StoreItemPage = lazyReload(() => import("../../../tenants/store/storeItemPage"));
const StoreSuccessRequestPage = lazyReload(() => import("../../../tenants/store/storeSuccessRequestPage"));

export const TenantPaymentsRoutes = [
    // temporary url to open the new building page only for a demo building
    {path: `/${constants.pages.buildingsUrl}/:buildingID`, component: BuildingPaymentNew, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.managerDetails}/:buildingID`, component: ManagerDetails, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.choosePaymentMethod}/:buildingID`, component: ChoosePaymentMethod, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.bankPaymentInfo}/:buildingID`, component: BankInfo, exact: true, removeHeader: true, noFooter: true},
    {path: `/${constants.pages.tenantDetailsUrl}-new/:buildingID`, component: TenantDetailsNew, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.maintenanceTenantUrl}/:buildingID`, component: TenantMaintenance, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.paymentSelectionUrl}-new/:buildingID/:apartmentNumber`, component: PaymentPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.collectionDate}/:buildingID`, component: ReportCollection, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.lastConfirmPaymentPageUrl}-new/:buildingID/:apartmentNumber`, component: CheckoutPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.chooseBankMethod}/:buildingID/:apartmentNumber`, component: ChooseBankChargeMethod, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.directTransfer}/:buildingID`, component: DirectTransfer, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.bankPage}/:buildingID`, component: BankPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.bankPageApplication}/:buildingID`, component: BankChargeApplicationPage, exact: true, removeHeader: true, newFlowFooter: true}, // TODO: route raw render
    {path: `/${constants.pages.paymentDetailsUrl}/:buildingID/:cartID`, component: CreditCardPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.mobilePaymentPage}/:paymentMethod/:buildingID/:cartID`, component: MobilePaymentPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.learnMore}/:buildingID`, component: LearnMore, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.insuranceOffer}/:buildingID/:apartmentNumber/:confirmationID`, component: InsuranceOfferPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.insuranceOffer}-new/:buildingID/:apartmentNumber/:confirmationID`, component: InsuranceOfferPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.additionalOffer}/:buildingID/:apartmentNumber/:confirmationID`, component: InsuranceOfferPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.additionalOfferFlowers}/:buildingID/:apartmentNumber/:confirmationID`, component: InsuranceOfferPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.adsFollowUp}/:buildingID/:apartmentNumber/:confirmationID`, component: InsuranceOfferPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.store}/:buildingID/`, component: StorePage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.store}/:buildingID/:storeItemId`, component: StoreItemPage, exact: true, removeHeader: true, newFlowFooter: true},
    {path: `/${constants.pages.storeRequestSuccess}/:buildingID/`, component: StoreSuccessRequestPage, exact: true, removeHeader: true, newFlowFooter: true},

    // {path: `/${constants.pages.buildingsUrl}/:buildingID`, component: BuildingPayment, exact: true},
    {path: `/${constants.pages.buildingsUrl}-favicon/:buildingID/`, component: BuildingPaymentChangeFavicon, exact: true},
    {path: `/${constants.pages.tenantDetailsUrl}/:buildingID`, component: TenantDetails, exact: true},
    {path: `/${constants.shortUrls.tenantDetailsUrl2}/:buildingID`, component: TenantDetails, exact: true},
    {path: `/${constants.pages.paymentSelectionUrl}/:buildingID/:apartmentNumber`, component: SelectPayments, exact: true},
    {path: `/${constants.pages.lastConfirmPaymentPageUrl}/:buildingID/:apartmentNumber`, component: PaymentsLastCheckoutPage, exact: true},
    {path: `/${constants.pages.paymentDetailsUrl}/:cartID`, component: CreditCard, exact: true},
    {path: `/${constants.pages.paymentDetailsRapydUrl}/:cartID`, component: RapydCheckoutPage, exact: true},
    {path: `/${constants.pages.paymentDetailsRapydStub}`, component: RapydIframeStub, exact: true},
    {path: `/${constants.pages.test}`, component: TestPage, exact: true},
    {path: `/${constants.pages.confirmationPage}/:buildingID`, component: ThankYouPage, exact: true, removeHeader: true, newFlowFooter: true},

]


