import styled from 'styled-components';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import QRCode from 'react-qr-code';
import { constants } from "../constants/constants";
import {hideLoader, showLoader} from '../../store/common/slice';
import {createPdfFromHtml, getBase64} from '../shared/statelessMethods';
import {GAEvent} from "../google_analytics/google_analytics";

const StyledBrochure = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  max-width: 1100px;
  height: 1400px;
  direction: rtl;
  text-align: right;
  opacity: 0;
  background-color: #fff;
  transform: scale(1.15);
  .bg-small {
    position: fixed;
    width: 150px;
    top: 50px;
    left: 80%;
    z-index: -1;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .bg-big {
    position: absolute;
    width: 440px;
    top: 100px;
    left: -200px;
    z-index: -1;
  }
  .bg-phone {
    position: absolute;
    width: 220px;
    right: -80px;
    top: 400px;
    z-index: -1;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .main-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    .phone {
      position: relative;
      max-width: 400px;
      margin-right: 30px;
      img:not(.bg-phone) {
        width: 100%;
        height: auto;
      }
    }
    .text {
      min-width: 500px;
    }
    .fast-pay {
      font-size: 26px;
      text-align: right;
    }
    .features {
      padding: 30px 0;
      width: 100%;
      li {
        display: flex;
        align-items: center;
        font-weight: bold;
        width: 100%;
        span {
          display: block;
          width: 100%;
        }
        &:not(:last-child) {
          margin-bottom: 50px;
        }
        img {
          max-width: 60px;
          margin-left: 25px;
        }
      }
    }
  }
  .brochure-wrapper {
    position: relative;
    width: 100%;
    padding: 40px;
    font-family: var(--bllink-font-primary);
    font-size: 22px;
    background-color: #fff;
  }
  .logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .empty {
      display: none;
    }
  }
  .logo {
    width: 250px;
    &.partner {
      width: 150px;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  h1 {
    text-align: right;
    font-size: 3.5rem;
    font-weight: bold;
    margin-bottom: 60px;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .qr-block {
    margin-right: 15px;
  }
  .bottom-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 150px;
      width: calc(100% - 165px);
    }
  }
  .contacts {
    li {
      display: inline-block;
      font-weight: bold;
      padding: 0 25px;
      min-width: 150px;
      &:not(:last-child) {
        border-left: 3px solid #212529;
      }
      &:first-child {
        padding-right: 0;
      }
    }
  }
`;

export default function BuildingBrochure({buildingID, hideContainer}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const container = useRef();
    const [partnerLogo, setPartnerLogo] = useState('');
    const [images, setImages] = useState({});
    const userProfile = useSelector(state => state.auth.userProfile);

    useEffect(async () => {
        if (Object.keys(images).length === 0) {
            // load dynamically to exclude from common bundle
            const module = await import('../shared/brochure_images');
            setImages(module);
        }
    });

    useEffect(async () => {
            dispatch(showLoader());
            if (userProfile.userFirstName && Object.keys(images).length > 0) {
                const image = await getBase64(userProfile.partnerLogo || '');
                setPartnerLogo(`${image ? `data:image/png;base64,${image}` : ''}`);

                const fileName = `${t('brochure.fileName')}_${buildingID}`;
                await createPdfFromHtml(container.current, fileName);
                GAEvent(constants.GoogleAnalytics.Events.managerOperations, 'BuildingBrochure', 1, 'downloadBrochure');
                hideContainer();
            }
            dispatch(hideLoader());
    }, [userProfile, images]);

    return (
        <StyledBrochure className="building-brochure">
            <div className='brochure-wrapper' ref={container}>
                <div className='logos'>
                    <div className={`logo partner ${partnerLogo ? '' : 'empty'}`}>
                        <img src={partnerLogo} alt="partner logo" />
                    </div>
                    <div className='logo'>
                        <img src={images.logoBase64} alt="company logo" />
                    </div>
                </div>
                <h1>{t('brochure.title')}</h1>

                <div className="bg">
                    <img className="bg-small" src={images.background} alt="bg small" />
                    <img className="bg-big" src={images.background} alt="bg big" />
                </div>


                <div className='main-section'>
                    <div className='text'>
                        <h3>
                            {t('brochure.subtitle')}
                        </h3>
                        <p className="fast-pay">{t('brochure.fast_pay')}</p>
                        {/*<p>{t('brochure.fast_pay_1')}</p>*/}
                        {/*<p>{t('brochure.fast_pay_2')}</p>*/}
                        {/*<p>{t('brochure.fast_pay_3')}</p>*/}
                        {/*<p>{t('brochure.fast_pay_4')}</p>*/}

                        <ul className='features'>
                            <li>
                                <img src={images.creditCard} alt="credit card" />
                                <span>{t('brochure.card')}</span>
                            </li>
                            <li>
                                <img src={images.bank} alt="wire" />
                                <span>{t('brochure.wire')}</span>
                            </li>
                            <li>
                                <img src={images.invoice} alt="invoice" />
                                <span>{t('brochure.invoice')}</span>
                            </li>
                            <li>
                                <img src={images.reminder} alt="reminder" />
                                <span>{t('brochure.reminders')}</span>
                            </li>
                            <li>
                                <img src={images.safe} alt="pci" />
                                <span>{t('brochure.pci')}</span>
                            </li>
                        </ul>
                    </div>
                    <div className='phone'>
                        <img src={images.phone} alt="phone" />
                        <img className="bg-phone" src={images.background} alt="phone bg" />
                    </div>
                </div>

                <div className="bottom-block">
                    <div className='text'>
                        <h3>{t('brochure.qr_title')}</h3>
                        <ul className="contacts">
                            <li>03-5090045</li>
                            <li>www.bllink.co</li>
                            <li>support@bllink.co</li>
                        </ul>
                    </div>
                    <div className='qr-block'>
                        <QRCode
                            value={`https://app.bllink.co/${constants.pages.buildingsUrl}/${buildingID}`}
                            size={160}
                            level="H"
                        />
                    </div>
                </div>
            </div>
        </StyledBrochure>
    );
}
