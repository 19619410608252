import { useTranslation } from "react-i18next";
import { Fragment, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import maintenance from "../../../images/newDesign/maintenance.svg";
import { partnerMaintenanceTitlesTranslations } from "../../sidebar/partnerMaintenanceTitlesTranslations";
import { constants } from "../../../common/constants/constants";
import { toggleMobileMenuOpened } from "../../../store/common/slice";
import CacheData from "../../../common/utils/cacheData";
import { SideMenuItem } from "../sideMenu/sideMenuItem";
import { SideMenuTitle } from "../sideMenu/sideMenuTitle";
import { SideMenu } from "../sideMenu/sideMenu";
import {isMobile} from "react-device-detect";
import {hasPremiumAccessSelector, isAdminSelector} from "../../../store/auth/selectors";
import {SideMenuMainLink} from "../sideMenu/sideMenuMainLink";

/**
 * @param buildingId
 * @return {JSX.Element|null}
 * @constructor
 */
export default function MaintenanceSidebarMenu({ buildingId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userProfile = useSelector((state) => state.auth.userProfile);
    const currentBuildingData = useSelector(state => state.buildings.currentBuildingData);
    const managerAllBuildings = useSelector(state => state.manager.buildings?.buildings ?? []);
    const hasMaintenanceBuildings = useMemo(() => managerAllBuildings.some(b => b.maintenanceEnabled), [managerAllBuildings]);
    const isPremiumUser = useSelector(hasPremiumAccessSelector);
    const isAdmin = useSelector(isAdminSelector);

    const localBuildingId = useSelector(state => buildingId ?? state.buildings?.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const userPartnerId = userProfile?.partnerID;
    const buildingPartnerId = currentBuildingData?.currentBuildingData?.managementCompany?.externalId;
    const partnerId = buildingPartnerId || userPartnerId;
    const shouldUsePartner = partnerId && partnerId !== 'private';

    const shouldNotShow = useMemo(() => {
        return !hasMaintenanceBuildings || (!shouldUsePartner && !localBuildingId)
    }, [hasMaintenanceBuildings, shouldUsePartner, localBuildingId])

    const entityType = shouldUsePartner ? 'partner' : 'building';
    const entityValue = shouldUsePartner ? partnerId : localBuildingId;
    const menuRef = useRef();
    const sideMenuRef = useRef();
    const baseUrl = `/${constants.pages.maintenanceLivyUrl}/${entityType}/${entityValue}`;

    const onItemClick = () => {
        dispatch(toggleMobileMenuOpened());
        sideMenuRef.current.close();
    }

    return shouldNotShow ? null : (
        <Fragment>
            <SideMenuMainLink
                icon={<img src={maintenance} alt="maintenance"/>}
                menuRef={menuRef}
                title={t(partnerMaintenanceTitlesTranslations.management)}
                disabled={!isPremiumUser}
                tooltip={t('buildings.upgrade_to_premium')}
                tooltipDisabled={isPremiumUser}/>
            <SideMenu container={menuRef.current} ref={sideMenuRef}>
                <SideMenuTitle
                    label={t(partnerMaintenanceTitlesTranslations.management)}
                    icon={maintenance}/>
                <SideMenuItem
                    url={`${baseUrl}/faults`}
                    label={t(partnerMaintenanceTitlesTranslations.maintenanceManager)}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/maintenance`}
                    label={isMobile ? t(partnerMaintenanceTitlesTranslations.mobileMaintenance) : t(partnerMaintenanceTitlesTranslations.maintenance)}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/systems`}
                    label={t(partnerMaintenanceTitlesTranslations.systems)}
                    className="mobile-hide"
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/suppliers`}
                    label={t(partnerMaintenanceTitlesTranslations.suppliers)}
                    className="mobile-hide"
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/reports`}
                    label={t(partnerMaintenanceTitlesTranslations.reports)}
                    className="mobile-hide"
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/amenities`}
                    label={t(partnerMaintenanceTitlesTranslations.amenities)}
                    className="mobile-hide"
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/company-users`}
                    label={t(partnerMaintenanceTitlesTranslations['company-users'])}
                    className="mobile-hide"
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`${baseUrl}/polls`}
                    label={t(partnerMaintenanceTitlesTranslations.polls)}
                    className="mobile-hide"
                    onClick={onItemClick}/>
            </SideMenu>
        </Fragment>
    );
}
