
class Strings{

    static numberWithCommas(number){
        // reported by talV via email this output was 616.6,600,000,000,001
        if (!number) return '0';
        // 2 decimal points. after reported by eyal
        number = Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;
        /// 2345 -> 2,345
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    static isValidUrl(string) {
        try {
            new URL(string);
        } catch (_) {
            return false;
        }

        return true;
    }

    static isHtmlCode(string){
        return /<\/?[a-z][\s\S]*>/i.test(string)
    }

    static keepNumbersOnly(string){
        return string.replace(/\D/g,'');
    }

    static removeCommas(string){
        return string.replace(/[ ,.]/g, "");
    }

    /**
     * @param string {string}
     * @return {number}
     */
    static parseNumericStringWithCommasToNumber(string){
        return Number(string.replace(/[ ,]/g, ""));
    }

    static getParameterByName(name, url = window.location.href) {
        console.log(`got name`, name)
        name = name.replace(/[[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }


    static buildApartmentFullTenants(){
        // get renter and owner

    }

    static capitalizeFirstLetter(string){
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static checkReverseCurrencyOrder(currency, t) {
        // TODO: consider to create a component properly formatting text instead of inlining this function
        return currency === t('currency.mxn');
    }

    static sanitizeString(string){
        // remove access spaces and special chars
        // https://sentry.io/organizations/bllink/issues/3764974514/?alert_rule_id=1438500&alert_timestamp=1672298701601&alert_type=email&environment=production&project=5402476&referrer=alert_email
        // ‪mosheyacov108@gmail.com (with special char) to mosheyacov108@gmail.com (withOUT special char)
        console.log(`before sanitation ${string}`);
        const stringNoSpaces = string.trim();
        const stringWithoutSpecialChars = stringNoSpaces.replace(/[^a-zA-Z0-9@.\-_]/g, "");
        console.log(`after sanitation ${stringWithoutSpecialChars}`);
        return stringWithoutSpecialChars

    }
}


export default Strings
