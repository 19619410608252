import {MenuItem} from "react-pro-sidebar";
import {NavLink} from "react-router-dom";
import {Tooltip} from "../../../ui/Tooltip";

/**
 * @param {JSX.Element} icon
 * @param menuRef
 * @param {string | JSX.Element} title
 * @param {boolean} disabled
 * @param {string} tooltip
 * @param {boolean} tooltipDisabled
 * @return {JSX.Element}
 * @constructor
 */
export const SideMenuMainLink = ({icon, menuRef, title, disabled, tooltip, tooltipDisabled = true}) => {
    return <MenuItem icon={icon} ref={disabled ? null : menuRef} className={disabled ? 'disabled' : ''}>
        <Tooltip text={tooltip} disabled={tooltipDisabled}>
            <NavLink className={`dropdown-link`} to={{pathname: ''}}>
                {title}
            </NavLink>
        </Tooltip>
    </MenuItem>
}