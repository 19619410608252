export const autoCharge = {
    "ownerId": "ת״ז בעל\/ת החשבון",
    "continue": "המשך\/י לחתימה דיגיטלית",
    "apartment": "דירה",
    "confirm": "אישור",
    "signature": "חתימה",
    "clear": "אפס טופס",
    "fileName": "_",
    "success": "בקשה נשלחה. אנחנו מטפלים בה ואת\/ה אמור\/ה לקבל אישור תשלום עוד 10 ימים",
    "tenantSuccess" : "הבקשה ממתינה. אישור תשלום יוצג לאחר אישור",
    "failed": "Request failed",
    "back": "חזרה",
    "date": "תאריך לחיוב",
    "title": "מלא\/י פרטים להקמת הוראת קבע לחיוב ישירות מחשבון הבנק",
    "address": "כתובת",
    "name": "שם",
    "phone": "טלפון"
};
