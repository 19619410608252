export class GlobalSearchApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} search
     * @return {Promise<{ records: MoneyMovementRecord[] }>}
     * */
    getGlobalSearchResults(search) {
        return this.#client.get(`/managers/globalSearch`, {search});
    }
}
