import React, {Component} from 'react';
import { Translation } from 'react-i18next';
import Loader from "../../common/simple/loader";
import $ from "jquery";


export class PopUp extends Component {

    async componentDidMount() {
        $(`#general-pop-up`).appendTo('body');
    }

    render() {

        return (
            <Translation>{(t, {_}) =>
                <div className="modal fade form-section" id={`general-pop-up`}>
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content n_content">
                            <div className="modal-header">
                                <h5 className="col-12 modal-title text-center"
                                    id="generalModalLabel">{t('titles.general_pop_up_title')}
                                </h5>
                            </div>
                            <div className="modal-body general-modal-body">
                                <Loader/>
                                    <div id={"general-pop-up-text"}/>
                            </div>
                        </div>
                    </div>
                </div>
            }</Translation>
        )
    }
}

export default PopUp;

