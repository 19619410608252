import React, { Component} from 'react';

export class HealthCheck extends Component {

    render() {
        return(
            <div className='container'>
                success
            </div>
        )
    }

}


export default HealthCheck;

