/**
 * @param {File} file
 * @param {boolean} [asDataUrl=false]
 * @return {Promise<string>}
 * */
export async function fileToBase64(file, asDataUrl = false) {
    return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        reader.addEventListener('load', function() {
            const base64 = asDataUrl ? reader.result : reader.result.slice(reader.result.indexOf(',')+1);
            resolve(base64);
        }, false);
        reader.addEventListener('error', function(event) {
            reject('Unable encode file');
        });
        reader.readAsDataURL(file);
    });
}
