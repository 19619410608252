import { constants } from "../../components/common/constants/constants";

export class UtilsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }


    /**
     * @param {string} endPoint
     * @param {string} query
     * @param {Object} params
     * @return {Promise<{ featureValues: FeatureValue[] }>}
     * */
    postInternalEmail(endPoint, query, params) {
        return this.#client.post(`/tenants/utils/${endPoint}${query}`, params);
    }

    /**
     * @param {{title: string, adID: string|number}} params
     * @return {Promise<{ featureValues: FeatureValue[] }>}
     * */
    sendCustomerInterestedEmail(params) {
        return this.#client.post(`/tenants/utils/sendCustomerInterestedEmail`, params);
    }

    /**
     * @typedef {{
     *     sent: boolean,
     * }} sendReportByEmailResponce
     */

    /**
     * @param {string} file
     * @param {string} sendTo
     * @param {string} fileName
     * @param {string} subject
     * @param {string} type
     * @param {SelectionData} data
     * @return Promise<sendReportByEmailResponce>
     * */
    sendReportByEmail(file, sendTo, fileName, subject, type, data) {
        return this.#client.post(`/tenants/utils/${constants.backendEndpoints.SEND_REPORT_BY_EMAIL}`, {file, sendTo, fileName, subject, type, data});
    }
}
