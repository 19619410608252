import { useState, useEffect } from 'react';

// TODO: replace by useDeferredValue once the project will be updated to react 18

/**
 * @param {any} value
 * @param {number} [delay=300] delay in ms
 * */
export function useDebounce(value, delay = 300) {

    const [debouncedValue, setDebouncedValue] = useState(value);
    const [handler, setHandler] = useState(/** @type {number | undefined} */undefined);

    useEffect(() => {
        window.clearTimeout(handler);
        const h = window.setTimeout(() => setDebouncedValue(value), delay);
        setHandler(h);
        return () => window.clearTimeout(handler);
    }, [value]);

    return debouncedValue;
}
