import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import DragAndDrop from '../../common/simple/dragAndDrop';

export default function PaymentUpload({handleFileAdd}) {
    const {t} = useTranslation();
    const inputFile = useRef(null);

    return (
        <>
            <input
                type="file"
                ref={inputFile}
                accept=".pdf"
                style={{display: 'none'}}
                onChange={evt => handleFileAdd(evt, evt.target.files)}
            />
            <DragAndDrop handleDrop={evt => handleFileAdd(evt, evt.target.dataTransfer)}>
                <div className={"container drag-to-upload-container"} onClick={() => inputFile.current.click()}>
                    <div className={"drag-to-upload bllink-font"}>
                        <p>{t('payment_settings.dragOrClickToUpload')}</p>
                    </div>
                </div>
            </DragAndDrop>
        </>
    );
}
