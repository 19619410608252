// https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929

import { createRef } from 'react'
import {BllinkLogger} from "../utils/bllink_loggers";

export default function DragAndDrop({handleDrop, children}) {
    const dropRef = createRef();

    const genericHandle = (e) => {
        BllinkLogger.info(`got event `, e);
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDrag = (e) => {genericHandle(e)}
    const handleDragIn = (e) => {genericHandle(e)}
    const handleDragOut = (e) => {genericHandle(e)}
    const onDrop = (e) => {
        genericHandle(e);
        handleDrop(e);
    }

    return (
        <div
            ref={dropRef}
            className={'drag-and-drop bllink-text'}
            onDragEnter={handleDragIn}
            onDragLeave={handleDragOut}
            onDragOver={handleDrag}
            onDrop={onDrop}
        >
            {children}
        </div>
    );
}
