import { constants } from '../../constants/constants';
import {lazyReload} from "../../lazyReload";
const RewardsNew = lazyReload(() => import( '../../../managers/rewards/RewardsNew'));
const Notifications = lazyReload(() => import('../../../managers/notifications/Notifications'));
const OrderStatusPage = lazyReload(() => import('../../order_status/orderStatusPage'));
const TenantList = lazyReload(() => import('../../../managers/reports/tenant_list/tenantList'));
const TenantListNew = lazyReload(() => import('../../../managers/reports/tenantList/index'));
const TenantListApartmentPage = lazyReload(() => import('../../../managers/reports/tenantList/ApartmentPage'));
const AllBuildings = lazyReload(() => import('../../../managers/all_buildings/index'));
const Maintenance = lazyReload(() => import('../../../managers/maintenace/index'));
const ArchivePage = lazyReload(() => import('../../../managers/archivePage/ArchivePage'));
const PdfTableReportRender = lazyReload(() => import('../../export_documents/PdfTableReportRender'));
const ProductsAtDiscount = lazyReload(() => import("../../../managers/productsAtDiscount/productsAtDiscount"));
const FundsEarlyWithdrawal = lazyReload(() => import("../../../managers/fundsEarlyWithdrawal/fundsEarlyWithrawal"));
const ExperimentInsurance = lazyReload(() => import("../../../managers/fundsEarlyWithdrawal/experimentInsurance"));
const ManualComponent = lazyReload(() => import("../../manual/manual"));
const MarketPlace = lazyReload(() => import("../../../managers/marketPlace/MarketPlace"));
const LeadPage = lazyReload(() => import("../../../managers/lead/leadPage"));
const PremiumInfo = lazyReload(() => import("../../../managers/premiumInfo/premiumInfo"));
const PaymentDetails = lazyReload(() => import("../../../managers/partnerPaymentDetails/partnerPaymentDetails"));
const UserDetails = lazyReload(() => import("../../../common/shared/userDetails"));
const ManagerChat = lazyReload(() => import('../../../managers/chat/index'));
const StorePage = lazyReload(() => import('../../../tenants/store/storePage'));
const StoreItemPage = lazyReload(() => import("../../../tenants/store/storeItemPage"));
const StoreSuccessRequestPage = lazyReload(() => import("../../../tenants/store/storeSuccessRequestPage"));


export const ManagerGeneral = [
    {path: `/${constants.pages.allBuildingsUrl}`, component: AllBuildings, exact: true},
    {path: `/${constants.pages.rewards}`, component: RewardsNew, exact: true},
    {path: `/${constants.pages.marketPlace}`, component: MarketPlace, exact: true},
    {path: `/${constants.pages.notifications}`, component: Notifications, exact: true},
    {path: `/${constants.pages.productsAtDiscountUrl}`, component: ProductsAtDiscount, exact: true},
    {path: `/${constants.pages.fundsEarlyTransferUrl}`, component: FundsEarlyWithdrawal, exact: true},
    {path: `/${constants.pages.insuranceOfferUrl}`, component: ExperimentInsurance, exact: true},
    {path: `/${constants.pages.orderStatusPage}/:cartID`, component: OrderStatusPage, exact: true},
    {path: `/${constants.pages.tenantListUrl}/:buildingID`, component: TenantListNew, exact: true}, // TODO remove old tenant list component
    {path: `/${constants.pages.tenantListUrl}/:buildingID/:apartmentNumber/:section?`, component: TenantListApartmentPage, exact: true}, // TODO remove old tenant list component
    {path: `/${constants.pages.tenantListUrl}-old/:buildingID`, component: TenantList, exact: true}, // TODO remove old tenant list component
    {path: `/${constants.pages.maintenanceLivyUrl}/:entityType/:entityValue/:pageName`, component: Maintenance, exact: true},
    {path: `/${constants.pages.archivePage}/:buildingID`, component: ArchivePage, exact: true},
    {path: `/${constants.pages.pdfRendererUrl}/:partnerID?`, component: PdfTableReportRender},
    {path: `/building/:buildingID/${constants.pages.bankChargeUrl}`, component: ManualComponent, exact: true, props: { mode: 'building' }},
    {path: `/${constants.pages.partnerLeadPage}/:partnerID`, component: LeadPage, exact: true},
    {path: `/${constants.pages.premiumInfoPage}`, component: PremiumInfo, exact: true},
    {path: `/${constants.pages.partnerProfilePage}/:partnerID`, component: PaymentDetails, exact: true},
    {path: `/${constants.pages.managersChat}/:entityType/:entityValue`, component: ManagerChat, exact: true},
    {path: `/${constants.pages.userDetailsPage}`, component: UserDetails, exact: true},
    {path: `/${constants.pages.serviceProviders}`, component: StorePage, exact: true},
    {path: `/${constants.pages.serviceProviders}/:storeItemId`, component: StoreItemPage, exact: true},
    {path: `/${constants.pages.serviceProviders}/success`, component: StoreSuccessRequestPage, exact: true},
]
