import $ from "jquery";
import {BllinkLogger} from "./bllink_loggers";
const randomstring = require("randomstring");
const {parsePhoneNumber} = require("libphonenumber-js");

class JsExtras {

    static linkifyWithCustomText(text, linkText) {
        // Regular expression to find URLs
        const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;

        // Replace URLs with anchor tags and custom link text
        return text.replace(urlPattern, `<a href="$1" target="_blank">${linkText || 'link'}</a>`);
    }


    static getPhoneNumberWithCountryExtension(phoneNumber){

        let fullPhoneNumber;
        try {
            // todo avoid hard coded IL. maybe auto detect by the user's locale? or auto detect prefix
            // iL is just the detault. it knows how to handle other countries for example
            //  parsePhoneNumber('+1 (213) 540-7202', 'IL').number; will get +12135407202
            fullPhoneNumber = parsePhoneNumber(phoneNumber, 'IL').number;
            return fullPhoneNumber
        }catch (e){
            // todo add an alert
            console.error(`PHONE_NUMBER_NOT_VALID ${phoneNumber}`);
            return false
        }
    }

    static generateUniqueConsistentID(){
        // not so consistent but close enough

        let navigator_info = window.navigator;
        let screen_info = window.screen;
        let uid = navigator_info.mimeTypes.length;
        uid += navigator_info.userAgent.replace(/\D+/g, '');
        uid += navigator_info.plugins.length;
        uid += screen_info.height || '';
        uid += screen_info.width || '';
        uid += screen_info.pixelDepth || '';

        return uid;
    }

    static todayTimeStamp() {
        const date = new Date();

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}_${month}_${day}`;

    }

    static randomString(length){
        return randomstring.generate({
            length: length || 6,
            charset: 'alphabetic',
            capitalization: 'lowercase'
        });
    }

    static getRange(maxInt) {
        // input 10 => will return array [1,2,..10]

        return Array.from({length: maxInt}, (_, i) => i + 1)

    }

    static range(minInt, range) {
        // input 10, 5 => will return array [10,11,12,13,14,15]

        return Array.from({length: range}, (_, i) => i + minInt)

    }

    static getUrlParameter(sParam) {
        let sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    }

    static sum(array, key){
        return array.reduce((a, b) => a + (parseFloat(this.getValue(b, key)) || 0), 0);
    }

    static getValue(item, key){
        // key examples: 'bla', 'foo.bla'

        const parts = key.split('.');

        let itemFinal = item;
        for (const part of parts){
            itemFinal = itemFinal[part];
            if (!itemFinal){
                return
            }
        }

        return itemFinal
    }

    static validate(obj, keys){
        /*
            created to validate object
            was create to avoid future bugs like this one https://app.asana.com/0/1196545425570329/1198912119756103  [Bug] final page before cc - installments
         */

        const objectKeys = Object.keys(obj);
        let missingKeys = [];
        for (const requiredKey of keys){
            if (!objectKeys.includes(requiredKey) || !obj[requiredKey]){
                missingKeys.push(requiredKey)
            }
        }

        if (missingKeys.length>0){
            throw new Error(`required keys ${JSON.stringify(missingKeys)} are missing from object ${JSON.stringify(obj)}`)
        }
    }

    static validURL(str) {
        let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    static isNumeric(str) {
        if (!["string", "number"].includes(typeof str)) return false // we only process strings!
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    static preventTextInPhoneInput(event){
        /*
            https://app.asana.com/0/1196545425570329/1198875635740805
            don't allow type letter in the phone field
         */
        console.log(`keypress`, event)
        let regex = new RegExp("^[0-9]+$");
        let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    }

    static phoneValidator(element, elementValue, options){
        console.log(`phone validating`, element);
        let afterRemovingDashes = elementValue.replace(/-/g, '').trim();
        let afterReplacePrefix = afterRemovingDashes.replace(/\+972/g, '0').trim();
        BllinkLogger.info(`afterReplacePrefix`, afterReplacePrefix);

        let len = afterReplacePrefix.length
        // requested to support 9 and 10 by rayee on email on 21/7/21
        let minLength = options.minLength || 10;
        let maxLength = options.maxLength || 10;
        const isValid = (len >= minLength) && (len <= maxLength)
        $('#phone').val(afterReplacePrefix);
        return isValid
    }

    static emailValidator(email){
        if (!email){
            return true
        }
        //    https://stackoverflow.com/a/9082446/13806459
        let emailReg = /^([\w-.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        return emailReg.test( email );
    }

    /**
     * @name ApartmentForSorting
     * @typedef {{
     *   apartmentNum?: string;
     * }}
     * */

    static startsWithNumber(str) {
        return /^[0-9]/.test(str);
    }

    static extractNumber(str) {
        if (!str) return '';

        const match = str.match(/(\d+)/);
        return match ? parseInt(match[0], 10) : null;
    }


    // created according to ticket https://bllink.atlassian.net/browse/SSB-1421
    /**
     * @param {ApartmentForSorting[]} aptArray
     * @return {ApartmentForSorting[]}
     */
    static sortApartments(aptArray) {
        return aptArray.sort((apt1, apt2) => {
            const a = apt1.apartmentNum;
            const b = apt2.apartmentNum;
            let aNum = Number(a);
            let bNum = Number(b);
            const aShouldBeConsideredANumber = isNaN(aNum) || this.startsWithNumber(a);
            const aAsANumber = aShouldBeConsideredANumber && this.extractNumber(a);
            const bShouldBeConsideredANumber = isNaN(bNum) || this.startsWithNumber(b);
            const bAsANumber = bShouldBeConsideredANumber && this.extractNumber(b);

            // Both a and b are numbers.
            if (aShouldBeConsideredANumber && bShouldBeConsideredANumber) {
                return aAsANumber - bAsANumber;
            }

            // a is a number, b is not.
            if (!aShouldBeConsideredANumber && !bShouldBeConsideredANumber) {
                return -1;
            }

            // b is a number, a is not.
            if (!aShouldBeConsideredANumber && bShouldBeConsideredANumber) {
                return 1;
            }

            // Neither a nor b are numbers.
            if (!aShouldBeConsideredANumber && !bShouldBeConsideredANumber) {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            }
            return 0;
        });
    }

    static getRandomBoolean(){
        // 50% chance -> [0, 0.5) , [0.5, 1)
        return Math.random() < 0.5;
    }

    static randomBasedOnPercentage(percentage) {
        // Generate a random number between 0 and 1
        let randomNumber = Math.random();

        // Compare the random number with the percentage
        // If the random number is less than the percentage (as a proportion),
        // return 1, otherwise return 0
        return randomNumber < (percentage / 100) ? 1 : 0;
        // Example usage
        // console.log(randomBasedOnPercentage(10)); // 10% chance to log 1 and 90% chance to log 0
        // console.log(randomBasedOnPercentage(50)); // 50% chance for each
        // console.log(randomBasedOnPercentage(90)); // 90% chance to log 1 and 10% chance to log 0

    }



    static addSkipMessageEvent(successCallback) {
        if (window.location.origin.includes('localhost:')) {
            // created in order to skip the payments on localhost and go to next page
            // copy this -> window.postMessage('SkipPayment', '*'); and paste on web console
            window.addEventListener('message', (event) => {
                if (event.data === 'SkipPayment') {
                    successCallback();
                }
            });
        }
    }

    static isPositiveNumber(str){
        if (!this.isNumeric(str)){
            return false
        }
        const num = parseFloat(str);
        return !isNaN(num) && num > 0;
    }
}

export default JsExtras
