
export class AiChatApi {

    /** @type {ServerData} */ #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
        this.apiPrefix = '/aiChat'
    }

    /**
     * @typedef {{
     *    todo: boolean;
     * }} PostMessagePayload
     * */

    /**
     * @typedef {{
     *    notCompleted: boolean;
     * }} QueryResultsResponse
     * */

    /**
     * @typedef {{
     *     runID: string,
     *     threadID: string,
     *     success: boolean
     * }} PostMessageResponse
     * */

    /**
     * @param {string} textMessage
     * @param {} attachment
     * @param {string} conversationID
     * @param {string} clientChatID
     * @param {string} userClientID
     * @param {string} botName type of bot {legalBot|onBoarding}
     * @param {} extraParams
     * @return {Promise<{ PostMessagePayload }>}
     * */
    postMessage({textMessage, attachment, conversationID, clientChatID, userClientID, botName, extraParams}) {
        return this.#client.post(`${this.apiPrefix }`, {
            textMessage, attachment, conversationID, clientChatID, userClientID, botName, extraParams
        });
    }


    // /**
    //  * @param {string} threadID
    //  * @param {string} runID
    //  * @param {string} clientChatID talkJS unique conversation ID
    //  * @param {string} botName type of bot
    //  * @param {string} userClientID
    //  * @return {Promise<{  }>}
    //  * */
    // queryResults({threadID, runID, clientChatID, botName, userClientID}) {
    //     return this.#client.post(`${this.apiPrefix }/queryResponse`, {
    //         conversationID: threadID,
    //         clientChatID,
    //         runID,
    //         botName,
    //         userClientID
    //     });
    // }

    /**
     * @param {string} threadID
     * @param {string} runID
     * @param {string} clientChatID talkJS unique conversation ID
     * @param {string} messageText the message the user inputed
     * @param {string} attachment user attachments
     * @param {string} botName type of bot
     * @param {string} userClientID
     * @param {} extraParams
     * @return {Promise<{  }>}
     * */
    streamResponse({threadID, runID, clientChatID, messageText, attachment, botName, userClientID, extraParams}) {
        return this.#client.post(`${this.apiPrefix }/streamResponse`, {
            messageText,
            threadID,
            clientChatID,
            attachment,
            botName,
            userClientID,
            extraParams
        });
    }


}
