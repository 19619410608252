export const footer = {
    "how_it_works": "How it works",
    "why_worth_it": "Why it's worth it",
    "q_and_a": "Q&A",
    "policy": "Policy",
    "accessibility": "Accessibility",
    "support": "Support",
    "all_rights": "© {{currentYear}} All rights reserved",
    "privacy": "Privacy",
    "share": "share bllink",
    "languagePlaceholder": "Select language",
    "test": "test",
    "languages": {
        "en": "English",
        "he": "עברית",
        "es": "Español"
    }
};
