export const statuses = {
    "transferred_to_building_account": "הכסף הועבר לבניין",
    "canceled_but_not_reversed": "תוכנית בוטלה ובוצע זיכוי",
    "tourist_card_success": "תוכנית תייר הסתיימה בהצלחה",
    "j5": "תוכנית לתשלום עתידי",
    "failed": "נכשלה",
    "stop_plan": "נעצרה",
    "skip_payment": "דולגה",
    "in_progress": "פעילה",
    "success_pending": "תיעוד תשלומים בהצלחה",
    "pending": "לא התחילה עדיין",
    "success": "הסתיימה בהצלחה",
    "success_bank_charge": "חוייב וטרם הופקד",
    "refunded": "זוכה לפני העברה",
    "deposited": "הופקד",
    "offset": "זוכה",
    "success_deposit": "הופקד",
    "unknown": "לא ידוע", // go ahead and calculate it from set of related payment plan details

    "inProgress": "תוכנית בתהליך",

    "failedOnce": "נכשלה לאחרונה( עדיין פעילה)", // still active
    "stopped": "נעצרה",

    "offlinePending": "תיעוד תשלום. לא הותאם בנקאית עדיין",
    "offlineProgress": "בתהליך (תיעוד תשלום)",
    "offlineSuccess": "תיעוד תשלום. לאחר הותאם בנקאית",
    "offlineStopped": "תיעוד תשלום נעצר",

    "success_action_notification": "פעולה בוצעה בהצלחה",
    "error_action_notification": "פעולה נכשלה",

    "pendingCollectionReport": "ממתין",
    "depositCollectionReport": "הופקד",
};
