import { constants } from "../../../constants/constants";
import {lazyReload} from "../../../lazyReload";
const ExpensesRecordPage = lazyReload(() => import("../../../../managers/expenses/expensesRecord"));
const ExpensesRecordNewPage = lazyReload(() => import('../../../../managers/expensesNewPage/recordExpense'));
const ExpensesEditPage = lazyReload(() => import("../../../../managers/expenses/expensesEdit"));
const BankAdjustments = lazyReload(() => import("../../../../managers/expenses/bank_adjustments_new/BankAdjustmentsNew"));
const SetExpense = lazyReload(() => import("../../../../managers/expenses/set_expense_process/SetExpense"));

export const ExpensesRecord = [
    {path: `/${constants.pages.expensesRecordUrl}/:buildingID`, component: ExpensesRecordPage, exact: true},
    {path: `/${constants.pages.expensesRecordUrl}-new/:buildingID`, component: ExpensesRecordNewPage, exact: true},
    {path: `/${constants.pages.expensesEditUrl}/:cartID`, component: ExpensesEditPage, exact: true},
    {path: `/${constants.pages.bankAdjustmentsUrl}/:buildingID/:month/:year`, component: BankAdjustments, exact: true},
    {path: `/${constants.pages.setExpenseUrl}/:buildingID`, component: SetExpense, exact: true},
]


