import { constants } from '../constants/constants';
import {default as BllinkUtils} from '../utils/utils';
import {BllinkLogger} from './bllink_loggers';

const jsExtras = BllinkUtils.JsExtras;

// todo find how to not use console.log . couldn't find a way since it depends on it
// todo enable mocking cache
export default class CacheData {

    /**
     *
     * @param key
     * @param value
     * @param options
     * @param options.typeOfCache sessionStorage | localStorage default is localStorage
     */
    static cache(key, value, options){

        const data = this.getKey(key, options);

        let result;
        if (data.ttl) {
            result = this.setWithExpiry(data.key, value, data.ttl, data.dontDelete, key);
        } else {
            result = data.typeOfCache.setItem(data.key, JSON.stringify(value));
            this.triggerCacheChangedEvent(key);
        }
        console.log(`saved to cache key ${data.key} with result ${result} and value to save is `, value);
        return result
    }

    static setWithExpiry(key, value, ttl, dontDelete, originalKey) {

        console.log(`set with expiry for ${key}`);
        const now = new Date()

        // `item` is an object which contains the original value
        // as well as the time when it's supposed to expire
        const item = {
            value: value,
            expiry: now.getTime() + this.ttlToMinutes(ttl),
            dontDelete
        }
        try {
            localStorage.setItem(key, JSON.stringify(item))
        } catch (e) {
            // we are logging the items in order to debug what is cousing localStorage overflow;
            // according to this error https://bllink.sentry.io/issues/4401462774/events/?project=5424386&referrer=issue-stream&statsPeriod=24h&stream_index=5
            const localStorageItems = Object.entries(localStorage).map(item => {return {key: item[0], length: item[1].length}}).sort((a,b)=> b.length - a.length)
            localStorageItems.forEach(item => console.log(item));
            throw e;
        }
        this.triggerCacheChangedEvent(originalKey);
    }

    static ttlToMinutes(ttlInMilliSeconds){
         return ttlInMilliSeconds *  1000 * 60
    }

    static getWithExpiry(key, typeOfCache=localStorage) {
        const itemStr = typeOfCache.getItem(key)
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        let item
        try {
            item = JSON.parse(itemStr)
        }catch (err){
            console.error(`error in getting ${key}. will delete key`)
            typeOfCache.removeItem(key)
            this.triggerCacheChangedEvent(key);
            return null
        }

        const now = new Date()

        // checking if this item has ttl at all
        if (item.expiry) {
            // compare the expiry time of the item with the current time
            if (now.getTime() > item.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                if (item.dontDelete) {
                    console.log(`NOT deleting cache key ${key} due to expiry. but will return expired true`);
                    item.expired = true;
                    return item;
                } else {
                    console.log(`deleting cache key ${key} due to expiry`);
                    typeOfCache.removeItem(key)
                }
                this.triggerCacheChangedEvent(key);
                return null
            }
        }else{
            return item
        }

        return item.value

    }

    static fetchCache(key, options){
        /*
          type sessionStorage or localStorage
         */

        const data = this.getKey(key, options);

        const result = this.getWithExpiry(data.key, data.typeOfCache);
        console.log(`cache fetched for key ${data.key} is `, result);

        return result
    }

    static delete(key, options){
        /*

         */
        const data = this.getKey(key, options);

        const result = data.typeOfCache.removeItem(data.key);
        this.triggerCacheChangedEvent(key);
        console.log(`deleted ${data.key} . result is `, result);
    }

    static triggerCacheChangedEvent(key){
        // used for example in the header to re render the building title
        console.log(`triggerCacheChangedEvent ${key}`)
        const event = new Event(constants.cache.cacheChanged(key));
        document.dispatchEvent(event)
    }

    static getKey(key, options){
        options = options || {};
        // not in use - we always use localstorage for now
        const typeOfCache = CacheData.getCacheStorage(options.typeOfCache);
        const cacheKey = CacheData.getCacheKey(key, options);
        if (!cacheKey){
            throw new Error(`undefined cacheKey for sent ${key}`)
        }

        return {key: cacheKey.key, typeOfCache: typeOfCache, ttl: cacheKey.ttl, dontDelete: cacheKey.dontDelete}
    }

    static getCacheStorage(typeOfCache){
        return typeOfCache || localStorage
    }

    static clearOutdatedCache(){
        // meant to clear cache to avoid overloading the client storage
        // useful for manager's only which will iterate for a lot of apartments
        for (const [key] of Object.entries(localStorage)) {
            if (key.startsWith(constants.cache.prefix_keys)) {
                this.getWithExpiry(key)
            }
        }

    }

    static getCacheKey(caseKey, options){
        let cacheKey;
        const keys = constants.cache.keys;
        let ttlInMinutes = null;
        let dontDelete = false;
        switch(caseKey) {
            // for the old footer to be currency aware
            case 'currency':
                cacheKey = 'currency';
                break;
            case 'buildingApartments':
                jsExtras.validate(options, ['buildingID'])
                cacheKey = keys.buildings.buildingApartments(options.buildingID);
                break;
            case 'buildingData':
                jsExtras.validate(options, ['buildingID'])
                cacheKey = keys.buildings.buildingData(options.buildingID);
                ttlInMinutes = 15; // 15 minutes
                break;
            case 'buildingAddress':
                jsExtras.validate(options, ['buildingID'])
                cacheKey = keys.buildings.buildingAddress(options.buildingID);
                ttlInMinutes = 15; // 15 minutes
                break;
            case 'managerData':
                jsExtras.validate(options, ['buildingID'])
                cacheKey = keys.buildings.managerData(options.buildingID);
                // reducing this to 1 hour since we are getting manager quota exception error
                ttlInMinutes = 60; // 1 hour
                // ttlInMinutes = 24*60; // 1 day
                break;
            case 'onGoingSelectedPayments':
                jsExtras.validate(options, ['buildingID', 'apartmentNumber'])
                cacheKey = keys.payments.onGoingSelectedPayments(options.buildingID, options.apartmentNumber);
                ttlInMinutes = 30; // 30 minutes
                break;
            case 'onGoingChargeDate' :
                jsExtras.validate(options, ['buildingID', 'apartmentNumber']);
                cacheKey = keys.payments.onGoingChargeDate(options.buildingID, options.apartmentNumber);
                ttlInMinutes = 30; // 30 minutes
                break;
            case 'debtSelectedPayments':
                jsExtras.validate(options, ['buildingID', 'apartmentNumber'])
                cacheKey = keys.payments.debtSelectedPayments(options.buildingID, options.apartmentNumber);
                ttlInMinutes = 30; // 30 minutes
                break;
            case 'oneTimeSelectedPayments':
                // todo split selected one time and one time settings cache.
                jsExtras.validate(options, ['buildingID', 'apartmentNumber'])
                cacheKey = keys.payments.oneTimeSelectedPayments(options.buildingID, options.apartmentNumber);
                ttlInMinutes = 15; // 15 minutes
                break;
            case 'installmentsChoices':
                jsExtras.validate(options, ['key'])
                cacheKey = keys.payments.installmentsChoices(options.key);
                break;
            case 'tenantDetails':
                cacheKey = keys.tenants.tenantsDetails;
                // causes bugs in last confirm page since it's required to create a new plan
                // ttlInMinutes = 5;
                break;
            case 'managerTenantDetails':
                jsExtras.validate(options, ['buildingID'])
                cacheKey = keys.tenants.managerDetails(options.buildingID);
                break;
            case 'createCartResponse':
                jsExtras.validate(options, ['cartID'])
                cacheKey = keys.payments.createCartResponse(options.cartID);
                ttlInMinutes = 24*60; // 1 day
                break;
            case 'visitedTabs':
                cacheKey = keys.payments.visitedTabs;
                ttlInMinutes = 20;
                break;
            case 'apartmentPayments':
                jsExtras.validate(options, ['buildingID', 'apartmentNumber'])
                cacheKey = keys.payments.apartmentPayments(options.buildingID, options.apartmentNumber);
                ttlInMinutes = 2;
                break;
            case 'profile':
                cacheKey = keys.managers.profile;
                ttlInMinutes = 5*60;  // 5 hours
                break;
            case 'userResetPassword':
                // saved in session storage
                cacheKey = keys.managers.resetPasswordUser;
                break;
            case 'PaymentMethodSelected':
                jsExtras.validate(options, ['buildingID', 'apartmentNumber'])
                cacheKey = keys.managers.selectedPaymentMethod(options.buildingID, options.apartmentNumber);
                ttlInMinutes = 24*60;  // 24 hours
                break;
            case 'currentBuilding':
                cacheKey = keys.managers.currentBuilding;
                ttlInMinutes = 5;  // 5 minutes
                break;
            case 'currentBuildingFeatures':
                jsExtras.validate(options, ['buildingID']);
                cacheKey = keys.managers.currentBuildingFeatures(options.buildingID);
                ttlInMinutes = 5;  // 5 minutes
                break;
            case constants.cache.keys.managers.partnerFeatures:
                jsExtras.validate(options, ['partnerId']);
                cacheKey = keys.managers.currentPartnerFeatures(options.partnerId);
                ttlInMinutes = 5;  // 5 minutes
                break;
            case 'BankDetails':
                cacheKey = keys.managers.bankDetails;
                break;
            case 'language':
                cacheKey = keys.general.language;
                ttlInMinutes = 60*24;  // 1 day
                break;
            case 'language_manual':
                // same as for case 'language'
                cacheKey = keys.general.language_manual;
                ttlInMinutes = 60*24;  // 1 day
                break;
            case 'expenseTypes':
                ttlInMinutes = 5;  // 5 minutes
                cacheKey = 'expenseTypes';
                break;
            case 'debug':
                cacheKey = keys.debug;
                break;
            case 'cartID':
                cacheKey = keys.payments.cartID;
                break;
            case 'lastPage':
                cacheKey = keys.general.lastPage;
                ttlInMinutes = 5;
                break;
            case 'rewardsCoinValue':
                cacheKey = keys.managers.rewardsCoinValue;
                ttlInMinutes = 10;
                dontDelete = true
                break;
            case 'showMonthSelectionInfoPopUp':
                cacheKey = keys.payments.showMonthSelectionInfoPopUp;
                break;
            case 'bankAdjustmentsTab':
                cacheKey = keys.managers.bankAdjustmentsTab;
                ttlInMinutes = 15;
                break;
            case 'bankRecords':
                cacheKey = keys.managers.bankRecords;
                ttlInMinutes = 15;
                break;
            case 'rewardDetails':
                cacheKey = keys.managers.rewardDetails;
                ttlInMinutes = 10;
                dontDelete = true;
                break;
            case 'currentAptInTenantList':
                cacheKey = keys.managers.currentAptInTenantList;
                ttlInMinutes = 2;
                break;
            case 'currentContactInTenantList':
                cacheKey = keys.managers.currentContactInTenantList;
                ttlInMinutes = 1;
                break;
            case 'sideBarPinned':
                cacheKey = keys.general.sideBarPinned;
                ttlInMinutes = 24*60;
                break;
            case constants.cache.keys.managers.partnerPaymentDetails:
                cacheKey = keys.managers.currentPartnerPaymentDetails(options.partnerId);
                ttlInMinutes = 24*60;
                break;
            case 'clientChatID':
                cacheKey = keys.managers.clientChatID;
                // no need for ttl since this is a session storage and not local storage
                // ttlInMinutes = 24*60;
                break;
            default:
                throw new Error(`handled key ${caseKey}. pls handle this case`)
        }

        return {key: `${constants.cache.prefix_keys}_${cacheKey}`, ttl: ttlInMinutes, dontDelete: dontDelete};
    }

    static async getOrCache(funcToGetData, cacheKey, cacheOptions) {
        let result = this.fetchCache(cacheKey, cacheOptions);
        if (result) {
            BllinkLogger.info('returning generic data from local cache');

        } else {
            result = await funcToGetData();
            BllinkLogger.info(`result from building server page `, result);
            this.cache(cacheKey, result, cacheOptions);
        }
        return result;
    }
}
