import { MenuItem, SubMenu } from "react-pro-sidebar";
import homeIcon from "../../../images/newDesign/homeIcon.svg";
import { isLinkActive } from "../../sidebar/Sidebar.shared";
import { NavLink } from "react-router-dom";
import { constants } from "../../../common/constants/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CacheData from "../../../common/utils/cacheData";
import BllinkTime from "../../../common/utils/time";
import React, { Fragment, useState } from "react";
import BuildingBrochure from "../../../common/building_brochure/brochure";
import { hideLoader, showLoader } from "../../../store/common/slice";
import { useAccessToken } from "../../../common/hooks/useAccessToken";
import { isAdminSelector } from "../../../store/auth/selectors";
import { MenuNavItem } from "../../sidebar/MenuNavItem";
import question from "../../../images/newDesign/question_transparent.svg";

export default function AdminSidebarGroup({ buildingId, year, hideListItems }) {
    const client = useAccessToken();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isAdmin = useSelector(isAdminSelector);
    const currentYear = BllinkTime.getCurrentYear();
    // const currentMonth = BllinkTime.getCurrentMonth();
    const linkYear = year || currentYear;
    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));

    const [isBrochureAvailable, setIsBrochureAvailable] = useState(false);

    const refreshBuildingData = async () => {
        dispatch(showLoader());
        let response = await client.post('/operations/clear_cache', { buildingID: localBuildingId });
        if (response.success) {
            alert(t('errors.managers.clearedCachedForBuildingSuccess', { buildingID: localBuildingId }))
        }
        dispatch(hideLoader());
    };

    const openExternalLink = (url) => {
        window.open(url, '_blank').focus();
    }

    const onResourceCenterClick = () => {
        if (window.userpilot) {
            window.userpilot.trigger('resource_center:gLRAU_sqYy');
        }
    }


    return <Fragment>
        <MenuNavItem
            icon={question}
            url={''}
            stopRedirect
            onClick={(e) => onResourceCenterClick(e)}
            label={t('buildings.nav_bar_resource_center')}
            className='mobile-hide'
        />
        {!hideListItems && isAdmin ? <Fragment>
            <SubMenu
                title={t('buildings.nav_bar_admin')}
                icon={<img src={homeIcon} alt="reports"/>}
                className={[
                    isLinkActive(['manager/editBuilding', 'manager/bankAdjustmentsNew'], {
                        part: true
                    }) ? 'active' : ''
                ]}
            >

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={{ pathname: `https://bllink.retool.com/apps/b4b8db8a-c248-11ed-a4a1-8feab553a29c/Home%20Page` }}
                        target="blank"
                        onClick={() => openExternalLink('https://bllink.retool.com/apps/b4b8db8a-c248-11ed-a4a1-8feab553a29c/Home%20Page')}
                    >
                        {t('reports.backoffice')}
                    </NavLink>
                </MenuItem>
                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={{ pathname: `https://auth.greeninvoice.co.il/signin` }} target="_blank"
                        onClick={() => openExternalLink('https://auth.greeninvoice.co.il/signin')}
                    >
                        {t('reports.accounting_service')}
                    </NavLink>
                </MenuItem>

                <MenuItem
                    active={isLinkActive(`/${constants.pages.balanceReportUrl}-old/:buildingID/:months/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/admin/chat`}
                    >
                        {'AI admin chat'}
                    </NavLink>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.addCollectionStatus}-new/${localBuildingId}`}*/}
                {/*    >*/}
                {/*        {t('reports.collection_report')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.buildingDebtList}-old/${localBuildingId}`}*/}
                {/*    >*/}
                {/*        {t('buildings.nav_bar_debt_list')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.yearlyByMonthsUrl}-admin/${localBuildingId}/${linkYear}`}
                    >
                        {t('buildings.nav_bar_reports_yearly_by_months')}
                    </NavLink>
                </MenuItem>



                {/*<MenuItem*/}
                {/*    active={isLinkActive(`/${constants.pages.balanceReportUrl}-old/:buildingID/:months/:year`)}*/}
                {/*>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.balanceReportUrl}-old/${localBuildingId}/${currentMonth}/${currentYear}`}*/}
                {/*    >*/}
                {/*        {t('buildings.nav_bar_reports_expenses_balance')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.tenantListUrl}-old/${localBuildingId}`}
                    >
                        {' Old tenant list '}
                    </NavLink>
                </MenuItem>

                <MenuItem>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={refreshBuildingData}
                    >
                        {t('buildings.nav_bar_refresh')}
                    </div>
                </MenuItem>

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.editBuilding}/${localBuildingId}`}
                    >
                        {t('buildings.nav_bar_edit_building')}
                    </NavLink>
                </MenuItem>

                {<MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.editContacts}/${localBuildingId}`}
                    >
                        {t('buildings.nav_bar_edit_contacts')}
                    </NavLink>
                </MenuItem>}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`${constants.pages.admin.editBuilding}/${localBuildingId}`}
                    >
                        {t('buildings.nav_bar_edit_building_commission')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.addCollectionStatus}/:buildingID`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.addCollectionStatus}/${localBuildingId}`}
                    >
                        {t('buildings.collection_status_title')}
                    </NavLink>
                </MenuItem>

                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`${constants.pages.admin.autoCampaign}/actions`}*/}
                {/*    >*/}
                {/*        {t('buildings.nav_bar_auto_campaign')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}
                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.monitoringUrl}/${localBuildingId}`}*/}
                {/*    >*/}
                {/*        {t('buildings.nav_bar_monitoring')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.balanceReportUrl}-old/${localBuildingId}`}*/}
                {/*    >*/}
                {/*        {'New Balance old one'}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`${constants.pages.admin.searchPaymentsUrl}`}
                    >
                        {t('buildings.nav_bar_search_payments')}
                    </NavLink>
                </MenuItem>

                <MenuItem
                    active={isLinkActive(`/${constants.pages.newBuildingUrl}`)}
                >
                    <NavLink
                        className="nav-link"
                        to={`/${constants.pages.newBuildingUrl}`}
                    >
                        {t('buildings.open_new_building')}
                    </NavLink>
                    {/*</OverlayTrigger>*/}
                </MenuItem>

                <MenuItem>
                    <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsBrochureAvailable(true)}
                    >
                        {t('buildings.nav_bar_brochure')}
                    </div>
                </MenuItem>
            </SubMenu>
            {
                isBrochureAvailable && (
                    <BuildingBrochure
                        buildingID={localBuildingId}
                        hideContainer={() => setIsBrochureAvailable(false)}
                    />
                )
            }
        </Fragment> : null}
    </Fragment>
}
