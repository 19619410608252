// needed for i18n init

import 'react-notifications/lib/notifications.css';
import { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import {NotificationContainer} from 'react-notifications';
import {ThemeProvider} from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import './globals.css';
import './General.scss';
import './index.scss';
import './fonts/almoni.scss';
import './fonts/arimo.css';
import './fonts/secular_one.css';
import './fonts/open_sans.css';
import './typography.scss';
import { SETTINGS } from './components/common/settings';
import Router from './components/common/router';
import Url from './components/common/utils/urlParser';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';// for performance monitoring
import { BllinkLogger } from './components/common/utils/bllink_loggers';
import { store, persistor } from './components/store/store';
// https://app.asana.com/0/1196545425570329/1199908027783383
// app.bllink not working on Explorer browser
// to solve object doesn't support property or method 'entries'
import "core-js/stable";
import "regenerator-runtime/runtime";
import cssVars from 'css-vars-ponyfill';
import Loader from './components/common/simple/loader';
import {theme} from './theme';
import './components/common/translations';
import {Watchdog} from "./components/common/watchdog";
// import {Replay as SentryReply} from "@sentry/browser";
// import { init as initSaola } from '@saola.ai/browser';
// // will auto update to latest by default unless specify the version initSaola('{account.token}', 'v1.1.3');
// initSaola('18dfc84d-1e74-4760-b9a4-c3503beb4fab');

require('./components/common/amplify'); // init Auth Object
require('./components/common/google_analytics/google_analytics');

if (SETTINGS.sentry) {
    BllinkLogger.info('init sentry ', SETTINGS);
    BllinkLogger.info('process.env ', process.env);
    const sentrySettings = SETTINGS.sentry;
    Sentry.init({
        // process.env.npm_package_version is not working . it's undefined
        release: process.env.REACT_APP_RELEASE,
        // https://docs.sentry.io/platforms/javascript/configuration/releases/#sessions
        autoSessionTracking: false, // default: true
        dsn: sentrySettings.dsn,
        integrations: [
            new Integrations.BrowserTracing(),
            // deacting now due to errors in production
            // new SentryReply()
        ],
        // an attemt to solve https://sentry.io/organizations/bllink/issues/2537277903/?project=5424386&sort=freq&statsPeriod=14d
        ignoreErrors: ['Non-Error promise rejection captured'],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // 1.0 configuration will capture ***100%**** of all your transactions
        // tracesSampleRate: 0.2, // 0.2 for all transactions
        tracesSampler: samplingContext => {
            const path = Url.detectPageName(samplingContext.transactionContext.name);
            BllinkLogger.info(`samplingContext path name is `, path);
            if (!path) {
                // Drop this transaction, by setting its sample rate to 0%
                return 0;
            } else {
                // Default sample rate for all others (replaces tracesSampleRate)
                return 1;
            }
        },
        // // This sets the sample rate to be 10%. You may want this to be 100% while
        // // in development and sample at a lower rate in production
        // replaysSessionSampleRate: sentrySettings.sampleRate || 0.1,
        // // If the entire session is not sampled, use the below sample rate to sample
        // // sessions when an error occurs.
        // replaysOnErrorSampleRate: 1.0,

    });
}

//https://jhildenbiddle.github.io/css-vars-ponyfill/#/
// are we using this?
cssVars({
    // Options...
});

// Workaround to fix an issue with braking page by Google translator
// https://github.com/facebook/react/issues/11538
if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function(child) {
        if (child.parentNode !== this) {
            if (console) {
                console.error('Cannot remove a child from a different parent', child, this);
            }
            return child;
        }
        return originalRemoveChild.apply(this, arguments);
    }

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function(newNode, referenceNode) {
        if (referenceNode && referenceNode.parentNode !== this) {
            if (console) {
                console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
            }
            return newNode;
        }
        return originalInsertBefore.apply(this, arguments);
    }
}

function FallbackComponent() {
    // TODO: provide some error text
    return <div></div>;
}

async function appFactory() {

    // WARNING: local storage will be cleared up if you'll change this version, it will affect all users, be careful
    const LAST_STORAGE_VERSION = 2;
    const localStorageVersion = Number(localStorage.getItem('STORAGE_VERSION') ?? '0');
    if (localStorageVersion < LAST_STORAGE_VERSION) {
        await persistor.purge();
        localStorage.clear();
        localStorage.setItem('STORAGE_VERSION', String(LAST_STORAGE_VERSION));
    }

    ReactDOM.render(
      <Sentry.ErrorBoundary fallback={FallbackComponent} >
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <StrictMode>
                    {/*i18n translations might still be loaded by the http backend*/}
                    {/* use react's Suspense*/}
                    <Suspense fallback="loading">
                        <ThemeProvider theme={theme}>
                            <Loader />
                            <Router />
                            <NotificationContainer />
                            <Watchdog />
                        </ThemeProvider>
                    </Suspense>
                </StrictMode>
            </PersistGate>
        </Provider>
      </Sentry.ErrorBoundary>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}

appFactory();

