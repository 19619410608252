export const navBar = {
    "headers": {
        "plans": "תוכניות תשלומים",
        "buildingActs": "פעולות בניינים",
        "userActs": "פעולות משתמשים",
        "autoCampaign": "קמפיין אוטומטי",
        "reports": "דוח״ות",
        "editBuilding": "ערוך פרטי בניין",
        "partners": "Partners"
    },
    "subheaders": {
        "searchPlans": "חיפוש תוכניות",
        "planActs": "פעולות תוכניות תשלומים",
        "planEdit": "עריכת תוכנית תשלומים",
        "buildAddress": "כתובת בניין",
        "openUser": "פתיחת משתמש",
        "actions": "פעולות קמפיין",
        "status": "סטאטוס קמפיין",
        "reports": {
            "sms": "דו״ח סמס לתשלומים פתוחים",
            "renew": "דו״ח חידושים",
            "failed": "דו״ח תשלומים שנכשלו"
        },
        "editBuilding": "ערוך פרטי בניין",
        "createEditPartner": "Create or edit a partner"
    }
};
