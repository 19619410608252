import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {verifyLoggedIn} from '../../store/auth/actions';
import {BllinkLogger} from '../utils/bllink_loggers';
import {useClient} from "./useClient";

/**
 * @return {ServerData}
 * */
export function useAccessToken() {
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.accessToken) ?? '';
    const client = useClient(accessToken);

    useEffect(async function() {
        if (accessToken === '') {
            try {
                await dispatch(verifyLoggedIn());
            } catch (e) {
                BllinkLogger.info(`user is not authenticated`, e);
            }
        }
    });

    return accessToken === '' ? null : client;
}
