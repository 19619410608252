import { MenuItem, SubMenu } from "react-pro-sidebar";
import income from "../../../images/newDesign/income.svg";
import IncomeReportsSidebarMenu from "./incomeReportsSidebarMenu";
import { useTranslation } from "react-i18next";
import { isLinkActive } from "../../sidebar/Sidebar.shared";
import { constants } from "../../../common/constants/constants";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import CacheData from "../../../common/utils/cacheData";
// import { isAdminSelector } from "../../../store/auth/selectors";

/**
 * @param buildingId
 * @param year
 * @param apartmentNumber
 * @param navData
 * @return {JSX.Element|null}
 * @constructor
 */
export default function IncomeSidebarMenu({buildingId, year, apartmentNumber, navData}) {
    const { t } = useTranslation();
    // const isAdmin = useSelector(isAdminSelector);
    const localBuildingId = useSelector(state => state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const buildingApsNumbers = useSelector(state => state.buildings.currentBuildingData.allNumbers);

    const firstApartment = buildingApsNumbers?.length ? buildingApsNumbers[0] : '1';
    // opened new apt report to all
    const addPaymentUrl = `/${constants.pages.newApartmentReport}/${localBuildingId}/${firstApartment}/payments/add`
    // const addPaymentUrl = isAdmin
    //     ? `/${constants.pages.newApartmentReport}/${localBuildingId}/${firstApartment}/payments/add`
    //     : `/${constants.pages.tenantDetailsManagerUrl}/${localBuildingId}`;

    return (
        <SubMenu
            title={t('buildings.incomes')}
            icon={<img src={income} alt="buildings.incomes"/>}>
            <MenuItem
                active={isLinkActive(addPaymentUrl)}
            >
                <NavLink
                    className="dropdown-link"
                    to={addPaymentUrl}
                >
                    {t('buildings.nav_bar_reports_update_payment')}
                </NavLink>
            </MenuItem>
            <MenuItem
                active={isLinkActive(`/${constants.pages.managerTenantRecord}/${localBuildingId}`)}
            >
                <NavLink
                    className="dropdown-link"
                    to={`/${constants.pages.managerTenantRecord}/${localBuildingId}`}
                >
                    {t('buildings.tenantRecordList')}
                </NavLink>
            </MenuItem>
            <IncomeReportsSidebarMenu year={year} apartmentNumber={apartmentNumber} navData={navData}/>
        </SubMenu>
    )
}
