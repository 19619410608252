import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import { colors } from "../../common/ui/globalColors";

const TenantSearchOptionCardStyled = styled.div`
  .tenant-search-option {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: baseline;
    gap: 8px;
    color: ${props => props.theme.text};
    

    .link {
      color: ${props => props.theme.text};
      opacity: 0.4;
          
          .value-type{
            // catching this property bu not really showing black.. 
            color: ${colors.textColors.black};
          }
    }
    
    .type{
      // joined the value and link under the same so adding this to split
      padding-inline-end: 10px;
    }

    
  }
`

/**
 * @param tenant
 * @returns {JSX.Element}
 * @constructor
 */
export const TenantSearchOptionCard = ({tenant}) => {
    const {t} = useTranslation();
    const tenantDetails = tenant.tenantDetails && tenant.tenantDetails[0];
    // adding trim to avoid names with before or after extra spaces
    const valueToDisplay = tenantDetails?.value?.trim();
    const detailsType = tenant.tenantDetails && tenant.tenantDetails[0] ? tenant.tenantDetails[0].type : '';
    const building = tenant.apartment?.Building;
    const buildingId = building?.externalID;
    const buildingTitle = building?.Title;
    const apartmentNum = tenant.apartment?.ApartmentNum;

    if (!detailsType || !buildingId || !apartmentNum) return null;

    return <TenantSearchOptionCardStyled>
        <div className='tenant-search-option bllink-text-tiny'>
            <a className='link'
               href={`/reports/apartment-new/${buildingId}/${apartmentNum}/payments/history`}>
                <span className='type value-type'>{valueToDisplay}</span>
                <span className='type'>
                    {t('globalSearch.tenant.aptReportLink', {buildingTitle, apartmentNum})}
                </span>
            </a>
        </div>
    </TenantSearchOptionCardStyled>
}
