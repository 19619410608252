export const sharedMobile = {
    apartment: {
        total: {
            paid: 'Paid at {{year}}',
            special: 'Special debts',
            ongoing: 'Ongoing debts',
            up_to_date: 'Up to date',
        },
        paid_payments: {
            title: 'Recent payments',
            receipt: 'receipt',
        },
        future_payments: {
            title: 'Future payments',
            for: 'For:',
            ongoing_total: 'Total future ongoing:',
            number_of_payments: 'Number of payments:',
        },
        show_more: 'show more',
        paid_for: 'Payment for:',
        comment: 'Comment:',
    }
}
