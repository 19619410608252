export const offlinePayments = {
    "notSet": "Not determined",
    "credit_card": "Credit card",
    "cheque_deposit": "Auto cheque",
    "tenant_record": "Tenant record",
    "bankCharge": "Bllink",
    "type_of_payment": "Type of payment",
    "pay_with_cheque_title": "Pay with cheque",
    "pay_with_cash_title": "Record cash payment",
    "pay_with_wire_title": "Record bank wire",
    "pay_with_tenant_record": "Report Paid Dues",
    "bank_code_title": "Bank",
    "bank_branch_title": "Branch",
    "bank_account_number_title": "Account number",
    "make_offline_payment_button": "Record payment",
    "date_of_deposit": "Deposit date",
    "recurrent_bank_wires": "Recurrent bank wire",
    "payment_date": "Payment date",
    "creation_date": "Creation date",
    "cheque_number": "Cheque number",
    "cheque_number_placeholder": "Enter amount of cheques",
    "add_row_tooltip": "Fill in previous row and we will autofill values for the next rows",
    "auto_cheque_explanation1": "After reaching this screen, make sure that the check scanner is connected. And the scanner software (CI3) runs and appears in green.",
    "auto_cheque_explanation2": "Make sure the mouse cursor appears in the 'Bank' box.",
    "auto_cheque_explanation3": "You can enter all the checks for the apartment at once in the check scanner. After each scan, a data scan window will be displayed for the check scanner",
    "partial_pay": "Partial payment",
    "payment": "To pay",
    "cancellation": "Cancel",
    "denyTenantRecord": "cancel tenant record",
    "approveTenantRecord": "approve tenant record",
    "partial_sum_must_be_smaller": "Partial amount must be less than the amount of the month or the one-time payment selected {{fullAmount}}",
    "auto_cheque_explanation4": "The system will type the data on this form after each click on the green hook in the check scanner software",
    "auto_cheque_explanation5": "Beyond the next check you can press the caps lock button",
    "auto_cheque_explanation6": "After click on 'Documentation of payment', actual deposit is up to 3 business days even if the cheque date is set for past date",
    "cheque": "Check",
    "wire": "Wire",
    "cash": "Cash",
    "date": "Date",
    "confirmation": "Confirm",
    "approve": "Confirm",
    "tenant_record_modal_text": "You are reporting that the selected items are paid. Confirming this action will inform the building manager for further inquiry",
    "amount": "Amount",
    "reccurent_wire_tooltip": "A check here will set up a monthly transfer month by month",
    "tenant_offline_payment_success": "A message has been successfully sent to management company",
    "tenant_offline_payment_failed": "Unable to send message to management company. Try later",
    "status": "Status"
};
