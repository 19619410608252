import AdminNavBar from "../AdminNavBar/AdminNavBar";
import { StyledHeader } from "./AdminPagesWrapper.styled";

const AdminPagesWrapper = ({ children, title, buildingID }) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <AdminNavBar buildingID={buildingID} />
                    <StyledHeader>{title}</StyledHeader>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AdminPagesWrapper;