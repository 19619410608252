export class DashboardApi {

    /**
     * @typedef {{
     *    totalDebts: CurrencyValue;
     *    totalTenantsCount: number;
     *    paymentsForClarificationCount: number;
     *    failedTransactions: number;
     *    buildingScore: number;
     * }} BuildingGeneralDashboardData
     * */

    /**
     * @typedef {{
     *    newPayments: CurrencyValue,
     *    recurringPayments: CurrencyValue,
     * }} BuildingTodayPaymentsTotal
     * */

    /**
     * TODO: definition not ready yet
     * @typedef {{
     *    lastPayments: unknown[];
     * }} BuildingLastPayments
     * */

    /**
     * @typedef {{
     *     paymentTotalsHistory: { year: number, month: number, day: number, amount: CurrencyValue }[],
     * }} BuildingPaymentTotalsHistory
     * */

    /**
     * @typedef {{
     *     paymentTotalsHistory: { year: number, month: number, day: number, amount: CurrencyValue }[],
     * }} BuildingExpenseTotalsHistory
     * */

    /**
     * @typedef {{
     *    month: number;
     *    year: number;
     *    incomeTotal: CurrencyValue;
     *    expenseTotal: CurrencyValue;
     *    incomeExpectedTotal: CurrencyValue;
     *    expenseExpectedTotal: CurrencyValue;
     *    incomeByPaymentTypeTotals: { paymentMethod: number, amount: CurrencyValue }[];
     * }} BuildingBalanceTotal
     * */

    /**
     * TODO: need to define enum for the transport field values
     * @typedef {{
     *    month: number;
     *    year: number;
     *    paymentRequests: { count: number, transport: string }[]
     * }} BuildingPaymentRequests
     * */

    #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} buildingId
     * @return {Promise<BuildingGeneralDashboardData>}
     * */
    getGeneralData(buildingId) {
        return this.#client.get(`/managers/dashboard/${buildingId}/general`);
    }

    /**
     * @param {string} buildingId
     * @return {Promise<BuildingTodayPaymentsTotal>}
     * */
    getTodayPaymentsTotal(buildingId) {
        return this.#client.get(`/managers/dashboard/${buildingId}/today_payments_total`);

    }

    /**
     * @param {string} buildingId
     * @return {Promise<BuildingLastPayments>}
     * */
    getLastPayments(buildingId) {
        return this.#client.get(`/managers/dashboard/${buildingId}/last_payments`);

    }

    /**
     * @param {string} buildingId
     * @return {Promise<BuildingPaymentTotalsHistory>}
     * */
    getPaymentTotalsHistory(buildingId) {
        return this.#client.get(`/managers/dashboard/${buildingId}/payment_totals_history`);
    }

    /**
     * @param {string} buildingId
     * @return {Promise<BuildingPaymentTotalsHistory>}
     * */
    getExpenseTotalsHistory(buildingId) {
        return this.#client.get(`/managers/dashboard/${buildingId}/expense_totals_history`);
    }

    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * @return {Promise<BuildingBalanceTotal>}
     * */
    getBalanceTotal(buildingId, year, month) {
        return this.#client.get(`/managers/dashboard/${buildingId}/stats/${year}/${month}/balance_total`);
    }

    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * @return {Promise<BuildingPaymentRequests>}
     * */
    getPaymentRequestStats(buildingId, year, month) {
        return this.#client.get(`/managers/dashboard/${buildingId}/stats/${year}/${month}/payment_requests`);
    }

    /**
     * @param {string | number} partnerId
     * @return {Promise<{ monthTotals: MonthPaymentTotals[] }>}
     * */
    getMonthAmounts(buildingId) {
        return this.#client.get(`/managers/dashboard/${buildingId}/month_amounts`, { }, { throwError: true });
    }
}
