import {useEffect, useMemo, useState} from 'react';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
import { TableWrap, TableContainer } from './table.styled';
import EditableCell from './EditableCell';
import {isAdminSelector} from "../../store/auth/selectors";

export default function Table({
    buildingId,
    columns,
    data,
    className,
    filters,
    updateData,
    skipPageReset,
    initialSorting,
    tableTitle,
    tableSubtitle,
    titleToolTip,
    hiddenColumns,
    paginationOptions,
    onRowClick,
    getRowProps,
    disableSort = false,
    ltr
}) {
    const defaultColumn = useMemo(
        () => ({
            ...(updateData && {
                Cell: EditableCell,
            })
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setFilter,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setHiddenColumns,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                ...(initialSorting && {
                    sortBy: initialSorting
                }),
                ...(hiddenColumns && {
                    hiddenColumns
                }),
                ...(paginationOptions && {
                    pageSize: paginationOptions.pageSize || 100,
                    pageIndex: paginationOptions.currentPage
                })
            },
            ...(initialSorting && {
                initialState: {
                    sortBy: initialSorting
                }
            }),
            ...(paginationOptions && {
                manualPagination: true,
                pageCount: paginationOptions.pageCount,
            }),
            defaultColumn,
            autoResetPage: !skipPageReset,
            updateData,
            disableSortBy: disableSort
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    const {t} = useTranslation();
    const isAdmin = useSelector(isAdminSelector);
    const titleData = useSelector(state => state.buildings.titleData);
    const [printTitle, setPrintTitle] = useState('');

    useEffect(() => {
        setHiddenColumns(hiddenColumns ?? []);
    }, [hiddenColumns]);

    useEffect(() => {
        if (buildingId && titleData?.translationKey != null) {
            setPrintTitle(t(`payments.${titleData.translationKey}`, titleData));
        }
    }, [buildingId, titleData]);

    useEffect(() => {
        if (filters) {
            filters.status && setFilter('status', filters.status);
            filters.global ? setGlobalFilter(filters.global) : setGlobalFilter(undefined);
        }
    }, [filters, data]);

    const {fetchData} = paginationOptions || {};
    if (fetchData) {
        useEffect(() => {
            fetchData({ pageIndex, pageSize });
        }, [fetchData, pageIndex, pageSize]);
    }

    const renderCell = (cell) => {
        return (
            <td
                {...cell.getCellProps({
                    className: cell.column.className ? cell.column.className : '',
                    style: {
                        minWidth: cell.column.minWidth ? `${cell.column.minWidth}px` : '',
                        maxWidth: cell.column.cellMaxWidth ? `${cell.column.cellMaxWidth}px` : '',
                    }
                })}
            >
                {cell.render('Cell')}
            </td>
        );
    }

    return (
        <TableContainer>
            <TableWrap ltr={ltr} className={`${className ? className : ''} ${(tableTitle || printTitle) ? 'with-title' : ''}`}>
                {
                    (tableTitle || printTitle) && (
                        <div className='table-title'>
                            <OverlayTrigger
                                placement="top"
                                overlay={props => (
                                    <Tooltip {...props}>
                                        {isAdmin && titleToolTip}
                                    </Tooltip>
                                )}
                            >
                                <h4>{tableTitle}</h4>
                            </OverlayTrigger>

                            {tableSubtitle && (<h3>{tableSubtitle}</h3>)}
                            {printTitle && <h3 className="print-title">{printTitle}</h3>}
                        </div>
                    )
                }
                <table {...getTableProps()} id={'table'} className={updateData ? 'editable-table' : ''}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps({
                                    ...column.getSortByToggleProps(),
                                    style: {
                                        maxWidth: column.cellMaxWidth ? `${column.cellMaxWidth}px` : '',
                                    }
                                })}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {
                            paginationOptions ? page.map((row, i) => {
                                    prepareRow(row);
                                    const props = getRowProps ? getRowProps(row) : {};
                                    return (
                                        <tr
                                            {...row.getRowProps(props)}
                                            onDoubleClick={onRowClick ? () => onRowClick(row.original) : null}
                                        >
                                            {row.cells.map(cell => {
                                                return renderCell(cell);
                                            })}
                                        </tr>
                                    )
                                }) :
                                rows.map(
                                    (row, i) => {
                                        prepareRow(row);
                                        const props = getRowProps ? getRowProps(row) : {};
                                        return (
                                            <tr
                                                {...row.getRowProps(props)}
                                                onDoubleClick={onRowClick ? () => onRowClick(row.original) : null}
                                            >
                                                {row.cells.map(cell => {
                                                    return renderCell(cell);
                                                })}
                                            </tr>
                                        )
                                    }
                                )
                        }
                        {
                            !data.length && (
                                <tr>
                                    <td colSpan="10000">{t('reports.empty_table')}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </TableWrap>

            {
                paginationOptions && paginationOptions.pageCount > 1 && (
                    <Row className="pagination">
                        <Col sm={'auto'}>
                            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </Col>
                        <Col sm={'auto'}>
                            <span>
                                Page{' '}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{' '}
                            </span>
                        </Col>
                    </Row>
                )
            }
        </TableContainer>
    )
}
