import {StyledManagerPage} from './managerPage.styled';
import ManagerTopBar from '../managerTopBar/ManagerTopBar';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import {getAuthenticatedUser, getUserProfile, verifyLoggedIn} from '../../store/auth/actions';
import Footer from '../../common/footer';
import { constants } from '../../common/constants/constants';
import {matchPath, useLocation} from 'react-router-dom';
import RewardsNotification from '../rewards/RewardsNotification';
import {
    fetchBuildingData,
    fetchManagerBuildingData,
    getBuildingTitle,
    getBuildingTitleText
} from "../../store/buildings/actions";
import {hideLoader, showLoader} from "../../store/common/slice";
import {useTranslation} from "react-i18next";
import Sidebar from '../sidebar/Sidebar';
import {MaintenanceSidebar} from "../sidebar/MaintenanceSidebar";
import SidebarNew from "../sideBarNew/SidebarNew";
import classNames from "classnames";
import {
    isAdminSelector,
    isDevSelector,
    shouldLandUserToPremiumInfoPageSelector,
} from "../../store/auth/selectors";
import { changeBuildingID } from "../../store/buildings/slice";
import { isMobile } from 'react-device-detect';
import {fetchPartnerFeatures, getPartnerPaymentDetails} from "../../store/manager/actions";
import {setVisitPremiumInfoPage} from "../../store/common/persistentSlice";
import ChatComponent from "../../ui_mobile/AIChat/talkJS";

export default function ManagerPage({children, match}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const useNewNavBar = urlParams.get('newNav')

    const {buildingID, partnerID, entityType, entityValue} = match.params;

    const entityTypeFinal = entityType  ?? (buildingID ? 'buildingID' : (partnerID ? 'partnerID' : null));
    const entityValueFinal = entityValue ?? (buildingID ?? partnerID);
    const location = useLocation();
    const history = useHistory();
    const isAdmin = useSelector(isAdminSelector);
    const isDev = useSelector(isDevSelector);
    const shouldLandUserToPremiumInfoPage = useSelector(shouldLandUserToPremiumInfoPageSelector);
    const accessToken = useSelector((state) => state.auth.accessToken);
    const userProfile = useSelector((state) => state.auth.userProfile);
    const sidebarCollapsed = useSelector(state => state.common.sideBarCollapsed);
    const mobileMenuOpened = useSelector(state => state.common.mobileMenuOpened);
    const managerDataPendingId = useSelector(state => state.buildings.managerDataPendingId);
    const managerAllBuildings = useSelector(state => state.manager.buildings?.buildings);
    // const buildingFeatures = useSelector(state => state.buildings.currentBuildingFeatures);
    // temp opening to all
    const shouldShowPersonalAssistant = true; //isAdmin || buildingFeatures?.openPersonalAssistant?.value === 'true';

    // const parnterFeatures = useSelector(state => state.buildings.fetchFeature);
    // const showNewFeature = parnterFeatures?.showNewFeature?.value === 'true';

    const [hideManagerComponents, setHideManagerComponents] = useState(true);

    const noVerificationPages = [
        `/${constants.pages.orderStatusPage}/:cartID`
    ];

    /**
     * @param {{params: {partnerID: string}}} match
     */
    const fetchPartnerInfo = async (match) => {
        const partnerId = match?.params?.partnerID ?? userProfile?.partnerID;
        if (partnerId) {
            await dispatch(fetchPartnerFeatures({partnerId}));
            await dispatch(getPartnerPaymentDetails({ignoreCache: false, partnerId}));
        }
    }

    useEffect(() => {
        (async function () {
            if (!accessToken) {
                try {
                    const {payload} = await dispatch(getAuthenticatedUser());
                    if (payload.accessToken) {
                        setHideManagerComponents(false);
                        await dispatch(verifyLoggedIn());
                    }
                } catch (e) {
                    if (!isNoVerificationPage()) await dispatch(verifyLoggedIn());
                }
            } else {
                setHideManagerComponents(false)
            }
        })();
    }, [accessToken, userProfile]);

    useEffect(async () => {
        if (accessToken == null || accessToken === '') {
            return;
        }
        const buildingID = match.params.buildingID;
        await fetchPartnerInfo(match);

        if (managerDataPendingId == buildingID) {
            return;
        }

        if (buildingID != null) {
            try {
                const options = { urlParams: { skipDisabledCheck: true } };
                dispatch(showLoader());
                const { payload } = await dispatch(fetchManagerBuildingData({ buildingID, options }));
                await dispatch(getBuildingTitle({buildingID, addressData: payload.building.address }));
                await dispatch(getBuildingTitleText({ buildingID, t }));
                await dispatch(fetchBuildingData({ buildingID, options }));
                await dispatch(changeBuildingID(buildingID));
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(hideLoader());
            }
        }
    }, [match, accessToken, t])

    useEffect(() => {
        if(shouldLandUserToPremiumInfoPage) {
            dispatch(setVisitPremiumInfoPage(false));
            history.push(`/${constants.pages.premiumInfoPage}`);
        }
    }, [shouldLandUserToPremiumInfoPage])

    useEffect(async () => {
        const buildingID = match.params.buildingID;
        if (userProfile) {
            // todo dynamically change the attribute for user pilot
            //  in order for the userpilot menu to direct to the
            //  specific building various reports
            // causing bugs when not logged in related to task https://bllink.atlassian.net/browse/SSB-1543
            // fixing by changing location to here
            if (window.userpilot && buildingID && userProfile?.userID) {
                const userID = userProfile.userID;
                await window.userpilot.identify( userID, {buildingID : buildingID})
            }
        }
    }, [userProfile]);

    useEffect(async () => {
        if (accessToken == null || accessToken === '') {
            return;
        }
        if (userProfile.userFirstName == null) {
            dispatch(showLoader());
            try {
                await dispatch(getUserProfile());
            } catch (e) {
                console.error(e)
            } finally {
                dispatch(hideLoader());
            }
        }
    }, [accessToken]);

    const maintenanceMode = useMemo(() => {
        if ((managerAllBuildings?.length ?? 0) === 0) return null; // wait for buildings to make correct redirect
        const isMaintenance = Boolean(userProfile?.isMaintenanceOnly || userProfile?.roles?.some(r => r ===  'maintenance'));
        return isMaintenance;
    }, [userProfile, managerAllBuildings]);

    useEffect(() => {
        if (maintenanceMode && window.location.href.indexOf(constants.pages.allBuildingsUrl) > -1) {
            //https://bllink.atlassian.net/browse/SSB-1594?focusedCommentId=15223
            let url = `/${constants.pages.maintenanceLivyUrl}/partner/${userProfile.partnerID}/faults`;
            const individualManager = userProfile.partnerID === 'private' || userProfile.partnerID == null;
            if (individualManager) {
                const building = managerAllBuildings[0];
                url = `/${constants.pages.maintenanceLivyUrl}/building/${building.buildingID}/faults`;
            }
            //https://bllink.atlassian.net/browse/SSB-1703 mobile for maintenance error
            setTimeout(() =>  history.push(url), 2000);
            // window.location.href = url;
        }
    }, [maintenanceMode, userProfile]);

    const showChildren = useMemo(() => {
        const noVerification = isNoVerificationPage();
        if (noVerification) {
            return true;
        }
        // NOTE: maintenanceMode will be defined once we get userProfile
        const value = maintenanceMode != null && (!maintenanceMode || window.location.href.indexOf(constants.pages.allBuildingsUrl) === -1);
        return value;
    }, [maintenanceMode]);

    const showNewSidebar = useMemo(() => {
        return !maintenanceMode && !isMobile;
    }, [maintenanceMode, isMobile])

    const newMobilePage = useMemo(() => {
        return isMobile && (
            match.url.startsWith(`/${constants.pages.allBuildingsUrl}`) ||
            match.url.startsWith(`/${constants.pages.tenantListUrl}`) ||
            match.url.startsWith(`/${constants.pages.maintenanceLivyUrl}`) ||
            match.url.startsWith(`/${constants.pages.tenantDetailsManagerUrl}`) ||
            match.url.startsWith(`/${constants.pages.paymentSelectionTabForManager}`) ||
            match.url.startsWith(`/${constants.pages.lastConfirmPaymentManagerPageUrl}`) ||
            match.url.startsWith(`/${constants.pages.managerConfirmation}`) ||
            match.url.startsWith(`/${constants.pages.buildingDebtList}`) ||
            match.url.startsWith(`/${constants.pages.managersChat}`)
        );
    }, [isMobile, match]);

    function isNoVerificationPage() {
        return noVerificationPages.some(page => !!matchPath(location.pathname, {
            path: page,
            exact: true,
            strict: true
        }));
    }

    return (
        <StyledManagerPage
            collapsedSidebar={sidebarCollapsed}
            mobileMenuOpened={mobileMenuOpened}
            noVerificationPage={hideManagerComponents}
            className="ManagerPage"
        >
            { !hideManagerComponents && (
                <div className={classNames('sidebar', { 'collapsed': sidebarCollapsed })}>
                    { maintenanceMode && <MaintenanceSidebar /> }
                    { showNewSidebar
                            ? <SidebarNew
                                buildingId={match.params.buildingID}
                                year={match.params.year}
                                apartmentNumber={match.params.apartmentNumber}
                                match={match}/>
                            : <Sidebar
                                buildingID={match.params.buildingID}
                                year={match.params.year}
                                apartmentNumber={match.params.apartmentNumber}
                                match={match}
                            />
                    }
                </div>
            ) }

            <div className={'page'}>
                <div className={classNames('page-content', { showChildren, hideManagerComponents, newMobilePage })}>
                    {!hideManagerComponents && !newMobilePage && <ManagerTopBar />}
                    {showChildren && children}
                </div>

                {userProfile?.userID && !isMobile && shouldShowPersonalAssistant && (
                    <ChatComponent
                        botName={'managersAssistants'}
                        extraParams={{
                            userID: userProfile?.userID,
                            entityValue: entityValueFinal,
                            entityType: entityTypeFinal,
                            managerMobileApp: false,
                            justPopUp: true,
                            photoUrl: userProfile?.photoUrl
                        }}
                    />)
                }
                <Footer />
            </div>

            <RewardsNotification />
        </StyledManagerPage>
    );
}
