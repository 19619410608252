export const managerRewards = {
    "phone": "טלפון",
    "email": "אימייל",
    "points": "נקודות {{pointsNumber}}",
    "full_name": "שם מלא",
    "title": "נקודות בלינק עבור",
    "apartment": "דירה",
    "street_and_num": "רחוב (רחוב, מספר)",
    "city": "עִיר",
    "title2": "נקודות בלינק עבור",
    // todo move to separate component
    "titleMarketPlace": "מוצרים בהנחה",
    "actions_title": "טבלת נקודות",
    "amount": "סכום",
    "prizes": "פרסים",
    "action": "פעולה",
    "coins_gained": "נקודות שנצברו",
    "coins": "מטבעות",
    "collect": "לאסוף",
    "collect_prize": "בחר\/י פרס",
    "collect_marketplace_product": "בחר\/י מוצר",
    "collect_marketplace_title": "מוצרים",
    "collect_marketplace_currency": "ש״ח",
    "collect_marketplace_popup_title": "מלא פרטים למשלוח המוצר",
    "collect_marketplace_popup_explain": "השלמת ההזמנה תתבצע לאחר שיחת נציג לאישור ותשלום",
    "collect_marketplace_product_success": "תודה רבה, אנחנו ניצור עמך קשר",
    "personal_details": "פרטים אישיים",
    "delivery_details": "פרטי משלוח",
    "next_prize": "יש לך <amountLeft> מטבעות עד לפרס הבא",
    "prize_collect_success": "פרס נבחר בהצלחה. בקרוב תקבל\/י אותו לכתובת משרדך או לכתובת מועדפת אחרת",
    "COINS_NOT_ENOUGH": "אין לך מספיק מטבעות בשביל פרס זה",
    "CAMPAIGN_NOT_FOUND": "קמפיין פרסים לא קיים",
    "CAMPAIGN_NOT_ACTIVE": "קמפיין לא פעיל",
    "CAMPAIGN_PRIZE_NOT_FOUND": "פרס נבחר לא קיים",
    "CAMPAIGN_PRIZE_ALREADY_REQUESTED": "פרס נבחר כבר בוקש בעבר",
    "new_coins_gained": "ברכות! קיבלת {{newPoints}} נקודות בלינק חדשות. בדוק\/בדקי את אזור הפרסים לראות את ההתקדמות שלך!",
    "no_coins": "אין לך מספיק נקודות בלינק לפרס זה",
    "collecting_points_tooltip": "צבירת נקודות תתבצע בגין פעולות גבייה חדשות בלבד. כלומר עבור כל דירה אשר ב-3 החודשים האחרונים לא בוצע עבורה תשלום"
};
