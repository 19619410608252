import { Scrollbar } from "react-scrollbars-custom";
import styled from "styled-components";

const NewScrollbarStyled = styled.div`
  width: 100%;
  
  .bllink-scrollbar {
    .scroll-track {
      width: 6px !important;
      background: #fff !important;
    }

    .scroll-thumb {
      background: linear-gradient(179.97deg, #6D8FFF -24.96%, #8467FF 99.97%) !important;
      border-radius: 25px !important;
    }
  }
`
/**
 * @param {string} width
 * @param {string} height
 * @param {boolean} mobileNative
 * @param {boolean} noScrollX
 * @param {boolean} rtl
 * @param {({scrollTop}) => void} onScroll
 * @param {string} className
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const NewScrollbar = (
    {
        width,
        height,
        mobileNative,
        noScrollX,
        rtl,
        onScroll,
        className,
        children
    }) => {
    return (
        <NewScrollbarStyled>
            <Scrollbar
                style={{ width, height }}
                mobileNative={mobileNative}
                noScrollX={noScrollX}
                rtl={rtl}
                onScroll={onScroll}
                className={`bllink-scrollbar ${className || ''}`}
                trackYProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return <div {...restProps} ref={elementRef} className="scroll-track"/>;
                    }
                }}
                thumbYProps={{
                    renderer: props => {
                        const { elementRef, ...restProps } = props;
                        return <div {...restProps} ref={elementRef} className="scroll-thumb"/>;
                    }
                }}>
                {children}
            </Scrollbar>
        </NewScrollbarStyled>
    );
}
