import { constants } from "../../constants/constants";
import {lazyReload} from "../../lazyReload";
const PaymentsLastCheckoutPage = lazyReload(() => import("../../../tenants/tenant_payments/paymentLastCheckoutPage"));
const SelectPayments = lazyReload(() => import("../../../tenants/tenant_payments/selectPayments"));
const OrderStatusPage = lazyReload(() => import("../../order_status/orderStatusPage"));
const TenantDetails = lazyReload(() => import("../../../tenants/tenant_details/TenantDetails"));
const TenantRecordTable = lazyReload(() => import("../../../managers/tenantRecord/TenantRecordTable"));

export const OfflineManagerRoutes = [
    {path: `/${constants.pages.tenantDetailsManagerUrl}/:buildingID`, component: TenantDetails, exact: true},
    {path: `/${constants.pages.managerTenantRecord}/:buildingID`, component: TenantRecordTable, exact: true},
    {path: `/${constants.pages.paymentSelectionTabForManager}/:buildingID/:apartmentNumber`, component: SelectPayments, exact: true},
    {path: `/${constants.pages.lastConfirmPaymentManagerPageUrl}/:buildingID/:apartmentNumber`, component: PaymentsLastCheckoutPage, exact: true},
    {path: `/${constants.pages.managerConfirmation}/:cartID`, component: OrderStatusPage, exact: true},
]
