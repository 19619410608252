import {MenuItem, SubMenu} from "react-pro-sidebar";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import accounting from "../../../images/newDesign/accounting.svg";
import {constants} from "../../../common/constants/constants";
import CacheData from "../../../common/utils/cacheData";
import BllinkTime from "../../../common/utils/time";
import {MenuNavItem} from "../../sidebar/MenuNavItem";
import {hasPremiumAccessSelector} from "../../../store/auth/selectors";
import {Tooltip} from "../../../ui/Tooltip";

/**
 * @param {string | number} buildingId
 * @param {boolean} isGreenInvoiceOn
 * @return {JSX.Element|null}
 * @constructor
 */
export default function AccountingSidebarMenu({buildingId, isGreenInvoiceOn}) {
    const {t} = useTranslation();
    const currentYear = BllinkTime.getCurrentYear();
    const currentMonth = BllinkTime.getCurrentMonth();
    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const isPremiumUser = useSelector(hasPremiumAccessSelector);

    /**
     * @param {MouseEvent} e
     */
    const blockedClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <SubMenu
            title={t('buildings.nav_bar_accounting')}
            icon={<img src={accounting} alt={t('buildings.nav_bar_accounting')}/>}>
            <MenuNavItem
                url={`/${constants.pages.bankAdjustmentsUrl}/${localBuildingId}/${currentMonth}/${currentYear}`}
                label={t('buildings.nav_bar_bank_reconciliation')}
                tooltip={t('buildings.upgrade_to_premium')}
                disabled={!isPremiumUser}
                tooltipDisabled={isPremiumUser}
            />
            <MenuNavItem
                url={`/${constants.pages.balanceReportUrl}-new/${localBuildingId}`}
                label={t('buildings.nav_bar_reports_expenses_balance')}
                tooltip={t('buildings.upgrade_to_premium')}
                disabled={!isPremiumUser}
                tooltipDisabled={isPremiumUser}
            />
            {isGreenInvoiceOn && (
                <MenuItem className={isPremiumUser ? '' : 'disabled'}>
                    <Tooltip text={t('buildings.upgrade_to_premium')} disabled={isPremiumUser}>
                        <NavLink
                            className="dropdown-link"
                            to={{pathname: `https://auth.greeninvoice.co.il/signin`}} target="_blank"
                            onClick={isPremiumUser ? () => {
                            } : blockedClick}>
                            {t('reports.accounting_service')}
                        </NavLink>
                    </Tooltip>
                </MenuItem>
            )}
        </SubMenu>
    )
}
