export const premiumInfo = {
    title: 'ברוכים הבאים לבלינק',
    top: 'חדשות מרגשות: מעצימים את החוויה שלך בבלינק',
    offer: {
        general: 'בתאריך 01/01/2024 אנו משיקים 2 מסלולים בבלינק:',
        freeTrack: {
            name: 'מסלול חינמי:',
            description: 'ממשיכים ליהנות מהדברים החיוניים עם המסלול החינמי שלנו. זה מושלם עבור אלה שמעריכים פשטות ופונקציונליות בסיסיות.'
        },
        paidTrack: {
            name: 'מסלול בתשלום:',
            description: 'קח את החוויה שלך לשלב הבא עם המסלול בתשלום העשיר שלנו! פתח את מלוא הפוטנציאל של הפלטפורמה שלנו עם יכולות מתקדמות, אוטומציות לתהליכים ותמיכה בעדיפות.'
        }
    },
    advantages: {
        general: 'למה מסלול בתשלום?',
        first: {
            title: '🌐 גישה מלאה למערכת:',
            description: 'נהנים מכל הפונקציות והדוחות שהמערכת מציעה. כל מה שתצטרך בהשיג ידך.'
        },
        second: {
            title: '🤖  אוטומציות לתהליכים:',
            description: 'מייעלים את זרימות העבודה ומגבירים את היעילות עם  האוטומציות החזקות שלנו, שנועדו לחסוך לך זמן ומאמץ'
        },
        third: {
            title: '🚀 תמיכה מועדפת:',
            description: 'זקוק לסיוע? משתמשי פרימיום מקבלים תמיכה בעדיפות על מנת להבטיח תשובות לשאלותיך במהירות וביעילות.'
        }
    },
    buttons: {
        submit: 'פתיחת המערכת המלאה',
        cancel: 'תודה, לא כרגע',
        selection: 'בחירת מסלול',
    },
    subscriptions: {
        title: 'בחר את המסלול שמתאים לך',
        free: {
            title: 'חינם',
            description: 'ממשיכים ליהנות מהדברים החיוניים עם המסלול החינמי שלנו. זה מושלם עבור אלה שמעריכים פשטות ופונקציונליות בסיסית.',
            price: '0 ש״ח / חודשי',

        },
        premium: {
            title: 'פרימיום',
            description: 'קח את החוויה שלך לשלב הבא עם המסלול בתשלום העשיר שלנו! פתח את מלוא הפוטנציאל של הפלטפורמה שלנו עם יכולות מתקדמות, ואוטומציות לתהליכים.',
            price: '25 ש״ח / חודשי לבניין',
        },
        points: {
            acceptDigitalPayments: 'קבלת תשלומים דיגיטליים',
            listOfTenants: 'רשימת דיירים',
            updateRates: 'עדכון תעריפים',
            monthlyPaymentReport: 'דו״ח תשלומים חודשי',
            apartmentPaymentReport: 'דו״ח תשלומי דירה',
            fullReports: 'דו״חות מלאים',
            paymentsDocumentation: 'תיעוד תשלומים (שיקים, מזומן, בנק ישיר)',
            messageSystem: 'מערכת הודעות',
            automatedCampaign: 'קמפיין אוטומטי',
            expenseManagement: 'ניהול הוצאות',
            bookkeeping: 'הנהלת חשבונות',
            documentArchive: 'ארכיון מסמכים',
            prizesAndGifts: 'פרסים ומתנות',
        }
    },
    cancelModal: {
        title: 'בחר את המסלול שמתאים לך',
        description: `שימו לב, ניתן לבחור את המסלול המתאים עד לתאריך {{closeDate}}.
לא הספקתם לבחור מסלול? נעדכן את המסלול ״החינמי״ ובכל שלב ניתן לעדכן למסלול ״פרימיום״`,
        submit: 'חזרה לבחירת מסלול',
        cancel: 'המשך למערכת',
    }
};
