export const paymentMethods = {
    "1": "כרטיס אשראי",
    "2": "צ׳קים",
    "3": "העברה בנקאית",
    "4": "מזומן",
    "5": "סומן כשולם", // Legacy Payment
    "6": "בלינק - בנק",
    "7": "סורק צ׳קים",
    "8": "דיווח דייר/ת",
    "9": "לא נקבע",
    "14": "צ׳ק לפקודת בלינק",
};
