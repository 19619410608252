import {Fragment, useMemo} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import allBuildings from "../../../images/newDesign/all_buildings.svg";
import { MenuNavItem } from "../../sidebar/MenuNavItem";
import MaintenanceSidebarMenu from "./maintenanceSidebarMenu";
import { constants } from "../../../common/constants/constants";
import { toggleMobileMenuOpened } from "../../../store/common/slice";
import withdrawal from "../../../images/newDesign/withdrawal.svg";
import {hasPremiumAccessSelector, isAdminSelector} from "../../../store/auth/selectors";
import maintenance from "../../../images/newDesign/maintenance.svg";
import homeIcon from "../../../images/newDesign/homeIcon.svg";

/**
 * @param {string} buildingId
 * @param {boolean} isGreenInvoiceOn
 * @return {JSX.Element}
 * @constructor
 */
export default function PartnerSidebarGroup({ buildingId, isGreenInvoiceOn }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const userProfile = useSelector((state) => state.auth.userProfile);
    const isPremiumUser = useSelector(hasPremiumAccessSelector);
    const isAdmin = useSelector(isAdminSelector);
    // const managerAllBuildings = useSelector(state => state.manager.buildings?.buildings ?? []);
    // const managerAllBuildingsActive = managerAllBuildings.filter(b=> !b.disabled);
    // const managerAllBuildingsCount = managerAllBuildingsActive.length;
    //https://bllink.atlassian.net/browse/SSB-1544
    // const MIN_BUILDINGS_TO_GET_LEAD_OFFER = 20;
    // const shouldShow = userProfile?.partnerID && managerAllBuildings && managerAllBuildingsCount > 0 && managerAllBuildingsCount < MIN_BUILDINGS_TO_GET_LEAD_OFFER;

    const closeSidebarOnMobileClick = () => {
        dispatch(toggleMobileMenuOpened())
    }

    const partnerProfileUrl = useMemo(() => {
        return isPremiumUser ? `/${constants.pages.partnerProfilePage}/${userProfile?.partnerID}` : `/${constants.pages.premiumInfoPage}`
    }, [userProfile, isPremiumUser])

    return (
        <Fragment>
            {/* requested by rayee via email*/}
            {/*<MenuNavItem*/}
            {/*    icon={products_at_discount}*/}
            {/*    url={`/${constants.pages.marketPlace}`}*/}
            {/*    onClick={closeSidebarOnMobileClick}*/}
            {/*    label={t('buildings.nav_bar_products_at_a_discount')}*/}
            {/*/>*/}
            {userProfile?.partnerID && isAdmin ? <MenuNavItem
                icon={maintenance}
                url={partnerProfileUrl}
                onClick={closeSidebarOnMobileClick}
                label={t('buildings.nav_bar_partner_profile')}
            /> : null }
            {/* maybe show this only after a week or 2 logins to bllink userProfile.createdAt*/}
            {userProfile?.isDemoSignUpUser ? <MenuNavItem
                icon={homeIcon}
                url={`/${constants.pages.selfOnBoard}`}
                onClick={closeSidebarOnMobileClick}
                tooltipDisabled={false}
                tooltip={t('buildings.nav_bar_demo_sign_up_real_building_tooltip')}
                label={t('buildings.nav_bar_demo_sign_up_real_building')}
            />  : null }

            {/* removing. omri confirmed verbally to remove*/}
            {/*<MenuNavItem*/}
            {/*    icon={homeIcon}*/}
            {/*    highlight={true}*/}
            {/*    url={`/${constants.pages.insuranceOfferUrl}`}*/}
            {/*    onClick={closeSidebarOnMobileClick}*/}
            {/*    tooltipDisabled={false}*/}
            {/*    tooltip={t('buildings.nav_bar_building_insurance_offer_tooltip')}*/}
            {/*    label={t('buildings.nav_bar_building_insurance_offer')}*/}
            {/*/>*/}

            <MenuNavItem
                    icon={homeIcon}
                    url={`/${constants.pages.serviceProviders}`}
                    onClick={closeSidebarOnMobileClick}
                    tooltipDisabled={false}
                    tooltip={t('buildings.nav_bar_additional_services_tooltip')}
                    label={t('buildings.nav_bar_additional_services')}
                />
            <MenuNavItem
                icon={withdrawal}
                url={`/${constants.pages.fundsEarlyTransferUrl}`}
                onClick={closeSidebarOnMobileClick}
                label={t('buildings.nav_bar_funds_early_transfer')}
                tooltip={t('buildings.upgrade_to_premium')}
                disabled={!isPremiumUser}
                tooltipDisabled={isPremiumUser}
            />
            {/*https://bllink.atlassian.net/browse/SSB-1689 */}
            {userProfile?.isViewOnly ? null : <MaintenanceSidebarMenu buildingId={buildingId}/>}
            {userProfile?.partnerID ?
                <MenuNavItem
                    icon={allBuildings}
                    url={`/${constants.pages.buildingManagerCompanyProfileUrl}/${userProfile.partnerID}` }
                    onClick={closeSidebarOnMobileClick}
                    label={t('buildings.nav_bar_all_buildings_data')}
                    tooltip={t('buildings.upgrade_to_premium')}
                    disabled={!isPremiumUser}
                    tooltipDisabled={isPremiumUser}
                /> : null}
            {/*requested by rayee*/}
            {/*{shouldShow ? <MenuItem*/}
            {/*    icon={<img src={leadOffer} alt="lead offer page"/>}*/}
            {/*    active={isLinkActive(`/${constants.pages.partnerLeadPage}/${userProfile.partnerID}`)}*/}
            {/*    onClick={closeSidebarOnMobileClick}>*/}
            {/*    <NavLink*/}
            {/*        className="nav-link"*/}
            {/*        to={`/${constants.pages.partnerLeadPage}/${userProfile.partnerID}`}>*/}
            {/*        {t('buildings.nav_bar_new_leads')}*/}
            {/*    </NavLink>*/}
            {/*</MenuItem> : null}*/}
        </Fragment>
    );
}
