import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCombobox } from "downshift";
import { LocationAutocompleteStyled } from "./locationAutocomplete.styled";
import { useClient } from "../../common/hooks/useClient";

export function LocationAutocomplete({ onPredictionSelect }) {

    const { t } = useTranslation();
    const client = useClient();

    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState(/** @type {AddressPrediction[]}*/ []);
    const [selectedItem, setSelectedItem] = useState(/** @type {AddressPrediction}*/ null);
    const [debounce, setDebounce] = useState(0);

    const combobox = useCombobox({
        items,
        itemToString: (item) =>  item?.displayText ?? '',
        onInputValueChange: (change) => handleInputChange(change),
        onSelectedItemChange: (change) => handleSelectedItemChange(change),
        circularNavigation: true,
    });

    useEffect(() => onPredictionSelect(selectedItem), [selectedItem])

    async function handleInputChange(change) {
        if (change.inputValue === change.selectedItem?.displayText) {
            return;
        }
        if (change.inputValue === '') {
            setItems([]);
            combobox.closeMenu();
        } else {
            window.clearTimeout(debounce);
            const d = window.setTimeout(async () => {
                setIsLoading(true);
                const result = await client.tenants.autoCompleteAddress(change.inputValue);
                setItems(result?.predictions);
                setIsLoading(false);
            }, 300);
            setDebounce(d);
        }
    }

    function handleSelectedItemChange(change) {
        setSelectedItem(change.selectedItem);
    }

    function handleInputKeyUp(e) {
        if (e.keyCode === 8 || e.keyCode === 46) { // backspace 8, delete 46
            setSelectedItem(null);
        }
    }

    function handleClearClick(e) {
        setSelectedItem(null);
        combobox.setInputValue('');
    }

    function HighlightedText(props) {
        const text = props.children;
        const entries = [];
        let prev = 0;
        for (const m of props.matched) {
            if (prev !== m.offset) {
                entries.push(text.slice(prev, m.offset))
            }
            entries.push(<strong>{text.slice(m.offset, m.offset + m.length)}</strong>);
            prev = m.offset + m.length;
        }
        entries.push(text.slice(prev));
        return (<span>{ entries.map((entry, i) => (<Fragment key={`fragment_${i}`}>{ entry }</Fragment>)) }</span>);
    }

    function ListItem({combobox, item, index, children}) {
        const properties = combobox.getItemProps({ item, index });
        properties.className = combobox.highlightedIndex === index ? 'active' : '';
        return (<li {...properties}>{children}</li>);
    }

    return (
        <LocationAutocompleteStyled {...combobox.getComboboxProps()}>
            <input
                {...combobox.getInputProps()}
                autoFocus={true}
                className="form-control"
                placeholder={t('search.full_address_placeholder')}
                onKeyUp={handleInputKeyUp}
            />
            <span className={isLoading ? 'loader' : ''} />
            <button className={isLoading || selectedItem == null ? 'hidden' : ''} onClick={handleClearClick} />
            <ul {...combobox.getMenuProps()} className={!combobox.isOpen ? 'hidden' : ''}>
                {items.map((item, index) => (
                    <ListItem combobox={combobox} item={item} index={index} key={index}>
                        <HighlightedText matched={item.matched}>{item.displayText}</HighlightedText>
                    </ListItem>
                ))}
            </ul>
        </LocationAutocompleteStyled>
    );
}
