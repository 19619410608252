import { Menu, SidebarContent, SidebarHeader } from "react-pro-sidebar";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import { constants } from "../../common/constants/constants";
import logo from "../../images/newDesign/logo.svg";
import {
    hideLoader, setBlockSidebarCollapsing, setHideListItems,
    setSidebarCollapsed, setSidebarPinned, setSidebarScrollTop, showLoader,
    toggleMobileMenuOpened,
    toggleSidebarPinned
} from "../../store/common/slice";
import pinnedIcon from "../../images/newDesign/pinned.png";
import unpinnedIcon from "../../images/newDesign/unpinned.png";
import { useDispatch, useSelector } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import PartnerSidebarGroup from "./partnerSidebarGroup/partnerSidebarGroup";
import BuildingSidebarGroup from "./buildingSidebarGroup/buildingSidebarGroup";
import ReportSidebarGroup from "./reportsSidebarGroup/reportSidebarGroup";
import AdminSidebarGroup from "./adminSidebarGroup/adminSidebarGroup";
import { SidebarNewStyled } from "./sidebarNew.styled";
import sidebarBg from "../../images/newDesign/sidebar_bg.svg";
import sidebarBgSmall from "../../images/newDesign/sidebar_bg_small.svg";
import Delimiter from "../../common/delimiter";
import { fetchBuildingFeatures, saveCurrentBuilding } from "../../store/buildings/actions";
import CacheData from "../../common/utils/cacheData";
import { BllinkLogger } from "../../common/utils/bllink_loggers";
import { isAllowedToEdit } from "../../common/shared/statelessMethods";
import { useAccessToken } from "../../common/hooks/useAccessToken";
import { fetchManagerBuildings } from "../../store/manager/actions";
import { NewScrollbar } from "../../common/newScrollbar";
import {partnerFeaturesSelector} from "../../store/manager/selectors";

export default function SidebarNew({ buildingId, year, match, apartmentNumber }) {
    const location = useLocation();
    const client = useAccessToken();
    const dispatch = useDispatch();
    const partnerFeatures = useSelector(partnerFeaturesSelector);
    const managerAllBuildingsPending = useSelector((state) => state.manager.buildingsPending);
    const managerData = useSelector(state => state.buildings.managerData);
    const localBuildingID = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const userProfile = useSelector((state) => state.auth.userProfile);
    const isLtrEnabled = useSelector(state => state.common.ltrEnabled);
    const sidebarCollapsed = useSelector(state => state.common.sideBarCollapsed);
    const sidebarPinned = useSelector(state => state.common.sideBarPinned);
    const sideMenuOpened = useSelector(state => state.common.sideMenuOpened);
    const blockSidebarCollapsing = useSelector(state => state.common.blockSidebarCollapsing);
    const mobileMenuOpened = useSelector(state => state.common.mobileMenuOpened);
    const hideListItems = useSelector(state => state.common.hideListItems);

    const [pinned, setPinned] = useState(sidebarPinned);
    const [toggled, setToggled] = useState(false);
    const [isGreenInvoiceOn, setIsGreenInvoiceOn] = useState(true);
    const [navData, setNavData] = useState({});

    const getFirstApartment = () => {
        if (localBuildingID) {
            return (managerData.allNumbers && managerData.allNumbers.length > 0) ? managerData.allNumbers[0] : '1';
        }
    };

    /**
     * @param {number} scrollTop
     */
    const handleScroll = ({ scrollTop }) => {
        dispatch(setSidebarScrollTop(scrollTop));
    }

    const handleMouseLeave = () => {
        !pinned && !sideMenuOpened && !blockSidebarCollapsing && dispatch(setSidebarCollapsed(true));
        if (!sideMenuOpened && blockSidebarCollapsing) {
            dispatch(setBlockSidebarCollapsing(false));
        }
    }

    useEffect(async () => {
        if (localBuildingID) {
            dispatch(showLoader());
            await dispatch(fetchBuildingFeatures({ buildingID: localBuildingID }));
            dispatch(hideLoader());
        }
    }, [localBuildingID]);

    useEffect(() => {
        (async function () {
            if (!localBuildingID || !client) return;
            CacheData.cache('currentBuilding', localBuildingID);

            BllinkLogger.info(`get data for buildingID ${localBuildingID}`);
            saveCurrentBuilding(localBuildingID);

            const canEdit = isAllowedToEdit(managerData.managerRole);
            const firstApartmentNumber = getFirstApartment();
            setNavData({
                firstApartmentNumber,
                oneTimeSettings: managerData.oneTimeSettings || [],
                managerData: managerData,
                isAllowedToEdit: canEdit
            });
        })();
        return () => {
            setNavData({});
        };
    }, [managerData, localBuildingID, client]);

    useEffect(async () => {
        if (client == null) return;
        // todo what is this condition?
        if (userProfile != null && !managerAllBuildingsPending) {
            dispatch(showLoader());
            // TODO: move this fetch to the ManagerPage
            await dispatch(fetchManagerBuildings());
            dispatch(hideLoader());
        }
    }, [userProfile, client]);

    useEffect(() => {
        const IsGreenInvoiceOnFeatureValue = !!partnerFeatures?.['partnerInvoiceServiceParams'];
        setIsGreenInvoiceOn(IsGreenInvoiceOnFeatureValue)
    }, [partnerFeatures])

    useEffect(() => {
        if (pinned !== sidebarPinned) setPinned(sidebarPinned);
    }, [sidebarPinned])

    useEffect(() => {
        if (mobileMenuOpened) dispatch(setSidebarCollapsed(false));
        setToggled(mobileMenuOpened);
    }, [mobileMenuOpened])

    useEffect(() => {
        if (!blockSidebarCollapsing && !pinned && !sideMenuOpened) {
            dispatch(setSidebarCollapsed(true));
        }
    }, [blockSidebarCollapsing, sideMenuOpened, pinned]);

    useEffect(() => {
        const savedSideBarPinned = CacheData.fetchCache(constants.cache.keys.general.sideBarPinned);
        if (savedSideBarPinned === true || savedSideBarPinned === false) {
            dispatch(setSidebarPinned(savedSideBarPinned))
        }
    }, [])

    useEffect(() => {
        dispatch(setHideListItems(false));

        const pages = [`/${constants.pages.allBuildingsUrl}`, `/${constants.pages.buildingManagerCompanyProfileUrl}/:partnerID`, `/${constants.pages.rewards}`]
        const isUrlMatch = pages.some(page => !!matchPath(location.pathname, {
            path: page,
            exact: true,
            strict: true
        }));

        if (isUrlMatch || match.params.partnerID || !localBuildingID) dispatch(setHideListItems(true));
    }, [location, match, localBuildingID]);

    return (
        <SidebarNewStyled
            id={'sidebar-content'}
            breakPoint={'md'}
            toggled={toggled}
            collapsed={sidebarCollapsed}
            onMouseEnter={() => !pinned && dispatch(setSidebarCollapsed(false))}
            onMouseLeave={handleMouseLeave}
            rtl={!isLtrEnabled}
            onToggle={() => dispatch(toggleMobileMenuOpened())}
        >
            <NewScrollbar
                width={'100%'}
                height={'100vh'}
                mobileNative={true}
                noScrollX={true}
                onScroll={handleScroll}
                rtl={!isLtrEnabled}
            >
                <SidebarHeader onClick={() => {
                }}>
                    <NavLink to={`/${constants.pages.allBuildingsUrl}`} className={'logo-link'}>
                        <img src={logo} alt="logo"/>
                    </NavLink>
                    <div
                        className='pin-icon'
                        onClick={() => dispatch(toggleSidebarPinned())}
                    >
                        {
                            pinned ? (
                                <img src={pinnedIcon} alt="pinned"/>
                            ) : (
                                <img src={unpinnedIcon} alt="unpinned"/>
                            )
                        }
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <div className={'bg'}>
                        <img src={sidebarBg} alt="bg"/>
                    </div>
                    <div className={'bg-small'}>
                        <img src={sidebarBgSmall} alt="bg small"/>
                    </div>
                    <Menu iconShape="square">
                        <PartnerSidebarGroup buildingId={localBuildingID}/>
                        <Delimiter padding={'20px 24px'}/>
                        <BuildingSidebarGroup buildingId={localBuildingID} navData={navData} match={match}/>
                        {localBuildingID && !hideListItems ? <Fragment>
                            <Delimiter padding={'20px 24px'}/>
                            <ReportSidebarGroup
                                buildingId={localBuildingID}
                                year={year}
                                navData={navData}
                                apartmentNumber={apartmentNumber}
                                isGreenInvoiceOn={isGreenInvoiceOn}/>
                        </Fragment> : null}
                        {!hideListItems ? <Delimiter padding={'20px 24px'}/> : null}
                        <AdminSidebarGroup buildingId={localBuildingID} year={year} hideListItems={hideListItems}/>
                    </Menu>
                </SidebarContent>
            </NewScrollbar>
        </SidebarNewStyled>
    )
}
