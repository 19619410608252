import {footer} from "./common/footer";
import {header} from "./common/header";
import {signup} from "./common/signup";
import {login} from "./common/login";
import {months} from "./common/months";
import {monthsShort} from './common/monthsShort';
import {banks} from "./common/banks";
import {cardPage} from "./tenant/payment/cardPage";
import {buildingPayment} from "./tenant/payment/buildingPayment";
import {titles} from "./common/titles";
import {rewards} from "./common/rewards";
import {errors} from "./common/errors";
import {searchPage} from "./tenant/search";
import {buildings} from "./manager/buildings";
import {reports} from "./manager/reports";
import {offlinePayments} from "./manager/offline_payments";
import {expenses} from "./manager/expenses";
import {paymentSettings} from "./manager/payment_settings";
import {warningLetter} from './manager/warning_letter';
import {buildingBrochure} from './manager/building_brochure';
import {managerRewards} from './manager/rewards';
import {searchPayments} from "./admin/searchPayments";
import {navBar} from './admin/navBar';
import {autoCampaign} from "./admin/autoCampaign";
import { messagePlatform } from './manager/messagePlatform';
import {adminReports} from './admin/adminReports';
import {editBuilding} from "./admin/editBuilding";
import {userActions} from "./admin/userActions";
import {createPartner} from "./admin/createEditPartner"
import {creditCardChoice} from "./manager/creditCardChoice";
import {statuses} from './common/statuses';
import {comments} from './manager/comments';
import {autoCharge} from './manager/autoCharge';
import {notifications} from './manager/notifications';
import {chargeExplain} from './common/chargeExplain';
import {currency} from './common/currency';
import { managerAutoCampaign } from "./manager/autoCampaign";
import { monitoring } from './manager/monitoring';
import {paymentMethods} from "./common/paymentMethods";
import { maintenance } from "./common/maintenance";
import { buttons } from './common/buttons';
import { archive } from "./manager/archive";
import {sharedMobile} from "./common/shared_mobile";
import {container} from "./tenant/container";
import {globalSearch} from "./manager/globalSearch";
import {premiumInfo} from "./manager/premiumInfo";
import {partnerPaymentSettings} from "./manager/partnerPaymentSettings";
import {store} from "./tenant/store";
import {chatBot} from "./manager/chatBot";

export const en = {
  translation: {
      BCP47LOCALE: 'en', // IETF BCP47 language tag (acceptable by Intl class)
      "direction": 'ltr',
      "common": {
          "months": months,
          "monthsShort": monthsShort,
          "banks": banks,
          "chargeExplain": chargeExplain,
          "paymentMethods": paymentMethods,
          "buttons": buttons,
      },
      "currency": currency,
      "search": searchPage,
      "payments": buildingPayment,
      "rewards": rewards,
      "card_page": cardPage,
      "sharedMobile": sharedMobile,
      "store": store,

      "header": header,
      "footer": footer,
      "signup": signup,
      "login": login,
      "titles": titles,
      "errors": errors,
      "statuses": statuses,
      "maintenance": maintenance,

      // managers
      "archive": archive,
      "buildings": buildings,
      "reports": reports,
      "offline_payments": offlinePayments,
      "expenses": expenses,
      "payment_settings": paymentSettings,
      "letter": warningLetter,
      "brochure": buildingBrochure,
      "manager_rewards": managerRewards,
      "creditCardChoice": creditCardChoice,
      "comments": comments,
      "auto_charge": autoCharge,
      "notifications": notifications,
      "managerAutoCampaign": managerAutoCampaign,
      "monitoring": monitoring,
      "premiumInfo": premiumInfo,
      "partnerPaymentSettings": partnerPaymentSettings,
      "chatBot": chatBot,

      //admins
      "searchPayments": searchPayments,
      "navBar": navBar,
      "autoCampaign": autoCampaign,
      "messagePlatform": messagePlatform,
      "adminReports": adminReports,
      "editBuilding": editBuilding,
      "userActions": userActions,
      "createPartner": createPartner,
      "globalSearch": globalSearch,

      // tenant area
      "tenant": {
          container,
      },
  }
};
