export const notifications = {
    "tenantDetailsFor": "Tenant details - apt",
    "name": "Name",
    "phone": "Phone",
    "email": "Email",
    "renter": "Renter",
    "owner": "Owner",
    "showAll": "Show all buildings",
    "chooseBuilding": "Choose building",
    "more_apartment_details": "More Apt details",
    "sendRequest": "Send payment request",
    "recordPayment": "Record payment",
    "charge": "Credit card Charge",
    "emptyList": "No records for selected building",
    "sendWarningLetter": "Send warning letter",
    "title": "Notifications",
    "apartment": "Apartment",
    "warningLetterTitle": "Warning letter",
    "sendWarningLetterTo": "Send warning letter to",
    "dismiss": "Dismiss",
    "commentDismissed": "Comment successfully dismissed",
};
