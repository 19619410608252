export const buildingBrochure = {
    "subtitle": "השירות שלנו",
    "fast_pay": "תשלום מהיר באמצעות בלינק מאפשר לבניין לקבל את הכספים ישירות לחשבון הבנק של הבניין ולנהל בצורה חכמה ונכונה את הכספים.",
    "fast_pay_1": "תשלום מהיר באמצעות בלינק",
    "fast_pay_2": "מאפשר לבניין לקבל את הכספים ישירות",
    "fast_pay_3": "לחשבון הבנק של הבניין ולנהל בצורה",
    "fast_pay_4": "חכמה ונכונה את הכספים.",
    "qr_title": "לביצוע התשלום במהירות - סרוק את הקוד או צור איתנו קשר",
    "card": "תשלום בכרטיס אשראי",
    "invoice": "קבלות ישירות למייל",
    "reminders": "תזכורת תשלום למניעת חוב",
    "pci": "תשלום מאובטח בתקן PCI העולמי",
    "fileName": "עלון_לבנין",
    "phone": "לביצוע התשלום במהירות - סרוק את הקוד",
    "wire": "תשלום בהעברה בנקאית",
    "title": "הודעת תשלום עבור ועד הבית"
};
