import {useEffect, useState} from "react";
import ServerData from "../serverData";
import {SETTINGS} from "../settings";

/**
 * @param {string} [accessToken=""]
 * @return {ServerData}
 * */
export function useClient(accessToken = '') {
    const [client, setClient] = useState(new ServerData(null, null, { accessToken }));

    if (SETTINGS.bllink.exposeClient) {
        useEffect(function() {
            window.apiClient = client;
        }, [accessToken]);
    }

    const oldAuthz = client.axios.defaults.headers.Authorization ?? 'Bearer ';
    const newAuthz = `Bearer ${accessToken ?? ''}`;

    if (oldAuthz !== newAuthz) {
        const newClient = new ServerData(null, null, { accessToken });
        setClient(newClient);
        return newClient;
    }
    return client;
}
