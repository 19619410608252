import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import {NotificationManager} from 'react-notifications';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {getAuthenticatedUser} from '../../store/auth/actions';
import ServerData from '../../common/serverData';
import CacheData from '../../common/utils/cacheData';
import {Confetti} from '../../tenants/tenant_payments/rewards/confetti';
import {isAdminSelector} from '../../store/auth/selectors';
import {BllinkLogger} from '../../common/utils/bllink_loggers';

export default function RewardsNotification() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const accessToken = useSelector((state) => state.auth.accessToken);
    const isAdmin = useSelector(isAdminSelector);
    const [hasNewCoins, setHasNewCoins] = useState(false);

    let client = new ServerData(null, null, {accessToken: accessToken});

    useEffect(() => {
        (async function () {
            if (!accessToken) {
                try {
                    const {payload} = await dispatch(getAuthenticatedUser());
                    client = new ServerData(null, null, {accessToken: payload.accessToken});
                } catch (e) {
                    BllinkLogger.info(`User is not authenticated`);
                    return;
                }
            }

            document.addEventListener('pointsGained', (e) => {
                e.stopImmediatePropagation();
                getCoinValue();
            });
        })();
        return () => document.removeEventListener('pointsGained', getCoinValue);
    }, [location.pathname]);

    const fetchAndSave = async () => {
        // meant to set it in cache for the first time only
        return CacheData.getOrCache(async () => {
                const response = await client.get('/managers/rewards/campaigns/active', {}, {avoidError: true});
                return response?.coinBalance?.[0]?.coinValue ?? 0;
            },
            'rewardsCoinValue'
        );
    }

    const getCoinValue = async () => {
        const cachedVal = await fetchAndSave();
        let response;
        BllinkLogger.info(`cached val is `, cachedVal);

        if (cachedVal && cachedVal.expired) {
            response = await client.get('/managers/rewards/campaigns/active', {}, {avoidError: true});
            let serverUpdatedPoints = response?.coinBalance?.[0]?.coinValue ?? 0;
            let cachedPoints = cachedVal.value;
            let userGotMorePoints = cachedPoints < serverUpdatedPoints;
            if (userGotMorePoints) {
                let newPoints = parseInt(serverUpdatedPoints - cachedPoints);
                setHasNewCoins(true);
                NotificationManager.success(t('manager_rewards.new_coins_gained', {newPoints: newPoints}), '', 10000);
                CacheData.cache('rewardsCoinValue', serverUpdatedPoints);

                // hide notifications
                setTimeout(() => setHasNewCoins(false), 10000);
            } else {
                BllinkLogger.info(`diff between cachedPoints ${cachedPoints} and serverUpdatedPoints ${serverUpdatedPoints} is 0 or negative`);
            }
        }
    }

    if (!hasNewCoins || !isAdmin) return null;

    return (
        <>
            <Confetti />
        </>
    )
}
