import dayjs from 'dayjs';

import { constants } from '../constants/constants';
import {BllinkLogger} from "./bllink_loggers";
import CacheData from './cacheData';

class BllinkTime{

    static addHours(date, hoursToAdd){
        date.setTime(date.getTime() + (hoursToAdd*60*60*1000));
        return date
    }

    /**
     *  "2023-03-16T05:52:04.000Z" to "2023-03-16"
     * @param timeA
     */
    static parseTimeAsDay(timeA){
        // Parse the input string to a Date object
        try {
            const date = new Date(timeA);
            // Extract the date in the desired format (YYYY-MM-DD)
            return date.toISOString().split('T')[0];
        }catch (e) {
            return timeA
        }
    }

    static getNextMonth(timeA){

        const timeAMonth = parseInt(timeA.month);
        const timeAYear = parseInt(timeA.year);
        const isLastMonthOfYear = (timeAMonth === constants.FINAL_MONTH_IN_YEAR)
        let result =  {
            month: isLastMonthOfYear ? constants.FIRST_MONTH_IN_YEAR: timeAMonth + 1,
            year: isLastMonthOfYear ? timeAYear + 1 : timeAYear,

        }
        if (timeA.day){
            const lastDayOfMonth = new Date(result.year, result.month, 0).getDate();
            result.day = Math.min(lastDayOfMonth, timeA.day);
        }
        return result
    }

    static getPreviousMonth(timeA){

        const isFirstMonthOfYear = (parseInt(timeA.month) === constants.FIRST_MONTH_IN_YEAR)
        return {
            month: isFirstMonthOfYear ? constants.FINAL_MONTH_IN_YEAR : timeA.month - 1,
            year: isFirstMonthOfYear ? timeA.year - 1 : timeA.year,
            day: timeA.day || 1
        }
    }

    static isNextMonth(timeA, timeB){

        const nextMonthB = BllinkTime.getNextMonth(timeB)
        return (
            nextMonthB.month === parseInt(timeA.month) &&
            nextMonthB.year === parseInt(timeA.year)
        )

    }

    static isConsecutive(months){
        // assumes is sorted

        BllinkLogger.info(`checking if isConsecutive for months `, months);
        if (!months){
            return false
        }
        if (months.length <= 1){
            return false
        }
        let currentMonth = months[0]

        for (const month of months.slice(1)){
            if (!BllinkTime.isNextMonth(month, currentMonth)){
               return false
            }

            currentMonth = month
        }

        return true;
    }

    static isSmallerThan(timeA, timeB) {
        /*
            is timeA < timeB
        */

        if (timeA.year > timeB.year) {
            return false;
        } else if (timeA.year < timeB.year) {
            return true;
        }

        let monthA = parseInt(timeA.month);
        let monthB = parseInt(timeB.month);
        let hasDaysData = timeA.day && timeB.day;

        if (hasDaysData) {
            if (monthA < monthB) {
                return true;
            } else if (monthA > monthB) {
                return false;
            } else {
                return parseInt(timeA.day) < parseInt(timeB.day);
            }
        } else {
            return (monthA < monthB);
        }
    }

    static isSameMonth(timeA, timeB){
        const Year_A = parseInt(timeA.year);
        const Year_B = parseInt(timeB.year);
        const Month_A = parseInt(timeA.month);
        const Month_B = parseInt(timeB.month);

        return (
            Year_A === Year_B &&
            Month_A === Month_B
        );
    }

    static isSmallerEqual(timeA, timeB){
        return (this.isSameMonth(timeA, timeB) || this.isSmallerThan(timeA, timeB))
    }

    static compareTime(timeA, timeB){
        const isSmaller = BllinkTime.isSmallerThan(
                {month: timeA.month, year:timeA.year},
                {month: timeB.month, year:timeB.year}
            )

        return isSmaller ? -1 : 1;
    }

    /**
     * @param {DateStruct[]}
     * @return {DateStruct[]}
     * */
    static sortMonths(months){

        months.sort(BllinkTime.compareTime);

        BllinkLogger.info(`sorted months are `, months)
        return months;
    }

    static renderShortDate(timeA){
        // return mm/yyy
        // for example 06/20 or 01/21
        BllinkLogger.info(`time to render`, timeA);
        return `${timeA.month}/${timeA.year.toString().slice(-2)}`
    }

    static getCurrentYear(){
        let dateObj = new Date();
        return dateObj.getYear() + 1900;
    }

    static getCurrentMonth(){
        let dateObj = new Date();
        return dateObj.getMonth() + 1;
    }

    static getCurrentDay(){
        // Provides day of the month values 1-31.
        let dateObj = new Date();
        return dateObj.getDate();
    }

    static getTimeNow(){
        return {
            month: BllinkTime.getCurrentMonth(),
            year: BllinkTime.getCurrentYear(),
            day: BllinkTime.getCurrentDay(),
        }
    }

    static parseMonth(timeAOrList){
        if (Array.isArray(timeAOrList)){
            return timeAOrList.map(x => {return BllinkTime.parseMonth(x)})
        }

        let data = {month: parseInt(timeAOrList.month), year: parseInt(timeAOrList.year)}

        if (timeAOrList.part_pay || timeAOrList.reward_pay){
            data = {
                ...data,
                // to support partial payment
                // https://app.asana.com/0/1196545425570329/1199334179749068
                part_pay: timeAOrList.part_pay,
                /// https://app.asana.com/0/1196545425570329/1200307774346154 reward experiment
                reward_pay: timeAOrList.reward_pay
            }
        }

        return data
    }

    static splitDate (t = new Date()) {
        // We cannot just split ISO formatted string as we will lose timezone
        return [
            String(t.getFullYear()),
            String(t.getMonth() + 1).padStart(2, '0'),
            String(t.getDate()).padStart(2, '0'),
            String(t.getHours()).padStart(2, '0'),
            String(t.getMinutes()).padStart(2, '0'),
            String(t.getSeconds()).padStart(2, '0'),
        ];
    }

    static parseDate(dataAsString, options) {
        options = options || {};
        if (!dataAsString) return null

        // in case if dataAsString is not valid for toISOString method
        try {
            if (options.includeTime){
                const [yyyy, mm, dd, hh, min, sec] = BllinkTime.splitDate(new Date(dataAsString));
                return `${dd}/${mm}/${yyyy} ${hh}:${min}:${sec}`
            }else {
                const [yyyy, mm, dd] = BllinkTime.splitDate(new Date(dataAsString));
                return `${dd}/${mm}/${yyyy}`;
            }
        } catch (e) {
            return null;
        }
    }


    static getNextMonthForString(dataAsString){
        /*
            input is from cheque form
            21/01/21

         */
        // console.log(`getNextMonthForString input ${dataAsString}`);
        let parts = dataAsString.split('/')
        let nextMonth = BllinkTime.getNextMonth({month: parts[1], year: parts[2]})
        // console.log(`nextMonth `, nextMonth);
        return `${parts[0]}/${nextMonth.month}/${nextMonth.year}`
    }

    static parseDateToBllinkTime(dataAsString){
        /*

         */
        if (!dataAsString){
            return
        }
        const [yyyy, mm, dd] = BllinkTime.splitDate(new Date(dataAsString));

        return {
            month: parseInt(mm),
            year: parseInt(yyyy),
            day: parseInt(dd),
        }

    }

    static parseIsraeliDateToDate(dataAsString) {
        /*
            input format is dd/mm//yyyy (vs mm/dd/yyyy)
            output is mm/dd/yyyy
         */
        if (!dataAsString) {
            return
        }

        const parts = dataAsString.split('/');
        const month = parts[1], day = parts[0], year = parts[2];
        // 12:00:00 was added and is hard coded due to
        // bug with time zones when adding an offline payment
        // thought it was last day
        return `${month}/${day}/${year} 12:00:00`
    }

    static toDate(string, format, delimiter) {
        let formattedDate = null;
        const formatLowerCase = format.toLowerCase();
        const formatItems = formatLowerCase.split(delimiter);
        const dateItems = string.split(delimiter);
        const monthIndex = formatItems.indexOf('mm');
        const dayIndex = formatItems.indexOf('dd');
        const yearIndex = formatItems.indexOf('yyyy');
        let d = dateItems[dayIndex];
        let month;
        if (d < 10) {
            d = '0'+ d;
        }
        if (monthIndex > -1) {
            month = parseInt(dateItems[monthIndex]);
            month -= 1;
            if (month < 10) {
                month = '0' + month;
            }
            formattedDate = new Date(dateItems[yearIndex], month, d);
        }
        return formattedDate;
    }

    static toJsDateFromFormatted(string) {
        // accept string in dd/mm/yyyy
        return new Date(string.split('/').reverse().join('/'));
    }

    static isPastDate(month, year) {
        const date = new Date();
        const currentMonth = date.getMonth() + 1;
        const currentYear = date.getFullYear();

        // [bug] apartment report - payments defined for the past
        return (month < currentMonth && year <= currentYear) || (year < currentYear);
    }

    static getTimeZoneOffset(date) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    }

    static getFutureChargeDate(cacheOptions) {
        let charge_date = CacheData.fetchCache('onGoingChargeDate', {buildingID: cacheOptions.buildingID, apartmentNumber: cacheOptions.apartmentNumber});
        charge_date = charge_date?.selected || charge_date?.default;
        charge_date = dayjs(charge_date).startOf('day').toDate();
        return dayjs(charge_date).isAfter(new Date()) ? BllinkTime.getTimeZoneOffset(charge_date) : null;
    }

    /**
     * consider only date, not time
     * @param {Date | string} date
     * @return {boolean}
     */
    static isDateIsInFuture(date) {
        return dayjs().isBefore(dayjs(date), 'date');
    }
}


export default BllinkTime
