import styled from 'styled-components';

export const StyledNav = styled.nav`
  width: 100%;
  padding: 0;
  direction: ltr;
  border-bottom: 1px solid var(--bllink-blue);
  border-top: 1px solid var(--bllink-blue);
  
  ul {
    width: 100%;
    justify-content: space-between;
  }
  
  .nav-item {
    position: relative;
    padding: 0 20px 0 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    border: 1px solid transparent;
    border-bottom: none;
    transition: all .3s ease-in;
    .nav-link {
      position: relative;
      height: 100%;
      padding: 16px 0 12px 0;
      font-family: var(--bllink-font-primary);
      font-size: 14px;
      line-height: 23px;
      text-align: right;
      color: var(--bllink-gray);
      border-bottom: 4px solid transparent;
      transition: border .2s ease-in;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      z-index: 10;
      img {
        max-width: 25px;
        margin-left: 10px;
      }
      &.active {
        border-bottom-color: #6A6FE1;
      }
    }
    &.n_nav_r {
      padding-left: 16px;
      min-width: 160px;
      .nav-link {
        padding-left: 30px;
      }
    }
    &.show {
      border-color: #6F8BFF;
      background-color: #fff;
      > .nav-link {
        border-bottom-color: #6A6FE1;
      }
    }
  }
  .dropdown-toggle::after {
    display: inline-block;
    content: '';
    position: absolute;
    left: 0;
    top: 20px;
    border-top: 1px solid #777fff;
    border-right: 1px solid #777fff;
    border-bottom: 0;
    border-left: 0;
    width: 10px;
    height: 10px;
    transform: rotate(135deg);
  }
  .dropdown-menu {
    display: block;
    visibility: hidden;
    width: calc(100% + 2px);
    max-height: 300px;
    margin: 0;
    padding: 0;
    left: -1px;
    top: 0;
    border-radius: 0 0 25px 25px;
    border-top: none;
    border-color: #6F8BFF;
    transition: all .3s ease-in;
    opacity: 0;
    z-index: -1;
    overflow-x: hidden;
    overflow-y: auto;
    &.show {
      top: 98%;
      visibility: visible;
      opacity: 1;
      z-index: 10000;
    }
  }
  .dropdown-item {
    direction: rtl;
    padding: 16px 15px;
    text-align: right;
    &:not(:last-child) {
      border-bottom: 1px solid #A8C0FF;
    }
    &.active, &:focus {
      background-color: #A8C0FFFF;
    }
  }
`;
