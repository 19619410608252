export class TenantRecordsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param { number } buildingId
     * @param { number } start
     * @param { number } end
     * @return {Promise<unknown>}
     * */
    getTenantRecordForBuilding(buildingId, start, end) {
        return this.#client.get(`/managers/manager-confirm/${buildingId}`, { end, start });
    }

    /**
     * @param { number } buildingId
     * @param { number } paymentPlanId
     * @param { 'confirm' | 'deny' } payload
     * @return {Promise<unknown>}
     * */
    postSolutionForTenantRecord(buildingId, paymentPlanId, payload) {
        return this.#client.post(`/managers/manager-confirm/${buildingId}/${paymentPlanId}`, payload);
    }
}
