export const footer = {
    "how_it_works": "איך זה עובד",
    "why_worth_it": "למה זה שווה את זה",
    "q_and_a": "שאלות ותשובות",
    "policy": "מדיניות האתר",
    "accessibility": "הצהרת נגישות",
    "support": "תמיכה",
    "all_rights": "© {{currentYear}} כל הזכויות שמורות",
    "privacy": "פרטיות",
    "share": "שיתוף",
    "copied_to_clipboard": "הועתק בהצלחה",
    "languagePlaceholder": "בחר\/י שפה",
    "languages": {
        "en": "English",
        "he": "עברית",
        "es": "Español"
    }
};
