import React from 'react';
import phone from '../images/phone.png';
import emoji from '../images/emoji.png';
import logoBllink from '../images/Logo-Full-Dark.png';
import defaultAvatar from '../images/default_avatar.svg';
import logoutIcon from '../images/Union.svg';
import {withTranslation} from 'react-i18next';
import Parent from './parent';
import {Auth} from 'aws-amplify';
import { constants } from './constants/constants';
import loginLogic from '../managers/login/loginLogic';
import './header.scss';
import {BllinkLogger} from "./utils/bllink_loggers";
import CacheData from "./utils/cacheData";
import $ from 'jquery';
import {GAEvent} from "./google_analytics/google_analytics";


class LoggedInHeader extends Parent{

    constructor(props) {
        super(props);
        this.bindLogout.bind(this);
    }


    async componentDidMount() {
        this.bindLogout();
    }


    bindLogout(){
        const thisObj = this;
        $('#js-logout').click(async (e)=>{
            e.preventDefault();
            GAEvent(constants.GoogleAnalytics.Events.login, 'logout', 1, 'logout');
            CacheData.delete('profile');
            try {
                thisObj.startLoader();
                window.localStorage.removeItem('managerLoggedIN');
                await Auth.signOut();
                thisObj.endLoader();
            }catch (e){
                BllinkLogger.warn(`sign out error `, e)
            }
        })
    }

    logout(){
        try{
            Auth.signOut();
            // to return the commbox
            window.localStorage.removeItem('managerLoggedIN');
        }catch (e){
            BllinkLogger.error(e)
        }
    }

    render(){
        const {t} = this.props;
        const profile = this.props.profile || {};
        const avatar = profile.partnerLogo || defaultAvatar;
        const userName = profile.userFirstName || this.props.user.name;

        return (
            <div className="col-sm-5 left_header d-none d-md-block">
                <ul>
                    <li>
                        <span className="capitalize">{t('login.hi', {firstName: userName})}</span>
                        <span className="helloEmoji" role="img"  aria-label="helloEmoji">
                             <img src={emoji} />
                        </span>
                    </li>
                    <li><a href={`/${constants.pages.allBuildingsUrl}`}><img className="userLogo" src={avatar} alt="company logo"/></a></li>
                    <li id="js-logout">
                        <a href={`/${constants.pages.loginUrl}`} onClick={this.logout}>
                            <img src={logoutIcon} alt={t('login.logout')}/>
                        </a>
                    </li>
                </ul>
            </div>
        )
    }
}

class LoggedOutHeader extends Parent{

    render(){
        const {t} = this.props;

        const ltr = document.body.classList.contains('leftToRight')

        let mcLogo
        if (this.props.buildingID){
            const cache = CacheData.fetchCache('buildingData', {buildingID: this.props.buildingID});
            mcLogo = cache?.managementCompany?.logo;
        }

        return (
            <div className="col-sm-5 col-6 header-2">
                <div className="header-left">
                    <a href="tel:+972-3-5090045" className='padding phone-section'><img alt="" src={phone} /><span>+972-3-5090045</span></a>
                    {this.props?.removeCallBtn && this.props?.addLogo && mcLogo ?
                    <img src={mcLogo} className="top-mc-logo" />
                    : this.props?.removeCallBtn && !this.props?.addLogo ?
                    <></>
                    : (
                        <a rel="noopener noreferrer"
                           target="_blank"
                           href={`${constants.pages.mainBllinkSite}/${ltr ? 'us/' : ''}${constants.pages.contactUs}`}
                           className="top-btn-set"
                        >
                            {t('header.question_for_representative')}
                        </a>
                    )}
                </div>
            </div>
        )
    }
}

class CurrentBuilding extends Parent {
    // this section is meant to help the manger
    // with context as to which building she is
    // currently watching at
    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        let title;
        if (this.props.buildingID) {
            title = await this.buildingTitleText(this.props.buildingID);
        } else {
            title = await this.getCurrentBuildingTitle();
        }
        if (title) {
            this.setState({buildingTitle: title});
        }

        BllinkLogger.info(`title is `, title);
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.buildingID !== this.props.buildingID) {
            const title = await this.buildingTitleText(this.props.buildingID);
            this.setState({buildingTitle: title});
        }
    }

    render() {
        return (
            <p className="building-title-in-header" id="building-title">
                {this.state.buildingTitle}
            </p>
        )
    }
}

const LoggedOutHeaderT = withTranslation()(LoggedOutHeader)
const LoggedInHeaderT = withTranslation()(LoggedInHeader)
const CurrentBuildingT = withTranslation()(CurrentBuilding)


export class Header extends Parent {

    constructor(props) {
        super(props);
        this.closeHeaderMessage = this.closeHeaderMessage.bind(this);
        this.headerAllJsRef = React.createRef();
    }

    async componentDidMount() {
        loginLogic.logLogoClicked();
        const user = await this.getLoggedInUser()
        const userProfile = await this.getUserInfo()
        this.setState({
            user: user,
            userProfile: userProfile,
            loadedUser: true,
            // approved by rayee. to increase conversion rate on payments
            showHeaderMessage: false
        })

        this.detectTripleClick();
    }

    detectTripleClick(){
        console.log('detectTripleClick');
        // to enable logic from the app
        const TOUCH_TIMEOUT_MILLISECONDS = 500;
        let touch_count = 0;
        const eventHandler = evt => {
            touch_count += 1;
            setTimeout(() => touch_count = 0, TOUCH_TIMEOUT_MILLISECONDS);
            if (touch_count === 3) {
                window.location.pathname = `/${constants.pages.loginUrl}`;
            }
        };
        if (this.headerAllJsRef.current == null) {
            console.error('#header-all-js element not found');
        } else {
            this.headerAllJsRef.current.addEventListener('click', eventHandler);
            this.headerAllJsRef.current.addEventListener('touchend', eventHandler);
        }
    }

    closeHeaderMessage() {
        this.setState({
            ...this.state,
            showHeaderMessage: false
        })
    }

    render() {
        let leftHeader = '', currentBuilding = '';
        if (this.state.loadedUser){
            const isLoggedIn = this.state.user;
            leftHeader =  isLoggedIn ?
                <LoggedInHeaderT user={this.state.user} profile={this.state.userProfile}/> :
                <LoggedOutHeaderT removeCallBtn={this.props?.removeCallBtn} buildingID={this.props.buildingID} addLogo={this.props?.addLogo} />;

            currentBuilding = isLoggedIn ? <CurrentBuildingT buildingID={this.props.buildingID} /> : '';
        }

        let logo;
        if (this.state.user){
            logo = <a id="main-logo" href={`/${constants.pages.allBuildingsUrl}`}>
                <img alt="bllink-logo" className="img-responsive-header" src={logoBllink} />
            </a>
        }else{
            logo = <a id="main-logo" href={constants.pages.mainBllinkSite} target={"_blank"} rel="noopener noreferrer">
                <img alt="bllink-logo" className="img-responsive-header" src={logoBllink} />
            </a>
        }

        return(
            <header>
                <div className="container" id="header-all-js" ref={this.headerAllJsRef}>
                    <div className="row logo-container">
                        <div className="col-sm-2 col-6 header-1">
                            <div className="header-right logo-img">
                                {logo}
                            </div>
                        </div>
                        <div className="col-sm-5 d-none d-md-block">
                            {currentBuilding}
                        </div>
                        {leftHeader}
                    </div>
                </div>
            </header>
        )
    }

}


export default withTranslation()(Header);
