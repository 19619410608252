export class ReportsApi {

    /**
     * @typedef {{
     * from: { year: number; month: number };
     * to: { year: number; month: number };
     * }} DateRange
     */

    /**
     * @typedef {{
     * description: string;
     * longDescription: string;
     * oneTimeId: number;
     * totalToPay: {
     *      currency?: string;
     *      value: number;
     * };
     * }} OneTime
     */

    /**
     * @typedef {{
     * bankCode?: string;
     * branchId?: string;
     * accountId?: string;
     * cardType?: string;
     * lastNums?: string;
     * expireDate?: string;
     * chequeNum?: number;
     * wireConfirmationNum?: number;
     * }} DepositsExtraDetails
     */

    /** @type {ServerData} */ #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} buildingId
     * @param {number} paymentId
     * @return {Promise<string>}
     * */
    getInvoiceUrl(buildingId, paymentId) {
        return this.#client.get(`/managers/reports/invoice-url/${buildingId}/${paymentId}`, {}, { throwError: true });
    }

    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * @return {Promise<unknown>}
     * */
    getBalance(buildingId, year, month) {
        // changed to a bug in production
        return this.#client.get(`/managers/reports/balance/${buildingId}`, { year, month });
    }

    /**
     * @typedef { value: CurrencyValue, needed: CurrencyValue } StatsPaymentBalance
     * @typedef { 'ongoing' | 'onetime' | 'pastYearsDebt' } StatsIncomeType
     * @typedef {{ paid: number, paymentMethod: string, currency: string }[]} StatsPaymentMethods
     * @typedef {{ value: number, paid: number, currency: string, methods: StatsPaymentMethods }} StatsTotalPart
     * */

    /**
     * @param {string} buildingId
     * @param {number} fromYear
     * @param {number} fromMonth
     * @param {number} toYear
     * @param {number} toMonth
     * @param {boolean} useNewLogic
     * @return {Promise<{
     *   incomes: { total: number, paid: number, amount_left: number, incomeType: StatsIncomeType, currency: string }[];
     *   expenses: { amount: number, paid: number, expenseTypeKey: string, currency: string }[];
     *   total: { expense: StatsTotalPart, income: StatsTotalPart };
     * }>}
     * */
    getBalanceForRange(buildingId, fromYear, fromMonth, toYear, toMonth, useNewLogic = false) {
        return this.#client.get(`/managers/reports/${buildingId}/balance`, { fromYear, fromMonth, toYear, toMonth, useNewLogic });
    }

    /**
     * @param {string} buildingId
     * @param {string} apartmentNumber
     * */
    getApartmentData(buildingId, apartmentNumber) {
        return this.#client.get(`/managers/cashflow/${buildingId}/${apartmentNumber}`, {}, { throwError: true });
    }


    /**
     * @typedef {object} MoneyOutPayments
     * @property {number} id
     * @property {number} paymentId
     * @property {number} tenantCommissionInCents
     * @property {number} buildingCommissionInCents
     * @property {number} managementCompanyCommissionInCents
     * @property {number} amountTransferredInCents
     * @property {string} transferredAt
     */

    /**
     * @typedef {object} DepositedPayment
     * @property {number} paymentId
     * @property {string} paymentDate iso date string
     * @property {string} depositDate iso date string
     * @property {string} transferredAt iso date string
     * @property {'success' | 'pending'} depositState
     * @property {CurrencyValue} amount
     * @property {string} apartmentNum
     * @property {string} tenantName
     * @property {string} createdBy
     * @property {number} paymentType
     * @property {number} paymentState
     * @property {boolean} isOnline
     * @property {boolean} isTransferred
     * @property {boolean} deposited
     * @property {string} cartID
     * @property {{ year: number, month: number }[]} months
     * @property {{ PaymentSettings: { Description: { description: string, longDescription: string } } }} oneTime
     * @property {MoneyOutPayments[]} MoneyOutPayments
     * @property {{ApartmentNumber: string, PaymentPlanId: number}} ExtraInfo
     * */

    /**
     * @typedef {DepositedPayment | PaymentInfo} DepositedPaymentWithBankInfo
     * */

    /**
     * @param {string | number} buildingId
     * @param {DateRange} dateRange
     * @param {'success' | 'pending' | 'any'} depositState
     * @param {string} [search]
     * @returns {Promise<DepositedPaymentWithBankInfo[]>}
     */
    getCollectionReportDeposits(buildingId, dateRange, depositState = 'success', search = '') {
        return this.#client.get(`/managers/reports/collection/${buildingId}/deposits/${depositState}`, {
            fromYear: dateRange.from.year,
            toYear: dateRange.to.year,
            fromMonth: dateRange.from.month,
            toMonth: dateRange.to.month,
            s: search,
        });
    }

    /**
     * @typedef {object} RefundsPayments
     * @property {number} RefundPaymentID
     * @property {number} PaymentId
     * @property {number} PaymentPlanId
     * @property {number} PtoPId
     * @property {string} PaymentDate
     * @property {string} RefundPaymentDate
     * @property {string} transferredAt iso date string
     * @property {string} createdAt iso date string
     * @property {string} updatedAt iso date string
     * @property {string} Comment
     * @property {number} AmountInCents
     * @property {string} ApartmentNumber
     * @property {string} ClientEmail
     * @property {string} ClientPhone
     * @property {string} ClientName
     * @property {string} TenantType
     * @property {string} PlanType
     * @property {number} PaymentState
     * @property {{currency: string}} Building
     * @property {{PaymentDetails: {PaymentPlan: {cartID: string}}}} Payment
     * */

    /**
     * @typedef {RefundsPayments | PaymentInfo} RefundsPaymentWithBankInfo
     * */

    /**
     * @param {string | number} buildingId
     * @param {DateRange} dateRange
     * @param {string} [search]
     * @param {boolean} [usePaymentDate] use RefundPaymentDate instead of transferredAt
     * @returns {Promise<RefundsPaymentWithBankInfo[]>}
     */
    getCollectionReportDeductions(buildingId, dateRange, search, usePaymentDate) {
        return this.#client.get(`/managers/reports/collection/${buildingId}/refunds`, {
            fromYear: dateRange.from.year,
            toYear: dateRange.to.year,
            fromMonth: dateRange.from.month,
            toMonth: dateRange.to.month,
            usePaymentDate: usePaymentDate,
            s: search,
        });
    }

    /**
     * @param {string | number} buildingId
     * @param {DateRange} dateRange
     * @param {'cheque' | 'wire' | 'cash' | 'any'} paymentMethod
     * @returns {Promise<DepositedPayment[]>}
     * */
    getCollectionReportOfflineDeposits(buildingId, dateRange, paymentMethod) {
        return this.#client.get(`/managers/reports/collection/${buildingId}/offline/${paymentMethod}`, {
            fromYear: dateRange.from.year,
            toYear: dateRange.to.year,
            fromMonth: dateRange.from.month,
            toMonth: dateRange.to.month,
        });
    }

    /**
     * @typedef {object} ChargesRow
     * @property {number} paymentState
     * @property {string} paymentType
     * @property {{
     *              month: string;
     *              year: string;
     *          }[]} months
     * @property {OneTime | null} oneTime
     * @property {string} tenantName
     * @property {string} apartmentNum
     * @property {string} currency
     * @property {string} cartId
     * @property {number} amount
     * @property {boolean} isTransferred
     * @property {string} depositDate
     * @property {string} chargeDate
     * @property {number} paymentId
     * @property {boolean} isRefundable
     * @property {boolean} isStoppable
     * */

     /**
     *
     * @param {string | number} buildingId
     * @param {DateRange} dateRange
     * @param {string} [search]
     * @returns {Promise<{
     *     rows: ChargesRow[]
     * }>}
     */
    async getCollectionReportCharges(buildingId, dateRange, search) {
        return this.#client.get(`/managers/reports/collection/${buildingId}/charges`, {
            fromYear: dateRange.from.year,
            toYear: dateRange.to.year,
            fromMonth: dateRange.from.month,
            toMonth: dateRange.to.month,
            s: search,
        });
    }

}
