import React from 'react';
import { withTranslation } from 'react-i18next';
import Parent from '../../common/parent';
import mapNew from '../../images/map-new.png';
import mobileMap from '../../images/mobile-m.png';
import $ from 'jquery';
import {BllinkLogger} from '../../common/utils/bllink_loggers';
import { NotificationManager } from 'react-notifications';
import { constants } from '../../common/constants/constants';
import 'bootstrap/dist/js/bootstrap.min'; // for the modal pop up
import './searchBuilding.css';
import {LocationAutocomplete} from "./locationAutocomplete";


export class SearchBuilding extends Parent {

    constructor(props) {
        super(props);
        document.title = props.t('titles.search_building');

        this.bindSearchClicked.bind(this);
        this.redirectToBuildingPage.bind(this);
        this.searchBuilding.bind(this);
        this.populateEntrancesModalAndShow.bind(this);
        this.validateBeforeSearch.bind(this);

        /** @type {AddressPrediction|null} */ this.searchPrediction = null;
    }

    async componentDidMount() {
        $("#autocomplete-form").submit(async function( event ) {
            // will be handled from js frontend
            event.preventDefault();
        });

        $(document).ready(function () {
            // append to body - https://stackoverflow.com/a/15780841/13806459
            // https://app.asana.com/0/1196545425570329/1198890480504266
            $('#myModal').appendTo('body');
        });

         // this.initAutocomplete();
         this.bindSearchClicked();

    }

    bindSearchClicked(){
        // https://app.asana.com/0/1196545425570329/1198182790293835 - Search page: trigger search only when clicking the search
        // button and not after selecting from dropdown or pressing enter. This to enable the user the option to add
        // a specific street number to the text box
        $(window).keydown(function(event){
            if(event.keyCode === 13) {
                event.preventDefault();
                return false;
            }
        });
    }

    /**
     * @param {Event<HTMLFormElement>} e
     */
    async handleFormSubmitted(e) {
        e.preventDefault();
        await this.searchBuilding();
    }

    async searchBuilding() {

        if (this.searchPrediction == null) {
            return;
        }

        if (this.searchPrediction.type === 'bllink') {
            this.redirectToBuildingPage(this.searchPrediction.buildingID);
            return;
        }

        const {t} = this.props;
        const searchButton = $('#search_submit');
        searchButton.text(t('search.searching'));

        const response = await this.client.tenants.searchByAddressPrediction(this.searchPrediction);
        if (response.error) {
            $('#address-error').text(response.errorMessage);
        } else if (response.buildings.length === 0) {
            NotificationManager.error(t('search.building_not_found'));
            $('#address-error').text(t('search.building_not_found'));
        } else {
            const isMultipleEntrancesBuildings = response.buildings.length > 1;
            if (isMultipleEntrancesBuildings){
                this.populateEntrancesModalAndShow(response.buildings)
            } else {
                this.redirectToBuildingPage(response.buildings[0].externalID);
            }
        }
        searchButton.text(t('search.search'));
    }

    /**
     * @param {AddressPrediction} prediction
     * */
    handleAutocompleteSelect(prediction) {
        this.searchPrediction = prediction;
        $('#address-error').text('');
    }

    populateEntrancesModalAndShow(buildings){
        const {t} = this.props;
        $('#month-or-year-select').find('option').remove().end();
        const thisObj = this;
        for (const building of buildings){
            // added building.Title + ' ' to support buildings with no multiple entrances
            // https://bllink.atlassian.net/browse/SSB-1786?focusedCommentId=15696
            const entrance = building.Address.entrance || building.Title + ' ' + t('buildings.no_entrance');
            $("#month-or-year-select").append(`<option value="${building.externalID}"> 
                                       ${entrance} 
                                  </option>`);
            BllinkLogger.info(`entrance added ${entrance} and ${building.externalID}`)
        }

        // append to body - https://stackoverflow.com/a/15780841/13806459
        // https://app.asana.com/0/1196545425570329/1198890480504266
        $('#myModal').modal('show');

        $('#search_submit_2').click(function (e){
            e.preventDefault();
            const selectedBuildingID = $('#month-or-year-select').children("option:selected").val();
            BllinkLogger.info(`entrance selected ${selectedBuildingID}`);
            thisObj.props.history.push(`/buildings/` + selectedBuildingID);
        })
    }

    redirectToBuildingPage(externalID){
        this.props.history.push(`/buildings/` + externalID)
    }


    renderPopUp(){
        const {t} = this.props;

        return (

            <div className="modal" id="myModal">
                <div className="modal-dialog new_modal_content">
                    <div className="modal-content n_content">
                        <div className="modal-body">
                            <div className="row d-flex">
                                <div className="col-sm-6">
                                    <label htmlFor="usr" className="level_set">* {t('search.entrance_number')} 11: </label>
                                    <select className="form-control" id="month-or-year-select" name="form">
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <button type="submit" className="btn" id="search_submit_2"
                                            data-toggle="modal" data-target="#myModal">{t('search.search')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const {t} = this.props;
        this.setBodyClasses(constants.backgroundOptions.map);

        return (
            <div>
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="main-sec-top-map">
                                    <h2>{t('search.search_address')}</h2>
                                    <p>{t('search.search_address_sub_title')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <form className="form_search_bar" id="autocomplete-form" onSubmit={(e) => this.handleFormSubmitted(e)}>
                            <div className="row">
                                <div className="col-md-8">
                                    <label className='label' htmlFor="usr">{t('search.full_address')}: </label>

                                    <div className="map_id">
                                        {/*<Autocomplete onLocationChanged={(loc) => this.handleLocationChanged(loc)}/>*/}
                                        <LocationAutocomplete onPredictionSelect={p => this.handleAutocompleteSelect(p)} />
                                        <label id="address-error" className="error" htmlFor="address"/>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <button type="submit" className="btn" id="search_submit">{t('search.search')}</button>
                                </div>

                            </div>
                        </form>
                        <div className="col-md-12">
                            <div className="w-full relative" id="js-preview-map">
                                <img id="js-preview-map-1" alt="map-desktop" src={mapNew}/>
                                <img id="js-preview-map-mobile" alt="map-mobile" src={mobileMap}/>
                            </div>
                        </div>
                    </div>



                </section>
                {this.renderPopUp()}
            </div>
        )
    }
}


export default withTranslation()(SearchBuilding);
