import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import { constants } from './constants/constants';
import BllinkTime from './utils/time';
import { dropupSelectStyles } from './shared/select.styled';
import {GAEvent} from "./google_analytics/google_analytics";
import CacheData from "./utils/cacheData";
import {selectCurrentBuildingCurrencyUnsafe} from "../store/buildings/selectors";
import { translatesLegalInfoPages } from "./constants/translatedLegalInfoLinks";
import {shareToSocial} from "./shared/statelessMethods";

const StyledFooter = styled.footer`
  font-family: var(--bllink-font-primary);
  margin-top: auto;
  background: #fff;
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    width: 100%;
    margin: auto;
    padding: 25px;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    
    a {
      padding: 10px 20px;
      font-size: 14px;
      color: ${props => props.theme.text};
      text-transform: capitalize;
    }
  }
  
  p {
    margin: 0;
    font-size: 12px;
    line-height: 23px;
    color: ${props => props.theme.textTable};
  }
  
  footer {
    height: 76px;
    max-width: 100%;
    background: #fff;
    padding-top: 50px;
    margin-top: auto;
    z-index: 0;
  }
  @media screen and (max-width: 768px) {
    .wrapper {
      display: block;

      ul {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .dropdownTop {
    width: 150px;
  }

  .copyright {
    display: flex;
    gap: 30px;
    align-items: center;
  }
`;


const CURRENCY2LNG = {
    'MXN': 'es',
    'USD': 'en',
    'ILS': 'he',
};

export default function Footer() {

    const { t, i18n } = useTranslation();
    const currentYear = BllinkTime.getCurrentYear();

    const LANGUAGES = [
        { value: 'en', label: t('footer.languages.en') },
        { value: 'he', label: t('footer.languages.he') },
        // { value: 'es', label: t('footer.languages.es') },
    ];

    const [language, setLanguage] = useState(LANGUAGES.find(o => o.value === i18n.language));

    const currencyCached = CacheData.fetchCache('currency');
    const buildingCurrency = useSelector(selectCurrentBuildingCurrencyUnsafe);
    const userProfile = useSelector(state => state.auth.userProfile);
    const currency = userProfile?.currency ?? buildingCurrency ?? currencyCached;

    useEffect(async () => {
        if (currency == null) return;
        const manuallyChanged = CacheData.fetchCache('language_manual') ?? false;
        if (!manuallyChanged) {
            // NOTE: save it the same way as manually, to avoid language reset on page refresh
            const lng = CURRENCY2LNG[currency] ?? 'he'
            await onLanguageChange({ value: lng });
        }
    }, [currency]);

    async function onLanguageChange(e) {
        const { value: lng } = e;
        if (lng !== i18n.language) {
            const key = `${lng}_from_${i18n.language}`;
            GAEvent(constants.GoogleAnalytics.Events.general, 'changeLanguage', key, 'changeLanguage');
            setLanguage(LANGUAGES.find(l => l.value === lng));
            CacheData.cache('language_manual', true);
            await i18n.changeLanguage(lng);
            window.dispatchEvent(new CustomEvent('changeLanguageOnIndex', {detail: { lng }}));
        }
    }

    const accessibilityUrl = translatesLegalInfoPages.accessibilityUrl[language.value] ?? translatesLegalInfoPages.accessibilityUrl.default;
    const termsOfUseUrl = translatesLegalInfoPages.termsOfUseUrl[language.value] ?? translatesLegalInfoPages.termsOfUseUrl.default;
    const privacyUrl = translatesLegalInfoPages.privacyUrl[language.value] ?? translatesLegalInfoPages.privacyUrl.default;


    if (currency == null) {
        // NOTE: avoid content in default language until building data is not loaded
        return '';
    }

    return (
        <StyledFooter>
            <div className='wrapper'>
                <div className='links'>
                    <ul>
                        {
                            <li hidden={currency === 'USD' || i18n.language === 'es'}>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`${constants.pages.mainBllinkSite}/${constants.pages.questionAndAnswers}`}
                                >
                                    {t('footer.q_and_a')}
                                </a>
                            </li>
                        }

                        <li hidden={currency === 'USD'}><a href={accessibilityUrl} target="_blank"
                                                           rel="noreferrer">{t('footer.accessibility')}</a></li>
                        <li><a href={termsOfUseUrl} target="_blank" rel="noreferrer">{t('footer.policy')}</a></li>
                        <li><a href={privacyUrl} target="_blank" rel="noreferrer">{t('footer.privacy')}</a></li>
                        <li hidden={currency === 'USD'}><a href="#" onClick={(e) => {
                            e.preventDefault();
                            shareToSocial({
                            }, t);
                        }}>
                            {t('footer.share')}
                        </a></li>
                        {
                            <li hidden={currency === 'USD' || i18n.language === 'es'}>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`${constants.pages.mainBllinkSite}/${constants.pages.contactUs}`}
                                >
                                    {t('footer.support')}
                                </a>
                            </li>
                        }
                    </ul>
                </div>
                <div className='copyright'>
                    {
                        (
                            <Select
                                styles={dropupSelectStyles}
                                menuPlacement='top'
                                options={LANGUAGES}
                                onChange={onLanguageChange}
                                value={language}
                                className='dropdownTop'
                                classNamePrefix="select"
                                placeholder={t('footer.languagePlaceholder')}
                        />
                      )
                    }
                    <p>{t('footer.all_rights', {currentYear: currentYear})}</p>
                </div>
            </div>
        </StyledFooter>
    );
}
