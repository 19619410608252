import $ from 'jquery';

/**
 * @deprecated use showLoader/hideLoader actions instead
 * */
export default class Loader{

    /**
     * @deprecated use showLoader action instead
     * */
    static startLoader(){
        // disabled background and loading spinner
        $('.loading-overlay').addClass('is-active')
    }

    /**
     * @deprecated use hideLoader action instead
     * */
    static endLoader(){
        // re-enable screen and remove spinner
        $('.loading-overlay').removeClass('is-active')
    }
}
