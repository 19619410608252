import Logger from 'js-logger';
import { SETTINGS } from '../settings';
// import CacheData from './cacheData';


let logLevel = SETTINGS.logger.logLevel;

// CIRCULAR DEPENDENCY: loggers -> cache data -> loggers
// to debug in production
// if (CacheData.fetchCache('debug')){
//     logLevel = Logger.TRACE;
// }

// todo add formatter to print file name 
Logger.useDefaults();
Logger.setLevel(logLevel);

const BllinkLogger = Logger.get('BllinkWebApp');


export {
    BllinkLogger,
    // add more specific loggers if needed
}

