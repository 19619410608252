export const adminReports = {
    "titles": {
        "renew": "Renew reports"
    },
    "generateBtn": "Generate",
    "generateRenewOffline": "Renew offline",
    "success": "Report was sent successfully",
    "rawReport": "Raw report",
    "reportSent": "Report sent",
};
