import {useEffect, useState} from 'react';
import {NavLink, matchPath, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import { Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { constants } from '../../common/constants/constants';
import {SidebarStyled} from './sidebar.styled';
import CacheData from '../../common/utils/cacheData';
import {BllinkLogger} from '../../common/utils/bllink_loggers';
import {fetchBuildingFeatures, saveCurrentBuilding} from '../../store/buildings/actions';
import {isAllowedToEdit} from '../../common/shared/statelessMethods';
import {hideLoader, showLoader, toggleMobileMenuOpened, setSidebarCollapsed, toggleSidebarPinned} from '../../store/common/slice';
import {isAdminSelector, shouldHideRewardsSelector} from '../../store/auth/selectors';
import BuildingBrochure from '../../common/building_brochure/brochure';
import AutoRecordPaymentPopup from '../autoRecordPayments/AutoRecordPaymentPopup';
import {useAccessToken} from '../../common/hooks/useAccessToken';
import BllinkTime from '../../common/utils/time';
import { fetchManagerBuildings } from '../../store/manager/actions';

// import gift from '../../images/newDesign/gift.svg';
import tenants from '../../images/newDesign/tenants.svg';
import messages from '../../images/newDesign/messages.svg';
import archive from '../../images/newDesign/archive.svg';
import logo from '../../images/newDesign/logo.svg';
// import allBuildings from '../../images/newDesign/all_buildings.svg';
import fees from '../../images/newDesign/fees_one_time.svg';
import payments from '../../images/newDesign/payments.svg';
import reports from '../../images/newDesign/reports.svg';
import sidebarBg from '../../images/newDesign/sidebar_bg.svg';
import sidebarBgSmall from '../../images/newDesign/sidebar_bg_small.svg';
// import list from '../../images/newDesign/list.svg';
import dashboard from '../../images/newDesign/main_building.svg';
import homeIcon from '../../images/newDesign/homeIcon.svg';
import unpinnedIcon from '../../images/newDesign/unpinned.png';
import pinnedIcon from '../../images/newDesign/pinned.png';
import integration from '../../images/newDesign/integration.svg';
// import withdrawal from '../../images/newDesign/withdrawal.svg';
import {isLinkActive} from "./Sidebar.shared";
import {PartnerMaintenanceSubMenu} from "./PartnerMaintenanceSubMenu";
import {MenuNavItem} from "./MenuNavItem";

export default function Sidebar({buildingID, year, match, apartmentNumber}) {
    const currentYear = BllinkTime.getCurrentYear();
    const linkYear = year || currentYear;
    const currentMonth = BllinkTime.getCurrentMonth();

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    const managerData = useSelector(state => state.buildings.managerData);
    const localBuildingID = useSelector(state => buildingID ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const userProfile = useSelector((state) => state.auth.userProfile);
    const managerAllBuildingsPending = useSelector((state) => state.manager.buildingsPending);
    const mobileMenuOpened = useSelector(state => state.common.mobileMenuOpened);
    const sidebarCollapsed = useSelector(state => state.common.sideBarCollapsed);
    const sidebarPinned = useSelector(state => state.common.sideBarPinned);
    const isLtrEnabled = useSelector(state => state.common.ltrEnabled);
    const isAdmin = useSelector(isAdminSelector);
    const shouldHideRewards = useSelector(shouldHideRewardsSelector);


    const [navData, setNavData] = useState({});
    const [isBrochureAvailable, setIsBrochureAvailable] = useState(false);
    const [showPaymentUploadPopup, setShowPaymentUploadPopup] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [collapsed, setCollapsed] = useState(sidebarCollapsed);
    const [pinned, setPinned] = useState(sidebarPinned);
    const [isGreenInvoiceOn, setIsGreenInvoiceOn] = useState(false);

    // to hide menu items on mc dashboard and all buildings
    const [hideListItems, setHideListItems] = useState(false);

    const client = useAccessToken();

    // NOTE this logic is in ManagerPage
    // useEffect(async () => {
    //     console.log('[fetchManagerBuildingData]', managerDataPendingId, localBuildingID);
    //     if (!client) return;
    //
    //     if (managerDataPendingId == localBuildingID) {
    //         return;
    //     }
    //
    //     if (!managerData?.buildingID && localBuildingID) {
    //         const options = { urlParams: { skipDisabledCheck: true } };
    //         dispatch(showLoader());
    //         const buildingID = localBuildingID;
    //         console.log('[fetchManagerBuildingData]', 'Sidebar', managerDataPendingId, buildingID, managerData);
    //         const { payload } = await dispatch(fetchManagerBuildingData({ buildingID, options }));
    //         await dispatch(getBuildingTitle({buildingID, addressData: payload.building.address }));
    //         await dispatch(getBuildingTitleText({ buildingID, t }));
    //         await dispatch(fetchBuildingData({ buildingID, options }));
    //         dispatch(hideLoader());
    //     }
    // }, [client, managerData, managerDataPendingId]);

    // useEffect(() => {
    //     if (buildingFeatures){
    //         let enableMessagePlatform =
    //         if (enableMessagePlatform){
    //             setShowMessagePlatform(true)
    //         }
    //     }
    //
    // }, [buildingFeatures])

    useEffect(async () => {
        if (localBuildingID) {
            dispatch(showLoader());
            await dispatch(fetchBuildingFeatures({ buildingID: localBuildingID }));
            dispatch(hideLoader());
        }
    }, [localBuildingID]);

    useEffect(() => {
        (async function () {
            if (!localBuildingID || !client) return;
            CacheData.cache('currentBuilding', localBuildingID);

            BllinkLogger.info(`get data for buildingID ${localBuildingID}`);
            saveCurrentBuilding(localBuildingID);

            const canEdit = isAllowedToEdit(managerData.managerRole);
            const firstApartmentNumber = await getFirstApartment();
            setNavData({
                firstApartmentNumber,
                oneTimeSettings: managerData.oneTimeSettings || [],
                managerData: managerData,
                isAllowedToEdit: canEdit
            });
        })();
        return () => {
            setNavData({});
        };
    }, [managerData, localBuildingID, client]);

    useEffect(() => {
        if (mobileMenuOpened) dispatch(setSidebarCollapsed(false));
        setToggled(mobileMenuOpened);
    }, [mobileMenuOpened])

    useEffect(() => {
        if (collapsed !== sidebarCollapsed) setCollapsed(sidebarCollapsed);
    }, [sidebarCollapsed])

    useEffect(() => {
        if (pinned !== sidebarPinned) setPinned(sidebarPinned);
    }, [sidebarPinned])

    useEffect(() => {
        setHideListItems(false);

        const pages = [`/${constants.pages.allBuildingsUrl}`, `/${constants.pages.buildingManagerCompanyProfileUrl}/:partnerID`, `/${constants.pages.rewards}`]
        const isUrlMatch = pages.some(page => !!matchPath(location.pathname, {
            path: page,
            exact: true,
            strict: true
        }));

        if (isUrlMatch || match.params.partnerID || !localBuildingID) setHideListItems(true);
    }, [location, match, localBuildingID]);

    useEffect(async () => {
        if (client == null) return;
        // todo what is this condition?
        if (userProfile != null && !managerAllBuildingsPending) {
            dispatch(showLoader());
            // TODO: move this fetch to the ManagerPage
            await dispatch(fetchManagerBuildings());
            dispatch(hideLoader());
        }

        // check if green invoice is on for the partner
        const partnerID = (userProfile || {}).partnerID;
        let result;
        if (partnerID) {
            result = await client.managers.features.getFeatureForEntity('partnerID', partnerID, 'partnerInvoiceServiceParams');
            if (result == null || result?.value?.length < 1) setIsGreenInvoiceOn(false)
            else setIsGreenInvoiceOn(true)
        }
    }, [userProfile, client]);


    const getFirstApartment = async () => {
        if (localBuildingID) {
            return (managerData.allNumbers && managerData.allNumbers.length > 0) ? managerData.allNumbers[0] : '1';
        }
    };

    const refreshBuildingData = async () => {
        dispatch(showLoader());
        let response = await client.post('/operations/clear_cache', {buildingID: localBuildingID});
        if (response.success){
            alert(t('errors.managers.clearedCachedForBuildingSuccess', {buildingID: localBuildingID}))
        }
        dispatch(hideLoader());
    };

    const triggerUpdatePlansDueToUpdatedFeesEvent = async () => {
        dispatch(showLoader());
        let response = await client.operations.updatePaymentPlans(localBuildingID);
        if (response.sent){
            alert('you should get an email (in 1 minute) with all the apartment that should be updated');
        }
        dispatch(hideLoader());
    };

    const closeSidebarOnMobileClick = () => {
        if (mobileMenuOpened) dispatch(toggleMobileMenuOpened())
    }

    const renderOneTimeOptions = () => {
        if (!navData.oneTimeSettings) {
            return;
        }

        return (
            navData.oneTimeSettings.map((oneTime, index) => {
                return (
                    <MenuItem
                        key={index}
                        active={isLinkActive(`/${constants.pages.oneTimePaymentSettings}/${localBuildingID}/${oneTime.oneTimeID}`)}
                    >
                        <NavLink
                            className="dropdown-link"
                            to={`/${constants.pages.oneTimePaymentSettings}/${localBuildingID}/${oneTime.oneTimeID}`}
                        >
                            {t('payment_settings.oneTimeTitle', {nameOfPayment: oneTime.name})}
                        </NavLink>
                    </MenuItem>
                );
            })
        );
    };

    const renderPaymentSettingsMenu = () => {
        // let isUpdateAllowed = navData.isAllowedToEdit ? '' : 'disabled';
        // we have an issue with pulling the managerRole for now.
        // for now, just allowing click for all and they will just get the error
        // if they actually try to edit the fee or record a payment
        let isUpdateAllowed = true; //navData.isAllowedToEdit ? '' : 'disabled';

        return (
            <SubMenu
                title={t('buildings.nav_bar_update_payments')}
                icon={<img src={fees} alt="fees and one time" />}
                className={[
                    isUpdateAllowed,
                    isLinkActive(['add-payment', 'manager/one-time', '/monthly-payments', 'collectionStatus'], {
                        part: true
                    }) ? 'active mobile-hide' : 'mobile-hide'
                ]}
            >
                <MenuItem
                    active={isLinkActive(`/${constants.pages.updateRates}/${localBuildingID}`, {
                        query: true
                    })}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.updateRates}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_update_rates')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.monthlyPaymentSettings}/:buildingID/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.monthlyPaymentSettings}/${localBuildingID}/${currentYear}`}
                    >
                        {t('buildings.nav_bar_view_monthly')}
                    </NavLink>
                </MenuItem>
                {renderOneTimeOptions()}
                <MenuItem
                    active={isLinkActive(`/${constants.pages.addNewPayment}/${localBuildingID}?oneTime=add`, {
                        query: true
                    })}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.addNewPayment}/${localBuildingID}?oneTime=add`}
                    >
                        {t('buildings.nav_bar_add_one_time')}
                    </NavLink>
                </MenuItem>
            </SubMenu>
        );
    }

    const renderActionsMenu = () => {
        // let isUpdateAllowed = navData.isAllowedToEdit ? '' : 'disabled';
        // temp enable to all till solving this issue
        // we have an issue with pulling the managerRole for now.
        // for now, just allowing click for all and they will just get the error
        // if they actually try to edit the fee or record a payment
        // https://bllink.atlassian.net/browse/SSB-1239
        let isUpdateAllowed = true? '' : 'disabled';

        return (
            <SubMenu
                title={t('buildings.nav_bar_actions')}
                icon={<img src={payments} alt="payments" />}
                className={[
                    isLinkActive(`/${constants.pages.tenantDetailsManagerUrl}/:buildingID`) ? 'active' : ''
                ]}
            >
                <MenuItem
                    active={isLinkActive(`/${constants.pages.tenantDetailsManagerUrl}/:buildingID`)}
                >
                    <NavLink
                        className={`dropdown-link ${isUpdateAllowed}`}
                        to={`/${constants.pages.tenantDetailsManagerUrl}/${localBuildingID}`}
                        onClick={closeSidebarOnMobileClick}
                    >
                        {t('buildings.nav_bar_reports_update_payment')}
                    </NavLink>
                </MenuItem>

                <MenuItem
                    active={isLinkActive(`/${constants.pages.setExpenseUrl}/${localBuildingID}`)}
                    className="mobile-hide"
                >
                    <OverlayTrigger
                        placement="top"
                        overlay={props => (
                            <Tooltip {...props}>
                                {t('expenses.expense_record_tooltip')}
                            </Tooltip>
                        )}
                    >
                        <NavLink
                            className="nav-link"
                            to={`/${constants.pages.setExpenseUrl}/${localBuildingID}`}
                        >
                            {t('buildings.nav_bar_record_an_expense')}
                        </NavLink>
                    </OverlayTrigger>
                </MenuItem>




                <MenuItem active={isLinkActive(`/${constants.pages.managerTenantRecord}/:buildingID`)} onClick={closeSidebarOnMobileClick}>
                    <NavLink
                        className={"nav-link"}
                        to={`/${constants.pages.managerTenantRecord}/${localBuildingID}`}
                    >
                        {t('buildings.tenantRecordList')}
                    </NavLink>
                </MenuItem>


                <MenuItem
                    active={isLinkActive(`/${constants.pages.bankAdjustmentsUrl}/:buildingID/:month/:year`)}
                    className="mobile-hide"
                >
                    <NavLink
                        className="nav-link"
                        to={`/${constants.pages.bankAdjustmentsUrl}/${localBuildingID}/${currentMonth}/${currentYear}`}
                    >
                        {t('buildings.nav_bar_bank_reconciliation')}
                    </NavLink>
                </MenuItem>

            </SubMenu>
        );
    }

    const renderReportsMenu = () => {
        return (
            <SubMenu
                title={t('buildings.nav_bar_reports')}
                icon={<img src={reports} alt="reports" />}
                className={isLinkActive(`/reports/`, {
                    part: true
                }) ? 'active mobile-hide' : 'mobile-hide'}
            >
                <MenuItem
                    active={isLinkActive(`/${constants.pages.buildingDebtList}/:buildingID`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.buildingDebtList}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_debt_list')}
                    </NavLink>
                </MenuItem>

                <MenuItem
                    active={isLinkActive(`/${constants.pages.yearlyByMonthsUrl}/:buildingID/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.yearlyByMonthsUrl}/${localBuildingID}/${linkYear}`}
                    >
                        {t('buildings.nav_bar_reports_yearly_by_months')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.yearlyByOneTimeUrl}/:buildingID/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.yearlyByOneTimeUrl}/${localBuildingID}/${linkYear}`}
                    >
                        {t('buildings.nav_bar_reports_yearly_by_one_time')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.monthlyCashFlowUrl}/:buildingID/:months/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.monthlyCashFlowUrl}/${localBuildingID}/${currentMonth}/${currentYear}`}
                    >
                        {t('buildings.nav_bar_reports_monthly_cashflow')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.newApartmentReport}/${localBuildingID}/${apartmentNumber || navData.firstApartmentNumber || 1}`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.newApartmentReport}/${localBuildingID}/${apartmentNumber || navData.firstApartmentNumber || 1}`}
                    >
                        {t('buildings.nav_bar_reports_apartment')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.yearlyCashFlowUrl}/:buildingID/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.yearlyCashFlowUrl}/${localBuildingID}/${linkYear}`}
                    >
                        {t('buildings.nav_bar_reports_yearly_cashflow')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.monthlyExpensesUrl}/:buildingID/:months/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.monthlyExpensesUrl}/${localBuildingID}/${currentMonth}/${currentYear}`}
                    >
                        {t('buildings.nav_bar_reports_monthly_expenses')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.balanceReportUrl}/:buildingID`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.balanceReportUrl}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_reports_expenses_balance')}
                    </NavLink>
                </MenuItem>

            </SubMenu>
        );
    }

    const renderAdminMenu = () => {
        return (
            <SubMenu
                title={t('buildings.nav_bar_admin')}
                icon={<img src={homeIcon} alt="reports" />}
                className={[
                    isLinkActive(['manager/editBuilding', 'manager/bankAdjustmentsNew'], {
                        part: true
                    }) ? 'active' : ''
                ]}
            >

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={{pathname: `https://bllink.retool.com/apps/b4b8db8a-c248-11ed-a4a1-8feab553a29c/Home%20Page`}} target="_blank"
                    >
                        {t('reports.backoffice')}
                    </NavLink>
                </MenuItem>
                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={{pathname: `https://auth.greeninvoice.co.il/signin`}} target="_blank"
                    >
                        {t('reports.accounting_service')}
                    </NavLink>
                </MenuItem>
                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={{pathname: `https://hightower.ylm.co.il`}} target="_blank"*/}
                {/*    >*/}
                {/*        {t('reports.maintenance_service')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}
                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.addCollectionStatus}-new/${localBuildingID}`}
                    >
                        {t('reports.collection_report')}
                    </NavLink>
                </MenuItem>

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.buildingDebtList}-old/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_debt_list')}
                    </NavLink>
                </MenuItem>

                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.maintenanceLivyUrl}`}*/}
                {/*    >*/}
                {/*        {t('reports.maintenance_service') + ' livy'}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.yearlyByMonthsUrl}-admin/${localBuildingID}/${linkYear}`}
                    >
                        {t('buildings.nav_bar_reports_yearly_by_months')}
                    </NavLink>
                </MenuItem>

                <MenuItem
                    active={isLinkActive(`/${constants.pages.balanceReportUrl}-old/:buildingID/:months/:year`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.balanceReportUrl}-old/${localBuildingID}/${currentMonth}/${currentYear}`}
                    >
                        {t('buildings.nav_bar_reports_expenses_balance')}
                    </NavLink>
                </MenuItem>

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.tenantListUrl}-old/${localBuildingID}`}
                    >
                        {' Old tenant list '}
                    </NavLink>
                </MenuItem>

                {/*<MenuItem>*/}
                {/*    <NavLink*/}
                {/*        className="dropdown-link"*/}
                {/*        to={`/${constants.pages.monthlyExpensesUrl}-new-2/${localBuildingID}/${currentMonth}/${linkYear}`}*/}
                {/*    >*/}
                {/*        {t('buildings.nav_bar_reports_monthly_expenses')}*/}
                {/*    </NavLink>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={refreshBuildingData}
                    >
                        {t('buildings.nav_bar_refresh')}
                    </div>
                </MenuItem>

                {/*<MenuItem>*/}
                {/*    <div*/}
                {/*        style={{cursor: 'pointer'}}*/}
                {/*        onClick={triggerUpdatePlansDueToUpdatedFeesEvent}*/}
                {/*    >*/}
                {/*        {t('buildings.nav_bar_update_payment_plans')}*/}
                {/*    </div>*/}
                {/*</MenuItem>*/}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.editBuilding}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_edit_building')}
                    </NavLink>
                </MenuItem>

                {<MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.editContacts}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_edit_contacts')}
                    </NavLink>
                </MenuItem>}

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`${constants.pages.admin.editBuilding}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_edit_building_commission')}
                    </NavLink>
                </MenuItem>
                <MenuItem
                    active={isLinkActive(`/${constants.pages.addCollectionStatus}/:buildingID`)}
                >
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.addCollectionStatus}/${localBuildingID}`}
                    >
                        {t('buildings.collection_status_title')}
                    </NavLink>
                </MenuItem>

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`${constants.pages.admin.autoCampaign}/actions`}
                    >
                        {t('buildings.nav_bar_auto_campaign')}
                    </NavLink>
                </MenuItem>
                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.monitoringUrl}/${localBuildingID}`}
                    >
                        {t('buildings.nav_bar_monitoring')}
                    </NavLink>
                </MenuItem>

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`/${constants.pages.balanceReportUrl}-old/${localBuildingID}`}
                    >
                        {'New Balance old one'}
                    </NavLink>
                </MenuItem>

                <MenuItem>
                    <NavLink
                        className="dropdown-link"
                        to={`${constants.pages.admin.searchPaymentsUrl}`}
                    >
                        {t('buildings.nav_bar_search_payments')}
                    </NavLink>
                </MenuItem>

                <MenuItem
                    active={isLinkActive(`/${constants.pages.newBuildingUrl}`)}
                >
                    {/*<OverlayTrigger*/}
                    {/*    placement="top"*/}
                    {/*    overlay={props => (*/}
                    {/*        <Tooltip {...props}>*/}
                    {/*            {t('buildings.open_new_building')}*/}
                    {/*        </Tooltip>*/}
                    {/*    )}*/}
                    {/*>*/}
                    <NavLink
                        className="nav-link"
                        to={`/${constants.pages.newBuildingUrl}`}
                    >
                        {t('buildings.open_new_building')}
                    </NavLink>
                    {/*</OverlayTrigger>*/}
                </MenuItem>

                <MenuItem>
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={() => setIsBrochureAvailable(true)}
                    >
                        {t('buildings.nav_bar_brochure')}
                    </div>
                </MenuItem>
            </SubMenu>
        )
    }

    return (
        <SidebarStyled
            id={'sidebar-content'}
            breakPoint={'md'}
            toggled={toggled}
            collapsed={sidebarCollapsed}
            onMouseEnter={() => {
                // https://app.asana.com/0/1201972127603759/1202029748948747
                // [FE] side menu behaviour
                !pinned && dispatch(setSidebarCollapsed(false));
            }}
            onMouseLeave={() => {
                !pinned && dispatch(setSidebarCollapsed(true));
            }}
            rtl={!isLtrEnabled}
            onToggle={() => dispatch(toggleMobileMenuOpened())}
        >
            <SidebarHeader onClick={closeSidebarOnMobileClick}>
                <NavLink to={`/${constants.pages.allBuildingsUrl}`} className={'logo-link'}>
                    <img src={logo} alt="logo" />
                </NavLink>
                <div
                    className='pin-icon'
                    onClick={() => dispatch(toggleSidebarPinned())}
                >
                    {
                        pinned ? (
                            <img src={pinnedIcon} alt="pinned" />
                        ) : (
                            <img src={unpinnedIcon} alt="unpinned" />
                        )
                    }
                </div>
            </SidebarHeader>
            <SidebarContent>
                <div className={'bg'}>
                    <img src={sidebarBg} alt="bg" />
                </div>
                <div className={'bg-small'}>
                    <img src={sidebarBgSmall} alt="bg small" />
                </div>
                <Menu iconShape="square">
                    {/*removing this due to report by eyal that maintenance managers see financial data (via email)*/}
                    {/*{!shouldHideRewards && (*/}
                    {/*<MenuItem*/}
                    {/*    icon={<img src={gift} alt="rewards" />}*/}
                    {/*    active={isLinkActive(`/${constants.pages.rewards}`)}*/}
                    {/*    className='mobile-hide'*/}
                    {/*>*/}
                    {/*    <NavLink*/}
                    {/*        className="nav-link"*/}
                    {/*        to={`/${constants.pages.rewards}`}*/}
                    {/*    >*/}
                    {/*        {t('buildings.nav_bar_rewards')}*/}
                    {/*    </NavLink>*/}
                    {/*</MenuItem>*/}
                    {/*)}*/}

                    {/*requested by rayee via email*/}
                    {/*<MenuNavItem*/}
                    {/*    icon={products_at_discount}*/}
                    {/*    url={`/${constants.pages.marketPlace}`}*/}
                    {/*    onClick={closeSidebarOnMobileClick}*/}
                    {/*    label={t('buildings.nav_bar_products_at_a_discount')}*/}
                    {/*/>*/}

                    {/*removing this due to report by eyal that maintenance managers see financial data (via email)*/}
                    {/*<MenuNavItem*/}
                    {/*    icon={withdrawal}*/}
                    {/*    url={`/${constants.pages.fundsEarlyTransferUrl}`}*/}
                    {/*    onClick={closeSidebarOnMobileClick}*/}
                    {/*    label={t('buildings.nav_bar_funds_early_transfer')}*/}
                    {/*/>*/}

                    {/*removing this due to report by eyal that maintenance managers see financial data (via email)*/}
                    {/* assuming this link is available just for maintenance managers */}
                    {/*{userProfile?.partnerID && (*/}
                    {/*    <MenuItem*/}
                    {/*        icon={<img src={allBuildings} alt="MC dashboard" />}*/}
                    {/*        active={isLinkActive(`/${constants.pages.buildingManagerCompanyProfileUrl}/:partnerID`)}*/}
                    {/*        onClick={closeSidebarOnMobileClick}*/}
                    {/*    >*/}
                    {/*        <NavLink*/}
                    {/*            className="nav-link"*/}
                    {/*            to={`/${constants.pages.buildingManagerCompanyProfileUrl}/${userProfile.partnerID}`}*/}
                    {/*        >*/}
                    {/*            {t('buildings.nav_bar_buildings_data')}*/}
                    {/*        </NavLink>*/}
                    {/*    </MenuItem>*/}
                    {/*)}*/}

                    {isGreenInvoiceOn && (
                        <MenuItem
                            icon={<img src={integration} alt="integration" />}
                        >
                            <NavLink
                                className="dropdown-link"
                                to={{pathname: `https://auth.greeninvoice.co.il/signin`}} target="_blank"
                            >
                                {t('reports.accounting_service')}
                            </NavLink>
                        </MenuItem>
                    )}

                    <PartnerMaintenanceSubMenu
                        onItemClick={closeSidebarOnMobileClick}
                    />

                    {/*removing this due to report by eyal that maintenance managers see financial data (via email)*/}
                    {/*<MenuItem*/}
                    {/*    icon={<img src={list} alt="all buildings" />}*/}
                    {/*    active={isLinkActive(`/${constants.pages.allBuildingsUrl}`)}*/}
                    {/*    onClick={closeSidebarOnMobileClick}*/}
                    {/*>*/}
                    {/*    <NavLink*/}
                    {/*        className="nav-link"*/}
                    {/*        to={`/${constants.pages.allBuildingsUrl}`}*/}
                    {/*    >*/}
                    {/*        {t('buildings.nav_bar_buildings_list')}*/}
                    {/*    </NavLink>*/}
                    {/*</MenuItem>*/}

                    { !hideListItems && localBuildingID != null && localBuildingID !== '' && (
                        <MenuNavItem
                            icon={dashboard}
                            url={`/${constants.pages.buildingManagerProfileUrl}/:buildingID`}
                            urlParams={{ buildingID: localBuildingID }}
                            onClick={closeSidebarOnMobileClick}
                            label={t('buildings.nav_bar_main_screen')}
                        />
                    ) }

                    { !hideListItems && localBuildingID != null && localBuildingID !== '' && (
                        <MenuNavItem
                            icon={tenants}
                            url={`/${constants.pages.tenantListUrl}/:buildingID`}
                            urlParams={{ buildingID: localBuildingID }}
                            onClick={closeSidebarOnMobileClick}
                            label={t('buildings.nav_bar_tenant_list')}
                            className="mobile-hide"
                        />
                    ) }

                    { localBuildingID != null && localBuildingID !== ''  && (
                        <MenuNavItem
                            icon={messages}
                            url={`/${constants.pages.autoCampaignPage}/:buildingID`}
                            urlParams={{ buildingID: localBuildingID }}
                            onClick={closeSidebarOnMobileClick}
                            label={t('buildings.nav_bar_auto_campaign')}
                            className="mobile-hide"
                        />
                    ) }

                    { !hideListItems && localBuildingID != null && localBuildingID !== '' && (
                        <MenuNavItem
                            icon={archive}
                            url={`/${constants.pages.archivePage}/:buildingID`}
                            urlParams={{ buildingID: localBuildingID }}
                            onClick={closeSidebarOnMobileClick}
                            label={t('buildings.nav_bar_archive')}
                            className='mobile-hide'
                        />
                    ) }

                    { localBuildingID != null && localBuildingID !== '' && (
                        <MenuNavItem
                            icon={messages}
                            url={`/${constants.pages.messagePlatformUrl}/:buildingID`}
                            urlParams={{ buildingID: localBuildingID }}
                            onClick={closeSidebarOnMobileClick}
                            label={t('buildings.nav_bar_message_platform')}
                            className='mobile-hide'
                        />
                    ) }
                    {!hideListItems && renderPaymentSettingsMenu()}
                    {!hideListItems && renderActionsMenu()}
                    {!hideListItems && renderReportsMenu()}
                    {!hideListItems && isAdmin && renderAdminMenu()}
                </Menu>

                {
                    !hideListItems && isBrochureAvailable && (
                        <BuildingBrochure
                            buildingID={localBuildingID}
                            hideContainer={() => setIsBrochureAvailable(false)}
                        />
                    )
                }
                <AutoRecordPaymentPopup
                    buildingID={buildingID}
                    show={showPaymentUploadPopup}
                    onHide={() => setShowPaymentUploadPopup(false)}
                />
            </SidebarContent>
        </SidebarStyled>
    );
}
