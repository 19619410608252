export const searchPayments = {
    "mainHeader": "חיפוש תוכניות תשלום",
    "nothingFound": "לא נמצאו",
    "tableTitle": "תוצאות חיפוש",
    "noAdminRights": "אין לך גישה לעמוד זה",
    "planScreensHeader": "פעולות תוכניות תשלומים",
    "tableHeaders": {
        "createdAt": "זמן יצירה",
        "planDescription": "תיאור",
        "planStatus": "סטאטוס",
        "planComment" : "הערה",
        "clientName": "שם לקוח",
        "aptNumber": "מספר דירה"
    },
    "placeholders": {
        "address": "כתובת",
        "buildingId": "מספר בניין / מספר פיטופי",
        "fourDigits": "4 ספרות אחרונות",
        "apartmentNumber": "מספר דירה",
    },
    "btns": {
        "search": "חיפוש",
        "return": "חזור אחורה",
        "planActions": "פעולות",
        "send": "שלח/י",
        "update": "עדכן"
    },
    "planActionsHeaders": {
        "onlineHeader": "פעולות תוכניות תשלומים ",
        "offlineHeader": "שינוי שיטת תשלום"
    },
    "planActionSubheaders": {
        "buildingAddress": "כתובת בניין היא",
        "planStatus": "סטאטוס תוכנית הוא",
        "planMonths": "תוכנית היא עבור חודשי",
        "payments": "פירוט תשלומים (לחצ/י לפתיחה)",
        "planCreatedBy": "תוכנית נוצרה ע״י ",
        "createdByDefault": "דייר/ת",
    },
    "radioHeaders": {
        "extend": "האריך/י תוכנית עד",
        "shorten": "קיצור תוכנית עד",
        "stopAll": "עצירת תוכנית",
        "renew": "חידוש תוכנית עד",
        "switchCard": "החלף חיובים לכרטיס קיים או חדש"
    },
    "notifications": {
        "planWasSuccessfully": "פעולה בוצעה בהצלחה",
        "failure": "שגיאה. אנא נסה/י שוב",
        "chooseDate": "אנא בחר תאריך",
        "chooseCard": "אנא בחר כרטיס",
        "extended": "הארכת תוכנית",
        "shortened": "קיצור תוכנית",
        "stopped": "עצירת תוכנית",
        "renewed": "חודשה",
        "dataSent": "נשלח בהצלחה",
        "cardCharged": "חויבה"
    },
    "paidAt": "שולם/ישולם ב",
    "forMonths": "עבור חודשים",
    "newCard": "כרטיס אשראי חדש"
};
