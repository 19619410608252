
/**
 * @typedef {object} ApartmentMonthlyPaymentSettings
 * @property {number} id
 * @property {number} apartmentID
 * @property {number} apartmentName an apartment number as numeric string
 * @property {string} tenantName
 * @property {string} createdAt ISO date string
 * @property {string} updatedAt ISO date string
 * @property {number} year
 * @property {number} january
 * @property {number} february
 * @property {number} march
 * @property {number} april
 * @property {number} may
 * @property {number} june
 * @property {number} july
 * @property {number} august
 * @property {number} september
 * @property {number} october
 * @property {number} november
 * @property {number} december
 * */

/**
 * @typedef {{ [year: string]: ApartmentMonthlyPaymentSettings[] }} BuildingMonthlyPaymentSettings
 * */


export class PaymentSettingsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {number | string} buildingId
     * @param {number} year
     * @return {Promise<BuildingMonthlyPaymentSettings>}
     * */
    async getMonthlySettings(buildingId, year) {
        const resp = await this.#client.get(`/managers/monthly-payment-settings/${buildingId}?year=${year}`, {}, { throwError: true });
        return resp;
    }

}
