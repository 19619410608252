import { createAsyncThunk } from '@reduxjs/toolkit';
import { BllinkLogger } from '../../common/utils/bllink_loggers';
import { constants } from '../../common/constants/constants';
import CacheData from '../../common/utils/cacheData';
import ServerData from '../../common/serverData';
import { i18n } from '../../common/translations';

const getAllExpenseTypes = createAsyncThunk(
    'expenses/getAllTypes',
    async (payload, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});

            const allExpensesTypes = await CacheData.getOrCache(async () => {
                return await client.managers.expenses.getTypes() },
                'expenseTypes'
            );

            BllinkLogger.info(`allExpensesTypes`, allExpensesTypes);
            let result = [];
            for (const expenseType of allExpensesTypes) {
                result.push({value: expenseType.id, label: i18n.t(`expenses.expenseTypes.${expenseType.expenseType}`)});
            }

            return result;
        } catch(e) {
            return rejectWithValue([]);
        }
    }
);

const getAllKnownVendors = createAsyncThunk(
    'expenses/getAllKnownVendors',
    async (buildingID, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});

            let allVendors = await client.get(`/vendors/all/${buildingID}`);
            if (!allVendors.length) {
                allVendors.unshift({value: '1', label: i18n.t('expenses.choose_vendor')});
            }
            allVendors.push({value: constants.expenses.addNewVendor, label: i18n.t('expenses.add_vendor')});
            BllinkLogger.info(`allVendors`, allVendors);
            return allVendors;
        } catch(e) {
            return rejectWithValue([]);
        }
    }
);

const recordExpenses = createAsyncThunk(
    'expenses/recordExpenses',
    async (payload, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, { accessToken });
            return client.managers.expenses.create(payload);
        } catch(e) {
            return rejectWithValue([]);
        }
    }
);

const getExpenseDetails = createAsyncThunk(
    'expenses/getDetails',
    async (cartID, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});

            const response = await client.managers.expenses.getDetails(cartID);
            return response;
        } catch(e) {
            return rejectWithValue([]);
        }
    }
);

const addVendor = createAsyncThunk(
    'expenses/addVendor',
    async (payload, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});

            return client.put('/vendors', payload);
        } catch(e) {
            return rejectWithValue([]);
        }
    }
);

const checkBankChargePermission = createAsyncThunk(
    'expenses/checkBankChargePermission',
    async (buildingID, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            const client = new ServerData(null, null, {accessToken: accessToken});
            const featureValue = await client.managers.features.getFeatureForEntity('buildingID', buildingID, 'enableBuildingBankCharge', true);
            return featureValue == null ? false : featureValue.value === 'true';
        } catch(e) {
            return rejectWithValue(null);
        }
    }
);

export {
    getAllExpenseTypes,
    getAllKnownVendors,
    recordExpenses,
    getExpenseDetails,
    addVendor,
    checkBankChargePermission,
};
