import { Fragment, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CacheData from "../../../common/utils/cacheData";
import messages from "../../../images/newDesign/messages.svg";
import { constants } from "../../../common/constants/constants";
import {hasPremiumAccessSelector, isAdminSelector} from "../../../store/auth/selectors";
import { toggleMobileMenuOpened } from "../../../store/common/slice";
import { SideMenu } from "../sideMenu/sideMenu";
import { SideMenuTitle } from "../sideMenu/sideMenuTitle";
import { SideMenuItem } from "../sideMenu/sideMenuItem";
import {SideMenuMainLink} from "../sideMenu/sideMenuMainLink";

/**
 * @param buildingId
 * @return {JSX.Element|null}
 * @constructor
 */
export default function MessagesSidebarMenu({ buildingId }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const menuRef = useRef();
    const sideMenuRef = useRef();
    const isAdmin = useSelector(isAdminSelector);
    const isPremiumUser = useSelector(hasPremiumAccessSelector);
    const buildingFeatures = useSelector(state => state.buildings.currentBuildingFeatures);
    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    // rayee asked to open for all
    // const showMessagePlatform = buildingFeatures?.enableMessagePlatform === 'true';

    const closeSidebarOnMobileClick = () => {
        dispatch(toggleMobileMenuOpened());
        sideMenuRef.current.close();
    }

    const showMassages = useMemo(() => {
        return !!localBuildingId
    }, [localBuildingId]);


    const messagesMenu = (
        <Fragment>
            <SideMenuMainLink
                icon={<img src={messages} alt="messages"/>}
                menuRef={menuRef}
                title={t('buildings.nav_bar_message_platform')}
                disabled={!isPremiumUser}
                tooltip={t('buildings.upgrade_to_premium')}
                tooltipDisabled={isPremiumUser}/>
            <SideMenu container={menuRef.current} ref={sideMenuRef}>
                <SideMenuTitle
                    label={t('buildings.nav_bar_message_platform')}
                    icon={messages}/>
                <SideMenuItem
                    url={`/${constants.pages.autoCampaignPage}/:buildingID`}
                    urlParams={{ buildingID: localBuildingId }}
                    label={t('buildings.nav_bar_auto_campaign')}
                    onClick={closeSidebarOnMobileClick}/>
                <SideMenuItem
                    url={`/${constants.pages.messagePlatformUrl}/:buildingID`}
                    urlParams={{ buildingID: localBuildingId }}
                    label={t('messagePlatform.btns.sendMessage')}
                    onClick={closeSidebarOnMobileClick}/>
            </SideMenu>
        </Fragment>
    )

    return showMassages ? messagesMenu : null;
}
