export class OperationsApi {

    #client;
    #prefix = '/managers/operations'


    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} buildingId
     * @param {string} apartmentNumber
     * @param {string} paymentLink
     * @param {string} fileType
     * @param {string} fileName
     * @param {string} fileBase64
     * @param {boolean} toOwner
     * @param {boolean} toRenter
     * @return {Promise<{ success: boolean, renter?: unknown, owner?: unknown }>}
     * */
    sendWarningLetter(buildingId, apartmentNumber, paymentLink, fileType, fileName, fileBase64, toOwner, toRenter) {
        const payload = { paymentLink, fileType, fileName, fileBase64, toOwner, toRenter };
        return this.#client.post(`${this.#prefix}/send_warning_letter/${buildingId}/${apartmentNumber}`, payload);
    }

    /**
     * @param {string} buildingId
     * @param {array} tenants
     * @param {{ byEmail: boolean, bySMS: boolean, byIVR: boolean }} methods
     * @return {Promise<unknown>}
     * */
    sendPaymentRequests(buildingId, tenants, methods) {
        const payload = {tenants: tenants, methods: methods};

        return this.#client.post(`${this.#prefix}/request_payment/${buildingId}`, payload);
    }

    /**
     * @param {string} buildingId
     * @param {number} paymentPlanId
     * @param {{
     *     startPaymentDetailsID?: number;
     *     allPending: boolean;
     *     stopFrom?: DateStruct;
     * }} options
     * */
    shortenPlan(buildingId, paymentPlanId, options) {
        const payload = {...options};
        return this.#client.post(`${this.#prefix}/shorten-plan/${buildingId}/${paymentPlanId}`, payload);
    }

    /**
     * @param {string} buildingId
     * @param {number} paymentID
     * @param {boolean} sendEmail
     * @param {number|null} partialRefund (in dollars)
     * */
    refund(buildingId, paymentID, sendEmail, partialRefund = null) {
        let payload = { paymentID, sendEmail };
        if (partialRefund){
            payload = { ...payload, partialRefund };
        }
        return this.#client.post(`${this.#prefix}/refund/${buildingId}`, payload);
    }

    /**
     * @param {string} buildingId
     * @param {string} apartmentNum
     * */
    inactivateApartment(buildingId, apartmentNum) {
        return this.#client.patch(`${this.#prefix}/inactivate/building/${buildingId}/apartment/${apartmentNum}`);
    }

    /**
     * @param {string} link
     * @return {Promise<{ link: string }>}
     * */
    async getShortLink(link) {
        return await this.#client.post('/managers/operations/link/short', { link });
    }

    /**
     * @param {string} buildingID
     *  @param {{ tenants: [{type: string, apartmentNumber: string, tenantID: number }],senderName: string, methods: { byEmail: boolean, bySMS: boolean}, personalizedMessage: string }} params
     * * */
    async sendMultiplePlatformMessages(buildingID, params){
        return await this.#client.post(`${this.#prefix}/messagePlatform/${buildingID}`, params);
    }
    /**
     * @param {string} buildingID
     * @return {Promise<{messageCount: {sms: number, email: number, smsLimit: number}>}
     * */
    async getMessagesCount(buildingID) {
        return await this.#client.get(`${this.#prefix}/messagePlatform/count/${buildingID}`);
    }

    /**
     * @param {number[]} paymentsIds
     * @param {string} buildingID
     * @return {Promise<{success: boolean}>}
     * */
    async markChequesAsManual(paymentsIds, buildingID) {
        return await this.#client.post(`${this.#prefix}/markChequesAsManual`, {buildingID, paymentsIds});
    }

    /**
     * @typedef {object} LastCreditCardDetails
     * @property {string} cardLast4Digits
     * @property {string} email
     * @property {string} expiry
     * @property {string} name
     * @property {string} phone
     * @property {number} paymentID
     * @property {string} lastUsed
     * @property {number} lastAmountInCents
     * @property {number} lastPaymentMethodId
     * @property {string} [cardIssuer]
     * @property {string} [accountId]
     * @property {string} [bankCode]
     * @property {string} [branchId]
     * */

    /**
     * @typedef {object} OperationApartmentDetails
     * @property {LastCreditCardDetails[]} lastCreditCards4Digits
     * */

    /**
     * @param {string | number} buildingID
     * @param {string} apartmentNumber
     * @return {Promise<OperationApartmentDetails>}
     * */
    async getApartmentDetails(buildingID, apartmentNumber) {
        return await this.#client.get(`${this.#prefix}/apartmentDetails/${buildingID}/${apartmentNumber}`, {}, { throwError: true });
    }

    /**
     * @typedef {object} CartPaymentChargedResult
     * @property {boolean} success
     * @property {number} PaymentID
     * @property {boolean} isChargePaymentAPI
     * */

    /**
     * @typedef {object} CartPaymentChargeData
     * @property {number} paymentID
     * @property {number} paymentDetailID
     * @property {CartPaymentChargedResult[]} chargedResult
     * */

    /**
     * @typedef {object} CartChargeResult
     * @property {string} cartID
     * @property {true} success
     * @property {CartPaymentChargeData[]} charged
     * */

    /**
     * @param {string} cartID
     * @param {number} paymentID last payment id
     * @param {string} last4Digits
     * @param {boolean} isBankCharge
     * @param {boolean} isFuturePayment
     * @return {Promise<CartChargeResult>}
     * */
    async chargeCart(cartID, paymentID, last4Digits, isBankCharge, isFuturePayment) {
        return this.#client.post(
            '/managers/operations/charge-cart/',
            { cartID, paymentID, last4Digits, isBankCharge, isFuturePayment },
            { throwError: true },
        );
    }
}
