export const buildingPayment = {
    "welcome": "ברוכים הבאים",
    "welcome_without_entrance": "ברוכים הבאים ל{{street}} {{streetNumber}}, {{city}}",
    "welcome_with_entrance": "ברוכים הבאים ל{{street}} {{streetNumber}} כניסה {{entrance}}, {{city}}",
    "welcome_to": "ברוכים הבאים ל",
    "payment_co_op": "תשלום ועד בית",
    "payment_to": "תשלום דמי ועד בית עבור ",
    "first_payment": "תשלום חודשי",
    "installment_cost": "תוספת עמלה לכל תשלום",
    "payment_does_not_take_credit": "התשלום אינו תופס מסגרת אשראי",
    "first_charge_one_payment_only": "סה\"כ לחיוב",
    "payment_services": "שירותי תשלום",
    "payment_services_via_tranzila": "שירותי תשלום מאובטחים באמצעות חברת טרנזילה בע״מ",
    "tourist_comment": "תשלום בכרטיס זר (תייר), אמריקן אקספרס או דיינרס יגרור עמלה נוספת שתחוייב בנפרד בימים הקרובים",
    "confirmation_email": "אישור תשלום\/קבלה נשלח גם במייל",
    "empty": "",
    "address_without_entrance": "{{street}} {{streetNumber}}, {{city}}{{apartmentText}}",
    "address_with_entrance": "{{street}} {{streetNumber}} כניסה {{entrance}}, {{city}}{{apartmentText}} ",
    "apartmentText": ", דירה {{apartmentNumber}}",
    "updates": "עדכונים לדיירי הבניין",
    "payment_method": "תשלום באמצעות",
    "managers": "נציגי הבניין",
    "makePayment": "ביצוע תשלום",
    "fill_in_details_secondary_title": "מלאו את הפרטים האישיים ואת פרטי הדירה ",
    "details_of_payer": "פרטי המשלם\/ת",
    "number_of_apartment": "מספר דירה",
    "full_name": "שם מלא",
    "type_of_tenant": "סוג דייר",
    "right_tenant_check": "לא אתה? פרטים חסרים?",
    "renter": "שוכר/ת",
    "owner": "בעלים",
    "phone": "טלפון",
    "email": "דוא\"ל",
    "comment": "הערת תשלום",
    "agree_to_ads": "אישור קבלת דיוור מבלינק",
    "accept_website_tos": "הנני מאשר\/ת את",
    "button_agree": "בלחיצה על כפתור זה הנני מאשר/ת את",
    "tos": "תנאי שימוש",
    "next": "הבא",
    "update_personal_details": "עדכון פרטים אישיים",
    "pay_debt": "תשלום חודשים שעברו",
    "pay_on_going": "תשלום ועד חודשי",
    "pay_one_time": "תשלום מיוחד",
    "pay_expense": "תשלום הוצאה",
    "debt_title": "חודשים שלא שולמו",
    "debt_sub_title": "נשארו כמה תשלומים פתוחים עבור הדירה בסך {{amount}} {{currency}}",
    "on_going_title": "תשלום חודשי",
    /** @deprecated use currency.ils instead  */ "ils": "₪",
    /** @deprecated use currency.mxn instead  */ "mxn": "$",
    /** @deprecated use currency.gbp instead  */ "gbp": "£",
    /** @deprecated use currency.eur instead  */ "eur": "€",
    "total_to_pay": "סה\"כ לתשלום",
    "first_charge": "סה\"כ חיוב ראשון",
    "number_of_installments": "מספר תשלומים",
    "first_payment_amount": 'סכום תשלום ראשון',
    "total_payment_amount": 'סכום התשלום הכולל',
    "payment_details": "פרטי תשלום",
    "monthly_payment": "תשלום חודשי",
    "months_selected": "פירוט חודשים",
    "month_selected": "פירוט חודש",
    "future_payments_by_months": "כמה יהיה התשלום החודשי שלי?",
    "future_payments_sub_title": "פירוט תשלומים חודשי",
    "future_payments_additional_title": "שימו 💜 תשלום ועד הבית באמצעות בלינק אינו תופס מסגרת אשראי",
    "automatic_billing_note": "תוכנית התשלומים שלך תחודש אוטומטית אם לא נקבל את תגובתך לגבי החידוש באמצעות הודעות טקסט",
    "pay_with_bank_permission": "תשלום בהוראת קבע בנקאית",
    "pay_credit_card": "תשלום בכרטיס אשראי",
    "everything_is_paid": "כל הכבוד! כאן הכל כבר שולם ",
    "confirmation_title": "אישור תשלום\/קבלה - דמי ועד בית",
    "4_last_digits": "4 ספרות אחרונות",
    "payment_for": "תשלום עבור",
    "card_type": "סוג כרטיס",
    "charge_card": "ביצוע תשלום",
    "transaction_date": "תאריך ביצוע העסקה",
    "no_confirmation_yet": "עסקה עדיין לא אושרה",
    "proceed_to_checkout": "סיימתי, אני רוצה לשלם",
    "back": "חזרה",
    "harshaaFileName": "הרשאה לחיוב חשבון.pdf",
    "call_us_for_bank_allow": "התקשר\/י אלינו להגדרת הוראת קבע. 03-5090045",
    "call_us_for_bank_allow_title": "הוראת קבע ב5 דקות",
    "confirmation_id": "אסמכתא",
    "dont_accept_america_express_cards": "אמריקן אקספרס? לצערנו אמריקן אקספרס הוא הכרטיס היחידי שאנחנו לא מקבלים כרגע. אנא נסה\/י עם כרטיס שונה.",
    "payment_error": "שגיאת חיוב",
    "incorrect_cvv": "אנא בדוק\/י את 3 הספרות האחרונות CVV",
    "cart_empty_title": "עגלה ריקה",
    "cart_empty_text": "לא בחרת בפריט לתשלום, לא ניתן להתקדם למסך הבא. אנא סמנ\/י לפחות פריט אחד",
    "offline_payment_amounts_are_wrong": "סכום תשלומים לא נכון",
    "offline_payment_amounts_are_wrong_text": "הוגדרו תשלומים בסך {{amountsSent}} אך סכום רצוי הוא {{totalToPay}}",
    "commissionTextForTenantInPercentage": "לתשלום תתווסף עמלה בסך {{commissionPercentage}}%",
    "commissionTextForTenantInInDollars": "לתשלום תתווסף עמלה בסך {{commissionInDollars}} {{currency}}",
    "confirmationNumbersText": "אסמכתאות עסקה",
    "buildingPageExplain-1": "כיף לראות אתכם",
    "buildingPageExplain-2": "לחצו על ביצוע תשלום ותתקדמו לתשלום חכם",
    "sub_title_before_payment": "מעבר נוסף על כל הפרטים לפני שמבצעים את התשלום",
    "record_expenses": "תיעוד הוצאות ",
    "renewal_for": "חידוש תשלום עבור ",
    "renewal_title": "חידוש תשלום - דמי ועד בית",
    "renew_months_forward": "{{numberOfMonths}} חודשים",
    "renew_option": "נא לחדש את ועד הבית לתקופה של",
    "dont_renew_option": "אל תחדשו לי את התשלום בגלל ש",
    "bllink_bank_charge": "בלינק",
    "update_credit_card": "עדכון פרטי אשראי ",
    "failed_payment_title": "עדכון פרטי אשראי - דמי ועד בית",
    "renew_send": "שלח\/י",
    "already_recorded_response_for_renewal": "כבר רשמנו את התשובה עבור הדירה שלך",
    "expense_edit": "עריכת הוצאות ",
    "setup_using_this_flow_1": "התהליך כולו לוקח כ-5 דקות ומומלץ להתחילו ממחשב ולא מטלפון נייד",
    "setup_using_this_flow_2": "ניתן להיעזר ",
    "setup_using_this_flow_3": "במדריך הקמת הרשאה לחיוב חשבון",
    "setup_using_this_flow_4": "שימו 💙 עבור הקמת ההרשאה ישנה עלות עמלה חד פעמית של הבנק בגובה 15 ש\"ח",
    "setup_auto_charge": "ניתן גם להקים הרשאה לחיוב חשבון בצורה פשוטה דרך בלינק",
    "future_payment_date": "תאריך תשלום עתידי",
    "address": "{{street}} {{streetNumber}}, {{city}}",
    "month": "חודש",
    "year": "שנה",
    "canceled_deal": "עסקה בוטלה",
    "payment_have_commission": "התשלום המוצג כולל עמלת סליקה",
    "payment_confirmation": "תשלום התבצע בהצלחה, הינך מועבר\/ת לעמוד אישור תשלום בעוד מספר שניות",
    "here": "כאן",
    "login_tenant": "התחברות לאיזור אישי",
    "already_logged_in_as_tenant": "מחובר/ת לאיזור האישי כדייר/ת",
    "cant_pay_with_credit_card": "אופס... לא ניתן לבצע תשלום על חודשים אלו באמצעות כרטיס אשראי",
    "cant_pay_with_credit_card_text": "לא ניתן לשלם עם כרטיס אשראי עבור העגלה הנוכחית. לתשלום עם כרטיס אשראי, אנא בטל\/י סימון תשלום ועד חודשי",
    "tenant_details_missing_title": "פרטי דייר\/ת חסרים",
    "tenant_details_missing_text": "פרטי דייר\/ת חסרים. אנא חזור\/י למסך ״עדכון פרטיים אישיים״ ומלא\/י פרטייך",
    "dont_renew_moved": "עברתי דירה",
    "dont_renew_call_me": "מעוניין\/ת שיצרו איתי קשר",
    "dont_renew_paid_via_cheque": "שילמתי כבר בצ׳קים",
    "dont_renew_other": "סיבה אחרת",
    "already_updated_this_failed_payment": "כבר עודכנו פרטי כרטיס אשראי עבור תשלום זה",
    "payment_plan_not_active_pay_at_building_page": "לינק אינו פעיל כבר. ניתן לשלם עבור חוב דרך עמוד הבניין <buildingLink>",
    "submitted_renew_response_successfully": "מעולה, התשלום חודש לפי מספר החודשים שנבחר. האישור יגיע למייל בדקות הקרובות.",
    "submitted_dont_renew_response_successfully": "קיבלנו את תשובתך. תודה רבה.",
    "cart_has_been_payed_already": "בוצע כבר תשלום עבור עגלה זאת, הינך מועבר\/ת לאישור התשלום",
    "tzla_script_load_failed": "לא ניתן לאתחל טופס כרטיס אשראי, נסה מאוחר יותר או מכשיר או דפדפן אחר",
    "select_placeholder": "בחר/י...",
    "open_new_building": "פתיחת בניין חדש",
    "choose_action": "בחרו את הפעולה הרצויה",
    "proceed_to_payment": "ביצוע תשלום",
    "proceed_to_tasks": "פתיחת תקלה",
    "additional_services": "שירותים ובעלי מקצוע",
    "contact_manager": "יצירת קשר",
    "installments_available": "חלוקה לתשלומים נוחים",
    "fifty_percent_mortgage": "עד 50% חסכון בביטוח משכנתא",
    "contact_soon": "מעולה! אחד מנציגנו ייצור אותך קשר בהקדם.",
    "ask_for_offer": "בקש הצעה",
    "contact_manager_subheader": "פרטי יצירת קשר עם מנהל הבניין",
    "start_chat": "התחל שיחה",
    "manager_name": "שם מנהל בניין",
    "manager_phone": "טלפון מנהל בניין",
    "manager_email": "דוא״ל מנהל בניין",
    "set_as_building_manager": "הנני ועד הבית של הבניין",
    "how_to_pay": "איך תרצו לשלם את דמי ועד הבית?",
    "bank": "בנק",
    "bit": "ביט / bit",
    "cheque": "שיקים",
    "applePay": "אפל פיי / Apple Pay",
    "googlePay": "גוגל פיי / Google Pay",
    "fill_details": "מלאו את פרטי המשלם עבור אישור התשלום",
    "mail": "דוא״ל",
    "terms_of_use": "הנני מאשר/ת",
    "confirm_mail": "הנני מאשר/ת קבלת דיוור מבלינק",
    "please_fill": "אנא מלא את כל השדות הנדרשים",
    // requested by Talv via email to increase loans
    "payment_will_renew": "נהלו את תקציב הבית בצורה חכמה ונוחה יותר עם חלוקה לתשלומים",
    // https://bllink.atlassian.net/browse/SSB-2140 Set payment - Tenant side
    "debt_amount": "ממתין לתשלום",
    "monthly_fee": "תעריף חודשי",
    "selected_months": "חודשים נבחרים",
    "click_to_view": "לחצ/י להצגת תשלומי השוכר/ת",
    "show_owner_payments": "הצגת תשלום מיוחד פתוח",
    "show_renter_payments": "הצגת תשלום חודשי פתוח",
    "months_details": "בחירת חודשים",
    "special_payment": "תשלום מיוחד",
    "special_details": "בחירת תשלום מיוחד",
    "debt_amount_past": "חוב פתוח (שנים קודמות)",
    "current_year_amount": "תשלום לשנה הנוכחית",
    // https://bllink.atlassian.net/browse/SSB-1247
    "selected_payments": "פריטים נבחרים",
    "apply": "אישור",
    "mark_as_paid": "דיווח כשולם",
    "success": "הסתיים בהצלחה",
    "no_payments_selected": "לא נבחרו תשלומים",
    "on_going": "ועד בית - שוטף",
    "past": "ועד בית - חוב פתוח",
    "no_open_payments": "אין תשלומים פתוחים",
    "select_date": "מלאו את פרטי המשלם וביחרו תאריך לתיאום איסוף התשלום",
    "checkout_info": "ניתן לפרוס את החובות עד ל-12 תשלומים נוחים בלחיצת כפתור",
    "bit_info": "לקבלת התשלום, לחצו על כפתור שלם עם ביט ולאחר מכן הזינו מס׳ טלפון / סרקו את קוד הQR / לחצו על כפתור למעבר לאפליקצית ביט (למשתמשים במובייל)",
    "applePay_info": "לתשלום באפל פיי, לחצו על הכפתור. תשלום באפל פיי מחייב שימוש במכשיר אפל ובדפדפן ספארי",
    "googlePay_info": "לתשלום בגוגל פיי, לחצו על הכפתור. לא ניתן לשלם עם גוגל פיי במוצרי אפל",
    "applePayLabel": "תשלום ועד בית",
    "payment_page": "עמוד תשלום",
    "payment_page_explain": "ניתן לבטל חודשים לתשלום במידה והמידע כאן לא מעודכן ע״י לחיצה על בחר/י חודשים",
    "payment_plan_was_not_created": "תוכנית תשלום לא נוצרה",
    "debts": "ועד חודשי - חובות",
    "onGoing": "ועד חודשי - שוטף",
    "show_details": "בחר/י חודשים",
    "details": "פירוט",
    "total": "סכום כולל",
    "distribution_cost": "עלות חלוקת תשלומים",
    "first_date": "מועד לחיוב הראשון",
    "fixed_day": "יום קבוע לתשלום",
    "payment_note": "לתשלום תתווסף עמלה בסך 1.4%. תשלום בכרטיס זר (תייר) יגרור עמלה נוספת שתחוייב בנפרד בימים הקרובים",
    "digital_signature": "חתימה דיגיטלית",
    "credit_card": "כרטיס אשראי",
    "notice": "שימו לב",
    "notice_info": "הבנק דורש 10 ימי עסקים לאישור הבקשה לתשלום. לאור דרישת הבנק, התשלום הנוכחי לא יבוצע מיידית ויתווסף לתשלום בחודש הבא",
    "spread_payment": "רוצים לפרוס תשלום חוב או תשלום מיוחד?",
    "when_deploy_payments": "מתי אפשר לפרוס לתשלומים?",
    "divide_payments_info": "בד״כ תשלום חוב או תשלום מיוחד ניתן לשלם בתשלום 1 בלבד. דרך בלינק ניתן לחלק את הסכום לתשלומים שווים שיאפשרו לכם גמישות מלאה.",
    "how_to_divide": "איך מבצעים את החלוקה?",
    "division_info": "ממש בקרוב נשיק את האפשרות לבצע את הפריסה ישירות מהמערכת. עד אז... הנציגים שלנו זמינים לבצע לכם את החלוקה דרך הטלפון.",
    "contact_info": "פרטי יצירת קשר",
    "bllink_customer_support": "מוקד בלינק",
    "back_to_payments": "חזור לביצוע תשלום",
    "contact_representative": "צור קשר עם נציג",
    "commissionOn": "ללא תפיסת מסגרת",
    "noCommission": "חינם וללא תפיסת מסגרת",
    "directTransferPayment": "תשלום בהעברה ישירה לחשבון",
    "chooseBankPayment": "יש לבחור את צורת התשלום דרך הבנק",
    "directPayment": "העברה ישירה",
    "digitalPermission": "הרשאה דיגיטלית",
    "amountToTransfer": "סכום להעברה",
    "verificationCode": "קוד אימות",
    "transferNote": "(להוספה כהערה או כסיבת העברה)",
    "paymentConfirmationWillBeSent": "*אישור תשלום ישלח לאחר אישור קוד האימות שיצורף להערת ההעברה",
    "copyCode": "העתק קוד",
    "bankAccountDetails": "פרטי חשבון בנק",
    "byOrderBllink": "לפקודת - בלינק מערכות בע״מ",
    "copied": "הועתק ללוח!",
    "installmentDisabled": "לא ניתן לפרוס לתשלומים עם אמצעי תשלום זה",
    "loginToPatExistingCard": "התחבר לתשלום בכרטיס קיים",
    "paymentThroughBank": "תשלום דרך הבנק",
    "howToMakePayment": "איך מבצעים תשלום בהעברה בנקאית?",
    "directTransfer": "העברה בנקאית ישירה",
    "digitalAuth": "הקמת הרשאה דיגיטלית",
    "fillDetailsForTransfer": "לאחר מילוי הפרטים יוצגו פרטי החשבון להעברה, נדרש לצרף את קוד האימות המוצג להערה/סיבת העברה",
    "confirmationOrPending": "במידה ולא יצורף אישור, התשלום לא יאומת ויוצג כממתין לתשלום",
    "establishingAuth": "הקמת הרשאה דיגיטלית",
    "fastSignProcess": "תהליך מהיר של חתימה דיגטלית עבור התשלום ישירות מחשבון הבנק",
    "tenDaysToVerify": "התשלום הראשון נדרש ל-10 ימי עסקים לאימות הפרטים",
    "contextLost": "אין מספיק נתונים להמשיך. חוזר לשלב הראשון",
    "contextLostAtAll": "אין מספיק נתונים להמשיך. חוזר לשלב הראשון",
    "savingMoney": "חוסכים לך כסף כל חודש!",
    "ezCharger": "עמדת טעינה לבניין - " +
        "הצעה לדיירי בלינק",
    // todo break line after one more minute
    // todo include only ils buildings
    "flowersAddOn": "עוד רגע אחד...<br>" +
        "יש לנו פינוק נוסף עבורך!",
    "insuranceStillInBank": "באמת?! <br> ביטוח המשכנתא שלך עדיין דרך הבנק?",
    "insuranceSavings": "חיסכון של עד 50% בביטוח המשכנתא",
    "ezChargerDescription": "הצעה מיוחדת לדיירי בלינק, הזמנת עמדת טעינה לבניין במחיר מיוחד!",
    "pizzaDescription": "מה דעתך להנות מפיצה טעימה שתישלח ישירות לביתך? \n" +
        "משפחתית ענקית רק ב- 51 ₪ " +
        "בלחיצה אחת פשוטה, תוכל לפנק את עצמך בפיצה חמה וטרייה ב- 20% הנחה, עם פרטי כרטיס האשראי שביצעת איתם את התשלום.",
    "flowersDescription": "איזה כיף ששילמת עם בלינק!<br>" +
        "החודש אנחנו מציעים לדיירי {{buildingAddress}} עציץ שווה עם משלוח עד הבית ב- <price> בלבד!",
    "lowerInsurance": "ביטוחי משכנתא דרך הבנק הם היקרים ביותר, תנו לנו להוזיל לכם עלויות",
    "weSavedUpTo": "עד היום חסכנו ללקוחותינו בממוצע 250₪ בחודש לא תרצו גם?",
    "paymentUponPurchase": "התשלום יבוצע אוטומטית עם השלמת הרכישה.<br> צבע העציץ הינו להמחשה בלבד. משלוח עד 7 ימי עסקים",
    "pizzaAddOn": "תשלום בוצע.",
    "ezChargerHoverText": "מחיר מיוחד לדיירי בלינק",
    "ezChargerContinueToOffer": "פתח הצעה בעמוד נפרד",
    // "ourCustomersChoose": "מתוך 10 לקוחות של בלינק 8 כבר עשו ביטוח משכנתא בגור, מה איתכם?",
    // https://bllink.atlassian.net/browse/SSB-1325
    "ourCustomersChoose": "מחזירים לך כסף כל חודש בביטוח המשכנתא",
    "checkHowMuchCanBeSaved": "לוחצים ובודקים כמה ניתן לחסוך בביטוח המשכנתא",
    "perfectWayTreatYourself": "הדרך המושלמת לפנק את עצמך על תשלום בזמן לקופת ועד הבית",
    "perfectWayTreatYourselfFlowers": "אם יש לכם שאלות או אתם זקוקים לעזרה, אנא אל תהססו לפנות אלינו",
    "scheduleCall": "לקביעת שיחה להוזלת הביטוח",
    "getTheCoupon": "לקבלת העציץ והשלמת הרכישה",
    "continueToConfirmPayment": "המשך לאישור התשלום",
    "representativeWillContactYou": "נציגנו ייצור עמכם קשר בהקדם",
    "itemCurrentlyNotAvailable": "פריט לא זמין כרגע. לא בוצע חיוב",
    "proceed_to_payment_action": "התקדם לתשלום",
    "payment_success": "תשלום בוצע בהצלחה",
    "flowers":{
        "initialPrice": "מחיר: 100₪",
        "afterDiscount": "מחיר לאחר 20% הנחה: 80₪",
        "notice": "שימו לב, המחיר כולל משלוח עד לבית הלקוח",
    },
    "mortgage_insurance_confirmation": {
        "title": 'חוסכים בביטוח המשכנתא',
        "description": 'רגע לפני שנציג יחזור אליך עם הצעה',
        "request": 'אנא אשר את הבקשה',
        "confirm": 'אישור',
        "cancel": 'לא תודה',
    },
    "additional_product_confirmation": {
        "title": 'פיצה בקליק',
        "titleFlowers": 'עציץ עם משלוח עד הבית',
        "description": 'רגע לפני ששולחים לך את הקופון',
        "descriptionFlowers": 'רגע לפני ששולחים לך את העציץ',
        "request": 'אנא אשר את הבקשה',
        "confirm": 'אישור',
        "cancel": 'לא תודה',
    },
    "fullPaymentHistory": "היסטוריית תשלומים מלאה",
    "openInApp": "פתח באפליקציה",
    "paymentOptions": "אמצעי תשלום נבחר",
};
