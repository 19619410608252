import styled from 'styled-components';

export const StyledManagerPage = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: ${props => props.noVerificationPage ? '' : props.theme.mainBg};
  font-family: var(--bllink-font-primary);
  color: ${props => props.theme.text};
  text-align: initial;
  
  h1, h2, h3 {
    margin: 0;
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .page {
    display: flex;
    flex-direction: column;
    // 92px - footer height, so user should scroll to see it
    // according to https://bllink.atlassian.net/browse/SSB-1513
    min-height: calc(100vh + 92px);
    width: calc(100% - ${props => props.noVerificationPage ? 0 : props.collapsedSidebar ? props.theme.sideBarCollapsedWidth : props.theme.sideBarWidth});
    margin-inline-start: ${props => props.noVerificationPage ? 0 : props.collapsedSidebar ? props.theme.sideBarCollapsedWidth : props.theme.sideBarWidth};
    padding: 40px 60px 25px;
    transition: all .3s ease-in;
    // transform: scale(0.9);
    &-content {
      position: relative;
      max-width: 1200px;
      width: 100%;
      margin: 0 auto 25px auto;

      @media screen and (max-width: 768px) {
        padding-top: 50px;
      }
      &.newMobilePage {
        @media screen and (max-width: 768px) {
          padding-top: 0 !important;
        }
      }
    }
    &-title {
      display: flex;
      align-items: center;
      max-width: 600px;
      margin: 0;
      font-size: 30px;
      line-height: 43px;
      font-weight: bold;
      img {
        margin-inline-end: 10px;
      }
      .address, .building-title, .partner-name {
        padding-inline-start: 5px;
        color: ${props => props.theme.main};
      }
      &.margin-bottom {
        margin-bottom: 50px;
      }
      &.text-only {
        display: block;
        text-align: initial;
      }
      &.centered {
        text-align: center;
        margin: 0 auto 30px;
      }
    }
    .scroll-track {
      width: 7px !important;
      background: #fff !important;
    }
    .scroll-thumb {
      background: linear-gradient(179.97deg, #6D8FFF -24.96%, #8467FF 99.97%) !important;
      border-radius: 25px !important;
    }
  }
  
  footer {
    margin: auto -60px -25px;
  }
  
  @media screen and (max-width: 1440px) {
    .page {
      padding-left: 30px;
      padding-right: 30px;
    }

    footer {
      margin: auto -30px -25px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .page {
      width: 100%;
      margin-inline-start: 0;
    }
  }

  @media print {
    .page {
      margin: 0 20px 20px;
      padding: 0;
      width: calc(100% - 40px);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;
