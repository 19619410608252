import styled from "styled-components";
import searchImg from "../images/search.svg";

const CollapsibleSearchStyled = styled.div`

  --loading-color: #7483FF;
  
  display: flex;

  .searchButton {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    border: none;
    position: relative;
    padding: 0;
    background: transparent;
    transition: all .3s ease;

    div {
      background: url("${searchImg}");
      width: 24px;
      height: 24px;
      opacity: 1;
      transition: opacity .3s ease-out .2s;
    }

    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      border: 2px solid var(--loading-color);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: block;
      transform: scale(0.6) translate(-18%, -18%);
      transition: all .4s ease 0s;
      opacity: 0;
    }

    span {
      display: block;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 2px;
      border-radius: 1px;
      margin: -1px 0 0 -1px;
      position: absolute;
      transition: background 0s ease 0s;

      &:before,
      &:after {
        content: '';
        top: 0;
        width: 2px;
        height: 2px;
        opacity: 0;
        border-radius: 1px;
        position: absolute;
        background: var(--loading-color);
        transition: all .3s ease 0.1s;
      }

      &:before {
        right: 0;
      }

      &:after {
        left: 0;
      }

      svg {
        display: block;
        fill: none;
        opacity: 0;
        stroke-linecap: round;
        stroke-width: 5px;
        stroke: var(--loading-color);
        width: 15px;
        height: 15px;
        position: absolute;
        left: 0;
        top: 0;
        stroke-dasharray: 47;
        stroke-dashoffset: 67;
        transition: all .4s ease .31s, opacity .1s ease .4s, stroke .4s ease .0s;
      }
    }

    &.loading {
      div {
        opacity: 0;
        transition: opacity .3s ease;
        transition-delay: 0s;
      }

      &:before {
        opacity: 1;
        transform: scale(1) translate(0, 0);
      }

      span {
        opacity: 1;
        background: var(--loading-color);
        animation: rotate 1s infinite ease .8s;

        svg {
          transition-delay: 0s;
          stroke-dasharray: 48;
          stroke-dashoffset: 139;
          transition: all .4s ease 0s, opacity 0s ease .4s;
        }

        &:before,
        &:after {
          opacity: 1;
          transition-delay: .4s;
        }

        &:before {
          transform: translate(-4px, 0);
        }

        &:after {
          transform: translate(4px, 0);
        }
      }
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

/**
 * @param {() => void} handleButtonClick
 * @param {boolean} isLoading
 * @param {string} className
 * @returns {JSX.Element}
 * @constructor
 */
export const SearchButton = ({handleButtonClick, isLoading = false, className = ''}) => {
    return <CollapsibleSearchStyled>
        <button
            type="button"
            className={`searchButton ${isLoading ? 'loading' : ''} ${className ?? ''}`}
            onClick={handleButtonClick}>
            <div></div>
            <span>
            <svg viewBox="0 0 40 40">
                <path d="M3,3 L37,37"></path>
            </svg>
        </span>
        </button>
    </CollapsibleSearchStyled>
}