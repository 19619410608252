import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import { constants } from './constants/constants';
import BllinkTime from './utils/time';
import {GAEvent} from "./google_analytics/google_analytics";
import CacheData from "./utils/cacheData";
import logoBllink from '../images/Logo-Full-Dark.png';
import internetIcon from '../images/newDesign/internet-icon.svg';
import {selectCurrentBuildingCurrencyUnsafe} from "../store/buildings/selectors";
import { translatesLegalInfoPages } from "./constants/translatedLegalInfoLinks";
import {shareToSocial} from "./shared/statelessMethods";

const StyledFooter = styled.footer`
  font-family: var(--bllink-font-headers);
  margin-top: auto;
  background: #FFFFFF;
  border-top: 1px solid #E6E3FC;
  
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    width: 100%;
    margin: auto;
    padding: 30px;

    .logo {
      width: 130px;
    }
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    
    a {
      padding: 10px 20px;
      font-size: 16px;
      text-transform: capitalize;
      color: #373647;
    }
  }

  .language-selector {
    position: relative;

    img {
      cursor: pointer;
    }

    .languages {
      position: absolute;
      display: flex;
      gap: 12px;
      left: 50%;
      transform: translateX(-50%);

      span {
        cursor: pointer;
        color: #373647;
      }

      .selected {
        color: #6E74FF;
      }
    }
  }
  
  .copyright {
    font-size: 12px;
    color: rgba(55, 54, 71, 0.4);
    text-align: center;
  }
  
  footer {
    max-width: 100%;
    padding-top: 50px;
    margin-top: auto;
    z-index: 0;
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      flex-direction: column;
      padding: 50px 25px;

      ul {
        flex-direction: column;
        align-items: center;
        gap: 29px;
        margin: 38px 0 29px;
      }

      .language-selector {
        margin-bottom: 38px;

        .languages {
          bottom: -30px;
        }
      }
    }
  }
`;

const CURRENCY2LNG = {
    'MXN': 'es',
    'USD': 'en',
    'ILS': 'he',
};

export default function Footer({forceFooter = false}) {
    const { t, i18n } = useTranslation();
    const currentYear = BllinkTime.getCurrentYear();

    const LANGUAGES = [
        { value: 'en', label: t('footer.languages.en') },
        { value: 'he', label: t('footer.languages.he') },
        // { value: 'es', label: t('footer.languages.es') },
    ];

    const [language, setLanguage] = useState(LANGUAGES.find(o => o.value === i18n.language));
    const [showLang, setShowLang] = useState(false);

    const buildingCurrency = useSelector(selectCurrentBuildingCurrencyUnsafe);
    const userProfile = useSelector(state => state.auth.userProfile);
    const loggedByPhone = useSelector(state => state.commonPersistent.tenantPhone != null);
    const currency = userProfile?.currency ?? buildingCurrency;

    useEffect(async () => {
        if (currency == null) return;
        const manuallyChanged = CacheData.fetchCache('language_manual') ?? false;
        if (!manuallyChanged) {
            // NOTE: save it the same way as manually, to avoid language reset on page refresh
            const lng = CURRENCY2LNG[currency];
            await onLanguageChange(lng);
        }
    }, [currency]);

    async function onLanguageChange(lng) {
        if (lng !== i18n.language) {
            const key = `${lng}_from_${i18n.language}`;
            GAEvent(constants.GoogleAnalytics.Events.general, 'changeLanguage', key, 'changeLanguage');
            setLanguage(LANGUAGES.find(l => l.value === lng));
            CacheData.cache('language_manual', true);
            await i18n.changeLanguage(lng);
            window.dispatchEvent(new CustomEvent('changeLanguageOnIndex', {detail: { lng }}));
        }
    }

    const accessibilityUrl = translatesLegalInfoPages.accessibilityUrl[language.value] ?? translatesLegalInfoPages.accessibilityUrl.default;
    const termsOfUseUrl = translatesLegalInfoPages.termsOfUseUrl[language.value] ?? translatesLegalInfoPages.termsOfUseUrl.default;
    const privacyUrl = translatesLegalInfoPages.privacyUrl[language.value] ?? translatesLegalInfoPages.privacyUrl.default;

    const onShowOrHideLang = () => setShowLang(!showLang);

    if ((currency == null || loggedByPhone) && !forceFooter) {
        // NOTE: currency == null is to avoid content in default language until building data is not loaded
        return '';
    }

    return (
        <StyledFooter>
            <div className='wrapper'>
                <img className='logo' src={logoBllink} />

                <div className='links'>
                    <ul>
                        {
                            <li hidden={currency === 'USD' || i18n.language === 'es'}  >
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`${constants.pages.mainBllinkSite}/${constants.pages.questionAndAnswers}`}
                                >
                                    {t('footer.q_and_a')}
                                </a>
                            </li>
                        }

                        <li hidden={currency === 'USD'} ><a href={accessibilityUrl} target="_blank" rel="noreferrer" >{t('footer.accessibility')}</a></li>
                        <li><a href={termsOfUseUrl} target="_blank" rel="noreferrer" >{t('footer.policy')}</a></li>
                        <li><a href={privacyUrl} target="_blank" rel="noreferrer" >{t('footer.privacy')}</a></li>
                        <li hidden={currency === 'USD'}><a href="#" onClick={(e) => {
                            e.preventDefault();
                            shareToSocial({
                            }, t);
                        }}>
                            {t('footer.share')}
                        </a></li>

                        {
                            <li hidden={currency === 'USD' || i18n.language === 'es'} >
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    href={`${constants.pages.mainBllinkSite}/${constants.pages.contactUs}`}
                                >
                                    {t('footer.support')}
                                </a>
                            </li>
                        }
                    </ul>
                </div>

                <div className='language-selector'>
                  <img src={internetIcon} onClick={onShowOrHideLang} />

                  {showLang && (
                    <div className='languages'>
                        {/*<span*/}
                        {/*  className={language.value === 'es' ? 'selected' : ''}*/}
                        {/*  onClick={() => onLanguageChange('es')}*/}
                        {/*>*/}
                        {/*  Esp*/}
                        {/*</span>*/}
                        <span
                          className={language.value === 'he' ? 'selected' : ''}
                          onClick={() => onLanguageChange('he')}
                        >
                          Heb
                        </span>
                        <span
                          className={language.value === 'en' ? 'selected' : ''}
                          onClick={() => onLanguageChange('en')}
                        >
                          En
                        </span>
                    </div>
                  )}
                </div>

                <div className='copyright'>{t('footer.all_rights', {currentYear: currentYear})}</div>
            </div>
        </StyledFooter>
    );
}
