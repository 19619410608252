export const currency = {
    "ils": "₪",
    "mxn": "$",
    "gbp": "£",
    "eur": "€",
    "usd": "$",

    // the same but uppercase
    "ILS": "₪",
    "MXN": "$",
    "GBP": "£",
    "EUR": "€",
    "USD": "$",
}
