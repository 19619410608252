// Helpers to get modified data from redux store
import { createSelector } from '@reduxjs/toolkit';
import {constants} from "../../common/constants/constants";
import {storeMock} from "../../tenants/store/storeMock";
import {storeProductsDavid} from "../../tenants/store/storeMockDavid";
import {additionalServices} from "../../managers/store/additionalServices";


export const selectCurrentBuildingCurrencyUnsafe = state => state?.buildings.currentBuildingData?.currency ?? state?.buildings.managerData?.currency;

export const selectCurrentBuildingCurrency = createSelector(
    selectCurrentBuildingCurrencyUnsafe,
    currency => currency ?? constants.defaultCurrency
);


export const isAllowedForDirectBankWire = createSelector([
        state => state.buildings.currentBuildingData,
        state => state.tenant.tenantCommission
    ],
    function (currentBuildingData, tenantCommission) {
        // requested by rayee to close. 100% of the tenants are NOT adding the cartID to the wire comment
        return false
        // const buildingID = currentBuildingData.id;
        // const externalPartnerId = currentBuildingData?.managementCompany?.externalId;
        // const isPrivatePartner = externalPartnerId === null;
        // // https://bllink.atlassian.net/browse/SSB-1788 open this feature to all buildings that BMC or the building are paying commission
        // const tenantIsNotPayingTheCommission = Number(tenantCommission) === 0;
        // return (
        //     allowedInternalBuildingIds.includes(buildingID) ||
        //     allowedExternalPartnerIds.includes(externalPartnerId) ||
        //     isPrivatePartner ||
        //     tenantIsNotPayingTheCommission
        // );
});


// tried to add this as a feature and the json is too big
export const buildingStoreProducts= createSelector([
        state => state.buildings.currentBuildingData
    ],
    function (currentBuildingData) {
        let externalPartnerId = currentBuildingData?.managementCompany?.externalId?.toLowerCase();
        const url = window.location.href;
        const isForManager = url.includes(constants.pages.serviceProviders);
        if (isForManager){
            externalPartnerId = 'managersAdditionalServices'
        }
        // // const isPrivatePartner = externalPartnerId === null;
        switch (externalPartnerId) {
          case 'managersAdditionalServices':
             return additionalServices
          case 'david':
            return storeProductsDavid
          default:
            return storeMock
        }

    });


