export const comments = {
    "history": "הערות דירה",
    "comments": "הערות",
    "apartments": "רשימת דירות",
    "you": "את\/ה",
    "writeComment": "כתוב\/י הערב",
    "add": "הוסף\/י הערה",
    "manager": "מנהל\/ת",
    "bllink": "בלינק Bllink",
    "empty": "לא קיימות הערות"
};
