export const login = {
    "login_building_title": "התחברות מנהל לחשבון הבניין",
    "login_building_sub_title": "הידעת? 4.5 מיליון ישראלים מעדיפים כרטיס אשראי על כל שיטת תשלום אחרת",
    "forgot_password": "שכחת סיסמא?",
    "login_with": "התחברות באמצעות",
    "sign_up_for_demo": "הרשמה לחשבון דמו לועד בית",
    "open_account": "פתיחת חשבון דמו לועד בית",
    "login_to_account": "התחברות לחשבון",
    "logout": "התנתקות",
    "hi": "שלום {{firstName}}",
    "facebook": "פייסבוק",
    "google": "גוגל",
    "are_you_a_tenant": "לא ועד בית? התחברות כדייר\/ת",
    "login_generic_error": "שגיאה בהתחברות. אנא בדוק\/בדקי פרטי משתמש",
    "reset_password_sub_title": "שמחים שעברת למערכת החדשה שלנו. להתחברות ראשונית עלייך לבחור סיסמא חזקה ולזכור אותה להתחברות בעתיד",
    "reset_password_sub_explain": "מיד לאחר בחירת הסיסמא החדשה, תועבר\/י שוב לעמוד להתחברות עם הסיסמא החדשה",
    "new_password": "סיסמא חדשה",
    "new_password_again": "סיסמא חדשה שוב",
    "reset_password": "שינוי סיסמא",
    "not_working_on_mobile": "מנהל\/ת יקר\/ה. לצערנו בלינק עדיין לא זמינה עבורך מהנייד.",
    "only_maintenance_on_mobile": "מנהל יקר, ההתחברות לנייד נתמכת רק עבור מנהלי תחזוקה כרגע",
    "must_contain": "סיסמא חייבת להכיל:",
    "small_letter": "אות קטנה באנגלית",
    "capital_letter": "אות גדולה באנגלית",
    "number": "ספרה",
    "8_digits": "לפחות 8 ספרות",
    "same_passwords": "2 הסיסמאות שמולאו הינם אותו דבר"
};
