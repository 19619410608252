/**
 * @typedef {object} ApartmentAddress
 * @property {string} entrance
 * @property {string} city
 * @property {string} [nickname]
 * @property {string} street
 * @property {string} streetNumber
 * */

/**
 * @typedef {Object} TenantApartmentInfo
 * @property {number} PtoPId
 * @property {string} buildingID
 * @property {string} apartmentNumber
 * @property {string} currency
 * @property {ApartmentAddress} address
 * */


export class ProfileApi {
    /** @type {ServerData} */ #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} phone
     * @return {Promise<TenantApartmentInfo[]>}
     * */
    getIsApartmentsAvailable(phone) {
        return this.#client.get('/tenants/profile/isApartmentsAvailable', {phone}, { throwError: true });
    }

    /**
     * @return {Promise<TenantApartmentInfo[]>}
     * */
    getApartments() {
        return this.#client.get('/tenants/profile/apartments', {}, { throwError: true });
    }

    /**
     * @typedef {{
     *    ClientName: string;
     *    ClientPhone: string;
     *    ClientEmail: string;
     *    PlanTypeInt: number;
     *    Comment: string;
     *    PaymentPlanId: number;
     *    PaymentId: number;
     *    Amount: number;
     *    PaymentDate: Date;
     *    paymentMethodId: number;
     *    CardLast4Digits: string;
     *    Expdate: string;
     *    TenantType: string;
     *    isRecurring: 'yes' | 'no';
     *    isRefundable: boolean;
     *    isEditable: boolean;
     *    isFutureCharge: boolean;
     *    reasons: {
     *        isNotEditable: string[];
     *    },
     *    PaymentState: number;
     *    oneTime: { description: string, longDescription: string, oneTimeId: number, totalToPay: CurrencyValue };
     *    months: { month: string; year: string }[] | null;
     *    paymentID: number | null;
     *    invoiceDownloadLink: number;
     *    cartID: string | number;
     *    Method: string;
     *    isOfflinePayment?: true;
     *    confirmationID?: string;
     *    payment: ApartmentReportPayment,
     *    totalAmount: number;
     *    totalInNonCredit: number;
     *    totalInCheque: number;
     *    planID: number;
     *    chequeDetails: {
     *        chequeNum: string;
     *    }
     * }} ApartmentReportPayment
     * */

    /**
     * @param {string} buildingId
     * @param {string} apartmentNumber
     * @returns {Promise<{
     *     paymentsData: {
     *         Total: {value: number},
     *         TotalFuture: {value: number},
     *         TotalInCheque: {value: number},
     *         TotalInCreditCard: {value: number},
     *         TotalOfflinePayments: {value: number},
     *         TotalToWithdraw: {value: number},
     *         TotalToWithdraw: ApartmentReportPayment[],
     *     }
     * }>}
     */
    getApartmentPayments(buildingId, apartmentNumber) {
        return this.#client.get(`/tenants/profile/apartment/${buildingId}/${apartmentNumber}`, {}, { throwError: true });
    }

    /**
     * @param {string|number} buildingId
     * @param {string} apartmentNumber
     * @param {number} paymentId
     * @return Promise<{ invoiceUrl: string }>
     * */
    getPaymentInvoiceUrl(buildingId, apartmentNumber, paymentId) {
        return this.#client.get(`/tenants/profile/apartment/${buildingId}/${apartmentNumber}/payment/${paymentId}/invoice`, {}, { throwError: true });
    }

    /**
     * @param {string|number} buildingId
     * @param {string} apartmentNumber
     * @param {number} year
     * @param {number | null} [paymentPlanId=null]
     * @return {Promise<{ paymentPlans: object[] }>} TODO: add type here
     * */
    getActivePaymentPlans(buildingId, apartmentNumber, year, paymentPlanId = null) {
        return this.#client.get(`/tenants/profile/apartment/${buildingId}/${apartmentNumber}/payment-plan/all-active/${year}/${paymentPlanId ?? ''}`, {}, { throwError: true });
    }


    /**
     * @param {string | number} buildingId
     * @param {string} apartmentNumber
     * @param {number} year
     * */
    getApartmentPaymentsByMonth(buildingId, apartmentNumber, year) {
        return this.#client.get(`/tenants/profile/apartment/${buildingId}/${apartmentNumber}/paymentsByMonths/${year}`, {}, { throwError: true });
    }

    /**
     * Get payment totals based on all unfiltered data (same as for managers)
     * @param {string | number} buildingId
     * @param {string} apartmentNumber
     * @param {number} year
     * @return {Promise<{ totals: { totalOnGoing: CurrencyValue, totalSpecial: CurrencyValue, totalCharged: CurrencyValue, totalOnGoingTillCurrentMonth: CurrencyValue } }>}
     * */
    getApartmentPaymentTotals(buildingId, apartmentNumber, year) {
        return this.#client.get(`/tenants/profile/apartment/${buildingId}/${apartmentNumber}/totals/${year}`, {}, { throwError: true });
    }

    /**
     * @param {string | number} buildingId
     * @param {string} apartmentNumber
     * @return {Promise<{tenantDetails: { apartmentNumber: string, buildingID: string | number, tenant_type?: 'renter' | 'owner', name?: string, phone?: string, email?: string }}>}
     * */
    getTenantDetails(buildingId, apartmentNumber) {
        return this.#client.get(`/tenants/profile/apartment/${buildingId}/${apartmentNumber}/tenant-details`, {}, { throwError: true });
    }

}
