export const theme = {
    main: '#6E74FF',
    text: '#373647',
    textLight: 'rgba(55, 54, 71, 0.6)',
    textLighter: 'rgba(55, 54, 71, 0.3)',
    textTable: '#6A697C',
    green: '#2BB596',
    pink: '#F33F95',
    red: '#F44197',
    redHighlight: '#f33f45',
    borderDark: '#6F8BFF',
    borderLight: '#D8D2FF',
    borderTable: '#A8C0FF',
    mainBg: '#F9F9FF',
    tableText: '#6A697C',
    sideBarWidth: '330px',
    sideBarCollapsedWidth: '80px'
}
