export class FeaturesApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {FeatureOwnerTypeValue} entityType
     * @param {number} entityId
     * @param {string} featureName
     * @param {string|number|boolean} value
     * @return {Promise<FeatureValue>}
     * */
    setFeatureForEntity(entityType, entityId, featureName, value) {
        return this.#client.put(`/managers/features/${entityType}/${entityId}/${featureName}`, { value });
    }

    /**
     * @param {FeatureOwnerTypeValue} entityType
     * @param {number} entityId
     * @param {string} featureName
     * @param {boolean} ignoreBrowserCache
     * @return {Promise<FeatureValue | null>}
     * */
    getFeatureForEntity(entityType, entityId, featureName, ignoreBrowserCache) {
        const params = ignoreBrowserCache ? { t: String(Date.now()) } : {}
        return this.#client.get(`/managers/features/${entityType}/${entityId}/${featureName}`, params);
    }

}
