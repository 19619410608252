export const paymentSettings = {
    "yearly_payment_settings_title": "תעריפים חודשיים {{year}}",
    "maxNumberOfInstallments": "מספר תשלומים מקסימאלי",
    "oneTimeTitle": "תשלום חד פעמי - {{nameOfPayment}}",
    "monthlyYear": "תשלומים חודשיים {{year}}",
    "oneTime": "תשלום חד פעמי חדש",
    "uploadExplain": "הורד את התבנית ולאחר הזנת הפרטים ניתן להעלות את הקובץ",
    "downloadFile": "הורד קובץ",
    "uploadFile": "העלאת קובץ",
    "freezeOneTime": "הקפאת תשלום",
    "unfreezeOneTime": "פתיחת תשלום",
    "addNew": "פתיחת תשלום חד פעמי חדש",
    "installments": "מספר תשלומים מקסימלי",
    "note": "הערה",
    "createPayment": "צור\/י תשלום",
    "oneTimeSuccess": "נוצר בהצלחה {{payments}} תשלומים. העמוד יתרענן מיד",
    "oneTimeError": "לא נוצר תשלום",
    "dragOrClickToUpload": "גרור\/י קובץ קבלה לתיעוד תשלום או לחצ\/י כאן לבחירת קובץ",
    "recordOffline": "תיעוד תשלום עבור בניין",
    "amountHeader": "סכום",
    "method": "תשלום באמצעות",
    "totalPayments": "{{num}} תשלומים בסכום {{amount}} ש״ח עבור דירה {{apartmentNum}}",
    "dismiss": "ביטול",
    "notifications": {
        "uploadFailure": "שגיאה בטעינת תשלום מקבלה",
        "approveSuccess": "תשלומים תועדו בהצלחה"
    },
    "paidMonths": "רשימות תשלומים בפועל מהקבלה",
    "openedMonths": "רשימות תשלומים פתוחים בבלינק",
    "overpay": "לא קיימים תשלומים פתוחים לדירה זו, או הקבלה מתעדת תשלום גדול משפתוח במערכת בלינק. לא ניתן לתעד תשלום.",
    "noOpenedMonths": "There is no relevant opened payments for this apartment",
    "paymentState": "סטאטוס תשלומים",
    "active": "פעיל",
    "inactive": "מוקפא",
    "noAmountToUpdate": "לא הוגדר סכום אחד לעדכון",
    "updatedMonthlySuccessfully": "התשלומים עודכנו בהצלחה",
    "uploadedMonthlySuccessfully": "התשלומים עודכנו בהצלחה עבור שנים {{year}} וקדימה",
    "buildingFeesSuccessfully": "תעריפי הבניין עודכנו בהצלחה , אם קיימות תוכניות פעילות הם יעודכנו גם כן. אנא וודא בדו״ח השנתי.",
    "uploadedOneTimeSuccessfully": "תשלום חד פעמי {{name}} התווסף בהצלחה.",
    "uploadedMonthlyNoUpdatedRows": "לא עודכנו תשלומים עבור הקובץ הנבחר ",
    "uploadedMonthlyError": "שגיאה בהעלאת קובץ נתונים. אנא שלח\/י את הקובץ ל - support@bllink.co ",
    "uploadHere": "גרור\/י את הקובץ להעלאת הפרטים",
    "name": "שם התשלום",
    "amount": "סכום",
    "month": "חודש",
    "year": "שנה",
    "letsStartSettingsPayments": "בואו נתחיל בהגדרות תשלומים",
    "fillDetailsToCollectPrize": "מלא פרטים כדי לאסוף את הפרס",
    "monthSelectionInfoPopUpText": "היי <tenantName><br>ניתן לבחור/לשנות את החודשים לתשלום ע״י לחיצה על החודש ועדכון הסימון לתשלום<br><pay> <heartImg> - התשלום בבלינק אינו תופס מסגרת אשראי.",
    "monthSelectionInfoPopUpText_pay": "שימו",
    "dontShowThisMessageAgain": "אל תציג את ההודעה הזו שוב",
    "updateMonthlyRates": "עדכון תעריפים חודשיים",
    "fixedAmount": "סכום קבוע",
    "variableAmount": "סכום משתנה",
    "updateFixedAmount": "עדכון תעריפים חודשיים קבועים לכל הדירות. ניתן לעדכן את הסכום או לעדכן תוספת לסכום קיים בטווח תאריכים נבחר",
    "amountUpdate": "סכום לעדכון",
    "addToExistingRate": "הוספת הסכום לתעריף הקיים",
    "startingFrom": "החל מ",
    "upTo": "עד ל",
    "update": "עדכון",
    "updateFlexibleAmount": "עדכון תעריפים חודשיים משתנים לכל הדירות. ניתן לעדכן את הסכום או לעדכן תוספת לסכום קיים בטווח תאריכים נבחר",
    "excelUpdate": "עדכון מאקסל",
    "manualUpdate": "עדכון ידני",
    "dragAndDrop": "לחץ כאן או גרור את קובץ האקסל",
    "exampleExcel": "אקסל לדוגמא",
    "provideDate": "אנא ספק טווח תאריכים",
    "negativeAmount": "לא ניתן להכניס סכום שלילי",
};
