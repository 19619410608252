export const partnerPaymentSettings = {
    title: 'Welcome to Blink {{partnerName}}',
    paymentMethodSelector: {
        title: 'Payment method details',
        noPaymentMethod: 'No payment method',
        addMethod: 'Add a payment method'
    },
    autoCampaignSettings: {
        title: 'Auto Campaign Settings',
        autoCampaignDays: {
            title: 'Auto Campaign Days selection',
            daysInWeek: 'Select Days in A Week',
            daysInMonth: 'Select Days in A Month',
            daysInMonthInstructions: 'To select a single day you have to double click. For a range of days a single click.',
            setDays: 'Set Days',
            firstWeekOnly: 'Set First Week Only',
            lastWeekOnly: 'Set Last Week Only',
            successMessage: 'Days have been set successfully',
            errorMessage: 'Error in setting days'
        },
        autoCampaignText: {
            title: 'Auto Campaign Message Editor',
            successMessage: 'Text has been set successfully',
            errorMessage: 'Error in setting text',
            bllinkText: 'Bllink Text',
            customizedText: 'Customized Text',
            setText: 'Set Text',
            isActive: 'This text is set currently',
            messageOptions: {
                message_welcomeMessage: 'Welcome Message',
                message_firstReminderMessage: 'First Message',
                message_secondReminderMessage: 'Second Message',
                message_thirdReminderMessageRenter: 'Third Message - Renter',
                message_thirdReminderMessageOwner: 'Third Message - Owner',
                message_monitoringOnetimeDebt4Phone: 'One-time Debt Reminder - 4',
                message_monitoringOnetimeDebt3Phone: 'One-time Debt Reminder - 3',
                message_monitoringOnetimeDebt2Phone: 'One-time Debt Reminder - 2',
                message_monitoringOnetimeDebt1Phone: 'One-time Debt Reminder - 1',
                message_monitoringDebt4Phone: 'Debt Reminder - 4',
                message_monitoringDebt3Phone: 'Debt Reminder - 3',
                message_monitoringDebt2Phone: 'Debt Reminder - 2',
                message_monitoringDebt1Phone: 'Debt Reminder - 1'
            },
            buttons: {
                tenantName: "Tenant Name",
                buildingAddress: "Building Address",
                buildingManagerName: "Building Manager Name",
                paymentLink: "Payment Link",
                bllinkPhone: "Apartment Number",
                apartmentNumber: "Payment Link",
                ownerName: "Owner Name",
                personName: "Person Name"
            }
        },
        autoCampaignPaymentFor: {
            title: 'Auto Campaign Payment For',
            successMessage: 'Payments Settings have been set successfully',
            errorMessage: 'Error in setting Payments',
            allowAll: 'Allow All',
            allowSelected: 'Allow Selected',
            continue: 'continue'
        },
    },
    paymentMethodModal: {
        title: 'Choosing the payment method',
        description: 'The payment for the system additions can be made by credit card or by offset from the planned transfer to the account',
        creditCard: 'Credit Card',
        fromTransfer: 'Offset from transfer',
    },
    systemFeatures: {
        title: 'System add-ons',
        completeSystem:  {
            label: 'Complete system',
            description: 'NIS 25 per building for a complete system with no limitation of features',
        },
        greenInvoice:  {
            label: 'Green invoice',
            description: 'Monthly payment according to engagement agreement, without limitation of buildings or documents',
        },
        maintenance:  {
            label: 'Maintenance',
            description: 'Monthly payment according to engagement agreement',
        },
        checkScanner:  {
            label: 'Check scanner',
            description: 'Monthly payment according to engagement agreement',
        },
        autoCampaignSettings:  {
            label: 'Auto Campaign Manual Day Selection',
            description: 'Allow Manually selection of the days to send messages via auto campaign',
        },
        active: 'active',
        disabled: 'off',
        actions: {
            add: 'addition'
        }
    },
    notifications: {
        paymentMethodEstablished: 'New payment method established'
    }
};
