import Flash from "../../images/newDesign/flash.svg";

export const storeMock = [
    {
        icon: Flash,
        title: 'שירות חשמלאי',
        description: 'יוסי הצבעי - החברה המובילה בענף הצבע. במבצע חסר תקדים - 500 ש״ח לצביעת חדר כולל תיקונים וצבע של תמבור.' +
            'עבודה מקצועית ונקייה + המלצות חמות.',
        id: 1,
    },
    {
        icon: Flash,
        title: 'שירות צבעי',
        description: 'יוסי הצבעי - החברה המובילה בענף הצבע. במבצע חסר תקדים - 500 ש״ח לצביעת חדר כולל תיקונים וצבע של תמבור.' +
            'עבודה מקצועית ונקייה + המלצות חמות.',
        id: 2,
    },
    {
        icon: Flash,
        title: 'שירות אינסטלציה',
        description: 'יוסי הצבעי - החברה המובילה בענף הצבע. במבצע חסר תקדים - 500 ש״ח לצביעת חדר כולל תיקונים וצבע של תמבור.' +
            'עבודה מקצועית ונקייה + המלצות חמות.',
        id: 3,
    }
]