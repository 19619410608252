import { createSlice } from '@reduxjs/toolkit';
import CacheData from "../../common/utils/cacheData";
import {constants} from "../../common/constants/constants";

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        loader: 0,
        fullScreen: false,
        // https://bllink.atlassian.net/browse/SSB-1801  Changing the PIN logic for side bar
        sideBarCollapsed: false,
        mobileMenuOpened: false,
        ltrEnabled: false,
        sideBarPinned: true,
        sideMenuOpened: true,
        blockSidebarCollapsing: false,
        sidebarScrollTop: 0,
        hideListItems: false,
    },
    reducers: {
        hideLoader: (state) => {
            state.loader--;
            if (state.loader < 0) {
                state.loader = 0;
                console.error('state.loader < 0, check showLoader/hideLoader pairs');
            }

        },
        showLoader: (state) => {
            state.loader++;
        },
        makeFullScreen: state => {
            state.fullScreen = true;
        },
        removeFullScreen: state => {
            state.fullScreen = false;
        },
        toggleSidebarCollapsed: state => {
            state.sideBarCollapsed = !state.sideBarCollapsed;
        },
        setSidebarCollapsed: (state, collapsed) => {
            state.sideBarCollapsed = collapsed.payload;
        },
        toggleSidebarPinned: state => {
            state.sideBarPinned = !state.sideBarPinned
            CacheData.cache(constants.cache.keys.general.sideBarPinned, state.sideBarPinned);
        },
        setSidebarPinned: (state, sidebarPinned) => {
            state.sideBarPinned = sidebarPinned.payload
        },
        setBlockSidebarCollapsing: (state, isBlocked) => {
            state.blockSidebarCollapsing = isBlocked.payload;
        },
        setIsSideMenuOpened: (state, isOpened) => {
            state.sideMenuOpened = isOpened.payload;
        },
        toggleMobileMenuOpened: state => {
            state.mobileMenuOpened = !state.mobileMenuOpened;
        },
        setLtr: (state, action) => {
            state.ltrEnabled = action.payload;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        },
        setSidebarScrollTop: (state, action) => {
            state.sidebarScrollTop = action.payload;
        },
        setHideListItems: (state, action) => {
            state.hideListItems = action.payload;
        }
    },
});

export const {
    hideLoader,
    showLoader,
    makeFullScreen,
    removeFullScreen,
    toggleSidebarCollapsed,
    setSidebarCollapsed,
    setIsSideMenuOpened,
    toggleSidebarPinned,
    setSidebarPinned,
    setBlockSidebarCollapsing,
    toggleMobileMenuOpened,
    setLtr,
    setLanguage,
    setSidebarScrollTop,
    setHideListItems
} = commonSlice.actions;
export default commonSlice.reducer;
