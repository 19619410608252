export const offlinePayments = {
    "notSet": "לא נקבע",
    "credit_card": "כרטיס אשראי",
    "cheque_deposit": "סורק צ׳קים",
    "tenant_record": "דיווח דייר/ת",
    "bankCharge": "בלינק - בנק",
    "type_of_payment": "סוג תשלום",
    "pay_with_cheque_title": "תיעוד תשלום צ׳ק",
    "pay_with_cash_title": "תיעוד תשלום מזומן",
    "pay_with_wire_title": "תיעוד תשלום העברה בנקאית",
    "pay_with_tenant_record": "דיווח על תשלומים שבוצעו",
    "bank_code_title": "בנק",
    "bank_branch_title": "סניף",
    "bank_account_number_title": "מספר חשבון",
    "make_offline_payment_button": "תיעוד תשלום",
    "date_of_deposit": "תאריך הפקדה",
    "recurrent_bank_wires": "הוראת קבע בנקאית",
    "payment_date": "תאריך תשלום",
    "creation_date": "תאריך יצירה",
    "cheque_number": "מספר צ'ק",
    "cheque_number_placeholder": "הכנס\/י כמות של צ׳קים",
    "add_row_tooltip": "מלא\/י פרטים בשורה הראשונה ואנו נמלא אוטומטית את השורות הבאות",
    "auto_cheque_explanation1": "לאחר הגעה למסך זה, וודא\/י כי סורק הצ׳קים מחובר. ותוכנת הסורק (CI3) פועלת ומופיעה בירוק.",
    "auto_cheque_explanation2": "וודאי שסמן העכבר מופיע בתיבת ״בנק״.",
    "auto_cheque_explanation3": "ניתן להזין את כל הצ׳קים עבור הדירה בבת אחת בסורק הצ׳קים. לאחר כל סריקה יוצג חלון וידוא נתונים של סורק הצ׳קים.",
    "partial_pay": "תשלום חלקי",
    "payment": "לתשלום",
    "cancellation": "ביטול",
    "denyTenantRecord": "ביטול דיווח דייר/ת",
    "approveTenantRecord": "אישור דיווח דייר/ת",
    "partial_sum_must_be_smaller": "סכום חלקי חייב להיות קטן מסכום החודש או התשלום החד פעמי הנבחר {{fullAmount}}",
    "auto_cheque_explanation4": "המערכת תקליד את הנתונים על גבי טופס זה לאחר כל לחיצה על הוי הירוק בתוכנת סורק הצ׳יקים.",
    "auto_cheque_explanation5": "למעבר לצ׳ק הבא ניתן ללחוץ על לחצן capslock.",
    "auto_cheque_explanation6": "לאחר ״תיעוד תשלום״, זמן שידור צ׳ק לבנק הוא 3 ימי עסקים ממועד הסריקה אף אם הצ׳ק הוא לתאריך עבר",
    "cheque": "צ׳קים",
    "wire": "העברה בנקאית",
    "cash": "מזומן",
    "date": "תאריך",
    "confirmation": "אישור",
    "approve": "אישור",
    "tenant_record_modal_text": "אני מעוניינ/ת לדווח כי הפריטים המסומנים כבר שולמו. אישור הפעולה יפתח פנייה לבירור מול מנהל הבניין",
    "amount": "סכום",
    "reccurent_wire_tooltip": "סימון כאן יגדיר העברה חודשית חודש בחודשו",
    "tenant_offline_payment_success": "הודעה נשלחה בהצלחה לחברת הניהול",
    "tenant_offline_payment_failed": "לא ניתן לשלוח הודעה לחברת הניהול. נסה מאוחר יותר",
    "status": "סטאטוס תשלום"
};
