import Strings from './strings';
import Money from './money';
import BllinkTime from './time';
import JsExtras from './jsExtras';
import Filter from './filter';

/// todo take this code from a shared npm (duplicated in backend)
const BllinkUtils = {
    /*
      will contain various helper methods
     */
    BllinkTime,
    Strings,
    Money,
    JsExtras,
    Filter,
}

export default BllinkUtils;
