import {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {BllinkLogger} from './utils/bllink_loggers';

class ScrollToTop extends Component {

    componentDidUpdate(prevProps) {
        BllinkLogger.info(`checking if need to scroll up`)
        BllinkLogger.info(this.props.location)
        BllinkLogger.info(prevProps.location)
        if (this.props.location !== prevProps.location) {
            BllinkLogger.info(`scrolling up `)
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}



export default withRouter(ScrollToTop);
