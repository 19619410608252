export const titles = {
    "home": "Bllink | בלינק",
    "bllink": "בלינק",
    "page_description": "בלינק תשלומי ועד בית דיגיטלי אוטומטי",
    "building_profile": "עמוד בניין - בלינק | Bllink",
    "tenant_details": "פרטי הדייר - בלינק | Bllink",
    "payment_details": "פרטי התשלום - בלינק | Bllink",
    "payment_checkout": "סיכום תשלום - בלינק | Bllink",
    "make_payment": "ביצוע תשלום - בלינק | Bllink",
    "confirmation_page": "אישור תשלום - בלינק | Bllink",
    "bank_charge_guide": "הדרכת הקמה הרשאה לחיוב חשבון - בלינק | Bllink",
    "renewal_page": "חידוש תשלום - בלינק | Bllink",
    "failed_payments_page": "עדכון פרטי אשראי - בלינק | Bllink",
    "terms_of_service": "תנאי שימוש - בלינק | Bllink",
    "privacy": "פרטיות - בלינק | Bllink",
    "all_buildings": "הבניינים שלי - בלינק | Bllink",
    "dashboard": "ראשי - בלינק | Bllink",
    "yearly_report": "דוח שנתי - בלינק | Bllink",
    "monthly_report": "דוח חודשי - בלינק | Bllink",
    "tenant_list": "רשימת דיירים - בלינק | Bllink",
    "apartment_report": "דוח דירה - בלינק | Bllink",
    "cash_flow_report": "דוח תזרים שנתי - בלינק | Bllink",
    "search_building": "חיפוש כתובת - בלינק | Bllink",
    "expenses_record": "תיעוד הוצאות | Bllink",
    "monthly_expense_table": "דוח הוצאות חודשי - בלינק | Bllink",
    "loading": "טוען עמוד",
    "forms": {
        "mandatory_field": "זהו שדה חובה",
        "mandatory_field_full_name": "זהו שדה חובה עם אורך מינימאלי של 3 תווים",
        "mandatory_above_zero": "סכום הוצאה חייב להיות גבוה מ-0",
        "correct_email": "אופס... דוא\"ל לא תקין",
        "correct_account_bank_branch": "יש למלא את הבנק, הסניף והחשבון ביחד",
        "correct_phone": "אופס... המספר לא תקין, אנא ודא אורך של 10 ספרות בלבד",
        "subscribe_check": "אנא אשר תקנון האתר",
        "going_to_credit_card": "עובר לעמוד תשלום...",
        "number_field": "צריך להיות מספר",
        "invalid_date": "נא להזין תאריך חוקי",
        "vendor_tax_id": "אורך ח.פ ספק צריך לעלות על 6 תווים",
        "min_string_size": "אורך ח.פ ספק צריך לעלות על {{size}} תווים",
        "account_field": "בין 6 ל-9 ספרות",
        "correct_phone_for_vendor": "אופס... המספר לא תקין, אנא ודא אורך של 3 עד 10 ספרות בלבד",
        "max_length_exceeded": "אורך חרג מהמקסימום",
        "max_length": "חייב להיות לכל היותר {{size}} תווים",
        "min_length": "חייב להיות לפחות {{size}} תווים",
        "invalid_card_number": "מספר כרטיס שגוי",
        "invalid_cvv_code": "CVV לא חוקי",
        "value_too_big": "ערך גדול מדי (מקסימום {{max}})",
        "value_too_small": "ערך קטן מדי (דקה {{min}})",
    },
    "bank_adjustments": "התאמות בנק - בלינק | Bllink",
    "balance_report": "דו״ח מאזן - בלינק | Bllink",
    "cheque_report": "דוח צ׳קים להפקדה - בלינק | Bllink",
    "expenses_edit": "עריכת הוצאות | Bllink",
    "general_pop_up_title": "הודעת מערכת",
    "building_debt_list": "דו״ח חייבים לבניין | Bllink",
    "partner_debt_list": "דו״ח חייבים כללי | Bllink",
    "tenant_claims_paid": "Bllink | תביעות שוכרים שולמו",
    "coming_soon": "בקרוב",
};
