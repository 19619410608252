import styled from 'styled-components';

export const TableWrap = styled.div`
  & .editable-table {
    th {
      padding: 20px 10px;
    }

    td {
      padding: 10px;
    }

    th, td {
      width: auto;
      white-space: nowrap;
    }

    input {
      width: 100%;
      height: 35px;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 10px;

      &:focus {
        box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%) !important;
      }
    }

    .error-text {
      position: absolute;
      left: -10px;
      font-size: 12px;
      background: #fff;
      width: calc(100% + 20px);
      color: red;
    }

    .editable-cell.with-error {
      position: relative;

      input {
        border: 1px solid red;
      }
    }
  }

  &.one-row-table {
    #table {
      border-collapse: separate;
      border-spacing: 0;
      overflow: visible;

      th {
        border-top: 1px solid ${props => props.theme.borderTable};
        border-bottom: none;
        background: var(--bllink-purple);

        &:first-child {
          border-inline-start: 1px solid ${props => props.theme.borderTable};
          border-top-right-radius:  ${({ ltr }) => ltr ? '0' : '50px'};
          border-top-left-radius: ${({ ltr }) => ltr ? '50px' : '0'};
        }

        &:last-child {
          border-left: 1px solid ${props => props.theme.borderTable};
          border-top-right-radius:  ${({ ltr }) => ltr ? '50px' : '0'};
          border-top-left-radius: ${({ ltr }) => ltr ? '0' : '50px'};
        }
      }

      td {
        border-bottom: 1px solid ${props => props.theme.borderTable};

        &:first-child {
          border-inline-start: 1px solid ${props => props.theme.borderTable};
          border-bottom-right-radius: ${({ ltr }) => ltr ? '0' : '50px'};
          border-bottom-left-radius: ${({ ltr }) => ltr ? '50px' : '0'};
        }

        &:last-child {
          border-left: 1px solid ${props => props.theme.borderTable};
          border-bottom-right-radius: ${({ ltr }) => ltr ? '50px' : '0'};
          border-bottom-left-radius: ${({ ltr }) => ltr ? '0' : '50px'};
        }
      }
    }
  }

  &:not(.one-row-table) {
    border: 1px solid ${props => props.theme.borderTable};
    border-radius: 50px;
    overflow: hidden;

    #table thead {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 2;
      background: ${props => props.theme.main};
      transform: translate(0, 0, 0);
    }
  }

  &.with-title {
    .table-title {
      padding: 25px;
      background: ${props => props.theme.main};
      font-family: var(--bllink-font-headers);
      color: #fff;

      h3, h4 {
        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        font-size: 30px;
        line-height: 36px;
      }

      h3 {
        font-size: 23px;
        line-height: 30px;
      }
      .print-title {
        display: none;
      }
    }

    #table {
      thead {
        background: #fff;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 100%;
          background-color: ${props => props.theme.borderTable};
        }
      }

      th {
        font-size: 15px;
        color: ${props => props.theme.text};
      }

      tr:first-child td {
        border-top: none;
      }
    }
  }

  #table {
    max-height: 400px;
    border-collapse: separate;
    border-spacing: 0;

    th {
      font-size: 18px;
      color: #fff;
      font-weight: bold;
      border-top: none;
      padding: 20px;
    }

    td {
      padding: 10px 20px;
    }

    th, td {
      text-align: center;
      width: 1%;
      border-inline-end: 1px solid ${props => props.theme.borderTable};

      :last-child {
        border-left: 0;
      }

      &.description {
        word-break: break-all;
      }
    }

    tbody {
      tr {
        &:hover {
          background: rgba(168, 192, 255, 0.2);

          .bllink-button:not(:disabled) {
            opacity: 1;
          }
        }
      }

      .control-with-icon {
        padding-left: 70px;

        &::after {
          top: 0;
          left: 0;
        }

        img {
          z-index: 5;
        }
      }
    }

    .bllink-button {
      width: 190px;
      padding: 15px 25px;
      line-height: 23px;
      opacity: .5;
      float: none;
    }

    .cell-button {
      margin: 0 auto;
    }
  }

  .payment-select {
    max-width: 250px;
    margin: 0 auto;
  }

  &.new-report {
    border-radius: 30px;
    font-family: var(--bllink-font-primary);
    color: ${props => props.theme.textTable};
    .table-title {
      padding: 20px 35px;
      font-family: var(--bllink-font-primary);
      background: linear-gradient(90deg, #6895FF 1.74%, #8C5CFF 100.15%);

      h3, h4 {
        &:last-child {
          margin-bottom: 0;
        }
      }

      h4 {
        font-weight: bold;
        font-size: 25px;
        line-height: 1;
      }
    }
  }

  .red-text {
    color: var(--bllink-red);
  }

  .orange-text {
    color: #ffae00;
  }

  .light-green-text {
    color: #90EE90;
  }

  .green-text {
    color: #009900;
  }
  
  .number-cell {
    direction: ltr;
    border-inline-start: 1px solid #A8C0FF;
    border-inline-end: none !important;
  }
`;

export const TableContainer = styled.div`
  .pagination {
    padding: 20px 0;
    align-items: center;

    strong {
      color: ${props => props.theme.main};
    }

    button {
      padding: 5px 10px;
      background-color: #fff;
      border: 1px solid ${props => props.theme.borderTable};
      border-radius: 5px;
    }
  }
`;
