export const messagePlatform = {
    "btns": {
        "createNewMessage": "Create New Message",
        "sendMessage": "Send Message",
        "privateName": "Private Name",
        "buildingAddress": "Building Address",
        "apartmentNumber": "Apartment Number",
        "paymentLink": "Payment Link",
        "sms": "SMS",
        "email": "Email",
        "chooseTenants": "Choose Tenants",
        "owners": "Owners",
        "renters": "Renters"
    },
    "labels": {
        "smsMessageContent": "SMS Content",
        "emailMessageContent": "Email Content",
        "chooseMethod": "Choose Message Method",
        "senderName": "Sender Name",
        "remainingMessages": "Monthly message balance",
        "personalizedContent": "Personalized content",
        "contact": "Contacts to send",
        "apartment": "Apartment",
        "selection": "Selection",
        "selectAll": "Select All",
        "quotaFinished": "The message quota is over, Contact a representative for renewal",
        "purchasing_package_of_messages": "Blink - purchasing a package of messages",
        "chooseOne": "You must select at least one tenant",
    },
    "titles": {
        "messagePlatform": "Message Platform",
        "sendMessage": "Send a Message",
    },
    "messages": {
        "successSending": "successfully sent messages to {{numberOfTenants}} tenants"
    }
};
