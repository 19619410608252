export const expenses = {
    "name_of_supplier": "שם ספק ",
    "payment_frequency": "תדירות תשלום ",
    "expense_record_table_title": "תיעוד הוצאה",
    "expense_record_table_type": "סוג הוצאה",
    "expense_record_table_index": "#",
    "expense_record_table_description": "תיאור הוצאה",
    "expense_record_table_invoice": "מספר חשבונית עסקה",
    "expense_record_table_upload": "העלאת חשבונית",
    "expense_record_table_period": "תאריך הוצאה",
    "expense_record_table_to": "מ",
    "expense_record_table_from": "עד",
    "description_placeholder": "הזנת תיאור פריט",
    "expense_record_table_amount": "סכום ב-₪",
    "expense_record_table_actions": "",
    "record_expense": "תעד הוצאה",
    "add_line": "הוסף שורה",
    "email": "דוא\"ל",
    "comment": "הערה",
    "total_amount": "סה״כ הוצאות חודשיות",
    "added_successfully": "הוצאה התווספה בהצלחה",
    "new_supplier": "הוספת ספק חדש",
    "phone_number": "מספר טלפון",
    "name": "שם",
    "vat_number": "ח.פ ספק",
    "vat_number_link_text": "ניתן למצוא ח.פ כאן",
    "add_vendor": "הוספ\/י ספק חדש",
    "expense_monthly_report": "דו״ח הוצאות חודשי - פירוט הוצאות שבוצעו בחודש {{monthName}} {{year}} - סה״כ {{amount}} {{currency}}",
    "one_time": "חד פעמי",
    "oneTime": "חד פעמי",
    "recurring": "תשלום קבוע",
    "expense_type": "סוג הוצאה",
    "expense_status": "סטאטוס הוצאה",
    "expenseTypes": {
        "oneTime": "תקבולים גבייה מיוחדת",
        "ongoing": " גבייה לשנת {{year}}",
        "ongoingRange": "תקבולים לתקופה נבחרת",
        "pastRangeDebts": "תקבולים לתקופת עבר",
        "pastYearDebts": "שנים קודמות",
        "futureRangeResult": "תקבולים לתקופה עתידית",
        "gardening": "גינון",
        "plumbing": "צנרת\/ביוב",
        "electricity": "חשמל",
        "elevators": "מעליות",
        "lighting": "תאורה",
        "managementCompany": "חברת ניהול",
        "intercom": "אינטרקום",
        "electricalGate": "שער חשמלי",
        "parkingLot": "חניון",
        "depretiation": "פחת",
        "fireSystem": "מערכת כיבוי אש",
        "garbage": "אשפה",
        "other": "אחר",
        "airCondition": "מיזוג אוויר",
        "cleaning": "ניקיון",
        "pestControl": "הדברה",
        "insurance": "ביטוח",
        "waterReserve": "מאגרי מים",
        "internet": "תשתיות אינטרנט",
        "generatorMaintenance": "תחזוקת גנרטור",
        "creditCommissions": "עמלות אשראי",
        "bankCommissions": "עמלות בנקים",
        "parkingElectricity": "חשמל חניון",
        "publicElectricity": "חשמל ציבורי",
        "agudaFee": "אגודה לתרבות הדיור",
        "dailerFire": "חייגן גילוי אש - קווי טלפון",
        "buildingSavings": "קופה קטנה",
        "solarSystems": "מערכות סולאריות",
        "doorsMaintenance": "אחזקה שוטפת - דלתות",
        "electricityMaintenance": "אחזקה שוטפת - חשמל ציבורי",
        "lobbyMaintenance": "אחזקה שוטפת - לובי",
        "maintenance": "תחזוקה",
    },
    "vendor_name_is_required": "שם ספק נדרש",
    "approve": "אשר\/י",
    "continue_and_approve": "המשך\/י לאישור",
    "date": "תאריך",
    "actual_date": "תאריך הפקדה",
    "select_payment_type": "בחר\/י סוג תשלום",
    "income_modal_title": "אני מאשר\/ת שהרשומה בבלינק תואמת לבנק",
    "deposit_date": "תאריך הפקדה",
    "actual_deposit_date": "תאריך הפקדה",
    "bank": "בנק",
    "branch": "סניף",
    "account": "מספר חשבון",
    "cheque_num": "מספר צ׳ק",
    "wire": "העברה בנקאית",
    "cheque": "צ׳ק",
    "cash": "מזומן",
    "type": "סוג תשלום",
    "description": "תיאור",
    "approved": "מאושר",
    "pending": "לא מאושר",
    "all": "הכל",
    "bank_adjustments_title": "התאמות בנקים",
    "expense": "הוצאה",
    "income": "הכנסה",
    "expenses": "הוצאות",
    "approveSuccess": "עסקה אושרה בהצלחה",
    "upload_doc": "העלה\/י קובץ",
    "upload_success": "קבלה הועלתה",
    "file_size_error": "תמונה או מסמך גדולים מידי. אנא בחר/י גודל קטן מ-2 מגה בייט",
    "disabled_pending": "אין צורך לאשר ידנית עסקה זאת. היא תאושר אוטומטית ע״י בלינק",
    "vendorDetails": "Vendor details",
    "bankDetails": "Bank details",
    "commission": "Service commission",
    "vendorCommissionLabel": "Set commission with offsetting form Vendor",
    "bankCommissionLabel": "Set commission with offsetting form bank account",
    "paymentDetails": "Payment details - Total amount {{total}}ILS",
    "expense_future_monthly_report": "דו״ח הוצאות עתידיות חודשי - פירוט הוצאות שיבוצעו בחודש {{monthName}} {{year}} - סה״כ {{amount}} {{currency}}",
    "vendor_created": "ספק חדש נוצר בהצלחה",
    "choose_vendor": "בחר\/י ספק",
    "expense_record_tooltip": "לחץ\/י כאן לתיעוד הוצאות ספקים",
    "bank_adjustments_tooltip": "לחץ\/י כאן להתאמות בנק",
    "no_vendor_has_been_selected": "לא ניתן לתעד הוצאה ללא בחירת ספק. אנא בחר\/י ספק",
    "payment_method": "אמצעי תשלום",
    "bank_comission": "קיימת עלות הקמה בסך 15 ש״ח עבור הבנק",
    "bank_adjustments": {
        "title": "התאמות בנק",
        "file": "קובץ",
        "to": "תשלום עבור",
        "from": "גורם מבצע",
        "apt_num": "דירה {{apt}}",
        "cheque_number": "מספר צ׳ק",
        "wire_number": "מספר אישור העברה",
        "bank_number": "אישור העברה",
        "cash_number": "אישור הפקדה",
        "document_number": "קוד מסמך",
        "bllink_records": "נתוני בלינק",
        "bank_records": "נתוני הבנק",
        "drag_and_drop": "גרור/י קובץ אקסל מהבנק",
        "no_files": "לא הועלו קבצים בעבר",
        "reconciliation_header": "בחר/י עסקה להתאמת בנק",
        "start_auto_reconciliation": "התחל/י התאמה אוטומטית",
        "manual_reconciliation": "התאמה ידנית",
        "auto_match_title": "התאמה אוטומטית - בחינת תוצאות",
        "choose_pairs": "בחירת צמדים להתאמה",
        "approve_all": "אשר/י כל הפריטים המסומנים",
        "items_have_same_amount": "בחרי/ פריטים עם אותו הסכום",
        "press_approve": "לחצ/י אישור לביצוע התאמה",
        "upload_failure": "לא ניתן להעלות קובץ",
        "load_failure": "לא נטען לטעון קובץ",
        "bytes": "בייטים",
        "balance": "מאזן",
        "description": "תיאור",
        "credit": "זכות",
        "debit": "חובה",
        "bllink_tooltip": "כאן נמצאות עסקאות הרשומות בבלינק.",
        "bank_tooltip": "כאן נמצאות עסקאות הבנק אשר הועלו מקובץ האקסל.",
        "no_receipt": "לא הועלת קבלה עדיין",
        "no_matches": "לא נמצאו התאמות",
        "no_records": "לא קיימות רשומות",
        "manual_error": "אנא בחר/י את העסקה עם אותו סכום",
        "approved_transaction": "עסקה {{id}} אושרה בהצלחה ברשומות בלינק",
        "not_approved_transaction": "עסקה {{id}} לא אושר",
        "clear_records": "נקה רישומי בנק",
        "offline_only": "תשלומים לא מקוונים בלבד",
    },
    "income_type": "סוג הכנסה",
    "total_needed": "מתוכנן לגבייה",
    "total_needed_expenses": "מתוכנן לתשלום",
    "expenses_type": "סוג הוצאה",
    "total_paid": "סה״כ שולם",
    "show_reconsiled": "לאחר התאמת בנק בלבד",
    "total_balance": "יתרת סגירה מאזן",
    "count_balance": "סכום פתיחה + סך הכל הכנסות - סך הכל הוצאות",
    "set_expenses": {
        "confirmation_title": "אישור הוצאה",
        "title_add_expense": "הוספת הוצאה לבניין",
        "manage_expenses": "ניהול הוצאות הבניין",
        "connect_bank_account": "מחברים את חשבון הבנק של הבניין לתשלום לספקים. אחר כך בוחרים בבלינק כאמצעי התשלום והכסף מועבר ישירות לחשבון הבנק של הספק. בלי מאמץ.",
        "add_expense": "הוסף הוצאה",
        "online_interface": "ניהול כל ההוצאות של הבניין בממשק אונליין",
        "use_bllink_digital_payments": "השתמש/י בתשלום דיגיטלי של בלינק להעברת כספים לספקים של הבניין. או תעד/י תשלומים שבוצעו באמצעות צ׳קים, העברה בנקאית, מזומן.",
        "establish_expense": "מקימים הוצאה חדשה לבניין",
        "for_payments": "עבור תשלום שצריך לבצע לספק (חברת חשמל, גינון, מעליותֿ...)",
        "add_means_of_payment": "ממשיכים להוספת אמצעי תשלום",
        "skip_saving": "או מדלגים לשמירה בדו״ח הוצאות ומזינים אמצעי תשלום בהמשך",
        "payment_is_recorded": "התשלום תועד בדו״ח ההוצאות של הבניין",
        "enters_icome_balance": "ונכנס למאזן הכנסות הוצאות",
        "add_expense_for": "הקמת הוצאה חדשה לבניין",
        "search_vendor": "חפש או הוסף ספק",
        "choose_type": "בחר סוג הוצאה",
        "save_and_add_method": "שמור והמשך להזנת אמצעי תשלום",
        "num_of_months": "מספר חודשים",
        "upload_invoice_file": "העלאת קבלה/קובץ",
        "select_date": "בחר תאריך",
        "save_and_close": "שמור וסגור",
        "set_reccurent_payment": "אתה משלם <amount> לתקופה של <monthsNum> חודשים  עבור <vendorName>",
        "continue": "המשך",
        "pay_with_bllink": "תשלום בבלינק",
        "easy_payment": "ללא מאמץ",
        "first_step": "הקמת הוצאה",
        "second_step": "הוספת אמצעי תשלום",
        "third_step": "אישור הוצאה",
        "add_payment_method": "הוספת אמצעי תשלום",
        "free": "ללא מאמץ",
        "set_one_time_payment": "אתה משלם <currency><amount> עבור <vendorName>",
        "pay_with_bllink_or_record_offline": "תרצו לשלם בבלינק או לתעד אמצעי תשלום?",
        "record_wire_payment": "תיעוד העברה בנקאית",
        "record_cheque_payment": "תיעוד תשלום בצ׳ק",
        "record_cash_payment": "תיעוד תשלום במזומן",
        "cheque_payment": "תיעוד תשלום בצ׳ק",
        "cash_payment": "תיעוד תשלום במזומן",
        "wire_payment": "תיעוד העברה בנקאית",
        "wire_confirmation_num": "מספר אסמכתא",
        "amount_out_of_range": " שימו 💜, הסכום לתשלום בביט הינו <max>₪ - <min>₪. יש לבחור אמצעי תשלום אחר",
        "select_months_placeholder": "מספר חודשים לתשלום",
        "setExpenseSuccess": "הוצאה נוצרה בהצלחה. יש!",
        "positiveNumberOnly": "סכום גדול מאפס בלבד",
        "continueToNewCard": "המשך לכרטיס חדש",
    },
    "balance_report": {
        "fromDate": "מתאריך",
        "toDate": "עד תאריך",
    },
    "from": "החל מ",
    "to": "עד ל",
    "status": "סטאטוס",
    "payment_type": "אמצעי תשלום",
    "register": {
        "title": "עדיין משלמים לספק בצ׳ק? הופכים את התשלום לפשוט! חברו עכשיו את חשבון הבנק של הבניין",
        "registerHere": "חיבור חשבון בנק",
    },
    "extract_yearly_planning": "שלח תכנון הוצאות שנתי",
    "download_yearly_planning": "הורד תכנון הוצאות שנתי",
    "expenses_yearly_planning": "תכנון הוצאות שנתיות",
    "expenses_yearly_planning_title": "תכנון הוצאות שנתי {{building}} - סה״כ {{amount}}",
    "send_unpaid": "שלח הוצאות שלא שולמו",
    "unpaid_expenses": "הוצאות שלא שולמו",
    "unpaid_expenses_title": "הוצאות שלא שולמו {{building}} - סה״כ {{amount}}",
    "no_unpaid_expenses": "לא נמצאו הוצאות שלא שולמו לשלוח",
};
