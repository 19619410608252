import $ from 'jquery'
import { SETTINGS } from '../../common/settings'
//initialized in amplify.js
import { Auth } from 'aws-amplify';
import {BllinkLogger} from "../../common/utils/bllink_loggers";
import CacheData from "../../common/utils/cacheData";
import {GAEvent} from "../../common/google_analytics/google_analytics";
import { constants } from "../../common/constants/constants";
import { i18n } from "../../common/translations";
import Loader from '../../common/utils/loader';
import ReactGA from "react-ga4";


export default class loginLogic{

    static bindSocialClick(){

        $('.js-social-login').click((e)=>{
            e.preventDefault();

            const socialName = $(e.target).attr('social-name'); //Google or Facebook
            if (!socialName){
                BllinkLogger.error(`socialName is not set for element pressed`, e)
                BllinkLogger.error(`socialName is not set for element pressed`, e.target)
                return
            }
            loginLogic.redirectToSocial(socialName);
        })
    }

    /**
     * todo this should be called when the user enters their phone number
     * @param phoneNumber
     * @returns {Promise<void>}
     */
    static async updatePhoneNumberToUser(phoneNumber){
        // validate using getPhoneNumberWithCountryExtension
        // let user = await Auth.currentAuthenticatedUser();
        // let result = await Auth.updateUserAttributes(user, {
        //     "phone_number": '+972526019037',
        // });
        // return result
        // todo add try catch here and notify the user

    }

    static redirectToSocial(provider){

        if (!provider){
            GAEvent(constants.GoogleAnalytics.Events.login, 'unknownProvider');
            throw new Error(`unknown provider`)
        }

        Loader.startLoader();
        const s = SETTINGS.aws.AwsConfig.Auth;
        let domain=s.oauth.domain;
        let clientId=s.userPoolWebClientId;
        let type='token'
        let callback = s.oauth.redirectSignIn
        let scope="phone email profile openid aws.cognito.signin.user.admin"
        let verification=123  // todo, random this number

        let url = 'https://' + domain + '/authorize?identity_provider=' + provider + '&response_type=' + type + '&client_id=' + clientId + '&redirect_uri=' + callback + '&state=' + verification + '&scope=' + scope
        BllinkLogger.info(`redirect to ${url}`);
        GAEvent(constants.GoogleAnalytics.Events.login, 'redirectToSocial', 1, provider);
        window.location.href = url;

    }

    static bindLoginButtonClicked(){
        $('#login-account').click(async (e)=>{
            e.preventDefault();

            Loader.startLoader();
            this.hideErrors();
            const userName = $('#login-email').val();
            const password = $('#login-password').val();

            try {

                const user = await Auth.signIn(userName, password);
                ReactGA.set({
                    userId: user.attributes?.email
                });

                BllinkLogger.info(`logged in user is `, user);
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED'){
                    GAEvent(constants.GoogleAnalytics.Events.login, constants.GoogleAnalytics.Events.login, 1, 'loginSuccessResetPassword')
                    CacheData.cache('userResetPassword', {user: userName, password: password}, {typeOfCache: sessionStorage});
                    window.location.href = `/${constants.pages.firstSigninUrl}/`
                }else {
                    GAEvent(constants.GoogleAnalytics.Events.login, constants.GoogleAnalytics.Events.login, 1, 'loginSuccessUserNamePassword')
                    window.location.href = this.getRedirectUrl();
                }
            } catch (error) {
                BllinkLogger.info('error signing in', error);
                GAEvent(constants.GoogleAnalytics.Events.login, constants.GoogleAnalytics.Events.login, 1, 'errorUserNamePassword');
                // todo present specific error by error from cognito
                this.showError('login.login_generic_error');
            } finally {
                Loader.endLoader();
            }
        })
    }

    static getRedirectUrl(){
        let lastPage = CacheData.fetchCache('lastPage');
        return lastPage || `/${constants.pages.allBuildingsUrl}`;
    }

    static hideErrors(){
        $('#login-error').parent('.row').hide()
    }

    static showError(translationKey){
        $('#login-error').text(i18n.t(translationKey)).parent('.row').attr('hidden', false).show()
    }


    static bindForgotPassword(){
        $('#forget-pwd').click(e=>{
            e.preventDefault();

            const s = SETTINGS.aws.AwsConfig.Auth;
            let domain=s.oauth.domain;
            let clientId=s.userPoolWebClientId;
            let type='token';
            let callback = s.oauth.redirectSignIn;
            let scope="phone email profile openid aws.cognito.signin.user.admin";
            GAEvent(constants.GoogleAnalytics.Events.login, 'forgotPasswordClicked');

            window.location.href =  `https://${domain}/forgotPassword?client_id=${clientId}&response_type=${type}&scope=${scope}&redirect_uri=${callback}`
        })
    }

    static logLogoClicked(){
        $('#main-logo').click(e=> {
            GAEvent(constants.GoogleAnalytics.Events.clicks, 'BllinkLogoClicked', 1, 'mainLogo');
        })
    }

    static async resetInitialPasswordUrl(user, newPassword){
        // users will be redirected here if they were migrated from ptop and they need to reset their initial password

        try {
            BllinkLogger.info(`change new password for user ${newPassword}`, user);
            const response = await Auth.completeNewPassword(user, newPassword, {});
            GAEvent(constants.GoogleAnalytics.Events.login, 'changeInitialPasswordSuccess');
            BllinkLogger.info(`changed success new password for user`, response);

        }catch (e){
            BllinkLogger.error(`error resetInitialPasswordUrl `, e);
        }
    }


    static async bindResetInitialPassword(userCred){
        /*
            as part of the migration process, the user has to set her initial password for the first time only
         */

        const user = await Auth.signIn(userCred.user, userCred.password);
        $('#reset-password').click(async (e)=>{
            e.preventDefault();
            Loader.startLoader();
            this.hideErrors();
            const password = $('#login-password').val();
            const result = await this.resetInitialPasswordUrl(user, password);
            BllinkLogger.info(`reset result `, result);

            Loader.endLoader();

            // all buildings? or back to login
            window.location.href = `/${constants.pages.loginUrl}`
        })


    }

    static async bindPasswordTyping(){
        /*
           https://www.w3schools.com/howto/howto_js_password_validation.asp
         */
        let  myInput = document.getElementById("login-password");
        let  myInputValidation = document.getElementById("login-password-validation");


        // When the user clicks on the password field, show the message box
        myInput.onfocus = function() {
            document.getElementById("message").style.display = "block";
        }

        // When the user clicks outside of the password field, hide the message box
        // myInput.onblur = function() {
        //     document.getElementById("message").style.display = "none";
        // }


        // When the user starts to type something inside the password field
        myInput.onkeyup = this.validation
        myInputValidation.onkeyup = this.validation

    }


    static validation() {
        let  myInput = document.getElementById("login-password");
        let  letter = document.getElementById("letter");
        let  capital = document.getElementById("capital");
        let  number = document.getElementById("number");
        let  length = document.getElementById("length");
        let  passwordsTheSame = document.getElementById("passwords-are-the-same");
        const password = $('#login-password').val();
        const passwordValidation = $('#login-password-validation').val();

        // Validate lowercase letters
        let  lowerCaseLetters = /[a-z]/g;
        if(myInput.value.match(lowerCaseLetters)) {
            letter.classList.remove("invalid");
            letter.classList.add("valid");
        } else {
            letter.classList.remove("valid");
            letter.classList.add("invalid");
        }

        // Validate capital letters
        let  upperCaseLetters = /[A-Z]/g;
        if(myInput.value.match(upperCaseLetters)) {
            capital.classList.remove("invalid");
            capital.classList.add("valid");
        } else {
            capital.classList.remove("valid");
            capital.classList.add("invalid");
        }

        // Validate numbers
        let  numbers = /[0-9]/g;
        if(myInput.value.match(numbers)) {
            number.classList.remove("invalid");
            number.classList.add("valid");
        } else {
            number.classList.remove("valid");
            number.classList.add("invalid");
        }

        // Validate length
        if(myInput.value.length >= 8) {
            length.classList.remove("invalid");
            length.classList.add("valid");
        } else {
            length.classList.remove("valid");
            length.classList.add("invalid");
        }

        if(password === passwordValidation) {
            passwordsTheSame.classList.remove("invalid");
            passwordsTheSame.classList.add("valid");
        } else {
            passwordsTheSame.classList.remove("valid");
            passwordsTheSame.classList.add("invalid");
        }
    }



}
