import { constants } from "../constants/constants";
import SearchBuilding from "../../tenants/map/searchBuilding";
import HealthCheck from "../healthCheck";
import {lazyReload} from "../lazyReload";
const TermsOfService = lazyReload(() => import("../../common/static_pages/terms_of_service/termsOfService2"));
const Privacy = lazyReload(() => import("../../common/static_pages/privacy/privacy2"));
const ManualComponent = lazyReload(() => import("../../common/manual/manual"));
const TenantProfileModule =  lazyReload(() => import("../../tenants/profile/index"));
// const MaintenanceTenantIndex =  lazyReload(() => import("../../tenants/maintenance/index"));
const OnBoardingNewPrivateBuilding =  lazyReload(() => import("../../chat/onBoardingPrivateBuilding"));
const LegalConsultantBuildingManagers =  lazyReload(() => import("../../chat/LegalConsultantBuildingManagers"));

export const GeneralRoutes = [
    {path: `/`, component: SearchBuilding, exact: true},// * home page is search*/
    {path: `/${constants.pages.healthCheckUrl}`, component: HealthCheck, exact: true},
    {path: `/${constants.pages.termsOfServiceUrl}`, component: TermsOfService, exact: true}, // deprecated, used pdf from s3
    {path: `/${constants.pages.privacyUrl}`, component: Privacy, exact: true}, // deprecated, used pdf from s3
    {path: `/${constants.pages.bankChargeUrl}`, component: ManualComponent, exact: true, props: { mode: 'tenant' }},
    {path: `/${constants.pages.tenantProfile}`, component: TenantProfileModule, exact: false, newFlowFooter: true, removeHeader: true },
    // this is not working. The route is set on  src/components/tenants/profile/index.jsx
    // {path: `/${constants.pages.tenantProfile}/maintenance/:buildingID/:apartmentNumber/:pageName`, component: MaintenanceTenantIndex, exact: false, newFlowFooter: true, removeHeader: true },
    {path: `/${constants.pages.selfOnBoard}`, component: OnBoardingNewPrivateBuilding, exact: false, newFlowFooter: true, removeHeader: true },
    {path: `/${constants.pages.legalConsultant}`, component: LegalConsultantBuildingManagers, exact: false, newFlowFooter: true, removeHeader: true },
]


