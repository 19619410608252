import $ from 'jquery';
import CacheData from '../../common/utils/cacheData';
import {BllinkLogger} from '../utils/bllink_loggers';
import { constants } from "../constants/constants";
import BllinkUtils from "../utils/utils";

const m = BllinkUtils.Money;

export default class MonthsRelatedLogic{

    static calcTotalAmountSelected(monthsSelected, ignoreReward){
        let totalAmount = 0;
        BllinkLogger.info(`monthsSelected  are `, monthsSelected);
        for (const month of monthsSelected){
            if (month.selected){
                let amountSelected = this.calcAmountSelected(month, ignoreReward);
                const amount = m.roundNumber(parseFloat(amountSelected));
                totalAmount += amount;
            }
        }
        BllinkLogger.info(`total amount is ${m.roundNumber(totalAmount)}`)
        return m.roundNumber(totalAmount)

    }

    static calcTotalReward(months){
        // todo ignore reward if offline payment method
        let totalAmount = 0;

        for (const month of months){
            if (month.selected && month.reward_pay){
                let amountSelected = month.amount_left.value - month.reward_pay.value;
                BllinkLogger.info(`adding to reward ${amountSelected} for month `, month)
                const amount = m.roundNumber(parseFloat(amountSelected));
                totalAmount += amount;
            }
        }
        BllinkLogger.info(`total reward amount is ${m.roundNumber(totalAmount)}`)
        return m.roundNumber(totalAmount)
    }

    static calcAmountSelected(month, ignoreReward){
        let amountSelected = month.part_pay ? month.part_pay.value: month.amount_left.value;
        if (!ignoreReward){
            amountSelected = month.reward_pay ? month.reward_pay.value : amountSelected;
        }
        amountSelected = amountSelected || 0;

        return amountSelected
    }

    static calcMaxOnGoingInstallmentsAllowed(monthsSelected){
        /*
            we only allow number of installments as number of months selected
            for on going only
         */

        let count = 0;
        for (const month of monthsSelected){
            count += month.selected ? 1 : 0;
        }
        return count;

    }

    static calcRewardAmount(monthsSelected){
        let totalReward = 0
        for (const month of monthsSelected){
            totalReward += month.reward_pay ? month.amount_left.value -  month.reward_pay.value : 0;
        }
        BllinkLogger.info(`calcRewardAmount rewards`, monthsSelected)
        return totalReward
    }

    static handleOngoingMonthsSelected(months, itemNumberSelected){
        /*
            discussion with omri 1/10/20 -
            Each time a use selects a checkbox,
            it should auto select all the months from start to
            the selected month.
            The logic behind it is that the tenant has to pay
            for consecutive months only! can't have a month missing
        */

        let thisObj = this;
        $('#on-going-months .label_container input').each(function (index, _){

            let checkedValue = (index  <= itemNumberSelected);
            const isFirstMonthSelected = (itemNumberSelected === 0 && index === 0)
            if (isFirstMonthSelected) {
                months[index].selected = $(this).is(':checked');
                months[index] = thisObj.getFullMonthData(months, index, this);
                return
            }

            // 1. notate on DOM
            $(this).prop('checked', checkedValue);
            // 2. also reflect on data (to be saved on cache)
            months[index].selected = checkedValue;
            BllinkLogger.info(`index ${index} months[index]`, months[index])
            months[index] = thisObj.getFullMonthData(months, index, this);

        })

        return months

    }

    static getFullMonthData(months, index, thisElem){
        return {
            ...months[index],
            // will effect only if relevant
            ...MonthsRelatedLogic.getPartPayPayload(thisElem),
            ...MonthsRelatedLogic.getRewardPayPayload(thisElem)
        }
    }

    static getPartPayPayload(thisElement){
        const parentElement = $(thisElement).parents('.label_container');
        const partPay = $(parentElement).find('.part-amount');
        // getting react remove child error on this
        // const isPartPayFullAmount = $(parentElement).find('.js-full-amount').length > 0;
        const isPartPay = partPay.length > 0;

        if (isPartPay){
            return this.getInfo(partPay, 'part_pay');
        }
    }

    static getRewardPayPayload(thisElement){
        // https://app.asana.com/0/1196545425570329/1200307774346154 reward experiment
        const parentElement = $(thisElement).parents('.label_container');
        const rewardAmount = $(parentElement).find('.js-reward');
        const isRewardPay = rewardAmount.length > 0 && !rewardAmount.parent().is('del');

        if (isRewardPay){
            return this.getInfo(rewardAmount, 'reward_pay');
        }else{
            return {reward_pay: null}
        }
    }

    static getInfo(partPay, typeOfPayment){
        let partPayElement = $(partPay[0]);
        const currency = partPayElement.attr('currency');
        const value = parseFloat(partPayElement.attr('value'));
        const timeIndex = partPayElement.attr('time-index');

        let data = {
            currency: currency,
            value: value,
            monthIndex: timeIndex,
            // isFullAmount: isPartPayFullAmount
        }

        if (typeOfPayment === 'part_pay'){
            return {
                part_pay: data
            }
        }else if (typeOfPayment === 'reward_pay'){
            return {
                reward_pay: data
            }
        }
    }

    static alignDebtSelectedStatus(debtMonths){
         /*
             important that debtMonths will be the same length as
          */

        const lengthFfDataArray = debtMonths.length;
        let allInputs = $('#debt-months .label_container input');
        const lengthOfInputs = allInputs.length;
        if (lengthFfDataArray !== lengthOfInputs){
            const msg = `un aligned data array and html inputs ${lengthFfDataArray} vs ${lengthFfDataArray}`;
            BllinkLogger.error(msg);
            throw new Error(msg);
        }

        let monthsSelected = [];
        allInputs.each(function (index, _){
            const correspondingDebtMonth = MonthsRelatedLogic.buildDebtMonth(this, debtMonths, index);
            monthsSelected.push(correspondingDebtMonth);
        })

        BllinkLogger.info(`monthsSelected`, monthsSelected)
        return monthsSelected;

    }

    static buildDebtMonth(thisElement, debtMonths, index){
        const isChecked = $(thisElement).is(':checked');
        let correspondingDebtMonth = debtMonths[index]; // this index is bug prone

        // todo also save the number of installments selected
        correspondingDebtMonth.selected = isChecked;
        BllinkLogger.info(`correspondingDebtMonth before`, correspondingDebtMonth);
        correspondingDebtMonth = {
            ...correspondingDebtMonth,
            // will effect only if relevant
            ...MonthsRelatedLogic.getPartPayPayload(thisElement)
        }
        BllinkLogger.info(`correspondingDebtMonth after`, correspondingDebtMonth)

        return correspondingDebtMonth
    }

    static cacheCurrentSelectedMonths(months, typeOfPayments, buildingID, apartmentNumber){
        /*
            caching in order for the user to be able to go back end forth between
            debt/on going /one time
            + also save to for last confirmation page
         */

        BllinkLogger.info(`caching months `, months);
        const cacheCategory = MonthsRelatedLogic.getCacheCategory(typeOfPayments);
        CacheData.cache(
            cacheCategory,
            months,
            {buildingID: buildingID, apartmentNumber: apartmentNumber}
        )
    }


    static fetchSelectedMonthsFromCache(months, typeOfPayments, buildingID, apartmentNumber, rewards){
        /*
            this cache is used in last page before credit card to know which were selected
         */
        BllinkLogger.info('fetch initial ', months);
        BllinkLogger.info(`typeOfPayments ${typeOfPayments} buildingID ${buildingID} apartmentNumber ${apartmentNumber} with rewards`, rewards);
        if (!buildingID){
            throw new Error(`building not set for save tot cache`)
        }

        const cacheCategory = MonthsRelatedLogic.getCacheCategory(typeOfPayments);
        const cacheOptions = {buildingID: buildingID, apartmentNumber: apartmentNumber};
        let result = CacheData.fetchCache(cacheCategory, cacheOptions)
        if (!result){
            BllinkLogger.info('returned from raw data.');
            result = MonthsRelatedLogic.markAllAsSelected(months);
        }

        if (this.shouldClearCache(result, months)){
            CacheData.delete(cacheCategory, cacheOptions);
            result = MonthsRelatedLogic.markAllAsSelected(months);
        }

        // if has rewards +  more than 6 is marked then mark on the months payload reward
        if (rewards && rewards.length > 0){
            // for the first time only? added here for first render in case all
            result = MonthsRelatedLogic.ensureRewardIsMarked(rewards, result);
        }

        if (result.length > 0) {
            CacheData.cache(cacheCategory, result, cacheOptions);
        }

        return result
    }

    static ensureRewardIsMarked(rewards, months){
        if (rewards[0]) {
            BllinkLogger.info(`rewards [0]`, rewards[0]);
            let numberOfMonthsMarked = months.filter(m => {
                return m.selected
            }).length;
            if (numberOfMonthsMarked >= rewards[0].minMonths) {
                if (!months[0].reward_pay) {
                    let amountLeft = months[0].amount_left;
                    let rewardAmount = rewards[0].months[0].value
                    months[0].reward_pay = {value: amountLeft.value - rewardAmount, currency: amountLeft.currency, rewardAmount: rewardAmount}
                }

            }
        }

        return months
    }

    static shouldClearCache(monthsA, monthsB){

        if (monthsA.length !== monthsB.length) {
            BllinkLogger.info(`cache data does not match the months length passed. removing from cache and using raw.`)
            return true
        } else if (!this.sameAmounts(monthsA, monthsB)){
            BllinkLogger.info(`cache data does not match the months amounts passed. removing from cache and using raw.`)
            return true
        }else if (!this.sameMonths(monthsA, monthsB)){
            BllinkLogger.info(`not the same months. will delete cache`)
            return true
        }
    }

    static sameAmounts(monthsA, monthsB){
        /*

         */

        let result = true
        monthsA.forEach(function (month, index) {

            if (month.amount_left.value !== monthsB[index].amount_left.value){
                result = false
                return
            }

            if (month.total.value !== monthsB[index].total.value){
                result = false
                return
            }
        })

        return result
    }

    static addYearAndMonth(a, b){
        return a + ((parseInt(b.year) || 0) + (parseInt(b.month) || 0))
    }

    static sameMonths(monthsA, monthsB){
        /*
            due to a bug https://app.asana.com/0/1196545425570329/1199728424333605
            happens in a beginning of a month
         */
        // number of years
        let totalSumOfYearsA = monthsA.reduce(this.addYearAndMonth, 0);
        let totalSumOfYearsB = monthsB.reduce(this.addYearAndMonth, 0);

        if (totalSumOfYearsA === totalSumOfYearsB){
            BllinkLogger.info(`totalSumOfYearsA ${totalSumOfYearsA} equals totalSumOfYearsB ${totalSumOfYearsB}`)
            return true
        }

    }

    // static cacheInstallmentData(totalAmount, maxInstallments){
    //
    //     CacheData.cache('onGoingInstallmentsBox', {
    //         totalAmount: totalAmount,
    //         maxInstallments: maxInstallments
    //     })
    // }

    static getCacheCategory(typeOfPayments){

        return typeOfPayments === constants.cache.keys.payments.onGoingPayments ?
            'onGoingSelectedPayments' : 'debtSelectedPayments';

    }

    static markAllAsSelected(months){

        for (let month of months){
            month.selected = true;
        }

        return months
    }

}
