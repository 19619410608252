export const buildingBrochure = {
    "subtitle": "Our service",
    "fast_pay": "Fast payment",
    "fast_pay_1": "Fast payment via bllink",
    "fast_pay_2": "enables the building to accept funds",
    "fast_pay_3": "directly to the building's bank account",
    "fast_pay_4": "and smartly manage the payment process",
    "qr_title": "For fast pay scan the QR or reach out",
    "card": "Credit card",
    "invoice": "Invoice",
    "reminders": "Reminders",
    "pci": "Pci compliant",
    "fileName": "building_brochure",
    "phone": "For payment, scan the QR code",
    "wire": "Bank wire",
    "title": "Notice for building payments"
};
