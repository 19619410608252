export const searchPage = {
    "search_address": "חיפוש כתובת הבניין",
    "search_address_sub_title": "לביצוע התשלום, חפשו את הבניין שלכם ע\"י הזנת כתובת המגורים המדויקת",
    "full_address": "כתובת הבניין",
    "full_address_placeholder": "הזינו (רחוב, מספר בניין, עיר)",
    "search": "חיפוש",
    "quick_search": "חיפוש מהיר",
    "searching": "מחפש..",
    "entrance_number": "מספר הכניסה שלך",
    "building_not_found": "בניין לא נמצא",
};
