// Actions needed to get current building information.
// Was copied from parent.js to be used in functional components.
// When other components will have been refactored we can remove duplicated code from parent.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import ServerData from '../../common/serverData';

const fetchAllComments = createAsyncThunk(
    'comments/fetchAll',
    async (options, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});
            return client.get('managers/buildings/all/apartments/comments', options);
        } catch (e) {
            return rejectWithValue([]);
        }
    }
);

const fetchBuildingComments = createAsyncThunk(
    'comments/fetchBuildingComments',
    async ({buildingID, options}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});
            return client.get(`managers/buildings/${buildingID}/apartments/comments`, options);
        } catch (e) {
            return rejectWithValue([]);
        }
    }
);

export {
    fetchAllComments,
    fetchBuildingComments
};
