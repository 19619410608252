export const buttons ={
    "confirm": "אישור",
    "cancel": "ביטול",
    "approve": "אישור",
    "back": "חזרה",

    "notifications": {
        'vendorWasNotified': `ספק קיבל הודעה על התעניינות במודעה "{{title}}".`,
    },
}
