export const managerAutoCampaign = {
    "activeCampaign": {
        "pageTitle": "מעקב פניות לדיירים",
        "tabs": {
            "activeCampaign": "דירות בקמפיין פעיל",
            "outOfCampaign": "דירות ללא קמפיין"
        },
        "totalTenants": "סה״כ מספר דיירים:",
        "labels": {
            "campaignType": "סוג קמפיין",
            "campaignStep": "שלב בקמפיין",
            "nextStepIn": "שלב הבא בעוד",
            "nextStepType": "שם השלב הבא",
            "totalAmountMissing": "גובה תשלום חסר",
            "recommendedCampaign": "סוג קמפיין מומלץ",
            "days": "ימים",
            "of": "of"
        },
        "btns": {
            "stopCampaign": "עצור/י קמפיין",
            "startCampaign": "התחל/י קמפיין",
            "aptNotes": "הערות דירה"
        },
        "recommendedCampaign": "Bllink campaign",
        "successfully_added": "The apartment was successfully added to the campaign",
        "successfully_removed": "The apartment was successfully removed from the campaign"
    }
}
