export const months = {
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    // shortening sep for better ux
    "9": "Sep",
    "10": "October",
    "11": "November",
    "12": "December"
};
