export const archive = {
    page_title: "ארכיון מסמכים",
    upload_per_cent_done: "בוצעו",
    all_upload_done: "כל הקבצים הועלו",
    select_file_type: "בחר סוג קובץ...",
    go_back_to_list: "חזרה למסך ארכיון",
    upload_failed: "העלאת קובץ נכשלה",
    upload_failed_message: "העלאת קובץ בשם <fileName> נכשלה",
    upload_failed_message_retry: "יש לנסות להעלאות את הקובץ פעם נוספת",
    upload_hint: "גרור קובץ או לחץ להוספה",
    upload_limits: "PDF, XML, DOCX, JPEG, PNG, XLS, CSV מקסימום <maxFileSize>",
    show_deleted: "הצג קבצים שנמחקו",
    insurance_title: "מעוניינים לחסוך בביטוח?",
    insurance_advantages: "אנחנו יכולים לחסוך לבניין את עלויות הביטוח ועדיין לשמור על היקף הכיסויים",
    insurance_conditions: "לצורך קבלת הצעת מחיר אטרקטיבית לחידוש פוליסת ביטוח ועד הבית, הריני מאשר לחברת בלינק להעביר את מסמכי הפוליסה ופרטי ההתקשרות עמי אל גור קבוצת ביטוח ומאשר לנציגי גור קבוצת ביטוח ליצור עמי קשר בעניין.",
    insurance_disagree: "לא תודה, בואו נמשיך",
    insurance_confirm: "מאשר",
    delete_confirm: {
        title: "מחיקת קובץ מהארכיון",
        question: "רגע לפני שנמחק את הקובץ לגמרי... האם אתה בטוח שברצונך לבצע את הפעולה?",
    },
    file_actions: {
        delete: 'מחיקת קובץ',
        undelete: 'בטל מחיקה',
    },
    file_type: {
        none: "אחר",
        insurance: "ביטוח בניין",
        managementAgreement: "הסכם ניהול בניין",
        supplierAgreement: "הסכם עם ספק",
        offer: "הצעת מחיר",
        protocol: "פרוטוקול דיירים",
        bllink: "Bllink",
    },
    select_type: "אנא בחר סוג עבור הקובץ {{name}}"
};
