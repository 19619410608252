import styled from "styled-components";

export const StyledChatComponent = styled.div`
    width: 100%;
    // no impact
    //height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    max-width: none;
    .content {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        
        &.manager-wrapper {
            height: 75vh;
        }
    }
    
    

    .header {
        display: flex;
        align-items: center;
        height: 4.5rem;
        background-color: #009688;
        flex-shrink: 0;
    }

    @media only screen and (max-width: 768px) {
        .content {
            display: flex;
            justify-content: center;
            align-items: center;

        }
    }
`

