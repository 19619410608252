export const expenses = {
    "name_of_supplier": "Supplier name",
    "payment_frequency": "Payment frequency",
    "expense_record_table_title": "Record payment",
    "expense_record_table_type": "Type of expense",
    "expense_record_table_index": "#",
    "expense_record_table_description": "Expense description",
    "description_placeholder": "Enter item description",
    "expense_record_table_amount": "Amount",
    "expense_record_table_invoice": "Invoice num",
    "expense_record_table_upload": "Upload invoice",
    "expense_record_table_period": "Expense period",
    "expense_record_table_to": "To",
    "expense_record_table_from": "From",
    "expense_record_table_actions": "",
    "record_expense": "Pay expense to vendor",
    "add_line": "Add line",
    "email": "Email",
    "comment": "Comment",
    "total_amount": "Total monthly amount",
    "added_successfully": "Expenses successfully added",
    "new_supplier": "Add new vendor",
    "phone_number": "Phone number",
    "name": "Name",
    "vat_number": "Vendor Tax ID",
    "vat_number_link_text": "Can search here",
    "add_vendor": "Add new vendor",
    "expense_monthly_report": "Monthly expenses",
    "one_time": "One time",
    "oneTime": "One time",
    "recurring": "Recurrent payment",
    "expense_type": "Expense type",
    "expense_status": "Expense status",
    "expenseTypes": {
        "oneTime": "Special Collection",
        "ongoing": "Collection for {{year}}",
        "ongoingRange": "collection for selected date range",
        "futureRangeResult": "collection for future date range",
        "pastRangeDebts": "collection for past date range",
        "pastYearDebts": "past years",
        "gardening": "Landscaping",
        "plumbing": "Plumbing",
        "electricity": "Electricity",
        "elevators": "Elevators",
        "lighting": "Lightning",
        "managementCompany": "Managment company",
        "intercom": "Intercom",
        "electricalGate": "Electric gate",
        "parkingLot": "Parking lot",
        "depretiation": "Depretiation",
        "fireSystem": "Fire safety system",
        "garbage": "Garbage",
        "other": "Other",
        "airCondition": "Air conditioning",
        "cleaning": "Cleaning",
        "pestControl": "Pest control",
        "insurance": "Insurance",
        "waterReserve": "Water reserves",
        "internet": "Internet infrastructure",
        "generatorMaintenance": "Generator maintenance",
        "creditCommissions": "Credit commission",
        "bankCommissions": "Bank commission",
        "parkingElectricity": "Parking lot electricity",
        "publicElectricity": "Public electricity",
        "agudaFee": "Housing Culture Association",
        "dailerFire": "Fire Alarm Dialer - Phone Lines",
        "buildingSavings": "Building Savings",
        "solarSystems": "Solar Systems",
        "doorsMaintenance": "Doors Maintenance",
        "electricityMaintenance": "Electricity Maintenance",
        "lobbyMaintenance": "Lobby Maintenance",
        "maintenance": "maintenance",
    },
    "vendor_name_is_required": "Supplier name is required",
    "approve": "Confirm",
    "continue_and_approve": "Continue for approve",
    "date": "Action date",
    "actual_date": "Actual action date",
    "select_payment_type": "Select payment type",
    "income_modal_title": "I confirm that bllink's record match the bank's",
    "deposit_date": "Deposit date",
    "actual_deposit_date": "Actual deposit date",
    "bank": "Bank",
    "branch": "Branch",
    "account": "Account number",
    "cheque_num": "Check number",
    "wire": "Wire",
    "cheque": "Check",
    "cash": "Cash",
    "type": "Type",
    "description": "Description",
    "approved": "Approved",
    "pending": "Pending",
    "all": "All",
    "bank_adjustments_title": "Validation of Income and Expenses from the Bank report",
    "expense": "Expense",
    "income": "Income",
    "expenses": "Expenses",
    "approveSuccess": "Transaction was approved successfully",
    "upload_doc": "Upload file",
    "upload_success": "Receipt was uploaded",
    "file_size_error": "File size is more than 2MB",
    "disabled_pending": "This transaction will be approved automatically",
    "vendorDetails": "Vendor details",
    "bankDetails": "Bank details",
    "commission": "Service commission",
    "vendorCommissionLabel": "Set commission with offsetting form Vendor",
    "bankCommissionLabel": "Set commission with offsetting form bank account",
    "paymentDetails": "Payment details - Total amount {{total}}ILS",
    "expense_future_monthly_report": "Monthly Future Expenditure Report - Details of Expenditure to be Performed in {{monthName}} {{year}} - Total {{amount}} {{currency}}",
    "vendor_created": "A new provider has been successfully created",
    "choose_vendor": "Select provider",
    "expense_record_tooltip": "Click here to document supplier expenses",
    "bank_adjustments_tooltip": "Click here for bank adjustments",
    "no_vendor_has_been_selected": "It is not possible to document an expense without choosing a provider. Please select a provider",
    "payment_method": "Payment method",
    "bank_comission": "There is a setup charge of 15 NIS for the bank",
    "bank_adjustments": {
        "title": "Bank adjustments",
        "file": "File",
        "to": "To",
        "from": "From",
        "apt_num": "Apt {{apt}}",
        "cheque_number": "Cheque number",
        "wire_number": "Wire number",
        "bank_number": "Bank number",
        "cash_number": "Deposit confiramtion",
        "document_number": "Document number",
        "bllink_records": "Bllink records",
        "bank_records": "Bank records",
        "drag_and_drop": "Drag & Drop Bank File",
        "no_files": "No previously uploaded files",
        "reconciliation_header": "Select transaction for bank adjustment",
        "start_auto_reconciliation": "Start auto adjustment",
        "manual_reconciliation": "Manual adjustment",
        "auto_match_title": "Auto Match Overview",
        "choose_pairs": "Choose right pairs",
        "approve_all": "Approve all checked items",
        "items_have_same_amount": "Selected items have the same amount",
        "press_approve": "Press Approve for a match",
        "upload_failure": "The file can't be uploaded",
        "load_failure": "The file can't be loaded",
        "bytes": "bytes",
        "balance": "Balance",
        "description": "Description",
        "credit": "credit",
        "debit": "debit",
        "bllink_tooltip": "Here are your Bllink transactions.",
        "bank_tooltip": "Here are your bank transactions.",
        "no_receipt": "No receipt has been uploaded yet",
        "no_matches": "No matches were found",
        "no_records": "No records were found",
        "manual_error": "Choose transactions with the same amount",
        "approved_transaction": "Bllink transaction {{id}} was successfully approved",
        "not_approved_transaction": "Bllink transaction {{id}} wasn't approved",
        "clear_records": "Clear bank records",
        "offline_only": "Offline payments only",
    },
    "income_type": "Income type",
    "total_needed": "Planned Payment",
    "total_needed_expenses": "Planned Expenses",
    "expenses_type": "Expenses type",
    "total_paid": "Total paid",
    "show_reconsiled": "Show only reconsiled",
    "total_balance": "Total",
    "count_balance": "Opening amount + Total income - Total expenses",
    "set_expenses": {
        "confirmation_title": "Expense confirmation",
        "title_add_expense": "Add building expense",
        "manage_expenses": "Managing building expenses",
        "connect_bank_account": "Connect the building's bank account to pay suppliers. Then choose Bllink as the payment method and the money is transferred directly to the supplier's bank account. Effortless.",
        "add_expense": "Add expense",
        "online_interface": "Manage all building expenses with an online interface",
        "use_bllink_digital_payments": "Use Bllink's digital payment to transfer funds to building suppliers. Or record payments made by check, bank transfer, cash.",
        "establish_expense": "Set up new expense for building",
        "for_payments": "For payments to the supplier (electric company, landscaping, elevators...)",
        "add_means_of_payment": "Proceed to Add Payment Method",
        "skip_saving": "Or skip to Save to Expense Report and enter payment method later",
        "payment_is_recorded": "The payment was recorded in the building's expense report",
        "enters_icome_balance": "And enters the balance of income and expenses",
        "add_expense_for": "Setup new building expense",
        "search_vendor": "Search or add Supplier",
        "choose_type": "Choose expense type",
        "save_and_add_method": "Save and continue to payment method",
        "num_of_months": "Num of months",
        "upload_invoice_file": "Upload invoice/file",
        "select_date": "Select date",
        "save_and_close": "Save and close",
        "set_reccurent_payment": "You are seting <monthsNum> month recurring payment of <amount> to <vendorName>",
        "continue": "Continue",
        "pay_with_bllink": "Pay with Bllink",
        "easy_payment": "Easy payment",
        "first_step": "Step 1",
        "second_step": "Step 2",
        "third_step": "Step 3",
        "add_payment_method": "Add payment method",
        "free": "Free",
        "set_one_time_payment": "You are paying <currency><amount> to <vendorName>",
        "pay_with_bllink_or_record_offline": "Do you want to Pay with Bllink or record payment method?",
        "record_wire_payment": "Record Wire payment",
        "record_cheque_payment": "Record Cheque payment",
        "record_cash_payment": "Record Cash payment",
        "cheque_payment": "Cheque payment",
        "cash_payment": "Cash payment",
        "wire_payment": "Wire Payment",
        "wire_confirmation_num": "Wire confirmation num",
        "amount_out_of_range": "Note, bit payment range is between <min>₪ to <max>₪. Please choose a different payment method",
        "select_months_placeholder": "Select",
        "setExpenseSuccess": "Success in setting up expense",
        "positiveNumberOnly": "positive amount only",
        "continueToNewCard": "Continue to new card",
    },
    "balance_report": {
        "fromDate": "From",
        "toDate": "To",
    },
    "from": "From",
    "to": "To",
    "status": "Status",
    "payment_type": "Payment Method",
    "register": {
        "title": "You still didn't register your building to the new building expenses?",
        "registerHere": "Register Here",
    },
    "extract_yearly_planning": "Extract Yearly Planing",
    "download_yearly_planning": "Download Yearly Planing",
    "expenses_yearly_planning": "Yearly Expenses Planing",
    "expenses_yearly_planning_title": "Yearly expenses for {{building}} - Total {{amount}}",
    "send_unpaid": "Send unpaid report",
    "unpaid_expenses": "Unpaid Expenses",
    "unpaid_expenses_title": "Unpaid expenses for {{building}} - Total {{amount}}",
    "no_unpaid_expenses": "No unpaid expenses found to send",
};
