import { createSlice } from '@reduxjs/toolkit';

const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        monthlyReportsSearchResults: [],
    },
    reducers: {
        changeMonthlyReportsSearchResults: (state, action) => {
            state.monthlyReportsSearchResults = action.payload;
        },
    },
});

export const {
    changeMonthlyReportsSearchResults,
} = reportsSlice.actions;
export default reportsSlice.reducer;