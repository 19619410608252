export const statuses = {
    "transferred_to_building_account": "Transferred to building account",
    "canceled_but_not_reversed": "Canceled but not reversed",
    "tourist_card_success": "Tourist card success",
    "j5": "J5",
    "failed": "Failed",
    "stop_plan": "Stop plan",
    "skip_payment": "Skip payment",
    "in_progress": "In progress",
    "success_pending": "Success pending",
    "pending": "Pending",
    "success": "Success",
    "success_bank_charge": "charged still not deposited",
    "refunded": "Refunded",
    "deposited": "Deposited",
    "offset": "Offset",
    "success_deposit": "Success",
    "unknown": "Unknown", // go ahead and calculate it from set of related payment plan details

    "inProgress": "Plan in process",

    "failedOnce": "Recently failed (still active)", // still active
    "stopped": "Stopped",

    "offlinePending": "Payment documentation. Not bank adjusted yet",
    "offlineProgress": "In process (payment documentation)",
    "offlineSuccess": "Payment documentation. Have been adjusted for banking",
    "offlineStopped": "Payment documentation stopped",

    "success_action_notification": "Action successfully performed",
    "error_action_notification": "Action failed",

    "pendingCollectionReport": "Pending",
    "depositCollectionReport": "Deposit",
};
