export const chatBot = {
    "onboarding": {
        "onboardingBotTitle": "Bllink.co building payments - open a new building in 5 minutes",
        "welcomeMessageGuest": "Hello, welcome to the building construction page. I will be happy to help you build a building in just 5 minutes. I would like to start with the full address of the building",
        "continueOnBoardingForManager": "Hi {{managerMame}}, we noticed you recently started creating a new building at {{address}}. What would you like to do next on this building?",
        "thanksForReport": "thanks for report"
    },

    "legalBot": {
        "Title": "Legal Bot Page Bllink.co",
        "welcomeMessageGuest": "Hello, welcome Legal bot page",
    },

    "csmTechnical": {
        "Title": "Tech Support",
        "welcomeMessage": "Hi!. I'll try to answer and explore technical or product related questions here. ❤️",
    },

    "managersAssistants": {
        "Title": "personal assistant for building manager",
        "welcomeMessage": "Hi. I would love to help  שלום. אשמח לעזור בכל דרך אפשרית. ניתן לשאול אותי שאלות על בלינק ובעתיד גם אוכל לבצע פעולות  ❤️",
    },

    "common": {
        "waitingForResponse": "a few second while i'm thinking..",
        "thanksForReport": "thanks for report"
    }

}
