export const editBuilding = {
    "titles": {
        "assignTo": "Assign to partner or user",
        "updateManager": "Update manager details",
        "commission": "Set/update commission"
    },
    "labels": {
        "partner": "Partner",
        "user": "User",
        "email": "Email",
        "phone": "Phone",
        "managerFirstName": "Manager first name",
        "managerLastName": "Manager last name",
        "tenant": "Tenant",
        "building": "Building",
        "managementCompany": "Management company"
    },
    "commissionError": "Total commission sum must be bigger than 1",
    "btns": {
        "assign": "Assign",
        "updateCreate": "Update / create",
        "setUpdate": "Set / update"
    },
    "errors": {
        "phone": "Phone isn't correct",
        "email": "Email isn't correct"
    },
    "success": {
        "manager": "Building was successfully assigned to partner/user",
        "managerDetails": "Manager details were successfully updated",
        "commissionDetails": "Commission details were successfully updated"
    }
};
