/**
 * @typedef {object} ImageUploadResult
 * @property {true} success
 * @property {{ fileName: string, fileType: string }} fileData
 * @property {string} filesKey
 * @property {{ filePath: string, type: string }[]} filesUploaded
 * */

class FakeAxiosError extends Error {
    constructor(message, key) {
        super(message);
        this.response = { data: { key } }
    }
}

export class ImagesApi {

    /*** @type {ServerData} */  #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} imageBase64
     * @param {string} name
     * @param {'bank-charge-permission' | 'building-bank-charge-permission' | 'regular'} [typeOfUpload = 'regular']
     * @param {object} [extraInformation = null] TODO: add type
     * @return {Promise<{ImageUploadResult}>}
     * */
    async upload(imageBase64, name, typeOfUpload = 'regular', extraInformation = null) {
        const payload = { imageBase64, name, typeOfUpload, extraInformation };
        const payloadLength = JSON.stringify(payload).length;
        console.log('payloadLength', payloadLength);
        if (payloadLength > 5000000) { // about 5 Meg
            throw new FakeAxiosError('File too big', 'FILE_TOO_BIG');
        }
        return this.#client.put('/images/upload', payload, { throwError: true });
    }
}
