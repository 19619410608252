export class BuildingsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }


    /**
     * @param {string} buildingId
     * @return {Promise<{ featureValues: FeatureValue[] }>}
     * */
    getFeatures(buildingId) {
        return this.#client.get(`/buildings/${buildingId}/features`);
    }

    /**
     * @param {string} buildingId
     * @param {string} featureName
     * @return {Promise<{ featureValues: FeatureValue[] }>}
     * */
    getFeatureValue(buildingId, featureName) {
        return this.#client.get(`/buildings/${buildingId}/features/${featureName}`);
    }

}
