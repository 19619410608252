import styled from "styled-components";

export const SideMenuStyled = styled.div`
  .bllink-side-menu {
    padding: 20px 16px;
    position: fixed;
    z-index: 11000;
    opacity: 0.95;
    background-color: ${props => props.theme.mainBg};
    box-shadow: 0px 8px 34px 0px rgba(89, 102, 137, 0.25);

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    &-header {
      margin-bottom: 6px;
      
      &-img {
        cursor: pointer;
      }

      .reversed-icon {
        transform: rotate(180deg);
      }
    }
    
    &-content {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-grow: 1;
      text-align: ${props => props.ltr ? 'left' : 'right'};
    }
  }
`
