import axios from 'axios';

/**
 * @typedef {object} ArchiveFile
 * @property {string} File
 * @property {string} Key
 * @property {string} LastModified
 * @property {string} ETag
 * @property {number} Size
 * @property {string} StorageClass
 * @property {{ displayname: string, userid: string, type: string }} Metadata
 * @property {string} ContentType
 * */

/**
 * @typedef {object} ArchiveUploadRequestItem
 * @property {string} name
 * @property {string} contentType
 * @property {object} metadata
 * @property {string|number} id this will be transparently returned back
 * */

/**
 * @typedef {object} ArchiveUploadItem
 * @property {string} name
 * @property {string|number} id this will be transparently returned back
 * @property {string} contentType
 * @property {object} metadata
 * @property {string} path
 * @property {string} uploadLink
 * */

export class ArchiveApi {

    /*** @type {ServerData} */ #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string|number} buildingId
     * @param {boolean} includeDeleted
     * @return Promise<{ files: ArchiveFile[] }>
     * */
    getList(buildingId, includeDeleted = false) {
        return this.#client.get(`/managers/archive/${buildingId}`, { includeDeleted }, { throwError: true });
    }

    /***
     * @param {string|number} buildingId
     * @param {ArchiveUploadRequestItem[]} files
     */
    generateUploadLinks(buildingId, files) {
        return this.#client.post(`/managers/archive/${buildingId}/upload_token`, { files }, { throwError: true });
    }

    /**
     * @param {string|number} buildingId
     * @param {string} key
     * @return {Promise<{ link: string }>}
     * */
    generateDownloadLink(buildingId, key) {
        return this.#client.post(`/managers/archive/${buildingId}/download_token`, { key }, { throwError: true });
    }

    /**
     * @param {ArchiveUploadItem} uploadItem
     * @param {File} file
     * @param {(e: ProgressEvent, item: ArchiveUploadItem) => void} progressCallback
     * */
    async uploadFile(uploadItem, file, progressCallback, doneCallback) {
        const config = {
            onUploadProgress: function(progressEvent) {
                progressCallback(progressEvent, uploadItem);
            },
            headers: {
                'Content-Type': file.type,
                'x-amz-acl': 'public-read',
                ...(Object.entries(uploadItem.metadata).reduce((acc, [k, v]) => Object.assign(acc, { [`x-amz-meta-${k}`]: v }), {})),
                'x-amz-meta-displayname': encodeURIComponent(uploadItem.metadata.displayname),
            }
        }
        try {
            const result = await axios.put(uploadItem.uploadLink, file, config);
            doneCallback(result, null, uploadItem);
        } catch (e) {
            doneCallback(null, e, uploadItem);
        }
    }

    async deleteFile(buildingId, file) {
        return this.#client.post(`/managers/archive/${buildingId}/delete`, { file }, { throwError: true });
    }

    async undeleteFile(buildingId, file) {
        return this.#client.post(`/managers/archive/${buildingId}/respawn`, { file }, { throwError: true });
    }
}
