export class RewardsApi {

    /**
     * @typedef {{
     *    phone: string;
     *    email: string;
     *    fullName: string;
     *    apartment?: string;
     *    street?: string,
     *    city?: string;
     * }} RewardDetails
     */

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {number} campaignId
     * @param {number} selectedPrizeId
     * @param {RewardDetails} payload
     * @return {Promise<unknown>}
     * */
    addReward(campaignId, selectedPrizeId, payload) {
        return this.#client.put(`/managers/rewards/campaigns/${campaignId}/redeem`, {
            campaignPrizeId: selectedPrizeId,
            ...payload
        });
    }

    /**
     * @typedef {{
     *    campaignPrizeId: number,
     *    coinValue: number,
     *    Details: {
     *        name: string,
     *        description: string
     *    },
     *    Images: {
     *        imageId: number,
     *        imageSrc: string
     *    }
     * }} Prizes
     */

    /**
     * @typedef {{
     *    campaignRewardId: number,
     *    coinValue: number,
     *    campaignId: number,
     *    EarningAction: {
     *        name: string,
     *        description: string,
     *        eventName: string
     *    }
     * }} Rewards
     */

    /**
     * @typedef {{
     *    campaignId: number,
     *    name: string,
     *    Rewards: [Rewards],
     *    Prizes: [Prizes],
     * }} CampaignsInfo
     */

    /**
     * @typedef {{
     *    campaignId: number,
     *    coinValue: number,
     * }} CoinBallance
     */

    /**
     * @typedef {{
     *    campaigns: [CampaignsInfo],
     *    coinBalance: [CoinBallance],
     * }} ActiveCampaignsInfo
     */

    /**
     * @return {Promise<ActiveCampaignsInfo>}
     * */
    getActiveCampaignsInfo() {
        return this.#client.get('/managers/rewards/campaigns/active');
    }

}
