/**
 * @typedef {object} MonthPaymentTotals
 * @property {number} month
 * @property {number} year
 * @property {number} Total
 * @property {number} TotalInCreditCard
 * @property {number} TotalInCheque
 * @property {number} TotalFuture
 * */

export class PartnerDashboardApi {
    #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string | number} partnerId
     * */
    getStats(partnerId) {
        return this.#client.get(`/partners/${partnerId}/dashboard/stats`, { }, { throwError: true });
    }

    /**
     * @param {string | number} partnerId
     * @return {Promise<{ monthTotals: MonthPaymentTotals[] }>}
     * */
    getMonthAmounts(partnerId) {
        return this.#client.get(`/partners/${partnerId}/dashboard/month_amounts`, { }, { throwError: true });
    }
}
