import {matchPath} from "react-router-dom";

export function isLinkActive(linkUrl, {part = false, query = false} = {}) {
    // what does this do?
    if (Array.isArray(linkUrl)) {
        return linkUrl.some(substring => window.location.pathname.includes(substring))
    }
    if (part && window.location.pathname.includes('tenant-list')) return false;
    if (part || query) {
        const url = `${window.location.pathname}${window.location.search}`;
        return query ? url === linkUrl : url.includes(linkUrl);
    }
    return !!matchPath(window.location.pathname, {
        path: linkUrl,
        exact: true,
        strict: true
    });
}
