import { constants } from "../../constants/constants";
import {lazyReload} from "../../lazyReload";
const Login = lazyReload(() => import("../../../managers/login/login"));
const LoginChangePassword = lazyReload(() => import("../../../managers/login/loginChangePassword"));
const SignUp = lazyReload(() => import("../../../managers/signup/signup"));

export const LoginRoutes = [
    {path: `/${constants.pages.singUpUrl}`, component: SignUp, exact: true},
    {path: `/${constants.pages.singUpDemoUrl}`, component: Login, exact: true},
    {path: `/${constants.pages.firstSigninUrl}`, component: LoginChangePassword, exact: true},
    {path: `/${constants.pages.loginUrl}`, component: Login, exact: true},
]

