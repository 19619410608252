// Accumulating Redux store modules here

import {combineReducers, configureStore} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import commonSlice from './common/slice';
import commonPersistentSlice from './common/persistentSlice';
import authSlice from './auth/slice';
import buildingsSlice from './buildings/slice';
import expensesSlice from './expenses/slice';
import adminSlice from './admin/slice';
import commentsSlice from './comments/slice';
import reportsSlice from './reports/slice';
import managerSlice from './manager/slice';
import tenantSlice from './tenant/slice';
import {tenantProfileSlice} from '../tenants/profile/store/slice';

const asyncReducers = {};
const staticReducers = {
    common: commonSlice,
    auth: authSlice,
    buildings: buildingsSlice,
    expenses: expensesSlice,
    admin: adminSlice,
    comments: commentsSlice,
    reports: reportsSlice,
    manager: managerSlice,
    commonPersistent: persistReducer({ key: 'commonPersistentSlice', storage }, commonPersistentSlice),
    tenant: persistReducer({ key: 'tenantSlice', storage }, tenantSlice),
    tenantProfile: persistReducer({ key: 'tenantProfile', storage }, tenantProfileSlice.reducer),
};

export const store = configureStore({
    reducer: staticReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

store.hasReducer = function (key) {
    return asyncReducers[key] != null || staticReducers[key] != null;
}
store.injectReducer = function (key, asyncReducer) {
    if (this.hasReducer(key)) {
        throw new Error(`Reducer ${key} already registered`);
    }
    asyncReducers[key] = asyncReducer;
    store.replaceReducer(combineReducers({
        ...staticReducers,
        ...asyncReducers
    }));
};

export const persistor = persistStore(store);

store.persistor = persistor;

// For test utils
export const reducer = {
    common: commonSlice,
    auth: authSlice,
    buildings: buildingsSlice,
    expenses: expensesSlice,
    admin: adminSlice,
    comments: commentsSlice,
    reports: reportsSlice,
    manager: managerSlice
};
