export const createPartner = {
    "titles": {
        "page": "Create or edit partner",
        "create": "Create partner",
        "update": "Update partner"
    },
    "labels": {
        "name": "Partner name",
        "shortcut": "Partner English shortcut",
        "select": "Select partner",
        "logo": "Partner logo"
    },
    "btns": {
        "create": "Create partner",
        "update": "Update partner",
        "upload": "Upload file"
    },
    "logoUploaded": "Logo was successfully uploaded"
};
