import Promax from "../../images/pmcStore/promax.jpg";
import PigeonRemover from "../../images/pmcStore/pigonRemover.webp";
import SmellGood from "../../images/pmcStore/smellGood.webp";
import Elevator from "../../images/pmcStore/elevator.webp";

export const additionalServices = [
    {
        icon: Promax,
        title: 'פרומקס - הבחירה הנכונה לבדיקת מערכות גילוי וכיבוי אש',
        paragraphs: [
            'עלון על השירות ניתן למצוא https://bllink-public.s3.amazonaws.com/systemsCheckBrochure.jpg',
            'כאשר מדובר בבטיחות וביטחון של מגדלי מגורים, חשוב לבחור בחברה מקצועית ומנוסה שתספק את הפתרונות הטובים ביותר. כאן בפרומקס, אנו מציעים מגוון רחב של שירותי בדיקות גילוי אש ומערכות כיבוי אש, תוך הקפדה על התקנים המחמירים ביותר, תקן 1220 ותקן 1928.',
            'הניסיון שלנו, הביטחון שלכם - פרומקס מציעה שירותי בדיקות שנתיות לגילוי אש לפי תקן 1220 ובדיקות מערכות כיבוי אש לפי תקן 1928. אנו מתמחים בבדיקת מגדלי מגורים, מה שמאפשר לנו לספק שירותים מותאמים אישית לצרכים המיוחדים של מבנים אלו. צוות המומחים שלנו מבצע בדיקות מקיפות ויסודיות לכל רכיבי המערכת, כולל בדיקות גלגלונים ומטפים, כדי להבטיח שהכל פועל בצורה תקינה ובטוחה.',
            'מה חשוב לחברות ניהול ווועדי בתים? - אחד ההיבטים החשובים ביותר עבור חברות ניהול וועדי בתים הוא השקט הנפשי שניתן להם על ידי הידיעה שהמבנים שבניהולם בטוחים ומוגנים מפני סכנות אש. בפרומקס, אנו מבינים את החשיבות של שמירה על רמת הבטיחות הגבוהה ביותר, ולכן אנו מספקים שירותים אמינים ומקצועיים שמותאמים בדיוק לצרכים של חברות ניהול ווועדי בתים במגדלי מגורים.',
        ],
        contactPhone: '051-5577148',
        id: 1,
    },
    {
        icon: Elevator,
        title: 'טכנאי מעליות',
        description: '',
        contactPhone: '03-5090045',
        id: 2,
    },
    {
        icon: PigeonRemover,
        title: 'מרחיק יונים',
        description: '',
        contactPhone: '03-5090045',
        id: 3,
    },
    {
        icon: SmellGood,
        title: 'ריחות ללובי',
        description: '',
        contactPhone: '03-5090045',
        id: 4,
    }

]
