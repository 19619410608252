import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useDebounce} from "../../common/hooks/useDebounce";
import {useAccessToken} from "../../common/hooks/useAccessToken";
import {CollapsibleSearch} from "../../ui/CollapsibleSearch";
import {TenantSearchOptionCard} from "./TenantSearchOptionCard";
import {GAEvent} from "../../common/google_analytics/google_analytics";
import {constants} from "../../common/constants/constants";

const MIN_LETTERS_TO_SEARCH = 2;

export const GlobalSearch = () => {
    const {t} = useTranslation();
    const client = useAccessToken();

    const [search, setSearch] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [searchOptions, setSearchOptions] = useState(null);

    const msDelayAfterTyping = 500;
    const deferredFilter = useDebounce(search, msDelayAfterTyping);

    /**
     * @param tenantsResult
     * @returns {*|*[]}
     */
    const getTenantOptions = (tenantsResult) => {
        if (!tenantsResult?.length) return [];
        return tenantsResult?.map(tenant => {
            return <TenantSearchOptionCard tenant={tenant}/>
        }).filter(option => !!option);
    }

    useEffect(async () => {
        // https://bllink.atlassian.net/browse/SSB-1878
        // start search to BE only after 2 letters typed
        if (!search || search.length < MIN_LETTERS_TO_SEARCH) {
            setSearchOptions([]);
            return;
        }
        setIsLoading(true);
        const {tenantsResult, bankInfoResult} = await client.managers.globalSearch.getGlobalSearchResults(search)
            .finally(() => setIsLoading(false));
        GAEvent(constants.GoogleAnalytics.Events.general, 'searchBarSearched', 1, 'searchBarSearched');
        const searchOptions = [...getTenantOptions(tenantsResult), ...getTenantOptions(bankInfoResult)];
        setSearchOptions(searchOptions);
    }, [deferredFilter])

    return <CollapsibleSearch
        onChange={e => setSearch(e)}
        searchOptions={searchOptions}
        isLoading={isLoading}
    />
}
