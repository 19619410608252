import {BankAdjustmentsApi} from "./managers/bankAdjustments.api";
import {ReportsApi} from "./managers/reports.api";
import {ExpensesApi} from "./managers/expenses.api";
import {RewardsApi} from "./managers/rewards.api";
import { BuildingsApi } from "./managers/buildings.api";
import { DebtsApi } from "./managers/debts.api";
import { OperationsApi } from "./managers/operations.api";
import { TenantRecordsApi } from "./managers/tenantRecords.api";
import {PaymentsApi} from "./managers/payments.api";
import {DashboardApi} from "./managers/dashboard.api";
import {MaintenanceApi} from "./managers/maintenance.api";
import { ContactsApi } from './managers/contacts.api';
import {ArchiveApi} from "./managers/archive.api";
import {PaymentSettingsApi} from "./managers/paymentSettings.api";
import {FeaturesApi} from "./managers/features.api";
import {ManagerMonitoringApi} from "./managers/monitoring.api";
import {GlobalSearchApi} from "./managers/globalSearch.api";

export class ManagersApi {

    /**
     * @typedef {{ year: number; month: number; day?: number }} DateStruct
     * */

    /**
     * @typedef {{ currency?: string, value: number }} CurrencyValue
     * */

    /**
     * @typedef {{ fileName: string; url: string; lastModified: string; size: number; }} FileInfo
     * */

    /**
     * @name PaymentPlans
     * @typedef {{
     *   PaymentPlanId?: number;
     *   PtoPId?: number;
     *   PlanType?: number;
     *   ClientName?: string;
     *   ClientEmail?: string;
     *   ClientPhone?: string;
     *   Comment?: string;
     *   oneTimePaymentID?: number;
     *   cartID?: string;
     *   CreatedDate?: string;
     *   CreatedBy?: string;
     *   AgreeAdds?: number;
     *   OverallStatus?: string;
     * }}
     * */

    /**
     * @name PaymentPlanDetails
     * @typedef {{
     *   PaymentPlanDetailId?: number;
     *   PaymentPlanId?:  number;
     *   PtoPId?: number;
     *   ChargeDate?: string;
     *   AmountInCents?: number;
     *   Currency?: string;
     *   CommissionInCents?: number;
     *   PaymentPlanStatus?: number;
     *   PeriodFrom?: string;
     *   PeriodTo?: string;
     * }}
     * */

    /**
     * @typedef { 'manager' | 'maintenance' | 'tenant' } UserRole
     * */

    /**
     * @typedef {object} ManagerProfile
     * @property {string} userID
     * @property {string} userFirstName
     * @property {string} partnerName
     * @property {string} partnerLogo
     * @property {string} partnerID
     * @property {string} partnerPaymentMethod
     * @property {UserRole[]} roles
     * @property {boolean} isMaintenanceOnly indicates user have maintenance permission on one of partner building
     * @property {boolean} isViewOnly indicates user have no access to maintenance page
     * @property {boolean} isDemoSignUpUser indicates user have access to one building only and it's the demo building
     * */

    #client;

    /** @type {BankAdjustmentsApi} */ bankAdjustments;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
        this.bankAdjustments = new BankAdjustmentsApi(client);
        this.reports = new ReportsApi(client);
        this.expenses = new ExpensesApi(client);
        this.rewards = new RewardsApi(client);
        this.buildings = new BuildingsApi(client);
        this.debts = new DebtsApi(client);
        this.operations = new OperationsApi(client);
        this.tenantRecords = new TenantRecordsApi(client);
        this.payments = new PaymentsApi(client);
        this.paymentSettings = new PaymentSettingsApi(client);
        this.dashboard = new DashboardApi(client);
        this.maintenance = new MaintenanceApi(client);
        this.contacts = new ContactsApi(client);
        this.archive = new ArchiveApi(client);
        this.features = new FeaturesApi(client);
        this.monitoring = new ManagerMonitoringApi(client);
        this.globalSearch = new GlobalSearchApi(client);
    }

    /**
     * @return {Promise<ManagerProfile>}
     * */
    getProfile() {
        return this.#client.get('/managers/profile');
    }

}
