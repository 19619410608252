export const monitoring = {
    "activeMonitoring": {
        "pageTitle": "קמפיין אוטומטי חדש",
        "tabs": {
            "inMonitoring": "דירות בקמפיין",
            "withoutMonitoring": "דירות מחוץ לקמפיין"
        },
        "totalTenants": "סך כל הדיירים:",
        "labels": {
            "tenantValue": "סוג פנייה",
            "actionName": "שם הפעולה הנוכחית",
            "nextActionIn": "הפעולה הבאה בעוד",
            "nextActionName": "שם הפעולה הבאה",
            "sentAt": "נשלח ב-",
            "totalAmountMissing": "סכום כולל חסר",
            "days": "ימים",
            "hours": "שעות",
            "tenantEmail": "דוא\"ל לשוכר/ת",
            "tenantPhone": "הודעה לשוכר/ת",
            "ownerEmail": "דוא\"ל לבעלים",
            "ownerPhone": "הודעה לבעלים",
        },
        "actions": {
            "welcome": "ברוכים הבאים",
            "reminder": "תזכורת תשלום",
            "tenantEmail1": "הודעת מייל ראשונה לשוכר",
            "tenantEmail2": "הודעת דואר שנייה לשוכר",
            "tenantEmail3": "הודעת מייל שלישית לשוכר",
            "tenantEmail4": "הודעת מייל רביעית לשוכר",
            "tenantPhone1": "סמס מספר 1 לשוכר/ת",
            "tenantPhone2": "סמס מספר 2 לשוכר/ת",
            "tenantPhone3":  "סמס מספר 3 לשוכר/ת",
            "tenantPhone4":  "סמס מספר 4 לשוכר/ת",
            "ownerEmail1": "הודעת דוא\"ל ראשונה לבעלים",
            "ownerEmail2": "הודעת דוא\"ל שנייה לבעלים",
            "ownerEmail3": "הודעת דוא\"ל שלישית לבעלים",
            "ownerEmail4": "הודעת דוא\"ל רביעית לבעלים",
            "ownerPhone1": "סמס מספר 1 לבעלים",
            "ownerPhone2": "סמס מספר 2 לבעלים",
            "ownerPhone3": "סמס מספר 3 לבעלים",
            "ownerPhone4": "סמס מספר 4 לבעלים",
        },
        "btns": {
            "stopMonitoring": "הפסק/י מעקב על כל הבניינים",
            "stopMonitoringApartment": "הפסק/י מעקב דירה",
            "startMonitoring": "הפעל/י מעקב אוטומטי לכל הבניינים",
            "startMonitoringApartment": "הכנס/י דירה למעקב",
            "aptNotes": "הערות דירה",
            "startAll": "הפעל מעקב דירות",
            "stopAll": "הפסק מעקב דירות",
        },
        "faq": {
            "faqs": "שאלות ותשובות - קמפיין האוטומטי",
            "settingsPage":"בעמוד ההגדרות",
            "qAndAs":{
                "q1":"הדלקנו את הקמפיין בעבר והוא שלח הודעות חוב על חודש נוכחי מוקדם מידי",
                "a1":"חדשות טובות! דיירים עם תשלום פתוח על חודש נוכחי יקבלו הודעות מהקמפיין האוטומטי רק אחרי העשירי לחודש.",
                "q2":"מה אם בחלק מהדירות אני לא רוצה לשלוח תזכורות?",
                "a2":"ניתן להחריג דירות ספציפיות מהקמפיין שלא רוצים שבלינק תשלח להם הודעות ישירות דרך הממשק.",
                "q3":"אם רוב הדיירים שלי ישלמו דרככם, אני לא אשלם עמלה גבוהה?",
                "a3":"אם אתה חושב שהעמלה שלנו גבוהה, נסה לחשב כמה עולה להתקשר לדייר, בקשת העברה בנקאית ישירות לחשבון שלך, ומה קורה אם ההעברה נכשלת? ואיך עושים התאמות בנקאיות? בצורה ידנית? ומה עם הדייר עבר דירה וביטל את ההוראה? מי עוקב אחרי זה? בסוף החישוב תגלה שהכי משתלם לעסק שלך, זה לתת לבלינק לגבות דיגיטלית ואוטומטית מהדיירים שלך.",
                "q4":"איך אני יודע מתי תשלחו הודעות לדיירים? אולי זה ביום שאני לא נמצא במשרד?",
                "a4":"המערכת שולחת לכל דייר עם חוב כל חמישה ימים אבל ניתן להגדיר בצורה פשוטה הגבלות על הימים שנשלחים הודעות כדי לסנכרן את הימים עם הימים שאתה או הצוות שלך נמצא במשרד.",
                "q5":"מה אם יש בדירה גם שוכר וגם בעלים?",
                "a5":"קל! השוכר מקבל הודעות חוב על תשלומים שוטפים והבעלים על תשלומים חד פעמיים. כשכל אחד מהם מגיע לעמוד תשלום, אלו התשלומים שהם יראו (שוכר - תשלומים שוטפים ובעלים - חד פעמי)\n" +
                    "ד״א הבעלים מקבלים התראה על חוב של השוכר אם השוכר/ת קיבלו כבר מעל 4 התראות ועדיין לא שילמו",
                "q6":"כל כמה זמן המערכת שולחת הודעות לבעלי חוב?",
                "a6":"כברירת מחדל, כל חמישה ימים. גם את זה אפשר לערוך לימים ספציפיים בעמוד ההגדרות",
                "q7":"האוטומציה הזאת עולה לנו כסף?",
                "a7":"כל הסמסים, אימיילים ושיחות קוליות הם על חשבוננו. חינם לגמרי. האינטרס הוא משותף. שלנו, תשלום של יותר דירות דרך בלינק ושלכם, הורדת מעקב ידני אחרי רשימת חייבים.",
                "q8":"האם אני יכול לשנות את הטקסט שנשלח?",
                "a8":"כן. אבל אנחנו לא ממליצים . הטקסט נבחן ושופר עבור אלפי הבניינים הרבים שאנחנו מנטרים. עם זאת, ניתן לשנות את הטקסט עבור הודעות הקמפיין בעמוד ההגדרות",
            }
        },
        "successfullyStarted": "דירה הוכנסה לקמפיין בהצלחה",
        "successfullyStopped": "דירה הוצאה מהקמפיין בהצלחה",
        "tooManyOutOfCampaign": "שְׁגִיאָה. לא יכול להשאיר {{maxNum}} דירות מחוץ לקמפיין. אנא צור קשר עם שירות הלקוחות",
        "successfullyAllStarted": "כל ניטור הדירות הוחל בהצלחה",
        "successfullyAllStopped": "כל ניטור הדירות הופסק בהצלחה",
        "somethingWentWrong": "משהו השתבש",
    }
}
