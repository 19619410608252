import {isLinkActive} from "./Sidebar.shared";
import {NavLink, generatePath} from "react-router-dom";
import {MenuItem} from "react-pro-sidebar";
import {Tooltip} from "../../ui/Tooltip";

/**
 * @param {string} [icon]
 * @param {string} label
 * @param {string} url
 * @param {string} triggerEvent
 * @param {boolean} highlight
 * @param {Record<string, any>} [urlParams]
 * @param {string} [className]
 * @param {boolean} stopRedirect
 * @param {boolean} disabled
 * @param {string} tooltip
 * @param {boolean} tooltipDisabled
 * @param {(e) => void} [onClick]
 * */
export function MenuNavItem({
                                icon,
                                url,
                                triggerEvent,
                                urlParams = {},
                                label,
                                highlight = false,
                                className,
                                onClick = (e) => void null,
                                stopRedirect = false,
                                disabled = false,
                                tooltip,
                                tooltipDisabled = true,
                            }) {

    /**
     * @param {MouseEvent} e
     */
    const blockedClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(stopRedirect && onClick) {
            onClick(e);
        }
        if (triggerEvent){
            document.dispatchEvent(new Event(triggerEvent));
        }
    }

    // generatePath
    return <MenuItem icon={icon == null ? '' : (<img src={icon} alt="icon"/>)}
                     active={isLinkActive(url)} onClick={disabled ? blockedClick : onClick}
                     className={`${className ?? ''} ${disabled ? 'disabled' : ''}`}>
        <Tooltip text={tooltip} disabled={tooltipDisabled}>
            <NavLink className={`nav-link ${highlight ? 'highlight': ''} ${disabled ? 'disabled' : ''}`}
                     to={generatePath(url, urlParams)} onClick={disabled || stopRedirect ? blockedClick : () => {}}>
                {label}
            </NavLink>
        </Tooltip>
    </MenuItem>
}
