export class MaintenanceApi {

    /**
     * duplicate
     * @typedef {{
     *    token: string;
     *    noFeatureSet: boolean;
     * }} MaintenanceLoginResponse
     * */

    #client;
    // #prefix = '/tenants/maintenance';
    #prefixOperations = '/tenants/operations'; // was easier to avoid a new endpoint file

    constructor(client) {
        this.#client = client;
    }


    /**
     * @param {string|number} buildingID
     * @param {string} apartmentNumber
     * @return {Promise<MaintenanceLoginResponse>}
     * */
    buildingLogin(buildingID, apartmentNumber) {
        return this.#client.post(`${this.#prefixOperations}/login/building/${buildingID}/${apartmentNumber}`, {}, { throwError: true });
    }


}
