export const reports = {
    tenantType: {
        renter: 'renter',
        owner: 'owner',
        unknown: 'unknown',
    },
    "yearly_cashflow_title": "דו״ח תזרים שנתי {{year}}",
    "monthly_cashflow_title": "דו״ח חודשי - פירוט חיובים שבוצעו בחודש {{monthName}} {{year}} - סה״כ <span class='amount'>{{currency}} {{amount}}<\/span>",
    "monthly_cashflow_table_title": "חיובים חודשיים {{monthName}} {{year}} - סה״כ {{amount}} {{currency}} ",
    "monthly_partner_cashflow_table_title_tooltip": "סה״כ חיובי בלינק ותיעודי תשלום. כולל גם בניינים לא פעילים. בהשוואה לדו״ח חודשי יש להחסיר תשלומי תייר שבוצעו חודש שעבר.",
    "export_title": "ד״וח חיובים כללי",
    "cheques_export_title": "Partner cheques report",
    "apartment_cashflow_title": "פירוט תשלומים עבור דירה {{apartmentNumber}}",
    "cheques_title": "דו״ח הפקדות צ׳קים - פירוט צ׳קים להפקדה {{monthName}} {{year}} - סה״כ {{amount}} {{currency}}",
    "monthly_future_cashflow_title": "דו״ח תזרים חודשי עתידי - פירוט חיובים שיבוצעו בחודש {{monthName}} {{year}} - סה״כ {{amount}} {{currency}}",
    "yearly_by_months_title": "דו״ח שנתי - פירוט חודשים שעבורם בוצע תשלום {{year}}",
    "yearly_by_months_title_new": "דו״ח שנתי",
    "special_payments_report_title": "תשלומים מיוחדים",
    "yearly_by_months_one_time_title": "פירוט תשלומים מיוחדים לשנת {{year}}",
    "tenantName": "שם דייר",
    "buildingTitle": "כתובת בניין",
    "selectApartment": 'בחירת דירה',
    "renterNameLabel": "שוכר/ת:",
    "ownerNameLabel": "בעלים:",
    "ownerName": "שם בעלים",
    "ownerPhone": "טלפון בעלים",
    "ownerEmail": "אימייל בעלים",
    "renterName": "שם שוכר",
    "renterPhone": "טלפון שוכר",
    "renterEmail": "אימייל שוכר",
    "apartmentName": "דירה",
    "buildingName": "בניין",
    "date_to_pay": "מועד חיוב",
    "ownership_type": "סוג דייר",
    "payment_comment": "הערת תשלום",
    "receipt": "קבלה",
    "done_by": "בוצע ע״י",
    "transferredAt": "הועבר לבניין ב",
    "actions": "פעולות",
    "confirmation": "אישור תשלום",
    "confirmation_link_text": "אישור",
    "billing_date": "מועד חיוב",
    "apartment_number": "דירה {{apartmentNumber}}",
    "paid_out_of_total": "שולם {{paid}} מתוך {{total}}",
    "paid": "שולם {{paid}}",
    "paid_full": "תשלום מלא {{paid}}",
    "paid_none": "לא משוייך תשלום",
    "paid_for": "תשלום עבור",
    "amount": "סכום",
    "scheduled_to_pay": "תשלום עתידי {{scheduledToPay}} מתוך {{total}}",
    "next_payments": "תשלומים עתידיים {{nextPayments}}",
    "failed_payment": "תשלום נכשל. הודעה עם לינק לעדכון פרטים נשלחה לדייר/ת",
    "monthly_cashflow_sub_title": "סה״כ דרך בלינק ",
    "none_paid": "לא משוייך תשלום",
    "partially_paid": "שולם חלקית",
    "paid_new": "שולם",
    "paid_full_new": "תשלום מלא",
    "paid_none_new": "לא משוייך תשלום",
    "scheduled_to_pay_only_new": "תשלום עתידי",
    "monthly_cashflow_sub_title_future": " סה״כ בתשלומים עתידיים",
    "monthly_cashflow_sub_title_early_withdrawal": " סה״כ הערכה למשיכה מוקדמת. אחרי קיזוז עמלות מהבניין",
    "scheduled_to_pay_only": "תשלום עתידי {{scheduledToPay}}",
    "paid_explanation": "שולם",
    "part_paid_explanation": "שולם חלקית",
    "tenant_claim_paid_explanation": "סומן כשולם",
    "empty_table": "לא קיימות רשומות",
    "empty_table_for": "לא קיימות רשומות עבור {{name}}",
    "monthly_on_going": "חודשי קבוע",
    "monthly_debt": "סה״כ חוב לתשלום חודשי",
    "one_time_debt": "סה״כ חוב לתשלום חד פעמי",
    "one_time_payment": "תשלום חד פעמי",
    "unknown": "תשלום לא ידוע",
    "paid_with": "שולם באמצעות",
    "tenantEmail": "אימייל",
    "tenantPhone": "טלפון",
    "monthlyFee": "תשלום חודשי",
    "tenant_list_title": "רשימת דיירים",
    "drag_and_drop_info": "גרור\/י לכאן לעדכון פרטי דייר",
    "updatedTenantList": "רשימת דיירים עודכנה בהצלחה",
    "notUpdatedTenantList": "שגיאה בהעלאת רשימת דיירים",
    "date_of_expense": "מועד תשלום",
    "vendor_name": "שם ספק",
    "expense_frequency": "תדירות תשלום",
    "print_tooltip": "לחץ\/י כאן להדפסת הטבלה",
    "excel_tooltip": "לחץ\/י כאן לייצוא הטבלה לאקסל",
    "csv_tooltip": "לחץ\/י כאן לייצוא הטבלה לפורמט csv",
    "hashavshevet_tooltip": "לחץ כאן כדי לייצא נתונים עבור חשבשבת",
    "balance_title": "מאזן ",
    "balance_total": "סך הכל הוצאות",
    "balance_total_bllink": "סך הכל בבלינק",
    "creditCards": "סך הכל בלינק - אשראי",
    "cheques": "סך הכל בשיקים",
    "wires": "סך הכל בהעברה בנקאית",
    "cash": "סך הכל במזומן",
    "legacyPayment": "סך הכל בתשלומים שתועדו ידנית",
    "bankCharge": "סך כל בלינק - בנק",
    "autoCheque": "סך הכל בבדיקות אוטומטיות",
    "tenantRecord": "סך הכל בדיווחי דיירים",
    "bit": "ביט",
    "applePay": "אפל פיי",
    "googlePay": "גוגל פיי",
    "other": "סך הכל בשיטות אחרות",
    "balance_total_cash": "סך הכל במזומן",
    "balance_total_cheque": "סך הכל בצ׳קים",
    "balance_total_bank": "סך הכל בה.בנק",
    "balance_income": "סה״כ הכנסות",
    "balance_expense": "סה״כ הוצאות",
    "balance_monthly_total": "סיכום חודשי",
    "not_paid_explanation": "מתוכנן לחיוב",
    "balance_money_in": "הכנסות",
    "balance_money_out": "הוצאות",
    "balance_diff": "ממתין לגבייה",
    "balance_diff_expenses": "ממתין לתשלום",
    "dateRangeSameYear": "תאריכי התחלה/סיום חייבים להיות באותה שנה",
    "show_all": "פתח",
    "hide": "מזער",
    "save": "שמור",
    "tenantsSaved": "מידע דיירים נשמר. ייקח כדקה למידע להתעדכן",
    "nothingToSave": "לא קיים מידע חדש לשמור",
    "fieldError": "{{field}} לא תקין",
    "deleteExpenseSuccess": "הוצאה נמחקה בהצלחה",
    "deleteExpenseConfirmText": "האם את\/ה בטוח שברצונך למחוק הוצאה?",
    "deleteExpenseRecurring": "מחק הוצאה ובטל את יתרת החודשים",
    "deleteOneMonthExpense": "מחק הוצאה  והשאר את תיעוד יתרת החודשים",
    "cancel": "ביטול",
    "partner_debt_list": "דו״ח חייבים כללי",
    "monthly_reports_at_partner_level": "דו״ח חיובים חודשי",
    "building_debt_list": "דו״ח חייבים לבניין",
    "one_time_comment": "פירוט חוב תשלום חד פעמי",
    "monthly_comment": "פירוט חודשי חוב",
    "mc_dashboard_title": "סטאטוס חברת <name>",
    "mc_dashboard": "סטאטוס חברה",
    "total_debt_title": "סה״כ חוב: ",
    "total_debt_sub_title_partner": "* לא כולל חובות מבניינים לא פעילים",
    "total_debt_title_tooltip": "לבניינים פעילים בלבד",
    "total_debt_sub_title_tooltip": "יהיה שווה לסה״כ חובות בסטאטוס מבט על עבור חד פעמי וחודשי מינוס חובות בניינים לא פעילים",
    "go_back": "חזרה לרשימת בניינים",
    "back_to_dashboard": "חזרה לסטאטוס מבט על",
    "total_charged": "שולם בפועל",
    "total_debt": "סה״כ חוב {{debt}}",
    "total_future": "תשלומים עתידיים",
    "futurePayment": "תשלום עתידי",
    "stoppedPayment": "תשלום נמחק",
    "partner_debt_title": "רשימת חייבים חברת ",
    "building_debt_title": "רשימת חייבים:",
    "building_debt": "רשימת חייבים",
    "refund": {
        "tooltip": "זיכוי מלא לעסקה. זמין רק בחודש ביצוע העסקה",
        "modalTitle": "אישור זיכוי עסקה",
        "modalConfirmText": "האם את\/ה בטוח\/ה שברצונך לבצע זיכוי?",
        "partialRefundText": "זיכוי חלקי לעסקה. חדש, נא לבדוק זיכוי קבלות",
        "refundNotificationSuccess": "זיכוי בוצע בהצלחה",
        "refundNotificationError": "שגיאה בזיכוי. אנא פנה למנהל. סיבת שגיאה {{reason}}",
        "btns": {
            "yes": "כן",
            "no": "לא"
        },
        "future": {
            "tooltip": "עצור\/י את כל החיובים העתידיים ובטלי את התוכנית",
            "modalTitle": "אישור ביטול תוכנית",
            "modalConfirmText": "האם את\/ה בטוח\/ה שברצונך לבטל את התוכנית?",
            "refundNotificationSuccess": "תשלום עתידי בוטל, דייר\/ת לא יחוייב כלל.",
            "refundNotificationError": "שגיאה בביטול תוכנית תשלומים"
        },
        "refund": "זיכוי",
        "chargeback": "הכחשת עסקה"
    },
    "delete": {
        "tooltip": "מחק\/י תיעוד תשלום",
        "modalTitle": "מחק\/י תיעוד תשלום",
        "notificationSuccess": "תיעוד תשלום נמחק בהצלחה",
        "notificationNoUpdates": "לא התווספו או נמחקו רשומות",
        "notificationFailure": "לא הצלחנו למחוק את תיעוד התשלום",
        "radioLabels": {
            "oneMonth": "מחק\/י תיעוד זה בלבד",
            "onePayment": "מחק\/י תשלום זה בלבד",
            "allPayments": "מחק\/י את כל התשלומים הקשורים לתוכנית זאת",
            "allNextPayments": "מחק את כל התשלומים החוזרים הבאים",
        },
        "btns": {
            "confirm": "אישור",
            "cancel": "ביטול"
        }
    },
    "apartment": {
        "monthly": "טבלה",
        "oneTime": "חד פעמי",
        "full": "רשימה",
        "year": "שנה",
        "tenantDetails": "פרטי דייר\/ת",
        "tenantsPage": "ביכולתך לערוך את פרטי הדייר\/ת בעמוד רשימת הדיירים",
        "paid": "שולם",
        "totalPaid": "סך הכל שולם",
        "fee": "סכום לתשלום",
        "diff": "נותר לתשלום"
    },
    "paymentRequestResults": {
        "fallBackSuccessEmail": "אימייל אל {{to}} נשלח בהצלחה",
        "fallBackSuccessSMS": "SMS אל {{to}} נשלח בהצלחה",
        "fallBackSuccessIVR": "הודעה קולית אל {{to}} נשלחה בהצלחה",
        "fallBackErrorEmail": "כשל שליחת דוא\"ל אל {{to}}",
        "fallBackErrorSMS": "כשל שליחת SMS אל {{to}}",
        "fallBackErrorIVR": "כשל שליחת הודעה קולית אל {{to}}",
        "errorNoEmail": "אימייל לא מסופק עבור {{to}}",
        "errorNoPhone": "לא מסופק הטלפון עבור {{to}}",
    },
    "paymentRequestPopUp": {
        "sendAll": "שלח בקשת תשלום",
        "selectAll": "בחר הכל",
        "selectAllExcludeOneMonth": "הכל מלבד החובות של החודש בלבד",
        "paymentTitle": "דרישת תשלום",
        "allInfoTitle": "פרטי דייר",
        "howToSendLabel": "כיצד תרצי לשלוח את דרישת התשלום",
        "sendToLabel": "שלח\/י אל",
        "recipientsSelect": "בחירת נמענים",
        "recipientType": "סוג נמענים",
        "requestMethods": "שלח בקשה באמצעות",
        "sendBuildingRequestLabel": "{{name}} שליחת בקשת תשלום",
        "tenantHelloText": "היי [שם דייר]",
        "buildingPaymentAddress": "הבניין שלך בכתובת [כתובת הבניין], [מספר דירה] מבקש ממך תשלום עבור ועד הבית",
        "buildingHelpInfo": "לצפייה בפרטים ואישור בקשת התשלום יש להיכנס לעמוד הבניין דרך הלינק",
        "importantNote": "חשוב לשים כי במידה והינך שוכר/ת, יש לוודא שהחודשים המסומנים לתשלום הם עד סיום החוזה בלבד",
        "onBehalf": "בשם [מנהל הבניין/חברת הניהול]",
        "problemWithLink": "*במידה ולא ניתן ללחוץ על הלינק, יש להשיב חזרה בהודעה וללחוץ על הלינק פעם נוספת",
        "currentMonthOnlyDebt": "חודש נוכחי רק חוב",
        "labels": {
            "sms": "ווטסאפ (מחשבון בלינק)",
            "sendEmail": "אימייל (מחשבון בלינק)",
            "sendIvr": "חייגן אוטומטי",
            "owner": "בעלים",
            "renter": "שוכר\/ת",
            "name": "שם",
            "phone": "טלפון",
            "email": "אימייל",
            "sendWa": "ווטסאפ (מהחשבון שלי)"
        },
        "btns": {
            "send": "שלח\/י",
            "saveSend": "שמור\/י ושלח\/י"
        },
        "thanksTitle": "תודה לך",
        "requestTitle": "דרישת התשלום נשלחה",
        "requestSuccess": "נשלח לדיירים נבחרים",
        "requestSuccessTo": "נשלח לדיירים שנבחרו מבניין הדירה {{apartmentNumber}} {{buildingID}}",
        "requestError": "משהו השתבש",
        "waText": "היי {{personName}}\n" +
            " הבניין שלך בכתובת {{buildingAddress}} דירה {{apartmentNumber}} מבקש ממך תשלום עבור ועד הבית\n" +
            " לצפייה בפרטים ואישור בקשת התשלום יש להיכנס לעמוד הבניין דרך הלינק\n" +
            " {{paymentLink}}\n" +
            " סה״כ תשלום שוטף פתוח: {{monthlyDebtIncludingCurrentMonth}} ש״ח\n" +
            " סה״כ תשלום מיוחד פתוח: {{oneTimeDebt}} ש״ח\n" +
            " חשוב לשים כי במידה והינך שוכר/ת, יש לוודא שהחודשים המסומנים לתשלום הם עד סיום החוזה בלבד\n" +
            " *במידה ולא ניתן ללחוץ על הלינק, יש להשיב חזרה בהודעה וללחוץ על הלינק פעם נוספת\n" +
            " בשם {{buildingManagerName}}",
        "sendWaNote": "מחייב חיבור ל-WA במחשב זה"
    },
    "debtListFilteringPopUp": {
        "title": "סינון דו״ח חייבים",
        "thisMontDebtsLabel": "הסר מדוח החובות עבור החודש הנוכחי",
    },
    "yearlyByMonths": {
        "mainTitle": "עידכוני סטאטוס תשלום ידני עבור דירה {{apartmentName}}, שנה {{year}}",
        "managerUpdateApartment": "דירה עדכון ידני",
        "monthlyFee": "סכום חודשי",
        "amountPaid": "סכום ששולם"
    },
    "transaction": {
        'tenant_record_approved': 'דיווח דייר אושר',
        'tenant_record_rejected': 'דייוח דייר בוטל',
        'tenant_record_rejected_and_redirect': 'דייוח דייר בוטל, ניתן לתעד את אמצעי התשלום',
        'tenant_record_transaction_failed': 'העסקה נכשלה',
    },
    "partnerMonthlyReportTitle": "דו״ח תשלומים חודשיים עבור",
    "monthly_cashflow_cheque_sub_title": "סה״כ בצ׳קים {{totalAmountInCheques}} {{currency}}",
    "yearly_by_one_time_title": "דו״ח שנתי תשלומים חד פעמיים",
    "cheque_deposit_tooltip": "לחץ\/י כאן להדפסת דו״ח צ׳קים להפקדה בבנק",
    "cheque_confirmation_id": "מספר צ׳ק {{confirmationID}}",
    "download_tooltip": "הורד pdf",
    "legacyPaymentsUpdated": "תשלום ידני נקבע בהצלחה, העמוד יתרענן עוד שנייה והמידע יופיע על המסך",
    "legacyPaymentsFailed": "שגיאה בקביעה תשלום ידני {{errors}}",
    "total_debt_col": "סה״כ חוב",
    "total_to_pay": "סה״כ",
    "payment_for": "תשלום עבור",
    "total": "סה״כ",
    "total_bank_transfers": "סה״כ הפקדות בנק",
    "4_last_digits": "4 ספרות",
    "percentage": "אחוז גבייה",
    "sendRequest": "דרישת תשלום",
    "confirm": "מחק",
    "setPayment": "קבע תשלום",
    "deleteExpense": "מחק",
    "payGiftPage_step2_tenantType_Lessee": "שוכר/ת",
    "payGiftPage_step2_tenantType_Owner": "בעלים",
    "tenantTypeUnknown": "",
    "expenses_monthly_title": "דו״ח הוצאות - חודש",
    "expenses_report_title": "דו״ח הוצאות",
    "expenses_monthly_subtitle": "סכום הוצאות עבור חודש זה",
    "payment_type": "סוג תשלום",
    "frequency": "תדירות תשלום",
    "confirmation_id": "מספר אסמכתא",
    "bank_reconsile": "התאמות בנק",
    "note": "הערה",
    "bllink_id": "אסמכתא בלינק",
    "download_files": "הורד/י קבצים",
    "file_not_found": "הקובץ לא נמצא",
    "edit_payment_details": "ערוך/י פרטי תשלום",
    "edit_expense_details": "ערוך/י פרטי הוצאה",
    "open_confirmation": "אישור תשלום",
    "send_confirmation": "שלח אישור",
    "add_payment_details": "הוסף פרטי תשלום",
    "delete_expense": "מחק הוצאה",
    "unpaid": "לא שולם",
    "oneTime": "חד פעמי",
    "recurring": "תשלום קבוע",
    "apt": "דירה",
    "chargeApt": "ביצוע תשלום",
    "paymentMethod": "אמצעי תשלום",
    "charge_date": "תאריך חיוב:",
    "charge_date_title": "תאריך חיוב",
    "deposit_date": "תאריך הפקדה:",
    "balance_report_title": "מאזן מצטבר",
    "opening_account": "סכום פתיחה (יתרת בנק)",
    "actual_balance": "יתרת בנק בתחילת חודש ",
    "building_tenant_records_list": "דיווחי דיירים בבניין",
    "auto_campaign": "קמפיין אוטומטי",
    "auto_campaign_instructions": "הורדת נוסח הודעות",
    "auto_campaign_settings": "הגדרות קמפיין אוטומטי",
    "mark_payment": "אישור/ביטול דיווח הדייר/ת",
    "record_different_months": "דחה/י תיעוד ועבוד לתיעוד מחדש",
    "add_meaning_of_payment": "הוספת אמצעי תשלום",
    "total_tenant_records_title": "סה״כ דווח כשולם ע״י הדייר:",
    "building_tenant_records_title": "דיווחי תשלום מדיירים",
    "tenant_report_marked_as_paid": "דוח דיירים סומן כשולם",
    "month_marked_as_paid": "חודש מסומן כשולם",
    "debtListActions": {
        "sendWarningLetter": "שליחת מכתב אזהרה",
        "sendRequest": "שליחת בקשת תשלום",
        "setPayment": "הוספת תשלום",
        "aptNotes": "הערות דירה",
        "downloadWarningLetter": "הורד מכתב אזהרה"
    },
    "debtListNew": {
        "selectApartment": "בחר דירה מהרשימה",
        "debtType": "סוג חוב",
        "debtTotal": "סך הכל"
    },
    "balance_report_subtitle": "בעמוד זה ניתן לעקוב אחר מאזן הוצאות והכנסות",
    "tenant_list_subtitle": "בעמוד זה ניתן לעדכן אנשי קשר",
    "total_apartments": "סך הכל דירות",
    "add_payment": "הוסף תשלום",
    "add_contact": "הוספת איש קשר",
    "edit_contact": "עריכת איש קשר",
    "delete_contact": "מחיקת איש קשר",
    "aptNotes": "הערות דירה",
    "delete_apartment": "מחק דירה",
    "apartment_deleted": "הדירה {{apartmentNum}} נמחקה",
    "total_contacts": "סה״כ אנשי קשר",
    "isPrimary": "איש קשר ראשי",
    "total_charged_balance": "הופקד בפועל",
    "total_charged_balance_expenses": "שולם בפועל",
    "set_expense_title": "מאזן מצטבר",
    "confirmation_title": "אישור הוצאה",
    "fast_search": "חיפוש מהיר",
    "debt_report_title": "דו״ח חייבים",
    "debt_report_subtitle": "בעמוד זה ניתן לעקוב אחרי דיירים חייבים",
    "total_debts": "סך חובות",
    "debt_type": "פרטי תשלום פתוח",
    "total_amount": "סכום לתשלום",
    "tenant_details": "פרטי דיירים",
    "debt_details": "פרטי חוב",
    "for": "עבור",
    "monthly_fee": "ועד חודשי",
    "special_payment": "מיוחד",
    "monthly_fee_and_spec_payment": "ועד חודשי ותשלום מיוחד",
    "stop_plans": {
        "action": "עצירת תשלום בבלינק",
        "action_tooltip": "עצור תשלומים עתידיים",
        "confirm": "לחצ/י לביטול חיובים עתידיים של תוכניות פעילות",
        "approve": "אישור",
        "success_notification": "תשלומים נבחרים נעצרו בהצלחה",
        "empty_selection_notification": "לא נבחר תשלום לעצירה",
        "no_payments": "לא נמצאו תשלומים להפסקה",
    },
    "edit_apartment_payments": {
        "stop_success": "תשלומים שנבחרו הופסקו בהצלחה",
        "charge_dates_success": "תאריכי החיוב של התשלומים שנבחרו עודכנו בהצלחה",
        "empty_selection": "לא נבחרו תשלומים להמשיך",
        "action": "עריכת תשלום",
        "title": "עריכת תשלום דירה {{apartmentNumber}}",
        "subtitle": "בחר את התשלומים לעריכה",
        "cancel_payments": "ביטול תשלום",
        "change_charge_date": "עדכון יום לחיוב תשלום",
        "items_selected": "תשלומים מסומנים",
        "no_payments": "לא נמצאו תשלומים זמינים",
    },
    "edit_check_payments": {
        "marked_as_deposited": "סמן כהופקד",
        "marked_as_deposited_success": "שיקים נבחרים סומנו כהופקד",
        "mark_as_manual": "העבר לפרעון ידני",
        "delete": "מחיקה",
        "delete_success": "שיקים נבחרים נמחקו",
    },
    "edit_wire_payments": {
        "marked_as_approved": "סמן כאושר",
        "delete_success": "העברות נבחרות נמחקו",
        "marked_as_wired_success": "ההעברות שנבחרו סומנו כמאושר",
    },
    "mark_primary": "סמן איש קשר כראשי",
    "mark_legal": "סמן איש קשר במצב משפטי",
    "mark_manager": "סמן איש קשר כמנהל",
    "filtering": "סינון דירות",
    "phone_is_unavailable": "אין מספר זמין",
    "collection_report": "דו״ח גבייה מפורט",
    "accounting_service": "חשבונית ירוקה morning",
    "maintenance_service": "שירות תחזוקה",
    "backoffice": "מערכת ריטול לאדמינים",
    "bank_deposit": "הפקדות בנק",
    "bllink_charges": "בלינק",
    "bllink_cheques": "שיקים",
    "bllink_bank_wire": "העברה בנקאית",
    "manual_cheques": "שיקים לפירעון ידני",
    "digital_cheuqes": "שיקים לפירעון דיגיטלי",
    "deposited_cheques": "שיקים שהופקדו",
    "createdAtDate": "נוצר ב {{date}}",
    "updatedAtDate": "עודכן ב {{date}}",
    "updatedBy": "עודכן על ידי {{updatedBy}}",
    "apt_num": "מספר דירה",
    "bank_code": "קוד בנק",
    "account": "חשבון",
    "check_number": "מספר שיק",
    "expire_date": "תאריך תפוגה",
    "card_type": "סוג כרטיס",
    "all_statuses": "כל הסטטוסים",
    "total_tenants": "סך כל הדיירים",
    "for_clarification": "תשלומים לבירור",
    "failed_transactions": "עסקאות שנכשלו",
    "update_credit_card": "עדכון פרטי אשראי",
    "wire_confirmation_num": "מספר אסמכתא",
    "reasons": {
        "MUST_BE_PENDING": "התשלום חייב להיות בהמתנה",
        "MUST_BE_OFFLINE": "התשלום חייב להיות במצב לא מקוון",
    },
    "yearly_collection_report": "דוח גבייה שנתי",
    "special": "מיוחד",
    "charged": "טעון",
    "debt": "חוֹב",
    "future": "עתיד",
    "collection_open_confirmation": "אישור תשלום",
    "collection_refund": "זיכוי עסקה",
    "collection_cancel": "ביטול תשלומים עתידיים",
    "expenses_deposit_date": "תאריך הפקדה",
    "credit_date": "תאריך זיכוי",
    "pdf": "PDF",
    "excel": "Excel",
    "csv": "CSV",
    "print": "Print",
    "send": "שלח דו\"ח",
    "sendReportModal": {
        "title": "שליחת דו״ח לועד הבית",
        "set_tenant_as_building_manager": "עדכן דייר כמנהל הבניין",
        "update_tenant_contact": "עדכן דייר כאיש קשר ראשי לדירה",
        "send_to_email": "שליחה לדוא״ל",
        "freeze_building_title": "לצורך סגירת בניין נדרש שם ועד הבית לצורך בירור במקרה של הכחשות עסקה עתידיות",
        "freeze_building": "אישור הקפאת בניין",
        "freeze_building_success": "בניין הוקפא בהצלחה"
    },
    "deposited_date_selection_modal": {
        "title": "בחירת תאריך ההפקדה",
    },
    "total_needed_tooltip": "סה״כ תעריפי דירות",
    "total_needed_tooltip_expenses": "סה״כ מתוכנן לתשלום",
    "total_charged_tooltip": "סה״כ הפקדות לבנק (כלל אמצעי התשלום)",
    "total_charged_tooltip_expenses": "סה״כ הוצאות בפועל (כלל אמצעי התשלום)",
    "balance_diff_tooltip": "תשלומים פתוחים",
    "balance_diff_tooltip_expenses": "ממתין לתשלום",
    "pdf_note": "רשימת הדיירים מצורפת לדו״ח זה",
    "special_debt": "חוב מיוחד",
    "details": "פרטים",
    "planned": "מתוכנן",
    "fileName": "שם קובץ",
    "fileType": "סוג קובץ",
    "uploadDate": "תאריך העלאה",
    "uploadedBy": "הועלה ע״י",
    "size": "גודל קובץ",
    "addFile": "הוספת מסמך",
    "ran_into_problem": "אופס... נתקלנו בבעיה",
    "uploaded_file_not_identified": "לא הצלחנו לזהות את הקובץ שהועלה. יכול להיות שהצוות שלנו יצטרך לבצע בדיקה לקובץ",
    "mark_transactions_manually": "עד אז... ניתן לסמן את העסקות שמוזנות בבלינק ולבצע התאמה ידנית",
    "understood": "הבנתי, תודה",
    "deposit": "הפקדות",
    "deductions": "קיזוזים",
    "wireAwaitingApproval": "ממתין לאישור",
    "wireApproved": "מאושר",
    "fileUploaded": "הקובץ שלך הועלה. אתה תופנה לכרטיסיית העדכון הידני כדי להמשיך",
    "totalOffsets": "סה״כ קיזוזים",
    "moreDetails": "פרטים נוספים",
    "charges": "חיובים",
    "awaitingApproval": "ממתין לאישור סופי. 4 ימים ממועד החיוב",
    "sentFor": "נשלח עבור",
    "nonRefundable": "לא ניתן לזכות עם אמצעי תשלום זה (או שעברה מעל חצי שנה מאז שחויב)",
    "producedByBllink": "מסמך זה הופק באמצעות מערכת בלינק.",
    "issueDate": "תאריך הפקה:",
    "noDataAvailable": "אין נתונים זמינים",
    "lastTurn": "פניה אחרונה",
    "messageType": "סוג הודעה",
    "nextFace": "פניה הבאה בעוד",
    "removeFromCampaign": "הסר מקמפיין",
    "addToCampaign": "הוסף לקמפיין",
    "markedApartments": "דירות מסומנות",
    "payments": "תשלומים",
    "tenants": "דיירים",
    "tenant": "דייר",
    "history": "היסטוריית תשלומים",
    "card": "כרטיס",
    "previousTenants": "דיירים קודמים",
    "mainTenants": "דיירים ראשיים",
    "managerNotes": "הערות מנהל",
    "bllinkNotes": "Bllink הערות",
    "downloadInvoice": "הורדת קבלה",
    "downloadInvoiceError": "לא ניתן להוריד קבלה",
    "refundPayment": "ביצוע זיכוי",
    "stopPlan": "ביטול תשלום עתידי",
    "deletePlan": "מחיקת תיעוד תשלום",
    "noPartnerIDError": "מזהה שותף לא מסופק",
    "installmentDeal": "עסקת תשלומים",
    "apartment_report_link": "קישור לדיווח על דירה",
    "status": {
        "awaiting_withdrawal": "ממתין למשיכה",
        "pending_approval": "ממתין לאישור משיכה",
        "approved": "הועבר לספק",
        "pulled_from_building": "נמשך",
        "failed": "נכשל",
    },
    "collection_total_tooltip": {
        "total_transferred": "סה״כ גבייה",
        "total_refunded": "סך הכל זיכויי עסקה",
        "building_commission": "עמלת בניין",
        "management_commission": "עמלת ח.ניהול",
        "revenue": "אחר",
        "deductions": "קיזוזים",
    },
    "tenant_labels": {
        "name": "שם:",
        "phone": "טלפון:",
        "email": "דוא״ל:"
    },
};
