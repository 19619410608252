export class ManagerMonitoringApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }


    /**
     * @param {string} buildingId
     * @return {Promise<Any>}
     * */
    async getState(buildingId) {
        return this.#client.get(`/managers/monitoring/${buildingId}/state`);
    }

    async removeFromCampaign(buildingId, apartmentNumbers){
        return this.#client.put(`/managers/monitoring/${buildingId}/stopped`, {apartmentNumbers});

    }
    async addToCampaign(buildingId, apartmentNumbers){
        // will set feature for apt to true in case it was false.
        // else will do nothing base on the assumption that the auto campaign is on for the entire building
        return this.#client.put(`/managers/monitoring/${buildingId}/started`, {apartmentNumbers});

    }
    async getPartnerText(partnerID, lang) {
        return this.#client.get(`/managers/monitoring/partnerID/${partnerID}/${lang}/text`);
    }

    async setPartnerText(partnerID, lang, isActive, code, text, updatedBy ) {
        return this.#client.put(`/managers/monitoring/partnerID/${partnerID}/text`,{ lang, isActive, code, text, updatedBy });
    }
}
