import {useEffect, useState} from 'react';

const EditableCell = ({
  value: initialValue,
  row: {index, original},
  column: { id },
  updateData,
}) => {
    const [value, setValue] = useState(initialValue);

    const onChange = e => {
        setValue(e.target.value);
    }

    const onBlur = () => {
        updateData(index, id, value);
    }

    useEffect(() => {
        setValue(initialValue)
    }, [initialValue]);

    const hasError = () => {
        return original.errors && original.errors[id]?.touched && !original.errors[id]?.valid;
    }

    return (
        <div exceltext={value} className={`editable-cell ${hasError() ? 'with-error' : ''}`}>
            <input
                className={`${hasError() ? 'invalid' : ''}`}
                value={value || ''}
                onChange={onChange}
                onBlur={onBlur}
            />
            {original.errors && original.errors[id]?.error && <p className='error-text'>{original.errors[id].error}</p>}
        </div>
    )
};

export default EditableCell;