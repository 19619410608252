export const adminReports = {
    "titles": {
        "renew": "דו״ח חידושי תשלום"
    },
    "generateBtn": "חידושי תשלום אונליין",
    "generateRenewOffline": "חידוש תשלומי אופליין",
    "success": "דו״ח יוצר בהצלחה, את/ה אמור/ה לקבל אותו לאימייל בדקות הקרובות(עד 10 דקות)",
    "rawReport": "דוח גולמי",
    "reportSent": "דו״ח נשלח",
};
