export const buildingPayment = {
    "welcome": "Welcome",
    "welcome_without_entrance": "Welcome to {{streetNumber}} {{street}}, {{city}}",
    "welcome_with_entrance": "Welcome to {{streetNumber}} {{street}} entrance {{entrance}}, {{city}}",
    "welcome_to": "Welcome to ",
    "payment_co_op": "Building Payments",
    "payment_to": "HOA Payment for ",
    "first_payment": "First payment",
    "installment_cost": "Installment cost",
    "perfectWayTreatYourself": "perfect way to treat yourself",
    "perfectWayTreatYourselfFlowers": "why not add a little color to your day?",
    "getTheCoupon": "Complete the purchase and get the coupon",
    "payment_does_not_take_credit": "Payment does not take credit line",
    "paymentUponPurchase": "Payment will be made from existing card upon purchase",
    "pizzaAddOn": "payment done!\n" +
        "let's add some flavour to your day :)",
    "ezCharger": "EzCharger for your building",
    "ezChargerDescription": "special offer for your building",
    "flowersAddOn": "payment done!\n" +
        "let's add some color to your day :)",
    "ezChargerHoverText": "EzCharger for your building",
    "ezChargerContinueToOffer": "open offer in a new tab",
    "pizzaDescription": "how does a tasty pizza sounds like? \n" +
        "large pizza only at ב- 51 ₪ " +
        "one simple click you can treat yourself with a 20% discount with the credit details you entered before",
    "flowersDescription": "מה דעתך לקבל פרחים יפים ישירות לבית שלך?\n" +
        "זר מושלם ומלא בצבע רק ב- 64 ₪\n" +
        "\n" +
        "בלחיצה אחת פשוטה, תוכל לפנק את עצמך או את האהובים עליך בפרחים ב- 20% הנחה, עם פרטי כרטיס האשראי שביצעת איתם את התשלום.",
    "first_charge_one_payment_only": "Total to charge",
    "payment_services": "Payment services",
    "payment_services_via_tranzila": "Secure payment services via Tranzila LTD",
    "tourist_comment": "Payment with a tourist card, american express or diners will charge additional commission that will be charges separately in the next few days",
    "confirmation_email": "Confirmation also sent via email",
    "empty": "",
    "address_without_entrance": "{{street}} {{streetNumber}}, {{apartmentText}} {{city}}",
    "address_with_entrance": "{{street}} {{streetNumber}} כניסה {{entrance}}, {{apartmentText}} {{city}}",
    "apartmentText": "Apartment {{apartmentNumber}}",
    "updates": "Updates for building tenants",
    "payment_method": "Payment Method",
    "managers": "Building managers",
    "makePayment": "Pay",
    "fill_in_details_secondary_title": "Fill in your personal and apartment details ",
    "details_of_payer": "Payer Details",
    "number_of_apartment": "Apartment number",
    "full_name": "Full name",
    "type_of_tenant": "Tenant Type",
    "right_tenant_check": "Not you?",
    "renter": "Tenant",
    "owner": "Owner",
    "phone": "Cellphone",
    "email": "Email",
    "comment": "Comment",
    "agree_to_ads": "Agree to get mail from bllink.co",
    "accept_website_tos": "By signing here, I agree to ",
    "button_agree": "By clicking this button, I agree",
    "tos": "the Terms of service",
    "next": "Next",
    "update_personal_details": "Update personal details",
    "pay_debt": "Pay for past months",
    "pay_on_going": "Co-op payment",
    "pay_one_time": "Single payments",
    "pay_expense": "Expense payment",
    "debt_title": "Unpaid months",
    "debt_sub_title": "A debt in the amount of {{amount}} {{currency}} was accumulated for your apartment",
    "on_going_title": "Monthly payment",
    /** @deprecated use currency.ils instead  */ "ils": "₪",
    /** @deprecated use currency.mxn instead  */ "mxn": "$",
    /** @deprecated use currency.gbp instead  */ "gbp": "£",
    /** @deprecated use currency.eur instead  */ "eur": "€",
    "total_to_pay": "Total",
    "first_charge": "Total first payment",
    "number_of_installments": "Number of Installments",
    "first_payment_amount": 'First Payment Amount',
    "total_payment_amount": 'Total Payment Amount',
    "payment_details": "Payment Details",
    "monthly_payment": "Monthly total",
    "months_selected": "Months",
    "month_selected": "Month",
    "future_payments_by_months": "How much will my monthly fee be?",
    "future_payments_sub_title": "Monthly payment details.",
    "future_payments_additional_title": "Please note that payments via Blink do not take up your credit limit",
    "automatic_billing_note": "Your payment plan will automatically renew if we do not receive your response to renew via text message",
    "pay_with_bank_permission": "Pay with bank account",
    "pay_credit_card": "Pay with credit card",
    "everything_is_paid": "Everything is paid for",
    "confirmation_title": "Payment confirmation",
    "4_last_digits": "4 last digits",
    "payment_for": "Payment for",
    "card_type": "Card",
    "charge_card": "Pay",
    "transaction_date": "Transaction date",
    "no_confirmation_yet": "No confirmation yet",
    "proceed_to_checkout": "Proceed to checkout",
    "back": "Back",
    "harshaaFileName": "accountPermissions.pdf",
    "call_us_for_bank_allow": "Call us to setup bank charge. 03-5090045",
    "call_us_for_bank_allow_title": "Bank setup in 5 minutes",
    "confirmation_id": "Confirmation",
    "dont_accept_america_express_cards": "American express? Unfortunately American express is the only card that we don't accept currently. Please try with a different card.",
    "payment_error": "Payment error",
    "incorrect_cvv": "Please check the 3 digit at the back of the card CVV",
    "cart_empty_title": "Cart is empty",
    "cart_empty_text": "You didn't select an item to pay, you can't progress to the next screen. Please select at least one item",
    "offline_payment_amounts_are_wrong": "Payments amount is incorrect",
    "offline_payment_amounts_are_wrong_text": "Payments set are {{amountsSent}} but required amount is {{totalToPay}}",
    "commissionTextForTenantInPercentage": "Payment includes a commission percent {{commissionPercentage}}%",
    "commissionTextForTenantInInDollars": "Total commission for payment is {{commissionInDollars}} {{currency}}. Payment with a tourist card will charge additional commission separately in the next few days",
    "confirmationNumbersText": "Transaction references",
    "buildingPageExplain-1": "Great to see you",
    "buildingPageExplain-2": "Click pay and move on to smart payment",
    "sub_title_before_payment": "Please go over the details before paying",
    "record_expenses": "Record expenses ",
    "renewal_for": "Renewal for ",
    "renewal_title": "Renewal form",
    "renew_months_forward": "{{numberOfMonths}} months",
    "renew_option": "Please renew for",
    "dont_renew_option": "Don't renew because",
    "bllink_bank_charge": "Bllink",
    "update_credit_card": "Update credit card ",
    "failed_payment_title": "Update credit card page",
    "renew_send": "Send",
    "already_recorded_response_for_renewal": "We already recorded your response",
    "expense_edit": "Edit expenses ",
    "setup_using_this_flow_1": "The whole process takes about 5 minutes. It is recommended to use a computer and not a mobile phone",
    "setup_using_this_flow_2": "You can use ",
    "setup_using_this_flow_3": "The Account Authorization Setup Guide",
    "setup_using_this_flow_4": "Please note that for setting up the authorization there is a one-time bank fee of 15 NIS",
    "setup_auto_charge": "You can also set up an account billing authorization simply via Blinky",
    "future_payment_date": "Future payment date",
    "address": "Address",
    "month": "For month:",
    "year": "Year",
    "canceled_deal": "Transaction canceled",
    "payment_have_commission": "The payment shown includes a clearing fee",
    "payment_confirmation": "Payment made successfully, you will be taken to the payment confirmation page in a few seconds",
    "here": "Here",
    "login_tenant": "login as a tenant",
    "already_logged_in_as_tenant": "You are already logged in as a tenant",
    "cant_pay_with_credit_card": "Oops ... you can not make a payment for these months by credit card",
    "cant_pay_with_credit_card_text": "It is not possible to pay with a credit card for the current cart. For payment by credit card, please cancel payment marking up to monthly",
    "tenant_details_missing_title": "Tenant details missing",
    "tenant_details_missing_text": "Missing tenant details. Please return to the 'Personal Privacy Update' screen and fill in your details",
    "dont_renew_moved": "Moved from apartment",
    "dont_renew_call_me": "Interested in contacting me",
    "dont_renew_paid_via_cheque": "I've already paid via check",
    "dont_renew_other": "Another reason",
    "already_updated_this_failed_payment": "Credit card information for this payment has already been updated",
    "payment_plan_not_active_pay_at_building_page": "Link is no longer active. You can pay for debt through the building pillar",
    "submitted_renew_response_successfully": "Excellent, the monthly payment according to the number of months selected. The confirmation will arrive in the email in the next few minutes.",
    "submitted_dont_renew_response_successfully": "We have received your reply. Thank you",
    "cart_has_been_payed_already": "Payment has already been made for this cart, you will be taken to the payment confirmation",
    "tzla_script_load_failed": "Unable initialize credit card form, try later or other device or browser",
    "select_placeholder": "Select...",
    "open_new_building": "open new building",
    "choose_action": "Choose your action",
    "proceed_to_payment": "Payments",
    "proceed_to_tasks": "Maintenance",
    "additional_services": "services and professionals",
    "contact_manager": "Contact Support",
    "installments_available": "Installments available at the checkout page",
    "fifty_percent_mortgage": "Up to 50% savings on mortgage insurance",
    "contact_soon": "great! one of our representatives will contact you soon.",
    "ask_for_offer": "Ask for offer",
    "contact_manager_subheader": "Building Manager Contact Details",
    "start_chat": "Start chat",
    "manager_name": "Building manager name",
    "manager_phone": "Building manager phone",
    "manager_email": "Building manager email",
    "set_as_building_manager": "I am the house committee of the building",
    "how_to_pay": "How would you like to pay?",
    "bank": "Bank",
    "bit": "Bit payment",
    "cheque": "Check",
    "applePay": "Apply pay payment",
    "googlePay": "Google pay payment",
    "fill_details": "Fill in details for payment confirmation",
    "mail": "Email",
    "terms_of_use": "I approve",
    "confirm_mail": "I approve receiving ads from Blink",
    "please_fill": "Please fill all the required fields",
    "payment_will_renew": "Recognize a paid payment? Mark/Check the payment and report as Paid",
    "debt_amount": "Unpaid Debt",
    "monthly_fee": "Monthly Rate",
    "selected_months": "Selected months",
    "click_to_view": "Click to view tenants' payments",
    "show_owner_payments": "Show special unpaid dues",
    "show_renter_payments": "Show renter payments",
    "months_details": "Choose Months",
    "special_payment": "Special Payment",
    "special_details": "Special details",
    "debt_amount_past": "Payments Due (Previous years)",
    "current_year_amount": "Current Year Payment",
    "selected_payments": "Selected Payments",
    "apply": "Confirm",
    "mark_as_paid": "Mark as paid",
    "success": "Success",
    "no_payments_selected": "No payments were selected",
    "on_going": "Ongoing Payments",
    "past": "Unpaid Debt",
    "no_open_payments": "There are no open payments",
    "select_date": "Fill in details and select date to schedule payment collection",
    "checkout_info": "Installments do not account for Credit Limit",
    "bit_info": "To receive the payment request, click the pay with bit button and then enter a phone number / scan the QR Code (for web users)/ Click the button to switch to the Bit application (for mobile users)",
    "applePay_info": "For Apple Pay payment, click the button. Payment with Apple Pay requires using an Apple device and Safari browser.",
    "googlePay_info": "For Google Pay payment, click the button. Google Pay cannot be used for purchasing via Apple products.",
    "applePayLabel": "Building Payments",
    "payment_page_explain": "Notice you can remove months to pay for in case the payment is not up to date",
    "payment_page": "Payment Page",
    "payment_plan_was_not_created": "Payment plan wasn't created",
    "debts": "Debts",
    "onGoing": "Ongoing",
    "show_details": "Show details",
    "details": "Details",
    "total": "Total",
    "distribution_cost": "Cost of payments distribution",
    "first_date": "First Payment Date",
    "fixed_day": "Fixed Payment Day",
    "payment_note": "A fee of 1.4% will be added to the payment. Payment with a foreign (tourist) card will include an additional fee that will be charged separately in the coming days",
    "digital_signature": "Digital signature",
    "credit_card": "Credit card",
    "notice": "Notice",
    "notice_info": "The Bank Requires 10 business days to approve payment request. Due to bank requirements, Current payment will not occur immediately and will be added to next month's payment",
    "spread_payment": "Want to use installments to pay for outstanding debt or a special payment?",
    "when_deploy_payments": "When can I use installments?",
    "divide_payments_info": "Usually a debt payment or a special payment can be paid in only 1 payment. Using Bllink, the amount can be divided into equal payments allowing you total flexibility.",
    "how_to_divide": "How are the installments impemented?",
    "division_info": "Soon we will launch the option to perform the installments directly from the system. Until then... our representatives are available to make the installments for you over the phone.",
    "contact_info": "Contact information",
    "bllink_customer_support": "Bllink Support",
    "back_to_payments": "Back to payments",
    "contact_representative": "Contact a representative",
    "commissionOn": "No Credit Limit",
    "noCommission": "Free and without credit limit",
    "directTransferPayment": "Payment by direct transfer to the account",
    "chooseBankPayment": "You must choose the form of payment through the bank",
    "directPayment": "Direct payment",
    "digitalPermission": "Digital permission",
    "amountToTransfer": "Amount to be transferred",
    "verificationCode": "Verification code",
    "transferNote": "(To be added as a note or as a reason for transfer)",
    "paymentConfirmationWillBeSent": "*Payment confirmation will be sent after confirmation of the verification code attached to the transfer note",
    "copyCode": "Copy code",
    "bankAccountDetails": "Bank account information",
    "byOrderBllink": "By order - Bllink Systems Ltd",
    "copied": "Copied to clipboard!",
    "installmentDisabled": "It is not possible to split installments with this payment method",
    "loginToPatExistingCard": "Login to pay with existing card",
    "paymentThroughBank": "Payment through the bank",
    "howToMakePayment": "How do you make a payment by bank transfer?",
    "directTransfer": "Direct bank transfer",
    "digitalAuth": "Establishing a digital authorization",
    "fillDetailsForTransfer": "After filling in the details, the account details for the transfer will be displayed, it is required to attach the verification code shown to the note/reason for transfer",
    "confirmationOrPending": "If no confirmation is attached, the payment will not be verified and will be displayed as pending payment",
    "establishingAuth": "Establishing a digital authorization",
    "fastSignProcess": "A fast digital signature process for the payment directly from the bank account",
    "tenDaysToVerify": "The first payment is required for 10 business days to verify the details",
    "contextLost": "Context lost. Navigating to the first step",
    "contextLostAtAll": "Context lost. Please go the first step",
    "savingMoney": "Saving you money every month!",
    "insuranceStillInBank": "Seriously?! <br> Is your mortgage insurance still through the bank?",
    "insuranceSavings": "Savings of up to 50% on mortgage insurance",
    "lowerInsurance": "Mortgage insurance through the bank is the most expensive, let us lower your costs",
    "weSavedUpTo": "Till now, we have saved our customers an average of 250₪ per month, wouldn't you like that too?",
    "ourCustomersChoose": "Out of 10 customers of Bllink 8 have already taken out mortgage insurance in GUR, how about you?",
    "checkHowMuchCanBeSaved": "Click and check how much can be saved for you",
    "scheduleCall": "To schedule a call",
    "continueToConfirmPayment": "Continue to confirm a payment",
    "representativeWillContactYou": "Our representative will contact you soon",
    "proceed_to_payment_action": "Proceed to Payment",
    "payment_success": "Payment was made successfully",
    "mortgage_insurance_confirmation": {
        "title": 'Save on mortgage insurance',
        "description": 'Just before a representative gets back to you with an offer',
        "request": 'Please confirm the request',
        "confirm": 'Approval',
        "cancel": 'No thanks',
    },
    "flowers":{
        "initialPrice": "price: 100₪",
        "afterDiscount": "price after 20% discount: 80₪",
        "notice": "Doorstep delivery included in the price",
    },
    "additional_product_confirmation": {
        "title": 'one click pizza',
        "titleFlowers": 'A delightful plant with doorstep delivery',
        "description": 'before sending the coupon',
        "descriptionFlowers": 'one more step to a colorful day',
        "request": 'please approve the request',
        "confirm": 'confirm',
        "cancel": 'no thanks',
    },
    "fullPaymentHistory": "full payment history",
    "openInApp": "Open in App",
    "paymentOptions": "Payment options",
};
