export const errors = {
    "notFound": {
        "404": "404 לא נמצא",
        "pageNotFound": "הדף המבוקש לא נמצא",
    },
    "errorFallback": {
        "title": "אופס, יש לנו עיכוב קטן",
        "subtitle": "התקלה דווחה ואנחנו על זה",
        "tryAgain": "נסה שוב",
        "detailsTitle": "פרטי התקלה",
    },
    "tenant": {
        "payments": {
            "OnGoingPaymentTimeError": "תשלום עבור ועד בית חייב להתחיל מהחודש הקרוב",
            "PaymentsInThePastError": "תשלום לא הוגדר כראוי, אנא נסה\/י לחזור למסך פרטים אישיים ולהתחיל מחדש. אנו מצטערים על העיכוב הקצר",
            "PartPayIsNotAllowedForTenant": "תשלום חלקי אינו מורשה עבור דייר",
            "PartPayIsNotAllowedForManager": "תשלום חלקי אינו מורשה עבור מנהל",
            "ExistingPaymentPlanRunning": "קיים חיוב חודשי קבוע",
            "AlreadyPayedError": "בוצע תשלום עבור התשלומים שנבחרו",
            "DebtTooManyMonthsError": "מספר תשלומים עבור חודשים שעברו גדול מידי",
            "FailedTokenHasNotFailed": "תשלום כבר עודכן בהצלחה. אין צורך בפעולות נוספות",
            "NumberOfInstallmentIsIllegal": "מספר תשלומים שהוזן לא חוקי, אני נסה\/י שוב",
            "NoNewPlansWereCreated": "לא נוצרו תשלומים. אנא וודא\/י שנבחר מספר תשלומים כרצוי.",
            "RenewalInProgress": "חידוש תשלום כבר נמצא בתהליך"
        },
        "search": {
            "BuildingNotFound": "בניין {{buildingName}}  לא נמצא במערכת. אנא גש\/י לעמוד הראשי וחפש\/י לפי כתובת הבניין. app.bllink.co",
            "SearchAddressIsNotCompleted": "לא בחרת בניין ספציפי. אנא מלא כתובת מלאה כולל מספר בניין",
            "ApartmentNotFound": "מספר דירה {{apartmentNumber}} לא נמצא"
        }
    },
    "buildings": {
        "BuildingIsDisabled": "בניין לא קיים או הוקפא זמנית",
        "ManagerNotAllowedToViewBuilding": "מנהל\/ת לא מורשה",
        "BuildingIsNotActive": "בניין עדיין לא פעיל. אנא פנה לתמיכה במייל support@bllink.co או בטלפון 03-5090045",
        "NotFullAddress": "כתובת לא מלאה. אנא ודא כתובת מדוייקת כולל מספר בניין",
        "card_company_reject": 'תשלום נדחה על ידי חברת האשראי'
    },
    "managers": {
        "NewOneTimeMissingTitle": "טופס תשלום חד פעמי לא כולל שם התשלום. אנא השלמ\/י בשורה הראשונה",
        "NewOneTimeMissingNumberOfInstallments": "טופס תשלום חד פעמי לא כולל מספר תשלומים מקסימאלי. אנא השלמ\/י בשורה השנייה",
        "NewOneTimeDidntAddAnyNewApartmentPayments": "טופס תשלום חד פעמי הועלה אבל לא התווספו שום תשלומים לדירות. אנא הוספ\/י שורות דירות עם סכומים או פנה לתמיכה",
        "ExternalSumDoesNotEqualTotalSum": " סכום שתועד אינו תואם במערכת. תועד {{externalAmountSent}} אבל הסכום במערכת עבור הפריטים שנבחרו הוא {{totalSumSelectedToPay}}",
        "clearedCachedForBuildingSuccess": "רועננו נתונים עבור בניין {{buildingID}}. אם בניין קשור לחברת ניהול מסויימת, אז רועננו הנתונים גם עבורה.",
        "NoReceiptsForThisPartner": "לא קיימות קבלות עבור חברת ניהול זאת בעקבות תשלומים ישירים.",
        "NotTimeToChargeYet": "לא ניתן לחייב עסקה עתידית כרגע. לתשלום עתידי יש למלא מחדש את פרטי האשראי בעמוד תשלום",
        "UserNotFound": "משתמש {{userID}} לא נמצא",
        "NoAmountOrApartmentNumberFilled": "שגיאה בקריאת הנתונים. אנא בדוק\/י שכל שורות הדירות מלאות עם מספר דירה וסכום",
        "TerminalIsNotSupportedForRefund": "עסקה זאת בוצעה במסוף אשר עדיין לא נתמך עבור זיכויים",
        "SchemaValidation": "בעיה בשליחת קובץ, שגיאה מהשרת {{message}}",
        "BankDetailsAlreadyExistsForBuilding": "פרטי בנק כבר קיימים עבור הבניין"
    },
    "general": {
        "generalError": "שגיאה באתר. אנא נסה\/י שוב",
        "networkError": "שגיאה בהתחברות אנא בדוק\/י חיבור אינטרנט",
        "uploadError": "תמונה או מסמך גדולים מידי. אנא בחר\/י גודל קטן מ-2 מגה בייט",
        "timeout": "בקשה לקחה יותר מידי זמן. אנא נסי שוב"
    },
    "clientError": {
        "UNEXPECTED_ERROR": "שגיאה בלתי צפויה",
        "REQUEST_VALIDATION_ERROR": "שגיאת אימות בקשה",
        "ACCESS_FORBIDDEN": "כניסה אסורה",
        "APARTMENT_NO_OWNER": "לא הוגדרו פרטי קשר לבעלים לצורך שליחת הודעה",
        "APARTMENT_NO_RENTER": "לא הוגדרו פרטי קשר לשוכרים לצורך שליחת הודעה",
        "CAMPAIGN_ALREADY_RUNNING": "אין אפשרות להתחיל קמפיין. יש לסיים קמפיין קיים",
        "TerminalIsNotSupportedForRefund": "המסוף {{terminalName}} לא נתמך בבלינק",
        "NoAmountOrApartmentNumberFilled": "מספר דירה או סכום חסרים משורה מספר {{index}}",
        "UNKNOWN_ENTITY_TYPE": "לא ידוע סוג יישות {{entityType}}",
        "ENTITY_NOT_FOUND": "ישות {{entityType}}:{{entityId}} לא נמצאה",
        "COMMENT_NOT_FOUND": "הערה {{commentId}} לא נמצאה",
        "COMMENT_UPDATE_DISALLOWED": "רק יוצר\/ת ההערה רשאי\/ת לעדכן הערה",
        "NO_PDF_MAP_FOUND": "לא נמצא קובץ מתאים",
        "CAMPAIGN_NOT_FOUND": "לא נמצא קמפייין {{campaignId}}",
        "CAMPAIGN_NOT_STARTED_YET": "קמפיין {{campaignId}} לא התחיל עדיין",
        "CAMPAIGN_FINISHED": "קמפיין {{campaignId}} כבר הסתיים",
        "CAMPAIGN_NOT_ACTIVE": "קמפיין {{campaignId}} לא פעיל",
        "CAMPAIGN_PRIZE_ALREADY_REQUESTED": "פרס כבר נתבקש",
        "CAMPAIGN_PRIZE_NOT_FOUND": "פרס {{campaignPrizeId}} לא נמצא או לא משתתף בקמפיין",
        "CAMPAIGN_LIMIT_REACHED": "קמפיין {{campaignId}} סכום נקודות מקסימאלי הגיע",
        "COINS_NOT_ENOUGH": "אין לך מספיק נקודות",
        "DATA_ROW_VALIDATION_ERROR": "נתונים שגויים בשורה {{row}}. ייבוא הנתונים הופסק",
        "VALIDATE_BODY_ERROR": "בקשה לא תקינה",
        "VALIDATE_QUERY_ERROR": "בקשה לא תקינה",
        "GENERAL_BAD_PARAMS": "פרמטרים לא תקינים נשלחו {{message}}",
        "NO_PAYMENTS_SENT": "לא נשלחו שום תשלומים בבקשה שנשלחה",
        "MissingDescriptionTitle": "שם חובה עבור תשלום חד פעמי. אנא מלא\/י",
        "DUPLICATE_ONE_TIME": "לא ניתן להוסיף תשלום חד פעמי עם אותו שם לאותה דירה, אנא בחר\/י שם אחר",
        "NO_ACTIVE_BUILDINGS": "לא נמצאו בניינים פעילים",
        "PHONE_PARAM_REQUIRED": "נדרש מספר טלפון",
        "PHONE_NOT_MATCH": "מספר טלפון שהוזן לא תואם את מספר הטלפון בדירה",
        "PARTNER_MAPPING_MISMATCH": "אין מספיק נתונים כדי לבצע ייצוא. חסרים נתוני מיפוי לכל הדירות ברשימה",
        "NOT_ENOUGH_TO_REFUND": "לא ניתן להחזיר. אין מספיק כסף פנוי. ({{refundAmount}} > {{availableAmount}})",
        "TEMP_ADMIN_ONLY": "תכונה זו זמינה זמנית רק למנהלי מערכת",
        "APARTMENT_NOT_FOUND": "דירה לא נמצאה",
        "APARTMENT_ALREADY_INACTIVATED": "דירה {{apartmentNum}} בבניין {{buildingId}} כבר מושבתת",
        "APARTMENT_HAS_PAYMENTS": "לדירה {{apartmentNum}} בבניין {{buildingId}} יש תשלומים",
        "NO_PENDING_PAYMENTS_TO_DELETE": "לא הצליחו למצוא תשלומים ממתינים למחיקה",
        "MAX_INSTALLMENTS_FOR_NEW_ONE_TIME": "מספר תשלומים {{num}} גדול מהמותר",
        "NO_MAINTENANCE_CONFIGURED": "תכונת התחזוקה אינה מוגדרת",
        "BUILDING_STILL_NOT_ACTIVE_IN_PTOP": "בניין עדיין לא פעיל. בדוק/י אם חסרים פרטי בנק",
        "CANT_PARSE_BANK_FILE": "לא ניתן לנתח קובץ",
        "NO_PLAN_WERE_CREATED": "לא נוצרה תוכנית. בדוק האם נשלחו פריטים לחיוב.",
        "UNEXPECTED_EXEL_FILE_FORMAT": "לקובץ האקסל ששלחת יש פורמט לא צפוי",
        "FILE_TOO_BIG": "קובץ גדול מדי",
        "BUILDING_IS_DISABLED": "בניין לא קיים או הוקפא זמנית",
        "SMS_LIMIT_QUOTA" : "כמות הסמס שנשלחה גדולה מהמכסה",
        "USER_NOT_FOUND" : "משתמש לא קיים. לא ניתן להתחבר. אנא צור קשר עם תמיכה לפתיחת משתמש למנהלי בניין",
        "CantLoginToLivy" : "לא יכול להתחבר לבניין {{buildingID}} {{externalLivyId}} בדוק שהבניין פעיל",
        "APARTMENT_IN_LEGAL_STATE" : "דירה תחת מצב משפטי, אין אפשרות לשלם עבור דירה זאת. אנא צור קשר עם מנהל הבניין.",
        "SchemaValidation" : "אחד מהשדות שנשלח לא חוקי",
        "CANT_ADD_MESSAGE_WHILE_AI_IS_THINKING" : "עדיין חושב על תשובה. אי אפשר לכתוב בינתיים",
        "DEMO_USER_CREATED" : "זיהינו כניסה ראשונה למערכת. אנחנו יוצרים עבורך משתמש ומחברים אותך לחשבון דמו. בעוד מספר שניות תועבר לעמוד הבניין",
        "BUILDING_NOT_FOUND": "בניין לא נמצא",
        "TOO_MANY_AI_QUESTIONS": "להמשך שיחה עם הבוט, אנא הירשמו לשירות שלנו כועד בית או חברת ניהול",
        "CANT_UPDATE_RATES_DUE_TO_BUILDING_100_PERCENT_TRACK": "לא ניתן לעדכן תעריפים בבניין אשר נמצא במסלול גבייה של 100%",
        "SITE_IS_TEMPORARILY_DOWN": "אנחנו בעדכון מערכות. נחזור לפעולה עוד 30 דקות",
        "noPermissions": "אין הרשאות מספקות לביצוע פעולה זו",
    }
};
