import {useEffect, useState, useMemo} from "react";
import {useSelector, useDispatch} from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./header";
import Footer from "./footer";
import FooterNew from './FooterNew';
import ScrollToTop from "./scrollToTop";
import {useTranslation} from "react-i18next";
import { OfflineManagerRoutes } from './routes/managers/offlineManagerPayments';
import { TenantPaymentsRoutes } from './routes/tenants/tenantPayments';
import { ExpensesRecord } from './routes/managers/expenses/expensesRecordRoutes';
import { ManagerReports } from './routes/managers/reports/managerReports';
import { PartnerReports } from './routes/managers/reports/partnerReports';
import { CreateBuildingRoutes } from './routes/managers/createBuilding/createBuildingRoutes';
import { AutoCampaign } from "./routes/managers/autoCampaign/autoCampaign";
import { MessagePlatform } from "./routes/managers/messagePlatform/messagePlatform";
import { Monitoring } from "./routes/managers/monitoring/monitoring";
import { GeneralRoutes } from './routes/generalRoutes';
import { ManagerGeneral } from './routes/managers/generalManagers';
import { LoginRoutes } from './routes/login/login';
import { OperationRoutes } from './routes/operations/operationRoutes';
import { AdminRoutes } from './routes/admin/adminRoutes';
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";
import PopUp from "./simple/popUp";
import ManagerPage from '../managers/managerPage/ManagerPage';
import AdminPagesWrapper from "../../admin/components/AdminPagesWrapper/AdminPagesWrapper";
import {NotFound} from "./NotFound";
import CatchAllRoute from "../ui/builderIO";

import './utils/globalFunctions';
import {setLanguage, setLtr} from '../store/common/slice';

const allRoutes = TenantPaymentsRoutes
    .concat(CreateBuildingRoutes)
    .concat(LoginRoutes)
    .concat(OperationRoutes)
    .concat(GeneralRoutes)
    // .concat(AdminRoutes)

const sidebarRoutes = ManagerGeneral
    .concat(ManagerReports)
    .concat(ExpensesRecord)
    .concat(OfflineManagerRoutes)
    .concat(PartnerReports)
    .concat(AutoCampaign)
    .concat(MessagePlatform)
    .concat(Monitoring)

export default function Routes() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isFullScreen = useSelector((state) => state.common.fullScreen);
    const currentBuildingData = useSelector(state => state.buildings.currentBuildingData);

    const [buildingAddress, setBuildingAddress] = useState();

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        document.body.classList.remove('paid_payment_body'); // will hide the happy family background
        // Url.reportPageToGoogleAnalytics(
        //     window.location.pathname,
        //     'direct_page_load'
        // );
    });

    const language = i18n.language;

    useEffect(() => {
        const isLtr = language !== 'he';
        if (isLtr) document.body.classList.add('leftToRight');
        else document.body.classList.remove('leftToRight');
        dispatch(setLtr(isLtr));
        dispatch(setLanguage(language));
    }, [language]);

    useMemo(() => {
        let address = '';
        if (currentBuildingData != null && currentBuildingData.address != null) {
            const a = currentBuildingData.address;
            i18n.language === 'he' ? address = a.full_address : address = `${a.countryEN}, ${a.cityEN}, ${a.streetEN} ${a.streetNumber}`;
        }
        setBuildingAddress(address);
    }, [currentBuildingData])

    return (
        <HelmetProvider>
            <Router>
                <Helmet>
                    <title>{t('titles.home')}</title>
                    <meta name="description" content={t('titles.home') + t("titles.page_description")}/>
                </Helmet>
                <ScrollToTop>
                    <Switch>
                        {allRoutes.map((entry, index) => {
                            return (
                                <Route exact={entry.exact} key={index} path={entry.path} render={(props) => {
                                    return (
                                        <>
                                            <div id="main-content-div" className={`main-content ${isFullScreen ? 'full-screen' : ''}`}>
                                                {!entry?.removeHeader && (
                                                    <Header buildingID={props.match.params.buildingID} removeCallBtn={entry?.removeCallBtn} addLogo={entry?.addLogo}/>
                                                )}
                                                <PopUp/>
                                                {/* A <Switch> looks through its children <Route>s and
                                           renders the first one that matches the current URL. */}
                                                <entry.component {...{...props, ...(entry.props ?? {})}} address={buildingAddress} />
                                            </div>
                                            {entry.newFlowFooter ? <FooterNew forceFooter ={entry.forceFooter}/> : entry.noFooter ? <></> : <Footer/>}
                                        </>
                                    )
                                }} />
                            );
                        })}

                        {sidebarRoutes.map((entry, index) => {
                            return (
                                <Route exact={entry.exact} key={index} path={entry.path} render={(props) => {
                                    return (
                                        <ManagerPage match={props.match}>
                                            <entry.component {...{...props, ...(entry.props ?? {})}} address={buildingAddress}/>
                                        </ManagerPage>
                                    )
                                }}/>
                            );
                        })}

                        {AdminRoutes.map((entry, index) => {
                            return (
                                <Route exact={entry.exact} key={index} path={entry.path} render={props => (
                                    <>
                                        <div id="main-content-div" className={`main-content ${isFullScreen ? 'full-screen' : ''}`}>
                                            <Header buildingID={props.match.params.buildingID} />
                                            <PopUp/>
                                            <AdminPagesWrapper buildingID={props.match.params.buildingID} title={t(entry.title)}>
                                                <entry.component {...{...props, ...(entry.props ?? {})}} />
                                            </AdminPagesWrapper>
                                        </div>
                                        <Footer/>
                                    </>
                                )} />
                            );
                        })}
                        {/*an experiment for faster execute pages*/}
                        <Route path='/builder'>
                            <CatchAllRoute/>
                        </Route>
                        <Route path="*" component={NotFound} />
                    </Switch>
                </ScrollToTop>
            </Router>
        </HelmetProvider>
    );
};

