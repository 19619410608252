import {PaymentsApi} from "./tenants/payments.api";
import {BuildingsApi} from "./tenants/buildings.api";
import {UtilsApi} from "./tenants/utils.api";
import {ProfileApi} from "./tenants/profile.api";
import {OperationsApi} from "./tenants/operations.api";
import {MaintenanceApi} from "./tenants/maintenance.api";

export class TenantsApi {

    /** @type {ServerData} */ #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
        this.payments = new PaymentsApi(client);
        this.operations = new OperationsApi(client);
        this.buildings = new BuildingsApi(client);
        this.utils = new UtilsApi(client);
        this.profile = new ProfileApi(client);
        this.maintenance = new MaintenanceApi(client);
    }


    /**
     * @typedef {{ raw: string, name?: string, street?: string, city?: string, streetNumber?: string, country?: string }} LocationSearch
     * */

    /**
     * @param {LocationSearch} loc
     * @return {Promise<{ buildings: unknown[] }>}
     * */
    searchByAddress(loc) {
        return this.#client.get('/tenants/searchByAddress', loc);
    }

    /**
     * @param {AddressPrediction} prediction
     * @return {Promise<{ buildings: unknown[] }>}
     * */
    searchByAddressPrediction(prediction) {
        return this.#client.post('/tenants/searchByAddressPrediction', prediction);
    }

    /**
     * @typedef {{
     *    type: 'gmap' | 'bllink';
     *    displayText: string;
     *    placeID?: string;
     *    buildingID?: string;
     *    matched: { length: number, offset: number }[];
     * }} AddressPrediction
     * */

    /**
     * @typedef {{
     *    name: string;
     *    email: string;
     *    phone: string;
     * }} TenantDetails
     * */

    /**
     * @typedef {{
     *    isPrimary: boolean;
     *    isActive: boolean;
     *    tenantType: 'renter' | 'owner';
     *    details: TenantDetails;
     * }} AddTenantPayload
     * */

    /**
     * @typedef {{
     *    id: number;
     *    apartmentId: number;
     *    createdAt: string;
     *    updatedAt: string;
     *    isActive: boolean;
     *    isPrimary: boolean;
     *    tenantType: 'renter' | 'owner';
     * }} TenantResponse
     * */

    /**
     * @typedef {{
     *    newPrimaryTenant: TenantResponse;
     *    oldPrimaryTenant: TenantResponse;
     * }} DeleteTenantResponse
     * */

    /**
     * @param {string} searchTerm
     * @return {Promise<{ predictions: AddressPrediction[] }>}
     * */
    autoCompleteAddress(searchTerm) {
        return this.#client.get('/tenants/autoCompleteAddress', { searchTerm });
    }

    /**
     * @param {string} buildingID
     * @deprecated this method uses managers endpoint, consider to update or create another one
     * */
    getTenantsForBuilding(buildingID) {
        return this.#client.get(`/managers/buildings/${buildingID}/tenants`)
    }

    /**
     * @param {string} buildingID
     * @param {string} apartmentNum
     * @param {string} tenantID
     * @return {Promise<DeleteTenantResponse>}
     * */
    deleteTenant(buildingID, apartmentNum, tenantID) {
        return this.#client.patch(`/managers/buildings/${buildingID}/apartment/${apartmentNum}/tenants/${tenantID}/active`, {isActive: false});
    }

    /**
     * @param {string} buildingID
     * @param {string} apartmentNum
     * @param {AddTenantPayload} payload
     * */
    addTenant(buildingID, apartmentNum, payload) {
        return this.#client.post(`/managers/buildings/${buildingID}/apartment/${apartmentNum}/tenants`, payload);
    }

    /**
     * @param {string} buildingID
     * @param {string} apartmentNum
     * @param {string} tenantID
     * @param {AddTenantPayload} payload
     * */
    editTenant(buildingID, apartmentNum, tenantID, payload) {
        return this.#client.put(`/managers/buildings/${buildingID}/apartment/${apartmentNum}/tenants/${tenantID}/details`, payload);
    }

    /**
     * @param {string} buildingID
     * @param {string} apartmentNum
     * @param {string} tenantID
     * @param {boolean} primary
     * */
    changeIsPrimary(buildingID, apartmentNum, tenantID, primary) {
        return this.#client.patch(`/managers/buildings/${buildingID}/apartment/${apartmentNum}/tenants/${tenantID}/primary`, {isPrimary: primary});
    }


    getDynamicContent() {
        return this.#client.post('/callbacks/dynamicData');
    }
}
