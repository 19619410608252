export const userActions = {
    "title": "פעולות משתמשים",
    "labels": {
        "editOrCreate": "עריכת / יצירה משתמשת חדשה",
        "email": "אימייל",
        "phone": "טלפון",
        "user": "משתמש/ת"
    },
    "btn": "ערוך/ צור משתמש",
    "success": "משתמש/ת נוצרה או עודכנה בהצלחה"
};
