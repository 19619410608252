export const maintenance = {
    "open_a_call": "Open Maintenance Request",
    "continue_to_choose": "Fill in Maintenance Request Details and send to Building Management",
    "name": "Name *",
    "email": "Email *",
    "apt": "Apartment number *",
    "call_details": "Maintenance Request Details",
    "category": "Category *",
    "urgency": "Urgency *",
    "low": "Low",
    "medium": "Medium",
    "high": "High",
    "attach_a_photo": "Attach photo",
    "desc": "Description *",
    "send": "Send",
    "fault_creation_failed": "Fault creation failed",
    "fault_created": "A fault has been created, result {{result}}",
    "thanks": "Thank you!",
    "form_submitted": "You form has been sent",
    "SevirityDescription": {
        "low": "Low",
        "medium": "Medium",
        "high": "High",
        "immediate": "Immediate"
    }
}
