export const comments = {
    "history": "Apartment {{apartmentNumber}} Comments",
    "comments": "Comments",
    "apartments": "Apartments List",
    "you": "You",
    "writeComment": "Write a comment",
    "add": "Add comment",
    "manager": "Manager",
    "bllink": "Bllink",
    "empty": "No comments for apartment"
};
