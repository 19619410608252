import {createSlice} from '@reduxjs/toolkit';
import Money from "../../common/utils/money";
import Time from "../../common/utils/time";
import { constants } from '../../common/constants/constants';
import {PaymentMethodName} from "../../common/constants/PaymentMethodName";
import {chargeDateMinSelector, chargeDateSelector, chargeDateMaxSelector} from "./selectors";

const NO_FUTURE_PAYMENTS_METHODS = [
    constants.APIContract.paymentMethods.bit,
    constants.APIContract.paymentMethods.applePay,
    constants.APIContract.paymentMethods.googlePay,
];

function makeInitialState() {
    return {
        headerMessageClosed: false, // temporary disabled
        buildingID: null,
        buildingAddress: null,
        buildingCurrency: null,
        buildingApartments: null,
        buildingMaxDebtInstallments: 1,
        buildingCreditCardDisabled: false,
        buildingBankChargeDisabled: false,
        buildingCashPaymentsEnabled: false,
        buildingBitPaymentsDisabled: false,
        buildingMobilePaymentsDisabled: false,
        buildingChequePaymentsEnabled: false,
        buildingMarkAsPaidDisabled: false,
        details: null,
        paymentMethod: PaymentMethodName.creditCards,
        paymentMethodId: constants.APIContract.paymentMethodsIds[PaymentMethodName.creditCards],
        numberOfPaymentMethods: 6,
        allPayments: null,
        allPaymentsCount: 0,
        paymentPlan: null,
        assignedToNextMonth: false,
        futurePaymentsDisabledByPaymentType: false,
        onGoingSelectedPayments: [],
        debtSelectedPayments: [],
        oneTimeSelectedPayments: [],

        // server calculated parameters with loaned installments
        installmentParams: {
            debts: null,
            onGoing: null,
            oneTime: {},
        },

        // actual selected installments count values
        installments: {
            debts: null,
            onGoing: null,
            oneTime: {},
        },

        firstPayment: {
            debts: null,
            onGoing: null,
            oneTime: {},
        },
        bankCharge: {
            ownerId: null,
            bankCode: null,
            bankName: null,
            accountNumber: null,
            signature: null,
            date: null,
        },
        chargeDate: null,
        chargeDateMin: null,
        chargeDateMax: null,
        chargeDay: 16,
        createPaymentPlanPayload: {
            paymentsUIVersion: 2,
            bankAutoCharge: false,
            isChargeCart: false,
            onlineDirectWire: false,
        },
        cartID: null,
        availableApartments: []
    };
}

function makeOngoingPaymentsPayload(state) {
    if (state.onGoingSelectedPayments.length === 0) return undefined;
    const instOption = state.installmentParams.onGoing?.options.find(o => o.installmentsCount === state.installments.onGoing);
    const loaned = instOption?.loaned ?? false;
    const number_of_installments = getInstallments(state, state.installments.onGoing);

    return {
        months: Time.sortMonths([...state.onGoingSelectedPayments]),
        number_of_installments,
        charge_date: state.chargeDate ?? undefined,
        chargeDay: state.chargeDay ?? undefined,
        loaned,
    }
}

function makePastPaymentsPayload(state) {
    if (state.debtSelectedPayments.length === 0) return undefined;
    const instOption = state.installmentParams.debts?.options.find(o => o.installmentsCount === state.installments.debts);
    const loaned = instOption?.loaned ?? false;
    const number_of_installments = getInstallments(state, state.installments.debts);

    return {
        months: Time.sortMonths([...state.debtSelectedPayments]),
        number_of_installments,
        charge_date: state.chargeDate ?? undefined,
        chargeDay: state.chargeDay ?? undefined,
        loaned,
    }
}

function makeOneTimePaymentsPayload(state) {
    if (state.oneTimeSelectedPayments.length === 0) return undefined;
    return state.oneTimeSelectedPayments.map(payment => ({
        number_of_installments: getInstallments(state, state.installments.oneTime[payment.uid]),
        building_one_time_payment_id: payment.building_one_time_payment_id,
        part_pay: (payment.part_pay?.value == null) ? undefined : {
            value: payment.part_pay.value,
            currency: payment.part_pay.currency
        },
        charge_date: state.chargeDate ?? undefined,
        chargeDay: state.chargeDay ?? undefined,
        createdAt: payment.createdAt ?? undefined,
        loaned: state.installmentParams.oneTime?.[payment.uid]?.options.find(o => o.installmentsCount === state.installments.oneTime?.[payment.uid])?.loaned ?? false,
    }));
}

function getInstallments(state, defaultValue){
    const isDirectWire = state.createPaymentPlanPayload.onlineDirectWire;
    //  https://bllink.atlassian.net/browse/SSB-1933 online wire has more than one installment
    return isDirectWire ? 1 : defaultValue;
}

// WARNING: this slice designed for tenant payment flow, let's keep it clean, think twice before add some new field here.
const tenantSlice = createSlice({
    name: 'tenant',
    initialState: makeInitialState(),
    // extraReducers is set of action which will be performed immediately after another one action executed, it is sort of action side effect.
    // builder.addMatcher adds a new action with a matcher - a function which decide was executed action honorable enough to proceed with new action or not.
    extraReducers: (builder,) => {
        builder.addMatcher(
            action => action.type === 'tenant/setAllPayments',
            (state, action) => {
                state.allPaymentsCount = state.allPayments?.on_going_payments.paymentsSortedArray.length +
                                         state.allPayments?.past_payments.paymentsSortedArray.length +
                                         state.allPayments?.one_time_payments.length ?? 0;
                // set unique ids for each payment
                state.allPayments?.on_going_payments?.paymentsSortedArray.forEach((item, i) => item['uid'] = i);
                state.allPayments?.past_payments?.paymentsSortedArray.forEach((item, i) => item['uid'] = i);
                state.allPayments?.one_time_payments?.forEach((item, i) => item['uid'] = i);

                // Preselect all
                // not sure how the payment page shows the open payments and the quick pay not.
                // didn't find the code for paymentPage that decided that
                state.onGoingSelectedPayments = state.allPayments?.on_going_payments?.hasOpenNextMonthPayment === false ? [] : [...(state.allPayments?.on_going_payments?.paymentsSortedArray ?? [])];
                state.debtSelectedPayments = [...(state.allPayments?.past_payments?.paymentsSortedArray ?? [])];
                state.oneTimeSelectedPayments = [...(state.allPayments?.one_time_payments ?? [])];

                state.chargeDate = chargeDateSelector(state);
                state.chargeDateMax = chargeDateMaxSelector(state);
                state.chargeDateMin = chargeDateMinSelector(state);
            },
        );
        builder.addMatcher(
            action => ['tenant/selectOngoingPayment', 'tenant/setOnGoingSelectedPayments', 'tenant/setOnGoingPaymentInstallments'].some(type => action.type === type),
            (state, action) => {
                state.chargeDate = chargeDateSelector(state);
                state.chargeDateMax = chargeDateMaxSelector(state);
                state.chargeDateMin = chargeDateMinSelector(state);

                if (action.type !== 'tenant/setOnGoingPaymentInstallments') {
                    state.installments.onGoing = state.onGoingSelectedPayments.length;
                }
                if (state.onGoingSelectedPayments.length === 0 ){
                    state.firstPayment.onGoing = 0
                }
                const option = state.installmentParams.onGoing?.options.find(p => p.installmentsCount === state.installments.onGoing) ?? null;
                if (option !== null) {
                    state.firstPayment.onGoing = Money.roundNumber(option.paymentAmountInCents, 2);
                    // adding log https://bllink.atlassian.net/browse/SSB-2094
                    console.log(`for selected number of installments number of ${state.installments.onGoing} 
                    the amount to pay is ${state.firstPayment.onGoing} total selected option.installmentsCount ${option.installmentsCount} and option.loanCommission ${option.loanCommission}` );

                }
                state.createPaymentPlanPayload.on_going_payments = makeOngoingPaymentsPayload(state);
            },
        );
        builder.addMatcher(
            action => ['tenant/selectDebtPayment', 'tenant/setDebtSelectedPayments', 'tenant/setDebtPaymentInstallments'].some(type => action.type === type),
            (state, action) => {
                if (action.type !== 'tenant/setDebtPaymentInstallments') {
                    state.installments.debts = state.debtSelectedPayments.length === 0 ? 0 : 1;
                }
                if (state.debtSelectedPayments.length === 0 ){
                    state.firstPayment.debts = 0
                }
                const option = state.installmentParams.debts?.options.find(p => p.installmentsCount === state.installments.debts) ?? null;
                if (option !== null) {
                    state.firstPayment.debts = Money.roundNumber(option.paymentAmountInCents, 2);
                    state.createPaymentPlanPayload.past_payments = makePastPaymentsPayload(state);
                }
            },
        );
        builder.addMatcher(
            action => ['tenant/selectOneTimePayment', 'tenant/setOneTimeSelectedPayments', 'tenant/setOneTimePaymentInstallments'].some(type => action.type === type),
            (state, action) => {
                if (action.type !== 'tenant/setOneTimePaymentInstallments') {
                    state.installments.oneTime = state.oneTimeSelectedPayments.reduce((acc, p) => Object.assign(acc, { [p.uid]: 1 }), {});
                }
                state.firstPayment.oneTime = state.oneTimeSelectedPayments.reduce((acc, p) => {
                    const param = state.installmentParams.oneTime[p.uid];
                    const inst = state.installments.oneTime[p.uid];
                    const option = param?.options.find(o => o.installmentsCount === inst) ?? null;
                    if (option !== null) {
                        const amount = option.paymentAmountInCents;
                        return Object.assign(acc, { [p.uid]: Money.roundNumber(amount, 2) })
                    }
                    return acc;
                }, {});
                state.createPaymentPlanPayload.one_time_payments = makeOneTimePaymentsPayload(state);
            },
        );
        builder.addMatcher(
            action => ['tenant/setChargeDate', 'tenant/setChargeDay'].some(type => action.type === type),
            (state, action) => {
                state.createPaymentPlanPayload.on_going_payments = makeOngoingPaymentsPayload(state);
                state.createPaymentPlanPayload.past_payments = makePastPaymentsPayload(state);
                state.createPaymentPlanPayload.one_time_payments = makeOneTimePaymentsPayload(state);
            },
        );
        builder.addMatcher(
            action => action.type === 'tenant/setBankCharge',
            (state, action) => {
                state.createPaymentPlanPayload.bankChargeData = {
                    bankCode: action.payload.bankCode,
                    branchNumber: action.payload.branchNumber,
                    accountNumber: action.payload.accountNumber,
                };
            },
        );
        builder.addMatcher(
            action => action.type === 'tenant/setTenantDetails',
            (state, action) => {
                const tenant_details = { ...action.payload };
                if (tenant_details.email === '' || tenant_details.email == null) {
                    delete tenant_details.email // other case BE will validate it and reject
                }
                state.createPaymentPlanPayload.tenant_details = tenant_details;
            },
        );
        builder.addMatcher(
            action => action.type === 'tenant/setPaymentMethodId',
            (state, action) => {
                const paymentMethodId = action.payload;
                state.futurePaymentsDisabledByPaymentType = NO_FUTURE_PAYMENTS_METHODS.some(pmid => pmid === paymentMethodId);
            },
        );
        builder.addMatcher(
            action => action.type === 'tenant/setInstallmentParams',
            (state, action) => {
                const { params, paymentType, uid } = action.payload;
                const { onGoing, debts, oneTime } = state.installmentParams;
                // Update/Reset installments and first payment
                switch (paymentType) {
                    case 'on_going_monthly':
                        const onGoingOption = onGoing.options.find(o => o.installmentsCount === onGoing.maxFreeInstallments);
                        state.firstPayment.onGoing = Money.roundNumber(onGoingOption.paymentAmountInCents, 2);
                        state.installments.onGoing = onGoingOption.installmentsCount;
                        break;
                    case 'past_due_unpaid_monthly':
                        const debtOption = debts.options.find(o => o.installmentsCount === 1);
                        state.firstPayment.debts = Money.roundNumber(debtOption.paymentAmountInCents, 2);
                        state.installments.debts = debtOption.installmentsCount;
                        break;
                    case 'one_time_payment':
                        const oneTimeOption = oneTime[uid].options.find(o => o.installmentsCount === 1);
                        const firstPayment = Money.roundNumber(oneTimeOption.paymentAmountInCents, 2);
                        // todo should consider if selected first?
                        // todo remove this log once https://bllink.atlassian.net/browse/SSB-2094 is resolved
                        console.log(`adding firstPayment ${firstPayment} for one time ${uid}`);
                        state.firstPayment.oneTime[uid] = firstPayment;
                        state.installments.oneTime[uid] = oneTimeOption.installmentsCount;
                        break;
                    default:
                        throw new Error(`Unexpected payment type '${paymentType}'`);
                }

                state.createPaymentPlanPayload.on_going_payments = makeOngoingPaymentsPayload(state);
                state.createPaymentPlanPayload.past_payments = makePastPaymentsPayload(state);
                state.createPaymentPlanPayload.one_time_payments = makeOneTimePaymentsPayload(state);
            },
        );
    },

    // NOTE: please keep reducers as clean as possible, move all side effect logic to extra reducers
    reducers: {
        resetTenant(state, action) {
            Object.assign(state, makeInitialState());
        },
        setHeaderMessageClosed(state, action) {
            state.headerMessageClosed = action.payload;
        },
        setTenantDetails(state, action) {
            if (action.payload.empty) {
                state.details = null;
                return;
            }
            const oldPhone = state.details?.phone;
            const newPhone = action.payload.phone;
            const oldTenantType = state.details?.tenant_type;
            const newTenantType = action.payload.tenant_type;
            const oldApartmentNumber = state.details?.apartmentNumber;
            const newApartmentNumber = action.payload.apartmentNumber;
            if (oldPhone !== newPhone || oldTenantType !== newTenantType || oldApartmentNumber !== newApartmentNumber) {
                state.allPayments = null;
            }
            state.details = action.payload;
        },
        setBuildingData(state, action) {
            const { buildingID, currency, apartments, assignedToNextMonth, address, numberOfInstallmentsForDebt, tenantCommission,
                disableCreditCard, disableBankCharge, enableCashPayments, enableChequePayments, disableBitPayments, disableMobilePayments, disableMarkAsPaid, insurancePageUrlPrefix } = action.payload;
            state.buildingID = buildingID;
            state.buildingAddress = address;
            state.buildingCurrency = currency;
            state.buildingApartments = (apartments ?? []).map(a => a.apartmentNumber);
            state.assignedToNextMonth = assignedToNextMonth;
            state.buildingMaxDebtInstallments = numberOfInstallmentsForDebt;
            state.tenantCommission = tenantCommission
            state.buildingCreditCardDisabled = disableCreditCard;
            state.buildingBankChargeDisabled = disableBankCharge;
            state.buildingCashPaymentsEnabled = enableCashPayments;
            state.buildingChequePaymentsEnabled = enableChequePayments;
            state.buildingBitPaymentsDisabled = disableBitPayments;
            state.buildingMobilePaymentsDisabled = disableMobilePayments;
            state.buildingMarkAsPaidDisabled = disableMarkAsPaid;
            state.insurancePageUrlPrefix = insurancePageUrlPrefix;
        },
        setAllPayments(state, action) {
            state.allPayments = action.payload;
        },
        setPaymentMethod(state, action) {
            state.paymentMethod = action.payload;
        },
        setPaymentMethodId(state, action) {
            state.paymentMethodId = action.payload;
        },
        setNumberOfPaymentMethods(state, action) {
            state.numberOfPaymentMethods = action.payload;
        },
        setBankCharge(state, action) {
            state.bankCharge = action.payload;
        },
        setChargeDate(state, action) {
            state.chargeDate = action.payload;
        },
        setChargeDay(state, action) {
            state.chargeDay = action.payload;
        },

        setPaymentPlan(state, action) {
            state.paymentPlan = action.payload;
        },
        // todo remove. we are not using this
        setDisabledInsurancePage(state, action){
            state.disableInsurancePage = action.payload;
        },
        setDebtPaymentInstallments(state, action) {
            state.installments.debts = action.payload;
        },
        setDebtSelectedPayments(state, action) {
            state.debtSelectedPayments = action.payload;
        },
        selectDebtPayment(state, action) {
            const { payment, isChecked } = action.payload;
            if (isChecked) {
                // fixed deletion of unselected payment
                state.debtSelectedPayments = state.debtSelectedPayments.filter(p => p.uid !== payment.uid);
            } else {
                state.debtSelectedPayments = [...state.debtSelectedPayments, payment];
            }
        },

        setOnGoingPaymentInstallments(state, action) {
            state.installments.onGoing = action.payload;
        },
        setOnGoingSelectedPayments(state, action) {
            state.onGoingSelectedPayments = action.payload;
        },
        selectOngoingPayment(state, action) {
            const { payment, isChecked } = action.payload;
            if (isChecked) {
                state.onGoingSelectedPayments = state.onGoingSelectedPayments.filter(p => p.uid !== payment.uid);
            } else {
                const allOngoingPayments = state.allPayments.on_going_payments.paymentsSortedArray;
                const index = allOngoingPayments.findIndex(p => p.uid === payment.uid);
                state.onGoingSelectedPayments = allOngoingPayments.slice(0, index + 1);
            }
        },

        setOneTimePaymentInstallments(state, action) {
            const { installments, payment } = action.payload;
            state.installments.oneTime[payment.uid] = installments;
        },
        setOneTimeSelectedPayments(state, action) {
            // todo remove this log once https://bllink.atlassian.net/browse/SSB-2094 is resolved
            console.log(`oneTimeSelectedPayments setting to`, action.payload);
            state.oneTimeSelectedPayments = action.payload;
        },
        selectOneTimePayment(state, action) {
            const { payment, isChecked } = action.payload;
            if (isChecked) {
                state.oneTimeSelectedPayments = state.oneTimeSelectedPayments.filter(p => p.uid !== payment.uid);
            } else {
                state.oneTimeSelectedPayments = [...state.oneTimeSelectedPayments, payment];
            }
        },
        setCartID(state, action) {
            state.cartID = action.payload
        },
        setInstallmentParams(state, action) {
            const { params, paymentType, uid } = action.payload;
            switch (paymentType) {
                case 'on_going_monthly':
                    state.installmentParams.onGoing = params;
                    break;
                case 'past_due_unpaid_monthly':
                    state.installmentParams.debts = params;
                    break;
                case 'one_time_payment':
                    state.installmentParams.oneTime[uid] = params;
                    break;
                default:
                    throw new Error(`Unexpected payment type '${paymentType}'`);
            }
        },
        setOnlineDirectWire(state, action) {
            state.createPaymentPlanPayload.onlineDirectWire = action.payload;
        },
        // to be used in quick page
        setCartPayloadDirectly(state, action) {
            state.createPaymentPlanPayload = action.payload;
        },
        setAvailableApartments(state, action) {
            state.availableApartments = action.payload;
        }
    },
});

export const {
    setHeaderMessageClosed,
    setTenantDetails,
    setBuildingData,
    setPaymentMethod,
    setPaymentMethodId,
    setNumberOfPaymentMethods,
    resetTenant,
    setDebtPaymentInstallments,
    setOnGoingPaymentInstallments,
    setOneTimePaymentInstallments,
    selectOngoingPayment,
    selectDebtPayment,
    selectOneTimePayment,
    setOnGoingSelectedPayments,
    setDebtSelectedPayments,
    setOneTimeSelectedPayments,
    setAllPayments,
    setBankCharge,
    setPaymentPlan,
    setChargeDate,
    setChargeDay,
    setCartID,
    setInstallmentParams,
    setOnlineDirectWire,
    setCartPayloadDirectly,
    setDisabledInsurancePage,
    setAvailableApartments
} = tenantSlice.actions;

export default tenantSlice.reducer;
