export class ContactsApi {
    /**
     * @typedef {{
     *     id: number;
     *     entityId: number;
     *     entityTypeId: number;
     *     type: string;
     *     value: string;
     *     entityType: string;
     *     deleted: boolean;
     * }} Contact
     * */

    #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {{deleted?: boolean}} [obj]
     * @returns {Promise<Contact[]>}
     */
    async getContacts(obj = {}) {
        const query = `
            query Contacts($deleted: Boolean) {
              Contact(deleted: $deleted) {
                deleted
                entityId
                entityTypeId
                id
                type
                value
                entityType
              }
            }
        `;
        const variables = {
            deleted: obj.deleted || false,
        };
        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: true, hideParams: true });
        if (resp.errors == null) {
            return resp.data.Contact;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }

    /**
     * @param {{deleted?: boolean, buildingExternalId: string | string[], ownerType: 1 | 3}} [obj]
     * @returns {Promise<Contact[]>}
     */
    async getContactsByExternalBuildingId(obj = {}) {
        const query = `
            query Query($deleted: Boolean, $buildingExternalId: [String!], $ownerType: Int) {
              Contact(deleted: $deleted, buildingExternalId: $buildingExternalId, ownerType: $ownerType) {
                id
                entityTypeId
                entityId
                type
                value
              }
            }
        `;
        const variables = {
            deleted: obj.deleted || false,
            buildingExternalId: obj.buildingExternalId,
            ownerType: obj.ownerType,
        };
        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: true, hideParams: true });
        if (resp.errors == null) {
            return resp.data.Contact;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }

    /**
     * @param {{id: number; type: string; value: string}} variables
     * @returns {Promise<Contact[]>}
     */
    async updateContact(variables) {
        const query = `
            mutation Mutation($id: [Int!], $type: String, $value: String) {
              Contact(id: $id) {
                updateContact(type: $type, value: $value) {
                    deleted
                    entityId
                    entityTypeId
                    id
                    type
                    value
                }
              }
            }
       `;

        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: true, hideParams: true });
        if (resp.errors == null) {
            return resp.data.Contact.updateContact;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }

    /**
     * @param {{id: number | number[]}} variables
     * @returns {Promise<Contact[]>}
     */
    async deleteContact(variables) {
        const query = `
            mutation Mutation($id: [Int!]) {
              Contact(id: $id) {
                deleteContact {
                    deleted
                    entityId
                    entityTypeId
                    id
                    type
                    value
                }
              }
            }
       `;

        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: true, hideParams: true });
        if (resp.errors == null) {
            return resp.data.Contact.deleteContact;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }

    /**
     * @param {{entityTypeId: number; entityId: number; type: string; value: string}} variables
     * @returns {Promise<Contact[]>}
     */
    async createContact(variables) {
        const query = `
            mutation Contact($entityTypeId: Int!, $entityId: Int!, $type: String!, $value: String!) {
              Contact {
                createContact(entityTypeId: $entityTypeId, entityId: $entityId, type: $type, value: $value) {
                    deleted
                    entityId
                    entityTypeId
                    id
                    type
                    value
                }
              }
            }
       `;

        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: true, hideParams: true });
        if (resp.errors == null) {
            return resp.data.Contact.createContact;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }
}
