import { constants } from "../../../constants/constants";
import {lazyReload} from "../../../lazyReload";
const NewBuildingDetails = lazyReload(() => import("../../../../managers/building/new_building_details"));
const NewBankDetails = lazyReload(() => import("../../../../managers/bank_details/new_bank_details"));
const ViewBankDetails = lazyReload(() => import("../../../../managers/bank_details/view_bank_details"));

export const CreateBuildingRoutes = [
    {path: `/${constants.pages.newBuildingUrl}`, component: NewBuildingDetails, exact: true},
    {path: `/${constants.pages.addBankDetailsUrl}/:buildingID`, component: NewBankDetails, exact: true},
    {path: `/${constants.pages.viewBankDetailsUrl}/:buildingID`, component: ViewBankDetails, exact: true},
]
