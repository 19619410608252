import { createSlice } from '@reduxjs/toolkit';
import {checkBankChargePermission, getAllExpenseTypes, getAllKnownVendors, getExpenseDetails} from './actions';

const expensesSlice = createSlice({
    name: 'expenses',
    initialState: {
        expenseTypes: [],
        expenseDetails: {},
        allKnownVendors: [],
        hasBankChargePermission: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllExpenseTypes.fulfilled, (state, { payload }) => {
            state.expenseTypes = payload;
        })
        builder.addCase(getAllExpenseTypes.rejected, (state, action) => {
            state.expenseTypes = [];
        })
        builder.addCase(getAllKnownVendors.fulfilled, (state, { payload }) => {
            state.allKnownVendors = payload;
        })
        builder.addCase(getAllKnownVendors.rejected, (state, action) => {
            state.allKnownVendors = [];
        })
        builder.addCase(getExpenseDetails.fulfilled, (state, { payload }) => {
            state.expenseDetails = payload;
        })
        builder.addCase(getExpenseDetails.rejected, (state, action) => {
            state.expenseDetails = [];
        })
        builder.addCase(checkBankChargePermission.fulfilled, (state, { payload }) => {
            state.hasBankChargePermission = payload;
        });
    }
});

export default expensesSlice.reducer;
