
import { useEffect, useState } from "react";
import { BuilderComponent, builder, Builder, useIsPreviewing } from "@builder.io/react";

// Put your Public API key here
builder.init('0033170a22a849488e77fb46011a701a');

// set whether you're using the Visual Editor,
// whether there are changes,
// and render the content if found
export default function CatchAllRoute() {
    const isPreviewingInBuilder = useIsPreviewing();
    const [notFound, setNotFound] = useState(false);
    const [content, setContent] = useState(null);

    // get the page content from Builder
    useEffect(() => {
        async function fetchContent() {
            const content = await builder
                .get("page", {
                    url: window.location.pathname
                })
                .promise();

            setContent(content);
            setNotFound(!content);

            // if the page title is found,
            // set the document title
            if (content?.data.title) {
                document.title = content.data.title
            }
        }
        fetchContent();
    }, [window.location.pathname]);

    // If no page is found, return
    // a 404 page from your code.
    // The following hypothetical
    // <FourOhFour> is placeholder.
    if (notFound && !isPreviewingInBuilder) {
        return <>Not Found</>
    }

    // return the page when found
    return (
        <>
            {/* Render the Builder page */}
            <BuilderComponent model="page" content={content} />
        </>
    );
}



// Any component in your codebase
const Heading1 = props => (
    <h1>{props.content} 222</h1>
)

// Register this component for use in the Visual Editor
Builder.registerComponent(Heading1, {
    name: 'Heading1',
    inputs: [
        // 'name' is the name of your prop
        {name: 'content', type: 'text'},
        {name: 'link', type: 'url'},
    ],
    image: 'https://tabler-icons.io/static/tabler-icons/icons-png/3d-cube-sphere-off.png'
})
