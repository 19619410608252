import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {NewScrollbar} from "../common/newScrollbar";
import {SearchButton} from "./SearchButton";

const CollapsibleSearchStyled = styled.div`

  --background-color: #F3F1FF;
  --loading-color: #7483FF;
  --trans-duration: 200ms;

  background: linear-gradient(var(--background-color), var(--background-color)) padding-box, var(--bllink-gradient-primary) border-box;
  border-radius: 100px;
  border: 1px solid transparent;

  display: flex;
  align-items: center;
  gap: 16px;
  padding: 9px;

  width: 100%;
  height: 44px;
  position: relative;
  transition: width var(--trans-duration) ease-in, border-color var(--trans-duration) ease-in, gap var(--trans-duration) ease-in;

  &.collapsed {
    width: 44px;
    background: var(--background-color);
    border-color: var(--background-color);
    gap: 0;
    transition: width var(--trans-duration) ease-out, border-color var(--trans-duration) ease-out, gap var(--trans-duration) ease-out;

    input {
      padding: 0;
      transition: padding-inline-start var(--trans-duration) ease-out, flex-grow var(--trans-duration) ease-out;
    }
  }

  input {
    background-color: transparent;
    border: none;
    flex: 1 1 auto;
    min-width: 0;
    padding: 0;
    padding-inline-start: .5em;
    transition: padding-inline-start var(--trans-duration) ease-in;
  }

  .search-options {
    &-container {
      width: 100%;
      position: absolute;
      top: 46px;
      left: 0;
      z-index: 1;
      border-radius: 16px;
      background-color: var(--bllink-general-background);
    }

    &-list {
      border: 1px solid ${props => props.theme.main};
      border-radius: 16px;
      overflow: hidden;
    }

    &-item {
      width: 100%;
      padding: 10px 20px;
      border-bottom: 1px solid rgba(168, 192, 255, 0.50);
    }

    &-no-options {
      opacity: 0.4;
      width: 100%;
      padding: 10px 20px;
    }
  }
`;

/**
 * @param {(string) => void} onChange
 * @param {JSX.Element[]} searchOptions
 * @param {boolean} isLoading
 * @param {boolean} disableDropdown
 * @returns {JSX.Element}
 * @constructor
 */
export function CollapsibleSearch({onChange, searchOptions, isLoading, disableDropdown}) {
    const {t} = useTranslation();
    const isLtrEnabled = useSelector(state => state.common.ltrEnabled);

    const [search, setSearch] = useState(true);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const ref = useRef();
    const inputRef = useRef();
    const handleButtonClick = useCallback(() => {
        setIsCollapsed(isCollapsed => !isCollapsed)
    }, [setIsCollapsed]);

    const handleSearchChange = useCallback((searchValue) => {
        setSearch(searchValue);
        onChange(searchValue);
    }, [onChange])

    /**
     * @param {MouseEvent} event
     */
    const outsideClickListener = useCallback((event) => {
        setIsCollapsed(!ref.current?.contains(event.target));
    }, [ref.current]);

    const noResultsBlock = useMemo(() => {
        if (!search?.length) return null;
        return <div className='search-options-no-options'>
            {t('globalSearch.noResults')}
        </div>
    }, [search, t])

    useEffect(() => {
        document.addEventListener('click', outsideClickListener);

        return () => {
            document.removeEventListener('click', outsideClickListener);
        }
    }, [outsideClickListener])

    useEffect(() => {
        if (isCollapsed) {
            window.setTimeout(() => {
                if (!inputRef?.current) return;
                inputRef.current.value = '';
                inputRef.current.blur();
                onChange('')
            }, 100);
        } else {
            window.setTimeout(() => inputRef.current?.focus(), 100);
        }
    }, [isCollapsed])

    return (
        <CollapsibleSearchStyled className={`collapsible-search ${isCollapsed ? 'collapsed' : ''}`} ref={ref}>
            <input
                type="text"
                ref={inputRef}
                onChange={(e) => handleSearchChange(e.target.value)}
            />
            <SearchButton
                handleButtonClick={handleButtonClick}
                isLoading={isLoading}
            />
            {!disableDropdown && !isCollapsed && (searchOptions?.length || noResultsBlock) && (
                <div className='search-options-container'>
                    <div className='search-options-list'>
                        {searchOptions?.length ? <NewScrollbar
                            width={'100%'}
                            height={'300px'}
                            mobileNative={true}
                            noScrollX={true}
                            rtl={!isLtrEnabled}>
                            {searchOptions.map((option, index) => (
                                <div className='search-options-item' key={index}>
                                    {option}
                                </div>
                            ))}
                        </NewScrollbar> : noResultsBlock}
                    </div>
                </div>
            )}
        </CollapsibleSearchStyled>
    );
}
