export class BuildingsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param params
     * @return {Promise<{ buildings: [], partner: [] }>}
     * */
    async getAllManagerBuildings(params = {}) {
        return this.#client.get(`/managers/buildings/`, params);
    }

    /**
     * @param {number} buildingID
     * @param params
     * @return {Promise<unknown>}
     * */
    async getManagerBuilding(buildingID, params = {}) {
        return this.#client.get(`/managers/buildings/${buildingID}`, params);
    }

    /**
     * @param {string} buildingId
     * @param {string} apartmentNumber
     * @return {Promise<unknown[]>}
     * */
    getComments(buildingId, apartmentNumber) {
        return this.#client.get(`/managers/buildings/${buildingId}/${apartmentNumber}/comments`);
    }

    /**
     * @param {string | number} buildingId
     * @return {Promise<{ imageExists: boolean, imageData: string | null, imageType: string | null, partnerName: string }>}
     * */
    getPartnerLogo(buildingId) {
        return this.#client.get(`/managers/buildings/${buildingId}/mclogo`);
    }

    /**
     * @param {string | number} buildingId
     * @param {string | number} apartmentId
     * @param {number} tenantId
     * @param {boolean} value
     * */
    markTenantAsBuildingManager(buildingId, apartmentId, tenantId, value) {
        return this.#client.post(`/managers/buildings/${buildingId}/apartment/${apartmentId}/tenants/${tenantId}/buildingManager`, { value }, { throwError: true });
    }

    /**
     * @param {string | number} buildingId
     * @param {string | number} apartmentId
     * @param {number} tenantId
     * @param {boolean} value
     * */
    markTenantAsInLegalIssues(buildingId, apartmentId, tenantId, value) {
        return this.#client.post(`/managers/buildings/${buildingId}/apartment/${apartmentId}/tenants/${tenantId}/legalIssues`, { value }, { throwError: true });
    }

    /**
     * @param {string | number} buildingId
     * @param {string} apartmentNum
     * @param {number} year
     * */
    getApartmentPaymentsByMonth(buildingId, apartmentNum, year) {
        return this.#client.get(`/managers/buildings/${buildingId}/apartment/${apartmentNum}/paymentsByMonths/${year}`, {}, { throwError: true });
    }

    /**
     * @param {string} buildingId
     * @param {string} apartmentNum
     * */
    getApartmentTenants(buildingId, apartmentNum) {
        return this.#client.get(`/managers/buildings/${buildingId}/apartment/${apartmentNum}/tenants`, {}, { throwError: true });
    }
}
