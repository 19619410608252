import {BllinkLogger} from "./bllink_loggers";

class Money{
    static roundNumber(number, digits){
        /// todo extract this logic to one place
        let numberRounded = Math.round((number + Number.EPSILON) * 100) / 100
        if (digits === 0){
            numberRounded = numberRounded.toFixed(0)
        }
        return numberRounded
    }

    static totalEachMonth(total, numberOfInstallments){
        if (numberOfInstallments === 0){
            //https://app.asana.com/0/1196545425570329/1198888709076573
            // [bug] when selecting on going months and clicking on no months (first month click) it shows NaN on installments box
            return 0
        }
        return Money.roundNumber(total / numberOfInstallments);
    }


    // duplicate code from backend here!!! - need to share this or keep it synced all the time
    static calcPlanByAveragePayment(totalSumToPay, numberOfInstallments){
        /*
            https://app.asana.com/0/1196545425570329/1198956650306896
            [bug] calculation of installments amount who are not even
         */
        BllinkLogger.info(`totalSumToPay is ${totalSumToPay} `)
        let sumsToPay = [], i;
        // should be a round number since cents are the lowest precision we have
        const totalSumToPayInCents = this.roundNumber(totalSumToPay * 100, 0);
        const sumInEachPayment = totalSumToPayInCents / numberOfInstallments;

        // if not round implement rounding logic
        const isRound = Number.isInteger(sumInEachPayment/100)
        BllinkLogger.info(`sumInEachPayment ${sumInEachPayment} for ${totalSumToPayInCents}/${numberOfInstallments}  . isRound ${isRound}`);
        if (isRound){
            for (i=0; i < numberOfInstallments ; i++){
                sumsToPay.push(sumInEachPayment)
            }
        }else {
            sumsToPay = this.createDynamicPlanThatWithReminder(totalSumToPayInCents, numberOfInstallments);
        }

        this.verifyPlanEqualsTheTotal(sumsToPay, totalSumToPayInCents);
        sumsToPay = sumsToPay.map((sumToPay) => {return this.roundNumber(sumToPay / 100)});

        if (sumsToPay.length === 0){
            return [0]
        }
        return sumsToPay;
    }

    static createDynamicPlanThatWithReminder(totalSumToPayInCents, numberOfInstallments){
        /*
            for example 1000 with 9 installments
            first payment is 1000/9 +  1000 % 9
            and the rest are 1000/9
         */
        let sumsToPay = [];
        // const isOriginalSumRound = Number.isInteger(totalSumToPayInCents/100);
        // https://app.asana.com/0/1196545425570329/1199570641176477
        // discussed with omri . decided to round to closest cent every time
        const shouldConvertToDollars = false //(totalSumToPayInCents > 1000 && isOriginalSumRound);
        totalSumToPayInCents = shouldConvertToDollars ? totalSumToPayInCents/100: totalSumToPayInCents ; // to calc in dollars, not cents, if needed
        console.log(`not a round figure creating an uneven plan totalSumToPayInCents ${totalSumToPayInCents}, shouldConvertToDollars ${shouldConvertToDollars} totalSumToPayInCents > 1000 ${totalSumToPayInCents > 1000}`);
        let amountLeft = totalSumToPayInCents;
        let quotient = Math.floor(totalSumToPayInCents / numberOfInstallments);
        let remainder = totalSumToPayInCents % numberOfInstallments;
        let singlePayment = quotient + remainder;
        for (let i = 0; i < numberOfInstallments; i++) {
            if (singlePayment > 0) {
                sumsToPay.push(singlePayment);
            }
            amountLeft -= singlePayment;
            if (amountLeft < 0){
                throw new Error(`creating a dynamic plan. will charge the user too much`);
            }
            singlePayment = Math.min(quotient, amountLeft);
        }

        sumsToPay = sumsToPay.map((sumToPay)=> {return shouldConvertToDollars? sumToPay*100: sumToPay}) // bring back to cents

        return sumsToPay
    }

    static verifyPlanEqualsTheTotal(planSumsInCents, totalSumToPayInCents){
        const totalPlanSum = parseInt(planSumsInCents.reduce((a, b) => a + b, 0))
        if (totalPlanSum !== parseInt(totalSumToPayInCents)){
            // should be an error?
            console.error(`plan does not equal total sum. diff is ${totalSumToPayInCents - totalPlanSum}. ${totalSumToPayInCents} vs ${totalPlanSum}`)
        }else{
            BllinkLogger.info(`all is well with the payment plan `, planSumsInCents)
        }
    }

}


export default Money
