export class BankAdjustmentsApi {

    /**
     * @typedef {{
     *    date: Date;
     *    dueDate: Date;
     *    confirmationId: number;
     *    debit: number;
     *    credit: number;
     *    balance: number;
     *    description?: string;
     *    descriptionEx?: string;
     *    comment?: string;
     * }} MoneyMovementRecord
     * */

    /**
     * @typedef {{
     *     downloadLink: string;
     *     lastModified: Date;
     *     name: string;
     *     size: number
     * }} BankReportFileDef
     * */

    /**
     * @typedef {{ type: string; paymentID?: number | number[]; expenseID?: number; recordIdx: number[] }} RecordMatch
     * */

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} buildingID
     * @param {string} fileName
     * @param {string} fileBase64Data
     * @return {Promise<{ uuid: string }>}
     * */
    addReportFile(buildingID, fileName, fileBase64Data) {
        return this.#client.post(`/managers/bank_adjustments/${buildingID}/report_files`, { fileName, fileBase64Data });
    }

    /**
     * @param {string} buildingID
     * @param {string} uuid
     * @return {Promise<{ records: MoneyMovementRecord[] }>}
     * */
    getReportFileRecords(buildingID, uuid) {
        return this.#client.get(`/managers/bank_adjustments/${buildingID}/report_files/${uuid}`);
    }

    /**
     * @param {string} buildingID
     * @param {number} [limit=2]
     * @return {Promise<{ reportFiles: BankReportFileDef[] }>}
     * */
    getBuildingReportFiles(buildingID, limit = 2) {
        return this.#client.get(`/managers/bank_adjustments/${buildingID}/report_files?limit=${limit}`);
    }

    /**
     * @param {string} buildingID
     * @param {number} year
     * @param {number} month
     * @param {string} [uuid]
     * @return {Promise<{ transactions, bankRecords?: MoneyMovementRecord[], recordMatches?: RecordMatch[] }>}
     * */
    getMatchedReportFileRecords(buildingID, year, month, uuid) {
        return this.#client.post(`/managers/bank_adjustments/${buildingID}/transactions/${year}/${month}`, {
            matchToReportFile: uuid,
            ignoreStatus: false, // do not turn it on for production, it is for debug only
        });
    }
}
