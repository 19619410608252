export const creditCardChoice = {
    "confirmWord": "אישור",
    "headers": {
        "selectCard": "בחר\/י חיוב מכרטיס חדש או קיים",
        "selectExistingCard": "בחר\/י חיוב מכרטיס קיים",
        "confirm": "וידוא עסקה - חיוב מכרטיס קיים"
    },
    "infoText": {
        "paidInThePast": "המערכת זיהתה כי הדייר\/ת שילמו דרכנו בעבר",
        "shorterProcess": "לחיוב מהיר, חייב\/י מכרטיס קיים",
        "verify": "להמשך התהליך אנא קבל אישור מבעל\/ת הכרטיס",
        "type": "והקלד\/י 'אישור'",
        "typeConfirm": "הקלד\/י 'אישור' כאן",
        "disabledExistingChoice": "לא ניתן לשלם תשלום עתידי עם כרטיס אשראי קיים"
    },
    "checkboxLabels": {
        "newCard": "תשלום מכרטיס חדש",
        "existingCard": "תשלום מכרטיס קיים"
    },
    "btns": {
        "continue": "המשך לתשלום",
        "charge": "חייב כרטיס"
    },
    "tableHeaders": {
        "name": "שם",
        "lastUsed": "חוייב לאחרונה",
        "cardLast4Digits": "4 ספרות אחרונות",
        "phone": "טלפון",
        "email": "אימייל",
        "expiry": "תוקף"
    }
};
