export const warningLetter = {
    "in_honor": "לכבוד:",
    "letter_reason": "ניתנת לך בזאת תזכורת, כי טרם שילמת את חלקך בהוצאות אחזקת הרכוש המשותף",
    "debts_title": "להלן פירוט יתרת החוב",
    "total": "סה״כ",
    "legal_text_title": "סעיף 58 לחוק המקרקעין מטיל חובה אבסולוטית על בעל דירה להשתתפות בהוצאות החזקת הרכוש המשותף",
    "legal_text": "אם בתוך 10 ימים לא יוסדר חובך, ניאלץ להתחיל בתהליך לצורך הגשת תביעה בפני המפקח לרישום מקרקעין. בשביל שנוכל להימנע מהליך משפטי אנו מעמידים לך אפשרויות תשלום עם פריסה נוחה באמצעות כרטיס אשראי.",
    "regards": "בברכה,",
    "issuer": "חברת הניהול ונציגות הבית",
    "currency": "ש״ח",
    "fileName": "מכתב_התראה_חוב_ועד_בית",
    "one_time": "עבור תשלומים מיוחדים",
    "month": "עבור חודשים:",
    "apartment": "מס׳ דירה:",
    "address": "כתובת:",
    "date": "תאריך:",
    "title": "הנדון: השתתפות בהוצאות אחזקת הרכוש המשותף",

    "new": {
        "title": "בלינק - מערכת התשלומים של הבניין",
        "date": "תאריך",
        "to": "לכבוד",
        "address": "כתובת",
        "noticeBeforeLegalAction": "התראה לפני נקיטת הליכים משפטיים",
        "listItems": {
            "first": "על פי הרישומים בחברתנו טרם הוסדרו תשלומי ועד הבית של דירתך.",
            "second": "החוב לוועד הבית הינו בהתאם לפירוט בטבלה שלהלן",
            "third": " חרף פניות אליך לשם הסדרת החוב, טרם פעלת להסדירו כמתחייב בחוק.",
            "forth": "החוב גורם עיכובים וקשיים מרובים בתפקוד הבניין כסדרו ובהחזקתו השוטפת. זאת, כאשר הדירה שבבעלותך נהנית מניהול הבניין, על חשבונם של יתר הדיירים.",
            "fifth": "על פי סעיף 58  א' לחוק המקרקעין, כל בעל דירה חייב להשתתף בהוצאות הדרושות להחזקתו התקינה ולניהולו של הרכוש המשותף.",
            "sixth": "למען הסר ספק מובהר כי אי הסדרת החוב לעיל יגרור נקיטה בהליכים משפטיים, לרבות אך לא רק, מכתב עורך דין, כאשר כל העלויות בגין כך יחולו עליך.",
            "sixthHighlited": "באופן מידי ובכל מקרה לא יאוחר משבוע ימים ממועד מכתב זה.",
            "seventh": "באם החוב לא יוסדר במסגרת הזמן שהוזכרה, תוגש כנגדך תביעה משפטית. במסגרת זו, בנוסף לתשלום החוב כנקוב לעיל, יתווספו לתביעה הוצאות ההליכים המשפטיים, לרבות אגרת בית משפט, שכר טרחת עו״ד, ריבית והצמדה וכל הוצאה נוספת אשר תפסקנה ע״י בית המשפט." ,
            "eighth": "במידה והחוב הוסדר על פי הפירוט המופיע להלן טרם קבלת מכתב זה, ראו הודעה זו כמבוטלת. ",
            "ninth": "לבירורים ניתן לפנות לבלינק בטלפון 03-5090045 ",
            "tenth": "התראה נוספת לא תישלח. ",
            "eleventh": "שיטות לתשלום - ישירות לחשבון הבנק המשותף של ועד הבית ",
            "eleventhFirst": "תשלום בכרטיס אשראי",
            "eleventhTwo": "תשלום ישירות דרך הבנק ",
        },
        "total": "סה״כ לתשלום",
        "extraInfo": "*לנוחיותך, ניתן לפרוס את הסכום לתשלומים נוחים דרך מוקד בלינק 03-5090045 ",
        "table": {
            "payment": "תשלום עבור",
            "monthInfo": "פירוט חודשים",
            "sum": "סכום",
            "firstRow": "תשלום ועד חודשי",
            "secondRow": "תשלום מיוחד"
        }
    }
};
