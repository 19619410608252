export const warningLetter = {
    "in_honor": "In honor of:",
    "letter_reason": "You are here by noticed, for not paying housing  debts",
    "debts_title": "Here is the remaining debt",
    "total": "total:",
    "legal_text_title": "section 58 for the law holds absolute responsibility",
    "legal_text": "If within 10 days you debt will not be settled, we will have to start a claims process to avoid legal action we give you payment options with easy installments plan with credit card",
    "regards": "Regards,",
    "issuer": "management company and house representative",
    "currency": "ILS",
    "fileName": "debt_warning_letter",
    "one_time": "For one time payments",
    "month": "For month:",
    "apartment": "Apartment number:",
    "address": "Address:",
    "date": "Date:",
    "title": "Topic: notice for apartment debts",

    "new": {
        "title": "ב – תכרעמ םימולשתה לש",
        "date": "תאריך",
        "to": "לכבוד",
        "address": "כתובת",
        "noticeBeforeLegalAction": "התראה לפני נקיטת הליכים משפטיים",
        "listItems": {
            "first": "לע יפ םימושירה תרבחב ונ רט ם ורדסוה ימולשת דעו תיבה לש ך",
            "second": "בוחה ול ו דע תיבה וניה םאתהב טוריפל לבטב ה ןלהלש :",
            "third": "נציגינו פנו מספר פעמים בבקשה להסדרת החוב, אולם טרם הוסדר התשלום כמתחייב בחוק",
            "forth": "החוב גורם עיכובים וקשיים מרובים בתפקוד הבניין כסדרו ובהחזקתו השוטפת. זאת, כאשר הדירה שבבעלותך נהנית מניהול הבניין, על חשבונם של יתר הדיירים. ",
            "fifth": "על פי סעיף 58  א' לחוק המקרקעין, כל בעל דירה חייב להשתתף בהוצאות הדרושות להחזקתו התקינה ולניהולו של הרכוש המשותף ולא הוא צפוי לעונשים הקבועים בחוק.",
            "sixth": "עליך להסדיר את התשלום",
            "sixthHighlited": "באופן מידי ובכל מקרה לא יאוחר משבוע ימים ממועד מכתב זה.",
            "seventh": "באם החוב לא יוסדר במסגרת הזמן שהוזכרה, תוגש כנגדך תביעה משפטית. במסגרת זו, בנוסף לתשלום החוב כנקוב לעיל, יתווספו לתביעה הוצאות ההליכים המשפטיים, לרבות אגרת בית משפט בסך 758 ש״ח, שכר טרחת עו״ד, ריבית והצמדה וכל הוצאה נוספת אשר תפסקנה ע״י בית המשפט." ,
            "eighth": "במידה והחוב הוסדר על פי הפירוט המופיע להלן טרם קבלת מכתב זה, ראו הודעה זו כמבוטלת. ",
            "ninth": "לבירורים ניתן לפנות לבלינק בטלפון 03-5090045 ",
            "tenth": "התראה נוספת לא תישלח. ",
            "eleventh": "שיטות לתשלום - ישירות לחשבון הבנק המשותף של ועד הבית ",
            "eleventhFirst": "תשלום בכרטיס אשראי",
            "eleventhTwo": "תשלום ישירות דרך הבנק ",
        },
        "total": "סה״כ לתשלום",
        "extraInfo": "*לנוחיותך, ניתן לפרוס את הסכום לתשלומים נוחים דרך מוקד בלינק 03-5090045 ",
        "table": {
            "payment": "תשלום עבור",
            "monthInfo": "פירוט חודשים",
            "sum": "סכום",
            "firstRow": "תשלום ועד חודשי",
            "secondRow": "תשלום מיוחד"
        }
    }
};
