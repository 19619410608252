import { SubMenu } from "react-pro-sidebar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import expenses from "../../../images/newDesign/expenses.svg";
import { constants } from "../../../common/constants/constants";
import CacheData from "../../../common/utils/cacheData";
import BllinkTime from "../../../common/utils/time";
import {hasPremiumAccessSelector} from "../../../store/auth/selectors";
import {MenuNavItem} from "../../sidebar/MenuNavItem";

/**
 * @return {JSX.Element|null}
 * @constructor
 */
export default function ExpensesSidebarMenu({buildingId}) {
    const { t } = useTranslation();
    const currentYear = BllinkTime.getCurrentYear();
    const currentMonth = BllinkTime.getCurrentMonth();
    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const isPremiumUser = useSelector(hasPremiumAccessSelector);

    const tooltip = t(isPremiumUser ? 'expenses.expense_record_tooltip' : 'buildings.upgrade_to_premium');

    return (
        <SubMenu
            title={t('buildings.nav_bar_expenses')}
            icon={<img src={expenses} alt={t('buildings.nav_bar_expenses')} />}>
            <MenuNavItem
                url={`/${constants.pages.setExpenseUrl}/${localBuildingId}`}
                label={t('buildings.nav_bar_record_an_expense')}
                tooltip={tooltip}
                disabled={!isPremiumUser}
                tooltipDisabled={false}
            />
            <MenuNavItem
                url={`/${constants.pages.monthlyExpensesUrl}/${localBuildingId}/${currentMonth}/${currentYear}`}
                label={t('buildings.nav_bar_reports_expenses')}
                tooltip={t('buildings.upgrade_to_premium')}
                disabled={!isPremiumUser}
                tooltipDisabled={isPremiumUser}
            />
        </SubMenu>
    )
}
