export const monitoring = {
    "activeMonitoring": {
        "pageTitle": "Keep track on tenants monitoring",
        "tabs": {
            "inMonitoring": "Apts in monitoring",
            "withoutMonitoring": "Apts without monitoring"
        },
        "totalTenants": "Total num of tenants:",
        "labels": {
            "tenantValue": "Tenant value",
            "actionName": "Action name",
            "nextActionIn": "Next action in",
            "nextActionName": "Next action name",
            "sentAt": "Sent at",
            "totalAmountMissing": "Total amount missing",
            "days": "days",
            "hours": "hours",
            "tenantEmail": "Tenant Email",
            "tenantPhone": "Tenant Phone",
            "ownerEmail": "Owner Email",
            "ownerPhone": "Owner Phone",
        },
        "actions": {
            "welcome": "Welcome",
            "reminder": "Payment Reminder",
            "tenantEmail1": "1st tenant email message",
            "tenantEmail2": "2st tenant email message",
            "tenantEmail3": "3st tenant email message",
            "tenantEmail4": "4st tenant email message",
            "tenantPhone1": "1st tenant SMS",
            "tenantPhone2": "2nd tenant SMS",
            "tenantPhone3": "3rd tenant SMS",
            "tenantPhone4": "4th tenant SMS",
            "ownerEmail1": "1st owner email message",
            "ownerEmail2": "2st owner email message",
            "ownerEmail3": "3st owner email message",
            "ownerEmail4": "4st owner email message",
            "ownerPhone1": "1st owner SMS",
            "ownerPhone2": "2nd owner SMS",
            "ownerPhone3": "3rd owner SMS",
            "ownerPhone4": "4th owner SMS",
        },
        "btns": {
            "stopMonitoring": "Stop monitoring",
            "stopMonitoringApartment": "stop monitoring apartment",
            "startMonitoring": "Start monitoring",
            "startMonitoringApartment": "Start monitoring apartment",
            "aptNotes": "Apt notes",
            "startAll": "Start all",
            "stopAll": "Stop all",
        },
        "faq": {
            "faqs": "FAQs",
            "settingsPage":"settings page",
            "qAndAs": {
                "q1": "We activated the campaign in the past and it sent debt messages for the current month too early.",
                "a1": "Good news! Tenants with an open payment for the current month will only receive messages from the automatic campaign after the tenth of the month.",
                "q2": "What if I have some apartments that I don't want to send messages to?",
                "a2": "Specific apartments can be exempted from the campaign, so that Bllink won't send them messages directly through the interface.",
                "q3": "If most of my tenants pay through you, will I not pay a high fee?",
                "a3": "If you think our fee is high, try calculating how much it costs to call the tenant, request a direct bank transfer to your account, what happens if the transfer fails, and how to make bank adjustments? Manually? And what if the tenant moves out and cancels the instruction? Who follows up on that? In the end, you'll find that the most profitable thing for your business is to allow Bllink to collect digitally and automatically from your tenants.",
                "q4": "How do I know when messages will be sent to tenants? Maybe it's on a day when I'm not in the office?",
                "a4": "The system sends messages to each debtor every five days, but you can easily set restrictions on the days messages are sent to synchronize them with the days you or your team are in the office.",
                "q5": "What if there are both tenants and landlords in the apartment?",
                "a5": "Easy! The tenant receives debt messages for ongoing payments and the landlord for one-time payments. When each of them comes to make a payment, those are the payments they'll see (tenant - ongoing payments, landlord - one-time)\n" +
                    "P.S. Landlords receive a notice of a tenant's debt if the tenant has already received more than 4 notices and still hasn't paid.",
                "q6": "How often does the system send messages to debtors?",
                "a6": "By default, every five days. This can also be edited to specific days on the settings page.",
                "q7": "Does this automation cost us money?",
                "a7": "All SMS messages, emails, and phone calls are on our account. Completely free. The interest is mutual. Ours is more payments through Bllink, and yours is reducing manual tracking of the debtor list.",
                "q8": "Can I change the text that is sent?",
                "a8": "Yes. But we don't recommend it. The text is tested and improved for the thousands of buildings we monitor. However, the text for campaign messages can be changed on the settings page.",
            }
        },
        "successfullyStarted": "The apartment monitoring was successfully started",
        "successfullyStopped": "The apartment monitoring was successfully stopped",
        "tooManyOutOfCampaign": "Error. cannot leave {{maxNum}} apartments out of campaign. please contact customer support",
        "successfullyAllStarted": "All apartments monitoring was successfully started",
        "successfullyAllStopped": "All apartments monitoring was successfully stopped",
        "somethingWentWrong": "Something went wrong",
    }
}
