export const partnerPaymentSettings = {
    title: 'ברוכים הבאים לבלינק {{partnerName}}',
    paymentMethodSelector: {
        title: 'פרטי אמצעי תשלום',
        noPaymentMethod: 'חסר אמצעי תשלום',
        addMethod: 'הוספת אמצעי תשלום'
    },
    autoCampaignSettings: {
        title: 'הגדרות קמפיין אוטומטי',
        autoCampaignDays: {
            title: 'בחירת ימים קמפיין אוטומטי',
            daysInWeek: 'בחירת ימים בשבוע',
            daysInMonth: 'בחירת ימים בחודש',
            daysInMonthInstructions: 'לחיצה ראשונה לתאריך התחלה ולחיצה שנייה לתאריך סיום. לחיצה כפולה לבחירת יום בודד',
            setDays: 'קבע ימים',
            firstWeekOnly: 'שבוע ראשון בלבד',
            lastWeekOnly: 'שבוע אחרון בלבד',
            successMessage: 'קביעת ימים בוצעה בהצלחה',
            errorMessage: 'שגיאה בקביעת ימים',
        },
        autoCampaignText: {
            title: 'עריכת טקסט קמפיין אוטומטי',
            successMessage: 'עריכת טקסט בוצעה בהצלחה',
            errorMessage: 'שגיאה עריכת טקסט',
            bllinkText: 'טקסט בלינק',
            customizedText: 'טקסט מותאם אישית',
            setText: 'קבע טקסט',
            isActive: 'הטקסט הזה מוגדר כרגע',
            messageOptions : {
                message_welcomeMessage: 'הודעת ברוכים הבאים',
                message_firstReminderMessage: 'הודעה ראשונה',
                message_secondReminderMessage: 'הודעה שנייה',
                message_thirdReminderMessageRenter: 'הודעה שלישית - שוכר',
                message_thirdReminderMessageOwner: 'הודעה שלישית - בעלים',
                message_monitoringOnetimeDebt4Phone: 'תזכורת חוב חד פעמית - 4',
                message_monitoringOnetimeDebt3Phone: 'תזכורת חוב חד פעמית - 3',
                message_monitoringOnetimeDebt2Phone: 'תזכורת חוב חד פעמית - 2',
                message_monitoringOnetimeDebt1Phone: 'תזכורת חוב חד פעמית - 1',
                message_monitoringDebt4Phone: 'תזכורת חוב - 4',
                message_monitoringDebt3Phone: 'תזכורת חוב - 3',
                message_monitoringDebt2Phone: 'תזכורת חוב - 2',
                message_monitoringDebt1Phone: 'תזכורת חוב - 1'
            },
            buttons: {
                tenantName: "שם דייר",
                buildingAddress: "כתובת הבניין",
                buildingManagerName: "שם מנהל הבניין",
                paymentLink: "קישור לתשלום",
                bllinkPhone: "מספר טלפון",
                apartmentNumber: "מספר הדירה",
                ownerName: "שם הבעלים",
                personName: "שם המשתמש"
            }
        },
        autoCampaignPaymentFor: {
            title: 'הגבלת הודעות עבור סוגי תשלום',
            successMessage: 'קביעת סוגי תשלום בוצעה בהצלחה',
            errorMessage: 'שגיאה בקביעת סוגי תשלום',
            allowAll: 'אפשר הכל',
            allowSelected: 'אפשר נבחרים',
            continue: 'המשך'
        }
    },
    paymentMethodModal: {
        title: 'בחירת אמצעי התשלום',
        description: 'את התשלום עבור תוספות המערכת ניתן לבצע באמצעות כרטיס אשראי או באמצעות קיזוז מההעברה המתוכננת לחשבון',
        creditCard: 'כרטיס אשראי',
        fromTransfer: 'קיזוז מהעברה'
    },
    systemFeatures: {
        title: 'תוספות מערכת',
        completeSystem:  {
            label: 'מערכת מלאה',
            description: '25 ש״ח לבניין עבור מערכת מלאה ללא הגבלת פיצ׳רים',
        },
        greenInvoice:  {
            label: 'חשבונית ירוקה',
            description: 'תשלום חודשי לפי הסכם התקשרות, ללא הגבלת בניינים או מסמכים',
        },
        maintenance:  {
            label: 'תחזוקה',
            description: 'תשלום חודשי לפי הסכם התקשרות',
        },
        checkScanner:  {
            label: 'סורק שיקים',
            description: 'תשלום חודשי לפי הסכם התקשרות',
        },
        autoCampaignSettings:  {
            label: 'בחירה ידנית לקמפיין האוטומטי',
            description: 'מאפשר בחירה ידנית של הימים בהם ישלחו הודעות מהקמפיין האוטומטי',
        },
        active: 'פעיל',
        disabled: 'לא פעיל',
        actions: {
            add: 'הוספה'
        }
    },
    notifications: {
        paymentMethodEstablished: 'הוקמה אמצעי תשלום חדש'
    }
};
