import styled from 'styled-components';
import {ProSidebar} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';

export const SidebarStyled = styled(ProSidebar)`
  .bg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .bg-small {
    position: absolute;
    bottom: 50px;
    left: 0;
  }
  &#sidebar-content {
    position: fixed;
    width: ${props => props.theme.sideBarWidth};
    height: 100%;
    .popper-inner {
      padding: 0;
      background: #fff;
      box-shadow: -10px 48px 140px rgba(126, 123, 160, .2);
    }
    &.collapsed {
      width: ${props => props.theme.sideBarCollapsedWidth};
      min-width: ${props => props.theme.sideBarCollapsedWidth};
      .pro-sidebar-header {
        position: relative;
        inset-inline-start: -180px;
        .logo-link {
          position: relative;
          inset-inline-start: -100%;
          opacity: 0;
        }
      }
    }
    .pro-sidebar-inner {
      background: #fff;
      color: ${props => props.theme.textLight};
    }
    .pro-sidebar-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 40px 20px;
      border: none !important;
      transition: all .3s ease-in;
      .logo-link {
        transition: all .3s ease-in;
        z-index: 10;
      }
      .pin-icon {
        z-index: 10;
        width: 30px;
        min-width: 30px;
        cursor: pointer;

        img {
          width: 100%;
        }
      }
    }
    .pro-icon-wrapper {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-inline-end: 20px;
      background: transparent;
      img {
        width: 100%;
        height: auto;
      }
    }
    .pro-menu-item {
      font-size: 16px;
      &.active {
        color: ${props => props.theme.textLight};
        & > .pro-inner-item {
          position: relative;
          color: ${props => props.theme.main};
          font-weight: bold;
          background: ${props => props.rtl ? 'linear-gradient(90.1deg, rgba(196, 196, 196, 0) 0.07%, rgba(139, 117, 245, 0.15) 99.91%)' : 'linear-gradient(90.1deg, rgba(139, 117, 245, 0.15) 0.07%, rgba(196, 196, 196, 0) 99.91%)'};
          &:before {
            content: "";
            position: absolute;
            right: 0;
            left: 0;
            top: 50%;
            width: 4px;
            height: 80%;
            background: linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%);
            box-shadow: 0 0 5px rgba(139, 117, 245, .4);
            border-radius: ${props => props.rtl ? '8px 0 0 8px' : '0 8px 8px 0'};
            transform: translateY(-50%);
          }
        }
      }
      &.pro-sub-menu {
        &.open {
          & > .pro-inner-item::before {
            background: linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%) !important;
          }
        }
      }
    }
    .pro-inner-item {
      padding: 13px 30px;
      font-size: 16px;
      a {
        padding: 0;
        color: inherit;
      }
      &:hover {
        color: ${props => props.theme.textLight};
      }
    }
    .pro-inner-list-item {
      background: transparent;
    }
    @media (max-width: 768px) {
      .pin-icon {
        display: none;
      }
      &.pro-sidebar.md {
        inset-inline-start: -330px;
        &.toggled {
          inset-inline-start: 0;
        }
      }
      .mobile-hide {
        display: none;
      }
    }
  }
`;
