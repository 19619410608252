import {BllinkLogger} from "../utils/bllink_loggers";
import * as Sentry from "@sentry/react";
import {constants} from "../constants/constants";
const gae = constants.GoogleAnalytics.Events;


/**
 * @param {string} categoryName
 * @param {string} eventName
 * @param {string|number|{}} value
 * @param {string} labelName
 * // if adding more, then need to make sure it exists in GA custom
 * dimension and in google tag manager and in https://analytics.google.com/analytics/web/#/a47715142p402334044/admin/customdefinitions/hub
 * @param {{
 *      event_apartment_type?: 'justOwner'|'hasBoth'|'justRenter'
 *      event_payer?: 'owner' | 'renter'
 *      event_payment_page_amount?: 'less_than_1000' | 'less_than_3000' | 'less_than_7000' | 'more_than_7000'
 *      event_payment_page_context?: 'onGoing' | 'onGoing,debt' | 'onGoing,oneTime' | 'oneTime',
 *      isLoan?: boolean
 * }} extraParams
 * */
async function GAEvent(categoryName, eventName, value, labelName = '', extraParams = null){
    try {
        extraParams = extraParams ?? {};
        let payload = {
            event: eventName,
            ...extraParams
        }
        // to make sure we have some value
        value = value ?? 1;
        window.dataLayer = window.dataLayer || [];
        if (eventName === gae.purchase){
            payload.ecommerce =  value;
        }else {
            // value is currently ignore . need to split to 3 cases. money, count and string values
            // in ga -> admin -> Data Display -> Custom definitions -> Custom dimensions
            // will probably need to split 2 different labels because count is done automatically
            // amount - in purchase event is a duplicate of purchase value - send it anyway as event_money_amount
            // string - send it as event_string_value ?
            // considering them can be done in explore-> report -> free form -> dimensions (https://analytics.google.com/analytics/web/#/analysis/p402334044/edit/Ja1JD2LKSPyzbKoJ5se4cw)
            // and also add it to tag manager under  GA4 Param Settings https://tagmanager.google.com/?hl=en#/container/accounts/6002101020/containers/32537426/workspaces/10/variables
            if (isNaN(value)) {
                payload.event_value_string =  value;
            }else{
                if (value !== 1) { // an actual amount
                    payload.event_value_amount = value;
                }
            }

            payload.event_label =  labelName;
            payload.event_category =  categoryName;
        }
        window.dataLayer.push(payload);
    } catch (e) {
        Sentry.captureMessage('Unable track event, check payload', 'error');
    }
}


function GAGeneratePaymentAttributes(tenantSlice) {

    const hasOneTimeSelectedPayments = tenantSlice?.oneTimeSelectedPayments?.length > 0;
    const hasPastSelectedPayments = tenantSlice?.debtSelectedPayments?.length > 0;
    const hasOnGoingSelectedPayments = tenantSlice?.onGoingSelectedPayments?.length > 0;
    let amount = 0;
    let pageContextArray = [];
    if (hasOneTimeSelectedPayments) {
        pageContextArray.push('oneTime');
        amount += tenantSlice?.oneTimeSelectedPayments.reduce((sum, item) => sum + (item.amount_left?.value), 0);;
    }
    if (hasPastSelectedPayments) {
        pageContextArray.push('debt');
        amount += tenantSlice?.debtSelectedPayments.reduce((sum, item) => sum + (item.amount_left?.value), 0);
    }
    if (hasOnGoingSelectedPayments) {
        pageContextArray.push('onGoing');
        amount += tenantSlice?.onGoingSelectedPayments.reduce((sum, item) => sum + (item.amount_left?.value), 0);
    }
    if (pageContextArray.length === 3) {
        pageContextArray = ['all'];
    }
    const pageContext = pageContextArray.toString();
    const totalAmountText = getAmountText(amount);
    // these are the saved attribute names at GTM and GA.
    // do not change those
    return {
        event_payment_page_context: pageContext,
        event_payment_page_amount: totalAmountText,
    }
}

function getAmountText(amount) {
    const thresholds = [1000, 3000, 7000];
    for (let i = 0; i < thresholds.length; i++) {
        if (amount < thresholds[i]) {
            return `less_than_${thresholds[i]}`;
        }
    }
    return `more_than_${thresholds[thresholds.length-1]}`;
}

BllinkLogger.info(`initialized google analytics`);

export {
    GAEvent,
    GAGeneratePaymentAttributes
}
