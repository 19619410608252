export const store = {
    storePageTitle: 'שליחת פניה וקבלת הצעת מחיר',
    buttons: {
        request: 'פנייה לקבלת שירות',
        showMore: 'הצג עוד',
        hide: 'צמצם',
    },
    storeItem: {
        addDescription: 'רצוי להוסיף תיאור לשירות המבוקש',
        dateSelector: 'מהו הזמן המועדף לקבלת השירות?',
        phoneNumber: 'לאיזה מספר ניתן לחזור אליך?',
        sendRequest: 'שלח פניה'
    },
    emailSuccess: {
        title: 'תודה רבה!',
        description: 'שלחנו את הפניה שלך למנהל הבניין. הוא יצור איתך קשר בהקדם האפשרי לתיאום קבלת השירות ומתן פרטים נוספים',
        button: 'תודה'
    }
}