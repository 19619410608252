import axios from 'axios';
import { SETTINGS } from './settings';
import ErrorsHandler from './errors/handleErrors';
import {BllinkLogger} from './utils/bllink_loggers';
import {GAEvent} from './google_analytics/google_analytics';
import CacheData from "./utils/cacheData";
import { constants } from "./constants/constants";
import {ManagersApi} from "../../client/managers.api";
import {OperationsApi} from "../../client/operations.api";
import {TenantsApi} from "../../client/tenants.api";
import {MonitoringApi} from "../../client/monitoring.api";
import {PartnersApi} from "../../client/partners.api";
import {GraphqlApi} from "../../client/graphql.api";
import {ImagesApi} from "../../client/images.api";
import {CommentsApi} from "../../client/comments.api";
import HttpCodes from "http-status-codes";
import {isMobile} from "react-device-detect";
import {AiChatApi} from "../../client/aiChat.api";
const { detect } = require('detect-browser');



class ServerData {

    /** @type {ManagersApi} */ managers;

    loginUrl = `/${constants.pages.loginUrl}`;
    handleForbiddenErrors = () => {};

    constructor(url, timeOut, headers, loginUrl, handleForbiddenErrors = () => {}) {
        this.baseUrl = url || SETTINGS.server.baseUrl;
        const browser = detect() || {};
        headers = headers || {};
        if (headers.accessToken){
            headers.Authorization = `Bearer ${headers.accessToken}`
            delete headers.accessToken
            this.autheticated = true
        }
        if (loginUrl) {
            this.loginUrl = loginUrl;
            this.isTenantClient = true;
        }
        headersValidator(headers);
        this.timeout = timeOut || SETTINGS.server.timeout || 30000;
        this.axios = axios.create({
            baseURL: this.baseUrl,
            timeout: this.timeout, // todo change back once payments to months api is back to normal
            headers: {
                ...headers,
                'X-Bllink-Header': 'Bllink-API-WebApp',
                'X-Bllink-isMobile': isMobile,
                'X-Bllink-browser': `${browser.name}-${browser.version}-${browser.os}`,
                'X-Bllink-is-Tenant-Logged-In': !!headers.isTenant,
                'X-Bllink-is-Logged-In': !!this.autheticated
            }
        });

        this.managers = new ManagersApi(this);
        this.operations = new OperationsApi(this);
        this.tenants = new TenantsApi(this);
        this.monitoring = new MonitoringApi(this);
        this.partners = new PartnersApi(this);
        this.graphql = new GraphqlApi(this);
        this.images = new ImagesApi(this);
        this.comments = new CommentsApi(this);
        this.aiChat = new AiChatApi(this);
        this.handleForbiddenErrors = handleForbiddenErrors;
    }

    /**
     * for demo buildings
     * */
    async initMockServerDataClient() {
        if (this.mockClient == null) {
            // load dynamically to exclude from common bundle
            const module = await import('./mocks/mockServerData');
            const MockServerData = module.default;
            this.mockClient = new MockServerData(this.axios); // for demo buildings
        }
    }

    async getClient() {
        if (SETTINGS.server.useMocks){
            if (this.mockClient == null) {
                // BllinkLogger.info(`using mock server (for some urls who are defined as mocked)`)
                await this.initMockServerDataClient();
            }
            return this.mockClient;
        }
        return this.axios;
    }

    /**
     * @param {string} uri
     * @param {object} urlParams
     * @param {HandleErrorOptions} [handleErrorParams={}]
     * @param {boolean} [asBlob=false]
     * */
    async get(uri, urlParams, handleErrorParams = {}, asBlob = false) {
        const payload = this.getParams(urlParams);

        // extremely dangerous, but we need to do this for now
        // comment out in case of problems in prod
        if (isSpecialDemoBuilding(uri)){
            await this.initMockServerDataClient();
            const response = await this.mockClient.get(uri, {params: urlParams});
            return handleSuccess(response);
        }

        console.log(`GET to uri ${uri} and `, JSON.stringify(urlParams));
        if (uri.includes('null')){
            throw new Error(`uri contains null`);
        }

        payload.headers = headersValidator(payload.headers);
        if (asBlob) {
            payload.responseType = 'blob';
        }
        const client = await this.getClient();
        return await client.get(uri, payload)
            .then(handleSuccess)
            .catch((error) => handleError(error, handleErrorParams, this));
    }

    /**
     * @param {string} uri
     * @param {object} params
     * @param {HandleErrorOptions & {hideParams: boolean}} [options]
     * @param {boolean} [options.hideParams=true]
     * @returns {Promise<{newPlanID: string, success: boolean}>}
     * */
    async post(uri, params, options) {
        options = options || {hideParams: true};
        let extraDataPayload = {};
        const headers = this.headers(params);
        if (headers){
            extraDataPayload.headers = headers
        }
        if (options.hideParams){  // to protect sensetive data if needed
            console.log(`POST to uri ${uri}`)
        }else{
            console.log(`POST to uri ${uri} and params are `, JSON.stringify(params))
        }

        extraDataPayload.headers = headersValidator(extraDataPayload.headers);
        const client = await this.getClient();
        return await client.post(uri, params, extraDataPayload)
            .then(handleSuccess)
            .catch(error => handleError(error, { avoidError: options.avoidError ?? false, throwError: options.throwError ?? false }, this));
    }

    /**
     * @param {string} uri
     * @param {object} params
     * @param {HandleErrorOptions & {hideParams: boolean}} [options]
     * @param {boolean} [options.hideParams=true]
     * */
    async put(uri, params, options) {
        options = options || {hideParams: true};
        let extraDataPayload = {};
        const headers = this.headers();
        if (headers){
            extraDataPayload.headers = headers
        }
        if (options.hideParams){  // to protect sensetive data if needed
            console.log(`PUT to uri ${uri}`)
        }else{
            console.log(`PUT to uri ${uri} and params are `, JSON.stringify(params))
        }
        extraDataPayload.headers = headersValidator(extraDataPayload.headers);
        const client = await this.getClient();
        return await client.put(uri, params, extraDataPayload)
            .then(handleSuccess)
            .catch(error => handleError(error, { avoidError: options.avoidError ?? false, throwError: options.throwError ?? false }, this));
    }

    async patch(uri, params, options) {
        options = options || {hideParams: true};
        let extraDataPayload = {};
        const headers = this.headers();
        if (headers){
            extraDataPayload.headers = headers
        }

        if (options.hideParams) console.log(`PATCH to uri ${uri}`)
        else console.log(`PATCH to uri ${uri} and params are `, JSON.stringify(params))

        extraDataPayload.headers = headersValidator(extraDataPayload.headers);
        const client = await this.getClient();
        return await client.patch(uri, params, extraDataPayload)
            .then(handleSuccess)
            .catch(error => handleError(error, { avoidError: options.avoidError ?? false, throwError: options.throwError ?? false }, this));
    }

    async delete(uri, urlParams = {}) {
        const payload = this.getParams(urlParams);
        payload.headers = headersValidator(payload.headers);
        try {
            const client = await this.getClient();
            const resp = await client.delete(uri, payload);
            return handleSuccess(resp);
        } catch (e) {
            return handleError(e, {}, this);
        }
    }

    getParams(urlParams){
        /*
         */
        let payload = {params: urlParams};
        let headers = this.headers();
        if (headers) {
            payload.headers = headers
        }

        console.log(`GET payload will be `, payload);
        return payload;
    }

    headers(params){

        params = params || {}
        const tenantDetails = CacheData.fetchCache('tenantDetails');
        let headers;
        if (tenantDetails){
            let details = {'x-phone': tenantDetails.phone}
            // let details = {'x-phone': 'a 你 好"'}
            if (tenantDetails.email){
                details['x-email'] = tenantDetails.email
            }
            headers = details
        }
        if (params.headers){
            headers = {...headers, ...params.headers}
        }

        return headers
    }


}

function isSpecialDemoBuilding(uri){
    // https://app.asana.com/0/1201855509796663/1201893194139289
    // demo building FE mocks data for  specific buildings
    // built for demo purposes, for now
    const specialDemoBuildings = constants.demoBuildingIDS;
    return specialDemoBuildings.some(demoBuildingID => uri.includes(demoBuildingID));
}

/**
 * @typedef {object} HandleErrorOptions
 * @property {boolean} [throwError=false]
 * @property {boolean} [avoidError=false]
 * */

/**
 * @param {object} error
 * @param {HandleErrorOptions} [options={}]
 * */
async function handleError(error, options = {}, currentClient = {}) {
    // handle error
    let result;
    console.log(`error in response`, JSON.stringify(error));
    if (options.throwError) {
        if (error?.response?.status === HttpCodes.FORBIDDEN) {
            await currentClient?.handleForbiddenErrors();
            // need to be sure that dispatches will be finished
            const redirectUrl = currentClient?.loginUrl ?? `/${constants.pages.loginUrl}`;
            console.log(`redirecting to ${redirectUrl} after forbidden response from server`);
            setTimeout(() => {window.location.href = redirectUrl}, 0);
            return;
        } else {
            throw error;
        }
    }
    if (options.avoidError) return error;
    if (!error.response) {
        result = ErrorsHandler.handleServerErrors(error.message, error);
        if (!result.avoidError) {
            throw new Error(error.message);
        }

        return result;
    }

    // NOTE: event name MUST be a string
    GAEvent('ServerError', String(error.response.status), 1, 'errorFromServer');
    let data = error.response.data;
    if ((data instanceof Blob) && data.type === 'application/json') {
        data = JSON.parse(await data.text());
    }
    const errorData = Object.assign({}, data);
    if (!errorData.message) errorData.message = error.message;
    result = ErrorsHandler.handleServerErrors(error.response.status, errorData);
    if (!result.avoidError) {
        throw new Error(errorData.message);
    }

    return result;
}


function validator(str) {
    // https://sentry.io/organizations/bllink/issues/2730558012/?environment=production&project=5424386&referrer=alert_email
    // TypeError: Failed to execute 'setRequestHeader' on 'XMLHttpRequest': String contains non ISO-8859-1 code point.
    return !/[^\u0000-\u00ff]/g.test(str);
}

function fixBrokenHeaders(brokenHeadersAsString){
    return brokenHeadersAsString.replace(/[^\x00-\x7F]/g, "")
}

function headersValidator(headers = {}) {
    // https://sentry.io/organizations/bllink/issues/2730558012/?environment=production&project=5424386&referrer=alert_email
    // TypeError: Failed to execute 'setRequestHeader' on 'XMLHttpRequest': String contains non ISO-8859-1 code point.
    let headersAsString = JSON.stringify(headers);
    if (!validator(headersAsString)) {
        try{
            headersAsString = fixBrokenHeaders(headersAsString);
            headers = JSON.parse(headersAsString);
            return headers;
        }catch (e){
            throw new Error(`non iso headers. tried to fix but failed.  request will fail  ${JSON.stringify(headers)}  ${e.message}`);
        }
    }
    try {
        // for faster debugging on BE
        headers['X-Bllink-Page-Origin'] = window.location.pathname
    }catch (e){

    }
    return headers
}

function handleSuccess(response){
    const data = response.data;
    if (data instanceof Blob) {
        data['fileName'] = response.headers['content-disposition'].split('filename=')[1];
    }
    BllinkLogger.info(`success response data is `, data);
    return data
}

export default ServerData;
