export const buildings = {
    "your_buildings": "Your buildings",
    "your_buildings_sub_title": "Accepting credit card payments saves about 20 minutes per month per apartment!",
    "you_have_x_active_buildings": "You have {{number}} active buildings",
    "building_x": "Building {{index}}",
    "building_address": "Building address",
    "add_new_building": "Add new building",
    "new_building_details": "New building details",
    "nav_bar_manager_chat": "personal assistant",
    "nav_bar_additional_services_tooltip": "additional offers from approved vendors",
    "nav_bar_additional_services": "service providers",
    "nav_bar_new_fault_tenants": "new ticket",
    "enable_building_today": "Will enable you to collect your co-op bills today!",
    "opening_building": " Opening a building",
    "entrance_optional": "Entrance (optional)",
    "building_manager_name": "Building manager name",
    "adding_another_manager": "Adding a manager",
    "nav_bar_actions": "Actions",
    "bank_account_owner": "Bank account owner name",
    "bank_name": "Bank name",
    "account_number": "Account number",
    "branch_number": "Branch number",
    "terms_of_use": "Terms of use",
    "cheque_photo": "Photo of check",
    "type_of_document": "Document type",
    "proof_of_ownership": "Proof of bank account ownership",
    "edit": "Edit",
    "legacyPayment": "Manually set",
    "total_payments_this_month": "This Month Payments",
    "total_payments_this_month_tooltip": "Includes credit payments and bank charges via Bllink. Includes only active buildings. Equal to charges via Bllink. Must consider tourist charges ftom this and last month and reduce returns.",
    "hours_saved": "Hours saved for manual collection",
    "hours_saved_tooltip": "Depending on the number of apartments paid during the current month",
    "total_manual_deposit": "Bank Deposits Total Payments",
    "total_manual_deposit_tooltip": "Sum of cash and check payments for current month",
    "total_debts_for_one_time": "Special Payments Debt",
    "total_debts_for_one_time_tooltip": "Sum of all unpaid special payments for all apartments, excluding inactive buildings",
    "total_debts_for_monthly": "Monthly HOA Debt",
    "total_debts_for_monthly_tooltip": "Sum of debts for past monthly payments and current month for all apartments, excluding inactive buildings",
    "no_payments_made_this_month_yet": "No payments made this month yet",
    "hours": "hours",
    "payment_by_methods_title": "Payments by Method",
    "connect_bank_account": "Connect bank account",
    "cheques": "Checks",
    "notSet": "Not determined",
    "wires": "Bank wire",
    "bit": "Bit",
    "creditCards": "Credit card",
    "bllinkCheque": "Cheque for bllink",
    "creditCard": "Credit card",
    "applePay": "Apple pay",
    "googlePay": "Google pay",
    "onlineDirectWire": "Bank transfer through Blink",
    "unknownPaymentMethod": "Unknown",
    "tenantRecord": "Tenant Record",
    "tenantRecordList": "Payments Inquiry (tenant reporting)",
    "monthly_amount_passed_to_building_title": "Credit Card Transfers",
    "monthly_amount_hover_title": "Monthly amount",
    "monthly_amount_hover_title_admin": "Monthly amount (it should be the same as in monthly report)",
    "nav_bar_edit_building": "Edit building",
    "nav_bar_edit_contacts": "Edit contacts",
    "nav_bar_edit_building_commission": "Edit building commission",
    "nav_bar_buildings_list": "Buildings list",
    "nav_bar_buildings_data": "Buildings data",
    "nav_bar_all_buildings_data": "All buildings data",
    "nav_bar_main_screen": "Building dashboard",
    "nav_bar_ai_agent": "AI personal assistant",
    "nav_bar_tenant_list": "Tenants list",
    "nav_bar_archive": "Documents archive",
    "nav_bar_maintenance": "Maintenance",
    "nav_bar_new_leads": "New Leads",
    "nav_bar_resource_center": "Resource Center",
    "nav_bar_maintenance_management": "Maintenance",
    "nav_bar_maintenance_systems": "Building Systems",
    "nav_bar_maintenance_tenant_reports": "Tenant Requests",
    "nav_bar_maintenance_tenant_reports_short": "Requests",
    "nav_bar_maintenance_on_going": "Preventive tasks",
    "nav_bar_maintenance_on_going_short": "Tasks",
    "nav_bar_maintenance_on_going_mobile": "Tasks and Systems",
    "nav_bar_maintenance_reports": "Reports",
    "nav_bar_maintenance_documents": "Documents",
    "nav_bar_maintenance_vendors": "Vendors",
    "nav_bar_maintenance_permissions": "Maintenance Permissions",
    "nav_bar_shared_space": "Shared Space",
    "nav_bar_votes": "Votes",
    "nav_bar_reports": "Financial reports",
    "nav_bar_reports_yearly_cashflow": "Yearly cashflow",
    "nav_bar_reports_monthly_cashflow": "Income by Month",
    "nav_bar_reports_apartment_cashflow": "Apartment",
    "nav_bar_reports_apartment": "Income by Apartment",
    "nav_bar_reports_yearly_by_months": "Yearly Collection",
    "nav_bar_reports_yearly_by_one_time": "Special (one-time) Collection",
    "nav_bar_reports_update_payment": "Add payment",
    "nav_bar_reports_apartment_collection": "Collection",
    "nav_bar_reports_apartment_debts": "Debts",
    "nav_bar_reports_apartment_tenants": "Tenants",
    "nav_bar_update_payments": "Update payment rates",
    "nav_bar_payments_rates": "Payment rates",
    "nav_bar_expenses": "Expenses",
    "nav_bar_record_an_expense": "Add expense",
    "nav_bar_update_rates": "Update monthly rates",
    "building_created_message": "Building is successfully created. You are being transferred to the main screen",
    "must_upload_document": "Proof of ownership document is required",
    "nav_bar_update_payments_year": "Update payment fees {{year}}",
    "nav_bar_view_monthly": "View monthly payments",
    "nav_bar_special_payments": "Special payments",
    "nav_bar_add_one_time": "Add new one time payment",
    "nav_bar_reports_expenses": "Expense report",
    "nav_bar_reports_monthly_expenses": "Monthly expenses",
    "nav_bar_reports_expenses_balance": "Balance",
    "nav_bar_bank_adjustment": "Bank adjustments",
    "nav_bar_accounting": "Accounting",
    "nav_bar_bank_reconciliation": "Performing Bank Adjustments",
    "nav_bar_debt_list": "Debt list",
    "nav_bar_payments": "Payments",
    "nav_bar_collection_data": "Collection data",
    "nav_bar_collection": "Collection",
    "nav_bar_brochure": "Building brochure",
    "nav_bar_rewards": "Rewards",
    "nav_bar_refresh": "Refresh building data",
    "nav_bar_update_payment_plans": "check payment plans amounts",
    "nav_bar_admin": "Admin menu",
    "nav_bar_new_bank_adjustments": "New bank adjustments",
    "nav_bar_auto_campaign": "Auto campaign",
    "nav_bar_message_platform": "Message Platform",
    "nav_bar_monitoring": "Monitoring",
    "nav_bar_search_payments": "Search payments",
    "nav_bar_set_broadcast": "Set broadcast",
    "nav_bar_products_at_a_discount": "Products at a discount",
    "nav_bar_funds_early_transfer": "Withdraw Now",
    "nav_bar_partner_profile": "Partner profile",
    "nav_bar_demo_sign_up_real_building": "Open real building",
    "nav_bar_demo_sign_up_real_building_tooltip": "open real building with our AI bot",
    "nav_bar_building_insurance_offer_tooltip": "insurance offer - nechonim",
    "nav_bar_building_insurance_offer": "building insurance offer",
    "upgrade_to_premium": "Upgrade to premium",
    "address_pop_up": {
        "header": "Manual entering address for a new building",
        "edit_address": "Edit address (make sure you spell it as it appears in google maps)",
        "labels": {
            "street_name": "Street name(he)",
            "street_number": "Street number",
            "city": "City(he)",
            "country": "Country(he)",
            "entrance": "Entrance(optional)",
            "building_title": "Building Address",
            "nickname": "Nickname (in case address does not appear in google/special name for building)",
        },
        "update": "Update"
    },
    "lead_page": {
      "title": "New leads",
      "content_part_1": "This page is locked and will open when the 20th building joins your account - currently you have {{managerAllBuildingsCount}} buildings",
      "content_part_2": "On this page will appear relevant leads of residential buildings looking for a management company",
      "button": "Adding buildings with a representative",
      "notification": "Perfect! Someone from our team will contact you shortly"
    },
    "nav_bar_upload_payment": "Upload offline payment",
    "collection_status_title": "Collection status",
    "collection_status_uploaded": "Collection status was uploaded",
    "something_wrong": "Something went wrong",
    "didntManagerToFindNewPrimary": "Didnt Manage To Find New Primary Contact. The apartment will be left with no primary {{typeOfTenant}}",
    "editBuildingTitle": "Edit building address",
    "open_new_building": "Open a new account",
    "autoCheque": "Digital check through a check scanner",
    "nav_bar_update_one_time": "Updating",
    "document_too_large": "Image or document is too large. Please select a file smaller than 2 MB",
    "add_bank_details_title": "Where to transfer the money?",
    "building_added": "The building was successfully created in the Bllink system",
    "actions_left": "Now all you have left to do is set up the bank account that we will know where to transfer the tenants' payments",
    "proof_of_ownership_title": "Confirmation of account ownership or photocopy of a check are necessary to complete of the process",
    "no_entrance": "Without entrance",
    "entrance": "Entrance",
    "collection_status_uploaded_in_background": "10 lines and sent for background update. It will be possible to check the status in an annual report in about 10 minutes",
    "cash": "Cash",
    "bankCharge": "Bank charge",
    "confirm": "Confirm",
    "full_address": "Full address",
    "dashboard_title": "Dashboard Analytics",
    "general_building_data": "General Building data",
    "building_score": "Building score",
    "today_stats": "Today stats",
    "new_payments": "New payments",
    "recurring_payments": "Recurring payments",
    "last_actions": "Last actions",
    "financial_data": "Financial data",
    "whatsapp": "WhatsApp",
    "ivr": "auto dialer",
    "incomes": "Incomes",
    "expenses": "Expenses",
    "waiting": "Waiting",
    "to": "to",
    "from": "from",
    "payment_method_type": "Payment method type",
    "maintenance_manager_title": "Tenant Requests",
    "open_building": "Open a new building",
    "withdrawal_now_description": "To find out the immediate balance for withdrawal to the bank account and making a money advance",
    "withdrawal_now_phone_number": "contact 03-5090045",
    "change_building": "Change Building",
    "will_be_delivered": "Will be delivered to {{buildingName}} apartment {{apartmentNumber}}",
    "will_be_charged_from": "Will be charged from {{last4Digits}} ****",
};
