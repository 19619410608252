import { createSlice } from '@reduxjs/toolkit';
import {fetchManagerBuildings, fetchPartnerFeatures} from "./actions";
import {getPartnerPaymentDetails} from "./actions";

const adminSlice = createSlice({
    name: 'manager',
    initialState: {
        buildings: { buildings: [], partner: {} },
        buildingsPending: false,
        tab: null,
        partnerFeatures: {},
        partnerPaymentDetails: {},
    },
    reducers: {
        changeTab: (state, action) => {
            state.tab = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchManagerBuildings.fulfilled, (state, action) => {
            state.buildings = action.payload;
            state.buildingsPending = false;
        });
        builder.addCase(fetchManagerBuildings.rejected, (state, action) => {
            state.buildings = { buildings: [], partner: {} };
            state.buildingsPending = false;
            console.error(action.payload);
        });
        builder.addCase(fetchManagerBuildings.pending, (state, action) => {
            state.buildingsPending = true;
        });
        builder.addCase(fetchPartnerFeatures.fulfilled, (state, action) => {
            if(action.payload?.featureValues?.length) {
                action.payload.featureValues.forEach(featureValue => {
                    if(!state.partnerFeatures) state.partnerFeatures = {}
                    state.partnerFeatures[featureValue.Feature?.featureName] = featureValue.value;
                })
            } else {
                state.partnerFeatures = {};
            }
        });
        builder.addCase(fetchPartnerFeatures.rejected, (state, action) => {
            state.partnerFeatures = {};
        });
        builder.addCase(getPartnerPaymentDetails.fulfilled, (state, { payload }) => {
            state.partnerPaymentDetails = {
                ...state.partnerPaymentDetails,
                ...payload
            };
        })
        builder.addCase(getPartnerPaymentDetails.rejected, (state, action) => {
            state.partnerPaymentDetails = {};
        })
    },
});

export const {
    changeTab
} = adminSlice.actions;
export default adminSlice.reducer;
