import {useState, useRef, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {SwitchTransition, CSSTransition} from 'react-transition-group';
import {StyledModal} from '../../common/shared/modal.styled';
import PaymentUpload from './PdfUpload';
import {useTranslation} from 'react-i18next';
import {useAccessToken} from '../../common/hooks/useAccessToken';
import {useDispatch} from 'react-redux';
import {showLoader, hideLoader} from '../../store/common/slice';
import PaymentResult from './Result';
import { getBuildingTitleText} from '../../store/buildings/actions';
import {NotificationManager} from 'react-notifications';
import { constants } from '../../common/constants/constants';
import {GAEvent} from '../../common/google_analytics/google_analytics';
import {fileToBase64} from "../../../utils/fileToBase64";

export default function AutoRecordPaymentPopup({show, onHide, buildingID}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const [renderForm, setRenderForm] = useState(false);
    const [paymentData, setPaymentData] = useState({});
    const [title, setTitle] = useState('');

    const client = useAccessToken();

    useEffect(() => {
        if (!client) return;
        (async () => {
            const titleData = await dispatch(getBuildingTitleText({t, buildingID}));
            setTitle(titleData.payload);
        })();
    }, [client]);

    const onFileAdd = async (evt, source) => {
        evt.preventDefault();
        if (source?.length > 0) {
            dispatch(showLoader());
            const base64EncodedPdfFile = await fileToBase64(source[0]);
            try {
                const response = await client.post('/managers/import/pdf', { base64EncodedPdfFile });
                setPaymentData(response.data);
                setRenderForm(true);
            } catch (e) {
                NotificationManager.error(t('payment_settings.notifications.uploadFailure'));
            }

            dispatch(hideLoader());
        }
    };

    const createPaymentPlan = async (allPayments, paymentsByMonths, apartmentNumber) => {
        dispatch(showLoader());

        const totalToPay = allPayments.reduce((a, b) => {
            return a + b.amount.value
        }, 0);
        const payments = allPayments.map(({errors, ...props}) => props);
        const months = paymentsByMonths.map(({errors, ...props}) => props);

        // NOTE: we are ignoring building address from pdf-data here, it may be incorrect
        const payload = {
            buildingID,
            apartmentNumber,
            totalToPay,
            paying_for: {
                past_payments: {
                    number_of_installments: 1,
                    months
                }
            },
           ...payments,
        };

        if (buildingID == null || apartmentNumber == null) {
            console.error('[AutoRecordPaymentPopup] incorrect API call, path parameters are undefined');
        }
        const result = await client.post(`/managers/external-payments/${buildingID}/${apartmentNumber}`, payload);

        dispatch(hideLoader());

        const cartID = result.cartID;
        if (cartID) {
            GAEvent(constants.GoogleAnalytics.Events.offlinePayments,
                'successAddPayment',
                paymentData.totalToPay,
                payments[0].paymentMethod, // FIXME: in theory one pdf may contains few payments by few methods
            );
            NotificationManager.success(t('payment_settings.notifications.approveSuccess'));
        }
    }

    return (
        <StyledModal
            centered
            dialogClassName="auto-bank-charge"
            show={show}
            onHide={onHide}
        >
            <span
                className={'modal-close'}
                onClick={onHide}
            >
                &#215;
            </span>
            <Modal.Body>
                <h3>
                    <span>{t('payment_settings.recordOffline')}</span><br/>
                    <span>{title}</span>
                </h3>
                <SwitchTransition mode={'out-in'}>
                    <CSSTransition
                        key={renderForm ? 'form' : 'upload'}
                        timeout={300}
                        classNames="fade"
                        nodeRef={nodeRef}
                    >
                        <div className="transition-container" ref={nodeRef}>
                            {
                                renderForm ? (
                                    <PaymentResult
                                        buildingId={buildingID}
                                        pdfData={paymentData}
                                        onDismiss={() => setRenderForm(false)}
                                        onApprove={createPaymentPlan}
                                    />
                                ) : (
                                    <PaymentUpload
                                        handleFileAdd={onFileAdd}
                                    />
                                )
                            }
                        </div>
                    </CSSTransition>
                </SwitchTransition>
            </Modal.Body>
        </StyledModal>
    )
}
