export class CommentsApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {number} commentId
     * @return {Promise<Comment>}
     * */
    updateCommentsAck(commentId) {
        return this.#client.post(`managers/comments/${commentId}/ack`);
    }
}
