export const monthsShort = {
    '1': 'ינו׳',
    '2': 'פבר׳',
    '3': 'מרץ',
    '4': 'אפר׳',
    '5': 'מאי',
    '6': 'יוני',
    '7': 'יולי',
    '8': 'אוג׳',
    '9': 'ספט׳',
    '10': 'אוק׳',
    '11': 'נוב׳',
    '12': 'דצמ׳',
};
