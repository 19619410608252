import { MenuItem } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CacheData from "../../../common/utils/cacheData";
import { SideMenu } from "../sideMenu/sideMenu";
import { SideMenuTitle } from "../sideMenu/sideMenuTitle";
import { SideMenuItem } from "../sideMenu/sideMenuItem";
import { constants } from "../../../common/constants/constants";
import { toggleMobileMenuOpened } from "../../../store/common/slice";
import BllinkTime from "../../../common/utils/time";
import income from "../../../images/newDesign/income.svg";
import {hasPremiumAccessSelector} from "../../../store/auth/selectors";

/**
 * @param year
 * @param apartmentNumber
 * @param navData
 * @return {JSX.Element|null}
 * @constructor
 */
export default function IncomeReportsSidebarMenu({year, apartmentNumber, navData}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const menuRef = useRef();
    const sideMenuRef = useRef();
    const localBuildingId = useSelector(state => state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const currentYear = BllinkTime.getCurrentYear();
    const linkYear = year || currentYear;
    const currentMonth = BllinkTime.getCurrentMonth();
    const isPremiumUser = useSelector(hasPremiumAccessSelector);

    const onItemClick = () => {
        dispatch(toggleMobileMenuOpened());
        sideMenuRef.current.close();
    }

    return (
        <Fragment>
            <MenuItem icon={null} ref={menuRef}>
                <NavLink className="dropdown-link" to={{ pathname: '' }}>
                    {t('buildings.nav_bar_maintenance_reports')}
                </NavLink>
            </MenuItem>
            <SideMenu container={menuRef.current} ref={sideMenuRef}>
                <SideMenuTitle
                    label={t('buildings.nav_bar_maintenance_reports')}
                    icon={income}/>
                <SideMenuItem
                    url={`/${constants.pages.buildingDebtList}/${localBuildingId}`}
                    label={t('buildings.nav_bar_debt_list')}
                    tooltip={t('buildings.upgrade_to_premium')}
                    disabled={!isPremiumUser}
                    tooltipDisabled={isPremiumUser}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`/${constants.pages.yearlyByMonthsUrl}/${localBuildingId}/${linkYear}`}
                    label={t('buildings.nav_bar_reports_yearly_by_months')}
                    tooltip={t('buildings.upgrade_to_premium')}
                    disabled={!isPremiumUser}
                    tooltipDisabled={isPremiumUser}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`/${constants.pages.addCollectionStatus}-new/${localBuildingId}`}
                    label={t('reports.collection_report')}
                    tooltip={t('buildings.upgrade_to_premium')}
                    disabled={!isPremiumUser}
                    tooltipDisabled={isPremiumUser}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`/${constants.pages.monthlyCashFlowUrl}/${localBuildingId}/${currentMonth}/${currentYear}`}
                    label={t('buildings.nav_bar_reports_monthly_cashflow')}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`/${constants.pages.newApartmentReport}/${localBuildingId}/${apartmentNumber || navData.firstApartmentNumber || 1}`}
                    label={t('buildings.nav_bar_reports_apartment')}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`/${constants.pages.yearlyByOneTimeUrl}/${localBuildingId}/${linkYear}`}
                    label={t('buildings.nav_bar_reports_yearly_by_one_time')}
                    tooltip={t('buildings.upgrade_to_premium')}
                    disabled={!isPremiumUser}
                    tooltipDisabled={isPremiumUser}
                    onClick={onItemClick}/>
                <SideMenuItem
                    url={`/${constants.pages.yearlyCashFlowUrl}/${localBuildingId}/${linkYear}`}
                    label={t('buildings.nav_bar_reports_yearly_cashflow')}
                    tooltip={t('buildings.upgrade_to_premium')}
                    disabled={!isPremiumUser}
                    tooltipDisabled={isPremiumUser}
                    onClick={onItemClick}/>
            </SideMenu>
        </Fragment>
    );
}
