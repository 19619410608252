export const colors = {
    primaryColors: {
        bllinkGradient: 'linear-gradient(50.53deg, #6A92FF 20.83%, #8764FF 86.02%)',
        bllinkBlue: '#6E74FF'
    },
    additionalColors: {
        blue: '#6F8BFF',
        lightBlue: '#A8C0FF',
        lightGray: '#E9E9FD',
        gray: '#D8D2FF',
        white: '#F9F9FF',
        green: '#2BB596',
        pink: '#F44197',
        coral: '#FFC0CBFF',
        brightBlue: '#6E8EFF',
        purple: '#8469FF',
        gradientBlue: '#6A92FF',
        equalWeb: '#7f73fb',
    },
    textColors: {
        black: '#373647',
        gray: 'rgba(55, 54, 71, 0.7)',
        blue: '#6E74FF',
        white: '#FFFFFF'
    },
    semiTransparentColors: {
        white: 'rgba(255, 255, 255, 0.5)',
        white07: 'rgba(255, 255, 255, 0.7)',
        darkGray: 'rgba(89, 102, 137, 0.05)',
        semiDarkGray: 'rgba(89, 102, 137, 0.3)',
        shadowGray: 'rgba(89, 102, 137, 0.12)',
        buttonShadowGray: 'rgba(45, 76, 140, 0.2)',
        gray: 'rgba(89, 102, 137, 0.4)',
        grayBlue: 'rgba(55, 54, 71, 0.7)',
        blue: 'rgba(110, 116, 255, 0.4)',
        lightBlue: 'rgba(110, 116, 255, 0.2)',
        purple: 'rgba(216, 210, 255, 0.5)'
    }
}
