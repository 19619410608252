export const maintenance = {
    "open_a_call": "פתיחת קריאת תחזוקה",
    "continue_to_choose": "מלאו את פרטי קריאת התחזוקה ושלחו למנהל הבניין",
    "name": "שם מדווח*",
    "email": "דוא״ל*",
    "apt": "מספר דירה*",
    "call_details": "פרטי קריאת תחזוקה",
    "category": "*קטגוריה",
    "urgency": "*דחיפות",
    "low": "נמוכה",
    "medium": "בינונית",
    "high": "גבוהה",
    "attach_a_photo": "צירוף תמונה",
    "desc": "*תיאור תקלה",
    "send": "שליחה",
    "fault_creation_failed": "יצירת התקלה נכשלה",
    "fault_created": "נוצרה תקלה, תוצאה {{result}}",
    "thanks": "תודה!",
    "form_submitted": "פנייתך התקבלה",
    "SevirityDescription": {
        "low": "נמוכה",
        "medium": "בינונית",
        "high": "גבוהה",
        "immediate": "מִיָדִי",
    }
}
