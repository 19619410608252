export class MonitoringApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {number} buildingId
     * @param {string} apartmentNum
     * */
    startSelectiveMonitoringApartment(buildingId, apartmentNum) {
        return this.#client.put(`/operations/monitoring/building/${buildingId}/apartment/${apartmentNum}/started`);
    }

    /**
     * @param {number} buildingId
     * @param {string} apartmentNum
     * */
    stopSelectiveMonitoringApartment(buildingId, apartmentNum) {
        return this.#client.put(`/operations/monitoring/building/${buildingId}/apartment/${apartmentNum}/stopped`);
    }

    /**
     * @param {number} buildingId
     * */
    getBuildingState(buildingId) {
        return this.#client.get(`/operations/monitoring/building/${buildingId}/state`);
    }
}
