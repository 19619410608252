import {useCallback, useEffect, useState} from 'react';
import Talk from 'talkjs';
import { Session, Chatbox, Popup } from '@talkjs/react';
import {useDispatch, useSelector} from "react-redux";
import {hideLoader, showLoader} from "../../store/common/slice";
import {NotificationManager} from "react-notifications";
import {useTranslation} from "react-i18next";
import CacheData from "../../common/utils/cacheData";
import messageSound  from "./assets/sound/message.mp3";
import {optionalUseAccessToken} from "../../common/hooks/optionalUseAccessToken";
import BllinkUtils from "../../common/utils/utils";
import {constants} from "../../common/constants/constants";
import {StyledChatComponent} from './talkJSStyle';
import { Helmet } from 'react-helmet-async';

const chatBotStyle = {
    width: '100%',
    height: '100vh',
    alignContent: 'center',
    fontSize: '20px',
};

const managersChatBotStyle = {
    ...chatBotStyle,
    height: '100%',
};


const uniqueID = BllinkUtils.JsExtras.generateUniqueConsistentID();
const CLIENT_CHAT_ID = 'clientChatID';
const GUEST_ID = `guest_${uniqueID}`; // todo make this unique for every guest based on their compterName
const BLLINK_ID = 'bllink';
const cacheOptions = {typeOfCache: sessionStorage};

/**
 *
 * @param welcomeText first text the user will see
 * @param botName {string} onBoarding|legalBot
 * @param SEOData {{title: string, tags: []}}
 * @param extraParams {entityType: string, userID: string, entityValue: string, photoUrl: string}
 * @returns {JSX.Element}
 * @constructor
 */
function ChatComponent({welcomeText, botName = null,
                           SEOData = null,
                           extraParams = null}) {

    // todo also support tenant clients
    const clientManagersOrGuest = optionalUseAccessToken();
    const userProfile = useSelector(state => state.auth.user);
    const signedIn = userProfile?.email || extraParams?.userID;
    const managerMobileApp = extraParams?.managerMobileApp;
    const justPopUp = extraParams?.justPopUp; // hovering instead of full screen
    const isMobile = extraParams?.isMobile; // hovering instead of full screen
    const userID = signedIn || GUEST_ID;
    const chatStyle = botName === constants.chatBot.names.managersAssistants ? managersChatBotStyle : chatBotStyle;
    const { t, i18n } = useTranslation();
    const translationPrefixOnboarding = 'chatBot.onboarding';
    const translationPrefixLegalChat = 'chatBot.legalBot';
    const translationPrefixCommon = 'chatBot.common';
    const translationPrefixCsmTechnical = 'chatBot.csmTechnical';
    const translationPrefixManagers = 'chatBot.managersAssistants';
    console.log(`botName is ${botName} justPopUp is ${justPopUp}`)

    const client = clientManagersOrGuest;

    const [isShown, setIsShown] = useState(false);
    const [runID, setRunID] = useState(null);
    const [threadID, setThreadID] = useState(null);
    const [clientChatID, setClientChatID] = useState('');
    const [playing, setPlaying] = useState(false);
    const dispatch = useDispatch();

    useEffect(async()=>{
        setClientChatID(await getClientChatIDFromSession());
    },[])

    useEffect(() => {
        document.addEventListener(`openChatAI`, (e) => {
            setIsShown(true);
        }, {once: true} );
    })

    async function getClientChatIDFromSession() {
        await Talk.ready;
        const randomChatId = BllinkUtils.JsExtras.randomString(10);
        const id = CacheData.fetchCache(CLIENT_CHAT_ID, cacheOptions) ?? randomChatId;
        return id;
    }


    const syncUser = useCallback(
        () =>{
            return new Talk.User({
                id: userID,
                name: signedIn ?? 'אורח',
                email: null,
                photoUrl: 'https://images.macrumors.com/t/x_zUFqghBUNBxVUZN_dYoKF3D9g=/1600x0/article-new/2019/04/guest-user-250x250.jpg',
                welcomeMessage: 'שלום',
                role: 'default',
                locale: i18n.language,
            })},
        [userProfile]
    );


    const syncConversation = useCallback((session) => {
        if (clientChatID.length > 0) {
            let welcomeMessage, botTitle;
            switch (botName){
                case 'onboardAssistant':
                    welcomeMessage = t(`${translationPrefixOnboarding}.welcomeMessageGuest`);
                    botTitle = t(`${translationPrefixOnboarding}.onboardingBotTitle`);
                    break;
                case 'legalBot':
                    welcomeMessage = t(`${translationPrefixLegalChat}.welcomeMessageGuest`);
                    botTitle = t(`${translationPrefixLegalChat}.Title`);
                    break;
                case constants.chatBot.names.CSMTechnical:
                    welcomeMessage = t(`${translationPrefixCsmTechnical}.welcomeMessage`);
                    botTitle = t(`${translationPrefixCsmTechnical}.Title`);
                    break;
                case constants.chatBot.names.managersAssistants:
                    welcomeMessage = t(`${translationPrefixManagers}.welcomeMessage`);
                    botTitle = t(`${translationPrefixManagers}.Title`);
                    break;
                default:
                    throw Error(`unknown bot name ${botName}`);
            }


            // JavaScript SDK code here
            const conversation = session.getOrCreateConversation(clientChatID);

            const other = new Talk.User({
                id: BLLINK_ID,
                name: botTitle,
                // chat@bllink is a google group https://groups.google.com/a/bllink.co/g/chat
                // before it was ohad@bllink.co
                email: 'chat@bllink.co',
                photoUrl: extraParams?.photoUrl ?? 'https://www.bllink.co/wp-content/uploads/2022/06/Favicon.png',
                // add link to terms of service https://bit.ly/3VcolWx
                welcomeMessage: welcomeText || welcomeMessage,
                role: 'default',
                locale: i18n.language,
            });
            conversation.setParticipant(session.me);
            conversation.setParticipant(other);

            CacheData.cache(CLIENT_CHAT_ID, clientChatID, cacheOptions);

            return conversation;
        }
    }, [clientChatID, botName]);

    const onNewMessage = async (event) => {
        const messageText = event.message.text;
        const attachment = event.message.attachment;
        console.log(`new message `  + messageText);

        // keeping here in case we'll need to rollback
        // the onBoarding will not!! work with the assistant
        // api since the function are not working
        const shouldCallAssistantApi = ['legalBot'].includes(botName);
        if (shouldCallAssistantApi){
            await handleSendMessage({messageText, attachment});
        }else{
            await handleSendMessageStream({messageText, attachment});
        }

    };

    const onCustomConversationAction = async (event) => {
        const eventName = event.action;
        console.log(`detected eventName`, eventName);
        if (eventName === "createNewChat") {
            // const [id1, id2] = Object.keys(event.conversation.participants);
            // const newId = Talk.oneOnOneId(id1,id2);
            const newId = BllinkUtils.JsExtras.randomString(10);
            setClientChatID(newId);
            // this should be only on the BE
            setThreadID(null)
            setRunID(null)

        }else if (eventName === "reportMessage"){
            await handleSendMessageStream({messageText: `${event.message.body} שולחת דיווח דייר`,
                // this will send an internal mail with all the details
                extraParamsOnTop: {reportedMessage: true}});
            // await utilsApi.postInternalEmail(constants.backendEndpoints.GENERIC_INTERNAL_EMAIL_ENDPOINT, '', params);
            NotificationManager.success(t(`${translationPrefixCommon}.thanksForReport`));
        }
    };

    const handleSendMessageStream = async({messageText, attachment, extraParamsOnTop})=> {
        dispatch(showLoader());

        // BE will write it to the chat
        if (extraParamsOnTop){
            extraParams = {...extraParams, ...extraParamsOnTop};
        }
        const response = await client.aiChat.streamResponse({
            threadID,
            runID,
            clientChatID,
            userClientID: userID,
            messageText,
            attachment,
            botName,
            extraParams
        });

        console.log(`response from streaming is `, response);
        playSound();

        setTimeout(() => {
            dispatch(hideLoader());
        }, 1500);

    }

    const handleSendMessage = async ({messageText, attachment}) => {
        // send to back end

        dispatch(showLoader());

        const response =
            await client.aiChat.postMessage({
                textMessage: messageText,
                attachment,
                clientChatID,
                userClientID: userID,
                conversationID: threadID,
                botName,
                extraParams
            });
        dispatch(hideLoader());

        if (response.success){
            setRunID(response.runID);
            setThreadID(response.threadID);
            playSound();
        }else{
            // todo translate
            NotificationManager.error(`error in sending new chat message`)
        }
    };

    const playSound = () => {
        const sound = new Audio(messageSound);
        sound.play();
        setPlaying(true);

        // Listen for the end of the audio to update state
        sound.onended = () => {
            setPlaying(false);
        };
    }

    return (
    <>
        {/* seo feature here https://bllink.atlassian.net/browse/RD-255 */}
        {SEOData && (
            //Flexibility: React Helmet's design to handle multiple instances makes it incredibly flexible for SEO management across a React application.
            // You can easily set global defaults and override them on specific pages as needed. (chatGPT)
            // https://bllink.atlassian.net/browse/RD-255
            <Helmet>
                <title>{SEOData.title}</title>
                {
                    SEOData.tags.map(tag => (
                        <meta name={tag.name}
                               content={tag.value}
                               key={tag.name}
                        />
                    ))
                }
            </Helmet>
            )
        }

        <StyledChatComponent>
            {playing && (<audio autoPlay={false} src={messageSound} />)}
            <div className={`content ${managerMobileApp ? 'manager-wrapper': ''} ${isMobile ? 'mobile-device' : ''}`}>
                {/*"tvifsUNi" is the staging app id for the chat component*/}
                <Session appId="sOc14lAu" syncUser={syncUser}>
                    {justPopUp ? (
                        <Popup
                            syncConversation={syncConversation}
                            style={chatBotStyle}
                            className="chat-container"
                            show={isShown}
                            keepOpen={true}
                            dir={'ltr'}
                            loadingComponent={<h1>Loading..</h1>}
                            onSendMessage={onNewMessage}
                            onCustomConversationAction={onCustomConversationAction}
                            onCustomMessageAction={onCustomConversationAction}
                        />

                        ) :
                        (
                    <Chatbox
                        dir={'rtl'}
                        syncConversation={syncConversation}
                        loadingComponent={<h1>Loading..</h1>}
                        onSendMessage={onNewMessage}
                        onCustomConversationAction={onCustomConversationAction}
                        onCustomMessageAction={onCustomConversationAction}
                        style={chatStyle}
                    ></Chatbox>)
                    }
                </Session>
            </div>
        </StyledChatComponent>

    </>

    );
}

export default ChatComponent;
