import {PartnerDashboardApi} from "./partners/dashboard.api";

export class PartnersApi {

    /** @type {ServerData} */ #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
        this.dashboard = new PartnerDashboardApi(client);
    }

    /**
     * @param {string} partnerId
     * @param {string} featureName
     * @return {Promise<FeatureValue[]>}
     * */
    getFeatureValue(partnerId, featureName) {
        return this.#client.get(`/partners/${partnerId}/features/${featureName}`);
    }
    /**
     * @param {string | number} partnerId
     * @return {Promise<{ imageExists: boolean, imageData: string | null, imageType: string | null }>}
     * */
    getPartnerLogo(partnerId) {
        return this.#client.get(`/partners/${partnerId}/mclogo`);
    }

    /**
     * @param {string | number} partnerId
     * @param {partnerPaymentMethodType} partnerPaymentMethod
     * @return {Promise<{ success: boolean }>}
     * */
    setPartnerPaymentMethod(partnerId, partnerPaymentMethod) {
        return this.#client.post(`/partners/${partnerId}/paymentMethod`, {partnerPaymentMethod});
    }

    /**
     * @param {string | number} partnerId
     * @return {Promise<{CCToken: string, ExpDate: string, Last4Digits: string, paymentMethod: partnerPaymentMethodType, updatedAt: string}>}
     * */
    getPartnerPaymentDetails(partnerId) {
        return this.#client.get(`/partners/${partnerId}/paymentDetails`);
    }

    /**
     * @param {string | number} partnerId
     * @return {Promise<{featureValues: FeatureValue[]}>}
     * */
    getPartnerFeatures(partnerId) {
        return this.#client.get(`/partners/${partnerId}/features`);
    }

}
