export function controlWithIcon() {
  return `
    .control-with-icon {
      position: relative;
      &.search {
        &::after {
          top: 0;
          left: 0;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        border: 1px solid var(--bllink-blue);
      }
      img {
        position: absolute;
        left: 26px;
        top: 26px;
        transform: translate(-50%, -50%);
      }
    }
  `;
}