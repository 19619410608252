import { Fragment } from "react";
import IncomeSidebarMenu from "./incomeSidebarMenu";
import ExpensesSidebarMenu from "./expensesSidebarMenu";
import AccountingSidebarMenu from "./accountingSidebarMenu";
import { useTranslation } from "react-i18next";

/**
 * @param buildingId
 * @param year
 * @param apartmentNumber
 * @param navData
 * @param {boolean} isGreenInvoiceOn
 * @return {JSX.Element}
 * @constructor
 */
export default function ReportSidebarGroup({ buildingId, year, apartmentNumber, navData, isGreenInvoiceOn }) {
    const { t } = useTranslation();
    return (
        <Fragment>
            <IncomeSidebarMenu
                buildingId={buildingId}
                year={year}
                navData={navData}
                apartmentNumber={apartmentNumber}/>
            <ExpensesSidebarMenu buildingId={buildingId}/>
            <AccountingSidebarMenu buildingId={buildingId} isGreenInvoiceOn={isGreenInvoiceOn}/>
        </Fragment>
    );
}
