import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {verifyLoggedIn} from '../../store/auth/actions';
import {BllinkLogger} from '../utils/bllink_loggers';
import {useClient} from "./useClient";

/**
 * if the user is logged in, then use their access token,
 * if not then not, but don't redirect to login page if not
 * @return {ServerData}
 * */
export function optionalUseAccessToken() {
    const dispatch = useDispatch();
    const accessToken = useSelector(state => state.auth.accessToken) ?? '';
    const client = useClient(accessToken);

    useEffect(async function() {
        if (accessToken === '') {
            try {
                await dispatch(verifyLoggedIn({avoidExceptionOnNotLogged: true}));
            } catch (e) {
                // todo also check if a tenant is logged in to create a client for them
                BllinkLogger.info(`user is not authenticated`, e);
            }
        }
    });

    return client;
}
