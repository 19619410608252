import { constants } from "../../../constants/constants";
import {lazyReload} from "../../../lazyReload";

const YearlyCahFlowTable = lazyReload(() => import("../../../../managers/reports/yearly_cashflow_table/yearlyCahflowTable"));
const MonthlyCashFlowTable = lazyReload(() => import("../../../../managers/reports/monthly_cashflow_table/monthlyCashflowTable"));
const MonthlyChequeTable = lazyReload(() => import("../../../../managers/reports/monthly_cashflow_table/monthlyChequeTable"));
const YearlyByMonthsTable = lazyReload(() => import("../../../../managers/reports/yearly_by_months/yearlyByMonthsTable"));
const YearlyByMonths = lazyReload(() => import("../../../../managers/reports/yearlyByMonths/YearlyByMonths"));
const BuildingManagerProfile = lazyReload(() => import("../../../../managers/building/buildingManagerProfile"));
const UploadNewPaymentSetting = lazyReload(() => import("../../../../managers/paymentSettings/uploadNewPaymentSetting"));
const MonthlySettings = lazyReload(() => import("../../../../managers/paymentSettings/monthlySettings"));
const OneTimeSettings = lazyReload(() => import("../../../../managers/paymentSettings/oneTimeSettings"));
const MonthlyExpensesTable = lazyReload(() => import("../../../../managers/reports/expensesMonthly/monthlyExpensesTable"));
const MonthlyExpensesNew = lazyReload(() => import("../../../../managers/reports/expensesMonthlyNew2/MonthlyExpenses2"));
const BalanceReport = lazyReload(() => import('../../../../managers/reports/expenses_balance/balanceReport'));
const BalanceReportNew = lazyReload(() => import('../../../../managers/reports/expensesBalanceNew/BalanceReportNew'));
const DebtList = lazyReload(() => import('../../../../managers/reports/debt_list/debtList'));
const DebtListNew = lazyReload(() => import('../../../../managers/reports/debtList/index'));
const CollectionStatus = lazyReload(() => import("../../../../managers/collectionStatus/collectionStatus"));
const CollectionStatusNew = lazyReload(() => import("../../../../managers/collectionStatusNew/CollectionStatus"));
const ApartmentReport = lazyReload(() => import('../../../../managers/reports/new_apartment_reports/ApartmentReport'));
const EditBuilding = lazyReload(() => import("../../../../managers/building/editBuilding/EditBuilding"));
const BuildingDashboard = lazyReload(() => import("../../../../managers/building/buildingDashboard/BuildingDashboard"));
const EditContacts = lazyReload(() => import('../../../../managers/contacts/EditContacts'));
const UpdateRates = lazyReload(() => import("../../../../managers/updateRates/UpdateRates"));
const ApartmentReportNew = lazyReload(() => import('../../../../managers/reports/apartment_report_module/Index'));

export const ManagerReports = [
    {path: `/${constants.pages.addNewPayment}/:buildingID`, component: UploadNewPaymentSetting, exact: true},
    {path: `/${constants.pages.monthlyPaymentSettings}/:buildingID/:year`, component: MonthlySettings, exact: true},
    {path: `/${constants.pages.oneTimePaymentSettings}/:buildingID/:oneTimeSettingsID`, component: OneTimeSettings, exact: true},
    {path: `/${constants.pages.yearlyCashFlowUrl}/:buildingID/:year`, component: YearlyCahFlowTable, exact: true},
    {path: `/${constants.pages.monthlyCashFlowUrl}/:buildingID/:month/:year`, component: MonthlyCashFlowTable, exact: true},
    {path: `/${constants.pages.monthlyChequesUrl}/:buildingID/:month/:year`, component: MonthlyChequeTable, exact: true},
    // {path: `/${constants.pages.apartmentCashFlowUrl}/:buildingID/:apartmentNumber`, component: ApartmentCashFlowTable, exact: true},
    {path: `/${constants.pages.apartmentCashFlowUrl}/:buildingID/:apartmentNumber/:year?`, component: ApartmentReport, exact: true},
    {path: `/${constants.pages.newApartmentReport}/:buildingID`, component: ApartmentReportNew },
    {path: `/${constants.pages.yearlyByMonthsUrl}/:buildingID/:year`, component: YearlyByMonths, exact: true},
    {path: `/${constants.pages.yearlyByMonthsUrl}-admin/:buildingID/:year`, component: YearlyByMonthsTable, exact: true},
    {
        path: `/${constants.pages.yearlyByOneTimeUrl}/:buildingID/:year`,
        component: props => <YearlyByMonthsTable {...props} isOneTime={true} />,
        exact: true
    },
    {path: `/${constants.pages.buildingManagerProfileUrl}/:buildingID`, component: BuildingManagerProfile, exact: true},
    {path: `/${constants.pages.buildingManagerProfileUrl}-new/:buildingID`, component: BuildingDashboard, exact: true},
    {path: `/${constants.pages.monthlyExpensesUrl}/:buildingID/:month/:year`, component: MonthlyExpensesNew, exact: true},
    {path: `/${constants.pages.monthlyExpensesUrl}-new-2/:buildingID/:month/:year`, component: MonthlyExpensesTable, exact: true},
    {path: `/${constants.pages.balanceReportUrl}-old/:buildingID/:month/:year`, component: BalanceReport, exact: true},
    {path: `/${constants.pages.balanceReportUrl}/:buildingID`, component: BalanceReportNew, exact: true},
    {path: `/${constants.pages.balanceReportUrl}-new/:buildingID`, component: BalanceReportNew, exact: true},
    {path: `/${constants.pages.buildingDebtList}-old/:buildingID`, component: DebtList, exact: true},
    {path: `/${constants.pages.buildingDebtList}/:buildingID`, component: DebtListNew, exact: true},
    {path: `/${constants.pages.addCollectionStatus}/:buildingID`, component: CollectionStatus, exact: true},
    {path: `/${constants.pages.addCollectionStatus}-new/:buildingID`, component: CollectionStatusNew, exact: true},
    {path: `/${constants.pages.editBuilding}/:buildingID`, component: EditBuilding, exact: true},
    {path: `/${constants.pages.editContacts}/:buildingID`, component: EditContacts, exact: true},
    {path: `/${constants.pages.updateRates}/:buildingID`, component: UpdateRates, exact: true},
]



