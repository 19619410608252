import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Reports from '../../../components/images/reports.svg';
import Building from '../../../components/images/edit_building.svg';
import TenantsList from '../../../components/images/tenant_list.svg';
import Pen from '../../../components/images/managers/pen.png';
import MoneyBag from '../../../components/images/managers/money-bag.png';
import TotalDebts from '../../../components/images/managers/total-debts-icon.png';
import Email from '../../../components/images/email.svg';
import { constants } from '../../../components/common/constants/constants';
import { StyledNav } from '../../../components/common/manager_navbar/styledNavBar.styled';
import CacheData from '../../../components/common/utils/cacheData';
import { saveCurrentBuilding } from '../../../components/store/buildings/actions';
import { useAccessToken } from '../../../components/common/hooks/useAccessToken';

const AdminNavBar = ({ buildingID }) => {
    const { t } = useTranslation();
    const client = useAccessToken();

    const [localBuildingID, setLocalBuildingID] = useState(buildingID);

    useEffect(() => {
        (async function () {
            if (!buildingID) {
                setLocalBuildingID(CacheData.fetchCache('currentBuilding') || '1904');
            }
            if (!localBuildingID || !client) return;
            CacheData.cache('currentBuilding', localBuildingID);
            saveCurrentBuilding(localBuildingID);
        })();
    }, [localBuildingID, client]);

    const renderPlans = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.plans')}
                    <img src={Reports} alt="plans"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.searchPaymentsUrl}/`}>
                        {t('navBar.subheaders.searchPlans')}
                    </NavLink>
                    {/*<NavLink className="dropdown-item" to={`/url`}>*/}
                    {/*    {t('navBar.subheaders.planActs')}*/}
                    {/*</NavLink>*/}
                    {/*<NavLink className="dropdown-item" to={`/url`}>*/}
                    {/*    {t('navBar.subheaders.planEdit')}*/}
                    {/*</NavLink>*/}
                </div>
            </li>
        );
    };

    const renderBuildingActions = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.buildingActs')}
                    <img src={Building} alt="payment reports"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`/url`}>
                        {t('navBar.subheaders.buildAddress')}
                    </NavLink>
                </div>
            </li>
        );
    };

    const renderUserActions = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.userActs')}
                    <img src={TenantsList} alt="payment reports"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.userActions}`}>
                        {t('navBar.subheaders.actions')}
                    </NavLink>
                </div>
                {/* <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`/url`}>
                        {t('navBar.subheaders.openUser')}
                    </NavLink>
                </div> */}
            </li>
        );
    };

    const renderAutoCampaign = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.autoCampaign')}
                    <img src={MoneyBag} alt="payment reports"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.autoCampaign}/actions`}>
                        {t('navBar.subheaders.actions')}
                    </NavLink>
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.autoCampaign}/status`}>
                        {t('navBar.subheaders.status')}
                    </NavLink>
                </div>
            </li>
        );
    };

    const renderReports = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.reports')}
                    <img src={Pen} alt="payment reports"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.reports}/sms`}>
                        {t('navBar.subheaders.reports.sms')}
                    </NavLink>
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.reports}/renew`}>
                        {t('navBar.subheaders.reports.renew')}
                    </NavLink>
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.reports}/failed`}>
                        {t('navBar.subheaders.reports.failed')}
                    </NavLink>
                </div>
            </li>
        );
    };

    const renderEditBuilding = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.editBuilding')}
                    <img src={TotalDebts} alt="payment reports"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.editBuilding}/${buildingID}`}>
                        {t('navBar.subheaders.editBuilding')}
                    </NavLink>
                </div>
            </li>
        );
    };

    const renderEditPartner = () => {
        return (
            <li className="nav-item n_nav_r">
                <a className="nav-link dropdown-toggle" href="/" id="navbardrop" data-toggle="dropdown">
                    {t('navBar.headers.partners')}
                    <img src={Email} alt="payment reports"/>
                </a>
                <div className="dropdown-menu">
                    <NavLink className="dropdown-item" to={`${constants.pages.admin.partners}`}>
                        {t('navBar.subheaders.createEditPartner')}
                    </NavLink>
                </div>
            </li>
        );
    };

    return (
        <section>
            <div className="container">
                <div className="row">
                    <StyledNav className="navbar navbar-expand-sm bg-transparent" id="nav_sec">
                        <ul className="navbar-nav" id="navbar_nav">
                            <>
                                {renderPlans()}
                                {/*{renderUserActions()}*/}
                                {renderAutoCampaign()}
                                {renderReports()}
                                {renderEditBuilding()}
                                {/*{renderEditPartner()}*/}
                            </>
                        </ul>
                    </StyledNav>
                </div>
            </div>
        </section>
    );
};

export default AdminNavBar;
