// Actions needed to get current building information.
// Was copied from parent.js to be used in functional components.
// When other components will have been refactored we can remove duplicated code from parent.js

import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from 'i18next';
import { BllinkLogger } from '../../common/utils/bllink_loggers';
import CacheData from '../../common/utils/cacheData';
import ServerData from '../../common/serverData';
import { isDemoAccount, sortApartmentNumbers } from '../../common/shared/statelessMethods';
import { constants } from "../../common/constants/constants";
import * as Sentry from "@sentry/react";

const getBuildingFromServer = async (client, buildingID, options) => {
    if (isDemoAccount(buildingID)) {
        // load dynamically to exclude from common bundle
        const fileName = constants.getDemoBuildingFileName(buildingID);
        const module = await import(`../../common/mocks/data/${fileName}`);
        const BuildingMockData = module.default;
        return BuildingMockData;
    }

    // https://sentry.io/organizations/bllink/issues/2908073266/?environment=production&project=5402476&referrer=alert_email
    // need to find out why this is happening
    if (!buildingID) return {};

    try {
        let urlParams = options.urlParams || {};
        let result = await client.get(`/buildings/${buildingID}`, urlParams);
        BllinkLogger.info(`result from building server page `, result);

        return result;
    } catch (e) {
        return {};
    }
};

const getManagerBuildingData = async (client, buildingID, options) => {
    if (isDemoAccount(buildingID)) {
        // load dynamically to exclude from common bundle
        const fileName = constants.getDemoBuildingMockDataFileName(buildingID);
        const module = await import(`../../common/mocks/demoAccount/${fileName}`);
        const ManagerBuildingMockData = module.default;
        return ManagerBuildingMockData;
    }

    try {
        let urlParams = options.urlParams || {};
        let result = await client.get(`/managers/buildings/${buildingID}`, urlParams, { throwError: true });
        BllinkLogger.info(`manager's building data for user are `, result);

        return {
            ...result,
            // adding ? https://bllink.sentry.io/issues/4632295774/?project=5424386&referrer=issue-stream&statsPeriod=24h&stream_index=0
            allNumbers: getAllNumbersForManagers(result?.building)
        };
    } catch (e) {
        // ErrorsHandler.notifyClientError(e);
        console.error('Unable to get managers building data', e.message);
        console.error('BuildingId', buildingID);
        Sentry.captureException(e);
        return {};
    }
};


const saveCurrentBuilding = buildingID => {
    BllinkLogger.info(`enter saveCurrentBuilding with `, buildingID);
    const options = {typeOfCache: sessionStorage};

    // todo find a better way using global state
    if (buildingID) {
        CacheData.cache('currentBuilding', buildingID, options);
    } else {
        CacheData.delete('currentBuilding', options);
    }
};


export const getAllNumbers = result => {
    return result.apartments.map(item => item.apartmentNumber).sort(sortApartmentNumbers);
};

const getAllNumbersForManagers = building => {
    if (!building || !building.tenants) return [];
    return Object.keys(building.tenants).sort(sortApartmentNumbers);
};

export const getApartmentsWithId = result => {
    return result.apartments
        .sort((a, b) => sortApartmentNumbers(a.apartmentNumber, b.apartmentNumber))
        .map(item => ({
            label: i18n.t('reports.apartment_number', {apartmentNumber: item.apartmentNumber}),
            value: item.id,
            apartmentNumber: item.apartmentNumber
        }));
}

const fetchBuildingData = createAsyncThunk(
    'buildings/fetchData',
    async ({buildingID, options = {}}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let result = await CacheData.getOrCache(async () => {
                    let client = new ServerData(null, null, {accessToken: accessToken});
                    const response = await getBuildingFromServer(client, buildingID, options);
                    return response.building;
                },
                'buildingData',
                {buildingID: buildingID}
            );
            let address = {...result.address,
                buildingExternal: buildingID,
                buildingInternalId: result.buildingInternal,
            }

            return {
                id: buildingID,
                currency: result.currency,
                address,
                managers: result.managers,
                allNumbers: getAllNumbers(result),
                apartments: getApartmentsWithId(result),
                updates: result.updates,
                numberOfInstallmentsForDebt: result.numberOfInstallmentsForDebt,
                tenantCommission: result.tenantCommission,
                // added to remove the min commission text https://bllink.atlassian.net/browse/RD-285
                hasNonTenantCommission: result.hasNonTenantCommission,
                assignedToNextMonth: result.assignedToNextMonth,
                managementCompany: result.managementCompany,
            };
        } catch (e) {
            return rejectWithValue({});
        }
    }
);

const fetchManagerBuildingData = createAsyncThunk(
    'buildings/fetchManagerBuildingData',
    async ({buildingID, options = {}}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});
            return getManagerBuildingData(client, buildingID, options);
        } catch (e) {
            CacheData.delete('currentBuilding', {typeOfCache: sessionStorage});
            CacheData.delete('currentBuilding', {typeOfCache: localStorage});
            return rejectWithValue({});
        }
    }
);

const fetchManagerBuildingDataLocal = createAsyncThunk(
    'buildings/fetchManagerBuildingDataLocal',
    async ({buildingID, options = {}}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});
            return getManagerBuildingData(client, buildingID, options);
        } catch (e) {
            return rejectWithValue({});
        }
    }
);

const getBuildingTitle = createAsyncThunk(
    'buildings/getTitle',
    async ({t, buildingID, addressData, typeOfWelcome = '', apartmentNumber = '', options = {}}, {getState, dispatch}) => {

        // WARNING: this is too heavy operation, try to avoid it!
        //          I you feel irresistible need to use it - please pass addressData

        let address;
        if (addressData == null) {
            //todo, if logged in , then as manager, if not, as tena
            const state = getState();
            const isLoggedIn = state.auth?.accessToken;
            address = await CacheData.getOrCache(async () => {
                let addressData;
                    if (isLoggedIn) {
                        options = {...options, ...{urlParams: {skipDisabledCheck: true}}};
                        const buildingData = await dispatch(fetchManagerBuildingDataLocal({buildingID, options}));
                        addressData = buildingData.payload.building.address;
                    } else {
                        const buildingData = await dispatch(fetchBuildingData({buildingID, options}));
                        addressData = buildingData.payload.address;
                    }
                    return addressData;
                },
                'buildingAddress',
                { buildingID }
            );
        } else {
            address = addressData;
        }

        BllinkLogger.info(`buildingTitle`, address, apartmentNumber);
        let welcomeKey = {
            'welcome': 'welcome_to',
            'paymentTo': 'payment_to',
            'expenseRecord': 'record_expenses',
            'expenseEdit': 'expense_edit',
            'expensesBalance': 'balance_title',
            'bankAdjustments': 'bank_adjustments_title'
        }[typeOfWelcome];

        if (!address) {
            return {
                welcomeKey: welcomeKey,
                translationKey: 'empty'
            };
        }

        const apartmentText = apartmentNumber ? t('payments.apartmentText', {apartmentNumber: apartmentNumber}) : '';
        const hasEntrance = address.entrance;
        return {
            // to know the current buildingID is related to
            // the fetched address https://bllink.atlassian.net/browse/SSB-2092,
            buildingExternal: address.buildingExternal,
            buildingInternalId: address.buildingInternalId,
            city: address.city,
            street: address.nickname || address.street,
            streetNumber: address.nickname ? '': address.streetNumber,
            entrance: address.entrance,
            apartmentText: apartmentText,
            welcomeKey:  welcomeKey,
            translationKey:  hasEntrance ? 'address_with_entrance' : 'address_without_entrance'
        }
    }
);

const getBuildingTitleText = createAsyncThunk(
    'buildings/getTitleText',
    async ({t, buildingID}, {getState}) => {
        const store = getState();

        let titleData = store.buildings.titleData;
        // check that current titleData belongs to requested buildingID, due to this bug
        // https://bllink.atlassian.net/browse/SSB-2092
        // not really helpful need to distigish internal and external id buildingID 6143 title requested but got for qfkw
        const requestedBuildingIdNotMatchingToSaved = (
            titleData.buildingExternal && titleData.buildingInternalId &&
            (String(titleData.buildingExternal) !== String(buildingID) ||  Number(titleData.buildingInternalId) !== Number(buildingID))
        );
        if (requestedBuildingIdNotMatchingToSaved){
            // Sentry.captureMessage(`buildingID ${buildingID} title requested but got for external ${titleData.buildingExternal} or internal ${titleData.buildingInternalId}`, 'error');
            await fetchBuildingData({ buildingID , options: {urlParams: {includeInternalID: true}}});
            titleData = await getBuildingTitle({buildingID});
        }
        return t('payments.' + titleData.translationKey, titleData);
    }
)

const fetchPaymentsByYear = createAsyncThunk(
    'buildings/fetchPaymentsByYear',
    async ({buildingID, year, ignoreCache}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            let result = state.buildings.data[buildingID]?.payments?.[year];
            if (ignoreCache || result == null) {
                const accessToken = state.auth.accessToken;
                const client = new ServerData(null, null, { accessToken });
                result = await client.get(`/managers/payments/${buildingID}`, {year, ignoreCache })
                BllinkLogger.info(`all buildings payments by months `, result);
            }
            return result;
        } catch (e) {
            return rejectWithValue({});
        }
    }
);

const loadDebtList = createAsyncThunk(
    'buildings/loadDebtList',
    async ({buildingID, options}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});

            const result = await client.managers.debts.getBuildingDebts(buildingID, options.start, options.end);
            return {
                buildingID,
                result: result.value
            }
        } catch (e) {
            return rejectWithValue([]);
        }
    }
);

const updateLegacyPayments = createAsyncThunk(
    'buildings/updateLegacyPayments',
    async ({buildingID, apartmentNumber, requestPayload}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});

            const response = await client.put(`callbacks/legacyPayments/${buildingID}/${apartmentNumber}`, requestPayload);
            return {
                buildingID,
                apartmentNumber,
                result: response.result
            }
        } catch (e) {
            return rejectWithValue([]);
        }
    }
);

const fetchBuildingFeatures = createAsyncThunk(
    'buildings/fetchFeatures',
    async ({buildingID, options = {}}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            const featureMap = await CacheData.getOrCache(async () => {
                    const client = new ServerData(null, null, {accessToken: accessToken});
                    const featuresResult = await client.tenants.buildings.getFeatures(buildingID);
                    const features = (featuresResult?.featureValues ?? []).reduce((acc, f) => Object.assign(acc, { [f.Feature.featureName]: f.value }), {});
                    return features;
                },
                'currentBuildingFeatures',
                { buildingID }
            );
            return featureMap;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export {
    fetchBuildingData,
    getBuildingTitleText,
    getBuildingTitle,
    fetchManagerBuildingData,
    fetchManagerBuildingDataLocal,
    saveCurrentBuilding,
    fetchPaymentsByYear,
    loadDebtList,
    updateLegacyPayments,
    fetchBuildingFeatures,
};
