
export class PaymentsApi {

    /** @type {ServerData} */ #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {number|string} buildingID
     * @param {string} apartmentNumber
     * @param {CreatePaymentPlanPayload} data
     * @param {boolean} [throwError=false]
     */
    createPaymentPlan(buildingID, apartmentNumber, data, throwError = false) {
        return this.#client.post(`/tenants/payments/${buildingID}/${apartmentNumber}`, data, { throwError });
    }

    /**
     * for now, just sends an email to building managers
     * @param {string|number} buildingID
     * @param {string} apartmentNumber
     * @param {{
     *     tenantName: string,
     *     phone: string,
     *     comment: string,
     *     date: Date,
     *     paymentMethod: string,
     * }} data
     */
    chequeCollection(buildingID, apartmentNumber, data) {
        const url = `/tenants/cheque-collection/${buildingID}/${apartmentNumber}`;
        return this.#client.post(url, data);
    }


    /**
     * @param {number|string} buildingID
     * @param {string} apartmentNumber
     * @param {BankChargePayload} data
     */
    bankCharge(buildingID, apartmentNumber, data) {
        return this.#client.post(`/tenants/bankCharge/${buildingID}/${apartmentNumber}`, data, { throwError: true });
    }

    /**
     * @param {string} cartId
     * @param {boolean} [includeData=false]
     * @returns {Promise<{apartment_details: {}}>}
     * */
    getOrderStatus(cartId, includeData = false) {
        const options = {};
        if (includeData) {
            options.includeData = true;
        }
        return this.#client.get(`/tenants/order/${cartId}`, options);
    }

    /**
     * @param {number | string} buildingID
     * @param {'on_going_monthly' | 'past_due_unpaid_monthly' | 'one_time_payment'} paymentType
     * @param {number} amountInCents
     * @param {object} [options = {}]
     * @param {number} [options.oneTimePaymentSettingsId] in case of one-time payment
     * @param {number} [options.paymentsCount] in case of ongoing payment
     * @return {Promise<InstallmentParams>}
     * */
    getInstallmentOptions(buildingID, paymentType, paymentMethod, amountInCents, options = {}) {
        //https://bllink.atlassian.net/browse/SSB-1936 New payment flow - Error - bug - 7486
        amountInCents = Math.round(amountInCents);
        return this.#client.post(`/tenants/installments/${buildingID}/${paymentType}`, { paymentMethod, amountInCents, ...options }, { throwError: true });
    }

    /**
     * @param {number[]} paymentIds
     * */
    getPaymentStates(paymentIds) {
        return this.#client.post('/tenants/payment-state', { paymentIds }, { throwError: true });
    }

    /**
     * @param {number | string} buildingId
     * @param {string} apartmentNumber
     * @param {string } phone
     * @return {Promise<LastCreditCardDetails[]> | Promise<{haveExistingCards: boolean}>}
     * */
    getExistingCardDetails(buildingId, apartmentNumber, phone) {
        return this.#client.get(`/tenants/operations/apartmentDetails/${buildingId}/${apartmentNumber}?phone=${phone}`, {}, { throwError: true });
    }

    // this will only work with validation and only 20 minutes after the cart creation date
    // for security reasons
    quickChargeFromExistingCard(buildingId, apartmentNumber, productName, cartID, tenantDetails, buildingAddress){
        return this.#client.post(`/tenants/quickChargeFromExistingCard/${buildingId}/${apartmentNumber}/`, {tenantDetails, cartID, productName, buildingAddress}, { throwError: true });
    }
}
