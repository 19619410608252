import { forwardRef, Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SideMenuStyled } from "./sideMenu.styled";
import longArrowRight from "../../../images/newDesign/longArrowRight.svg";
import { setBlockSidebarCollapsing, setIsSideMenuOpened } from "../../../store/common/slice";
import { NewScrollbar } from "../../../common/newScrollbar";

/**
 * @param {HTMLElement} container
 * @param {'right' | 'left'} position
 * @param {(boolean) => void} onToggle
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const SideMenu = forwardRef(({ container, position, onToggle, children }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLtrEnabled = useSelector(state => state.common.ltrEnabled);
    const sidebarScrollTop = useSelector(state => state.common.sidebarScrollTop);
    const [isVisible, setIsVisible] = useState(false);
    const [positioning, setPositioning] = useState(null);
    const [bounding, setBounding] = useState({ top: null, right: null, left: null });
    const [initialBounding, setInitialBounding] = useState({ top: null, right: null, left: null });
    const menuRef = useRef();
    const menuContentRef = useRef();
    const initialScrollTop = useRef(0);

    const sideMenuTopPadding = 46;
    const sideMenuBottomPadding = 20;
    const menuPadding = 10;
    const maxHeight = 260;
    const maxWidth = 230;

    const getStyle = useMemo(() => {
        const styles = {
            display: isVisible ? 'flex' : 'none',
        }
        if (bounding.top !== null) {
            styles.top = bounding.top;
        }
        if (bounding.bottom !== null) {
            styles.bottom = bounding.bottom;
        }
        if (bounding.left !== null) {
            styles.left = bounding.left;
        }
        if (bounding.right !== null) {
            styles.right = bounding.right;
        }
        return styles;
    }, [bounding, isVisible]);

    const handleCloseClick = () => {
        setIsVisible(false);
    }

    const getScrollbarStyles = useMemo(() => {
        const calculatedHeight = menuContentRef.current?.clientHeight > 0 ? Math.min(menuContentRef.current?.clientHeight, maxHeight) : 0;

        return {
            width: `${maxWidth}px`,
            height: `${calculatedHeight}px`
        }
    }, [menuContentRef.current, children])

    const menuContent = (
        <SideMenuStyled ltr={isLtrEnabled}>
            <div className='bllink-side-menu' style={getStyle} ref={menuRef}>
                <div className='bllink-side-menu-header'>
                    <img
                        src={longArrowRight}
                        alt={'Close'}
                        onClick={handleCloseClick}
                        className={`bllink-side-menu-header-img ${isLtrEnabled ? 'reversed-icon' : ''}`}/>
                </div>
                <div className='bllink-side-menu-content'>
                    <NewScrollbar
                        width={getScrollbarStyles.width}
                        height={getScrollbarStyles.height}
                        mobileNative={true}
                        noScrollX={true}
                        rtl={!isLtrEnabled}
                    >
                        <div ref={menuContentRef}>
                            {children}
                        </div>
                    </NewScrollbar>
                </div>
            </div>
        </SideMenuStyled>
    );

    /**
     * @param {MouseEvent} event
     */
    const outsideClickListener = useCallback((event) => {
        if (container?.contains(event.target)) {
            initialScrollTop.current = !isVisible ? sidebarScrollTop : 0;
            setIsVisible(!isVisible);
            return;
        }
        if (!menuRef.current?.contains(event.target)) {
            setIsVisible(false);
        }
    }, [isVisible, menuRef.current, sidebarScrollTop]);

    const handleClick = useCallback((event) => {
        event.preventDefault();
        if (container) {
            let boundingData = {};
            const containerBounding = container.getBoundingClientRect();
            const viewWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
            const viewHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
            //set on top of container if no space below
            if (viewHeight < containerBounding.top - sideMenuTopPadding + maxHeight) {
                boundingData = {
                    bottom: Math.min(viewHeight - containerBounding.top - containerBounding.height - sideMenuBottomPadding, 0),
                }
            } else {
                boundingData = {
                    top: containerBounding.top - sideMenuTopPadding,
                }
            }

            boundingData.left = positioning === 'right' ? containerBounding.right + menuPadding : null;
            boundingData.right = positioning === 'right' ? null : viewWidth - containerBounding.left;

            setInitialBounding(boundingData);
            setBounding(boundingData);
        }
    }, [container, positioning])

    useEffect(() => {
        if (!isVisible || !initialBounding) return;
        if (initialBounding.top || initialBounding.top === 0) {
            setBounding({
                ...initialBounding,
                top: initialBounding.top - sidebarScrollTop + initialScrollTop.current,
            });
        }
        if (initialBounding.bottom || initialBounding.bottom === 0) {
            setBounding({
                ...initialBounding,
                bottom: initialBounding.bottom + sidebarScrollTop - initialScrollTop.current,
            });
        }
    }, [sidebarScrollTop, initialScrollTop, initialBounding, isVisible]);

    useEffect(() => {
        if (container) {
            container.onclick = handleClick;
        }
    }, [container, handleClick])

    useEffect(() => {
        setIsVisible(false);
        if (position) {
            setPositioning(position);
        } else {
            setPositioning(isLtrEnabled ? 'right' : 'left');
        }
    }, [isLtrEnabled, position])

    useEffect(() => {
        onToggle && onToggle(isVisible);
        dispatch(setIsSideMenuOpened(isVisible));
        if (isVisible) dispatch(setBlockSidebarCollapsing(true));
    }, [isVisible]);

    useEffect(() => {
        document.addEventListener('click', outsideClickListener);

        return () => {
            document.removeEventListener('click', outsideClickListener);
        }
    }, [outsideClickListener])

    useImperativeHandle(ref, () => ({
        close() {
            setIsVisible(false);
        }
    }));

    return (
        <Fragment>
            {container ? createPortal(
                menuContent,
                document.body,
            ) : null}
        </Fragment>
    );
});
