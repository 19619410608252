import styled from "styled-components";
import { theme } from "../../theme";

const StyledDelimiter = styled.div`
  .bllink-delimiter {
    margin: 0;
    padding: 0;
    min-width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: ${props => props.color};

    &-container {
      min-width: 100%;
      max-width: 100%;
      margin: ${props => props.margin || '0'};
      padding: ${props => props.padding || '0'};
    }
  }
`
/**
 * @param margin
 * @param padding
 * @param color
 * @return {JSX.Element}
 * @constructor
 */
export default function Delimiter({margin, padding, color}) {
    return (
        <StyledDelimiter padding={padding} margin={margin} color={color || theme.borderLight}>
            <div  className='bllink-delimiter-container'>
                <div className='bllink-delimiter'></div>
            </div>
        </StyledDelimiter>
    )
}
