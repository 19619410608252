export const creditCardChoice = {
    "confirmWord": "confirm",
    "headers": {
        "selectCard": "Select billing from a new or existing card",
        "selectExistingCard": "Select billing from an existing card",
        "confirm": "Verification process - existing credit card"
    },
    "infoText": {
        "paidInThePast": "The system identified that the tenant had paid through us in the past",
        "shorterProcess": "For quick billing, charge from an existing card",
        "verify": "To continue the process, please verify the tenant's permission",
        "type": "and type 'confirm'",
        "typeConfirm": "Type 'confirm' here",
        "disabledExistingChoice": "You can not make future payments with existing credit card"
    },
    "checkboxLabels": {
        "newCard": "Make payment from new card",
        "existingCard": "Make payment from existing card"
    },
    "btns": {
        "continue": "Continue to payment",
        "charge": "Charge card"
    },
    "tableHeaders": {
        "name": "Name",
        "lastUsed": "Recently Charged",
        "cardLast4Digits": "Card last 4 digits",
        "phone": "Phone",
        "email": "Email",
        "expiry": "Expiry"
    }
};
