export class OperationsApi {

    #client;
    #prefix = '/tenants/operations'


    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string | number} buildingID
     * @param {string} apartmentNumber
     * @return {Promise<LastCreditCardDetails[]>}
     * */
    async getApartmentDetails(buildingID, apartmentNumber) {
        return await this.#client.get(`${this.#prefix}/apartmentDetails/${buildingID}/${apartmentNumber}`, {}, { throwError: true });
    }

    /**
     * @param {string } buildingID
     * @param {string} apartmentNumber
     * @param {string } cartID
     * @param {string} last4Digits
     * @param {number} paymentID
     * @param {boolean} isBankCharge
     * @param {boolean} isFuturePayment
     * @return {Promise<boolean>}
     * */
    async chargeCart(buildingID, apartmentNumber, cartID, paymentID, last4Digits, isBankCharge, isFuturePayment) {
        return await this.#client.post(`${this.#prefix}/charge-cart/${buildingID}/${apartmentNumber}`, {cartID, last4Digits, paymentID, isBankCharge, buildingID, isFuturePayment}, { throwError: true });
    }



}
