export const login = {
    "login_building_title": "Manager Login to building account",
    "login_building_sub_title": "Login to building account will enable you to monitor and manage building payments and easily watch tenants receipts",
    "forgot_password": "Forgot password?",
    "login_with": "Login with",
    "open_account": "Open account",
    "login_to_account": "Login",
    "sign_up_for_demo": "Sign up for a demo account",
    "logout": "Logout",
    "hi": "Hi, {{firstName}}",
    "facebook": "Facebook",
    "are_you_a_tenant": "not a manager? Login as tenant",
    "google": "Google",
    "login_generic_error": "Error logging in. Please check username and password",
    "reset_password_sub_title": "Glad you moved into our new system. For initial login please select a strong password and remember it for future login",
    "reset_password_sub_explain": "Immediately after selecting new password, you will be redirected to the login page with the new password",
    "new_password": "New password",
    "new_password_again": "Repeat new password",
    "reset_password": "Reset password",
    "not_working_on_mobile": "Dear manager, unfortunately the link is not available for you from your mobile yet",
    "only_maintenance_on_mobile": "Dear manager, the link from your mobile is available only for maintenance managers yet",
    "must_contain": "Password must contain:",
    "small_letter": "A small letter in English",
    "capital_letter": "A capital letter in English",
    "number": "Number",
    "8_digits": "At least 8 digits",
    "same_passwords": "The 2 passwords entered are the same"
};
