import {useTranslation} from 'react-i18next';
import {useMemo, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col} from 'react-bootstrap';
import Select from 'react-select';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {StyledPdfResult} from './autoRecordPayments.styled';
import Table from '../../common/table/TableFunctional';
import BllinkUtils from '../../common/utils/utils';
import {selectCustomStyles} from '../../common/shared/select.styled';
import {useAccessToken} from '../../common/hooks/useAccessToken';
import {fetchBuildingData} from '../../store/buildings/actions';
import {hideLoader, showLoader} from '../../store/common/slice';

const StringUtils = BllinkUtils.Strings;
const BllinkTime = BllinkUtils.BllinkTime;

export default function PaymentResult({onApprove, onDismiss, pdfData, buildingId}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [selectedApartment, setSelectedApartment] = useState({
        label: '',
        value: ''
    });
    const [apartmentOptions, setApartmentOptions] = useState([]);
    const [openedPayments, setOpenedPayments] = useState([]);
    const [payments, setPayments] = useState([].concat(Object.values(pdfData.payments).flat()));
    const [paymentsByMonths, setPaymentsByMonths] = useState([].concat(Object.values(pdfData.months)));
    const [skipPaymentsPageReset, setSkipPaymentsPageReset] = useState(false);
    const [skipMonthsPageReset, setSkipMonthsPageReset] = useState(false);
    const currentBuildingData = useSelector(state => state.buildings.currentBuildingData);
    const client = useAccessToken();

    useEffect(() => {
        (async function () {
            if (!client) return;

            if (!currentBuildingData.id || currentBuildingData.id !== buildingId) {
                await dispatch(fetchBuildingData({buildingID: buildingId}))
            }
        })();
    }, [buildingId, client]);

    useEffect(() => {
        (async function () {
            if (currentBuildingData.id === buildingId) {
                const isApartmentExist = currentBuildingData.apartments.find(item => item.apartmentNumber === pdfData.apartmentNumber);
                let apartments = [...currentBuildingData.apartments];
                let defaultApartment = isApartmentExist ? isApartmentExist : currentBuildingData.apartments && currentBuildingData.apartments[0];

                if (!isApartmentExist) {
                    const pdfApartment = {
                        label: t('reports.apartment_number', {apartmentNumber: pdfData.apartmentNumber}),
                        value: pdfData.apartmentNumber,
                        apartmentNumber: pdfData.apartmentNumber
                    };
                    apartments = [
                        (pdfApartment.apartmentNumber && pdfApartment),
                        ...apartments
                    ];
                    defaultApartment = pdfData.apartmentNumber ? pdfApartment : defaultApartment;
                }
                setApartmentOptions(apartments);
                setSelectedApartment(defaultApartment);
            }
        })();
    }, [currentBuildingData]);

    useEffect(() => {
        (async function () {
            if (selectedApartment.apartmentNumber) {
                dispatch(showLoader());
                const paymentsData = await client.get(`/tenants/payments/${buildingId}/${selectedApartment.apartmentNumber}`);
                getResultPaymentsList(paymentsData);
                dispatch(hideLoader());
            }
        })();
    }, [selectedApartment.apartmentNumber]);

    useEffect(() => {
        setSkipPaymentsPageReset(false);
        setSkipMonthsPageReset(false);
    }, [pdfData]);

    const getResultPaymentsList = (paymentsData) => {
        const openedPayments = paymentsByMonths.map(item => {
            let openedPayment;
            if (BllinkTime.isPastDate(item.month, item.year)) {
                openedPayment = paymentsData.past_payments.paymentsSortedArray.find(payment => item.month === parseInt(payment.month) && item.year === parseInt(payment.year));
            } else {
                openedPayment = paymentsData.on_going_payments.paymentsSortedArray.find(payment => item.month === parseInt(payment.month) && item.year === parseInt(payment.year));
            }
            return openedPayment || false;
        }).filter(item => item);
        setOpenedPayments(openedPayments || []);
    };

    const paymentsColumns = useMemo(
        () => [
            {
                Header: t('payment_settings.amountHeader'),
                id: 'amount',
                accessor: row => row.amount.value
            },
            {
                Header: t('payment_settings.method'),
                accessor: 'paymentMethod',
                Cell: ({value}) => (
                    <span>{value}</span>
                ),
            },
        ],
        [pdfData.payments]
    );

    const openedPaymentsArray = useMemo(() => openedPayments, [openedPayments]);

    const monthsColumns = useMemo(
        () => [
            {
                Header: t('payment_settings.month'),
                accessor: 'month',
                Cell: ({value}) => (
                    <span>{t(`common.months.${value}`)}</span>
                ),
            },
            {
                Header: t('payment_settings.year'),
                accessor: 'year',
                Cell: ({value}) => (
                    <span>{value}</span>
                ),
            },
            {
                Header: t('payment_settings.amountHeader'),
                accessor: 'amount',
            },
        ],
        [pdfData.months]
    );

    const openedMonthsColumns = useMemo(
        () => [
            {
                Header: t('payment_settings.month'),
                accessor: 'month',
                Cell: ({value}) => (
                    <span>{t(`common.months.${value}`)}</span>
                ),
            },
            {
                Header: t('payment_settings.year'),
                accessor: 'year',
            },
            {
                Header: t('payment_settings.amountHeader'),
                accessor: 'amount_left',
                Cell: ({value}) => (
                    <span>{StringUtils.numberWithCommas(value?.value)}</span>
                ),
            },
        ],
        [openedPayments]
    );

    const updateTableData = (rowIndex, columnId, value, onSkipReset, setNewData) => {
        let isValid = true;
        let error = '';
        if (['amount'].includes(columnId)) {
            isValid = value ? BllinkUtils.JsExtras.isNumeric(value) : true;
            error = isValid ? '' : t('reports.fieldError', {field: columnId});
        }

        onSkipReset(true);
        setNewData(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    const updatedVal = row[columnId].value ? {
                        value: parseInt(value),
                        currency: row[columnId].currency
                    } : parseInt(value);

                    return {
                        ...old[rowIndex],
                        [columnId]: updatedVal,
                        ...(old.part_pay && {
                            part_pay: {
                                value: parseInt(value),
                                currency: old.part_pay.currency
                            }
                        }),
                        errors: {
                            ...row.errors,
                            [columnId]: {
                                touched: true,
                                valid: !error,
                                error: error ? error : undefined
                            }
                        }
                    };
                }
                return row;
            })
        );
    }

    const updateMonthsTableData = (rowIndex, columnId, value) => {
       updateTableData(rowIndex, columnId, value, setSkipMonthsPageReset, setPaymentsByMonths);
    }

    const updatePaymentsTableData = (rowIndex, columnId, value) => {
        updateTableData(rowIndex, columnId, value, setSkipPaymentsPageReset, setPayments);
    }

    const isApproveDisabled = () => {
        const isMonthsOverpaid = paymentsByMonths.map(item => {
            const isMonthsOpened = openedPayments.find(openedMonth => parseInt(item.month) === parseInt(openedMonth.month) && parseInt(item.year) === parseInt(openedMonth.year));
            return isMonthsOpened ? parseInt(item.amount) > isMonthsOpened.amount_left.value : item.amount > 0;
        });
        return !openedPayments.length || isMonthsOverpaid.some(item => item);
    }

    return (
        <StyledPdfResult>
            <Row className={'mb-4'}>
                <Col sm={4}>
                    <Select
                        styles={selectCustomStyles}
                        options={apartmentOptions}
                        onChange={evt => setSelectedApartment(evt)}
                        value={selectedApartment}
                        name="apartment"
                    />
                </Col>
            </Row>

            {
                payments.length > 0 && (
                    <Table
                        columns={paymentsColumns}
                        data={payments}
                        tableTitle={t('payment_settings.totalPayments', {
                            amount: StringUtils.numberWithCommas(pdfData.totalToPay),
                            num: payments.length,
                            apartmentNum: pdfData.apartmentNumber
                        })}
                        className="payments-table"
                        updateData={updatePaymentsTableData}
                        skipPageReset={skipPaymentsPageReset}
                    />
                )
            }

            {
                paymentsByMonths.length > 0 && (
                    <Table
                        columns={monthsColumns}
                        data={paymentsByMonths}
                        className="payments-table"
                        tableTitle={t('payment_settings.paidMonths')}
                        updateData={updateMonthsTableData}
                        skipPageReset={skipMonthsPageReset}
                    />
                )
            }

            {
                openedPaymentsArray.length > 0 ? (
                    <Table
                        columns={openedMonthsColumns}
                        data={openedPaymentsArray}
                        className="payments-table"
                        tableTitle={t('payment_settings.openedMonths')}
                    />
                ) : (<p className={'text-center mb-4'}>{t('payment_settings.noOpenedMonths')}</p>)
            }

            <Row className="justify-content-center">
                <OverlayTrigger
                    placement="top"
                    overlay={props => (
                        <Tooltip {...props}>
                            {isApproveDisabled() && t('payment_settings.overpay')}
                        </Tooltip>
                    )}
                >
                    <Col sm={3}>
                        <button
                            className="bllink-button"
                            disabled={isApproveDisabled()}
                            onClick={() => onApprove(payments, paymentsByMonths, selectedApartment.apartmentNumber)}
                        >
                            {t('expenses.approve')}
                        </button>
                    </Col>
                </OverlayTrigger>
                <Col sm={3}>
                    <button
                        className="bllink-button"
                        onClick={onDismiss}
                    >
                        {t('payment_settings.dismiss')}
                    </button>
                </Col>
            </Row>
        </StyledPdfResult>
    );
}
