import { constants } from "../../../constants/constants";
import {lazyReload} from "../../../lazyReload";
const BuildingManagerProfile = lazyReload(() => import("../../../../managers/building/buildingManagerProfile"));
const DebtList = lazyReload(() => import('../../../../managers/reports/debt_list/debtList'));
const MonthlyReportsAtPartner = lazyReload(() => import("../../../../managers/reports/monthly_reports_at_partner_level/monthlyReportsAtPartner"));
const ChequeReportForPartners = lazyReload(() => import("../../../../managers/reports/monthly_reports_at_partner_level/chequeReportForPartners"));

export const PartnerReports = [
    {path: `/${constants.pages.partnerMonthlyReports}/:partnerID/:month/:year`, component: MonthlyReportsAtPartner, exact: true},
    {path: `/${constants.pages.monthlyPartnerChequesUrl}/:partnerID/:month/:year`, component: ChequeReportForPartners, exact: true},
    {
        path: `/${constants.pages.buildingManagerCompanyProfileUrl}/:partnerID`,
        component: (props) => <BuildingManagerProfile {...props} isManagementCompany={true} />,
        exact: true
    },
    {path: `/${constants.pages.partnerDebtList}/:partnerID`, component: DebtList, exact: true},
]



