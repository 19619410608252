import { Fragment, useMemo } from "react";
import MessagesSidebarMenu from "./messagesSidebarMenu";
import UpdatePaymentRatesSidebarMenu from "./updatePymentsRatesSidebarMenu";
import { MenuNavItem } from "../../sidebar/MenuNavItem";
import dashboard from "../../../images/newDesign/main_building.svg";
import managerAi from "../../../images/newDesign/manager.svg";
import { constants } from "../../../common/constants/constants";
import { useDispatch, useSelector } from "react-redux";
import CacheData from "../../../common/utils/cacheData";
import { toggleMobileMenuOpened } from "../../../store/common/slice";
import { useTranslation } from "react-i18next";
import tenants from "../../../images/newDesign/tenants.svg";
import archive from "../../../images/newDesign/archive.svg";
import { BuildingsListSidebarMenu } from "./buildingsListSidebarMenu";
import {hasPremiumAccessSelector} from "../../../store/auth/selectors";

/**
 * @param buildingId
 * @param navData
 * @param match
 * @return {JSX.Element}
 * @constructor
 */
export default function BuildingSidebarGroup({ buildingId, navData, match }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));
    const hideListItems = useSelector(state => state.common.hideListItems);
    const isPremiumUser = useSelector(hasPremiumAccessSelector);
    // todo replace isPremiumUser with shouldBlockPremiumAccess = isPremiumUser && date > 2024-01-01 / 2024-02-01
    // motivation is that want to show the info page https://app.bllink.co/premium-info before actually blocking access

    const closeSidebarOnMobileClick = () => {
        dispatch(toggleMobileMenuOpened())
    }

    const showListsData = useMemo(() => {
        return !hideListItems && !!localBuildingId;
    }, [hideListItems, localBuildingId])

    return (
        <Fragment>
            <BuildingsListSidebarMenu match={match}/>
            {showListsData && localBuildingId ? <Fragment>
                <MenuNavItem
                    icon={dashboard}
                    url={`/${constants.pages.buildingManagerProfileUrl}/:buildingID`}
                    urlParams={{ buildingID: localBuildingId }}
                    onClick={closeSidebarOnMobileClick}
                    label={t('buildings.nav_bar_main_screen')}
                />
                <MenuNavItem
                    icon={managerAi}
                    url={''}
                    triggerEvent={'openChatAI'}
                    stopRedirect={true}
                    onClick={closeSidebarOnMobileClick}
                    label={t('buildings.nav_bar_ai_agent')}
                />
                <MenuNavItem
                    icon={tenants}
                    url={`/${constants.pages.tenantListUrl}/:buildingID`}
                    urlParams={{ buildingID: localBuildingId }}
                    onClick={closeSidebarOnMobileClick}
                    label={t('buildings.nav_bar_tenant_list')}
                    className="mobile-hide"
                />
                <MenuNavItem
                    icon={archive}
                    url={`/${constants.pages.archivePage}/:buildingID`}
                    urlParams={{ buildingID: localBuildingId }}
                    onClick={closeSidebarOnMobileClick}
                    label={t('buildings.nav_bar_archive')}
                    className='mobile-hide'
                    disabled={!isPremiumUser}
                    tooltip={t('buildings.upgrade_to_premium')}
                    tooltipDisabled={isPremiumUser}
                />
                <MessagesSidebarMenu buildingId={buildingId}/>
            </Fragment> : null}
            {showListsData ? <UpdatePaymentRatesSidebarMenu buildingId={buildingId} navData={navData}/> : null}
        </Fragment>
    );
}
