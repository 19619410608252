import CacheDate from "../utils/cacheData";
import CacheData from "../utils/cacheData";
import { constants } from "../constants/constants";

export default class PaymentsLogic{

    static getOngoingPayments(buildingID, apartmentNumber){
        return this.getPayments(buildingID, apartmentNumber,
            'onGoingSelectedPayments', constants.pages.onGoingTab
        );
    }

    static getDebtPayments(buildingID, apartmentNumber){
        return this.getPayments(buildingID, apartmentNumber,
            'debtSelectedPayments', constants.pages.debtTab
        );
    }

    static getOneTimePayments(buildingID, apartmentNumber){
        return this.getPayments(buildingID, apartmentNumber,
            'oneTimeSelectedPayments', constants.pages.oneTimeTab
        );
    }


    static getPayments(buildingID, apartmentNumber, cacheKey, tabName){
        let payments = CacheDate.fetchCache(cacheKey,
            {buildingID: buildingID, apartmentNumber: apartmentNumber}) || [];

        const visitedTabs = CacheData.fetchCache('visitedTabs') || {};
        if (!visitedTabs[tabName]){
            //https://app.asana.com/0/1196545425570329/1199887848518432
            // payment - the "I'm done, want to pay" button in the payment flow needs to deselect all payments in the next item
            // only show payments from visited tabs
            payments = []
        }

        return payments
    }

}
