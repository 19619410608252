const defaultIsHebrew = navigator.languages.includes('he');
const defaultIsSpanish = navigator.languages.includes('es');
// const defaultLang = defaultIsHebrew ? 'he': defaultIsSpanish ? 'es': 'en';
const defaultLang = 'he';
// const DEFAULT_LANGUAGE = defaultLang;
// reverted back since a lot of users got english (I saw on mobile mostly)
// maybe we can give priority to location
const DEFAULT_LANGUAGE = defaultLang;

export {
    DEFAULT_LANGUAGE,
};
