import { createSlice } from '@reduxjs/toolkit';
import {verifyLoggedIn, getUserProfile} from './actions';
import CacheData from '../../common/utils/cacheData';
import { constants } from '../../common/constants/constants';
import {BllinkLogger} from '../../common/utils/bllink_loggers';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: '',
        user: {},
        userCheck: false,
        userProfile: {},
    },
    extraReducers: builder => {
        builder.addCase(verifyLoggedIn.fulfilled, (state, { payload }) => {
            if (payload && payload.accessToken) {
                state.accessToken = payload.accessToken;
                state.user = payload.attributes || {};
                state.userCheck = true;
            }
        })
        builder.addCase(verifyLoggedIn.rejected, (state, action) => {
            state.user = {};
            state.accessToken = '';
            state.userCheck = false;

            BllinkLogger.info(`will redirect to login`);
            if (!window.location.href.includes('/login')) CacheData.cache('lastPage', window.location.href);
            window.location.href = `/${constants.pages.loginUrl}`;
        })
        builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
            state.userProfile = payload;
        })
        builder.addCase(getUserProfile.rejected, (state, action) => {
            state.userProfile = {};
        })
    }
});

export default authSlice.reducer;
