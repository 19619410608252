export const autoCharge = {
    "ownerId": "Social Security/ID Number",
    "continue": "Continue to digital signature",
    "apartment": "Apartment",
    "confirm": "Confirm",
    "signature": "Signature",
    "clear": "Clear",
    "fileName": "Bank_charge_agreement_for_",
    "success": "Request was sent!",
    "tenantSuccess" : "The request is pending. Payment confirmation will be displayed after confirmation",
    "failed": "Request failed",
    "back": "Back",
    "date": "Charge date",
    "title": "Fill in details for a standing order to charge directly from your bank account",
    "address": "Address",
    "name": "Name",
    "phone": "Phone"
};
