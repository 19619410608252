export const searchPayments = {
    "mainHeader": "Search payment plans",
    "nothingFound": "Nothing was found",
    "tableTitle": "Search results",
    "noAdminRights": "You don't have admin rights",
    "planScreensHeader": "Plan Actions",
    "tableHeaders": {
        "createdAt": "Created at",
        "planDescription": "Plan description",
        "planStatus": "Plan status",
        "planComment" : "Plan comment",
        "clientName": "Client name",
        "aptNumber": "Apartment number"
    },
    "placeholders": {
        "address": "Address",
        "buildingId": "Building ID",
        "fourDigits": "Card 4 digits",
        "apartmentNumber": "Apartment number",
    },
    "btns": {
        "search": "Search",
        "return": "Return",
        "planActions": "Plan actions",
        "send": "Send",
        "update": "Update"
    },
    "planActionsHeaders": {
        "onlineHeader": "Plan actions with id ",
        "offlineHeader": "Change offline payment method"
    },
    "planActionSubheaders": {
        "buildingAddress": "Buliding address is",
        "planStatus": "Plan status is",
        "planMonths": "Plan months are",
        "planCreatedBy": "plan created by ",
        "payments": "Payments are (click to open)",
        "createdByDefault": "Tenant",
    },
    "radioHeaders": {
        "extend": "Extend plan until",
        "shorten": "Shorten plan until",
        "stopAll": "Stop all pending payments",
        "renew": "Renew plan until",
        "switchCard": "Switch/charge payment plan credit card to"
    },
    "notifications": {
        "planWasSuccessfully": "Plan was successfully",
        "failure": "Failure! Try again",
        "chooseDate": "You need to choose date",
        "chooseCard": "You need to choose card",
        "extended": "extended",
        "shortened": "shortened",
        "stopped": "stopped",
        "renewed": "renewed",
        "dataSent": "Data was successfully sent!",
        "cardCharged": "charged",
    },
    "paidAt": "paid at",
    "forMonths": "for months",
    "newCard": "New credit card"
};
