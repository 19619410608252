import styled from "styled-components";
import { MenuItem } from "react-pro-sidebar";
import { useSelector } from "react-redux";

const SideMenuTitleStyled = styled.div`
  .bllink-sidebar-menu-title {
    list-style: none;
    padding: 5px 0;

    .pro-inner-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;

      .pro-icon-wrapper {
        width: 24px;
        height: 24px;
        margin: ${props => !!props.ltr ? '0 20px 0 6px' : '0 6px 0 20px'};
      }
    }

    &-label {
      color: ${props => props.theme.main};
    }
  }
`;

/**
 * @param {string} icon
 * @param {string} label
 * @param {string} className
 * @param {() => void} onClick
 * @return {JSX.Element}
 * @constructor
 */
export const SideMenuTitle = ({ icon, label, className, onClick }) => {
    const isLtrEnabled = useSelector(state => state.common.ltrEnabled);
    return (
        <SideMenuTitleStyled ltr={isLtrEnabled}>
            <MenuItem
                icon={icon == null ? '' : (<img src={icon} alt="icon"/>)}
                className={`bllink-sidebar-menu-title bllink-text-bold ${className || ''}`}
                onClick={onClick}>
                <div className="bllink-sidebar-menu-title-label">{label}</div>
            </MenuItem>
        </SideMenuTitleStyled>
    )
}
