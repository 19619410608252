import styled from "styled-components";
import {MenuItem} from "react-pro-sidebar";
import {isLinkActive} from "../../sidebar/Sidebar.shared";
import {generatePath, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {Tooltip} from "../../../ui/Tooltip";

const SideMenuItemStyled = styled.div`
  .bllink-sidebar-menu-item {
    list-style: none;

    .pro-inner-item {
      .pro-item-content {
        .nav-link {
          color: ${props => props.theme.text};
          opacity: 0.7;
          margin: ${props => !!props.ltr ? '0 0 0 34px' : '0 34px 0 0'};
          padding: 5px 16px;

          &.active {
            color: ${props => props.theme.main};
            opacity: 1;
          }

          &.disabled {
            opacity: .5;
          }
        }
      }

      &[role=button] {
        cursor: ${props => props.disabled ? 'default' : 'pointer'};
      }
    }
  }
`;

/**
 * @param {string} url
 * @param {string} icon
 * @param {string} label
 * @param {() => void} onClick
 * @param {string} className
 * @param {Object} urlParams
 * @param {boolean} isActiveWithQuery
 * @param {boolean} disableRouteAction
 * @param {boolean} disabled
 * @param {string} tooltip
 * @param {boolean} tooltipDisabled
 * @return {JSX.Element}
 * @constructor
 */
export const SideMenuItem = ({
                                 url,
                                 icon,
                                 label,
                                 onClick,
                                 className,
                                 urlParams,
                                 isActiveWithQuery,
                                 disableRouteAction = false,
                                 disabled = false,
                                 tooltip,
                                 tooltipDisabled = true
                             }) => {
    const isLtrEnabled = useSelector(state => state.common.ltrEnabled);
    /**
     * @param {MouseEvent} e
     */
    const blockedClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
    }
    return (
        <SideMenuItemStyled ltr={isLtrEnabled} disabled={disabled}>
            <MenuItem icon={icon == null ? '' : (<img src={icon} alt="icon"/>)} active={isLinkActive(url, {
                query: !!isActiveWithQuery
            })}
                      onClick={disableRouteAction || disabled ? blockedClick : onClick}
                      className={`bllink-sidebar-menu-item bllink-text ${className || ''}`}>
                <Tooltip text={tooltip} disabled={tooltipDisabled}>
                    <NavLink
                        className={`nav-link ${disabled ? 'disabled' : ''}`}
                        to={generatePath(url, urlParams)}
                        onClick={disableRouteAction || disabled ? blockedClick : () => {}}>
                        {label}
                    </NavLink>
                </Tooltip>
            </MenuItem>
        </SideMenuItemStyled>
    )
}
