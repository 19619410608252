export const notifications = {
    "tenantDetailsFor": "פרטי דיירים - דירה",
    "name": "שם",
    "phone": "טלפון",
    "email": "אימייל",
    "renter": "שוכר",
    "owner": "בעלים",
    "showAll": "הראה את כל הבניינים",
    "chooseBuilding": "בחר\/י בניין",
    "more_apartment_details": "פרטים נוספים לדירה",
    "sendRequest": "שלח\/י דרישת תשלום",
    "recordPayment": "תיעוד תשלום",
    "charge": "תשלום באמצעות כרטיס אשראי",
    "emptyList": "אין הודעות להצגה",
    "sendWarningLetter": "שלח/י מכתב אזהרה",
    "title": "מרכז הודעות",
    "apartment": "דירה",
    "warningLetterTitle": "מכתב אזהרה",
    "sendWarningLetterTo": "שלח מכתב אזהרה(אימייל) ל",
    "dismiss": "הסר",
    "commentDismissed": "הערה הוסרה בהצלחה",
};
