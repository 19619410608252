class Filter {
    static filterItems(items, filter) {
        let show = false;
        [...items].forEach(item => {
            if (filter === '' || item?.toString().toLowerCase().includes(filter)) show = true;
        });
        return show;
    }
};

export default Filter; 