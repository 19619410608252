export class MaintenanceApi {

    /**
     * @typedef {{
     *    token: string;
     *    noFeatureSet: boolean;
     * }} MaintenanceLoginResponse
     * */

    /**
     * @typedef {{
     *     Sevirity: 'low' | 'medium' | 'high' | 'immediate';
     *     SevirityDescription: string;
     *     Comments: string;
     *     CategoryId: number;
     *     CategoryDescription: string;
     *     AppImages?: string[];
     *     OpeningResident?: {
     *         Name?: string | null;
     *         Phone?: string | null;
     *         Email?: string | null;
     *     }
     * }} MaintenanceCreateFaultPayload
     * */

    /**
     * @typedef {{
     *     IsDeleted: boolean;
     *     Id: number;
     *     Name: string;
     *     ExtraFields: any[];
     * }} MaintenanceFaultCategory
     * */

    #client;
    #prefix = '/managers/maintenance';

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string|number} partnerID
     * @return {Promise<MaintenanceLoginResponse>}
     * */
    partnerLogin(partnerID) {
        return this.#client.post(`${this.#prefix}/login/partner/${partnerID}`, {}, { throwError: true });
    }

    /**
     * @param {string|number} buildingID
     * @return {Promise<MaintenanceLoginResponse>}
     * */
    buildingLogin(buildingID) {
        return this.#client.post(`${this.#prefix}/login/building/${buildingID}`, {}, { throwError: true });
    }

    /**
     * @param {number} buildingId
     * @param {string} apartmentNumber
     * @param {MaintenanceCreateFaultPayload} data
     * */
    async createFault(buildingId, apartmentNumber, data) {
        console.log('createFault', buildingId, apartmentNumber);
        const query = `
            mutation ApiClientCreateFault($buildingId: Int!, $apartmentNumber: String!, $data: LivyFaultCreatePayload!) { 
                Gateway { 
                    Livy { 
                        forBuilding(BuildingId: $buildingId) { 
                            createFault(apartmentNumber: $apartmentNumber, data: $data) 
                        }
                    }
                }
            }
        `;
        const variables = { buildingId, apartmentNumber, data };
        // changed from  avoidError: true to false to not hide images too large error.
        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: false, hideParams: true });
        if (resp.errors == null) { // todo fix this is not always true
            return resp.data.Gateway.Livy.forBuilding.createFault;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }

    /**
     * @param {number|string} buildingId
     * @return {Promise<MaintenanceFaultCategory[]>}
     * */
    async getFaultCategories(buildingId) {
        // NOTE: definition "$buildingId: Int!" is mandatory, server will replace string value by internal id,
        //       so it is safe to send string values here.
        const query = `
            query ApiClientFaultCategories($buildingId: Int!) { 
                Gateway { 
                    Livy { 
                        forBuilding(BuildingId: $buildingId) {
                            faultCategories {
                                IsDeleted
                                Id
                                Name
                                ExtraFields {
                                    name                                
                                }
                            } 
                        }
                    }
                }
            }
        `;
        const variables = { buildingId };
        const resp = await this.#client.post('/graphql', { query, variables }, { avoidError: true, hideParams: true });
        if (resp.errors == null) {
            return resp.data.Gateway.Livy.forBuilding.faultCategories;
        }
        throw new Error(resp.errors[0].message); // TODO: need some custom graphql error class to provide all errors
    }

}
