/// This component was created to share state across components
import { Component } from 'react';
import ServerData from './serverData';
import { SETTINGS } from './settings';
import CacheData from './utils/cacheData';
import {BllinkLogger} from './utils/bllink_loggers';
import BllinkUtils from '../common/utils/utils';
import MonthsRelatedLogic from './payment_flow/monthsRelatedLogic';
import PaymentsLogic from './payment_flow/paymentsLogic';
import LastConfirmationPageLogic from '../tenants/tenant_payments/lastConfirmationPageLogic';
import $ from 'jquery'
import 'bootstrap/dist/js/bootstrap.min';
import { constants } from './constants/constants';
import { Auth } from 'aws-amplify';
import { i18n } from './translations';
import BllinkTime from "./utils/time";
import offlinePaymentsLogic from "../managers/offlinePayments/offlinePaymentsLogic";
import {GAEvent} from "./google_analytics/google_analytics";
import {triggerRewardsNotification} from '../managers/rewards/rewardsEvent';
import CacheDate from './utils/cacheData';
import { Currency } from './currency';
import { store } from '../store/store';
import { hideLoader, showLoader } from '../store/common/slice';
import {BllinkLanguageDetector} from "./translations/BllinkLanguageDetector";
import { DEFAULT_LANGUAGE } from './translations/selectedLangage';
import {getFakePaymentData, isDemoApartment} from "./shared/statelessMethods";
import JsExtras from "./utils/jsExtras";

require('jquery-validation');


const CURRENCY2LNG = {
    'MXN': 'es',
    'USD': 'en',
    'ILS': 'he',
};
export class Parent extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.bindBackNextClickedFunc = this.bindBackNextClicked.bind(this)
        this.state = {};
        this.baseServerUrl = SETTINGS.server.baseUrl;
        this.googleSettings = SETTINGS.google;
        this.client = new ServerData();
        this.BllinkUtils = BllinkUtils;
        this.timeUtils = BllinkUtils.BllinkTime;
        this.StringUtils = BllinkUtils.Strings;
        this.JsExtras = BllinkUtils.JsExtras;
        this.Money = BllinkUtils.Money;
        this.logger = BllinkLogger;
        this.rerender = this.rerender.bind(this);
        this.genericBinds = this.genericBinds.bind(this);
        this.renderCorrectReceipt.bind(this);
        this.clearOutdatedCache();
        this.listenToPageChanged();
        this.getAuthenticatedClient();

        BllinkLogger.info(`${this.constructor.name} (from parent) props`, this.props)
    }

    saveCurrentBuilding(props){
        /*
            meant to pass this data to the header so the manager can see which
            building she is currently looking at
         */
        BllinkLogger.info(`enter saveCurrentBuilding with `, props)
        const buildingInUrl = props.match && props.match.params && props.match.params.buildingID;
        const buildingID = props.buildingID || buildingInUrl;
        const options = {typeOfCache: sessionStorage};

        // todo find a better way using global state
        if (buildingID) {
            CacheData.cache('currentBuilding', buildingID, options);
        }else{
            CacheData.delete('currentBuilding', options);
        }


    }

    async componentDidMount() {
        this.bindBoxToggleClick()
    }

    async verifyLoggedIn(){
        try {
            BllinkLogger.info(`verifyLoggedIn start`)
            let user = await Auth.currentAuthenticatedUser()
            this.setState({user: user, userCheck: true})
            BllinkLogger.info(`verify end with `, user?.attributes?.email);
        } catch(e) {
            this.setState({user: null})
            BllinkLogger.info(`will redirect to login`)
            this.storeCurrentPage();
            window.location.href = `/${constants.pages.loginUrl}`
        }
    }

    storeCurrentPage(){
        // to allow back to this page after successful login
        CacheData.cache('lastPage', window.location.href)
    }

    async isLoggedIn(){
        try {
            BllinkLogger.info(`isLoggedIn start`)
            await Auth.currentAuthenticatedUser()
            return true
        } catch(e) {
            return false
        }
    }

    async getAuthenticatedClient(){
        /*
            in case logged in, we need to re init the client to
            pass the access token to the server
         */
        // BllinkLogger.info(`getAuthenticatedClient`);
        if (!this.user){
            await this.getLoggedInUser()
        }

        if (this.user){
            if (this.client.autheticated){
                BllinkLogger.info(`already initialized client with token. return client`);
            }else {
                const accessToken = this.user.signInUserSession.accessToken.jwtToken;
                this.client = new ServerData(null, null, {accessToken: accessToken})
                // BllinkLogger.info(`re init server client with access token`);
            }
            return this.client
        }
    }

    /**
     * @return {Promise<ManagerProfile>}
     * */
    async getUserInfo(){
        /*
            for example: management logo url
         */

        if (this.client.autheticated) {
            const response = await this.getOrCache(async () => this.client.managers.getProfile(), 'profile');
            BllinkLogger.info(`profile response `, response);
            return response
        }
    }

    async getOrCache(funcToGetData, cacheKey, cacheOptions){
        /*

         */
        let result = CacheData.fetchCache(cacheKey, cacheOptions);
        if (result){
            BllinkLogger.info('returning generic data from local cache');
            result.building = result;

        }else {
            result = await funcToGetData();
            BllinkLogger.info(`result from building server page `, result);
            CacheData.cache(cacheKey, result, cacheOptions)
        }


        return result
    }

    async getLoggedInUser(){
        // todo this is called multiple times in one page . find a better way
        BllinkLogger.info(`getLoggedInUser start`)
        try {
            const user = await Auth.currentAuthenticatedUser();
            // BllinkLogger.info(`authenticated user is `, user);
            this.user = user;
            await this.getAuthenticatedClient()

            this.identifySession(user.attributes)
            return {
                name: user.attributes.nickname || user.attributes.email
            }
        }catch (e){
            BllinkLogger.info('Not signed in ', e)
        }

    }

    async isAdmin(){
        // is meant to rollout features for admin users. not a security risk
        let user = await this.getLoggedInUser()
        return (user && user.name && (user.name.includes('ptop.co.il') || user.name.includes('bllink.co')) )
    }

    async isPartnerAllowedForChequeScanner(){
        const state = store.getState();
        const userProfile = state.auth.userProfile;
        if (userProfile != null) {
            const userFeature = (userProfile.features ?? []).find(f => f.Feature.featureName === 'enableAutoChequePayments');
            const enabledForUser = userFeature?.value === 'true';
            if (enabledForUser) {
                return true;
            }
            const partnerID = userProfile.partnerID;
            if (partnerID != null) {
                const client = await this.getAuthenticatedClient();
                const result = await client.managers.features.getFeatureForEntity('partnerID', partnerID, 'enableAutoChequePayments');
                if (result != null) {
                    return result.value === 'true';
                }
            }
        }
        return false;
    }


    identifySession(userAttributes){
         // full story is no longer in use
        if (userAttributes){
            if (userAttributes.sub && userAttributes.email){
                if (window.smartlook){

                    BllinkLogger.info(`set email manager in smartlook`, userAttributes.email);
                    window.smartlook('identify', userAttributes.email);
                    // window.FS.identify(userAttributes.sub, {
                    //     email: userAttributes.email
                    // });
                }

                // moved to getUserProfile
                // if (window.userpilot) {
                //     window.userpilot.identify( userAttributes.email, {email: userAttributes.email})
                // }
            }
        }

    }

    listenToPageChanged(){
        /*
            required since we are using a single page application instead of redirects

         */
        // skip in case of test env
        if (process.env.REACT_APP_STAGE === 'test') return;

        if (this.props && this.props.history){
            if (!this.props.history.listenerHasBeenSet) {
                BllinkLogger.info(`binding  history change to `, this.props.history);
                this.props.history.listen((location, action) => {
                    BllinkLogger.info(`History changed!`);
                    // Url.reportPageToGoogleAnalytics(location.pathname, action);
                });
                // to avoid multiple listeners related to https://app.asana.com/0/1196545425570329/1197557244117681 (at description)
                this.props.history.listenerHasBeenSet = true
            }
        }
    }

    setBodyClasses(exceptClasses){
        /*
            created since there are multiple background and we are using a SPA so we need to dynamically set these classes
            for every page
         */
        if (!Array.isArray(exceptClasses)){
            exceptClasses = [exceptClasses]
        }

        for (const optionValue of Object.values(constants.backgroundOptions)){
            if (exceptClasses.includes(optionValue)){
               document.body.classList.add(optionValue)
            }else{
                document.body.classList.remove(optionValue)
            }

        }

    }

    clearOutdatedCache(){
       CacheData.clearOutdatedCache()
    }

    handleChange(e) {
        BllinkLogger.info(`change:`, this.state)
        this.setState({temperature: e.target.value});
    }

    rerender(){
        BllinkLogger.info(`rerender from parent component`)
        this.setState(
            this.newState({})
        );
    }

    loadScript(scriptUrl, onScriptLoad){
        let s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = scriptUrl;
        let x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
        // Below is important.
        //We cannot access google.maps until it's finished loading
        s.addEventListener('load', e => {
            onScriptLoad();
        })
    }

    alertPopUp(title, text){
        // meant to replace native alert('')

        if (title){
            $('#generalModalLabel').text(title)
        }

        $('#general-pop-up-text').html(text)
        $('#general-pop-up').modal('show')
    }

    bindBackNextClicked(newTabToRedirectTo, isUrl, options){
        // move to another page without reload
        BllinkLogger.info(`bindBackNextClicked received `, newTabToRedirectTo);
        if (isUrl){
            // either back to tenant details or next to confirmation page
            // if it's manager and it's fot checkout page, then open the pop up
            if (newTabToRedirectTo?.includes(constants.pages.lastConfirmPaymentManagerPageUrl)){
                this.popUpManagerOfflinePayment(newTabToRedirectTo, options);
            }else {
                this.props.history.push({pathname: newTabToRedirectTo})
            }
        }else{
            this.setState({
                tabSelected: newTabToRedirectTo
            })
        }
    }

    popUpManagerOfflinePayment(newTabToRedirectTo, options){
        let cachedSelection = CacheData.fetchCache('PaymentMethodSelected', {buildingID: options.buildingID, apartmentNumber: options.apartmentNumber})
        cachedSelection = (cachedSelection && cachedSelection.selectedPaymentMethod);
        BllinkLogger.info(`cachedSelection`, cachedSelection)
        if (cachedSelection === constants.managerPaymentsOptions.creditCard){
            // should move forward as usual for tenant to the checkout page
            this.props.history.push(newTabToRedirectTo);
        }else if (cachedSelection === constants.managerPaymentsOptions.cheque || cachedSelection === constants.managerPaymentsOptions.autoCheque) {
            const event = new Event(`tabChangedTo_${cachedSelection}`);
            document.dispatchEvent(event)
            this.fillOfflinePaymentsPopUp(cachedSelection);
        }else {
            // todo if cheque is selected, then go to cheque tab with the amount
            GAEvent(constants.GoogleAnalytics.Events.offlinePayments, 'popupOpened', 1, cachedSelection);
            this.fillOfflinePaymentsPopUp(cachedSelection);
            $(`#offline-payments-${cachedSelection}`).modal('show');
        }

    }

    popUpOfflinePaymentClose(PaymentMethodSelected){
       $(`#offline-payments-${PaymentMethodSelected}`).modal('hide');
    }

    fillOfflinePaymentsPopUp(methodSelected){
        // fill in the popup with details before showing // total . if it's cash then

        const buildingID = $('#building-id').val();
        const apartmentNumber = $('#apartment-number').val();
        let options = {buildingID: buildingID, apartmentNumber: apartmentNumber};
        let totalToPay;
        BllinkLogger.info(`fillOfflinePaymentsPopUp cachedSelection is ${methodSelected} and options`, options)

        totalToPay = this.calcTotalToPayFromSelected(options);
        const totalToPayHtml = `${totalToPay.value} ${i18n.t(`currency.${totalToPay.currency}`)}`;
        $('.js-total-amount-label').html(totalToPayHtml);

        // todo if bank transfer hide hora'at keva checkbox unless just on going was selected
        this.hideRecurrentBankTransferIfNeeded(methodSelected, options);
    }

    hideRecurrentBankTransferIfNeeded(methodSelected, options){
        // https://app.asana.com/0/1196545425570329/1199600914315053
        // if bank transfer hide hora'at keva checkbox unless just on going was selected
        if (methodSelected === constants.managerPaymentsOptions.wire){
            if (!this.justOnGoingWasSelected(options)){
                $('#recurrent-bank-wire-checkbox').hide()
            }else{
                $('#recurrent-bank-wire-checkbox').show()
            }
        }
    }

    justOnGoingWasSelected(options){
        const buildingID = options.buildingID;
        const apartmentNumber = options.apartmentNumber;
        const maxDebtInstallmentsDontCare = 1;
        let oneTimeTotal = 0;
        let onGoing =  this.onGoingSettings(buildingID, apartmentNumber);
        let past = this.pastPaymentsSettings(buildingID, apartmentNumber, maxDebtInstallmentsDontCare);
        let oneTime = this.oneTimePaymentsSettings(buildingID, apartmentNumber);
        oneTime.forEach(o => {oneTimeTotal += o.total})
        BllinkLogger.info(`oneTimeTotal`,oneTimeTotal)
        return (
            onGoing.total > 0 &&
            past.total === 0 &&
            oneTimeTotal === 0
        )

    }

    isAllowedToEdit(managerRole){
        if (!managerRole) {return false}

        return constants.APIContract.managerRoles.editorRoles().includes(managerRole)
    }

    async fetchBuildingData(buildingID, options = {ignoreCache: false}) {
        let result;
        if (options.ignoreCache) {
            const response = await this.getBuildingFromServer(buildingID, options);
            result = response.building;
        } else {
            result = await CacheData.getOrCache(async () => {
                    const response = await this.getBuildingFromServer(buildingID, options);
                    return response.building;
                },
                'buildingData',
                {buildingID: buildingID}
            );
        }

        // NOTE: this is cached on HTTP-level
        const featuresResult = await this.client.tenants.buildings.getFeatures(buildingID);
        const features = (featuresResult?.featureValues ?? []).reduce((acc, f) => Object.assign(acc, { [f.Feature.featureName]: f.value }), {});
        const maintenanceLivy = features['maintenanceLivy'];

        if (result) {
            const payload = {
                id: buildingID,
                features,
                maintenanceLivy,
                address: result.address,
                managers: result.managers,
                allNumbers: this.getAllNumbers(result),
                apartments: this.getApartmentsWithId(result.apartments),
                updates: result.updates,
                numberOfInstallmentsForDebt: result.numberOfInstallmentsForDebt,
                tenantCommission: result.tenantCommission,
                assignedToNextMonth: result.assignedToNextMonth,
                currency: result.currency,
            };
            this.setState({
                building: payload
            });

            return payload;
        }
    }

    async getBuildingFromServer(buildingID, options){
        if (this.isDemoAccount(buildingID)){
            // load dynamically to exclude from common bundle
            const fileName = constants.getDemoBuildingFileName(buildingID);
            const module = await import(`../common/mocks/data/${fileName}`);
            const BuildingMockData = module.default;
            return BuildingMockData
        }

        // https://sentry.io/organizations/bllink/issues/2908073266/?environment=production&project=5402476&referrer=alert_email
        // need to find out why this is happening
        if (!buildingID) return {};

        let urlParams = options.urlParams || {};
        let result = await this.client.get(`/buildings/${buildingID}`, urlParams);
        BllinkLogger.info(`result from building server page `, result);

        return result || {};
    }

    getAllNumbers(result) {
        return result.apartments.map(item => item.apartmentNumber).sort(this.sortApartmentNumbers);
    }

    getApartmentsWithId(apartments) {
        return JsExtras.sortApartments(apartments)
            .map(item => ({
                label: this.props.t('reports.apartment_number', {apartmentNumber: item.apartmentNumber}),
                value: item.id,
                apartmentNumber: item.apartmentNumber
            }))
    }

    sortApartmentNumbers(a, b){
        // cases:
        // starts with text - חנות 1 - go to bottom
        // starts with number 1 א - ignore the letters and spaces
        // just numbers is the simple cases
        const A_startsWithANumber = (a.match(/^\d/))
        const B_startsWithANumber = (b.match(/^\d/))
        if (!A_startsWithANumber){
            return 1
        }
        if (!B_startsWithANumber){
            return -1
        }

        a = parseInt(BllinkUtils.Strings.keepNumbersOnly(a));
        b = parseInt(BllinkUtils.Strings.keepNumbersOnly(b));

        return a - b
    }


    async allBuildingsKnownVendors(buildingID){
        let allVendors = await this.client.get(`/vendors/all/${buildingID}`)
        if (!allVendors.length){
            allVendors.unshift({value: '1', label: i18n.t('expenses.choose_vendor')});
        }
        allVendors.push({value: constants.expenses.addNewVendor, label: i18n.t('expenses.add_vendor')})
        BllinkLogger.info(`allVendors`, allVendors)
        return allVendors
    }

    fetchBuildingDataFromCache(buildingID){
        const cacheKey = 'buildingData';
        return CacheData.fetchCache(cacheKey, {buildingID: buildingID});

    }

    fetchMaxDebtInstallmentsFromCache(buildingID){
        // this is problematic if the amount has changed
        // the building data has 2 hours cache

        const buildingData = this.fetchBuildingDataFromCache(buildingID);
        const hasDebtData = (buildingData && buildingData.numberOfInstallmentsForDebt)
        if (hasDebtData){
            BllinkLogger.info(`max debt from building cache is ${buildingData.numberOfInstallmentsForDebt}`)
            return parseInt(buildingData.numberOfInstallmentsForDebt);
        }else{
            BllinkLogger.info(`no debt data cached. returning 1 as default`)
            return 1
        }
    }

    async fetchManagerBuildingData(buildingID, urlParams){
        // didn't find a better way to do this
        if (this.isDemoAccount(buildingID)){
            // load dynamically to exclude from common bundle
            const fileName = constants.getDemoBuildingMockDataFileName(buildingID);
            const module = await import(`../common/mocks/demoAccount/${fileName}`);
            const ManagerBuildingMockData = module.default;
            return ManagerBuildingMockData.building
        }

        const result = await this.client.get(`/managers/buildings/${buildingID}`, urlParams);
        //todo cache this
        BllinkLogger.info(` manager's building data for user are `, result);
        return result.building

    }

    isDemoAccount(buildingID){
        /*
            hate doing hacks like this but couldn't find a better option
            https://app.asana.com/0/1123966772501425/1199701537611853
         */
        const ids = Object.values(constants.countriesDemoBuildings).flat();
        return (ids.includes(buildingID));
    }

    getSelectMonthForDropdown(props){
        const {t} = props;

        const year = parseInt(props.match.params.year);
        let monthsTexts = [];
        let years = [year-1, year, year + 1];
        years.forEach((year) => {
            monthsTexts = monthsTexts.concat(Object.keys(constants.MonthsToText).map(monthInt=>  {
                    return {
                        value: `${monthInt}_${year}`, label: t(`common.months.${monthInt}`) + ' ' + year}
                })
            )
        })

        return monthsTexts;
    }

    sortApartmentNumbersForTable(a, b){
        const is_A_NegativeAmount = parseInt(a.Amount) < 0 ;
        const is_B_NegativeAmount = parseInt(b.Amount) < 0 ;
        // negative should be first
        if (is_A_NegativeAmount) {
            return -1
        }else if (is_B_NegativeAmount){
            return 1
        }else{
            return parseInt(a.ApartmentNumber) <  parseInt(b.ApartmentNumber) ? -1 : 1;
        }
    }

    async fetchApartmentPaymentData(buildingID, apartmentNumber, isManager, ownership, phone){
        if (isDemoApartment(buildingID, apartmentNumber)){
            return await getFakePaymentData(buildingID)
        }
        console.log('fetchApartmentPaymentData');

        const cacheKey = 'apartmentPayments';
        const options = { buildingID, apartmentNumber };
        let search = this.props.location.search.substring(1);
        let params = new URLSearchParams(search);
        params = !isManager ? {...params, phone: phone?.slice(-7)}: params;
        const url = `/tenants/payments/${buildingID}/${apartmentNumber}/${isManager ? '' : (ownership ?? '')}`;
        const payments = await this.client.get(url, params);
        BllinkLogger.info(`result from payments server page `, payments); // todo validate result before sending
        CacheData.cache(cacheKey, payments, options);

        return payments
    }

    async fetchAllBuildings(){

        // small race condition bug in this case since all building is the first page after login
        // so we need to wait for the getAuthenticatedClient
        const authenticatedClient = await this.getAuthenticatedClient();
        if (!authenticatedClient){
           window.location.href = `/login`
           return []
           //  should never get here since verifyLoggedIn was called before
           // https://sentry.io/organizations/bllink/issues/2070149147/?project=5424386 error in get all buildings
           // throw new Error(`error in get all buildings`);
        }
        const result = await authenticatedClient.get('/managers/buildings');
        //cache this?
        BllinkLogger.info(`all buildings for user are `, result);
        return result
    }

    async fetchPaymentByMonths(buildingID, year){
        /*

         */
        const result = await this.client.get(`/managers/payments/${buildingID}`, {year: year})
        // todo cache this
        BllinkLogger.info(`all buildings payments by months `, result);
        return result

    }

    async fetchYearlyCashFlow(buildingID, year){
        /*

         */
        const result = await this.client.get(`/managers/cashflow-building/${buildingID}`, {year: year})
        // todo cache this
        BllinkLogger.info(`all buildings payments by yearly cashflow  `, result);
        return result

    }

    async fetchMonthlyCashFlow(buildingID, year, month){
        /*

         */
        const result = await this.client.get(`/managers/cashflow-building/${buildingID}`, {year: year, month: month, skipDisabledCheck: true})
        // todo cache this
        BllinkLogger.info(`all buildings payments by monthly cashflow  `, result);
        return result

    }

    async fetchTenantList(buildingID){
        /*

         */
        const result = await this.fetchBuildingData(buildingID);

        return result

    }

    async fetchApartmentCashFlow(buildingID, apartmentNumber){
        /*

         */
        const result = await this.client.get(`/managers/cashflow/${buildingID}/${apartmentNumber}`)
        // todo cache this
        BllinkLogger.info(`all buildings payments by apartment cashflow  `, result);
        return result

    }

    async afterLoadForCart(state, props){
        BllinkLogger.info(`status props `,state);

        const orderStatus = await this.fetchOrderStatus(state.cartID)
        if (orderStatus != null) {
            const buildingID = orderStatus.buildingID;
            props.isStatusPage && this.#handleLanguage(orderStatus.currency);
            const buildingData = await this.fetchBuildingData(buildingID, {urlParams: {skipDisabledCheck: true}});
            if (buildingData != null) {
                this.setState(this.newStatusForCart({
                    cartID: props.cartID || props.match.params.cartID,
                    hideUnsuccessful: props.hideUnsuccessful,
                    address: buildingData.address,
                    fetchedFromBackend: true,
                    ...orderStatus,
                }));
            }
        }
    }

    async fetchOrderStatus(cartID){
        const response = await this.client.tenants.payments.getOrderStatus(cartID);
        BllinkLogger.info(`status response `, response);
        return response
    }

    bindLogExternalPaymentClicked(){
        const thisObj = this;

        $('.js-log-payments-btn').unbind('click').bind('click', async (e)=> {
            e.preventDefault();
            const targetButton = $(e.target)
            const isCurrentlyDisabled = (targetButton.hasClass('bllink-button-disabled'));
            const paymentMethodServerKey = targetButton.attr('arie-type');
            if (isCurrentlyDisabled){
                BllinkLogger.info(`isCurrentlyDisabled true`)
                return
            }

            const validResult = $(e.target).parents('.js-offline-payment-form').valid(); // is form valid?

            if (validResult) {
                const payload = thisObj.buildOfflineCreatePaymentPayload(e, thisObj);
                BllinkLogger.info(`payload offlinePayments `, payload);
                if (payload) {
                    await this.createAnOfflinePayment(thisObj, payload, e, paymentMethodServerKey);
                }
            }
        })
    }

    async createAnOfflinePayment(thisObj, payload, e, paymentMethodServerKey){
        thisObj.startLoader();
        thisObj.disableButton(e.target);
        const result = await offlinePaymentsLogic.createOfflinePaymentPlan(thisObj.client, payload);
        thisObj.endLoader();
        thisObj.enableButton(e.target);

        GAEvent(constants.GoogleAnalytics.Events.offlinePayments,
            'successAddPayment',
            payload.totalToPay,
            paymentMethodServerKey);

        const cartID = result.cartID;
        if (cartID) {
            triggerRewardsNotification();
            // redirect to confirmation page. history push doesn't work
            window.location.href = `/${constants.pages.managerConfirmation}/${cartID}`;
        }
    }

    async uploadPaymentSettingsFile(year, buildingID, jsonData){
        this.startLoader();
        const result = await this.uploadFile(year, buildingID, jsonData);
        this.endLoader();
        this.handleUploadResult(result, buildingID, year);
    }

    async uploadFile(year, buildingID, jsonData){
        let payload = {'tenantPaymentsJson': jsonData}
        return await this.client.post(`/managers/payments/${buildingID}/${year}`, payload);
    }

    handleUploadResult(result, buildingID, year){

        const isSuccess  = result && result.updatedNumber > 0
        const isSuccessMonthly  = isSuccess && !result.oneTimeResult
        const noRowsWereUpdated  = result && result.updatedNumber === 0
        const isSuccessOneTime = isSuccess && result.oneTimeResult
        const oneTimeSettingsID = isSuccessOneTime ?  result.oneTimeResult.buildingOneTimeSettingsID : null;
        const wasUpdated = result && result.wasUpdated;

        this.reportUploadToAnalytics(buildingID, isSuccess, isSuccessMonthly, wasUpdated);

        if (isSuccessMonthly) {
            alert(i18n.t('payment_settings.uploadedMonthlySuccessfully', {year: year}))
            window.location.href = `/${constants.pages.monthlyPaymentSettings}/${buildingID}/${year}`
        }else if (isSuccessOneTime){
            alert(i18n.t('payment_settings.uploadedOneTimeSuccessfully'))
            window.location.href = `/${constants.pages.oneTimePaymentSettings}/${buildingID}/${oneTimeSettingsID}`
        } else if (noRowsWereUpdated){
            // warning
            alert(i18n.t('payment_settings.uploadedMonthlyNoUpdatedRows'))
        } else{
            // error
            alert(i18n.t('payment_settings.uploadedMonthlyError'))
        }
    }

    reportUploadToAnalytics(buildingID, isSuccess, isSuccessMonthly, wasUpdated){
        // will be used to send an email to

        let eventName = isSuccess ? 'uploadSuccess': 'uploadFailed';
        eventName = isSuccessMonthly ? eventName + 'Monthly': eventName + 'OneTime';
        BllinkLogger.info(`buildingID ${buildingID} got isSuccess ${isSuccess} 
            isSuccessMonthly ${isSuccessMonthly} 
            and wasUpdated ${wasUpdated}. 
            event Name is ${eventName}`);

        GAEvent(constants.GoogleAnalytics.Events.paymentSettings, eventName, 1, buildingID);

        if (wasUpdated){
            GAEvent(constants.GoogleAnalytics.Events.paymentSettings, eventName + 'WasUpdated', 1, buildingID);
        }

    }

    disableButton(btnElement){
        $(btnElement).addClass('bllink-button-disabled')
    }

    enableButton(btnElement){
        $(btnElement).removeClass('bllink-button-disabled')
    }

    buildOfflineCreatePaymentPayload(e, thisObj){
        const buildingID = $('#building-id').val();
        const apartmentNumber = $('#apartment-number').val();
        const targetButton = $(e.target);
        const paymentMethodServerKey = targetButton.attr('arie-type');
        let options = {buildingID: buildingID,
            apartmentNumber: apartmentNumber,
            paymentMethod: paymentMethodServerKey};

        const payingFor = new LastConfirmationPageLogic(options).buildingPaymentPayload({ignoreMissingInstallments: true});
        const { tenant_details, on_going_payments, one_time_payments, past_payments } = payingFor;
        if (!tenant_details || (!on_going_payments && !one_time_payments && !past_payments)) {
            const {t} = thisObj.props;

            return this.renderHiddenPopUp({
                title: t('payments.cart_empty_title'),
                id: 'cartIsEmpty',
                textLine1: t('payments.cart_empty_text'),
            })
        };
        const result = thisObj.calculateOffLineServerPayload(options, payingFor, targetButton);
        if (result && result.payments) {
            let payload = {
                paying_for: payingFor,
                tenant_details: payingFor.tenant_details,
                buildingID: buildingID,
                apartmentNumber: apartmentNumber,
                totalToPay: result.totalToPay
            }

            ///wires/cheques/cash
            payload[paymentMethodServerKey] = result.payments;

            return payload
        }
    }

    calcTotalToPayFromSelected(options){
        const buildingID = options.buildingID;
        const apartmentNumber = options.apartmentNumber;
        const maxDebtInstallmentsDontCare = 12; //fake number for this calculation
        const nextMonthsPaymentsData = LastConfirmationPageLogic.calcNextMonthsPayments({
            onGoing: this.onGoingSettings(buildingID, apartmentNumber),
            past: this.pastPaymentsSettings(buildingID, apartmentNumber, maxDebtInstallmentsDontCare),
            oneTime: this.oneTimePaymentsSettings(buildingID, apartmentNumber),
            options: {commission: 0} // offline payments it's always zero for now
        });

        return nextMonthsPaymentsData.total;
    }

    renderMonthsComment(months){
        if ($.type(months) === 'array') {
            return this.shortenText(this.calcMonthsTextForSelectBox(months));
        }
    }

    renderOneTimeComment(oneTime){
        // returns string
        if (oneTime){
            return this.shortenText(oneTime.description);
        }

    }

    renderPartPayAmount(value, props){
        const {t} = props;
        let result = props.partPay || value.part_pay || value.part_pay;

        if (result && result.currency) {
            let timeOrOneTimeIndex = result.monthIndex || result['time-index'];
            let fullAmount = result.fullMonthSum || result['full-amount'];
            let existingElement = $(`.js-part-pay.js-dynamic-add[time-index="${timeOrOneTimeIndex}"]`);
            let alreadyHasPartPaySpan = (existingElement.length > 0);
            if (!alreadyHasPartPaySpan) {
                return (<span className="js-amount part-amount js-part-pay is-render break"
                              currency={`${result.currency}`}
                              value={`${result.value}`}
                              time-index={timeOrOneTimeIndex}
                              full-amount={`${fullAmount}`}
                >
                {result.value} <Currency>{result.currency}</Currency>
                </span>)
            }
        }
    }

    handleConfirmPartPay(thisObj, typeOfCaller){
        //gather input
        let result = thisObj.gatherPartPayInput(thisObj, typeOfCaller);
        if (!result){
            return
        }

        // actual work
        thisObj.fillDataInDom(thisObj, result);
    }


    bindPopUpClicks(thisObj, typeOfCaller){
        $('#cancelation').unbind('click').bind('click', function (_){
            $(`#offline-payments-part-pay`).modal('hide');
        })

        $('#popup_submit').unbind('click').bind('click', function (_){
            thisObj.handleConfirmPartPay(thisObj, typeOfCaller)
        })
    }

    addPartPaySpan(thisObj, parent, result, alreadyHasPartPaySpan, partSumIsFullAmount){
        const {t} = thisObj.props;
        let statusClass = alreadyHasPartPaySpan ? 'hidden': '';
        let isFullAmountClass = partSumIsFullAmount ? 'js-full-amount' : ''; // to remove it in case of full amount

        // hidden since it's just to setup the model
        parent.prepend(`<span class="js-amount part-amount js-dynamic-add js-part-pay break ${statusClass} ${isFullAmountClass}"
                    currency="${result.currency}" 
                    value="${result.newSum}"
                    time-index="${result.oneTimeIndex}"
                    full-amount="${result.fullMonthSum}"
                    >
                        ${result.newSum} ${t(`currency.${result.currency}`)}
                    </span>`);
        parent.addClass('label_part_pay');
    }

    fillDataInDom(thisObj, result){

        let parent = result.parent;
        let timeOrOneTimeIndex = result.monthIndex || result.oneTimeIndex;
        let existingElement = $(`.js-part-pay[time-index="${timeOrOneTimeIndex}"]`);
        let alreadyHasPartPaySpan = (existingElement.length > 0);
        let partSumIsFullAmount = (result.newSum === result.fullMonthSum);

        existingElement.remove();
        parent.removeClass('label_part_pay');

        thisObj.addPartPaySpan(thisObj, parent, result, alreadyHasPartPaySpan, partSumIsFullAmount);

        $(`#offline-payments-part-pay`).modal('hide');
        // trigger the ongoing/debt on change
        // e.target should be the relevant input element
        // to match the regular onChangeFunc callback expectation
        setTimeout(()=> {
            thisObj.props.onChangeFunc({target: null});
        }, 500)
    }


    renderApartmentLink(apartmentNumber){
        const buildingID = this.props.buildingID;
        // removing keep numbers only due to a bug where the apartment Number is built from string as well
        // https://app.asana.com/0/1196545425570329/1200472830993096
        // let HousingPaymentPlanApartmentNumber = this.StringUtils.keepNumbersOnly(apartmentNumber);
        // example to payment id 115266 where the apartment number is a string
        let HousingPaymentPlanApartmentNumber = apartmentNumber;
        return <a href={`/${constants.pages.newApartmentReport}/${buildingID}/${HousingPaymentPlanApartmentNumber}`}>{apartmentNumber}</a>
    }

    tableTitle(year, totalAmount, month, params, type){
        const {t} = this.props;
        month = month || params.monthInt;
        params = params || {};
        let isExpenses = type === 'expenses';
        let isCheque = type === 'cheque';
        let isFutureMonth = !this.timeUtils.isSmallerEqual({year: year, month: month}, this.timeUtils.getTimeNow());
        const monthName = t(`common.months.${parseInt(month)}`);
        const total = params.totalAmount ? params.totalAmount.value : (totalAmount ? totalAmount.value : null);
        const currencyCode = params.totalAmount ? params.totalAmount.currency : (totalAmount ? totalAmount.currency : null);
        let monthTotal = this.Money.roundNumber(total);
        monthTotal = this.StringUtils.numberWithCommas(monthTotal);
        const amountData = {
            amount: monthTotal,
            currency: currencyCode ? t(`currency.${currencyCode}`) : '',
        }
        let titleTextKey
        if (isCheque){
            titleTextKey = 'reports.cheques_title'
        }else {
            titleTextKey = isExpenses ? 'expenses.expense_monthly_report' : 'reports.monthly_cashflow_title';
            if (isFutureMonth) {
                titleTextKey = isExpenses ? 'expenses.expense_future_monthly_report' : 'reports.monthly_future_cashflow_title'
            }
        }

        return i18n.t(titleTextKey, {monthName: monthName, year: year, ...amountData, interpolation: {escapeValue: true}})

    }

    async allExpensesTypes(){
        const allExpensesTypes = await this.getOrCache(async ()=>{
                return await this.client.managers.expenses.getTypes() ?? [] },
            'expenseTypes'
        )
        BllinkLogger.info(`allExpensesTypes`, allExpensesTypes)
        let result = [];
        for (const expenseType of allExpensesTypes) {
            result.push({value: expenseType.id, label: i18n.t(`expenses.expenseTypes.${expenseType.expenseType}`)})
        }
        return result
    }

    gatherPartPayInput(thisObj, typeOfCaller){

        const {t} = thisObj.props;
        let parentClass = typeOfCaller === 'oneTime' ? '.label_container_select'  : '.label_container';
        let relevantItem = typeOfCaller === 'oneTime' ? 'p' : 'input';
        let newSum = parseFloat($('.js-new-sum').val());
        let oneTimeIndex = $('#js-time-index').val();
        let oneTimeElement = $(`[time-index=${oneTimeIndex}]`);
        if (oneTimeElement.length === 0){
            throw new Error(`failed to find oneTimeIndex ${oneTimeIndex} length is ${$('#js-time-index').length}`)
        }
        let currency = oneTimeElement.attr('currency');
        let parent = oneTimeElement.parents(parentClass)
        let inputElement = $(parent).find(relevantItem)[0];
        let fullMonthSum = parseFloat($(inputElement).attr('full-amount') || $(inputElement).attr('amount'));

        // validations
        if (fullMonthSum < newSum){
            alert(t('offline_payments.partial_sum_must_be_smaller', {fullAmount: fullMonthSum}));
            return
        }

        return {
            currency: currency,
            parent: parent,
            inputElement: inputElement,
            newSum: newSum,
            fullMonthSum: fullMonthSum,
            oneTimeIndex: oneTimeIndex
        }
    }

    handlePartPayDoubleClicked(e){
        // get month clicked
        let timeOrOneTimIndex = $(e.target).attr('time-index')
        let fullAmount = $(e.target).attr('full-amount')
        if (!timeOrOneTimIndex){
            throw new Error(`failed to init part pay pop up. didnt find the clicked on time-index ${e.target}`)
        }

        if (!fullAmount){
            throw new Error(`failed to init part pay pop up. didnt find the clicked on fullAmount ${e.target}`)
        }
        $('#js-time-index').val(timeOrOneTimIndex);
        $('#js-full-amount').val(fullAmount);
        $('.js-new-sum').val('');
        $(`#offline-payments-part-pay`).modal('show');
        GAEvent(constants.GoogleAnalytics.Events.popUps, 'popUpRender', 1, 'PartPay');
    }

    calcMonthsTextForSelectBox(selectedMonths){
        /*
            calcMonthsText - array
         */
        // if consecutive, render the range
        // if not render al months with space between each one
        BllinkLogger.info(`selectedMonths `, selectedMonths);

        const timeHelper = this.BllinkUtils.BllinkTime;

        selectedMonths.sort();
        selectedMonths = timeHelper.sortMonths(selectedMonths)
        const isConsecutive = timeHelper.isConsecutive(selectedMonths)
        BllinkLogger.info(`isConsecutive result ${isConsecutive}`)

        if (isConsecutive){
            const firstMonth = timeHelper.renderShortDate(selectedMonths[0])
            const lastMonth = timeHelper.renderShortDate(selectedMonths.slice(-1)[0])
            return `${firstMonth} - ${lastMonth}`
        }else{
            let result = ''
            for (const month of selectedMonths){
                result += timeHelper.renderShortDate(month) + ' ';
            }

            return result
        }
    }

    calculateOffLineServerPayload(options, payingFor, targetButton){

        const totalToPay = this.calcTotalToPayFromSelected(options);
        const result = this.getDataFromOffLineInput(totalToPay, payingFor, options, targetButton);

        let payments = [];
        const hasAmounts = result.paymentAmounts.length > 0;
        for (let i=1; i <= result.numberOfInstallments; i++){
            let amount = hasAmounts ? result.paymentAmounts[i-1] : totalToPay.value/result.numberOfInstallments;
            amount = BllinkUtils.Money.roundNumber(amount)
            payments.push({
                "amount": {
                    "value": amount,
                    "currency": totalToPay.currency
                },
                "extraDetails": result.extraDetails ? result.extraDetails[i-1]: null,
                // todo make sure it's not null default to now to prevent this error https://bllink.sentry.io/issues/4095869254/?alert_rule_id=1438500&alert_timestamp=1681663370901&alert_type=email&environment=production&project=5402476&referrer=alert_email
                // QA well after the change with cheques/wires, one installment/more than one. check the ChargeDate and PaymentDate
                "dueDate": result.dates[i-1],
                "paymentMethod": options.paymentMethod
            })

        }
        const isValid = this.validateAmounts(payments, totalToPay);
        if (isValid){
            return {
                payments: payments,
                totalToPay: totalToPay.value
            }
        }
    }

    validateAmounts(payments, totalToPay){
        const totalPaymentsSent = BllinkUtils.Money.roundNumber(this.JsExtras.sum(payments, 'amount.value') || 0);
        const totalPaymentsShouldHaveBeenSent = BllinkUtils.Money.roundNumber(totalToPay.value);

        if (totalPaymentsSent !== totalPaymentsShouldHaveBeenSent){
            $('#amountsSentOfflinePaymentsAreWrong .js-txt-line-1')
                .html(i18n.t('payments.offline_payment_amounts_are_wrong_text',
                    {
                        amountsSent: this.StringUtils.numberWithCommas(totalPaymentsSent),
                        totalToPay: this.StringUtils.numberWithCommas(totalPaymentsShouldHaveBeenSent)
                    }));
            // should only append once
            $('#amountsSentOfflinePaymentsAreWrong').modal('show');
            GAEvent(constants.GoogleAnalytics.Events.popUps, 'popUpRender', 1, 'amountsSentOfflinePaymentsAreWrong');
            $('#offline-payments-cheque').modal('hide');
            return false
        }

        return true
    }

    getDataFromOffLineInput(totalToPay, payingFor, options, targetButton){
        /*
         */
        // number of installments and extra details if applied
        // relevant for wire and cheques only
        const modalDiv = targetButton.parents('.modal-body');
        const bankDetails = {
            "bankCode": modalDiv.find('.js-bank-code select option:selected').val(),
            "branchNumber":  modalDiv.find('.js-branch').val(),
            "accountNumber":  modalDiv.find('.js-account-number').val()
        }

        let date = $(`#datepicker-${options.paymentMethod}`).val();
        date = BllinkTime.parseIsraeliDateToDate(date);
        const dateObj = new Date(date);
        let dates = [dateObj];

        let result = {
            numberOfInstallments: 1
        };


        if (options.paymentMethod === constants.managerPaymentsOptions.wireServerKey){
            result = this.getWireData(totalToPay, dates, payingFor, bankDetails);

        }else if (options.paymentMethod === constants.managerPaymentsOptions.cashServerKey){
            BllinkLogger.info(`no action required for cash`)

        }else if (options.paymentMethod === constants.managerPaymentsOptions.chequeServerKey){
            result = this.getChequesData(bankDetails);
        }

        return {
            numberOfInstallments: result.numberOfInstallments,
            extraDetails: result.extraDetails,
            dates: result.dates || dates,
            paymentAmounts: result.paymentAmounts || []
        }

    }

    getChequesData(bankDetails){
        let numberOfInstallments = 0
        let extraDetails = []
        let dates = []
        let paymentAmounts = []
        $('tr.js-cheque-row').each(function(){
            numberOfInstallments += 1;

            extraDetails.push({
                ...bankDetails,
                chequeID: $(this).find('.js-cheque-id').val(),
            })

            let date = $(this).find('.js-datepicker').val();
            BllinkLogger.info(`cheque date is `, date)
            date = BllinkTime.parseIsraeliDateToDate(date);
            const dateObj = new Date(date);
            dates.push(dateObj)
            paymentAmounts.push(parseFloat($(this).find('.js-cheque-amount').val()));

        })


        return {
            numberOfInstallments: numberOfInstallments,
            extraDetails: extraDetails,
            dates: dates,
            paymentAmounts: paymentAmounts
        }
    }

    getWireData(totalToPay, dates, payingFor, bankDetails){


        let numberOfInstallments;
        // if hora'at keva is marked then spilt the payments
        const isRecurrentPayment = ($('#recurrent-bank-wire').is(':checked'));
        let extraDetails = [bankDetails];
        let paymentAmounts;

        if (isRecurrentPayment){
            // get from the number of on ongoing months selected
            numberOfInstallments = this.determineNumberOfInstallmentsOfHoraatKeva(payingFor);
            let currentDate = new Date(dates[0]);
            extraDetails = [{...bankDetails}];
            // add paymentAmounts due to a bug in horaa'at keva 2300/12 = 199.66666
            // https://sentry.io/organizations/bllink/issues/2233242033/?project=5402476&query=%22external-payments%22&statsPeriod=14d
            // https://app.asana.com/0/1123966772501425/1200466085245908
            paymentAmounts = this.Money.calcPlanByAveragePayment(totalToPay.value, numberOfInstallments);
            for (let i=1; i<= numberOfInstallments-1; i++){
                dates.push(new Date(currentDate.setMonth(currentDate.getMonth()+1)));
                extraDetails.push({...bankDetails})
            }

        }else{
            numberOfInstallments = 1
        }

        return {
            numberOfInstallments: numberOfInstallments,
            dates: dates,
            extraDetails: extraDetails,
            paymentAmounts: paymentAmounts
        }
    }

    determineNumberOfInstallmentsOfHoraatKeva(payingFor){
        // usually recurrent wires are for on going
        if (payingFor.on_going_payments){
            // discussion with rayee. if a tenant pays with recurrent wire,
            // it's coupled the number of months
            const instalments = payingFor.on_going_payments.number_of_months_forward || payingFor.on_going_payments.months.length;
            return instalments || 1
        }

        if (payingFor.past_payments){
            const instalments = payingFor.past_payments.number_of_months_forward || payingFor.past_payments.months.length;
            return instalments || 1
        }

        // one time payments will be considered as one time

        return 1

    }

    buildingTitle(address, apartmentNumber, typeOfWelcome){
        BllinkLogger.info(`buildingTitle`, this.props, address, apartmentNumber)
        const {t} = this.props.parentProps || this.props;

        let welcomeKey = {
            'welcome': 'welcome_to',
            'paymentTo': 'payment_to',
            'expenseRecord': 'record_expenses',
            'expenseEdit': 'expense_edit',
            'expensesBalance': 'balance_title'
        }[typeOfWelcome];

        if (!address){
            return {
                welcomeKey: welcomeKey,
                translationKey: 'empty'
            }
        }else{

            const apartmentText = apartmentNumber ? t('payments.apartmentText', {apartmentNumber: apartmentNumber}) : '';
            const hasEntrance = address.entrance;
            return {
                city: address.city,
                street: address.nickname || address.street,
                streetNumber: address.nickname ? '': address.streetNumber,
                entrance: address.entrance,
                apartmentText: apartmentText,
                welcomeKey:  welcomeKey,
                translationKey:  hasEntrance ? 'address_with_entrance' : 'address_without_entrance'
            }
        }
    }

    async getCurrentBuildingTitle() {
        const options = {typeOfCache: sessionStorage};
        const cacheKey = 'currentBuilding';
        const currentBuildingID = CacheData.fetchCache(cacheKey, options);
        if (currentBuildingID) {
            return await this.buildingTitleText(currentBuildingID);
        }
    }

    async addHiddenBuildingTitle(){
        /*
            will be used when printing the table
         */
        let title = await this.getCurrentBuildingTitle();
        BllinkLogger.info(`addHiddenBuildingTitle  title `, title)
        if (title) {
            setTimeout(()=> {
                $('.js-building-print-title').text(title);
            }, 200)
        }
    }

    async buildingTitleText(buildingID){

        const data = await this.fetchBuildingData(buildingID, {urlParams: {skipDisabledCheck: true}});
        if (!data) return '';
        const titleData = this.buildingTitle(data.address)

        return i18n.t('payments.' + titleData.translationKey, titleData);
    }

    onGoingSettings(buildingID, apartmentNumber, isViewedAsManager){
        /*/
           get cached ongoing and calc installments settings
            *** fetching data here instead of in installment select to
              make sure to pass the same number of installments to installments boxes and
              calcNextMonthsPayments - https://app.asana.com/0/1196545425570329/1198172719635833
        */

        const shouldIgnoreReward = this.shouldIgnoreReward(buildingID, apartmentNumber);


        const onGoingMonths = PaymentsLogic.getOngoingPayments(buildingID, apartmentNumber);
        const totalAmount = MonthsRelatedLogic.calcTotalAmountSelected(onGoingMonths, shouldIgnoreReward);
        const totalReward = shouldIgnoreReward ? 0 : MonthsRelatedLogic.calcTotalReward(onGoingMonths);
        const maxByMonths = MonthsRelatedLogic.calcMaxOnGoingInstallmentsAllowed(onGoingMonths);
        const maxInstallments = isViewedAsManager ? Math.max(constants.FINAL_MONTH_IN_YEAR, maxByMonths) : maxByMonths;
        const data = CacheData.fetchCache('installmentsChoices', {key: constants.cache.keys.payments.onGoingPayments}) || {};
        const chargeDateOptions = CacheDate.fetchCache('onGoingChargeDate', {buildingID: buildingID, apartmentNumber: apartmentNumber});
        const numberOfInstallments = data.selected || 1;


        return {
            total: totalAmount,
            totalReward: totalReward,
            maxInstallments: maxInstallments,
            numberOfInstallments: numberOfInstallments,
            selectedMonths: onGoingMonths.filter(x => { return x.selected}),
            chargeDateOptions
        }
    }

    shouldIgnoreReward(buildingID, apartmentNumber){
        // https://app.asana.com/0/1200654622299830/1200855539606171
        // [bug] don't consider discount on offline payments in the frontend
        let PaymentMethodSelected = CacheData.fetchCache('PaymentMethodSelected', {buildingID: buildingID, apartmentNumber: apartmentNumber})
        // https://app.asana.com/0/1200654622299830/1200855539606171 [bug]
        // don't consider discount on offline payments in the frontend
        if (PaymentMethodSelected && PaymentMethodSelected.shouldNotRenderInstallmentsSelect){
            this.hideReward();
            return true
        }
    }

    hideReward() {
        BllinkLogger.info(`should hide rewards`)
        GAEvent(constants.GoogleAnalytics.Events.rewards, 'rewards', 1, 'hide');
        $('#rewards-text').addClass('hidden');
        let normalFullPrice = $('#reward-striked').parents('del');
        normalFullPrice.replaceWith(normalFullPrice.html());
        let rewardDiscountedPrice = $('#reward-amount');
        rewardDiscountedPrice.wrap("<del></del>");
    }

    showReward() {
        BllinkLogger.info(`should show rewards`)
        GAEvent(constants.GoogleAnalytics.Events.rewards, 'rewards', 1, 'show');
        $('#rewards-text').removeClass('hidden');
        let normalFullPrice = $('#reward-striked');
        normalFullPrice.wrap( "<del></del>" );
        let rewardDiscountedPrice = $('#reward-amount').parents('del');
        rewardDiscountedPrice.replaceWith(rewardDiscountedPrice.html());
    }

    pastPaymentsSettings(buildingID, apartmentNumber, maxDebtInstallments, isViewedByManager){
        /*/
          get cached pastPayments and calc installments settings

          *** fetching data here instead of in installment select to
              make sure to pass the same number of installments to installments boxes and
              calcNextMonthsPayments - https://app.asana.com/0/1196545425570329/1198172719635833
         */

        const pastPaymentsMonths = PaymentsLogic.getDebtPayments(buildingID, apartmentNumber);
        const totalAmountSelected = MonthsRelatedLogic.calcTotalAmountSelected(pastPaymentsMonths);
        const data = CacheData.fetchCache('installmentsChoices', {key: constants.cache.keys.payments.pastPayments}) || {}
        const numberOfInstallments = data.selected || 1;
        // https://app.asana.com/0/1196545425570329/1200004480349789 allow 12 installments for debt for manager when logged in
        maxDebtInstallments =  isViewedByManager ? 12 : maxDebtInstallments;

        return {
            total: totalAmountSelected,
            maxInstallments: maxDebtInstallments,
            numberOfInstallments: numberOfInstallments,
            selectedMonths: pastPaymentsMonths.filter(x => { return x.selected})
        }
    }

    oneTimePaymentsSettings(buildingID, apartmentNumber){
        /*/
           get cached pastPayments and calc installments settings
         */

        const oneTimePayments = PaymentsLogic.getOneTimePayments(buildingID, apartmentNumber);

        BllinkLogger.info(`oneTimePaymentsMonths cached `, oneTimePayments);
        let result = []
        for (const payment of oneTimePayments){
            if (payment.selected){
                result.push(this.oneTimeSinglePaymentSettings(payment))
            }
        }

        return result
    }

    oneTimeSinglePaymentSettings(payment){

        // fetching data here instead of in installment select to
        // make sure to pass the same number of installments to installments boxes and
        // calcNextMonthsPayments - https://app.asana.com/0/1196545425570329/1198172719635833

        const name = constants.cache.keys.payments.oneTimeInstallmentsName(
            payment.building_one_time_payment_id
        );
        const data = CacheData.fetchCache('installmentsChoices', {key: name}) || {}
        const numberOfInstallments = data.selected || 1;

        return {
            title: payment.payment_description,
            sub_title: payment.payment_long_description,
            total: payment.part_pay ? parseFloat(payment.part_pay.value) : payment.amount_left.value,
            currency: payment.amount_left.currency,
            building_one_time_payment_id: payment.building_one_time_payment_id,
            maxInstallments: payment.max_installments,
            numberOfInstallments: numberOfInstallments
        }
    }

    validateDetails(buildingID, apartmentNumber, params){
       /*
            will be used to block a user to go to the next page if no items were selected

        */

        const maxDebtInstallments = 1; //  fake number just to get the items
        params = params || {};

        const data = {
            onGoingInstallments: this.onGoingSettings(buildingID, apartmentNumber),
            pastPaymentsInstallments: this.pastPaymentsSettings(buildingID, apartmentNumber, maxDebtInstallments),
            oneTimePaymentsInstallments: this.oneTimePaymentsSettings(buildingID, apartmentNumber),
            tenantDetails: CacheData.fetchCache('tenantDetails')
        }

        BllinkLogger.info(`data for validateDetails`, data)

        if (!this.cartHasItems(data)){
            this.alertTheUserForEmptyCart()
            return false
        }

        if (!this.validateTenantDetails(data)){
            this.alertTheUserForTenantDetails()
            return false
        }

        if (!this.isValidPaymentMethodSelected(buildingID, apartmentNumber, data, params)){
            this.alertCantPayWithCreditCard()
            return false
        }

        return true

    }

    alertTheUserForEmptyCart(){
        $('#cartIsEmpty').appendTo('body').modal('show');
        GAEvent(constants.GoogleAnalytics.Events.popUps, 'popUpRender', 1, 'cartIsEmpty');
    }

    alertTheUserForTenantDetails(){
        $('#tenantDetailsMissing').appendTo('body').modal('show');
        GAEvent(constants.GoogleAnalytics.Events.popUps, 'popUpRender', 1, 'tenantDetailsMissing');
    }

    alertCantPayWithCreditCard(){
        $('#creditCardMethodError').appendTo('body').modal('show');
        GAEvent(constants.GoogleAnalytics.Events.popUps, 'popUpRender', 1, 'creditCardMethodError');
    }

    validateTenantDetails(data){
        return (
            data.tenantDetails
        )
    }

    cartHasItems(data){
        return (
            data.onGoingInstallments.selectedMonths.length > 0 ||
            data.pastPaymentsInstallments.selectedMonths.length > 0 ||
            data.oneTimePaymentsInstallments.length > 0
        )
    }

    isValidPaymentMethodSelected(buildingID, apartmentNumber, data, params){

        let isOnGoingPaymentSelected = (data.onGoingInstallments.selectedMonths.length > 0)
        let cachedSelection = CacheData.fetchCache('PaymentMethodSelected', {buildingID: buildingID, apartmentNumber: apartmentNumber})
        cachedSelection = (cachedSelection && cachedSelection.selectedPaymentMethod);
        let creditIsSelected = (cachedSelection === constants.managerPaymentsOptions.creditCard)

        let illegalSelection = (params.shouldHideCreditCardOption && isOnGoingPaymentSelected && creditIsSelected)
        return !illegalSelection;
    }

    startLoader(){
        // NOTE: direct access to store is an anti-pattern, do not copy this solution out to other classes;
        store.dispatch(showLoader());
    }

    endLoader(){
        // NOTE: direct access to store is an anti-pattern, do not copy this solution out to other classes;
        store.dispatch(hideLoader());
    }

    #handleLanguage(currency){
        const fetchedLanguage = CacheData.fetchCache('language');
        CacheData.cache('currency', currency, {});
        const currencyLanguage = CURRENCY2LNG[currency.toUpperCase()] || DEFAULT_LANGUAGE;
        if (fetchedLanguage !== currencyLanguage){
            const languageDetector = new BllinkLanguageDetector();
            languageDetector.cacheUserLanguage(currencyLanguage);
        }
    }

    newStatusForCart(props){
        const apartmentDetails = props.apartment_details || props.vendor_details || {};
        const installments = props.installments_details || {};
        const paymentDetails = props.payment_details || {};
        const offlinePayments = props.offline_payments_details || {};
        const buildingID = apartmentDetails.buildingID;
        const apartmentNumber = apartmentDetails.apartmentNumber;
        const planStartedAt = paymentDetails.date;

        BllinkLogger.info(`OrderStatusPage received in newState `, props);

        let result = this.initialCartStatus(props);

        if (buildingID){
            result = this.cartInstallmentData(result, buildingID, apartmentNumber, installments);
            result.nextMonthsPaymentsData = this.calcNextMonthsPaymentsFromInstallments(props, result, planStartedAt, offlinePayments);
        }

        if (props.address){
            result.address = this.buildingTitle(props.address, apartmentNumber);
        }

        BllinkLogger.info(`OrderStatusPage return result `, result);
        return result
    }

    calcNextMonthsPaymentsFromInstallments(props, result, planStartedAt, offlinePayments){
        if (!$.isEmptyObject(offlinePayments)){ // should we remove? I think calcNextMonthsPayments can handle it
            // in the case of offline payments we already know the spilt to months
            BllinkLogger.info(`returning offline payments raw from server`);
            // reported by tay. should filter stopped payments by
            offlinePayments.months = offlinePayments.months.filter(m=> {
                const isSuccessful = constants.PaymentPlanDetailsStatus.isSuccessful(m.paymentState, props.isExpense);
                const isSelected = typeof m.selected === 'boolean' ? m.selected : true;
                return isSuccessful && isSelected;
            });
            return offlinePayments;
        }

        let nextMonths = LastConfirmationPageLogic.calcNextMonthsPayments({
            onGoing: result.onGoingInstallments,
            past: result.pastPaymentsInstallments,
            oneTime: result.oneTimePaymentsInstallments,
            options: {
                hideUnsuccessful: props.hideUnsuccessful,
                planStartedAt: planStartedAt,
                isExpense: result.isExpense,
            }
        });
        BllinkLogger.info(`result.nextMonthsPaymentsData `, nextMonths);

        return nextMonths
    }

    cartInstallmentData(result, buildingID, apartmentNumber, installments){
        result = {
        ...result,
            buildingID: buildingID,
            apartmentNumber: apartmentNumber,
            // todo take this from server response and NOT from client local storage
            // need to bring total and selected number of installments
            onGoingInstallments: (installments.onGoingInstallments || [{}])[0],
            pastPaymentsInstallments: (installments.pastPaymentsInstallments|| [{}])[0],
            oneTimePaymentsInstallments:(installments.oneTimePaymentsInstallments || []),
            tenantCommission: installments.tenantCommission,
        }

        BllinkLogger.info(`result for ${buildingID}`, result);

        return result
    }

    initialCartStatus(props){
        let result = {
            cartID: props.cartID,
            isExpense: props.isExpense,
            nextMonthsPaymentsData: {months: []},
            tenantDetails: props.tenant_details || {},
            vendorDetails: props.vendor_details,
            apartmentDetails: props.apartment_details,
            buildingPayerDetails: props.building_payer_details || {},
            paymentDetails: props.payment_details || {},
            managementCompanyDetails: props.management_company || {},
            onGoingInstallments: {},
            pastPaymentsInstallments: {},
            oneTimePaymentsInstallments: [],
            fetchedFromBackend: props.fetchedFromBackend,
            isViewedAsManager: props.isViewedAsManager,
            ...props.apartment_details
        }

        if (props.tenantEmail){
            result.tenantEmail = props.tenantEmail
        }

        return result
    }

    bindBoxToggleClick(){
        /*
           open / close payments box for mobile page
           also tenant details
        */

        // unbind and bind to ensure trigger only once
        $('.js-clickable-box').unbind('click').bind('click', (e) => {
            BllinkLogger.info(`clicked`)
            BllinkLogger.info($(e.target).next('.tog_new').length)

            $(e.target).toggleClass('open-block').next('.tog_new').toggleClass(' d-block')
        })
    }

    filterRelevantPayments(payments){
        /*
         only if not payed yet and not scheduled tto pay as well
         The reason why this filtering does not happen in the backend is since the manger gets the same response
         and she might use this information

        */
        const lengthBefore = payments.length;

        //filtering out months where scheduled to pay equals the months' total
        const paymentsFiltered = payments.filter((p)=> {
            return (p.amount_left.value - p.scheduled_to_pay.value) > 0
        })

        const lengthAfter = paymentsFiltered.length;

        if (lengthAfter !== lengthBefore){
            BllinkLogger.info('the input payments ', payments);
            BllinkLogger.info(`filtered ${lengthBefore - lengthAfter} payments. from ${lengthBefore} to ${lengthAfter}`)
        }else{
            BllinkLogger.info(`all months have amounts left to pay. returning all `)
        }

        return paymentsFiltered;

    }

    genericBinds(history){
        /*
            all generic binds that apply to all the website
        */

        $('.js-href').click((e)=>{
           e.preventDefault();
           BllinkLogger.info(`history`, history)
           history.push($(e.target).attr('href'))
        })
    }

    makeFullScreen(){
        /*
            100% instead of 90% (for tables screens)
         */
        $('#main-content-div').addClass('full-screen') //100% instead of 90%
    }

    renderHiddenPopUp(props){

        return (
            <div className="modal fade" id={props.id}>
                <div className="modal-dialog new_modal_content">
                    <div className="modal-content n_content">
                        <div className="modal-header">
                            <h5 className="col-12 modal-title text-center" id="genericModalLabel">{props.title}</h5>
                        </div>
                        <div className="modal-body">
                            <p className="text-center js-txt-line-1">{props.textLine1}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    parseDate(dateString){
        return this.timeUtils.parseDate(dateString)
    }

    parseAmount(amountString){
        if (amountString) {
            return <span exceltext={amountString}>{this.StringUtils.numberWithCommas(amountString)}</span>
        }

    }

    renderCorrectReceipt(cartIdOrPaymentId){

        const {t} = this.props;
        BllinkLogger.info(`render receipt for cart id ${cartIdOrPaymentId}`)

        if (!cartIdOrPaymentId){
            return ''
        }

        const isNumber = Number.isInteger(cartIdOrPaymentId);
        let linkText = t('reports.confirmation');
        let url;
        if (isNumber){
            url = `/managers/reports/confirmation-url/${this.props.buildingID}/${cartIdOrPaymentId}`;
            return <a className="js-confirmation-link" href={url}>{linkText} </a>

        }else{
            // bllink orders
            url = `/${constants.pages.orderStatusPage}/${cartIdOrPaymentId}`
            return <a target="_blank" rel="noopener noreferrer" href={url}>{linkText} </a>
        }

    }

    renderPaymentMethod(serverMethodName){

        const {t} = this.props;

        let methodKey = constants.APIContract.paymentMethodsKeys[serverMethodName];
        return methodKey ? t(`offline_payments.${methodKey}`): '';

    }

    renderDisabledReceiptLink(){
        // for offline payments, there is no receipt, we just render a disabled link
        // https://app.asana.com/0/1196545425570329/1199593240130079
        const {t} = this.props;
        return <a className="js-confirmation-link disabled" href='/'>{t('reports.receipt')} </a>
    }

    renderReceiptLink(paymentID){
        const {t} = this.props;

        if (!paymentID){ return }

        let linkText = t('reports.receipt');
        let url = `/managers/reports/invoice-url/${this.props.buildingID}/${paymentID}`;

        return <a className="js-downloaded-link" href={url}>{linkText} </a>
    }

    bindTablesQuickSearch(){
        // should be called AFTER table has been loaded
        // https://app.asana.com/0/1196545425570329/1199505436116096 All reports - search not working

        setTimeout(()=> {
            $('#quick-search').keyup((e) => {
                BllinkLogger.info(`quick search for ${$(e.target).val()}`)
                const text = $(e.target).val();
                $(`.js-payment-item:not(:contains(${text}))`).parent('tr').hide()
                $(`.js-payment-item:contains(${text})`).parent('tr').show();
            })
        },500)
    }

    bindTooltips(){
        const allTooltips = $('[data-toggle="tooltip"]');
        BllinkLogger.info(`length of tooltips found ${allTooltips.length}`);
        if (allTooltips.length) allTooltips.tooltip();
    }

    bankOptions(currency){
        const codes = currency?.toUpperCase() === 'MXN' ? constants.bankCodesInMexico : constants.bankCodesInIsrael;
        const country = currency?.toUpperCase() === 'MXN' ? 'mexico' : 'israel';
        return codes.map(value => ({ value, label: `${value} - ${i18n.t(`common.banks.${country}.${value}`)}` }));
    }

    bindDatePicker(){
        //https://uxsolutions.github.io/bootstrap-datepicker/?markup=component&format=&weekStart=&startDate=&endDate=&startView=0&minViewMode=0&maxViewMode=4&todayBtn=false&clearBtn=false&language=en&orientation=bottom%20auto&multidate=&multidateSeparator=&calendarWeeks=on&autoclose=on&keyboardNavigation=on&forceParse=on#sandbox
        $(".js-datepicker").datepicker({
            autoclose: true,
            format: "dd/mm/yyyy",
            startDate: '-2y',
            endDate: '+2y'
        });
    }

    shortenText(commentOriginalText){
        /*
            Monthly + Apt report - long comment should be shown in tooltip
		        https://app.asana.com/0/search/1199538455442485/1199505436116115

         */

        if (!commentOriginalText){return commentOriginalText}

        if (commentOriginalText.length > constants.longCommentCutOff){
            return {
                originalComment: commentOriginalText,
                shortComment: commentOriginalText.substring(0, constants.longCommentCutOff) + constants.longCommentPostfix,
            }
        }

        return commentOriginalText

    }

    getUploadPaymentState(props){
        let year = this.timeUtils.getCurrentYear();
        let isOneTime = this.props.match.path.includes(constants.pages.oneTimePaymentSettings);
        if (props.location && props.location.search){
            const sp = new URLSearchParams(props.location.search);
            let params = Object.fromEntries(sp.entries());
            year = params.year || year
            isOneTime = params.oneTime
        }

        return {
            year: year,
            isOneTime: isOneTime,
            isMonthly: !isOneTime
        }
    }

    validateBeforeSearch(addressComponents){
        const {t} = this.props;
        BllinkLogger.info(`addressComponents`, addressComponents);
        if (addressComponents[0].types[0] !== 'street_number'){

            let number = this.getFirstNumber($('#address').val());
            if (number) {
                addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});

                return {result: true, addressComponents: addressComponents}
            }

            // const resultSpecialCase = this.specialCases(addressComponents);
            // if (resultSpecialCase.specialCase){
            //     BllinkLogger.info(`is special case true`)
            //     return {result: true, addressComponents: resultSpecialCase.addressComponents}
            // }

            $('#address-error').text(
                t(`errors.tenant.search.SearchAddressIsNotCompleted`, {searchTerm:  $('#address').val()})
            )

            return {result:false}
        }

        return {result:true}
    }

    // specialCases(addressComponents){
    //     // cases where google maps doesn't detect a full address even if the user has entered one
    //     // there is nothing we can do except hard code those cases for now
    //     let specialCase=false;
    //     if (this.isShahafTwoCase(addressComponents)){
    //         let number = this.getFirstNumber($('#address').val());
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }else if (this.isSahlabThreeCase(addressComponents)){
    //         let number = this.getFirstNumber($('#address').val());
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }else if (this.isHarShlomoTwoCase(addressComponents)){
    //         let number = this.getFirstNumber($('#address').val());
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }else if (this.isHaravShlomoThreeCase(addressComponents)){
    //         addressComponents.unshift({long_name: '3', short_name: '3', types: ["street_number"]});
    //         // this street has returns special char u200 in it so it's not finding it
    //         addressComponents[1] = {long_name: 'הרב שלמה לורינץ', short_name: 'הרב שלמה לורינץ', types: ["route"]}
    //         specialCase = true
    //     }else if (this.isMeirAmitOrAkivaCase(addressComponents)){
    //         let number = this.getFirstNumber($('#address').val());
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }else if (this.isSigalitCase(addressComponents)){
    //         let number = this.getFirstNumber($('#address').val());
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }else if (this.is3920Case(addressComponents)){
    //         let number = '8'; // first number won't work here since the street name is a number . need second number
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }else if (
    //         this.isRehavamCase(addressComponents) ||
    //         this.isYoseftalCase(addressComponents) ||
    //         this.isTnufaCase(addressComponents) ||
    //         this.isAzrielCase(addressComponents) ||
    //         this.isMorCase(addressComponents) ||
    //         this.isAhadotCase(addressComponents)
    //     ){
    //         let number = this.getFirstNumber($('#address').val());
    //         addressComponents.unshift({long_name: number, short_name: number, types: ["street_number"]});
    //         specialCase = true
    //     }
    //
    //     return {
    //         specialCase: specialCase,
    //         addressComponents: addressComponents
    //     }
    //
    // }
    //
    // isShahafTwoCase(addressComponents){
    //     return (addressComponents[0].short_name === 'שחף' || addressComponents[0].short_name === 'shahaf')
    // }
    //
    // isSahlabThreeCase(addressComponents){
    //     return (addressComponents[0].short_name === 'סחלב') // no english title in google yet
    // }
    //
    // isHarShlomoTwoCase(addressComponents){
    //     //הר שלמה 2, אור עקיבא
    //     return (addressComponents[0].short_name === 'הר שלמה' || addressComponents[0].short_name === 'Har Sholom St')
    // }
    //
    // isHaravShlomoThreeCase(addressComponents){
    //     //הרב שלמה לורינץ 3, בית שמש
    //     return (addressComponents[0].short_name.includes('שלמה לורינץ'))
    // }
    //
    // isMeirAmitOrAkivaCase(addressComponents){
    //     //מאיר עמית 5 ו -3 אור עקיבא
    //     return (addressComponents[0].short_name.includes('מאיר עמית'))
    // }
    //
    // isSigalitCase(addressComponents){
    //     //מאיר עמית 5 ו -3 אור עקיבא
    //     return (addressComponents[0].short_name.includes('סיגלית'))
    // }
    //
    // is3920Case(addressComponents){
    //     //3920 8
    //     return (addressComponents[0].short_name.includes('3920'))
    // }
    //
    // isTnufaCase(addressComponents){
    //     //Tnufa 6
    //     return (addressComponents[0].short_name.includes('תנופה'))
    // }
    //
    // isAzrielCase(addressComponents){
    //     //Azriel 15 petach
    //     return (addressComponents[0].short_name.includes('עזריאל'))
    // }
    //
    // isRehavamCase(addressComponents){
    //     //Rehavam Ze'evi 11
    //     return (addressComponents[0].short_name.includes('רחבעם זאבי'))
    // }
    //
    // isYoseftalCase(addressComponents){
    //     //Yoseftal 4 yahud
    //     return (addressComponents[0].short_name.includes('יוספטל'))
    // }
    //
    // isMorCase(addressComponents){
    //     //Mor 9 A or akiva
    //     return (addressComponents[0].short_name.includes('מור'))
    // }
    //
    // isAhadotCase(addressComponents){
    //     //ahadot 14 or 16
    //     return (addressComponents[0].short_name.includes('אחדות'))
    // }

    getFirstNumber(string){
        if (!string){return ''}
        let result = string.match(/\d+/)
        if (result === null){return ''}
        return result[0]
    }
}

export default Parent;
