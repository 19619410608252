import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Auth} from 'aws-amplify';
import {hideLoader, showLoader, toggleMobileMenuOpened} from '../../store/common/slice';
import defaultAvatar from '../../images/default_avatar.svg';
import {constants} from '../../common/constants/constants';
import logoutIcon from '../../images/newDesign/logout.svg';
import bellOutline from '../../images/newDesign/bell_outline.svg';
import {GAEvent} from '../../common/google_analytics/google_analytics';
import CacheData from '../../common/utils/cacheData';
import {BllinkLogger} from '../../common/utils/bllink_loggers';
import hamburger from '../../images/newDesign/hamburger_icon.svg';
import {fetchAllComments} from "../../store/comments/actions";
import {EqualWebButton} from "./equalWebButton";
import gift from "../../images/newDesign/gift.svg";
import {useAccessToken} from "../../common/hooks/useAccessToken";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {isAdminSelector, isPremiumUserSelector, shouldHideRewardsSelector} from "../../store/auth/selectors";
import {GlobalSearch} from "../globalSearch/globalSearch";

const StyledTopBar = styled.div`
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  width: 100%;

  .actions {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .user-logo {
    width: 43px;
    height: 43px;
  }

  .top-link {
    display: block;
    border: none;
    background: transparent;
    position: relative;

    .notification-counter {
      position: absolute;
      top: -14px;
      left: 9px;
      width: 24px;
      height: 24px;
      background-color: var(--bllink-pink);
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 12px;
    }

    & + .top-link {
      margin-inline-start: 23px;
    }

    .rewards {
      height: 20px;
      width: 20px;
    }

    &.disabled {
      opacity: .4;
      pointer-events: auto;
    }
  }

  .global-search {
    margin: 0 12px;
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    max-width: 300px;
  }

  .hamburger-icon {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .hamburger-icon {
      display: block;
      width: 30px;
      margin-inline-end: 23px;
      cursor: pointer;
    }

    .mobile-hide {
      display: none;
    }
  }
`;

export default function ManagerTopBar() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const client = useAccessToken();
    const isAdmin = useSelector(isAdminSelector);
    const shouldHideRewards = useSelector(shouldHideRewardsSelector);
    const userProfile = useSelector(state => state.auth.userProfile);
    const allComments = useSelector((state) => state.comments.allComments);
    const isPremiumUser = useSelector(isPremiumUserSelector);
    const [notificationNumber, setNotificationNumber] = useState('0');
    const [rewardsBalance, setRewardsBalance] = useState(0);

    const MAX_NOTIFICATION_NUMBER = 99;

    useEffect(() => {
        if (allComments?.comments) {
            const amountToShow = allComments.comments.length > MAX_NOTIFICATION_NUMBER ? `+${MAX_NOTIFICATION_NUMBER}` : allComments.comments.length.toString();
            setNotificationNumber(amountToShow);
        }
    }, [allComments]);

    useEffect(async () => {
        if (userProfile?.partnerID) {
            await dispatch(fetchAllComments({notify: true, ack: false}));
        }
    }, [userProfile]);

    useEffect(async () => {
        if (client) {
            const rewards = await client.managers?.rewards?.getActiveCampaignsInfo();
            const newBalance = rewards.coinBalance?.length ? rewards.coinBalance[0]?.coinValue : 0;
            setRewardsBalance(newBalance ?? 0);
        }
    }, [client]);

    const clearDataOnLogout = () => {
        CacheData.delete('profile');
    }

    const logout = async () => {
        GAEvent(constants.GoogleAnalytics.Events.login, 'logout', 1, 'logout');
        clearDataOnLogout();
        try {
            dispatch(showLoader());
            // to return the commbox
            window.localStorage.removeItem('managerLoggedIN');
            await Auth.signOut();
        } catch (e) {
            BllinkLogger.warn(`sign out error `, e);
        } finally {
            dispatch(hideLoader());
        }
        window.location.href = `/${constants.pages.loginUrl}`;
    }

    /**
     * @param {MouseEvent} e
     */
    const onGiftsClick = useCallback((e) => {
        if (!isPremiumUser) {
            e.stopPropagation();
            e.preventDefault();
        }
    }, [isPremiumUser])

    const giftsTooltip = useMemo(() => {
        return isPremiumUser ? t('manager_rewards.points', {pointsNumber: rewardsBalance}) : t('buildings.upgrade_to_premium');
    }, [isPremiumUser, rewardsBalance])

    return (
        <StyledTopBar className="manager-top-bar">
            <div className='actions'>
                <div
                    className='hamburger-icon'
                    onClick={() => dispatch(toggleMobileMenuOpened())}
                >
                    <img src={hamburger} alt="mobile menu"/>
                </div>

                {/* todo hiding from mobile for now since it's blocking the hamburger menu. can return after sorted out*/}
                <div className='global-search mobile-hide'><GlobalSearch/></div>

                {!shouldHideRewards ?
                    (
                        <OverlayTrigger
                            placement="top"
                            overlay={props => (
                                <Tooltip {...props}>
                                    {giftsTooltip}
                                </Tooltip>
                            )}
                        >
                            <NavLink
                                to={`/${constants.pages.rewards}`}
                                className={`top-link ${isPremiumUser ? '' : 'disabled'}`}
                                onClick={onGiftsClick}>
                                <img src={gift} alt="rewards" className='rewards'/>
                            </NavLink>
                        </OverlayTrigger>
                    ) : null
                }

                {
                    (
                        <NavLink to={`/${constants.pages.notifications}`} className={'top-link'}>
                            <img src={bellOutline} alt="notifications"/>
                            <div hidden={notificationNumber === '0'}
                                 className={'notification-counter'}>{notificationNumber}</div>
                        </NavLink>
                    )
                }

                <EqualWebButton/>

                <NavLink to={`/${constants.pages.allBuildingsUrl}`} className={'top-link'}>
                    <img className="user-logo" src={userProfile.partnerLogo || defaultAvatar} alt="company logo"/>
                </NavLink>

                <button type="button" className={'top-link'} onClick={logout}>
                    <img src={logoutIcon} alt={t('login.logout')}/>
                </button>
            </div>
        </StyledTopBar>
    )
}
