export const messagePlatform = {
    "btns": {
        "createNewMessage": "יצירת הודעה חדשה",
        "sendMessage": "שליחת הודעה",
        "privateName": "שם פרטי",
        "buildingAddress": "כתובת הבניין",
        "apartmentNumber": "מספר דירה",
        "paymentLink": "לינק לתשלום",
        "sms": "הודעת SMS",
        "email":" הודעת דוא״ל ",
        "chooseTenants": "בחירת דיירים",
        "owners": "בעלי דירות",
        "renters": "שוכרים"
    },
    "labels": {
        "smsMessageContent": "תוכן הודעת SMS",
        "emailMessageContent": "תוכן  הודעת דוא״ל",
        "chooseMethod": "בחר אמצעי לשליחת הודעה",
        "senderName": "שם השולח",
        "remainingMessages": "יתרת הודעות חודשית",
        "personalizedContent": "תוכן מותאם אישית",
        "contact": "אנשי קשר לשליחה",
        "apartment": "דירה",
        "selection": "בחירה",
        "selectAll": "בחר הכל",
        "quotaFinished": "מכסת ההודעות הסתיימה. יש לפנות לנציג לחידוש",
        "purchasing_package_of_messages": "בלינק - רכישת חבילת הודעות",
        "chooseOne": "עליך לבחור דייר אחד לפחות",
    },
    "titles": {
        "messagePlatform": "מערכת הודעות",
        "sendMessage": "שליחת הודעה",
    },
    "messages":{
        "successSending": "נשלחו בהצלחה {{numberOfTenants}} הודעות לדיירים"
    }
};
