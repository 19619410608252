export const sharedMobile = {
    apartment: {
        total: {
            paid: 'שולם עד היום לשנת {{year}} ',
            special: 'חובות מיוחדים',
            ongoing: 'חובות שוטפים',
            up_to_date: 'עדכני לתאריך',
        },
        paid_payments: {
            title: 'תשלומים אחרונים',
            receipt: 'קבלה',
            unable_download_invoice: 'לא ניתן להוריד את הקובץ, נסה שוב מאוחר יותר',
        },
        future_payments: {
            title: 'תשלומים עתידיים',
            for: 'ל:',
            ongoing_total: 'סך הכל תשלומים עתידיים:',
            number_of_payments: 'מספר תשלומים:',
        },
        show_more: 'הצג עוד',
        paid_for: 'תשלום עבור:',
        comment: 'הערת תשלום:',
    }
}
