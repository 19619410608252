// Creating Redux module for building info
// extraReducers process data that was got in action

import { createSlice} from '@reduxjs/toolkit';
import {
    fetchBuildingData,
    getBuildingTitle,
    getBuildingTitleText,
    fetchManagerBuildingData,
    loadDebtList,
    fetchPaymentsByYear,
    fetchBuildingFeatures,
} from './actions';

const buildingsSlice = createSlice({
    name: 'buildings',
    initialState: {
        currentBuildingData: {},
        currentBuildingDataPendingId: null,
        currentBuildingFeatures: null, // shouldn't this be [] ?
        // https://bllink.atlassian.net/browse/SSB-2043 Tenant login Frozen buildings
        isBuildingDisabled: true,
        currentBuildingFeaturesPendingId: null,
        managerData: {},
        managerDataPendingId: null,
        titleText: '',
        titleData: {},
        debtListByBuildings: {},
        data: {}, // data per building ID
        buildingID: ''
    },
    reducers: {
        changeBuildingID: (state, action) => {
            state.buildingID = action.payload;
        },

        clearBuildingPaymentsCache(state, action) {
            if (action.payload.buildingId) {
                state.data[action.payload.buildingId] = null;
            }
        },

        clearBuildingManagersData(state, action) {
            state.managerData = {};
            state.managerDataPendingId = null;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchPaymentsByYear.fulfilled, (state, data) => {
            const buildingId = data.meta.arg.buildingID;
            const year = data.meta.arg.year;
            state.data[buildingId] = state.data[buildingId] ?? {};
            state.data[buildingId]['payments'] = state.data[buildingId]['payments'] ?? {};
            state.data[buildingId]['payments'][year] = data.payload;
        });

        builder.addCase(fetchBuildingData.pending, (state, args) => {
            state.currentBuildingDataPendingId = args.meta.arg.buildingID;
        })
        builder.addCase(fetchBuildingData.fulfilled, (state, { payload }) => {
            state.currentBuildingData = payload;
        })
        builder.addCase(fetchBuildingData.rejected, (state, action) => {
            state.currentBuildingData = {};
        })

        builder.addCase(getBuildingTitleText.fulfilled, (state, { payload }) => {
            state.titleText = payload;
        })
        builder.addCase(getBuildingTitleText.rejected, (state, action) => {
            state.titleText = '';
        })

        builder.addCase(getBuildingTitle.fulfilled, (state, { payload }) => {
            state.titleData = payload;
        })
        builder.addCase(getBuildingTitle.rejected, (state, action) => {
            state.titleData = {};
        })

        builder.addCase(fetchManagerBuildingData.pending, (state, { meta }) => {
            state.managerDataPendingId = meta.arg.buildingID;
        })
        builder.addCase(fetchManagerBuildingData.fulfilled, (state, { payload }) => {
            state.managerData = payload.building || payload;
        })
        builder.addCase(fetchManagerBuildingData.rejected, (state, action) => {
            state.managerData = {};
        })

        builder.addCase(loadDebtList.fulfilled, (state, { payload }) => {
            state.debtListByBuildings = {
                ...state.debtListByBuildings,
                [payload.buildingID]: payload.result
            }
        })

        builder.addCase(fetchBuildingFeatures.fulfilled, (state, { payload }) => {
            state.currentBuildingFeatures = payload;
            state.isBuildingDisabled = state.currentBuildingFeatures?.deactivateBuilding === 'true';
        });
        builder.addCase(fetchBuildingFeatures.rejected, (state, action) => {
            state.currentBuildingFeatures = null;
            console.error(action.payload);
        });
        builder.addCase(fetchBuildingFeatures.pending, (state, action) => {
            state.currentBuildingFeaturesPendingId = action.meta.arg.buildingID;
        });
    }
});

export const {
    changeBuildingID,
    clearBuildingPaymentsCache,
    clearBuildingManagersData
} = buildingsSlice.actions;
export default buildingsSlice.reducer;
