import BllinkUtils from '../../common/utils/utils';
import CacheData from '../../common/utils/cacheData';
import { constants } from '../../common/constants/constants';
import {BllinkLogger} from '../../common/utils/bllink_loggers';
import PaymentsLogic from '../../common/payment_flow/paymentsLogic';
import {GAEvent} from "../../common/google_analytics/google_analytics";
import i18n from "i18next";

const timeUtil = BllinkUtils.BllinkTime;
const moneyUtil = BllinkUtils.Money;

const paymentTypes = [
    {key: 'onGoing', cacheKey: 'onGoingPayments'},
    {key: 'past', cacheKey: 'pastPayments'},
]

export default class LastConfirmationPageLogic{

    constructor(options) {

        this.client = options.client;
        this.buildingID = options.buildingID;
        this.apartmentNumber = options.apartmentNumber;

    }

    static calcNextMonthsPayments(payments){
        /*
            to show the user how much she is going to pay in future months
            aggregates all payments (past, on going and on time payments)
         */
        let paymentsByMonths = {}, paymentsByMonthsFromServer;
        const options = (payments.options || {});
        const shouldHideUnsuccessful = options.hideUnsuccessful;
        const isExpense = options.isExpense;
        const tenantCommission = parseFloat(options.commission || 0);
        options.planStartedAt = BllinkUtils.BllinkTime.parseDateToBllinkTime(options.planStartedAt);
        BllinkLogger.info(`payments options tenantCommission ${tenantCommission}`, payments);

        // debt and on going
        for (const paymentType of paymentTypes){
            const data = payments[paymentType.key];
            const shouldShow = this.shouldShow(data, shouldHideUnsuccessful, isExpense);
            if (shouldShow) {
                const cacheKey = constants.cache.keys.payments[paymentType.cacheKey];
                if (data.chargeDateOptions) {
                    const chargeDate = data.chargeDateOptions.selected || data.chargeDateOptions.default;
                    options.planStartedAt = {
                        year: new Date(chargeDate).getFullYear(),
                        month: new Date(chargeDate).getMonth() + 1,
                        day: new Date(chargeDate).getDate()
                    }
                }
                let result = this.calcMonthsSums(paymentsByMonths, paymentsByMonthsFromServer, cacheKey, data, tenantCommission, options);
                paymentsByMonths = result.paymentsByMonths;
                paymentsByMonthsFromServer = result.paymentsByMonthsFromServer;
            }else{
                BllinkLogger.info(`${paymentType.key} is not successful. skipping`)
            }
        }

        // one time payments
        const thisObj = this;
        payments.oneTime.forEach(function(oneTimePayment, index){
            const shouldShow = thisObj.shouldShow(oneTimePayment, shouldHideUnsuccessful, isExpense);
            if (shouldShow) {
                const cacheKey = constants.cache.keys.payments.oneTimeInstallmentsName(oneTimePayment.building_one_time_payment_id);
                let result = thisObj.calcMonthsSums(paymentsByMonths, paymentsByMonthsFromServer, cacheKey, oneTimePayment, tenantCommission, options);
                paymentsByMonths = result.paymentsByMonths
                paymentsByMonthsFromServer = result.paymentsByMonthsFromServer

            }else{
                BllinkLogger.info(`${oneTimePayment.title} is not successful. skipping`)
            }
        })

        let result;
        if (paymentsByMonthsFromServer){
            BllinkLogger.info(`paymentSums returned`, paymentsByMonthsFromServer);
            result =  LastConfirmationPageLogic.monthPayments(paymentsByMonthsFromServer, options);
        }else{
            BllinkLogger.info(`paymentsByMonths results is `, paymentsByMonths);
            result =  LastConfirmationPageLogic.monthPayments(paymentsByMonths, options);
        }

        BllinkLogger.info(`results monthPayments`, result);
        return result

    }

    static shouldShow(data, shouldHideUnsuccessful, isExpense) {
        const shouldShow = (
          constants.PaymentPlanDetailsStatus.isSuccessful(data.status, isExpense) ||
          !shouldHideUnsuccessful
        );
        BllinkLogger.info(`months data status ${data.status} shouldShow ${shouldShow} , shouldHideUnsuccessful ${shouldHideUnsuccessful}`, data);

        return shouldShow
    }

    static calcMonthsSums(paymentsByMonths, paymentsByMonthsFromServer, cacheKey, data, tenantCommission, options){
        if (data.installmentsPlan){
            paymentsByMonthsFromServer = this.calcSumsFromServer(paymentsByMonthsFromServer, data);
        } else {
            paymentsByMonths = this.calcSumsWithFrontEndLogic(data, tenantCommission, cacheKey, paymentsByMonths, options)
        }

        return {
            paymentsByMonths: paymentsByMonths,
            paymentsByMonthsFromServer: paymentsByMonthsFromServer
        }
    }

    static calcSumsWithFrontEndLogic(data, tenantCommission, cacheKey, paymentsByMonths, options){
        const totalReward = data.totalReward || 0;
        const total = data.total + totalReward;
        //data.selectedMonths is the month related payments
        let currency = data.selectedMonths ? data.selectedMonths[0]?.total?.currency ?? constants.defaultCurrency : constants.defaultCurrency;
        // data.currency comes from one time (oneTimeSinglePaymentSettings)
        currency = data.currency ||  currency;
        BllinkLogger.info(`total ${total} for tenantCommission ${tenantCommission}. before commission ${data.total}`)
        const numberOfInstallments = (
            data.numberOfInstallments ||
            LastConfirmationPageLogic.getSelectedNumberOfInstallments(cacheKey)
        );

        options = {...options, totalReward: totalReward};
        paymentsByMonths = LastConfirmationPageLogic.fillInMonthsData(
            total, numberOfInstallments, paymentsByMonths, tenantCommission, currency, data.selectedMonths, options);
        BllinkLogger.info(`paymentsByMonths after ${cacheKey} `, paymentsByMonths);

        return paymentsByMonths;
    }

    static calcSumsFromServer(paymentsByMonthsFromServer, data){
        console.log(444, data);
        // relevant for order status page only and for
        // payment plans that we started logging the actual time of execution
        BllinkLogger.info(`payment plan from server`)
        paymentsByMonthsFromServer = paymentsByMonthsFromServer || {};
        data.installmentsPlan.forEach(function (amountToPay, i) {
            if (!amountToPay.isActive) return;
            // https://app.asana.com/0/1196545425570329/1199935925247006
            // dateToCharge null bug in confirmation page
            // happens when we want to ignore a plan in auto charge or the plan is hora'at keva (not credit charge)
            if (amountToPay.dateToCharge) {
                const timeOfCharge = BllinkUtils.BllinkTime.parseDateToBllinkTime(amountToPay.dateToCharge)
                const year = timeOfCharge.year;
                const month = timeOfCharge.month;

                paymentsByMonthsFromServer[year] = paymentsByMonthsFromServer[year] || {};
                paymentsByMonthsFromServer[year][month] = paymentsByMonthsFromServer[year][month] || {
                    total: 0,
                    currency: constants.defaultCurrency
                };
                paymentsByMonthsFromServer[year][month].total += BllinkUtils.Money.roundNumber(amountToPay.amount, 2);
                paymentsByMonthsFromServer[year][month].total = BllinkUtils.Money.roundNumber(paymentsByMonthsFromServer[year][month].total, 2);
                paymentsByMonthsFromServer[year][month].month = month;
                paymentsByMonthsFromServer[year][month].year = year;
                paymentsByMonthsFromServer[year][month].currency = amountToPay.currency;
            }
        });
        BllinkLogger.info(`paymentsByMonthsFromServer `, paymentsByMonthsFromServer);

        return paymentsByMonthsFromServer;
    }

    static dataIsPresent(timeA, paymentsByMonths){
        return (paymentsByMonths[timeA.year] || {})[timeA.month]
    }

    static monthPayments(paymentsByMonths, options){
        /*
            from {'2020'=> '1' => {total: 50} ....}

            to

            [
                {month: 1, year: 2020, total}
            ]
         */
        let total = 0, currency = constants.defaultCurrency;
        let currentMonth = options.planStartedAt || timeUtil.getTimeNow();

        // https://app.asana.com/0/1196545425570329/1199945049878524
        // no upcoming months in the confirmation page
        let dataIsPresent = true // LastConfirmationPageLogic.dataIsPresent(currentMonth, paymentsByMonths);
        let months = [];
        // allow un consecutive months up until 5 holes. not the best code. todo improve.
        const maxHoles = 12;
        let unConsecutiveMonths = 0;
        while (dataIsPresent){
            let monthData = (paymentsByMonths[currentMonth.year]|| {})[currentMonth.month];
            if (monthData) {
                let hasAmountCharged = monthData && monthData.total > 0;
                const isSelected = typeof monthData.selected === 'boolean' ? monthData.selected : true;
                if (hasAmountCharged && isSelected) {
                    monthData.year = currentMonth.year;
                    monthData.month = currentMonth.month;
                    currency = monthData.currency;
                    months.push(monthData);
                    total += monthData.total;
                }
            }

            currentMonth = timeUtil.getNextMonth(currentMonth);
            dataIsPresent = LastConfirmationPageLogic.dataIsPresent(currentMonth, paymentsByMonths);
            if (!dataIsPresent){
                unConsecutiveMonths +=1;
                if (unConsecutiveMonths < maxHoles){
                    dataIsPresent = true
                }
            }
        }

        BllinkLogger.info(`months are `, months);
        return {
            months: months,
            total: {value: moneyUtil.roundNumber(total), currency: currency
        }}

    }

    static fillInMonthsData(total, numberOfInstallments, paymentsByMonths, tenantCommission, currency, selectedMonths, options){
        /*
             TODO logic here should be aligned with backend logic so the user won't be surprised
              support uneven amount

         */
        let currentMonth = options.planStartedAt || timeUtil.getTimeNow();
        let totalRewardAmount = options.totalReward;
        const monthsAreSelected = !!selectedMonths;
        if (total > 0) {
            let sumsToPay;

            let userChooseToPayExactlyByMonthsAmounts;
            if (monthsAreSelected) {
                userChooseToPayExactlyByMonthsAmounts = (selectedMonths.length === +numberOfInstallments);
            }

            if (userChooseToPayExactlyByMonthsAmounts && monthsAreSelected) {
                sumsToPay = selectedMonths.map(month => month.part_pay ? month.part_pay.value : month.amount_left.value);
            } else {
                sumsToPay = moneyUtil.calcPlanByAveragePayment(total, numberOfInstallments);
            }

            for (const sumToPay of sumsToPay){

                let monthAmount = sumToPay * (1 + tenantCommission /100) - totalRewardAmount;
                totalRewardAmount = 0;
                paymentsByMonths[currentMonth.year] = paymentsByMonths[currentMonth.year] || {};
                paymentsByMonths[currentMonth.year][currentMonth.month] = paymentsByMonths[currentMonth.year][currentMonth.month] || {total: 0, currency};
                paymentsByMonths[currentMonth.year][currentMonth.month].total += monthAmount;
                paymentsByMonths[currentMonth.year][currentMonth.month].total = moneyUtil.roundNumber(paymentsByMonths[currentMonth.year][currentMonth.month].total);
                currentMonth = timeUtil.getNextMonth(currentMonth);
            }
        }

        return paymentsByMonths;
    }

    static getSelectedNumberOfInstallments(nameOfPayment){
        /*
            get it from cache
         */

        const installmentsData =  CacheData.fetchCache('installmentsChoices',
            {key: nameOfPayment})

        if (!installmentsData){
            BllinkLogger.warn(`selected number of installments is not defined for ${nameOfPayment}`);
            return 1 // this is the default
        }

        return installmentsData.selected

    }

    async handleContinueClicked(buttonUrl, thisObject, redirect = true, bankCharge = false, isChargeCart = false) {
        GAEvent(constants.GoogleAnalytics.Events.payment, 'clickedPayOnCheckoutPage', 1 , 'clickedPayOnCheckoutPage');

        const history = thisObject.props.history;

        thisObject.startLoader();

        let result;
        try {
            result = await this.createPaymentPlan(thisObject, bankCharge, isChargeCart);
        } catch (e) {
            result = {};
        }

        if (result.cartID) {
            thisObject.endLoader();

            // save charge date for credit card page
            const cacheOptions = {buildingID: this.buildingID, apartmentNumber: this.apartmentNumber};
            let charge_date = timeUtil.getFutureChargeDate(cacheOptions);
            if (charge_date) result.charge_date = charge_date;

            // save response to cache so can be used in the cc page
            CacheData.cache(`createCartResponse`, result, {cartID: result.cartID});
            // redirect to credit card details page
            // for now, need to reload since history push does not init the fields
            if (result.currency === 'MXN') {
                // Mexican Peso - use Rapyd
                redirect && history.push(`/${constants.pages.paymentDetailsRapydUrl}/${result.cartID}`);
                // window.location.href = `/${constants.pages.paymentDetailsRapydUrl}/${result.cartID}`;
            } else {
                redirect && history.push(`${buttonUrl}/${result.cartID}`)
                // window.location.href = `${buttonUrl}/${result.cartID}`
            }
            return {
                cartId: result.cartID,
                isFuturePayment: result.isFuturePayment
                // todo isLoan
            }
        }
    }

    /**
     * @param {PaymentsLastCheckoutPage} callerObj
     * @param {boolean} [bankAutoCharge=false]
     * @return {Promise<{
     *    firstPaymentTotal: number;
     *    firstRewardTotal: number;
     *    currency: string;
     *    cartID: string;
     *    buildingID: string;
     *    maxInstallmentsInCart: number;
     *    hasTenantCommission: boolean;
     *    paymentTerminal: string | number;
     * }>}
     * */
    async createPaymentPlan(callerObj, bankAutoCharge = false, isChargeCart = false) {
        // get selected payments settings
        const payload = this.buildingPaymentPayload({}, bankAutoCharge, isChargeCart);

        const isCartValid = callerObj.validateDetails(this.buildingID, this.apartmentNumber);
        if (!isCartValid) {
            callerObj.endLoader();
            return Promise.reject();
        }

        // call server and get cart id
        const result = await this.client.post(
            `/tenants/payments/${this.buildingID}/${this.apartmentNumber}`,
            payload
        );

        // was removed due to a bug where a user sometimes got this discount on the frontend and
        // if (payload.rewardAmount) {
        //     result.firstPaymentTotal = parseFloat(result.firstPaymentTotal) - payload.rewardAmount
        // }

        return result;
    }

    /**
     * @param {PaymentsLastCheckoutPage} caller
     * @param {string} imageBase64
     * @param {string} imageName
     * @param {{
     *     bankCode: { value: string };
     *     branchNumber: string;
     *     accountNumber: string;
     * }} bankChargeData
     * @return {Promise<{
     *     paymentResult: { cartID: string },
     *     imageResult: { success: boolean },
     * }>}
     * */
    async createBankChargePaymentPlan(caller, imageBase64, imageName, bankChargeData) {
        const paymentPayload = this.buildingPaymentPayload({}, true);
        const isCartValid = caller.validateDetails(this.buildingID, this.apartmentNumber);
        if (!isCartValid) {
            caller.endLoader();
            throw new Error('Cart is invalid');
        }
        const payload = {
            ...paymentPayload,
            bankChargeData,
            imageBase64,
            imageName,
        };
        return await this.client.post(`/tenants/bankCharge/${this.buildingID}/${this.apartmentNumber}`, payload);
    }

    static getOnGoingPayload(cacheOptions){
        let onGoingPayments = PaymentsLogic.getOngoingPayments(cacheOptions.buildingID, cacheOptions.apartmentNumber);
        let onGoingInstallments = CacheData.fetchCache('installmentsChoices', {key: constants.cache.keys.payments.onGoingPayments});
        let charge_date = timeUtil.getFutureChargeDate(cacheOptions);
        BllinkLogger.info(`onGoingInstallments from cache `, onGoingInstallments)
        if (onGoingInstallments){
            onGoingInstallments = parseInt(onGoingInstallments.selected);
        }

        onGoingPayments = this.selectedOnly(onGoingPayments)
        if (onGoingPayments.length > 0){
            const months = timeUtil.sortMonths(onGoingPayments);

            // changed due to a bug fix https://app.asana.com/0/1196545425570329/1200247575959774 number of installments bug
            // it would send by max number of months and not according to what was selected
            let numberOfInstallments = onGoingInstallments ? onGoingInstallments : months.length;
            if (numberOfInstallments < 1){
                BllinkLogger.info(`onGoingInstallments`, onGoingInstallments)
                BllinkLogger.info(`months.length`, months.length)
                alert(i18n.t(`errors.tenant.payments.NumberOfInstallmentIsIllegal`))
                GAEvent(constants.GoogleAnalytics.Events.payment, 'paymentsErrors', 1, 'NumberOfInstallmentIsIllegal');
                throw new Error(`numberOfInstallments ${numberOfInstallments} months.length ${months.length}`)
            }
            // const firstMonth = timeUtil.parseMonth(months[0]);
            // support first month is not the next months
            // https://app.asana.com/0/1123966772501425/1199546685691687
            // enable offline payments for future months (not just the next month)
            BllinkLogger.info(`timeUtil.parseMonth(months) `, timeUtil.parseMonth(months))
            let payload = {
                months: timeUtil.parseMonth(months),
                number_of_installments: numberOfInstallments,
            }
            if (charge_date) {payload.charge_date = charge_date}
            return payload
        }
    }

    static getPastPayload(cacheOptions){
        /*
            return past payments request to charge payload
         */
        let pastPayments = PaymentsLogic.getDebtPayments(cacheOptions.buildingID, cacheOptions.apartmentNumber);
        let pastInstallments = CacheData.fetchCache('installmentsChoices', {key: constants.cache.keys.payments.pastPayments});
        let charge_date = timeUtil.getFutureChargeDate(cacheOptions);
        BllinkLogger.info(`pastInstallments from cache `, pastInstallments);
        if (pastInstallments){
            pastInstallments = parseInt(pastInstallments.selected);
        }

        pastPayments = this.selectedOnly(pastPayments);

        if (pastPayments.length > 0){
            const months = timeUtil.sortMonths(pastPayments);
            // to enable part pay, we are switching to months array always instead of start month and number_of_months_forward
            // https://app.asana.com/0/1196545425570329/1199334179749068
            let payload = {
                months: timeUtil.parseMonth(months),
                number_of_installments: pastInstallments,
            }
            if (charge_date) {payload.charge_date = charge_date}
            return payload
        }
    }

    static getOneTimePaymentsPayload(cacheOptions, options){
        /*
              [
               { number_of_installments: 3, building_one_time_payment_id: 1544},
               { number_of_installments: 2, building_one_time_payment_id: 2103},

              ]
         */
        let result = [];
        let oneTimePayments = PaymentsLogic.getOneTimePayments(cacheOptions.buildingID, cacheOptions.apartmentNumber);
        BllinkLogger.info(`oneTimePayments from cache `, oneTimePayments)
        if (oneTimePayments){
            oneTimePayments = LastConfirmationPageLogic.selectedOnly(oneTimePayments);

            for (const payment of oneTimePayments){
                const boxName = constants.cache.keys.payments.oneTimeInstallmentsName(payment.building_one_time_payment_id);
                let numberOfInstallments = CacheData.fetchCache('installmentsChoices',
                    {key: boxName})
                BllinkLogger.info(`numberPayments ${numberOfInstallments} and boxName ${boxName}`);
                if (options.ignoreMissingInstallments && !numberOfInstallments){
                    BllinkLogger.info(`adding fake numberOfInstallments 1 so pass to server `)
                    numberOfInstallments = 1 // it's fake. ignored either way in offline payments
                }
                if (numberOfInstallments){
                    let payload = {
                        number_of_installments: parseInt(numberOfInstallments.selected),
                        building_one_time_payment_id: payment.building_one_time_payment_id
                    }
                    if (payment.part_pay && payment.part_pay.value){
                        payload.part_pay = {value: payment.part_pay.value, currency: payment.part_pay.currency}
                    }
                    result.push(payload)
                }

            }
        }
        BllinkLogger.info(`one result is `, result);
        return result


    }

    static selectedOnly(allMonths){

        return allMonths.filter(x => { return x.selected})
    }

    /**
     * @param options
     * @param {boolean} [bankAutoCharge=false]
     * @param {boolean} [isChargeCart=false]
     * */
    buildingPaymentPayload(options= {}, bankAutoCharge = false, isChargeCart = false){
        /*
            will collect all selected payments and send to server

            on going for example
            {
                start
                number_of_installments
                number_of_months_forward
            }

            debt for example
            months: [
                {month: 10, year: 2019},
                {month: 01, year: 2020},
                {month: 02, year: 2020},
            ],
            number_of_installments: 2,


         */
        console.log(`building payload with bankAutoCharge ${bankAutoCharge}`);

        let payload = {
            bankAutoCharge,
            isChargeCart,
            tenant_details: CacheData.fetchCache('tenantDetails')
        }
        const cacheOptions = {buildingID: this.buildingID, apartmentNumber: this.apartmentNumber};

        const onGoing = LastConfirmationPageLogic.getOnGoingPayload(cacheOptions);
        if (onGoing){
            payload.on_going_payments = onGoing;
        }

        const pastPayments = LastConfirmationPageLogic.getPastPayload(cacheOptions);
        if (pastPayments){
            payload.past_payments = pastPayments;
        }

        const oneTimePayments = LastConfirmationPageLogic.getOneTimePaymentsPayload(cacheOptions, options);
        if (oneTimePayments.length > 0){
            payload.one_time_payments = oneTimePayments;
        }

        if (this.isRewardCart(onGoing)){
            payload.rewardAmount = this.getRewardTotal(onGoing)
        }

        BllinkLogger.info(`payload for payment `, payload);

        return payload

    }

    isRewardCart(onGoing){
        if (onGoing){
            return onGoing.months &&
                onGoing.months.some(m => m.reward_pay)
        }
    }

    getRewardTotal(onGoing){
        if (onGoing){
            return onGoing.months &&
                onGoing.months.reduce((a, b) => a + ((b.reward_pay && b.reward_pay.rewardAmount) || 0), 0);
        }
    }

}
