export const managerAutoCampaign = {
    "activeCampaign": {
        "pageTitle": "Keep track on tenants active campaign",
        "tabs": {
            "activeCampaign": "Apt with active campaign",
            "outOfCampaign": "Apt out of campaign"
        },
        "totalTenants": "Total num of tenants:",
        "labels": {
            "campaignType": "Active campaign type",
            "campaignStep": "Campaign step",
            "nextStepIn": "Next step in",
            "nextStepType": "Next step type",
            "totalAmountMissing": "Total amount missing",
            "recommendedCampaign": "Recommended campaign",
            "days": "days",
            "of": "of"
        },
        "btns": {
            "stopCampaign": "Stop campaign",
            "startCampaign": "Start campaign",
            "aptNotes": "Apt notes"
        },
        "recommendedCampaign": "Bllink campaign",
        "successfully_added": "The apartment was successfully added to the campaign",
        "successfully_removed": "The apartment was successfully removed from the campaign"
    }
}
