import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CacheData from "../../../common/utils/cacheData";
import { constants } from "../../../common/constants/constants";
import { MenuItem, SubMenu } from "react-pro-sidebar";
import fees from "../../../images/newDesign/fees_one_time.svg";
import { isLinkActive } from "../../sidebar/Sidebar.shared";
import BllinkTime from "../../../common/utils/time";
import SpecialPaymentsSidebarMenu from "../specialPaymentsSidebarMenu";

/**
 * @param buildingId
 * @param navData
 * @return {JSX.Element|null}
 * @constructor
 */
export default function UpdatePaymentRatesSidebarMenu({buildingId, navData}) {
    const { t } = useTranslation();
    const currentYear = BllinkTime.getCurrentYear();

    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));

    return (
        <SubMenu
            title={t('buildings.nav_bar_payments_rates')}
            icon={<img src={fees} alt="fees and one time" />}
            className={[
                isLinkActive(['add-payment', 'manager/one-time', '/monthly-payments', 'collectionStatus'], {
                    part: true
                }) ? 'active mobile-hide' : 'mobile-hide'
            ]}
        >
            <MenuItem
                active={isLinkActive(`/${constants.pages.updateRates}/${localBuildingId}`, {
                    query: true
                })}
            >
                <NavLink
                    className="dropdown-link"
                    to={`/${constants.pages.updateRates}/${localBuildingId}`}
                >
                    {t('buildings.nav_bar_update_rates')}
                </NavLink>
            </MenuItem>
            <MenuItem
                active={isLinkActive(`/${constants.pages.monthlyPaymentSettings}/:buildingID/:year`)}
            >
                <NavLink
                    className="dropdown-link"
                    to={`/${constants.pages.monthlyPaymentSettings}/${localBuildingId}/${currentYear}`}
                >
                    {t('buildings.nav_bar_view_monthly')}
                </NavLink>
            </MenuItem>
            <SpecialPaymentsSidebarMenu buildingId={buildingId} navData={navData}/>
        </SubMenu>
    )
}
