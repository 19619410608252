export const premiumInfo = {
    title: 'Welcome to Blink',
    top: 'Exciting news: enhancing your Blink experience',
    offer: {
        general: 'On 01/01/2024 we are launching 2 routes in Blink:',
        freeTrack: {
            name: 'Free track:',
            description: 'Continue to enjoy the essentials with our free track. It\'s perfect for those who value simplicity and basic functionality.'
        },
        paidTrack: {
            name: 'Paid Track:',
            description: 'Take your experience to the next level with our rich paid track! Unlock the full potential of our platform with advanced capabilities, process automations and priority support.'
        }
    },
    advantages: {
        general: 'Why a paid route?',
        first: {
            title: '🌐 Full access to the system:',
            description: 'enjoy all the functions and reports that the system offers. Everything you need at your fingertips.'
        },
        second: {
            title: '🤖 Automations for processes:',
            description: 'optimize workflows and increase efficiency with our powerful automations, designed to save you time and effort.'
        },
        third: {
            title: '🚀 Preferred Support:',
            description: 'Need assistance? Premium users receive priority support to ensure your questions are answered quickly and efficiently.'
        }
    },
    buttons: {
        submit: 'Open the full system',
        cancel: 'Thanks, not right now',
        selection: 'Route selection',
    },
    subscriptions: {
        title: 'Choose the route that suits you',
        free: {
            title: 'Free',
            description: `Continue enjoying the essentials with our free route. It's perfect for those who value simplicity and basic functionality.`,
            price: '0 NIS / monthly'
        },
        premium: {
            title: 'Premium',
            description: `Take your experience to the next level with our rich paid route! Unlock the full potential of our platform with advanced capabilities, and process automations.`,
            price: 'NIS 25/monthly for the building'
        },
        points: {
            acceptDigitalPayments: 'Accepting digital payments',
            listOfTenants: 'List of tenants',
            updateRates: 'Update rates',
            monthlyPaymentReport: 'Monthly payment report',
            apartmentPaymentReport: 'Apartment payment report',
            fullReports: 'Full reports',
            paymentsDocumentation: 'Documentation of payments (checks, cash, direct bank)',
            messageSystem: 'Message system',
            automatedCampaign: 'Automated campaign',
            expenseManagement: 'Expense management',
            bookkeeping: 'Bookkeeping',
            documentArchive: 'Document archive',
            prizesAndGifts: 'Prizes and gifts',
        }
    },
    cancelModal: {
        title: 'Choose the route that suits you',
        description: `Please note, you can choose the appropriate route until {{closeDate}}.
Didn't have time to choose a route? We will update the "free" route and at any stage you can update to the "premium" route`,
        submit: 'Back to track selection',
        cancel: 'Continue to the system',
    }
};
