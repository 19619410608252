import {createSelector} from "@reduxjs/toolkit";
import BllinkTime from "../../common/utils/time";
// import {constants} from "../../common/constants/constants";

export function getChargeDate(chargeDateMin, chargeDateMax, chargeDay, onGoingPaymentsLength) {
    const max = new Date(chargeDateMax);
    const min = new Date(chargeDateMin);
    const today = new Date();
    let storedChargeDate = BllinkTime.getTimeZoneOffset(new Date(max.getFullYear(), max.getMonth(), chargeDay));
    if (storedChargeDate.getMonth() === today.getMonth() && storedChargeDate.getFullYear() === today.getFullYear()) {
        storedChargeDate = today;
    }
    if (onGoingPaymentsLength.length > 0 && (storedChargeDate > max || storedChargeDate < min)) {
        const day = chargeDay <= max.getDate() && chargeDay >= min.getDate() ? chargeDay : min.getDate();
        const calculated = new Date(min.getFullYear(), min.getMonth(), day);
        return calculated.toJSON();
    }
    return storedChargeDate.toJSON();
}

export function getChargeDateMax(payments, assignedToNextMonth) {
    if (payments.length === 0) {
        return new Date().toJSON();
    }
    const firstOngoing = payments.reduce((prev, next) => (prev.year > next.year && prev.month > next.month) ? next : prev);
    let endDate = BllinkTime.getTimeZoneOffset(new Date(Number(firstOngoing.year), Number(firstOngoing.month), 0));
    if (assignedToNextMonth) {
        // https://app.asana.com/0/1201421792207582/1201547769585958
        // "consider assigned to next month - change in the datepicker logic "
        const monthBefore = BllinkTime.getPreviousMonth({
            month: Number(firstOngoing.month),
            year: Number(firstOngoing.year),
            day: 1
        });
        endDate = BllinkTime.getTimeZoneOffset(new Date(monthBefore.year, monthBefore.month, 0));
    }
    return endDate.toJSON();
}

export const chargeDateMaxSelector = createSelector(
    [state => state.onGoingSelectedPayments, state => state.assignedToNextMonth],
    getChargeDateMax,
);


export const chargeDateMinSelector = createSelector(
    [chargeDateMaxSelector, state => state.onGoingSelectedPayments],
    (chargeDateMax, onGoingPayments) => {

        // NOTE: Use should be allowed to pay from the current day even the selected month is in future
        //       see https://bllink.atlassian.net/browse/SSB-1091
        const today = new Date();
        return today.toJSON();

        // if (onGoingPayments.length === 0) {
        //     return new Date().toJSON();
        // }
        // const max = new Date(chargeDateMax);
        // const startDate = BllinkTime.getTimeZoneOffset(new Date(max.getFullYear(), max.getMonth(), 1));
        // const today = new Date();
        // if (startDate.getMonth() === today.getMonth() && startDate.getFullYear() === today.getFullYear()) {
        //     return today.toJSON();
        // }
        // return startDate.toJSON();
    },
);

export const chargeDateSelector = createSelector(
    [chargeDateMinSelector, chargeDateMaxSelector, state => state.chargeDay, state => state.onGoingSelectedPayments.length],
    getChargeDate,
);

export const getIfAnyPaymentsSelected = createSelector(
    [state => state.tenant.onGoingSelectedPayments, state => state.tenant.debtSelectedPayments, state => state.tenant.oneTimeSelectedPayments],
    (onGoingSelectedPayments, debtSelectedPayments, oneTimeSelectedPayments) => {
        return !!onGoingSelectedPayments?.length || !!debtSelectedPayments?.length || !!oneTimeSelectedPayments?.length;
    },
);

export const isDisableInsurancePage = createSelector(
    [state => state.buildings?.currentBuildingFeatures?.disableInsurancePage],
    (disableInsurancePage) => {
        // requested by omri to close "waiting to close a deal with a delivery company"
        return true;
        // // opening by feature . the feature is reversed meaning disableInsurancePage true is enabling
        // const url = window.location.href;
        // const isCreditCardPage = url.includes(constants.pages.paymentDetailsUrl);
        // const isMobilePaymentsPage = url.includes(constants.pages.mobilePaymentPage);
        // const isCheckoutPage = url.includes(constants.pages.lastConfirmPaymentPageUrl);
        // const isRenewalPage = url.includes(constants.pages.planRenewalPage);
        // // doing this to exclude bank charge (since they take 15 min to setup and can't pay for additional products directly after)
        // const isRelevantPage = (isCreditCardPage || isMobilePaymentsPage || isCheckoutPage || isRenewalPage);
        // const disableInsurancePageFeature = disableInsurancePage === 'true'
        // const isEnabled = !disableInsurancePageFeature && isRelevantPage;
        // if (isEnabled) {
        //     console.log('insurance page is enabled for current building');
        // }
        // return !isEnabled;
        // return true;
        // return disableInsurancePage ?? false;
    },
);



