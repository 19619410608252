/**
 * @enum {string}
 * */
export const PaymentMethodName = Object.freeze({
    creditCards: 'creditCards',
    cheques: 'cheques',
    wires: 'wires',
    cash: 'cash',
    legacyPayment: 'legacyPayment',
    bankCharge: 'bankCharge',
    autoCheque: 'autoCheque',
    tenantRecord: 'tenantRecord',
    notSet: 'notSet',
    onlineDirectWire: 'onlineDirectWire',
    bit: 'bit',
    applePay: 'applePay',
    googlePay: 'googlePay',
    bllinkCheque: 'bllinkCheque',
});
