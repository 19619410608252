export const signup = {
    "signup": "הרשמה לשירות",
    "signin": "התחבר\/י",
    "username": "שם משתמש",
    "password": "סיסמא",
    "first_name": "שם פרטי",
    "last_name": "שם משפחה",
    "already_have_an_account": "כבר נרשמת?",
    "or_with": "או",
    "open_a_new_user": "פתיחת חשבון חדש",
    "open_a_new_user_sub_title": "פתיחת חשבון תאפשר לך לפתוח בניינים נוספים בהמשך תחת אותו חשבון",
    "open_a_new_user_with": "פתח חשבון באמצעות",
    "email_verify": "אימות דוא\"ל",
    "password_verify": "אימות סיסמא",
    "test": "test",
    "email": "אימייל",
    "phone": "מספר טלפון"
};
