export const searchPage = {
    "search_address": "Search building address",
    "search_address_sub_title": "For payment, Search building by inputing exact address",
    "full_address": "Building Address",
    "full_address_placeholder": "Input (Street, Building number, City)",
    "search": "Search",
    "quick_search": "Quick search",
    "searching": "Searching..",
    "entrance_number": "Your entrance number",
    "building_not_found": "Building not found",
};
