import Flash from "../../images/newDesign/flash.svg";

export const storeProductsDavid = [
    {
        icon: Flash,
        title: 'שירות צביעה',
        description: 'עוזבים את הדירה? רוצים לחדש את הקיר בסלון? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 1,
    },
    {
        icon: Flash,
        title: 'שירות ניקיון',
        description: 'נכנסתם לדירה חדשה? עשיתם שיפוץ בבית? הזמנתם אורחים שעשו קצת בלגאן? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 2,
    },
    {
        icon: Flash,
        title: 'מנעולן',
        description: 'נתקעתם מחוץ לדירה? איבדתם את המפתח הרזרבי? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 3,
    },
    {
        icon: Flash,
        title: 'תיקוני חשמל',
        description: 'יש לכם בעיות חשמל בדירה? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 4,
    },
    {
        icon: Flash,
        title: 'שירות אינסטלציה',
        description: 'יש לכם בעיות אינסטלציה? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 5,
    },
    {
        icon: Flash,
        title: 'שירות איטום',
        description: 'צריכים לבצע עבודת איטום בדירה? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 6,
    },
    {
        icon: Flash,
        title: 'שיפוץ לדירה',
        description: 'רוצים לחדש את סביבתם המגורים? תזמינו בקליק שירות מקצועי עד הבית ממי שאתם כבר מכירים.\n' +
            '\n' +
            'דוד ניהול ואחזקת מבנים',
        id: 7,
    }
]
