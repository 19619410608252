export const paymentMethods = {
    "1": "Credit Card",
    "2": "Cheque",
    "3": "Wire",
    "4": "Cash",
    "5": "Manually set", // Legacy Payment
    "6": "Bank Charge",
    "7": "Auto Cheque",
    "8": "Tenant Record",
    "9": "Not determined",
    "14": "cheque for bllink",
};
