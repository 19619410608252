import { MenuItem } from "react-pro-sidebar";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import list from "../../../images/newDesign/list.svg";
import React, { Fragment, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SideMenuTitle } from "../sideMenu/sideMenuTitle";
import { SideMenu } from "../sideMenu/sideMenu";
import { SideMenuItem } from "../sideMenu/sideMenuItem";
import { constants } from "../../../common/constants/constants";
import { toggleMobileMenuOpened } from "../../../store/common/slice";

/**
 * @param match
 * @return {JSX.Element}
 * @constructor
 */
export const BuildingsListSidebarMenu = ({match}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const menuRef = useRef();
    const sideMenuRef = useRef();
    const allBuildings = useSelector((state) => state.manager.buildings);

    /**
     * @param {MouseEvent} event
     * @param {string} buildingId
     */
    const onItemClick = (event, buildingId) => {
        event?.stopPropagation();
        event?.preventDefault();
        dispatch(toggleMobileMenuOpened());
        sideMenuRef.current.close();
        const currentBuildingId = match?.params?.buildingID;
        const url = match?.url;
        if (currentBuildingId && url && buildingId) {
            if (currentBuildingId === buildingId) return;
            const newUrl = url.replace(currentBuildingId, buildingId);
            history.push({ pathname: `${newUrl}` });
            window.location.reload();
        } else {
            history.push({ pathname: `/${constants.pages.buildingManagerProfileUrl}/${buildingId}` });
        }
    }

    const onAllBuildingsCLick = () => {
        dispatch(toggleMobileMenuOpened());
        sideMenuRef.current.close();
        history.push({ pathname: `/${constants.pages.allBuildingsUrl}` });
    }

    const sortedBuildings = useMemo(() => {
        return [...allBuildings.buildings || []].sort((a, b) => a.title >= b.title ? 1 : -1);
    }, [allBuildings])

    return (
        <Fragment>
            {allBuildings.buildings?.length ? <Fragment>
                <MenuItem icon={<img src={list} alt="buildings list"/>} ref={menuRef}>
                    <NavLink className="dropdown-link" to={{ pathname: '' }}>
                        {t('buildings.nav_bar_buildings_list')}
                    </NavLink>
                </MenuItem>
                <SideMenu container={menuRef.current} ref={sideMenuRef}>
                    <SideMenuTitle
                        label={t('buildings.nav_bar_buildings_list')}
                        icon={list}
                        onClick={onAllBuildingsCLick}/>
                    {sortedBuildings.map(building => {
                        return <SideMenuItem
                            key={building.buildingID}
                            url={`/${constants.pages.buildingManagerProfileUrl}/${building.buildingID}`}
                            label={building.title}
                            disableRouteAction={true}
                            onClick={(event) => onItemClick(event, building.buildingID)}/>
                    })}
                </SideMenu>
            </Fragment> : null}
        </Fragment>
    );
}
