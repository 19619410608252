export const navBar = {
    "headers": {
        "plans": "Plans",
        "buildingActs": "Building actions",
        "userActs": "User actions",
        "autoCampaign": "Auto campaign",
        "reports": "Reports",
        "editBuilding": "Edit building",
        "partners": "Partners"
    },
    "subheaders": {
        "searchPlans": "Search plans",
        "planActs": "Plan actions page",
        "planEdit": "Plan edit page",
        "buildAddress": "Building address",
        "openUser": "Open user",
        "actions": "Actions",
        "status": "Status",
        "reports": {
            "sms": "SMS report",
            "renew": "Renew report",
            "failed": "Failed report"
        },
        "editBuilding": "Edit building",
        "createEditPartner": "Create or edit a partner"
    }
};
