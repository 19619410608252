import {createSlice} from "@reduxjs/toolkit";


export const tenantProfileSlice = createSlice({
    name: 'tenantProfile',
    initialState: {
        accessToken: null,
        idToken: null,
        refreshToken: null,
    },
    reducers: {
        setToken(state, action) {
            state.accessToken = action.payload.AccessToken;
            state.idToken = action.payload.IdToken;
            state.refreshToken = action.payload.RefreshToken;
        },
        clearToken(state) {
            state.accessToken = null;
            state.idToken = null;
            state.refreshToken = null;
        },
    },
    extraReducers: (builder) => {
    },
});


export const {
    setToken,
    clearToken
} = tenantProfileSlice.actions;

export const reducer = tenantProfileSlice.reducer;
