export const chatBot = {
    "onboarding": {
        "onboardingBotTitle": "בלינק - פתיחת בניין מהירה",
        "welcomeMessageGuest": "שלום ברוכים הבאים לעמוד הקמת בניין. אשמח לעזור לך להקים בניין ב-5 דקות בלבד. אשמח להתחיל עם הכתובת המלאה של הבניין. רק הערה קטנה לפני שנתחיל, בהצטרפות לשירות את/ה מקבל את תנאי השירות שלנו👍",
        "continueOnBoardingForManager": "היי {{managerMame}}, שמנו לב שהתחלת ביצירת בניין בכתובת {{address}}. איזה פעולה נוספת היית רוצה לעשות בבניין?",

    },

    "legalBot": {
        "Title": "בוט משפטי בלינק",
        "welcomeMessageGuest": "ברוכים הבאים לבוט המשפטי להתייעצות ״בקטנה״. אני סורק מקורות מידע משפטיים ועונה בהתאם. כמובן שאין להסתמך על התשובה שלי כתחליף לייעוץ משפטי 🫶",
    },

    "csmTechnical": {
        "Title": "שאלה טכנית",
        "welcomeMessage": "שלום. שאל אותי שאלות טכניות. אני אשתדל לעזור כמה שאפשר ❤️",
    },

    "managersAssistants": {
        "Title": "עוזרת אישית למנהל/ת",
        "welcomeMessage": "שלום. אשמח לעזור בכל דרך אפשרית. ניתן לשאול אותי שאלות על בלינק וגם באפשרותי לבצע הפעולות. נא קח בחשבון שהתשובות מיוצרות בצורה אוטומטית לכן יתכנו טעויות מסוימות.  ❤️",
    },

    "common": {
        "waitingForResponse": "כמה שניות, חושב..",
        "thanksForReport": "תודה על הדיווח"
    }

}
