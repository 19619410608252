export const translatesLegalInfoPages = {
    accessibilityUrl: {
        default: 'https://bllink-public.s3.amazonaws.com/accesability-bllink.pdf',
        he: 'https://bllink-public.s3.amazonaws.com/accesability-bllink.pdf',
    },
    termsOfUseUrl: {
        default: 'https://bllink-public.s3.amazonaws.com/Terms_conditions(hebrew).pdf',
        he: 'https://bllink-public.s3.amazonaws.com/Terms_conditions(hebrew).pdf',
        es: 'https://bllink-public.s3.amazonaws.com/T%C3%A9rminos+de+Uso.pdf',
        en: 'https://bllink-public.s3.amazonaws.com/terms_of_use_bllink.pdf',
    },
    privacyUrl: {
        default: 'https://bllink-public.s3.amazonaws.com/privacy(hebrew).pdf',
        he: 'https://bllink-public.s3.amazonaws.com/privacy(hebrew).pdf',
        es: 'https://bllink-public.s3.amazonaws.com/privacy-SPANISH.pdf',
        en: 'https://bllink-public.s3.amazonaws.com/privacy_bllink.pdf',
    }
};
