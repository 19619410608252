import styled from "styled-components";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import * as Sentry from "@sentry/react";

export const NotFoundStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  h1 {
    font-variant: small-caps;
  }
`;
export function NotFound() {

    const {t} = useTranslation();

    useEffect(() => {
        Sentry.captureMessage('Page Not Found', 'error');
    }, []);

    return (
        <NotFoundStyled>
            <h1 className="bllink-heading1">{t('errors.notFound.404')}</h1>
            <div className="bllink-text">{t('errors.notFound.pageNotFound')}</div>
        </NotFoundStyled>
    )
}


