// import { useTranslation } from 'react-i18next';
import { constants } from '../constants/constants';
import { i18n } from '../translations';
import {NotificationManager} from "react-notifications";
import {Auth} from 'aws-amplify';
const HttpCodes = require('http-status-codes');


class ErrorsHandler{

    static handleServerErrors(responseStatus, error){
        /*
            error structure can be viewed at documentation at
            error.yaml

              - message
              - status
              - type
              - params (optional)
         */

        const errorType = error.type;
        let avoidError = true;
        let handleErrorByStatusResult = {}
        let errorMessage = null;

        console.error(`error full errorType ${errorType}`, error);
        console.error(`error params`, error.params);
        let params = error.params || {};
        params.message = params.message || error.message;
        switch(errorType) {
            // tenants
            case 'BuildingNotFound':
                alert(i18n.t(`errors.tenant.search.${errorType}`, {buildingName: error.params.buildingID}));
                avoidError = false;
                break;

            case 'ApartmentNotFound':
                alert(i18n.t(`errors.tenant.search.${errorType}`, {apartmentNumber: error.params.apartmentNumber}))
                break;


            case 'OnGoingPaymentTimeError':
            case 'PartPayIsNotAllowedForTenant':
            case 'PartPayIsNotAllowedForManager':
            case 'PaymentsInThePastError':
            case 'ExistingPaymentPlanRunning':
            case 'AlreadyPayedError':
            case 'DebtTooManyMonthsError':
            case 'FailedTokenHasNotFailed':
            case 'NoNewPlansWereCreated':
            case 'RenewalInProgress':
                alert(i18n.t(`errors.tenant.payments.${errorType}`))
                break;


            case 'SearchAddressIsNotCompleted':
                errorMessage = i18n.t(`errors.tenant.search.${errorType}`, {searchTerm: error.params.searchTerm});
                NotificationManager.info(errorMessage);
                break;


            case 'BuildingIsDisabled':
                errorMessage = i18n.t(`errors.buildings.${errorType}`);
                NotificationManager.error(errorMessage);
                window.setTimeout(() => window.location.href = '/', 3000);
                break;


            case 'BuildingIsNotActive':
                alert(i18n.t(`errors.buildings.${errorType}`))
                this.redirectToAllBuildings()
                break;


            // managers
            case 'DuplicateApartmentsInNewTenantsExcel':
                // todo handle with i18n and display a proper popup
                alert('Duplicate Apartments In Excel - ' + error.message)
                break;

            case 'ManagerNotAllowedToViewBuilding':
                alert(i18n.t(`errors.buildings.${errorType}`))
                this.redirectToAllBuildings()
                break;


            case 'ExternalSumDoesNotEqualTotalSum':
            case 'NewOneTimeDidntAddAnyNewApartmentPayments':
            case 'NewOneTimeMissingNumberOfInstallments':
            case 'NewOneTimeMissingTitle':
            case 'NoReceiptsForThisPartner':
            case 'NoAmountOrApartmentNumberFilled':
            case 'TerminalIsNotSupportedForRefund':
            case 'NotTimeToChargeYet':
            case 'SchemaValidation':
            case 'BankDetailsAlreadyExistsForBuilding':
                alert(i18n.t(`errors.managers.${errorType}`, params))
                break;

            case 'UserNotFound':
                alert(i18n.t(`errors.managers.${errorType}`, params))
                // clear the endless redirect loop by logout the user.
                // due to a bug where users logged in with the wrong gmail account
                Auth.signOut();
                window.location.href = `/${constants.pages.loginUrl}`
                break;

            case 'NotFullAddress':
                alert(i18n.t(`errors.buildings.${errorType}`))
                break;


            case constants.network.serverNetworkError:
                // todo handle with i18n and display a proper popup
                alert('we are currently having network trouble. please check your connection or try again in a few minutes');
                break;

            //rewards


            case 'ClientError':
                const key = `errors.clientError.${error.key}`;
                let message;
                try {
                    message = i18n.t(key, error.params);
                } catch (e) {
                    message = error.message;
                }
                // skip error for specific error keys
                avoidError = ['PHONE_NOT_MATCH', 'CANT_PARSE_BANK_FILE'].indexOf(error.key) > -1;
                const greenError = ['DEMO_USER_CREATED'].indexOf(error.key) > -1;
                const shouldReload = ['DEMO_USER_CREATED'].indexOf(error.key) > -1;
                if (greenError){
                    NotificationManager.success(message);
                }else{
                    NotificationManager.error(message);
                }

                // specific errors handling:
                if (error.key === 'BUILDING_IS_DISABLED'){
                    window.setTimeout(() => window.location.href = '/', 3000);
                }

                if (shouldReload){
                    window.setTimeout(() => window.location.reload(), 3000);

                }
                break;

            default:
                // handle by statuses
                avoidError = false
                let resolved = ErrorsHandler.handleByMessage(params);
                if (!resolved) {
                    handleErrorByStatusResult = ErrorsHandler.handleByStatus(responseStatus);
                }
        }

        return {
            error: true,
            errorMessage,
            avoidError: handleErrorByStatusResult.avoidError || avoidError,
        }


    }

    static handleByMessage(params){
        const message = params.message;

        if (message.includes('timeout')){
            NotificationManager.error(i18n.t(`errors.general.timeout`))
            return true
        }

    }


    static handleByStatus(responseStatus){
        let avoidError = false;
        switch(responseStatus) {
            case HttpCodes.INTERNAL_SERVER_ERROR:
                alert('שגיאה באתר. אנא נסה שוב');
                break;

            case HttpCodes.BAD_REQUEST:
                // code block
                alert(i18n.t(`errors.general.generalError`));
                break;

            case HttpCodes.NOT_FOUND:
                // code block
                alert(i18n.t(`errors.general.generalError`));
                break;

            case HttpCodes.FORBIDDEN:
                // no need for an error message at this point, just redirect to login (example taken from asana) - decided by omri at 18/10/20
                window.location.href = `/${constants.pages.loginUrl}`
                avoidError = false;
                break;

            case HttpCodes.UNAUTHORIZED:
                // no need for an error message at this point, just redirect to login (example taken from asana) - decided by omri at 18/10/20
                window.location.href = `/${constants.pages.loginUrl}`
                avoidError = true;
                break;

            case HttpCodes.REQUEST_TOO_LONG:
                // code block
                alert(i18n.t(`errors.general.uploadError`));
                break;

            case 'Network error':
                alert(i18n.t(`errors.networkError`));
                break;

            default:
                alert(i18n.t(`errors.general.generalError`));
                break;
        }


        const response =  {
            avoidError: avoidError,
        }
        console.log(`Error handler avoidError ${avoidError}: ${JSON.stringify(response)}`, response);

        return response

    }

    static redirectToAllBuildings(){
        window.location.href = `/${constants.pages.allBuildingsUrl}`
    }

    /**
     * @param {AxiosError | Error} error
     * */
    static getErrorMessage(error) {
        const data = error.response?.data ?? {};
        let message = '';
        if (data.key != null) {
            message = i18n.t(`errors.clientError.${data.key}`, data.params);
        } else if (data.message != null) {
            message = data.message;
        } else if (Array.isArray(data.errors)) {
            message = i18n.t(`errors.clientError.REQUEST_VALIDATION_ERROR`);
        } else {
            message = error.message;
        }
        if (message === '') {
            message = i18n.t(`errors.clientError.UNEXPECTED_ERROR`);
        }
        return message;
    }

    /**
     * @param {AxiosError | Error} error
     * */
    static notifyClientError(error) {
        console.error(error);
        let message = this.getErrorMessage(error);
        NotificationManager.error(message, '', 5000)
    }
}


export default ErrorsHandler;
