import { constants } from '../../constants/constants';
import {lazyReload} from "../../lazyReload";
const SearchPaymentPlan = lazyReload(() => import("../../../../admin/searchPaymentPlan/SearchPaymentPlan"));
const ResultTable = lazyReload(() => import("../../../../admin/searchPaymentPlan/ResultTable/ResultTable"));
const PlanActions = lazyReload(() => import("../../../../admin/searchPaymentPlan/PlanActions/PlanActions"));
const PlanActionsScreens = lazyReload(() => import("../../../../admin/searchPaymentPlan/PlanActionsScreens/PlanActionsScreens"));
const AutoCampaignActions = lazyReload(() => import("../../../../admin/autoCampaign/autoCampaignActions/AutoCampaignActions"));
const AutoCampaignStatus = lazyReload(() => import("../../../../admin/autoCampaign/autoCampaignStatus/AutoCampaignStatus"));
const SmsReports = lazyReload(() => import("../../../../admin/reports/SmsReports/SmsReports"));
const RenewReports = lazyReload(() => import("../../../../admin/reports/RenewReports/RenewReports"));
const FailedReports = lazyReload(() => import("../../../../admin/reports/FailedReports/FailedReports"));
const EditBuilding = lazyReload(() => import("../../../../admin/editBuilding/EditBuilding"));
const UserActionsPage = lazyReload(() => import("../../../../admin/userActions/UserActionsPage"));
const CreateEditPartner = lazyReload(() => import("../../../../admin/createEditPartner/CreateEditPartner"));
const AdminsChat = lazyReload(() => import("../../../chat/adminsChat"));

export const AdminRoutes = [
    {path: constants.pages.admin.searchPaymentsUrl, component: SearchPaymentPlan, exact: true, title: 'searchPayments.mainHeader'},
    {path: `${constants.pages.admin.searchPaymentsUrl}/resultTable`, component: ResultTable, exact: true, title: 'searchPayments.mainHeader'},
    {path: `${constants.pages.admin.searchPaymentsUrl}/planActions/:planID`, component: PlanActions, exact: true},
    {path: `${constants.pages.admin.searchPaymentsUrl}/planActionsScreens/:planID`, component: PlanActionsScreens, exact: true, title: 'searchPayments.planScreensHeader'},
    {path: `${constants.pages.admin.autoCampaign}/actions`, component: AutoCampaignActions, exact: true, title: 'autoCampaign.actions.title'},
    {path: `${constants.pages.admin.autoCampaign}/status`, component: AutoCampaignStatus, exact: true, title: 'autoCampaign.status.title'},
    {path: `${constants.pages.admin.reports}/sms`, component: SmsReports, exact: true, title: 'navBar.subheaders.reports.sms'},
    {path: `${constants.pages.admin.reports}/renew`, component: RenewReports, exact: true, title: 'adminReports.titles.renew'},
    {path: `${constants.pages.admin.reports}/failed`, component: FailedReports, exact: true, title: 'navBar.subheaders.reports.failed'},
    {path: `${constants.pages.admin.editBuilding}/:buildingID`, component: EditBuilding, exact: true},
    {path: `${constants.pages.admin.userActions}`, component: UserActionsPage, exact: true, title: 'userActions.title'},
    {path: `${constants.pages.admin.partners}`, component: CreateEditPartner, exact: true, title: 'createPartner.titles.page'},
    {path: `${constants.pages.admin.chat}/:entityType?/:entityValue?`, component: AdminsChat, exact: true, title: 'createPartner.titles.chatAdmins'},
];
