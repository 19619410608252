export const store = {
    storePageTitle: 'Sending an inquiry and receiving a quote',
    buttons: {
        request: 'Request for service',
        showMore: 'Show more',
        hide: 'Hide',
    },
    storeItem: {
        addDescription: 'It is advisable to add a description to the requested service',
        dateSelector: 'What is the preferred time to receive the service?',
        phoneNumber: 'What number can I call you back?',
        sendRequest: 'Send a request'
    },
    emailSuccess: {
        title: 'Thank you!',
        description: 'We have sent your request to the building manager. He will contact you as soon as possible to coordinate receiving the service and provide additional details',
        button: 'Thanks'
    }
}