export const paymentSettings = {
    "yearly_payment_settings_title": "Monthly rates {{year}}",
    "maxNumberOfInstallments": "Max number of installments",
    "oneTimeTitle": "One time payment - {{nameOfPayment}}",
    "monthlyYear": "Monthly payments {{year}}",
    "oneTime": "New one time payment",
    "uploadExplain": "Download the template and after inputin the details you can upload the file",
    "downloadFile": "Download",
    "uploadFile": "Upload",
    "freezeOneTime": "Freeze payment",
    "unfreezeOneTime": "Unfreeze payment",
    "addNew": "Add new payment",
    "installments": "Number of installments",
    "note": "Add note",
    "createPayment": "Create payment",
    "oneTimeSuccess": "Successfully created! Number of payments - {{payments}}",
    "oneTimeError": "Something happened while creating a new payment",
    "dragOrClickToUpload": "Drag pdf file here or click to open dialog",
    "recordOffline": "Record offline payment for building",
    "amountHeader": "Amount",
    "method": "Payment method",
    "totalPayments": "{{num}} payments of total {{amount}} ILS for apartment {{apartmentNum}}",
    "dismiss": "Dismiss",
    "notifications": {
        "uploadFailure": "Cannot upload pdf",
        "approveSuccess": "Payments were approved!"
    },
    "paidMonths": "List of actual payments",
    "openedMonths": "List of opened payments",
    "overpay": "There is no opened payments for this apartment or you have overpaid. Check table with opened payments",
    "noOpenedMonths": "There is no relevant opened payments for this apartment",
    "paymentState": "Payment status",
    "active": "Active",
    "inactive": "Inactive",
    "noAmountToUpdate": "No one amount defined to update",
    "updatedMonthlySuccessfully": "Payments have been successfully updated",
    "uploadedMonthlySuccessfully": "Payments have been successfully updated for years {{year}} and beyond",
    "buildingFeesSuccessfully": "The building rates have been updated successfully, if there are activities they will be updated as well. Please verify in the annual report.",
    "uploadedOneTimeSuccessfully": "One-time payment {{name}} successfully added",
    "uploadedMonthlyNoUpdatedRows": "Payments for the selected file have not been updated",
    "uploadedMonthlyError": "Error uploading data file. Please send the file to -",
    "uploadHere": "Drag the file to upload the details",
    "name": "Enter name",
    "amount": "Enter amount",
    "month": "Month",
    "year": "Year",
    "letsStartSettingsPayments": "Let's start settings payments",
    "fillDetailsToCollectPrize": "Choose Prize",
    "monthSelectionInfoPopUpText": "You can select/change the months to pay by clicking on the month and updating the payment selection. Please note - Payment via Bllink does not take up your credit limit.",
    "monthSelectionInfoPopUpText_pay": "Pay",
    "dontShowThisMessageAgain": "Don't show this message again",
    "updateMonthlyRates": "Update monthly rates",
    "fixedAmount": "Fixed amount",
    "variableAmount": "Changing amount",
    "updateFixedAmount": "Update fixed monthly rates for all apartments. You can update the amount or update an addition to an existing amount within a selected date range",
    "amountUpdate": "Update amount",
    "addToExistingRate": "Add the amount to the existing rate",
    "startingFrom": "Starting from",
    "upTo": "Until",
    "update": "Update",
    "updateFlexibleAmount": "Update changing monthly rates for all apartments. You can update the amount or update an addition to an existing amount within a selected date range",
    "excelUpdate": "Update from Excel",
    "manualUpdate": "Manual update",
    "dragAndDrop": "Drag & Drop Bank File",
    "exampleExcel": "Excel for example",
    "copied": "Copied!",
    "provideDate": "Please provide a date range",
    "negativeAmount": "Negative Amounts are not allowed",
};
