import {createAsyncThunk} from "@reduxjs/toolkit";
import ServerData from "../../common/serverData";
import CacheData from "../../common/utils/cacheData";
import {constants} from "../../common/constants/constants";
import {BllinkLogger} from "../../common/utils/bllink_loggers";


export const fetchManagerBuildings = createAsyncThunk(
    'managers/fetchBuildings',
    async (params, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            let client = new ServerData(null, null, {accessToken: accessToken});
            const response = await client.managers.buildings.getAllManagerBuildings();
            return response;
        } catch (e) {
            return rejectWithValue({});
        }
    }
);

export const fetchPartnerFeatures = createAsyncThunk(
    'manager/fetchPartnerFeatures',
    async ({ignoreCache, partnerId}, {getState, rejectWithValue}) => {
        try {
            const state = getState();
            const accessToken = state.auth.accessToken;
            if(ignoreCache) {
                CacheData.delete(constants.cache.keys.managers.partnerFeatures, {partnerId});
            }
            const featureMap = await CacheData.getOrCache(async () => {
                    const client = new ServerData(null, null, {accessToken: accessToken});
                    return await client.partners.getPartnerFeatures(partnerId);
                },
                constants.cache.keys.managers.partnerFeatures,
                { partnerId }
            );
            return featureMap;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getPartnerPaymentDetails = createAsyncThunk(
    'manager/getPartnerPaymentDetails',
    async ({ignoreCache, partnerId}, {getState, rejectWithValue}) => {
        const state = getState();
        const accessToken = state.auth.accessToken;
        const partnerID = partnerId ?? state.auth.userProfile?.partnerID;
        let client = new ServerData(null, null, {accessToken: accessToken});

        try {
            if (!partnerID || !client) {throw new Error(`Error on manager/getPartnerPaymentDetails with partnerId: ${partnerID}`)}
            if(ignoreCache) {
                CacheData.delete(constants.cache.keys.managers.partnerPaymentDetails, {partnerId: partnerID});
            }
            const response = await CacheData.getOrCache(async () => client.partners.getPartnerPaymentDetails(partnerID), constants.cache.keys.managers.partnerPaymentDetails, {partnerId: partnerID});
            BllinkLogger.info(`partner payment details response `, response);
            return response;
        } catch (e) {
            BllinkLogger.error(e.message);
            return rejectWithValue({});
        }
    }
);
