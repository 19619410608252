import { constants } from '../constants/constants';
import BllinkUtils from '../utils/utils';
import {BllinkLogger} from '../utils/bllink_loggers';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';
import { colors } from "../ui/globalColors";
import {GAEvent} from "../google_analytics/google_analytics";
import { NotificationManager } from "react-notifications";

const states = constants.APIContract.paymentStates;

const getSelectMonthForDropdown = (year, t) => {
    const yearInt = parseInt(year);
    let monthsTexts = [];
    let years = [yearInt - 1, yearInt, yearInt + 1];
    years.forEach((yearVar) => {
        monthsTexts = monthsTexts.concat(Object.keys(constants.MonthsToText).map(monthInt=>  {
            return {
                value: `${monthInt}_${yearVar}`, label: t(`common.months.${monthInt}`) + ' ' + yearVar}
            })
        );
    });

    return monthsTexts;
};

const getRowProps = (row) => {
    const color = row.original.Amount < 0 || constants.APIContract.paymentStates.CANCELED_PAYMENT_STATUSES.includes(row.original.PaymentState) ? colors.additionalColors.coral : '';
    return {
        style: {
            background: color
        }
    };
}

const sortApartmentNumbersForTable = (a, b) => {
    const is_A_NegativeAmount = parseInt(a.Amount) < 0 ;
    const is_B_NegativeAmount = parseInt(b.Amount) < 0 ;
    // negative should be first
    if (is_A_NegativeAmount) {
        return -1
    } else if (is_B_NegativeAmount) {
        return 1
    } else {
        return parseInt(a.ApartmentNumber) <  parseInt(b.ApartmentNumber) ? -1 : 1;
    }
};

const sortApartmentNumbers = (a, b) => {
    const A_startsWithANumber = (a.match(/^\d/));
    const B_startsWithANumber = (b.match(/^\d/));
    if (!A_startsWithANumber) {
        return 1;
    }
    if (!B_startsWithANumber) {
        return -1;
    }

    a = parseInt(BllinkUtils.Strings.keepNumbersOnly(a));
    b = parseInt(BllinkUtils.Strings.keepNumbersOnly(b));

    return a - b;
};

const isDemoAccount = buildingID => {
    const ids = Object.values(constants.countriesDemoBuildings).flat();
    return (ids.includes(buildingID));
};

const isDemoApartment = (buildingID, apartmentNumber) => {
    // https://bllink.atlassian.net/browse/SSB-1196 "Add 2 apt"
    return isDemoAccount(buildingID)  && ['1', '10'].includes(apartmentNumber)
};

const  getFakePaymentData = async (buildingID, apartmentNumber) => {
    // https://bllink.atlassian.net/browse/SSB-1196 "Add 2 apt"
    const fileName = constants.getDemoBuildingMockPaymentsDataFileName(buildingID);
    const module = await import(`../../common/mocks/data/${fileName}`);
    // todo use dynamic months based on current month
    return module.default;
};

const isDemoPartnerAccount = partnerID => {
    const ids = Object.values(constants.countriesDemoPartners).flat();
    return (ids.includes(partnerID));
};

const isAllowedToEdit = managerRole => {
    if (!managerRole) return false;

    return constants.APIContract.managerRoles.editorRoles().includes(managerRole);
}

const createPdfFromHtml = async (selector, fileName, {save = true, ignoreLastPage = false} = {}) => {
    // load dynamically to exclude from common bundle
    const module = await import('jspdf');
    const { default: html2canvas } = await import('html2canvas');
    const jsPDF = module.jsPDF;
    const canvas = await html2canvas(selector);
    const dataUrl = canvas.toDataURL('image/jpeg');
    const imgWidth = 210;
    const pageHeight = 295;
    const doc = new jsPDF('p', 'mm', 'a4', true);
    const imgProps = doc.getImageProperties(dataUrl);
    const imgHeight = imgProps.height * imgWidth / imgProps.width;
    let heightLeft = imgHeight;
    let position = 0;
    doc.addImage(dataUrl, 'JPEG', 0, position, imgWidth, imgHeight, undefined,'FAST');

    heightLeft -= pageHeight;

    if (!ignoreLastPage) {
        while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(dataUrl, 'JPEG', 0, position, imgWidth, imgHeight, undefined,'FAST');
            heightLeft -= pageHeight;
        }
    }

    if (save) {
        doc.save(removeTagsAndDecimalsFromFileName(fileName));
    } else {
        return doc.output('blob');
    }
};

/**
* @name ImageProps
* @typedef {{
*     dataUrl: string
*     imgHeight: number
*     imgWidth: number
*  }}
*/

/**
 * @param {string} selector
 * @param {'p' | 'l'} orientation
 * @returns {ImageProps}
 */
const createImageForPdf = async (selector, orientation = 'p') => {
    // load dynamically to exclude from common bundle
    const module = await import('jspdf');
    const { default: html2canvas } = await import('html2canvas');
    const jsPDF = module.jsPDF;
    const doc = new jsPDF(orientation, 'mm', 'a4', true);

    const canvas = await html2canvas(selector);
    const dataUrl = canvas.toDataURL('image/jpeg');

    const imgProps = doc.getImageProperties(dataUrl);

    return {
        dataUrl,
        imgWidth: imgProps.width,
        imgHeight: imgProps.height,
    };
}

/**
 * @param {ImageProps[]} images
 * p = portrait (vertical), l = landscape (horizontal)
 * @param {"p" | "l"} orientation
 * @returns
 */
const createPdfFromImages = async (images, orientation = 'p') => {
    const module = await import('jspdf');
    const jsPDF = module.jsPDF;

    const imgWidth = orientation === 'p' ? 210 : 297;

    const doc = new jsPDF(orientation, 'mm', 'a4', true);

    for (let i = 0; i < images.length; i++) {
        const imgHeight = images[i].imgHeight * imgWidth / images[i].imgWidth;
        doc.addImage(images[i].dataUrl, 'JPEG', 0, 0, imgWidth, imgHeight);
        if (i+1 !== images.length) doc.addPage();
    }

    return {url: doc.output('dataurlstring'), blob: doc.output('blob')};
}

const savePdf = async (fileName) => {
    const printSection = document.querySelector('#section-to-print');
    // Create new node t apply styles and append in to body silently(need it to generate an image)
    const printHtml = document.createElement('div');
    printHtml.classList.add('pdf-download-section');
    printHtml.appendChild(printSection.cloneNode(true));
    document.body.appendChild(printHtml);

    await createPdfFromHtml(document.querySelector('.pdf-download-section>#section-to-print'), fileName);

    document.body.removeChild(printHtml);
};

function calcOnetimeTextForSelectBox(openOneTimePayments) {
    return openOneTimePayments
        .reduce((acc, curVal, index) => `${acc} ${curVal.payment_description}${index < openOneTimePayments.length - 1 ? ',' : ''}`, '');
}

const calcMonthsTextForSelectBox = selectedMonths => {
    /*
        calcMonthsText - array
     */
    // if consecutive, render the range
    // if not render al months with space between each one
    BllinkLogger.info(`selectedMonths `, selectedMonths);
    const timeHelper = BllinkUtils.BllinkTime;

    let arrForSort = cloneDeep(selectedMonths)
    arrForSort.sort();
    arrForSort = timeHelper.sortMonths(arrForSort);
    const isConsecutive = timeHelper.isConsecutive(arrForSort);
    BllinkLogger.info(`isConsecutive result ${isConsecutive}`);

    if (isConsecutive) {
        const firstMonth = timeHelper.renderShortDate(arrForSort[0]);
        const lastMonth = timeHelper.renderShortDate(arrForSort.slice(-1)[0]);
        return `${firstMonth} - ${lastMonth}`;
    } else {
        let result = ''
        for (const month of arrForSort) {
            result += timeHelper.renderShortDate(month) + ' ';
        }

        return result;
    }
};

const shortenText = commentOriginalText => {
    if (!commentOriginalText) return commentOriginalText;

    if (commentOriginalText.length > constants.longCommentCutOff) {
        return {
            originalComment: commentOriginalText,
            shortComment: commentOriginalText.substring(0, constants.longCommentCutOff) + constants.longCommentPostfix,
        };
    }

    return {originalComment: commentOriginalText, shortComment: commentOriginalText};
}

// https://bllink.atlassian.net/browse/SSB-2100 Payment status comment
const getPaymentCommentConsideringStatus = (originalComment, paymentStatus, t) => {
    let postfix = '';
    if (states.REFUNDED_PAYMENT_STATUSES.includes(paymentStatus)) {
        postfix = t('reports.refund.refund');
    }else if (states.CANCELED_CHARGEBACK_PAYMENT_STATUSES.includes(paymentStatus)){
        postfix = t('reports.refund.chargeback');
    }
    if (originalComment && postfix){
        postfix = ' - ' + postfix;
    }
    return (originalComment || '') + postfix;


}

const getYearsOptions = () => {
    let currentYear = BllinkUtils.BllinkTime.getCurrentYear();
    let yearsOptions = []
    for (let i = 3; i >= -1; i--) {
        yearsOptions.push({
            label: currentYear - i,
            value: currentYear - i
        });
    }
    return yearsOptions;
};

// https://app.asana.com/0/1200855539606174/1200977678822850
// Some translations contain html tags and there are titles with decimals. Had to remove it not to break file
const removeTagsAndDecimalsFromFileName = filename => {
    return filename.replace(/(&nbsp;|<([^>]+)>)/ig, '').replace(/\.[\d]+$/g, '');
};

const isEditableTableValid = (tableData) => {
    const rowResults = tableData.map(row => Object.values(row.errors).every(item => item.valid));
    BllinkLogger.info(`table errorList `, tableData.map(row => Object.values(row.errors)));
    return rowResults.every(item => !!item);
}

const getBase64 = url => {
    if (!url) return '';
    return axios
        .get(url, {
            responseType: 'arraybuffer',
            crossdomain: true
        })
        .then(response => Buffer.from(response.data, 'binary').toString('base64'))
        .catch(() => '')
}

/**
 * @param {import('i18next').TFunction} t
 * @param {object|null} addressObj
 * @param {string} addressObj.entrance
 * @param {string} addressObj.city
 * @param {string} [addressObj.nickname]
 * @param {string} addressObj.street
 * @param {string} addressObj.streetNumber
 * */
const getBuildingAddress = (t, addressObj, options = {}) => {
    if (!addressObj) return '';
    const { apartmentNumber } = options;
    const hasEntrance = addressObj.entrance;
    const apartmentText = apartmentNumber ? t('payments.apartmentText', { apartmentNumber }) : '';
    const data = {
        city: addressObj.city,
        street: addressObj.nickname || addressObj.street,
        streetNumber: addressObj.nickname ? '': addressObj.streetNumber,
        entrance: addressObj.entrance,
        apartmentText,
        translationKey:  hasEntrance ? 'address_with_entrance' : 'address_without_entrance'
    }

    return t('payments.' + data.translationKey, data);
}

/**
 * @param {DepositedPayment} payment
 * @return {string}
 */
const getPaymentComment = (payment) => {
    if (!payment) return '';
    let comment = '';
    if (payment.months && payment.months?.length > 0) comment = shortenText(calcMonthsTextForSelectBox(payment.months));
    else if (payment.oneTime) {
        comment = shortenText(payment.oneTime?.PaymentSettings?.Description?.description || payment.oneTime?.description);
    }
    return comment?.shortComment || '';
};

const getLongPaidForComment = (record) => {
    return record?.months && record.months?.length > 0 ?
        calcMonthsTextForSelectBox(record?.months) : record?.oneTime?.PaymentSettings?.Description?.description;
};

const getLongAndShortCommentPaidFor = (record) => {
    const shortComment = getPaymentComment(record);
    const longComment = getLongPaidForComment(record);
    const shouldShowTooltip = longComment?.length !== shortComment?.length;

    return {shortComment, longComment, shouldShowTooltip};
}

/**
 *
 * @param {number} amount
 * @param {string} cartID
 * @param {string|null} eventLabel
 * @param {string|null} paymentMethod
 * @param {boolean|false} isChargeCart
 * @param {boolean|false} isFuturePayment
 * @param {boolean|false} isLoan
 */
const sendSuccessPaymentToTagManager = ({amount, cartID,
                                            eventLabel = null,
                                            paymentMethod=null,
                                            isChargeCart = false,
                                            isFuturePayment = false,
                                            isLoan = false}) => {
    const labelName = eventLabel || constants.GoogleAnalytics.Labels.creditCard.bllinkUI;
    GAEvent(constants.GoogleAnalytics.Events.creditCard, 'SuccessCharge', amount, labelName, {
        isChargeCart,
        isFuturePayment,
        isLoan
    });
    GAEvent(constants.GoogleAnalytics.Events.purchase, 'purchase', {
        // This purchase event uses a different transaction ID
        // from the previous purchase event so Analytics
        // doesn't deduplicate the events.
        // Learn more: https://support.google.com/analytics/answer/12313109
        // todo this is not supported in purchase event. figure out how to send it to ga
        // payment_type: paymentMethod,
        transaction_id: cartID,
        value: amount,
        tax: 0,
        shipping: 0,
        currency: "ILS", // todo use currency
        // coupon: "TEST"
    }, 'purchase');

}

const copyToClipboard = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
};

/**
 * on mobile only
 */
const shareToSocial = async ({title, text, url}, t) =>{
    title = title || 'בלינק - תשלומי ועד בית אוטומטיים';
    text = text || 'אני משתמש/ת בבלינק לתשלומי ועד הבית וממליץ לך לבדוק אותם. הרשמה לחשבון דמו בחינם ובקליק אחד';
    url = url || 'https://bit.ly/493PQ8d';
    const textToCopy = title + '\n' + text + '\n' + url;
    await copyToClipboard(textToCopy);

    if (navigator.share) {
        try {
            await navigator.share({
                title, text, url
            });
            console.log('Data successfully shared');
        } catch (err) {
            console.error('Error sharing the data', err);
        }
    } else {
        // Fallback for browsers that do not support the Web Share API
        console.log('Web Share API not supported.');
        NotificationManager.success(t('footer.copied_to_clipboard'), '', 6000);
    }
}


/**
 * designed for additional products offered to tenant after order experiment
 * started with insurance offer and now we are experimenting with pizza and flowers
 */
const afterPaymentPage = () => {
    const isFlowersUrl = true; //BllinkUtils.JsExtras.getRandomBoolean();
    return isFlowersUrl ? `${constants.pages.additionalOfferFlowers}` : `${constants.pages.additionalOffer}`
}

export {
    getLongAndShortCommentPaidFor,
    getSelectMonthForDropdown,
    sortApartmentNumbersForTable,
    sortApartmentNumbers,
    isDemoAccount,
    isDemoApartment,
    getFakePaymentData,
    savePdf,
    isAllowedToEdit,
    shortenText,
    calcOnetimeTextForSelectBox,
    calcMonthsTextForSelectBox,
    getYearsOptions,
    removeTagsAndDecimalsFromFileName,
    isEditableTableValid,
    createPdfFromHtml,
    createImageForPdf,
    createPdfFromImages,
    getBase64,
    isDemoPartnerAccount,
    getBuildingAddress,
    getRowProps,
    getPaymentComment,
    getLongPaidForComment,
    getPaymentCommentConsideringStatus,
    sendSuccessPaymentToTagManager,
    shareToSocial,
    afterPaymentPage,
};
