export const editBuilding = {
    "titles": {
        "page": "ערוך בניין",
        "assignTo": "שייך ל",
        "updateManager": "עדכן פרטי מנהל/ת",
        "commission": "קבעי או עדכני עמלה"
    },
    "labels": {
        "partner": "חברת ניהול",
        "user": "שם משתמש",
        "email": "אימייל",
        "phone": "טלפון",
        "managerFirstName": "מנהל שם פרטי",
        "managerLastName": "מנהל שם משפחה",
        "tenant": "דייר/ת",
        "building": "בניין",
        "managementCompany": "חברת ניהול"
    },
    "commissionError": "סה״כ עמלה צריכה להיות גדולה מ-1 אחוז",
    "btns": {
        "assign": "שייך",
        "updateCreate": "עדכן",
        "setUpdate": "עדכן"
    },
    "errors": {
        "phone": "טלפון לא תקין",
        "email": "אימייל לא תקין"
    },
    "success": {
        "manager": "בניין שוייך בהצלחה",
        "managerDetails": "פרטי מנהל עודכנו בהצלחה",
        "commissionDetails": "פרטי עמלה עודכנו בהצלחה"
    }
};
