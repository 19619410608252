import {setSidebarCollapsed, toggleMobileMenuOpened, toggleSidebarPinned} from "../../store/common/slice";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {Menu, SidebarContent, SidebarHeader} from "react-pro-sidebar";
import {SidebarStyled} from "./sidebar.styled";
import {constants} from "../../common/constants/constants";
import logoIcon from "../../images/newDesign/logo.svg";
import pinnedIcon from "../../images/newDesign/pinned.png";
import unpinnedIcon from "../../images/newDesign/unpinned.png";
import sidebarBg from "../../images/newDesign/sidebar_bg.svg";
import sidebarBgSmall from "../../images/newDesign/sidebar_bg_small.svg";
import {PartnerMaintenanceSubMenu} from "./PartnerMaintenanceSubMenu";


export function MaintenanceSidebar() {

    const dispatch = useDispatch();

    const isRtl = useSelector(state => !state.common.ltrEnabled);
    const mobileMenuOpened = useSelector(state => state.common.mobileMenuOpened);
    const sidebarCollapsed = useSelector(state => state.common.sideBarCollapsed);
    const sidebarPinned = useSelector(state => state.common.sideBarPinned);
    const [toggled, setToggled] = useState(false);
    const [pinned, setPinned] = useState(sidebarPinned);

    useEffect(() => {
        if (mobileMenuOpened) dispatch(setSidebarCollapsed(false));
        setToggled(mobileMenuOpened);
    }, [mobileMenuOpened])

    useEffect(() => {
        if (pinned !== sidebarPinned) setPinned(sidebarPinned);
    }, [sidebarPinned])

    function handleMouseEnter() {
        if (!pinned) {
            dispatch(setSidebarCollapsed(false));
        }
    }

    function handleMouseLeave() {
        if (!pinned) {
            dispatch(setSidebarCollapsed(true));
        }
    }

    function handleToggle() {
        dispatch(toggleMobileMenuOpened())
    }

    function handleMobileClick() {
        if (mobileMenuOpened) {
            dispatch(toggleMobileMenuOpened());
        }
    }

    function handlePinClick() {
        dispatch(toggleSidebarPinned());
    }

    return (
        <SidebarStyled
            id="sidebar-content"
            breakPoint="md"
            toggled={toggled}
            collapsed={sidebarCollapsed}
            rtl={isRtl}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onToggle={handleToggle}
        >
            <SidebarHeader onClick={handleMobileClick}>
                <NavLink to={`/${constants.pages.allBuildingsUrl}`} className={'logo-link'}>
                    <img src={logoIcon} alt="logo" />
                </NavLink>
                <div className='pin-icon' onClick={handlePinClick}>
                    { pinned ? <img src={pinnedIcon} alt="pinned" /> : <img src={unpinnedIcon} alt="unpinned" /> }
                </div>
            </SidebarHeader>

            <SidebarContent>
                <div className="bg"><img src={sidebarBg} alt="bg" /></div>
                <div className="bg-small"><img src={sidebarBgSmall} alt="bg small" /></div>
                <Menu iconShape="square">
                    <PartnerMaintenanceSubMenu onItemClick={handleMobileClick} />
                </Menu>
            </SidebarContent>

        </SidebarStyled>
    );
}
