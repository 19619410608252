import {constants} from "../../common/constants/constants";
import {SubMenu} from "react-pro-sidebar";
import maintenance from "../../images/newDesign/maintenance.svg";
import {isLinkActive} from "./Sidebar.shared";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import { useEffect, useMemo, useState } from "react";
import CacheData from '../../common/utils/cacheData';
import {MenuNavItem} from "./MenuNavItem";
import {partnerMaintenanceTitlesTranslations} from "./partnerMaintenanceTitlesTranslations";
import {isMobile} from "react-device-detect";

export function PartnerMaintenanceSubMenu({ onItemClick = (e) => void null }) {
    const {t} = useTranslation();

    const [localBuildingId, setLocalBuildingId] = useState(null);
    const currentBuildingData = useSelector(state => state.buildings.currentBuildingData);
    const userProfile = useSelector(state => state.auth.userProfile);
    const managerAllBuildings = useSelector(state => state.manager.buildings?.buildings ?? []);
    const hasMaintenanceBuildings = useMemo(() => managerAllBuildings.some(b => b.maintenanceEnabled), [managerAllBuildings]);
    const userPartnerId = userProfile?.partnerID;
    const buildingPartnerId = currentBuildingData?.currentBuildingData?.managementCompany?.externalId;
    const partnerId = buildingPartnerId || userPartnerId;


    useEffect(() => {
        // FIXME: why do we use cache instead of incoming parameter?
        setLocalBuildingId(CacheData.fetchCache('currentBuilding') || currentBuildingData?.currentBuildingData?.id);
    }, [currentBuildingData]);

    const shouldUsePartner = partnerId && partnerId !== 'private';
    if (!hasMaintenanceBuildings ||
        (!shouldUsePartner && !localBuildingId) ||
        // https://bllink.atlassian.net/browse/SSB-1689
        userProfile?.isViewOnly)
    {
        return null;
    }

    const entityType = shouldUsePartner ? 'partner' : 'building';
    const entityValue = shouldUsePartner ? partnerId : localBuildingId;

    const baseUrl = `/${constants.pages.maintenanceLivyUrl}/${entityType}/${entityValue}`;
    const isSystemsActive = isLinkActive(`${baseUrl}/systems`);
    const isMaintenanceActive = isLinkActive(`${baseUrl}/maintenance`);
    const isMaintenanceManagerActive = isLinkActive(`${baseUrl}/faults`);
    const isDocumentsActive = isLinkActive(`${baseUrl}/documents`);
    const isReportsActive = isLinkActive(`${baseUrl}/reports`);
    const isActive = isSystemsActive || isMaintenanceManagerActive || isMaintenanceActive || isDocumentsActive || isReportsActive;

    return (
        <SubMenu
            title={t(partnerMaintenanceTitlesTranslations.default)}
            icon={<img src={maintenance} alt="maintenance" />}
            className={isActive ? 'active' : ''}
        >
            <MenuNavItem
                url={`${baseUrl}/systems`}
                label={t(partnerMaintenanceTitlesTranslations.systems)}
                className="mobile-hide"
            />
            <MenuNavItem
                url={`${baseUrl}/faults`}
                label={t(partnerMaintenanceTitlesTranslations.maintenanceManager)}
                onClick={onItemClick}
            />
            <MenuNavItem
                url={`${baseUrl}/maintenance`}
                label={isMobile ? t(partnerMaintenanceTitlesTranslations.mobileMaintenance) : t(partnerMaintenanceTitlesTranslations.maintenance)}
            />
            <MenuNavItem
                url={`${baseUrl}/reports`}
                label={t(partnerMaintenanceTitlesTranslations.reports)}
                className="mobile-hide"
            />
            <MenuNavItem
                url={`${baseUrl}/suppliers`}
                label={t(partnerMaintenanceTitlesTranslations.suppliers)}
                className="mobile-hide"
            />
            <MenuNavItem
                url={`${baseUrl}/company-users`}
                label={t(partnerMaintenanceTitlesTranslations['company-users'])}
                className="mobile-hide"
            />

        </SubMenu>
    );
}
