/**
 * @enum
 * @typedef {{
 *    buildingID: 'buildingID',
 *    apartmentID: 'apartmentID',
 *    partnerID: 'partnerID',
 *    paymentPlanID: 'paymentPlanID',
 *    userID: 'userID',
 * }} FeatureOwnerTypeValue
 * */

/**
 * @typedef {{
 *    id: number;
 *    featureId: number;
 *    featureOwnerTypeID: number;
 *    ownerId: string;
 *    value: string
 * }} FeatureValue
 * */

export class FeaturesApi {

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {FeatureOwnerTypeValue} entityType
     * @param {number} entityId
     * @param {string} featureName
     * @param {string|number|boolean} value
     * @return {Promise<FeatureValue>}
     * */
    setFeatureForEntity(entityType, entityId, featureName, value) {
        return this.#client.put(`/operations/features/${entityType}/${entityId}/${featureName}`, { value });
    }

    /**
     * @param {FeatureOwnerTypeValue} entityType
     * @param {number} entityId
     * @param {string} featureName
     * @return {Promise<FeatureValue | null>}
     * */
    getFeatureForEntity(entityType, entityId, featureName) {
        if (!entityId) return {}

        return this.#client.get(`/operations/features/${entityType}/${entityId}/${featureName}`);
    }

}
