import { useTranslation } from "react-i18next";
import { MenuItem } from "react-pro-sidebar";
import { NavLink } from "react-router-dom";
import React, { Fragment, useRef } from "react";
import { SideMenuTitle } from "./sideMenu/sideMenuTitle";
import fees from "../../images/newDesign/fees_one_time.svg";
import { SideMenu } from "./sideMenu/sideMenu";
import { useDispatch, useSelector } from "react-redux";
import { toggleMobileMenuOpened } from "../../store/common/slice";
import { constants } from "../../common/constants/constants";
import { SideMenuItem } from "./sideMenu/sideMenuItem";
import CacheData from "../../common/utils/cacheData";

/**
 * @param buildingId
 * @param navData
 * @return {JSX.Element|null}
 * @constructor
 */
export default function SpecialPaymentsSidebarMenu({ buildingId, navData }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const menuRef = useRef();
    const sideMenuRef = useRef();
    const localBuildingId = useSelector(state => buildingId ?? state.buildings.buildingID ?? CacheData.fetchCache('currentBuilding'));

    const onItemClick = () => {
        dispatch(toggleMobileMenuOpened());
        sideMenuRef.current.close();
    }

    const renderOneTimeOptions = () => {
        if (!navData.oneTimeSettings) return;
        return (navData.oneTimeSettings.map((oneTime) => {
            return (<SideMenuItem
                key={oneTime.oneTimeID}
                url={`/${constants.pages.oneTimePaymentSettings}/${localBuildingId}/${oneTime.oneTimeID}`}
                label={oneTime.name}
                onClick={onItemClick}/>);
        }));
    };

    return (
        <Fragment>
            <MenuItem icon={null} ref={menuRef}>
                <NavLink className="dropdown-link" to={{ pathname: '' }}>
                    {t('buildings.nav_bar_special_payments')}
                </NavLink>
            </MenuItem>
            <SideMenu container={menuRef.current} ref={sideMenuRef}>
                <SideMenuTitle
                    label={t('buildings.nav_bar_special_payments')}
                    icon={fees}/>
                <SideMenuItem
                    isActiveWithQuery={true}
                    url={`/${constants.pages.addNewPayment}/${localBuildingId}?oneTime=add`}
                    label={t('buildings.nav_bar_add_one_time')}
                    onClick={onItemClick}/>
                {renderOneTimeOptions()}
            </SideMenu>
        </Fragment>

    )
}
