import Logger from 'js-logger';

const test = {
    server: {
        useMocks: true
    },
    aws: {
        AwsConfig: require('../auth/aws-exports-dev.json')
    },
    logger: {
        logLevel: Logger.OFF
    },
    bllink: {
        exposeClient: false,
    },
    language: 'en'
};

const dev = {
    debug: true,
    server: {
        // baseUrl: 'http://testbllink.co:3001/dev/api/v1',
        // see proxy settings in package.json
        baseUrl: '/dev/api/v1',
        useMocks: process.env.BLLINK_USE_MOCKS, // set in .zshrc or .bashrc
        timeout: 1400000,
    },
    aws: {
        AwsConfig: require('../auth/aws-exports-dev.json')
        // AwsConfig: require('../auth/aws-exports-production.json')
    },
    logger: {
        logLevel: Logger.TRACE
    },
    google:{
        autocompleteKey: 'AIzaSyA7F3mRpQRgU361whCcNGe1iLoUcPljD5Y'
    },
    // just for testing sentry locally
    // sentry: {
    //     dsn: 'https://a3295a4665984e94b43ee6c0b9dcebbe@o438198.ingest.sentry.io/5424386',
    //     // sampleRate: 1
    // },
    bllink: {
        exposeClient: true,
    },
};

const prod = {
    server: {
        baseUrl: 'https://api.bllink.co/api/v1'
    },
    aws: {
        AwsConfig: require('../auth/aws-exports-production.json')
    },
    logger: {
        logLevel: Logger.TRACE // to debug an issue with select
    },
    sentry: {
        dsn: 'https://a3295a4665984e94b43ee6c0b9dcebbe@o438198.ingest.sentry.io/5424386'
    },
    google:{
        autocompleteKey: 'AIzaSyAdMcKqQCew_jDjd4ZW5nrGESAinvz2tRI'
    },
    bllink: {
        exposeClient: true,
    },
};


const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;
let FINAL_SETTINGS = config;

if (process.env.REACT_APP_STAGE !== 'production'){
    try {
        const PERSONAL_SETTINGS = require('./personal_settings');
        FINAL_SETTINGS = {
            ...config,
            ...PERSONAL_SETTINGS.default
        }

    } catch (ex) {
        // nothing here
        // BllinkLogger.info(`error in personal_settings`, ex)
    }
}

if (process.env.REACT_APP_STAGE === 'test'){
    FINAL_SETTINGS = test;
}

export const SETTINGS = {
    // Add common config values here
    COMMON_CONFIG_1: 5000000,
    language: 'he',
    debug: false,
    ...FINAL_SETTINGS
};

