export const titles = {
    "home": "Bllink co-op payments",
    "bllink": "Bllink",
    "page_description": "Bllink.co online co-op bills payments",
    "building_profile": "Building page | Bllink",
    "tenant_details": "Tenant Details | Bllink",
    "payment_details": "Payment Details | Bllink",
    "payment_checkout": "Payment Checkout | Bllink",
    "make_payment": "Payment Page | Bllink",
    "confirmation_page": "Payment Confirmation | Bllink",
    "bank_charge_guide": "Setup recurrent bank charge instructions | Bllink",
    "renewal_page": "Renew payment | Bllink",
    "failed_payments_page": "Update credit card details | Bllink",
    "terms_of_service": "Terms of use | Bllink",
    "privacy": "Privacy | Bllink",
    "all_buildings": "My buildings | Bllink",
    "dashboard": "Main | Bllink",
    "yearly_report": "Yearly Report | Bllink",
    "monthly_report": "Monthly Report | Bllink",
    "tenant_list": "Tenant list | Bllink",
    "apartment_report": "Apartment payments report | Bllink",
    "cash_flow_report": "Yearly cashflow report | Bllink",
    "search_building": "Search by address | Bllink",
    "expenses_record": "Record Expenses | Bllink",
    "monthly_expense_table": "Monthly expenses report | Bllink",
    "loading": "Loading",
    "forms": {
        "mandatory_field": "This is a required field",
        "mandatory_field_full_name": "This is a mandatory 3 characters field",
        "mandatory_above_zero": "Expense amount has to be bigger than 0",
        "correct_email": "Please enter a correct email address",
        "correct_account_bank_branch": "bank, branch and account must be filled together",
        "correct_phone": "Please enter a correct cellphone number",
        "subscribe_check": "Please confirm terms of use",
        "going_to_credit_card": "Processing...",
        "number_field": "Should be number",
        "invalid_date": "Please enter a valid date",
        "vendor_tax_id": "Min 6 chars",
        "min_string_size": "Min {{size}} chars",
        "account_field": "Should be from 6 to 9 chars",
        "correct_phone_for_vendor": "Please verify phone length between 3 and 10 digits long",
        "max_length_exceeded": "Max length for this field",
        "max_length": "Must be at most {{size}} characters",
        "min_length": "Must be at least {{size}} characters",
        "invalid_card_number": "Invalid card number",
        "invalid_cvv_code": "Invalid CVV",
        "value_too_big": "Value too big (max {{max}})",
        "value_too_small": "Value too small (min {{min}})",
    },
    "bank_adjustments": "Bank Adjustments | Bllink",
    "balance_report": "Balance report | Bllink",
    "cheque_report": "Check Deposit Report | Bllink",
    "expenses_edit": "Edit Expenses | Bllink",
    "general_pop_up_title": "System notification",
    "building_debt_list": "Building debt list | Bllink",
    "partner_debt_list": "General Debt Report",
    "tenant_claims_paid": "Tenant claims paid | Bllink",
    "testTitle": "test title",
    "coming_soon": "Coming Soon",
};
