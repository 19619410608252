import styled, {keyframes} from 'styled-components';
import LoaderIcon from '../../images/loader.svg';
import BlueCloseIcon from '../../images/newDesign/blueClose.svg';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LocationAutocompleteStyled = styled.div`
  
  display: flex;
  
  input {
    background: #fff;
    border: 1px solid var(--bllink-blue);
    box-sizing: border-box;
    border-radius: 50px;
    height: 52px;
    -webkit-appearance: none;
    z-index: 2;
    position: relative;
  }
  
  .loader {
    width: 26px;
    height: 50px;
    background-image: url("${LoaderIcon}");
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-inline-start: -42px;
    z-index: 3;
    opacity: .8;
    animation: ${rotate} 2s linear infinite;
  }
  
  button {
    border: none;
    width: 26px;
    height: 50px;
    padding: 0;
    margin-inline-start: -42px;
    z-index: 3;
    background-image: url("${BlueCloseIcon}");
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    cursor: pointer;
  }
  
  .hidden {
    visibility: hidden;
  }
  
  ul {
    position: absolute;    
    min-height: 100px;
    width: 100%;    
    z-index: 1;
    // background-color: #d3ddff;
    background-color: #F9F9FF;
    border: 1px solid var(--bllink-blue);
    list-style: none;
    padding: calc(26px + .5em) .5em 1em .5em;
    top: 26px;
    border-radius: 0 0 25px 25px;
    li {
      display: flex;
      cursor: pointer;
      padding: .5em 1em;
      &.active {
        background-color: #525e87;
        color: white;
      }
    }
  }
`;
