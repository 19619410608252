import {PaymentMethodName} from "./PaymentMethodName";

export const constants = {
    chatBot: {
      names: {
          legalBot: 'legalBot',
          // technical CSM helper
          CSMTechnical: 'CSMTechnical',
          managersAssistants: 'managersAssistants',
      }
    },

    network: {
        serverNetworkError: 'serverNetworkError'
    },

    cache: {
        prefix_keys: 'bllink',
        cacheChanged: (key) => {
            return `cacheChanged_${key}`
        },
        keys: {
            buildings: {
                buildingApartments: (buildingID) => {
                    return `building_aprt_${buildingID}`
                },
                buildingData: (buildingID) => {
                    return `buildingDetails_${buildingID}`
                },
                buildingAddress: (buildingID) => {
                    return `buildingAddress_${buildingID}`
                },
                managerData: (buildingID) => {
                    return `managerData_${buildingID}`
                },
            },
            tenants: {
                tenantsDetails: 'tenantDetails',
                managerDetails: (buildingID) => {
                    return `managerDetails_${buildingID}`
                },
                managerTenantsDetails: 'managerTenantsDetails'
            },
            payments: {
                // buildingID and apartment number is required since
                // a manager would also use this screen to log payments
                // for multiple apartments
                onGoingSelectedPayments: (buildingID, apartmentNumber) => {
                    return genericFuncPayments('onGoingSelectedPayments', buildingID, apartmentNumber)
                },
                onGoingChargeDate: (buildingID, apartmentNumber) => {
                    return genericFuncPayments('onGoingChargeDate_2', buildingID, apartmentNumber)
                },
                debtSelectedPayments: (buildingID, apartmentNumber) => {
                    return genericFuncPayments(`debtSelectedPayments`, buildingID, apartmentNumber)
                },
                oneTimeSelectedPayments: (buildingID, apartmentNumber) => {
                    return genericFuncPayments(`oneTimeSelectedPayments`, buildingID, apartmentNumber)
                },
                installmentsChoices: (key) => {
                    return `installmentsChoices_${key}`
                },
                oneTimeInstallmentsName: (oneTimeBuildingPaymentId) => {
                    return `oneTimePayment_${oneTimeBuildingPaymentId}`
                },
                apartmentPayments: (buildingID, apartmentNumber) => {
                    return genericFuncPayments(`apartmentPayments`, buildingID, apartmentNumber);
                },
                onGoingPayments: 'onGoingPayments',
                pastPayments: 'pastPayments',
                oneTimePayments: 'oneTimePayments',
                createCartResponse: (cartID) => {
                    return `createCartResponse_${cartID}`
                },
                visitedTabs: 'visitedTabs',
                cartID: 'cartID',
                showMonthSelectionInfoPopUp: 'showMonthSelectionInfoPopUp',
            },
            managers: {
                profile: 'profileKey3',
                resetPasswordUser: 'resetPasswordUser',
                selectedPaymentMethod: (buildingID, apartmentNumber) => {
                    return `selectedPaymentMethod_${buildingID}_${apartmentNumber}`
                },
                currentBuilding: 'currentBuilding',
                partnerFeatures: 'partnerFeatures',
                currentPartnerFeatures: (partnerID) => {
                    return `currentPartnerFeatures_${partnerID}`
                },
                currentBuildingFeatures: (buildingID) => {
                    return `currentBuildingFeatures_${buildingID}`
                },
                bankDetails: 'BankDetails',
                rewardsCoinValue: 'rewardsCoinValue',
                bankAdjustmentsTab: 'bankAdjustmentsTab',
                bankRecords: 'bankRecords',
                rewardDetails: 'rewardDetails',
                currentAptInTenantList: 'currentAptInTenantList',
                currentContactInTenantList: 'currentContactInTenantList',
                clientChatID: 'clientChatID',
                featureNames: {
                    showNewFeature: 'showNewFeature',
                },
                partnerPaymentDetails: 'partnerPaymentDetails',
                currentPartnerPaymentDetails: (partnerID) => {
                    return `currentPartnerPaymentDetails_${partnerID}`
                },
            },
            debug: 'debug',
            general: {
                language: 'language2',
                language_manual: 'language_manual',
                lastPage: 'lastPage',
                sideBarPinned: 'sideBarPinned'
            }
        }
    },

    pages: {
        admin: {
            searchPaymentsUrl: '/admin/searchPayments',
            autoCampaign: '/admin/autoCampaign',
            reports: '/admin/reports',
            editBuilding: '/admin/editBuilding',
            userActions: '/admin/userActions',
            partners: '/admin/partners',
            activeCampaign: '/admin/activeCampaign',
            chat: '/admin/chat'
        },

        // tenants
        test: 'test',
        debtTab: 'past-payments',
        onGoingTab: 'on-going-payments',
        oneTimeTab: 'one-time-payments',
        chequeTab: 'cheque-form',
        chequeDepositTab: 'cheque-deposit-form',
        searchBuilding: 'search',
        buildingsUrl: 'buildings',
        managerDetails: 'manager-details',
        choosePaymentMethod: 'choose-payment-method',
        tenantDetailsUrl: 'tenant-details',
        paymentSelectionUrl: 'payment-page',  // AKA select payments page
        collectionDate: 'collection-date',
        paymentSelectionTab: (buildingID, apartmentNumber, selectedTab) => {
            return `/payment-page/${buildingID}/${apartmentNumber}?tab=${selectedTab}`
        },
        paymentDetailsUrl: 'payment-details',  // AKA credit card details page
        paymentDetailsRapydUrl: 'payment-details-rapyd',
        paymentDetailsRapydStub: 'payment-details-rapyd-stub',
        lastConfirmPaymentPageUrl: 'confirm-selection',  // AKA checkout page
        orderStatusPage: 'status', // AKA confirmation page
        downloadBankBillingPdfUrl: 'tenants/payments-download-bank-document',
        bankPage: 'bank-charge-details',
        chooseBankMethod: 'choose-bank-charge-method',
        directTransfer: 'direct-transfer',
        bankPaymentInfo: 'bank-info',
        bankPageApplication: 'bank-charge-details-application',
        mobilePaymentPage: 'mobile-charge',
        learnMore: 'learn-more',
        insuranceOffer: 'insurance-offer',
        additionalOffer: 'additional-offer',
        additionalOfferFlowers: 'additional-offer-flowers',
        adsFollowUp: 'ads-follow-up',

        // tenants plan renewals and failed payments screens
        planRenewalPage: 'renew',
        failedPaymentPage: 'update-card',

        // manager
        loginUrl: 'login',
        firstSigninUrl: 'first-time-signin',
        tenantDetailsManagerUrl: 'manager-fill-details',
        paymentSelectionTabForManager: 'manager-log-payment',
        lastConfirmPaymentManagerPageUrl: 'manager-selection-confirmation',
        managerTenantRecord: 'manager-confirm',
        managerConfirmation: 'manager-confirmation', // AKA confirmation page, after logging offline payment
        singUpUrl: 'signup',
        singUpDemoUrl: 'signup-demo',
        allBuildingsUrl: 'all-buildings',
        newBuildingUrl: 'building/new',
        store: 'store',
        storeRequestSuccess: 'store-request-success',
        viewBankDetailsUrl: 'bank-details',
        addBankDetailsUrl: 'bank-details/add',
        yearlyCashFlowUrl: 'reports/yearly',
        monthlyCashFlowUrl: 'reports/monthly',
        monthlyChequesUrl: 'reports/cheques',
        monthlyPartnerChequesUrl: 'reports/partners/cheques',
        apartmentCashFlowUrl: 'reports/apartment',
        newApartmentReport: 'reports/apartment-new',
        yearlyByMonthsUrl: 'reports/yearly-by-months',
        yearlyByOneTimeUrl: 'reports/yearly-by-one-time',
        tenantListUrl: 'reports/tenant-list',
        archivePage: 'archive',
        maintenanceLivyUrl: 'maintenance',
        maintenanceTenantUrl: 'tenant-maintenance',
        confirmationPage: 'confirmation',
        quickPay: 'pay',
        monthlyExpensesUrl: 'reports/expenses-monthly',
        balanceReportUrl: 'reports/balance',
        buildingDebtList: 'reports/building-debt',
        buildingManagerProfileUrl: 'building-profile',
        buildingManagerCompanyProfileUrl: 'mc-building-profile',
        partnerLeadPage: 'mc-lead-page',
        premiumInfoPage: 'premium-info',
        partnerProfilePage: 'partner-profile',
        managersChat: 'manager/chat',
        userDetailsPage: 'manager/userDetails',
        serviceProviders: 'manager/service-providers',
        userChatPage: 'manager/chat',
        rewards: 'manager/rewards',
        marketPlace: 'manager/marketPlace',
        notifications: 'manager/notifications',
        editBuilding: 'manager/editBuilding',
        editContacts: 'manager/editContacts',
        updateRates: 'manager/updateRates',
        // manager add payment settings
        addCollectionStatus: 'manager/collectionStatus',
        addNewPayment: 'manager/add-payment',
        monthlyPaymentSettings: 'manager/monthly-payments',
        oneTimePaymentSettings: 'manager/one-time',
        // expenses record
        expensesRecordUrl: 'expenses-record',
        expensesEditUrl: 'expenses-edit',
        bankAdjustmentsUrl: 'manager/bankAdjustments',
        autoCampaignUrl: 'managers/auto-campaign',
        messagePlatformUrl: 'managers/operations/messagePlatform',
        autoCampaignPage: 'managers/auto-campaign-page',
        monitoringUrl: 'managers/monitoring',
        productsAtDiscountUrl: 'managers/products-at-discount',
        fundsEarlyTransferUrl: 'managers/funds-early-transfer',
        insuranceOfferUrl: 'managers/insurance-offer',
        setExpenseUrl: 'record-expense',
        pdfRendererUrl: 'pdf',
        pdfOrientation:{
            portrait:'p',
            landscape: 'l'
        },

        partnerDebtList: 'partner-debt-list',
        partnerMonthlyReports: 'partner-monthly-report',

        // general
        mainBllinkSite: 'https://www.bllink.co',
        questionAndAnswers: 'faq',
        contactUs: 'contact-us',
        bankChargeUrl: 'bank-charge',
        termsOfServiceUrl: 'terms-of-service',
        privacyUrl: 'privacy',
        healthCheckUrl: 'health-check',
        tenantProfile: 'tenant',
        selfOnBoard: 'self-onboard',
        legalConsultant: 'legal-bot',
    },

    shortUrls: {
        tenantDetailsUrl2: 't', // shorter. maybe for sms links to look prettier
        apartmentNumberParam: 'a',

    },

    popUp: {
        missingTenantsDetails: 'mtd',
        keyName: 'pop'
    },

    defaultCurrency: 'ils',

    phoneNumbers: {
        ISRAEL_PREFIX_CODE: '972',
        MEXICO_PREFIX_CODE: '52',
        USA_PREFIX_CODE:'1'
    },

    attributes: {
        isSelected: 'isSelectedItem'
    },

    commissions:{
        BIT_COMMISSION: 0.585,
        MINIMUM_TENANT_COMMISSION: 5,
    },

    backendEndpoints:{
        SEND_REPORT_BY_EMAIL : 'sendReportByEmail',
        GENERIC_INTERNAL_EMAIL_ENDPOINT : 'genericInternalEmail'
    },

    FINAL_MONTH_IN_YEAR: 12, // aka december
    FIRST_MONTH_IN_YEAR: 1, // aka january
    ONE_TIME_BANK_CHARGE_SETUP_FEE: 15, // additional fee of the first payment in a bank charge plan (not related to onetime payments)

    serverContract: {
        payments: {
            onGoing: 'on_going_payments',
            past: 'past_payments',
            one_time: 'one_time_payments',
        }
    },

    PaymentPlanDetailsStatus: {
        PENDING: 1,
        1: 'pending',
        SUCCESS: 2,
        2: 'success',
        TRANSFERRED_TO_BUILDING_ACCOUNT: 5,
        // money got to us, and we already transferred it to the building
        5: 'transferred_to_building_account',

        CANCELED_BUT_NOT_REVERSED: 6, // see payment payment_state.yaml
        6: 'canceled_but_not_reversed',

        // tourist cards are handled differently
        // since we receive the money one month later than usual cards
        TOURIST_CARD_SUCCESS: 7,
        7: 'tourist_card_success',
        J5_VALIDATED: 8,
        8: 'j5',

        // If charge is failed set failed status - https://app.asana.com/0/search/1199905585116408/1199687426800523
        FAILED: 9,
        9: 'failed',

        //https://app.asana.com/0/1123966772501425/1199908653560616
        // add a status to stop a plan
        // this status is skip. meaning all have to be 10 to be stopped
        STOP_PLAN: 10,
        10: 'stop_plan',

        // not used in code but setting this will make the auto charge skip the payment since it's not pending
        SKIP_PAYMENT: 11,
        11: 'skip_payment',

        // https://app.asana.com/0/1196545425570329/1200027441653643 InProgress status for each payment Detail
        // to avoid duplicate charge
        IN_PROGRESS: 12,
        12: 'in_progress',


        // https://app.asana.com/0/1200496983031576/1200576289753551
        // created due to bank adjustments page. we needed a way to know the offline payments is paid for
        // but still pending in the eyes of the manager until validated against the bank
        SUCCESS_PENDING: 13,
        13: 'success_pending',

        CANCELED_BUT_NOT_REVERSED_CHARGEBACK: 15,
        15: 'canceled_but_not_reversed_chargeback',

        get ALL_SUCCESS() {
            // all possible success statuses
            return [this.SUCCESS, this.TOURIST_CARD_SUCCESS, this.SUCCESS_PENDING, this.J5_VALIDATED, this.IN_PROGRESS]
        },
        get CANCELED() {
            // * canceled_but_not_reversed - money was not received by the building yet (status 6)
            return [this.CANCELED_BUT_NOT_REVERSED, this.CANCELED_BUT_NOT_REVERSED_CHARGEBACK]
        },
        isSuccessful(status, isExpense = false) {
            return this.ALL_SUCCESS.includes(status) || isExpense && status === this.PENDING;
        }
    },

    // used in credit card page and in payments to months table
    months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],

    expenses: {
        addNewVendor: 'addNewVendor',
        expenseTypeIdKey: 'expenseTypeID'
    },

    MonthsToText: {
        1: 'january',
        2: 'february',
        3: 'march',
        4: 'april',
        5: 'may',
        6: 'june',
        7: 'july',
        8: 'august',
        9: 'september',
        10: 'october',
        11: 'november',
        12: 'december',
    },

    // according to designs, we have different backgrounds for different pages.
    // listing those various options here (all except the default)
    backgroundOptions: {
        map: 'map',
        buildingProfileForTenant: 'building_profile_for_tenant',
        tenantDetails: 'tenant_details',
        debtSelectPayments: 'debt_select_payments',
        ongoingSelect: 'on_going_select_payments',
        oneTimeSelect: 'one_time_select_payments',
        checkout: 'checkout',
        creditCard: 'credit_card',
        confirmation: 'confirmation', // AKA status page. the unofficial "receipt".
        allPaymentsPaid: 'paid_payment_body',
        allBuildings: 'all_buildings',
        login: 'login',
        signup: 'signup',
        cashflow_yearly: 'cashflow_yearly',
        cashflow_monthly: 'cashflow_monthly',
        cashflow_apartment: 'cashflow_apartment',
        yearly_by_months: 'yearly_by_months',
        report: 'report',
        mobileView: 'mobile-view',
    },

    GoogleAnalytics: {
        Events: {
            creditCard: 'creditCard',
            // purchase is a saved event name to use to track revenue
            // https://support.google.com/analytics/answer/9267735?visit_id=638276433632308543-1038019914&rd=1
            purchase: 'purchase',
            tenantDetails: 'tenantDetails',
            payment: 'payment',
            selectPayments: 'selectPayments',
            offlinePayments: 'offlinePayments',
            expenses: 'expenses',
            clicks: 'clicks',
            login: 'login',
            allBuildings: 'allBuildings',
            createBuilding: 'createBuilding',
            addBankDetails: 'addBankDetails',
            paymentSettings: 'paymentSettings',
            popUps: 'popUps',
            rewards: 'rewards',
            bankAdjustments: 'bankAdjustments',
            editTenants: 'editTenantsData',
            deleteExpense: 'deleteExpense',
            fastOneTime: 'fastOneTime',
            managerOperations: 'managerOperations',
            comments: 'comments',
            general: 'general',
            learnMore: 'learnMore',
            learnMoreWA: 'learnMoreWA',
            tenantLogin: {
                category: 'tenantLogin',
                details: {
                    loginAttemptSuccess: 'tenantLoginAttemptSuccess',
                    loginAttemptFailed: 'tenantLoginAttemptFailed',
                    loginChallengeSuccess: 'tenantLoginChallengeSuccess',
                    loginChallengeFailed: 'tenantLoginChallengeFailed',
                    noApartments: 'tenantLoginNoApartments',
                }
            },
            onBoardingBot:{
                category: 'onBoardingBot',
                enteredTheBot: 'enteredTheBot',
                createdBuilding: 'createdBuilding',
                uploadedBankDetails: 'uploadedBankDetails',
                uploadedProofOfOwnership: 'uploadedProofOfOwnership',
                changedApartmentNames: 'changedApartmentNames',
                uploadedApartmentsExcel: 'uploadedApartmentsExcel',
            }
        },

        Labels: {
            creditCard: {
                bllinkUI: 'bllinkUI',
                bllinkTenantCheckout: 'bllinkTenantCheckout',
                bllinkUIUpdateCard: 'bllinkUIUpdateCard',
                bllinkChargeExistingCard: 'bllinkChargeExistingCard',

            }
        }
    },

    APIContract: {
        fields: {
            on_going: 'on_going_payments',
            past: 'past_payments', // debt
            one_time_payments: 'one_time_payments',
        },

        planTypes: {
            on_going: 2,
            debt: 5,
            one_time: 1,

            on_going_no_installments: 5,
            debt_no_installments: 7,
            one_time_no_installments: 8,

            isOnGoing: function (planType) {
                return [this.on_going, this.on_going_no_installments].includes(planType)
            },

            isDebt: function (planType) {
                return [this.debt, this.debt_no_installments].includes(planType)
            },

            isOneTime: function (planType) {
                return [this.one_time, this.one_time_no_installments].includes(planType)
            }
        },

        renewalDeclineReasons: [
            'moved',
            'paid_via_cheque',
            'call_me',
            'other'
        ],

        tenantTypes: {
            renter: 'renter',
            owner: 'owner',
        },

        failedPayments: {
            cartIDPrefix: 'f-'
        },

        /**
         * @enum {number}
         * */
        paymentMethods: {
            creditCard: 1,
            cheque: 2,
            wire: 3,
            cash: 4,
            legacyPayment: 5,
            bankCharge: 6,
            autoCheque: 7,
            tenantRecord: 8,
            notSet: 9,
            bit: 11,
            applePay: 12,
            googlePay: 13,
            // a cheque deposited to bllink bank account
            bllinkCheque: 14,

            offlinePayments: [4, 2, 3, 5],
        },

        paymentMethodsKeys: {
            creditCards: 'credit_card',
            cheques: 'cheque',
            wires: 'wire',
            cash: 'cash',
            bankCharge: 'bankCharge',
            cheque: 'cheques',
            wire: 'wires',
            notSet: 'notSet',
            onlineDirectWire: 'onlineDirectWire',
            bit: 'bit',
            applePay: 'applePay',
            googlePay: 'googlePay'
        },

        paymentMethodsIds: {
            [PaymentMethodName.creditCards]: 1,
            [PaymentMethodName.cheques]: 2,
            [PaymentMethodName.bit]: 11,
            [PaymentMethodName.cash]: 4,
            [PaymentMethodName.legacyPayment]: 5,
            [PaymentMethodName.applePay]: 12,
            [PaymentMethodName.googlePay]: 13,
            [PaymentMethodName.bankCharge]: 6,
            [PaymentMethodName.wires]: 3,
        },

        paymentMethodsById: {
            1: {
                key: 'credit_card',
                serverKey: 'creditCard'
            },
            2: {
                key: 'cheque',
                serverKey: 'cheques'
            },
            3: {
                key: 'wire',
                serverKey: 'wires'
            },
            4: {
                key: 'cash',
                serverKey: 'cash'
            },
            5: {
                key: 'legacyPayment',
                serverKey: 'legacyPayment',
            },
            6: {
                key: 'bankCharge',
                serverKey: 'bankCharge'
            },
            7: {
               key: 'autoCheque',
               serverKey: 'autoCheque'
            },
            8: {
                key: 'tenantRecord',
                serverKey: 'tenantRecord'
            },
            9: {
                key: 'notSet',
                serverKey: 'notSet'
            },
            10: {
                key: 'onlineDirectWire',
                serverKey: 'onlineDirectWire'
            },
            11: {
                key: 'bit',
                serverKey: 'bit'
            },
            12: {
                key: 'applePay',
                serverKey: 'applePay'
            },
            13: {
                key: 'applePay',
                serverKey: 'applePay'
            },
            14: {
                key: 'bllinkCheque',
                serverKey: 'bllinkCheque'
            }
        },
        mobilePaymentMethods :[PaymentMethodName.bit, PaymentMethodName.applePay, PaymentMethodName.googlePay],
        managerRoles: {
            edit: 'edit',
            admin: 'admin',
            view: 'view',
            editorRoles: function () {
                return [this.edit, this.admin]
            }
        },

        paymentStates: {
            // ppd statuses are the same as the payments mode statuses
            PENDING: 1,
            1: 'pending',
            SUCCESS: 2,
            2: 'success',
            CANCELED: 4,
            4: 'canceled',

            TRANSFERRED_TO_BUILDING_ACCOUNT: 5,
            // money got to us, and we already transferred it to the building
            5: 'transferred_to_building_account',

            CANCELED_BUT_NOT_REVERSED: 6, // see payment state.yaml
            6: 'canceled_but_not_reversed',

            // tourist cards are handled differently
            // since we receive the money one month later than usual cards
            TOURIST_CARD_SUCCESS: 7,
            7: 'tourist_card_success',
            J5_VALIDATED: 8,
            8: 'j5',

            // If charge is failed set failed status - https://app.asana.com/0/search/1199905585116408/1199687426800523
            FAILED: 9,
            9: 'failed',

            //https://app.asana.com/0/1123966772501425/1199908653560616
            // add a status to stop a plan
            // this status is skip. meaning all have to be 10 to be stopped
            STOP_PLAN: 10,
            10: 'stop_plan',

            // not used in code but setting this will make the auto charge skip the payment since it's not pending
            SKIP_PAYMENT: 11,
            11: 'skip_payment',

            // https://app.asana.com/0/1196545425570329/1200027441653643 InProgress status for each payment Detail
            // to avoid duplicate charge
            IN_PROGRESS: 12,
            12: 'in_progress',

            PENDING_SUCCESS: 13,
            13: 'pending_success',

            CANCELED_CHARGEBACK: 14,
            14: 'canceled_chargeback',

            CANCELED_BUT_NOT_REVERSED_CHARGEBACK: 15,
            15: 'canceled_but_not_reversed_chargeback',

            get SUCCESS_STATUSES() {
                return [this.SUCCESS, this.TOURIST_CARD_SUCCESS]
            },
            get DEAD_STATUSES() {
                return [this.CANCELED_BUT_NOT_REVERSED, this.STOP_PLAN, this.SKIP_PAYMENT]
            }, // to be consider in the confirmation page
            get CANCELED_PAYMENT_STATUSES() {
                return [this.CANCELED, this.CANCELED_BUT_NOT_REVERSED, this.CANCELED_CHARGEBACK, this.CANCELED_BUT_NOT_REVERSED_CHARGEBACK]
            },
            get REFUNDED_PAYMENT_STATUSES() {
                return [this.CANCELED, this.CANCELED_BUT_NOT_REVERSED]
            },
            get CANCELED_CHARGEBACK_PAYMENT_STATUSES() {
                return [this.CANCELED_CHARGEBACK, this.CANCELED_BUT_NOT_REVERSED_CHARGEBACK]
            },
            get PENDING_STATUSES() {
                return [this.PENDING, this.PENDING_SUCCESS];
            }
        },

        expenses: {
            recurring: 'recurring',
            oneTime: 'oneTime',
        },

        expensesByNumbers: {
            1: 'oneTime',
            2: 'recurring'
        },

        blockedProvider:{
            // yahalom does not block any providers like we do (amex)
            'diamondmc': []
        },

        legacyPayments: {
            months: {
                // excel columns names used to upload collection status
                january: 'jan',
                february: 'feb',
                march: 'mar',
                april: 'apr',
                may: 'may',
                june: 'jun',
                july: 'jul',
                august: 'aug',
                september: 'sep',
                october: 'oct',
                november: 'nov',
                december: 'dec',
            }
        }
    },

    managerPaymentsOptions: {
        autoCheque: 'autoCheque',
        chequeDepositName: 'Auto Deposited Cheque',
        cash: 'cash',
        cheque: 'cheque',
        wire: 'wire',
        creditCard: 'creditCard',
        bankCharge: 'Bank Charge',
        tenantRecord: 'Tenant Record',
        get offlinePayments() {
            return [this.cash, this.cheque, this.wire]
        },
        get onlineBankCharge() {
            return [this.bankCharge]
        },
        creditCardServerKey: 'creditCard',
        creditCardsServerKey: 'creditCards',
        cashServerKey: 'cash',
        chequeServerKey: 'cheques',
        // using a scanner and a third party payment provider,
        // we auto deposit partner's cheques to the buildings' accounts
        // with no need to drive to the bank
        autDepositChequeServerKey: 'autoCheque',
        wireServerKey: 'wires',
        legacyPaymentKey: 'legacyPayment',
        bankChargeKey: 'bankCharge',
        onlineDirectWire: 'onlineDirectWire',
        tenantRecordKey: 'tenantRecord',
        bitKey: 'bit',
        applePayKey: 'applePay',
        googlePayKey: 'googlePay',
        get onlineServerPayments() {
            return [this.creditCardsServerKey, this.creditCardServerKey, this.bankChargeKey];
        },
        isValidMethod(paymentMethod) {
            return [this.cashServerKey,
                this.creditCardServerKey,
                this.creditCardsServerKey,
                this.wireServerKey,
                this.chequeServerKey,
                this.legacyPaymentKey,
                this.autDepositChequeServerKey,
                this.bankChargeKey,
                this.onlineDirectWire,
                this.tenantRecordKey,
                this.bitKey,
                this.applePayKey,
                this.googlePayKey,
            ].includes(paymentMethod)
        }
    },

    bankCodesInIsrael: [4, 9, 10, 11, 12, 13, 14, 17, 18, 20, 26, 31, 34, 46, 52, 54, 68],
    // the 3rd party that submits the form only supports banks with Physical branchs. removed one zero
    bankWithPhysicalBranchesCodesInIsrael: [4, 9, 10, 11, 12, 13, 14, 17, 20, 26, 31, 34, 46, 52, 54, 68],
    bankCodesInMexico: [
        '002','006','009','012','014','019','021','022','030','032','037',
        '042','044','058','059','060','072','102','103','106','108','110',
        '112','113','116','124','126','127','128','129','130','131','132',
        '133','134','135','136','137','138','139','140','141','143','145',
        '147','148','150','155','156','166','168','600','601','602','604',
        '605','606','607','608','610','611','613','614','615','616','617',
        '618','619','620','621','622','623','624','626','627','628','629',
        '630','631','632','633','634','636','637','638','640','642','646',
        '647','648','649','651','652','653','655','656','659','670','674',
        '677','679','684','901','902',
    ],

    longCommentPostfix: '...',
    longCommentCutOff: 12,
    frontendUrl: 'https://app.bllink.co',

    // removing 3692 becuase it overwrote one time settings
    demoBuildingIDS: ['spn1', 'eng1', 'demo3', 'demo-eng'],
    // todo add later demoBuildingIDS: ['spn1', '3692', 'eng1', 'demo3', 'demo-eng','kdfo', 'US Demo'],
    countriesDemoBuildings: {
        usDemoBuildingIDS: [], // 'kdfo', 6543, '6543'
        israelDemoBuildingIDS: ['rsql', 2190, '2190']
    },
    countriesDemoPartners: {
        usDemoPartnerIDS: ['US Demo'],
        israelDemoPartnerIDS: ['demo'],
    },

    collectionReportTabs: {
        bankDeposit: 'bankDeposit',
        bllinkCharges: 'bllinkCharges',
        bllinkChegues: 'bllinkChegues',
        bankWire: 'bankWire',
        bankWireTabOptions: {
            approved: 'approved',
            awaitingApproval: 'awaitingApproval',
        },
        chequesTabOptions: {
            manualCheuqes: 'manualCheuqes',
            digitalCheuqes: 'digitalCheuqes',
            depositedCheques: 'depositedCheques',
        },
        bankDepositOptions: {
            deposit: 'deposit',
            deductions: 'deductions',
        },
        bllinkChargesOptions: {
            charges: 'charges',
            refunds: 'refunds',
        },
        statusOptions: {
            all: 'all',
            success: 'success',
            deposited: 'deposited',
            refunded: 'refunded',
            failed: 'failed',
        },
    },

    cardExistingType: {
        new: 'new',
        existing: 'existing'
    },

    getDemoBuildingFileName: function (buildingID) {
        if (this.countriesDemoBuildings.usDemoBuildingIDS.includes(buildingID)) {
            return 'buildingMockBrooklynData.json';
        } else {
            return 'buildingMockHistarotData.json';
        }
    },
    getDemoBuildingMockDataFileName: function (buildingID) {
        if (this.countriesDemoBuildings.usDemoBuildingIDS.includes(buildingID)) {
            return 'ManagerBuildingUSAMockData.json';
        } else {
            return 'ManagerBuildingMockData.json';
        }
    },
    getDemoBuildingMockPaymentsDataFileName: function (buildingID) {
        if (this.countriesDemoBuildings.israelDemoBuildingIDS.includes(buildingID)) {
            return 'buildingPaymentMockData.json';
        }
    },
    getDemoPartnerProfileDataFileName: function (partnerID) {
        if (this.countriesDemoPartners.usDemoPartnerIDS.includes(partnerID)) {
            return 'partnerProfileDataUS.json';
        } else {
            return 'partnerProfileData.json';
        }
    },
    getDemoPartnerDebtFileName: function (partnerID) {
        if (this.countriesDemoPartners.usDemoPartnerIDS.includes(partnerID)) {
            return 'partnerDebt.json';
        } else {
            return 'partnerDebtUSA.json';
        }
    },

    // opening to all except this partner since we don't support rapyd
    newFlowBuildingsExclude: ['Adems'],
    disabledFeatureValue: 'disabled',
    bitRange:{
        min: 5,
        max: 3600
    },

    localization: {
        WEEKDAYS_SHORT: {
            he: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
            en: ['U', 'M', 'T', 'W', 'R', 'F', 'S'],
        },
        MONTHS: {
            he: [
                'ינואר',
                'פברואר',
                'מרץ',
                'אפריל',
                'מאי',
                'יוני',
                'יולי',
                'אוגוסט',
                'ספטמבר',
                'אוקטובר',
                'נובמבר',
                'דצמבר',
            ],
            en: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
        },
        WEEKDAYS_LONG: {
            he: [
                'ראשון',
                'שני',
                'שלישי',
                'רביעי',
                'חמישי',
                'שישי',
                'שבת',
            ],
            en: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
        }
    },

    trnzilaLinks: {
        ptop: 'https://pay.tranzila.com/ptop'
    },

    leanUsersAccessSidebarTillDate: new Date(2024, 0, 15),

    quickPay: {
        sourceType: {
            utmSource: 'utm_source',
            utmCampaign: 'utm_campaign',
            utmVersion: 'utm_version',
            utmCampaignDate: 'utm_campaignDate',
            utmMedium: 'utm_medium',
            utmMediumValue: 'tenantApp',
            // not needed since we'll just check the postfix to get the version number
            // QP_VER1: 'qp_pr_ver1',
            // QP_VER2: 'qp_pr_ver2',
            // QP_VER3: 'qp_pr_ver3',
            QP_TENANT_APP: 'qp_tenantApp', // 3 is all debt shown.
            QP_TENANT_APP_VERSION: 'ver3', // 3 is all debt shown.
        },
        query: {
            apartmentNumber: 'apartmentNumber',
            tenantType: 'tenantType',
            tenantName: 'name',
            tenantPhone: 'phone',
            tenantEmail: 'email',
        }
    }
}

function genericFuncPayments(key, buildingID, apartmentNumber) {
    if (!buildingID) {
        throw new Error(`buildingID is required for this cache key ${key}`)
    }
    if (!apartmentNumber) {
        throw new Error(`apartmentNumber is required for this cache key ${key}`)
    }
    return `${key}_${buildingID}_${apartmentNumber}`;
}
