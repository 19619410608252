import { useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import equalWebIcon from "../../images/newDesign/equalWeb.svg";
import { colors } from "../../common/ui/globalColors";

const EqualWebButtonStyled = styled.div`
  .equalweb-buton {
    cursor: pointer;
    transform: scale(-1, 1);
    margin-inline: 23px;
    height: 43px;
    width: 43px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
    background-color: ${colors.additionalColors.equalWeb};
  }
`

// added due task https://bllink.atlassian.net/browse/SSB-1585

export const EqualWebButton = () => {
    const [originalButton, setOriginalButton] = useState(null);

    const userProfile = useSelector(state => state.auth.userProfile);

    const handleEqualWebClick = useCallback(() => {
        window.interdeal?.a11y?.openMenu();
    }, [window.interdeal]);

    useEffect(() => {
        if (originalButton) {
            originalButton.style.display = userProfile?.userID ? 'none' : 'block';
        }
    }, [userProfile, originalButton]);

    useEffect(() => {
        const body = document.querySelector('body');
        const observer = new MutationObserver((mutationsList, observer) => {
            const originalButton = document.querySelector('#INDmenu-btn');
            setOriginalButton(originalButton ?? null);
        });
        observer.observe(body, { childList: true });
        return () => {
            observer.disconnect()
        }
    }, []);

    return userProfile?.userID ?
        <EqualWebButtonStyled>
            <img src={equalWebIcon} alt="EqualWeb" className='equalweb-buton' onClick={handleEqualWebClick}/>
        </EqualWebButtonStyled>
        : null;
}
