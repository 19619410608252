export const userActions = {
    "title": "User actions page", 
    "labels": {
        "editOrCreate": "Edit existing user or create new",
        "email": "Email",
        "phone": "Phone",
        "user": "User"
    },
    "btn": "Update user / create user",
    "success": "User was updated or created successfully"
};
