export const buildings = {
    "your_buildings": "הבניינים שלך",
    "your_buildings_sub_title": "קבלת תשלום בכרטיס אשראי חוסכת כ-20 דקות בחודש לכל דירה!",
    "you_have_x_active_buildings": "יש לך {{number}} בניינים פעילים",
    "building_x": "בניין {{index}}",
    "building_address": "כתובת הבניין",
    "add_new_building": "הוספת בניין חדש",
    "new_building_details": "פרטי הבניין החדש",
    "enable_building_today": "תאפשר לך להתחיל את גביית דמי ועד הבית בכרטיס אשראי כבר היום!",
    "opening_building": "פתיחת הבניין",
    "entrance_optional": "כניסה (אופציונאלי)",
    "building_manager_name": "שם מנהל הבניין",
    "adding_another_manager": "הוספת תמונה",
    "nav_bar_actions": "פעולות לביצוע",
    "bank_account_owner": "שם מורשה בחשבון",
    "bank_name": "שם הבנק",
    "account_number": "מספר חשבון",
    "branch_number": "מספר סניף",
    "terms_of_use": "תנאי שימוש",
    "cheque_photo": "צילום צ׳ק או אישור בעלות על חשבון",
    "type_of_document": "סוג מסמך",
    "proof_of_ownership": "אישור בעלות חשבון",
    "edit": "עריכה",
    "legacyPayment": "סומן כשולם",
    "total_payments_this_month": "בוצע החודש",
    // rayee requested https://bllink.atlassian.net/browse/SSB-1240
    // "total_payments_this_month_tooltip": "כולל גם תשלומים באשראי או בחיובי בנק דרך בלינק. כולל רק בניינים פעילים. שווה לחיובים דרך בלינק. יש להתחשב חיובי תייר מהחודש וחודש שעבר ולקזז החזרים",
    "total_payments_this_month_tooltip": "סך התשלומים שחיובו. יש לשים לב כי סכום זה לא מייצג את סכום ההעברה הקרובה",
    "hours_saved": "חסכון גבייה ידנית",
    "hours_saved_tooltip": "בהתאם למספר הדירות ששולמו במהלך החודש הנוכחי",
    "total_manual_deposit": "סה״כ להפקדה בבנק",
    "total_manual_deposit_tooltip": "סכום תשלומי מזומן וצ'קים עבור החודש הנוכחי",
    "total_debts_for_one_time": "חובות תשלומים מיוחדים",
    "total_debts_for_one_time_tooltip": "חבות תשלומים מיוחדים - לא כולל בניינים לא פעילים",
    "total_debts_for_monthly": "חובות ועד חודשי",
    // https://bllink.atlassian.net/browse/SSB-1242
    "total_debts_for_monthly_tooltip": "חבות תשלומים שוטפים כולל חודש נוכחי ולא כולל בניינים לא פעילים",
    "no_payments_made_this_month_yet": "לא בוצעו תשלומים עדיין",
    "hours": "שעות",
    "payment_by_methods_title": "איך התקבלו התשלומים?",
    "connect_bank_account": "חיבור חשבון הבנק",
    "cheques": "שיק",
    "notSet": "לא נקבע",
    "wires": "ה. בנקאית",
    "bit":"ביט",
    "creditCards": "אשראי",
    "bllinkCheque": "צ׳ק לפקודת בלינק",
    "creditCard": "אשראי",
    "applePay": "אפל פיי",
    "googlePay": "גוגל פיי",
    "onlineDirectWire": "העברה בנקאית דרך בלינק",
    "unknownPaymentMethod": "לא ידוע",
    "tenantRecord": "דיווח דייר/ת",
    "tenantRecordList": "תשלומים לבירור (דיווח דייר)",
    "monthly_amount_passed_to_building_title": "העברות לתשלומים באשראי",
    "monthly_amount_hover_title": "כמות חודשית",
    "monthly_amount_hover_title_admin": "סכום חודשי (צריך להיות זהה לדוח החודשי)",
    "nav_bar_edit_building": "עריכת פרטי הבניין",
    "nav_bar_edit_contacts": "ערוך אנשי קשר",
    "nav_bar_edit_building_commission": "עריכת עמלת הבניין",
    "nav_bar_buildings_list": "רשימת בניינים",
    "nav_bar_buildings_data": "נתוני כל הבניינים",
    "nav_bar_all_buildings_data": "גבייה - כל הבניינים שלי",
    "nav_bar_main_screen": "מסך ראשי לבניין",
    "nav_bar_ai_agent": "עוזרת אישית AI",
    "nav_bar_tenant_list": "רשימת דיירים",
    "nav_bar_archive": "ארכיון מסמכים",
    "nav_bar_maintenance": "תחזוקה",
    "nav_bar_new_leads": "לידים של בניינים",
    "nav_bar_resource_center": "מרכז המידע",
    "nav_bar_maintenance_management": "ניהול תחזוקה",
    "nav_bar_maintenance_systems": "מערכות בניין",
    "nav_bar_maintenance_tenant_reports": "קריאות דיירים",
    "nav_bar_maintenance_tenant_reports_short": "קריאות",
    "nav_bar_maintenance_on_going": "משימות לטיפול (מנע) ",
    "nav_bar_maintenance_on_going_short": "משימות",
    "nav_bar_maintenance_on_going_mobile": "משימות ומערכות",
    "nav_bar_maintenance_reports": "דו״חות",
    "nav_bar_maintenance_documents": "מסמכים",
    "nav_bar_maintenance_vendors": "ספקים",
    "nav_bar_maintenance_permissions": "הרשאות",
    "nav_bar_shared_space": "חדר דיירים",
    "nav_bar_votes": "הצבעות",
    "nav_bar_reports": "דו\"חות כספיים",
    "nav_bar_reports_yearly_cashflow": "תזרים הכנסות",
    "nav_bar_reports_monthly_cashflow": "הכנסות לפי חודש",
    "nav_bar_reports_apartment_cashflow": "הכנסות לפי דירה",
    "nav_bar_reports_apartment": "הכנסות לפי דירה",
    "nav_bar_reports_yearly_by_months": "גבייה שנתית",
    "nav_bar_reports_yearly_by_one_time": "גבייה מיוחדת",
    "nav_bar_reports_update_payment": "הוספת תשלום",
    "nav_bar_reports_apartment_collection": "גבייה",
    "nav_bar_reports_apartment_debts": "חובות",
    "nav_bar_reports_apartment_tenants": "דיירים",
    "nav_bar_update_payments": "עדכון תעריפי תשלום",
    "nav_bar_record_an_expense": "הוספת הוצאה",
    "nav_bar_expenses": "הוצאות",
    "building_created_message": "בניין נוצר בהצלחה. הינך מועבר\/ת לעמוד ראשי",
    "nav_bar_payments_rates": "תעריפי תשלום",
    "nav_bar_update_rates": "עדכון תעריפים חודשיים",
    "must_upload_document": "מסמך הוכחת בעלות נדרש על מנת לשמור פרטי בנק",
    "nav_bar_update_payments_year": "עדכון תעריפי תשלום {{year}}",
    "nav_bar_view_monthly": "צפייה בתשלומים חודשיים",
    "nav_bar_add_one_time": "הוספת תשלום חד פעמי חדש",
    "nav_bar_special_payments": "תשלומים מיוחדים",
    "nav_bar_reports_expenses": "דו״ח הוצאות",
    "nav_bar_reports_monthly_expenses": "הוצאות לפי חודש",
    "nav_bar_reports_expenses_balance": "מאזן מצטבר",
    "nav_bar_accounting": "הנהלת חשבונות",
    "nav_bar_bank_adjustment": "התאמות בנק",
    "nav_bar_bank_reconciliation": "ביצוע התאמות בנק",
    "nav_bar_debt_list": "חייבים",
    "nav_bar_payments": "תשלומים",
    "nav_bar_new_fault_tenants": "פתיחת תקלה",
    "nav_bar_collection_data": "נתוני גבייה",
    "nav_bar_collection": "נתוני גבייה",
    "nav_bar_manager_chat": "עוזרת אישית",
    "nav_bar_brochure": "עלון ללובי הבניין",
    "nav_bar_rewards": "פרסים ומתנות",
    "nav_bar_refresh": "ריענון נתוני בניין",
    "nav_bar_update_payment_plans": "בדוק תוכניות לעדכון",
    "nav_bar_admin": "תפריט אדמין",
    "nav_bar_new_bank_adjustments": "התאמות בנק חדש",
    "nav_bar_auto_campaign": "קמפיין אוטומטי",
    "nav_bar_message_platform": "מערכת הודעות",
    "nav_bar_monitoring": "קמפיין אוטומטי חדש",
    "nav_bar_search_payments": "חיפוש תשלומים",
    "nav_bar_set_broadcast": "מעקב פניות לדיירים",
    "nav_bar_products_at_a_discount": "מוצרים בהנחה",
    "nav_bar_funds_early_transfer": "משיכת כספים לחשבון",
    "nav_bar_partner_profile": "הגדרות",
    "nav_bar_demo_sign_up_real_building": "פתיחת בניין אמיתי",
    "nav_bar_demo_sign_up_real_building_tooltip": "פתיחת בניין עצמאית באמצעות בוט הAI שלנו",
    "nav_bar_building_insurance_offer_tooltip": "הצעת ביטוח מבנה אטרקטיבית - חברת נכונים",
    "nav_bar_additional_services_tooltip": "הצעות מספקים נוספים ומוצרים",
    "nav_bar_additional_services": "נותני שירות",
    "nav_bar_building_insurance_offer": "הצעה לביטוח וועד הבית",
    "upgrade_to_premium": "למערכת המלאה שדרג/י",
    "address_pop_up": {
        "header": "הזנת כתובת בצורה ידנית",
        "edit_address": "הכנס\/י כתובת. אנא הקפד להזין כתובת לפי שם הרחוב במפות גוגל",
        "labels": {
            "street_name": "שם רחוב בעברית",
            "street_number": "מספר בניין",
            "city": "עיר בעברית",
            "country": "מדינה",
            "entrance": "כניסה(אופציונאלי)",
            "building_title": "כותרת בניין",
            "nickname": "כינוי (במקרה שהכתובת לא מופיעה בגוגל/שם מיוחד לבניין)",
        },
        "update": "שמור\/י"
    },
    "lead_page": {
        "title": "לידים של בניינים",
        "content_part_1": "עמוד זה נעול ויפתח כשיצטרף לחשבון שלך הבניין ה-20 - כרגע לחשבונך קיימים {{managerAllBuildingsCount}} בניינים",
        "content_part_2": "בעמוד זה יופיעו לידים רלוונטים של בנייני מגורים המחפשים חברת ניהול",
        "button": "הוספת בניינים עם נציג",
        "notification": "מושלם! מישהו מהצוות שלנו ייצור איתך קשר בקרוב"
    },
    "nav_bar_upload_payment": "תיעוד תשלום מחשבונית",
    "collection_status_title": "סטאטוס גבייה",
    "collection_status_uploaded": "סטאטוס גבייה עודכן בהצלחה",
    "something_wrong": "תקלה בעדכון סטאטוס",
    "didntManagerToFindNewPrimary": "לא נקבע דייר ראשי עבור הדירה. הדירה תישאר ללא {{typeOfTenant}} ראשי ",
    "editBuildingTitle": "ערוך פרטי בניין",
    "open_new_building": "פתיחת חשבון חדש",
    "autoCheque": "שיק דיגיטלי",
    "nav_bar_update_one_time": "עדכון {{oneTimeTitle}}",
    "document_too_large": "תמונה או מסמך גדולים מידי. אנא בחר\/י גודל קטן מ-2 מגה בייט",
    "add_bank_details_title": "לאן להעביר את הכסף?",
    "building_added": "הבניין נוצר בהצלחה במערכת בלינק",
    "actions_left": "כעת כל מה שנשאר לך לעשות זה להגדיר את חשבון הבנק שנדע לאן להעביר את תשלומי הדיירים",
    "proof_of_ownership_title": "אישור בעלות חשבון או צילום צ׳ק הם הכרחיים לצורך השלמה התהליך",
    "no_entrance": "ללא כניסה",
    "entrance": "כניסה",
    "collection_status_uploaded_in_background": "סטאטוס גבייה גדול מ-10 שורות ונשלח לעדכון ברקע. ניתן יהיה לבדוק סטאטוס בדוח שנתי בעוד כ-10 דקות",
    "cash": "מזומן",
    "bankCharge": "בנק",
    "confirm": "אישור",
    "full_address": "כתובת מלאה של הבניין",
    "dashboard_title": "ניתוח לוח מחוונים",
    "general_building_data": "נתוני בניין כלליים",
    "building_score": "בניין ציון",
    "today_stats": "סטטיסטיקות היום",
    "new_payments": "תשלומים חדשים",
    "recurring_payments": "תשלומים חוזרים",
    "last_actions": "פעולות אחרונות",
    "financial_data": "מידע פיננסי",
    "whatsapp": "WhatsApp",
    "ivr": "חייגן אוטומטי",
    "incomes": "הכנסות",
    "expenses": "הוצאות",
    "waiting": "הַמתָנָה",
    "to": "ל",
    "from": "מ",
    "payment_method_type": "סוג אמצעי תשלום",
    "maintenance_manager_title": "קריאות דיירים",
    "open_building": "פתח בניין חדש",
    "withdrawal_now_description": "לבירור יתרה מיידית למשיכה לחשבון הבנק וביצוע הקדמת כספים",
    "withdrawal_now_phone_number": "צור קשר 03-5090045",
    "change_building": "החלף בניין",
    "will_be_delivered": "המוצר יישלח ל{{buildingName}} לדירה מספר {{apartmentNumber}}",
    "will_be_charged_from": "חיוב באמצעות {{last4Digits}} ****",
};
