import { createSlice } from '@reduxjs/toolkit';

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        searchResults: [],
        updateBtnDisabled: true,
        planScreensDataToSend: {},
        partnersList: [],
        currentPartnerShared: {}
    },
    reducers: {
        changeSearchResults: (state, action) => {
            state.searchResults = [...action.payload];
        },
        changeUpdateBtnDisabled: (state, action) => {
            state.updateBtnDisabled = action.payload;
        },
        changePlanScreensDataToSend: (state, action) => {
            state.planScreensDataToSend = action.payload;
        },
        changePartnersList: (state, action) => {
            state.partnersList = action.payload;
        },
        changeCurrentPartnerShared: (state, action) => {
            state.currentPartnerShared = action.payload;
        }
    },
});

export const {
    changeSearchResults,
    changeUpdateBtnDisabled,
    changePlanScreensDataToSend,
    changePartnersList,
    changeCurrentPartnerShared
} = adminSlice.actions;
export default adminSlice.reducer;