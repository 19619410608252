export const buttons ={
    "confirm": "Confirm",
    "cancel": "Cancel",
    "approve": "Approve",
    "back": "Back",

    "notifications": {
        'vendorWasNotified': `Vendor was notified for ad {{title}}.`,
    },
}
