export class ExpensesApi {

    /**
     * @typedef {{
     *    pp: PaymentPlans;
     *    reportedPpd: PaymentPlanDetails;
     *    amount: CurrencyValue;
     *    paid: CurrencyValue;
     * }} ExpensesReportItem
     * */

    /**
     * @typedef {{
     *    total: CurrencyValue,
     *    items: ExpensesReportItem[]
     * }} ExpensesReport
     * */

    /**
     * @typedef {{
     *    amount: number;
     *    paid: number;
     *    currency: string;
     *    vendorName: string;
     *    comment: string;
     *    vendorExternalID: string;
     *    expenseID: number;
     *    expenseTypeID: number;
     *    expenseState: number;
     *    chargeDate: string;
     *    isRecurring: string;
     *    method: string;
     *    expenseTypeKey: string;
     *    cartID: string;
     *    planID: number;
     *    isPaid: boolean;
     *    isOnline: boolean;
     *    paymentID: number;
     *    isEditable: boolean;
     * }} ExpensesByMonthReportItem
     * */

    /**
     * @typedef {{
     *    currency: string;
     *    paid: number;
     *    value: number;
     * }} ExpensesByMonthReportTotal
     * */

    /**
     * @typedef {{
     *    total: ExpensesByMonthReportTotal,
     *    Expenses: ExpensesByMonthReportItem[]
     * }} ExpensesByMonthReport
     * */

    /**
     * @typedef {{ id: number, expenseType: string, description: string }} ExpenseType
     * */

    /**
     * @typedef {{
     *    description: string,
     *    expenseTypeID: number,
     *    monthlyPayments: { chargeDate: DateStruct, amount: CurrencyValue, forMonth?: DateStruct }[]
     * }} ExpensePlansCreatePlanData
     * */

    /**
     * @typedef {{
     *    buildingID: string;
     *    vendorID: string;
     *    paymentMethod: string;
     *    frequency?: 'recurring' | 'oneTime';
     *    invoiceNumber?: string,
     *    plans: ExpensePlansCreatePlanData[];
     * }} ExpenseCreatePayload
     */

    /**
     * @typedef {{ fileName: string, fileBase64: string }} File
     * */

    /**
     * @typedef {{
     *    buildingID: string,
     *    frequency: 'recurring' | 'oneTime',
     *    numberOfMonths?: number,
     *    amount: number,
     *    invoiceNumber: string,
     *    plan: {
     *      description?: string,
     *      expenseTypeID: number,
     *      files?: File[]
     *    },
     *    paymentDate: {
     *      month: number,
     *      year: number,
     *      day: number,
     *    },
     *    vendorID: string
     * }} AddExpenseCreatePayload
    */


    /**
     * @typedef {{
     *    buildingID: string,
     *    extraDetails: {
     *      chequeID: string,
     *      bankCode: string,
     *      branchNumber: string,
     *      accountNumber: string
     *    },
     *    paymentDate: {
     *      month: number,
     *      year: number,
     *      day: number,
     *    },
     *    vendorID: string,
     *    paymentMethod: string
     * }} SetPaymentCreatePayload
    */

    #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    getDetails(cartId) {
        return this.#client.get(`/managers/expenses/by-cart-id/${cartId}`);
    }

    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * @return {Promise<ExpensesReport>}
     * */
    getReport(buildingId, year, month) {
        return this.#client.get(`/managers/expenses/report/${buildingId}/${year}/${month}`);
    }

    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * @return {Promise<ExpensesByMonthReport>}
     * */
    getMonthlyExpenses(buildingId, year, month) {
        return this.#client.get(`/managers/expenses/sorted-by-month/${buildingId}/${year}/${month}`);
    }

    /**
     * @param {string} buildingId
     * @param {number} paymentPlanId
     * @return {Promise<{ exists: boolean, fileInfo?: FileInfo }>}
     * */
    getExpenseFileInfo(buildingId, paymentPlanId) {
        return this.#client.get(`/managers/expenses/files/${buildingId}/${paymentPlanId}`);
    }

    /**
     * @return {Promise<ExpenseType[]>}
     * */
    getTypes() {
        return this.#client.get('/managers/expenses/types');
    }

    /**
     * @param {ExpenseCreatePayload} payload
     * @return {Promise<{ success: true, paymentPlans: PaymentPlans[], cartID: string }>}
     * */
    create(payload) {
        return this.#client.put('/managers/expenses', payload);
    }

    /**
     * @param {number} paymentId
     * @param {boolean} [deleteAllPending=false]
     * @return {Promise<unknown>}
     * */
    delete(paymentId, deleteAllPending = false) {
        return this.#client.delete(`/managers/expenses/by-payment-id/${paymentId}`, { deleteAllPending });
    }

    /**
     * @param {AddExpenseCreatePayload} payload
     * @return {Promise<unknown>}
     * */
    addExpense(payload) {
        return this.#client.post(`/managers/expenses`, payload);
    }

    /**
     * @param {SetPaymentCreatePayload} payload
     * @return {Promise<unknown>}
    * */
    setPayment(paymentPlanId, payload) {
        return this.#client.put(`/managers/expenses/${paymentPlanId}`, payload);
    }
}
