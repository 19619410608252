export class DebtsApi {

    /** @type {ServerData} */ #client;

    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} buildingId
     * @param {number} [start]
     * @param {number} [end]
     * @return {Promise<unknown>}
     * */
    getBuildingDebts(buildingId, start, end, throwError = false) {
        return this.#client.get(`/managers/debts/per_building/${buildingId}`, { end, start }, { throwError });
    }

    /**
     * @param {number} partnerId
     * @param {number} [start]
     * @param {number} [end]
     * @return {Promise<unknown>}
     * */
    getPartnerDebts(partnerId, start, end) {
        return this.#client.get(`/managers/debts/per_partner/${partnerId}`, { end, start });
    }
}
