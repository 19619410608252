// Creating Redux module for building info
// extraReducers process data that was got in action

import {createSlice} from '@reduxjs/toolkit';
import {fetchBuildingComments, fetchAllComments} from './actions';

const commentsSlice = createSlice({
    name: 'comments',
    initialState: {
        allComments: {},
        buildingComments: {}
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchAllComments.fulfilled, (state, { payload }) => {
            state.allComments = payload;
        })
        builder.addCase(fetchAllComments.rejected, (state) => {
            state.allComments = [];
        })
        builder.addCase(fetchBuildingComments.fulfilled, (state, { payload }) => {
            state.buildingComments = payload;
        })
        builder.addCase(fetchBuildingComments.rejected, (state) => {
            state.buildingComments = [];
        })
    }
});

export default commentsSlice.reducer;