export class PaymentsApi {

    /** @type {ServerData} */ #client;

    /**
     * @param {ServerData} client
     * */
    constructor(client) {
        this.#client = client;
    }

    /**
     * @param {string} buildingId
     * @param {string} apartmentNumber
     * @param {number} year
     * @param {number | null} paymentPlanId
     * */
    getActivePaymentPlans(buildingId, apartmentNumber, year, paymentPlanId = null) {
        const resp = this.#client.get(`/managers/payments/${buildingId}/${apartmentNumber}/payment-plans/all-active/${year ?? 'all'}/${paymentPlanId ?? ''}`);
        return resp;
    }

    /**
     * @param {string} buildingId
     * @param {number[]} PaymentPlanDetailId
     * @param {{withoutInvalidate?: boolean}} [options]
     * @return {Promise<void>}
     * */
    stopPaymentPlanDetails(buildingId, PaymentPlanDetailId, options = {}) {
        let query = '';
        // always invalidating after stop ignoring withoutInvalidate
        // https://bllink.atlassian.net/browse/RD-107
        // if (options.withoutInvalidate) {
        //     query += `withoutInvalidate=${options.withoutInvalidate}`;
        // }
        const resp = this.#client.post(`/managers/payments/${buildingId}/payment-plan-details/stop?${query}`, { PaymentPlanDetailId });
        return resp;
    }

    /**
     * @param {string} buildingId
     * @param {{[key: number]: Date}} dates
     * @param {{withoutInvalidate?: boolean}} [options]
     * @return {Promise<void>}
     * */
    updateChargeDatesPaymentPlanDetails(buildingId, dates, options = {}) {
        let query = '';
        if (options.withoutInvalidate) {
            query += `withoutInvalidate=${options.withoutInvalidate}`;
        }
        const resp = this.#client.patch(`/managers/payments/${buildingId}/payment-plan-details/charge-date?${query}`, { ...dates });
        return resp;
    }


    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * */
    getHashavshevetXmlBuildingData(buildingId, year, month) {
        const resp = this.#client.get(`/managers/cashflow/data/building/${buildingId}/${year}/${month}/hashavshevet_xml`);
        return resp;
    }

    /**
     * @param {string} buildingId
     * @param {number} year
     * @param {number} month
     * */
    getHashavshevetXmlPartnerData(partnerId, year, month) {
        const resp = this.#client.get(`/managers/cashflow/data/partner/${partnerId}/${year}/${month}/hashavshevet_xml`);
        return resp;
    }

    /**
     * @param {number | string} buildingId
     * @param {number} year
     * @param {object} tenantPaymentsJson
     * @param {object} updatePaymentPlansParams
     * */
    uploadPaymentSettings(buildingId, year, tenantPaymentsJson, updatePaymentPlansParams) {
        const resp = this.#client.post(`/managers/payments/${buildingId}/${year}`, { tenantPaymentsJson, updatePaymentPlansParams }, { throwError: true });
        return resp;
    }

    /**
     * @param {number | string} buildingId
     * @param {number} year
     * @param {boolean} ignoreCache
     * */
    async getPaymentsByMonth(buildingId, year, ignoreCache = false) {
        const resp = await this.#client.get(`/managers/payments/${buildingId}`, { year, ignoreCache }, { throwError: true });
        return resp;
    }

    /**
     * @typedef {{
     *     paymentId: number;
     *     isLoan: boolean;
     *     paymentMethodId: number;
     *     bankChargeDetails: null | {
     *         bankCode: string;
     *         branchId: string;
     *         accountId: string;
     *     };
     *     creditCardDetails: null | {
     *         cardType: string;
     *         lastNums: string;
     *         expireDate: string;
     *     };
     *     wireDetails: null | {
     *         wireConfirmationNum: string;
     *         bankCode: string;
     *         branchId: string;
     *         accountId: string;
     *     };
     *     chequeDetails: null | {
     *         chequeNum: string;
     *         bankCode: string;
     *         branchId: string;
     *         accountId: string;
     *     };
     * }} PaymentInfo
     * */

    /**
     * @param {string|number} buildingId
     * @param {number} paymentId
     * @return {Promise<PaymentInfo>}
     * */
    getPaymentInfo(buildingId, paymentId) {
        const resp = this.#client.get(`/managers/payments/${buildingId}/info/${paymentId}`, {}, { throwError: true });
        return resp;
    }

    /**
     * @param {string|number} buildingId
     * @param {number[]} paymentIds
     * @return {Promise<PaymentInfo[]>}
     * */
    getPaymentsInfo(buildingId, paymentIds) {
        const resp = this.#client.post(`/managers/payments/${buildingId}/info`, {paymentIds}, { throwError: true });
        return resp;
    }


    /**
     * @param {string|number[]} paymentsIds
     * @param {string} deletePending (all|allNext)
     * @return {Promise<{}>}
     * */
    deleteOfflinePayments(paymentsIds, deletePending = 'false') {
        const query = `deleteAllPending=${deletePending === 'all'}&deleteAllNextPending=${deletePending === 'allNext'}`;
        return  this.#client.post(`/managers/delete-external-payments?${query}`, {paymentsIds});
    }

    /**
     * @typedef {object} RecordExternalPaymentsResult
     * @property {string|number} buildingID
     * @property {string} cartID
     * @property {number} firstPlanID
     * @property {number} firstPlanPaymentID
     * @property {number} monthsPaidTotal
     * @property {number} numberOfPlansCreated
     * @property {number} totalPaymentsAdded
     * */

    /**
     * @typedef {object} RecordExternalPaymentsPayload
     * @property {number|string} buildingID
     * @property {string} apartmentNumber
     * @property {number} totalToPay
     * @property {PaymentPlanTenantDetails} tenant_details
     * @property {ChequePaymentData[]} cheques
     * @property {CreatePaymentPlanPayload} paying_for
     * */

    /**
     * @typedef {object} ChequePaymentData
     * @property {CurrencyValue} amount
     * @property {Date} dueDate
     * @property {{chequeID: string, accountNumber: string, bankCode: number, branchNumber: string}} extraDetails
     * @property {"cheques"} paymentMethod
     * */

    /**
     * @param {string|number} buildingId
     * @param {string} apartmentNumber
     * @param {RecordExternalPaymentsPayload} payload
     * @return {Promise<RecordExternalPaymentsResult>}
     * */
    recordExternalPayments(buildingId, apartmentNumber, payload) {
        return  this.#client.post(`/managers/external-payments/${buildingId}/${apartmentNumber}`, payload, { throwError: true });
    }

    /**
     * @typedef {{
     *     paymentIds: number[];
     *     dueDate: string;
     * }} markPaymentsAsApprovedPayload
     * */

    /**
     * @param {markPaymentsAsApprovedPayload} payload
     * @return {Promise<{success: boolean}>}
     * */
    async markPaymentsAsApproved(payload) {
        return await this.#client.post('/managers/markPaymentsAsApproved', payload);
    }

    /**
     * @typedef {object} CreateOneTimePaymentPayload
     * @property {number} amount
     * @property {string} description
     * @property {string} longDescription
     * @property {number} numberOfInstallments
     * @property {string[]} apartmentNumbers
     * */

    /**
     * @typedef {object} ApartmentOneTimePayment
     * @property {number} id
     * @property {number} apartmentID
     * @property {number} amountInCents
     * @property {string} currency
     * @property {number} buildingOneTimePaymentID
     * @property {number} paymentPlanId
     * @property {boolean} isActive
     * @property {string} createdBy
     * */

    /**
     * @param {string|number} buildingID
     * @param {CreateOneTimePaymentPayload} payload
     * @return {Promise<ApartmentOneTimePayment[]>}
     * */
    async createOneTimePayment(buildingID, payload) {
        // changed to false to show the error in creating a one time
        const response = await this.#client.put(`/managers/onetime-payments/${buildingID}`, payload, { throwError: false });
        return response
    }
}
