import react from 'react';


class SessionRefreshMap {

    constructor(storageKey) {
        this.storageKey = storageKey;
    }

    #getMapCopy() {
        return JSON.parse(window.sessionStorage.getItem(this.storageKey) ?? '{}');
    }

    #saveMap(map) {
        window.sessionStorage.setItem(this.storageKey, JSON.stringify(map));
    }

    hasRefreshed(key) {
        const refreshMap = this.#getMapCopy();
        return refreshMap[key] ?? false;
    }

    setRefreshed(key) {
        const refreshMap = this.#getMapCopy();
        refreshMap[key] = true;
        this.#saveMap(refreshMap);
    }
}

const REFRESH_MAP_KEY = 'lazy-reload';
const refreshMap = new SessionRefreshMap(REFRESH_MAP_KEY);

export function lazyReload(asyncImport) {
    return react.lazy(function () {
        return retry(asyncImport);
    });
}

async function retry(asyncImport) {
    try {
        const comp = await asyncImport();
        return comp;
    } catch (error) {
        // NOTE: message contains failed path
        if (!refreshMap.hasRefreshed(error.message)) {
            refreshMap.setRefreshed(error.message);
            console.error('Unable load module, possible version mismatch. Refreshing page.');
            return window.location.reload();
        }
        throw error;
    }
}

