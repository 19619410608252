export const errors = {
    "notFound": {
        "404": "404 Not Found",
        "pageNotFound": "Requested page is not found",
    },
    "errorFallback": {
        "title": "An unexpected error occurred",
        "subtitle": "The error has been reported, we are working on it",
        "tryAgain": "Try Again",
        "detailsTitle": "Error details",
    },
    "tenant": {
        "payments": {
            "OnGoingPaymentTimeError": "payment must start from this current month only for Building Payments",
            "PaymentsInThePastError": "payment is not properly setup, please return to the first screen and try again",
            "PartPayIsNotAllowedForTenant": "part pay is not allowed for tenant",
            "PartPayIsNotAllowedForManager": "part pay is not allowed for manager",
            "ExistingPaymentPlanRunning": "There is a fixed monthly charge",
            "AlreadyPayedError": "Payment was made for the selected payments",
            "DebtTooManyMonthsError": "The number of payments for past months is too large",
            "FailedTokenHasNotFailed": "Payment has been successfully updated. No further action is required",
            "NumberOfInstallmentIsIllegal": "Invalid number of payments entered, please try again",
            "NoNewPlansWereCreated": "No payments were made. Please make sure you select the number of payments you want.",
            "RenewalInProgress": "Renewal already in progress"
        },
        "search": {
            "BuildingNotFound": "Building {{buildingName}} was not found in bllink's records",
            "SearchAddressIsNotCompleted": "Didn't search for a specific building address. required street number, street name and city",
            "ApartmentNotFound": "Apartment Number {{apartmentNumber}} was not found"
        }
    },
    "buildings": {
        "BuildingIsDisabled": "building does not exists or temporary disabled",
        "ManagerNotAllowedToViewBuilding": "manager not allowed",
        "BuildingIsNotActive": "Building is still inactive. Please contact support at support@bllink.co or by calling 03-5090045",
        "NotFullAddress": "Incomplete address. Please verify the exact address including building number",
        "card_company_reject": 'Payment has been rejected by credit card company'
    },
    "managers": {
        "NewOneTimeMissingTitle": "New one time payment is missing title",
        "NewOneTimeMissingNumberOfInstallments": "New one time payment is missing number of installments",
        "NewOneTimeDidntAddAnyNewApartmentPayments": "New one time payment didn't add any new apartment payments",
        "ExternalSumDoesNotEqualTotalSum": "Amount recorded does not match system's records. Amount sent {{externalAmountSent}} but the system's amount is  {{totalSumSelectedToPay}}",
        "clearedCachedForBuildingSuccess": "Cleared cached data for  {{buildingID}}. If the building is associated with a management company, data was refreshed for it as well.",
        "NoReceiptsForThisPartner": "No receipts due to direct partner.",
        "NotTimeToChargeYet": "Can't charge future payment with existing card. please select new card",
        "UserNotFound": "User {{userID}} not found",
        "NoAmountOrApartmentNumberFilled": "Error reading data. Please check that all apartment rows are filled with apartment number and amount",
        "TerminalIsNotSupportedForRefund": "This transaction was made at a terminal which is not yet supported for credits",
        "SchemaValidation": "Problem with sending file, server error {{message}}",
        "BankDetailsAlreadyExistsForBuilding": "Bank details already exists for this building"
    },
    "general": {
        "generalError": "Error. Please try again.",
        "networkError": "Network error. Please check internet connection and try again",
        "uploadError": "Image larger than 2MB",
        "timeout": "Request took too long. Please try again or contact the administrator"
    },
    "clientError": {
        "UNEXPECTED_ERROR": "Unexpected error",
        "REQUEST_VALIDATION_ERROR": "Request validation error",
        "ACCESS_FORBIDDEN": "Access forbidden",
        "APARTMENT_NO_OWNER": "No owner defined for apartment",
        "APARTMENT_NO_RENTER": "No renter defined for apartment",
        "CAMPAIGN_ALREADY_RUNNING": "Unable to start auto campaign. Need to finalize running campaigns",
        "TerminalIsNotSupportedForRefund": "This terminal {{terminalName}} is still not support in ptop",
        "NoAmountOrApartmentNumberFilled": "Apartment number or amount is missing from row {{index}}",
        "UNKNOWN_ENTITY_TYPE": "Unknown entity type {{entityType}}",
        "ENTITY_NOT_FOUND": "Entity {{entityType}}:{{entityId}} was not found",
        "COMMENT_NOT_FOUND": "Comment with id {{commentId}} was not found",
        "COMMENT_UPDATE_DISALLOWED": "Only comment creator may update it",
        "NO_PDF_MAP_FOUND": "No one PDF map matches provided file",
        "CAMPAIGN_NOT_FOUND": "Campaign id {{campaignId}} is not found",
        "CAMPAIGN_NOT_STARTED_YET": "Campaign id {{campaignId}} is not started yet",
        "CAMPAIGN_FINISHED": "Campaign id {{campaignId}} is already finished",
        "CAMPAIGN_NOT_ACTIVE": "Campaign id {{campaignId}} is not active",
        "CAMPAIGN_PRIZE_ALREADY_REQUESTED": "This prize already requested, please try later",
        "CAMPAIGN_PRIZE_NOT_FOUND": "Campaign Prize id {{campaignPrizeId}} not found or not participate the campaign",
        "CAMPAIGN_LIMIT_REACHED": "Campaign id {{campaignId}} limit is reached",
        "COINS_NOT_ENOUGH": "Not enough points",
        "DATA_ROW_VALIDATION_ERROR": "Incorrect data in row {{row}}. Data import interrupted",
        "VALIDATE_BODY_ERROR": "Request body is invalid",
        "VALIDATE_QUERY_ERROR": "Request query is invalid",
        "GENERAL_BAD_PARAMS": "Invalid parameters sent {{message}}",
        "NO_PAYMENTS_SENT": "No payments were sent in the sent request",
        "MissingDescriptionTitle": "Mandatory name for one-time payment. Please fill in",
        "DUPLICATE_ONE_TIME": "Unable to create same one time payment for same apartment. Choose a different name for it",
        "NO_ACTIVE_BUILDINGS": "Unable to find any active buildings",
        "PHONE_PARAM_REQUIRED": "Phone number required",
        "PHONE_NOT_MATCH": "Phone number you've entered is unknown to the system",
        "PARTNER_MAPPING_MISMATCH": "Not enough mapping data to perform export",
        "NOT_ENOUGH_TO_REFUND": "Unable to refund. Not enough available money. ({{refundAmount}} > {{availableAmount}})",
        "TEMP_ADMIN_ONLY": "This feature is temporarily available only for admins",
        "APARTMENT_NOT_FOUND": "Apartment not found",
        "APARTMENT_ALREADY_INACTIVATED": "Apartment {{apartmentNum}} in building {{buildingId}} already inactivated",
        "APARTMENT_HAS_PAYMENTS": "Apartment {{apartmentNum}} in building {{buildingId}} has payments",
        "NO_PENDING_PAYMENTS_TO_DELETE": "Failed to find any pending payments to delete",
        "MAX_INSTALLMENTS_FOR_NEW_ONE_TIME": "max installments {{num}} is larger than max allowed",
        "NO_MAINTENANCE_CONFIGURED": "The maintenance feature is not configured",
        "BUILDING_STILL_NOT_ACTIVE_IN_PTOP": "building still not activated. probably missing bank details",
        "CANT_PARSE_BANK_FILE": "Can't parse a file",
        "NO_PLAN_WERE_CREATED": "no plan was created, check if items were selected to charge",
        "UNEXPECTED_EXEL_FILE_FORMAT": "The excel file you've sent has unexpected format",
        "FILE_TOO_BIG": "Too big file",
        "BUILDING_IS_DISABLED": "Building does not exists or temporary disabled",
        "SMS_LIMIT_QUOTA" : "Amount of SMS sent is bigger than quota",
        "CantLoginToLivy" : "cant login to building number {{buildingID}} check the building is active",
        "APARTMENT_IN_LEGAL_STATE" : "apartment under legal dispute, can't pay for this apartment currently. contact the building manager",
        "BUILDING_NOT_FOUND": "building not found",
        "TOO_MANY_AI_QUESTIONS": "Sign up for bllink for more questions.",
    }
};
