import CacheData from "../utils/cacheData";
import { SETTINGS } from '../settings';

const DEFAULT_LANGUAGE = SETTINGS.language; // NOTE: settings define language for test environment

/**
 * @implements {LanguageDetectorAsyncModule}
 * */
export class BllinkLanguageDetector {

    type = 'languageDetector';
    async = true;

    cacheUserLanguage(lng) {
        CacheData.cache('language', lng);
        window.document.documentElement.setAttribute('lang', lng);
    }

    detect(callback) {
        const hasHebrew = navigator.languages.some(l => l === 'he' || l.startsWith('he-'));
        // stopped support for spanish for now
        const hasSpanish = false;//navigator.languages.some(l => l === 'es' || l.startsWith('es-'));
        const fallback = hasHebrew ? 'he': (hasSpanish ? 'es': DEFAULT_LANGUAGE);
        const stickyLanguage = CacheData.fetchCache('language');
        const lng = stickyLanguage ?? fallback;
        callback(lng);
    }

    init(services, detectorOptions, i18nextOptions) {
        // just nothing to do here...
    }
}
