import {useTranslation} from "react-i18next";

export function Currency(props) {
    const children = props.children;
    if (typeof children !== 'string' || children === '') {
        return '';
    }
    const [t] = useTranslation();
    return t(`currency.${children}`);
}
