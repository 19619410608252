export const archive = {
    page_title: "Documents Archive",
    upload_per_cent_done: "done",
    all_upload_done: "All files uploaded",
    select_file_type: "Choose File Category...",
    go_back_to_list: "Back to list",
    upload_failed: "File upload failed",
    upload_failed_message: "Failed to upload file named <fileName>",
    upload_failed_message_retry: "Try uploading the file one more time",
    upload_hint: "Drag & drop or click to add files",
    upload_limits: "PDF, XML, DOCX. Max <maxFileSize>",
    show_deleted: "Show Deleted files",
    insurance_title: "Interested in saving on insurance?",
    insurance_advantages: "We can save the building the insurance costs and still maintain the scope of coverage",
    insurance_conditions: "For the purpose of receiving an attractive quote for renewing a home insurance policy, I authorize the Blink company to transfer the policy documents and contact details with me to Gor Insurance Group and authorize representatives of Gor Insurance Group to contact me regarding the matter.",
    insurance_disagree: "No thanks, let's continue",
    insurance_confirm: "Confirm",
    delete_confirm: {
      title: "Deleting a file from archive",
      question: "Just before we delete the file completely... Are you sure you want to perform the action?",
    },
    file_actions: {
        delete: 'Delete',
        undelete: 'Undelete',
    },
    file_type: {
        none: "Other",
        insurance: "Building Insurance",
        managementAgreement: "Management Agreement",
        supplierAgreement: "Supplier Agreement",
        offer: "Offer",
        protocol: "Protocol",
        bllink: "Bllink",
    },
    select_type: "Please select a type for file {{name}}"
};
