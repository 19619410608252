/**
 * @typedef {Object} partnerMaintenanceTitlesTranslations
 */
export const partnerMaintenanceTitlesTranslations = {
    default: 'buildings.nav_bar_maintenance',
    management: 'buildings.nav_bar_maintenance_management',
    systems: 'buildings.nav_bar_maintenance_systems',
    maintenanceManager: 'buildings.nav_bar_maintenance_tenant_reports',
    faults: 'buildings.nav_bar_maintenance_tenant_reports',
    maintenance: 'buildings.nav_bar_maintenance_on_going',
    mobileMaintenance: 'buildings.nav_bar_maintenance_on_going_mobile',
    reports: 'buildings.nav_bar_maintenance_reports',
    suppliers: 'buildings.nav_bar_maintenance_vendors',
    'company-users': 'buildings.nav_bar_maintenance_permissions',
    amenities: 'buildings.nav_bar_shared_space',
    polls: 'buildings.nav_bar_votes',
}
