import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    tenantPhone: null,
    userShouldVisitPremiumInfoPage: true
}

const commonPersistentSlice = createSlice({
    name: 'commonPersistent',
    initialState,
    reducers: {
        setTenantPhone(state, action) {
            state.tenantPhone = action.payload;
        },
        setVisitPremiumInfoPage(state, {payload}) {
            state.userShouldVisitPremiumInfoPage = payload;
        },
        onLogOut(state) {
            state.tenantPhone = initialState.tenantPhone;
            state.userShouldVisitPremiumInfoPage = initialState.userShouldVisitPremiumInfoPage;
        },
    },
});

export const {
    onLogOut,
    setTenantPhone,
    setVisitPremiumInfoPage
} = commonPersistentSlice.actions;

export default commonPersistentSlice.reducer;
