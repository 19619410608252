export const banks = {
    israel: {
        "13": "Union Bank of Israel",
        "14": "Bank Otsar Ha-Hayal",
        "17": "Mercantile Discount Bank",
        "20": "Bank Mizrahi-Tefahot",
        "26": "UBank Ltd.",
        "31": "First International Bank of Israel",
        "34": "Arab Israel Bank",
        "46": "Bank Massad",
        "52": "Bank Poaley Agudat Israel",
        "54": "Bank of Jerusalem",
        "68": "Dexia Israel Bank",
        "4": "Bank Yahav",
        "9": "The postal bank",
        "10": "Bank Leumi",
        "11": "Israel Discount Bank",
        "12": "Bank Hapoalim",
        "18": "bank one zero digital bank",
    },
    mexico: {
        '002': 'Banco Nacional de México',
        '006': 'Banco Nacional de Comercio Exterior',
        '009': 'Banco Nacional de Obras y Servicios Públicos',
        '012': 'BBVA Bancomer',
        '014': 'Banco Santander',
        '019': 'Banco Nacional del Ejército, Fuerza Aérea y Armada',
        '021': 'HSBC México',
        '022': 'GE Money Bank',
        '030': 'Banco del Bajío',
        '032': 'IXE Banco',
        '036': 'Banco Inbursa',
        '037': 'Banco Interacciones',
        '042': 'Banca Mifel',
        '044': 'Scotiabank Inverlat',
        '058': 'Banco Regional de Monterrey',
        '059': 'Banco Invex',
        '060': 'Bansi',
        '062': 'Banca Afirme',
        '072': 'Banco Mercantil del Norte',
        '102': 'The Royal Bank of Scotland México',
        '103': 'American Express Bank (México)',
        '106': 'Bank of America México',
        '108': 'Bank of Tokyo-Mitsubishi UFJ (México)',
        '110': 'Banco J.P. Morgan',
        '112': 'Banco Monex',
        '113': 'Banco Ve por Mas',
        '116': 'EING Bank (México)',
        '124': 'Deutsche Bank México',
        '126': 'Banco Credit Suisse (México)',
        '127': 'Banco Azteca',
        '128': 'Banco Autofin México',
        '129': 'Barclays Bank México',
        '130': 'Banco Compartamos',
        '131': 'Banco Ahorro Famsa',
        '132': 'Banco Multiva',
        '133': 'Banco Actinver',
        '134': 'Banco Wal Mart de México Adelante',
        '135': 'Nacional Financiera',
        '136': 'Inter Banco',
        '137': 'BanCoppel',
        '138': 'ABC Capital',
        '139': 'UBS Banco',
        '140': 'Consubanco',
        '141': 'Volkswagen Bank S.A. Institución de Banca Múltiple',
        '143': 'Consultoría Internacional Banco',
        '145': 'Banco BASE',
        '147': 'Bankaool',
        '148': 'Banco PagaTodo',
        '150': 'Banco Inmobiliario Mexicano',
        '155': 'Industrial and Commercial Bank of China',
        '156': 'Banco Sabadell',
        '166': 'Banco del Ahorro Nacional y Servicios Financieros',
        '168': 'Sociedad Hipotecaria Federal',
        '600': 'Monex Casa de Bolsa',
        '601': 'GBM Grupo Bursátil Mexicano',
        '602': 'Masari Casa de Bolsa',
        '604': 'Inversora Bursátil',
        '605': 'Valué',
        '606': 'Estructuradores del Mercado de Valores Casa de Bolsa',
        '607': 'Casa de Cambio Tíber',
        '608': 'Vector Casa de Bolsa',
        '610': 'B y B Casa de Cambio',
        '611': 'Intercam Casa de Cambio',
        '613': 'Multivalores Casa de Bolsa',
        '614': 'Acciones y Valores Banamex',
        '615': 'Merrill Lynch México',
        '616': 'Casa de Bolsa Finamex',
        '617': 'Valores Mexicanos Casa de Bolsa',
        '618': 'Única Casa de Cambio',
        '619': 'MAPFRE Tepeyac',
        '620': 'Profuturo G.N.P.',
        '621': 'Actinver Casa de Bolsa',
        '622': 'OPERADORA ACTINVER',
        '623': 'Skandia Vida',
        '624': 'Consultoría Internacional Casa de Cambio',
        '626': 'Deutsche Securities',
        '627': 'Zúrich Compañía de Seguros',
        '628': 'Zúrich Vida, Compañía de Seguros',
        '629': 'Hipotecaria su Casita',
        '630': 'Intercam Casa de Bolsa',
        '631': 'CI Casa de Bolsa',
        '632': 'Bulltick Casa de Bolsa',
        '633': 'Sterling Casa de Cambio',
        '634': 'Fincomún, Servicios Financieros Comunitarios',
        '636': 'HDI Seguros',
        '637': 'OrderExpress Casa de Cambio',
        '638': 'Akala',
        '640': 'J.P. Morgan Casa de Bolsa',
        '642': 'Operadora de Recursos Reforma',
        '646': 'Sistema de Transferencias y Pagos STP',
        '647': 'Telecomunicaciones de México',
        '648': 'Evercore Casa de Bolsa',
        '649': 'Skandia Operadora',
        '651': 'Seguros Monterrey New York Life',
        '652': 'Solución Asea',
        '653': 'Kuspit Casa de Bolsa',
        '655': 'J.P. SOFIEXPRESS',
        '656': 'UNAGRA',
        '659': 'Opciones Empresariales Del Noreste',
        '670': 'Libertad Servicios Financieros',
        '674': 'AXA Seguros',
        '677': 'Caja Popular Mexicana',
        '679': 'Financiera Nacional De Desarrollo Agropecuario',
        '684': 'Operadora De Pagos Móviles De México',
        '901': 'CLS Bank International',
        '902': 'SD. INDEVAL',
    },
};
