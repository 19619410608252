export const managerRewards = {
    "phone": "Phone",
    "email": "Email",
    "points": "{{pointsNumber}} points",
    "full_name": "Full name",
    "title": "Rewards for",
    "apartment": "Apartment",
    "street_and_num": "Street (street, num)",
    "city": "City",
    "title2": "Reward for management company",
    // todo move to separate component
    "titleMarketPlace": "Discounted Products",
    "collect_marketplace_product": "Select A Product",
    "collect_marketplace_product_success": "You've collected the product successfully",
    "collect_marketplace_title": "products",
    "collect_marketplace_currency": "ILS",
    "collect_marketplace_popup_title": "fill in your details for this product",
    "collect_marketplace_popup_explain": "order will be completed after a call with our representative",
    "actions_title": "Points Table",
    "amount": "Amount of points",
    "prizes": "Rewards",
    "action": "Action",
    "coins_gained": "Points earned",
    "coins": "Points",
    "collect": "Collect",
    "collect_prize": "Collect prize",
    "personal_details": "Personal details",
    "delivery_details": "Delivery details",
    "next_prize": "You have <amountLeft> points left to reach the next prize",
    "prize_collect_success": "You've collected prize successfully",
    "COINS_NOT_ENOUGH": "You do not have enough points for this prize",
    "CAMPAIGN_NOT_FOUND": "Campaign not found",
    "CAMPAIGN_NOT_ACTIVE": "Campaign not active",
    "CAMPAIGN_PRIZE_NOT_FOUND": "Selected prize was not found",
    "CAMPAIGN_PRIZE_ALREADY_REQUESTED": "Selected prize was already requested",
    "new_coins_gained": "Congratulations, you have got {{newPoints}} new points! Check rewards page and select prize",
    "no_coins": "You do not have enough points to select this prize",
    "collecting_points_tooltip": "Points will be accumulated for new collection operations only. This applies to any apartment for which no payment was made in the last 3 months"
};
