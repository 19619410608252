import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import * as Sentry from "@sentry/react";

const LOADING_TIMEOUT = 55000; // 1m

export function Watchdog() {

    const loading = useSelector(state => state.common.loader);
    const [loadingHandler, setLoadingHandler] = useState(0);

    useEffect(() => {
        console.log('WATCHDOG', 'loading track');
        if (loading > 0 && loadingHandler === 0) {
            const handler = window.setTimeout(() => {
                // NOTE: this will work once, set loadingHandler back to zero if you need repeat it
                console.warn(`WATCHDOG LOADING > 0 its ${loading}`);
                Sentry.captureMessage('WATCHDOG: page loading timeout reached', 'error');
            }, LOADING_TIMEOUT);
            setLoadingHandler(handler)
        }
        if (loading < 1) {
            console.log(`clearing due to loading ${loading}`);
            window.clearTimeout(loadingHandler);
            setLoadingHandler(0);
        }
    }, [loading, loadingHandler]);

    return '';
}
