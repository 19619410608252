import { colors } from "../ui/globalColors";

export const selectCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '7px 12px 7px 0',
        borderColor: '#A8C0FFFF',
        fontSize: '15px',
        lineHeight: '20px',
        color: '#373647',
        borderRadius: '25px',
        borderBottomLeftRadius: state.menuIsOpen ? 0 : '25px',
        borderBottomRightRadius: state.menuIsOpen ? 0 : '25px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#A8C0FFFF',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#373647',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#A8C0FFFF',
        '&:hover': {
            color: '#A8C0FFFF',
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        border: '1px solid #A8C0FFFF',
        borderTop: 'none',
        borderBottomLeftRadius: '25px',
        borderBottomRightRadius: '25px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: 'none',
        animation: 'fadeIn 0.2s linear',
        zIndex: 9999,
        overflowY: 'hidden'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '15px',
        lineHeight: '20px',
        color: '#373647',
        textAlign: 'start',
    }),
    option: (provided, state) => ({
        ...provided,
        padding: '15px 20px',
        backgroundColor: state.isSelected ? '#A8C0FFFF' : provided.backgroundColor,
        '&:last-child': {
            borderBottomLeftRadius: '25px',
            borderBottomRightRadius: '25px',
        }
    }),
}

export const newSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '5px 10px',
        border: 'none',
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: '37px',
        minWidth: '100px',
        color: '#373647',
        borderRadius: '20px',
        borderBottomLeftRadius: state.menuIsOpen ? 0 : '20px',
        borderBottomRightRadius: state.menuIsOpen ? 0 : '20px',
        boxShadow: '0px 3px 10px rgba(89, 102, 137, 0.12)',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: '22px'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '22px',
        color: colors.semiTransparentColors.grayBlue,
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
       alignSelf: 'center'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#373647',
        padding: 0,
        width: '22px',
        height: '22px',
        backgroundColor: '#E9E9FD',
        borderRadius: '50%',
        boxShadow: '0px 3px 34px rgba(89, 102, 137, 0.05)',
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        '&:hover': {
            color: '#373647',
        },
        '& svg': {
            width: '16px'
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        border: 'none',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: '0px 35px 50px -5px rgba(89, 102, 137, 0.2)',
        animation: 'fadeIn 0.2s linear',
        zIndex: 9999,
        overflowY: 'hidden'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#373647',
    }),
    option: (provided, state) => ({
        ...provided,
        position: 'relative',
        padding: '7px 20px',
        backgroundImage: state.isSelected && state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : state.isSelected && !state.isRtl ? 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)' : provided.backgroundImage,
        color: '#373647',
        backgroundColor: 'transparent',
        '&:last-child': {
            marginBottom: '15px'
        },
        '&:active, &:hover': {
            backgroundImage: state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)',
            backgroundColor: 'transparent'
        },
        '&::before': state.isSelected && {
            content: '""',
            position: 'absolute',
            right: state.isRtl && '0',
            left: !state.isRtl && '0',
            top: '50%',
            width: '4px',
            height: '80%',
            backgroundImage: 'linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%)',
            boxShadow: '0px 0px 5px rgba(139, 117, 245, 0.4)',
            borderRadius: state.isRtl ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
            transform: 'translateY(-50%)'
        }
    }),
};

export const graySelectStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '5px 10px',
        border: 'none',
        fontSize: '14px',
        lineHeight: '22px',
        height: '32px',
        minWidth: '100px',
        color: '#373647',
        borderRadius: '20px',
        backgroundColor: '#F4F2FE',
        borderBottomLeftRadius: state.menuIsOpen ? 0 : '20px',
        borderBottomRightRadius: state.menuIsOpen ? 0 : '20px',
        boxShadow: '0px 3px 10px rgba(89, 102, 137, 0.12)',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: '22px'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#373647',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
       alignSelf: 'center'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#373647',
        padding: 0,
        width: '22px',
        height: '22px',
        backgroundColor: '#FFFFFF',
        borderRadius: '50%',
        boxShadow: '0px 3px 34px rgba(89, 102, 137, 0.05)',
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        '&:hover': {
            color: '#373647',
        },
        '& svg': {
            width: '16px'
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        border: 'none',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: '0px 35px 50px -5px rgba(89, 102, 137, 0.2)',
        animation: 'fadeIn 0.2s linear',
        zIndex: 9999,
        overflowY: 'hidden'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#373647',
    }),
    option: (provided, state) => ({
        ...provided,
        position: 'relative',
        padding: '7px 20px',
        backgroundImage: state.isSelected && state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : state.isSelected && !state.isRtl ? 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)' : provided.backgroundImage,
        color: '#373647',
        backgroundColor: 'transparent',
        '&:last-child': {
            marginBottom: '15px'
        },
        '&:active, &:hover': {
            backgroundImage: state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)',
            backgroundColor: 'transparent'
        },
        '&::before': state.isSelected && {
            content: '""',
            position: 'absolute',
            right: state.isRtl && '0',
            left: !state.isRtl && '0',
            top: '50%',
            width: '4px',
            height: '80%',
            backgroundImage: 'linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%)',
            boxShadow: '0px 0px 5px rgba(139, 117, 245, 0.4)',
            borderRadius: state.isRtl ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
            transform: 'translateY(-50%)'
        }
    }),
};

export const newSelectWithBorder = {
    control: (provided, state) => ({
        ...provided,
        padding: '5px 10px',
        borderColor: 'var(--bllink-blue)',
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: '37px',
        color: '#373647',
        borderRadius: '20px',
        borderBottomLeftRadius: state.menuIsOpen ? 0 : '20px',
        borderBottomRightRadius: state.menuIsOpen ? 0 : '20px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: 'var(--bllink-blue)',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: '22px'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#373647',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        alignSelf: 'center'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#373647',
        padding: 0,
        width: '22px',
        height: '22px',
        backgroundColor: '#E9E9FD',
        borderRadius: '50%',
        boxShadow: '0px 3px 34px rgba(89, 102, 137, 0.05)',
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        '&:hover': {
            color: '#373647',
        },
        '& svg': {
            width: '16px'
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: '-1px 0 0',
        padding: 0,
        border: '1px solid #c4c7ff',
        borderTop: 'none',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: 'none',
        animation: 'fadeIn 0.2s linear',
        zIndex: 9999,
        overflowY: 'hidden'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#373647',
    }),
    option: (provided, state) => ({
        ...provided,
        position: 'relative',
        padding: '7px 20px',
        backgroundImage: state.isSelected && state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : state.isSelected && !state.isRtl ? 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)' : provided.backgroundImage,
        color: '#373647',
        backgroundColor: 'transparent',
        '&:last-child': {
            marginBottom: '15px'
        },
        '&:active, &:hover': {
            backgroundImage: state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)',
            backgroundColor: 'transparent'
        },
        '&::before': state.isSelected && {
            content: '""',
            position: 'absolute',
            right: state.isRtl && '0',
            left: !state.isRtl && '0',
            top: '50%',
            width: '4px',
            height: '80%',
            backgroundImage: 'linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%)',
            boxShadow: '0px 0px 5px rgba(139, 117, 245, 0.4)',
            borderRadius: state.isRtl ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
            transform: 'translateY(-50%)'
        }
    }),
};

export const dropupSelectStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '5px 10px',
        borderColor: '#c4c7ff',
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: '37px',
        color: '#373647',
        borderRadius: '20px',
        borderTopLeftRadius: state.menuIsOpen ? 0 : '20px',
        borderTopRightRadius: state.menuIsOpen ? 0 : '20px',
        boxShadow: 'none',
        '&:hover': {
            borderColor: '#c4c7ff',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: '22px'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '22px',
        color: '#373647',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        alignSelf: 'center'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#373647',
        padding: 0,
        width: '22px',
        height: '22px',
        backgroundColor: '#E9E9FD',
        borderRadius: '50%',
        boxShadow: '0px 3px 34px rgba(89, 102, 137, 0.05)',
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(360deg)' : 'rotate(180deg)',
        '&:hover': {
            color: '#373647',
        },
        '& svg': {
            width: '16px'
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: '1px 0 0',
        padding: 0,
        border: '1px solid #c4c7ff',
        borderBottom: 'none',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: 'none',
        animation: 'fadeIn 0.2s linear',
        zIndex: 9999,
        overflowY: 'hidden'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#373647',
    }),
    option: (provided, state) => ({
        ...provided,
        position: 'relative',
        padding: '7px 20px',
        backgroundImage: state.isSelected && state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : state.isSelected && !state.isRtl ? 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)' : provided.backgroundImage,
        color: '#373647',
        backgroundColor: 'transparent',
        '&:active, &:hover': {
            backgroundImage: state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)',
            backgroundColor: 'transparent'
        },
        '&::before': state.isSelected && {
            content: '""',
            position: 'absolute',
            right: state.isRtl && '0',
            left: !state.isRtl && '0',
            top: '50%',
            width: '4px',
            height: '80%',
            backgroundImage: 'linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%)',
            boxShadow: '0px 0px 5px rgba(139, 117, 245, 0.4)',
            borderRadius: state.isRtl ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
            transform: 'translateY(-50%)'
        }
    }),
};

export const oneLineSelectStyles = {
    control: provided => ({
        ...provided,
        border: 'none',
        fontSize: '14px',
        lineHeight: '22px',
        minHeight: '30px',
        color: '#373647',
        borderBottom: '1px solid rgba(110, 116, 255, 0.4)',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        borderRadius: '0',
    }),
    valueContainer: provided => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: '22px'
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: '14px',
        lineHeight: '22px',
        color: 'rgba(55, 54, 71, 0.7)'
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: provided => ({
        ...provided,
       alignSelf: 'center'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#373647',
        padding: 0,
        width: '22px',
        height: '22px',
        backgroundColor: '#E9E9FD',
        borderRadius: '50%',
        boxShadow: '2px 2px 8px 0px rgba(34, 60, 80, 0.2)',
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        '&:hover': {
            color: '#373647',
        },
        '& svg': {
            width: '16px'
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        border: 'none',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: '0px 35px 50px -5px rgba(89, 102, 137, 0.2)',
        animation: 'fadeIn 0.2s linear',
        zIndex: 9999,
        overflowY: 'hidden'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#373647',
    }),
    option: (provided, state) => ({
        ...provided,
        position: 'relative',
        padding: '7px 20px',
        backgroundImage: state.isSelected && state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : state.isSelected && !state.isRtl ? 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)' : provided.backgroundImage,
        color: '#373647',
        backgroundColor: 'transparent',
        textAlign: 'start',
        '&:last-child': {
            marginBottom: '15px'
        },
        '&:active, &:hover': {
            backgroundImage: state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)',
            backgroundColor: 'transparent'
        },
        '&::before': state.isSelected && {
            content: '""',
            position: 'absolute',
            right: state.isRtl && '0',
            left: !state.isRtl && '0',
            top: '50%',
            width: '4px',
            height: '80%',
            backgroundImage: 'linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%)',
            boxShadow: '0px 0px 5px rgba(139, 117, 245, 0.4)',
            borderRadius: state.isRtl ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
            transform: 'translateY(-50%)'
        }
    }),
};

export const newGraySelectStyles = {
    control: (provided, state) => ({
        ...provided,
        padding: '5px 10px',
        border: 'none',
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '22px',
        height: '54px',
        minWidth: '100px',
        color: '#373647',
        backgroundColor: '#F3F1FF',
        borderBottomLeftRadius: state.menuIsOpen ? '0px !important' : '30px !important',
        borderBottomRightRadius: state.menuIsOpen ? '0px !important' : '30px !important',
        borderTopLeftRadius: state.menuIsOpen ? '18px !important' : '30px !important',
        borderTopRightRadius: state.menuIsOpen ? '18px !important' : '30px !important',
        boxShadow: 'none',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        margin: 0,
        lineHeight: '22px'
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '18px',
        lineHeight: '22px',
        color: '#373647',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
       alignSelf: 'center'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#373647',
        padding: 0,
        width: '22px',
        height: '22px',
        backgroundColor: '#DBD6FB',
        borderRadius: '50%',
        boxShadow: '0px 3px 34px rgba(89, 102, 137, 0.05)',
        transition: 'all .2s ease',
        transform: state.isFocused ? 'rotate(180deg)' : null,
        '&:hover': {
            color: '#373647',
        },
        '& svg': {
            width: '16px'
        }
    }),
    menu: (provided) => ({
        ...provided,
        margin: 0,
        padding: 0,
        border: 'none',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: '0px 35px 50px -5px rgba(89, 102, 137, 0.2)',
        animation: 'fadeIn 0.2s linear',
        zIndex: 99999,
        overflowY: 'hidden',
        backgroundColor: '#F3F1FF'
    }),
    menuList: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: '18px',
        lineHeight: '24px',
        color: '#373647',
    }),
    option: (provided, state) => ({
        ...provided,
        textAlign: 'start',
        position: 'relative',
        padding: '7px 20px',
        backgroundImage: state.isSelected && state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : state.isSelected && !state.isRtl ? 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)' : provided.backgroundImage,
        color: '#373647',
        backgroundColor: 'transparent',
        '&:last-child': {
            marginBottom: '15px'
        },
        '&:active, &:hover': {
            backgroundImage: state.isRtl ? 'linear-gradient(90deg, rgba(196, 196, 196, 0) 0%, rgba(139, 117, 245, 0.15) 169.34%)' : 'linear-gradient(90deg, rgba(139, 117, 245, 0.15) 0%, rgba(196, 196, 196, 0) 80%, rgba(196, 196, 196, 0) 100%)',
            backgroundColor: 'transparent'
        },
        '&::before': state.isSelected && {
            content: '""',
            position: 'absolute',
            right: state.isRtl && '0',
            left: !state.isRtl && '0',
            top: '50%',
            width: '4px',
            height: '80%',
            backgroundImage: 'linear-gradient(360deg, #6798FF 0%, #8C5CFF 100%)',
            boxShadow: '0px 0px 5px rgba(139, 117, 245, 0.4)',
            borderRadius: state.isRtl ? '8px 0px 0px 8px' : '0px 8px 8px 0px',
            transform: 'translateY(-50%)'
        }
    }),
};


export const newGraySelectHighlightStyles = {
    ...newGraySelectStyles,
    control: (provided, state) => ({
        ...provided,
        ...newGraySelectStyles.control(provided, state),
        // for loans to highlight the option to take a loan. requested by omri and talV
        transition: 'all 0.3s ease',  // Smooth transition for all properties
        animation: 'glow 1.5s 5' // Apply the glow animation
    }),
    option: (provided, state) => ({
        ...provided,
        ...newGraySelectStyles.option(provided, state),
        transition:  'background 0.2s ease',  // Smooth transition for background changes
    }),
}
