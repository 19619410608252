export const autoCampaign = {
    "confirmPopUp": {
        "title": "Start new campaign",
        "sure": "Are you sure?"
    },
    "errorPopUp": {
        "title": "Attention!",
        "startNew": "Campaign is already running for selected buildings, would you like to cancel last campaign, and start a new one?"
    },
    "actions": {
        "title": "Auto campaign actions",
        "labels": {
            "partner": "Partner name",
            "buildings": "Partner buildings"
        },
        "btns": {
            "calculate": "Calculate",
            "start": "Start"
        },
        "statistics": "{{currentPartner}} already have {{inCampaign}} apartments in the default campaign. Clicking Start will start the campaign for {{nextCampaign}} more apartments"
    },
    "status": {
        "tableHeaders": {
            "lastAction": "Last action",
            "campaignName": "Campaign name",
            "step": "Step",
            "buildingTitle": "Building Address",
            "aptNumber": "Apt number",
            "campaignFinished": "Campaign finished",
            "stepSuccess": "Step successful",
            "partnerName": "Partner name"
        },
        "title": "Running campaign auto campaign",
        "subtitle": "Apartments shown here still didn't pay",
        "yes": "Yes",
        "no": "No",
        "actions": {
            "welcomeMessage": "Welcome message (sms / whatsapp)",
            "firstReminderMessage": "Reminder Message",
            "ivrAndText": "IVR + Text",
            "secondReminderMessage": "The second reminder message",
            "callFromBllink": "Send an email to Bllink operator",
            "thirdReminderMessage": "Split message",
            "noop": "The last step"
        },
        "getBtn": "Get status",
        "exportTitle": "Partners and buildings status",
        "failedStep": "Failed in sending usually means no phone number updated for apartment. Check the apartment report"
    },
    "labels": {
        "owner": "Owner",
        "renter": "Renter"
    }
};
