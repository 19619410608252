export const autoCampaign = {
    "confirmPopUp": {
        "title": "קמפיין אוטומטי",
        "sure": "האם את/ה בטוח/ה?"
    },
    "errorPopUp": {
        "title": "שימי לב!",
        "startNew": "קמפיין כבר רץ עבור הבניינים שנבחרו, האם לבטל קמפיין רץ ולהתחיל קמפיין חדש?"
    },
    "actions": {
        "title": "קמפיין אוטומטי",
        "labels": {
            "partner": "חברת ניהול",
            "buildings": "בניינים"
        },
        "btns": {
            "calculate": "חישוב",
            "start": "לא פועל עברנו לקמפיין החדש"
        },
        "statistics": "לחברת {{currentPartner}} כבר יש {{inCampaign}} דירות בקמפיין. לחיצה על ״התחל״ תתחיל קמפיין עבור עוד {{nextCampaign}} דירות"
    },
    "status": {
        "tableHeaders": {
            "lastAction": "פעולה אחרונה",
            "campaignName": "שם הקמפיין",
            "step": "צעד",
            "buildingTitle": "שם הבניין",
            "aptNumber": "מספר דירה",
            "campaignFinished": "קמפיין הסתיים",
            "stepSuccess": "שלב הסתיים בהצלחה",
            "partnerName": "שם חברה"
        },
        "title": "סטאטוס קמפיין אוטומטי",
        "subtitle": "דירות למטה הן דירות שעדיין לא השלימו תשלום כלומר עדיין נמצאים בקמפיין",
        "yes": "כן",
        "no": "לא",
        "actions": {
            "welcomeMessage": "הודעת ברוכים הבאים לבלינק (סמס/ ווטסאפ)",
            "firstReminderMessage": "הודעת תזכורת (אחרי 4 ימים)",
            "ivrAndText": "שיחת IVR וטקסט  (אחרי 5 ימים)",
            "secondReminderMessage": "הודעת תזכורת שנייה (אחרי 5 ימים)",
            "callFromBllink": "שיחת טלפון מבלינק  (אחרי 7 ימים)",
            "thirdReminderMessage": "הודעת תזכורת שלישית לבעלים  (אחרי 35 ימים)",
            "noop": "The last step"
        },
        "getBtn": "מצא",
        "exportTitle": "סטאטוס קמפיין אוטומטי",
        "failedStep": "שלב נכשל בדר״כ אומר שלדירה אין מספר טלפון מעודכן במערכת"
    },
    "labels":{
        "owner": "בעלים",
        "renter": "שוכר"
    }
};
