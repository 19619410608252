import styled from 'styled-components';
import {Modal} from 'react-bootstrap';
import {controlWithIcon} from './control-with-icon.styled';

export const StyledModal = styled(Modal)`
  &.header-with-bg {
    .modal-dialog {
      .modal-close {
        top: 17px;
        left: auto;
        inset-inline-end: 15px;
      }
      .modal-body {
        padding: 30px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        background: linear-gradient(190.57deg, #F9F9FF 19.25%, #F8F8FF 54.39%, #F9F9FF 90.02%);
      }
    }
    .modal-header {
      padding: 10px 15px;
      background: linear-gradient(90deg, #6895FF 1.59%, #8C5CFF 100%);
      border: none;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      color: #fff;
      font-size: 22px;
      line-height: 25px;
    }
  }
  .bank-adjustments-modal {
    max-width: 1170px;
    &.small-modal {
      max-width: 770px;
    }
  }
  .comments-modal.modal-dialog {
    max-width: 800px;
    h3 {
      margin-bottom: 20px;
    }
  }
  .supplier-modal {
    max-width: 1000px;
  }
  .fast-one-time-modal {
    max-width: 970px;
    .form-group {
      padding-right: 0;
      label {
        padding-right: 15px;
        margin-bottom: 8px;
      }
    }
  }
  .bank-charge.modal-dialog {
    max-width: 670px;
    
    .bllink-button {
      max-width: unset;
      font-size: 16px;
    }
  }
  .auto-bank-charge {
    max-width: 800px;
    overflow: hidden;
  }
  .modal-dialog {
    position: relative;
    .modal-body {
      padding: 50px;
    }
    .modal-header {
      justify-content: center;
    }
    .modal-content {
      border-radius: 25px;
      border: 1px solid ${props => props.theme.borderDark};
    }
    .modal-close {
      display: block;
      position: absolute;
      top: 20px;
      left: 20px;
      height: 20px;
      width: 20px;
      font-weight: 300;
      font-size: 36px;
      line-height: .5;
      color: ${props => props.theme.borderTable};
      cursor: pointer;
      z-index: 10;
    }
    #table {
      width: 1074px;
      margin-bottom: 50px;
    }
    .bllink-button {
      max-width: 270px;
      &.full-width {
        width: 100%;
      }
    }
    h3 {
      max-width: 770px;
      margin: 0 auto 35px auto;
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      color: #373647;
    }
    div:not(.check):not(.input-item) {
      > label {
        width: 100%;
        margin: 0;
        text-align: start;
        span {
          display: block;
          padding-right: 20px;
          margin-bottom: 13px;
          text-align: right;
        }
      }
    }
    .modal-row {
      margin-bottom: 23px;
      &:only-child, &:last-child {
        margin-bottom: 50px;
      }
      .DayPickerInput {
        width: 100%;
      }
    }
    .file_upload-input {
      z-index: 10;
      cursor: pointer;
    }
    .file-upload-btn {
      position: relative;
      height: 52px;
      padding: 0 20px 0 62px;
      font-weight: bold;
      font-size: 15px;
      line-height: 23px;
      color: ${props => props.theme.text};
      border-radius: 50px;
      border: 1px solid ${props => props.theme.borderTable};
      background-color: #fff;
      .file-name {
        display: block;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
      }
    }
    .modal-header + .modal-footer {
      border-top: none;
    }
    .modal-header {
      display: flex;
      align-items: center;
    }
    .modal-footer {
      .bllink-button {
        max-width: 120px;
      }
      .bllink-button + .bllink-button {
        margin-right: 15px;
      }
    }
    .checkbox-block {
      display: flex;
      align-items: center;
      text-align: right;
      line-height: 18px;
      input {
        margin-left: 10px;
        width: 18px;
        height: 18px;
      }
    }
    ${controlWithIcon()}

    .form-check {
      display: flex;
      align-items: center;
      padding-top: 0;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      label {
        line-height: 1;
        order: 2;
        font-size: 16px;
      }
      &-input {
        position: static;
        width: 20px;
        height: 20px;
        margin: 0 0 0 10px;
      }
    }
    .bllink-button:disabled {
      pointer-events: none;
      opacity: .65;
    }
  }

  .hidden-empty-option {
    display: none;
  }
  
  .tenant-details-modal {
    
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;