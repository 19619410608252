import styled from 'styled-components';

export const StyledHeader = styled.h2`
    font-family: var(--bllink-font-headers);
    font-size: 44px;
    line-height: 62px;
    color: var(--bllink-gray); 
    text-align: center; 
    margin-top: 50px;
`
