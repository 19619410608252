import {OverlayTrigger, Tooltip as BSTooltip} from "react-bootstrap";

/**
 * @param {string} text
 * @param {boolean} disabled
 * @param {'top' | 'bottom' | 'left' | 'right'} placement
 * */
export function Tooltip({text, children, placement = 'top', disabled = false}) {
    return disabled ? children : (
        <OverlayTrigger placement={placement}
                        overlay={props => (<BSTooltip {...props}>{text}</BSTooltip>)}>
            {children}
        </OverlayTrigger>
    );
}
